/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { Allocation } from '../proposal';
import { BaseFactoryAbstract } from './base-factory-abstract';

@Injectable()
export class AllocationFactory extends BaseFactoryAbstract<Allocation> {

    constructor() {
        super();
    }

    public createAllocation(
        allocationType: number,
        swpStrategyId: number,
        fundTickerId: string,
        modelId: number,
        percentage: number,
        allocationDescription: string,
        assetName: string,
        nonSei: boolean,
        targetPctUpperTolerance: number,
        targetPctLowerTolerance: number,
        taxMgmtEligFl?: boolean,
        sorKey?: number,
        level1Id?: number,
        level1Value?: string,
        level2Id?: string,
        level2Value?: string,
        level3Id?: string,
        level3Value?: string,
        baseManagerSWPStrategyId?: number,
        cusip?: string,
        productScope?: string,
        productType?: string,
        levelClass?: number
    ): Allocation {
        return {
            allocationDescription,
            allocationType,
            assetId: undefined,
            assetTypeId: undefined,
            fundTickerId,
            id: undefined,
            modelId,
            percentage,
            subModelId: undefined,
            swpStrategyId,
            assetName,
            nonSei,
            targetPctUpperTolerance,
            targetPctLowerTolerance,
            taxMgmtEligFl,
            sorKey,
            level1Id,
            level1Value,
            level2Id,
            level2Value,
            level3Id,
            level3Value,
            baseManagerSWPStrategyId,
            cusip,
            productScope,
            productType,
            levelClass
        };
    }
}
