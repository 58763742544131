/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { Scenario } from '../proposal';
import { ToolTipOptions } from '../tooltip-options';
import { BaseFactoryAbstract } from './base-factory-abstract';
@Injectable()
export class ToolTipOptionsFactory extends BaseFactoryAbstract<Scenario> {
    constructor() {
        super();
    }

    public createOption(label: string, disabled?: boolean): ToolTipOptions {
        return {
            disabled: disabled || false,
            label
        };
    }

    public createRemoveOption(label: string, titleText: string, bodyText: string, disabled?: boolean): ToolTipOptions {
        return {
            disabled: disabled || false,
            label,
            removeOptions: {
                titleText,
                bodyText
            }
        };
    }
}
