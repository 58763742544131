/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SpinnerService extends ParentComponentSubscriptionManager {
    public isSpinnerActive: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private spinnerQueue: number[] = [];

    constructor() {
        super('SpinnerService');
    }

    public start(): void {
        this.spinnerQueue.push(1);
        this.isSpinnerActive.next(true);
    }

    public stop(): void {
        this.spinnerQueue.pop();
        this.isSpinnerActive.next(this.spinnerQueue.length > 0);
    }
}
