/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { TooltipConstants } from '@CarModels/constants';
import { ExistingAccountsService } from '@CarServices/existing-accounts/existing-accounts.service';
import { Location } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Proposal, ProposalService, UserProfileService } from '@sei/advisor-client-review-proposal-ux';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { Subscription } from 'rxjs';
import { CommandText, RightHandFooterFunctions } from '../../model/enums';
import { DelegationService } from '../../service/delegation.service';
import { PreviousRouteService } from '../../service/previous-route.service';
import { ConfigurationService } from '../../service/system/configuration.service';

@Component({
    selector: 'car-right-hand-dialog-footer',
    templateUrl: './right-hand-dialog-footer.component.html'
})
export class RightHandDialogFooterComponent
    extends ParentComponentSubscriptionManager
    implements OnDestroy, OnInit {
    /* eslint-disable-next-line @angular-eslint/no-input-rename */
    @Input('function') functionWIP: string = '';

    public enviromentApplicationUrls;

    public disableProposedScenarioButton: boolean = true;
    public subscriptionToDataService: Subscription;
    public showProposedScenario: boolean;
    public enableCreateProposalButton: boolean;
    public enableModifyProposalScenarioButton: boolean = false;
    public disableSaveAndExitButton: boolean = true;

    public isOffsetVisibility: boolean;
    public flexContainerVisibility: boolean;
    public isSaveAndExitButtonVisible: boolean;
    public isAddSelectedAccountsButtonVisible: boolean;
    public isDisableSaveAndExitButton: boolean;
    public isHidePagingNextPreviousButton: boolean;
    public isReadUser: boolean = false;
    public isLoading: boolean = false;
    public showLockTooltip: boolean = false;
    public isNonModelEnrichmentInProgress: boolean = false;
    public isAddSelectedAccountsButtonDisabled: boolean = true;
    public isAddSelectedAccountsButton: boolean = true;


    private proposalId: number;
    private scenarioId: number;

    public constructor(
        private router: Router,
        private delegationService: DelegationService,
        private previousRouteService: PreviousRouteService,
        private location: Location,
        private configService: ConfigurationService,
        private userProfileService: UserProfileService,
        private proposalService: ProposalService,
        private existingAccountsService: ExistingAccountsService
    ) {
        super('RightHandDialogFooterComponent');
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
        this.delegationService.publish('');
    }

    public ngOnInit(): void {
        const locked: boolean = this.proposalService.isProposalChecklistLocked();
        this.isReadUser = this.userProfileService.isReadUser || locked;
        this.showLockTooltip = locked;
        this.subscriptionToDataService = this.delegationService
            .refresh()
            .subscribe((data: string) => {
                switch (data) {
                    case CommandText.ProposeScenario:
                        this.showProposedScenario = true;
                        break;
                    case CommandText.EnableCreateProposal:
                        this.enableCreateProposalButton = true;
                        break;
                    case CommandText.EnableModifyProposalScenario:
                        this.enableModifyProposalScenarioButton = true;
                        break;
                    case CommandText.DisableCreateProposal:
                        this.enableCreateProposalButton = false;
                        break;
                    case CommandText.EnableScenario:
                        this.disableProposedScenarioButton = false;
                        break;
                    case CommandText.DisableScenario:
                        this.disableProposedScenarioButton = true;
                        break;
                    case CommandText.DisableSaveAndExitButton:
                        this.disableSaveAndExitButton = true;
                        break;
                    case CommandText.EnableSaveAndExitButton:
                        this.disableSaveAndExitButton = false;
                        break;
                    case CommandText.IsLoading:
                        this.isLoading = true;
                        break;
                    case CommandText.LoadingStopped:
                        this.isLoading = false;
                        break;
                    case CommandText.NonModelEnrichmentInProgress:
                        this.isNonModelEnrichmentInProgress = true;
                        break;
                    case CommandText.NonModelEnrichmentComplete:
                        this.isNonModelEnrichmentInProgress = false;
                        break;
                    case CommandText.EnableProposedAccountsButton:
                        this.isAddSelectedAccountsButtonDisabled = false;
                        break;
                    case CommandText.DisableAddProposedAccountsButton:
                        this.isAddSelectedAccountsButtonDisabled = true;
                        break;
                    case CommandText.AddSelectedAccountsButton:
                        this.isAddSelectedAccountsButton = true;
                        break;
                    case CommandText.SaveAndExitButton:
                        this.isAddSelectedAccountsButton = false;
                        break;
                    default:
                        break;
                }
            });

        this.subscriptions.push(this.subscriptionToDataService);

        this.initButtonVisibility();
        super.ngOnInit();

        const proposalSubscription: Subscription = this.proposalService.currentProposal.subscribe((proposal: Proposal) => {
            if (proposal) {
                this.proposalId = proposal.id;
                this.scenarioId = proposal.scenarios[0].id;
            }
        });
        this.subscriptions.push(proposalSubscription);
    }
    public onCancelClick(): void {
        if (this.functionWIP === RightHandFooterFunctions.SelectExistingAccounts) {
            this.existingAccountsService.restoreSelectionsFromBackUp();
        }
        const cancelRoute = this.getCancelRoute();

        if (this.functionWIP === RightHandFooterFunctions.EditProposedAccounts) {
            this.delegationService.publish(CommandText.AccountsFlyoutCancel);
            return;
        } else if (cancelRoute) {
            this.router.navigate(cancelRoute);
            // Note: This will trigger verifyProposal
            this.delegationService.publish(CommandText.UpdateWipCheckList);

            this.delegationService.publish(CommandText.CancelStrategyBalance);
        } else {
            this.delegationService.publish(CommandText.CancelGoal);
            this.location.back();
        }

        this.delegationService.publish(CommandText.CancelRightHandDialog);
    }

    public onProposedScenarioClick(): void {
        this.delegationService.publish(CommandText.ProposeScenario);
    }

    public onCreateProposalClick(): void {
        this.delegationService.publish(CommandText.DisableCreateProposal);
        this.delegationService.publish(CommandText.CreateProposal);
    }

    public onSaveAndExitAccountClick(): void {
        switch (this.functionWIP) {
            case RightHandFooterFunctions.Account:
                this.delegationService.publish(CommandText.IsLoading);
                this.delegationService.publish(CommandText.SaveStrategies);
                break;
            case RightHandFooterFunctions.Fee:
                this.delegationService.publish(CommandText.IsLoading);
                this.delegationService.publish(CommandText.SaveFeeGroup);
                break;
            case RightHandFooterFunctions.EditProposal:
                this.delegationService.publish(CommandText.SaveEditProposal);
                break;
            case RightHandFooterFunctions.EditGoal:
                this.delegationService.publish(CommandText.SaveGoal);
                break;
            case RightHandFooterFunctions.EditScenario:
                this.delegationService.publish(CommandText.ModifyProposalScenario);
                break;
            case RightHandFooterFunctions.EditProposedAccounts:
                this.delegationService.publish(CommandText.IsLoading);
                this.delegationService.publish(CommandText.SaveEditProposal);
                break;
            case RightHandFooterFunctions.EditExistingAccounts:
                this.delegationService.publish(CommandText.SaveEditProposal);
                break;
            default:
                break;
        }
        this.delegationService.publish(CommandText.DisableSaveAndExitButton);
    }

    public onAddSelectedAccountsClick(): void {
        this.delegationService.publish(CommandText.AddSelectedAccounts);
    }

    public saveAndExitButtonVisibility(): boolean {
        const returnValue =
            (this.functionWIP === RightHandFooterFunctions.Account ||
                this.functionWIP === RightHandFooterFunctions.Fee ||
                this.functionWIP === RightHandFooterFunctions.EditProposal ||
                this.functionWIP === RightHandFooterFunctions.EditScenario ||
                this.functionWIP === RightHandFooterFunctions.EditGoal ||
                this.functionWIP === RightHandFooterFunctions.EditProposedAccounts ||
                this.functionWIP === RightHandFooterFunctions.EditExistingAccounts);
        return returnValue;
    }

    public getDisableSaveAndExitButton(): boolean {
        const returnValue =
            (this.functionWIP === RightHandFooterFunctions.Account ||
                this.functionWIP === RightHandFooterFunctions.EditScenario ||
                this.functionWIP === RightHandFooterFunctions.EditGoal || this.isReadUser ||
                this.functionWIP === RightHandFooterFunctions.EditProposedAccounts ||
                this.functionWIP === RightHandFooterFunctions.EditExistingAccounts);
        return returnValue;
    }

    public getOffsetVisibility(): boolean {
        const returnValue =
            (this.functionWIP === RightHandFooterFunctions.Proposal ||
                this.functionWIP === RightHandFooterFunctions.Fee ||
                this.functionWIP === RightHandFooterFunctions.EditProposal ||
                this.functionWIP === RightHandFooterFunctions.EditScenario ||
                this.functionWIP === RightHandFooterFunctions.EditGoal ||
                this.functionWIP === RightHandFooterFunctions.EditProposedAccounts ||
                this.functionWIP === RightHandFooterFunctions.EditExistingAccounts ||
                this.functionWIP === RightHandFooterFunctions.SelectExistingAccounts);
        return returnValue;
    }

    public getHidePagingNextPreviousButton(): boolean {
        const returnValue =
            (this.functionWIP === RightHandFooterFunctions.Proposal ||
                this.functionWIP === RightHandFooterFunctions.Fee ||
                this.functionWIP === RightHandFooterFunctions.EditProposal ||
                this.functionWIP === RightHandFooterFunctions.EditScenario ||
                this.functionWIP === RightHandFooterFunctions.EditGoal ||
                this.functionWIP === RightHandFooterFunctions.EditProposedAccounts ||
                this.functionWIP === RightHandFooterFunctions.EditExistingAccounts ||
                this.functionWIP === RightHandFooterFunctions.SelectExistingAccounts);
        return returnValue;
    }

    public getFlexContainerVisibility(): boolean {
        const returnValue =
            (this.functionWIP === RightHandFooterFunctions.Proposal ||
                this.functionWIP === RightHandFooterFunctions.Fee ||
                this.functionWIP === RightHandFooterFunctions.EditProposal ||
                this.functionWIP === RightHandFooterFunctions.EditScenario ||
                this.functionWIP === RightHandFooterFunctions.EditGoal ||
                this.functionWIP === RightHandFooterFunctions.SelectExistingAccounts);
        return returnValue;
    }

    public getAddSelectedAccountsVisibility(): boolean {
        return this.functionWIP === RightHandFooterFunctions.SelectExistingAccounts;
    }

    public getSaveAndExitTooltip(): string {
        if (this.showLockTooltip) {
            return TooltipConstants.PROPOSAL_CHECKLIST_LOCKED_MESSAGE;
        }
        return undefined;
    }

    public getSelectAccountsButtonText(): string {
        return this.isAddSelectedAccountsButton ? 'Add Selected Accounts' : 'Save & Exit';
    }

    public getSelectAccountsButtonIcon(): string {
        return this.isAddSelectedAccountsButton ? 'fa-solid fa-circle-plus' : 'fas fa-save';
    }

    public selectedAccountsButtonDisabled(): boolean {
        if (!this.isAddSelectedAccountsButton) {
            return false;
        }
        return this.existingAccountsService.existingAccountsToBeAddedToProposal.value.length === 0;
    }

    private getCancelRoute(): string[] {
        const dashboardRoute = 'Proposal/Dashboard';
        const proposal = 'Proposal';
        const previousRoute = this.previousRouteService.getPreviousUrl(
            this.configService.environment.applicationUrls
        );
        if (previousRoute[0]) {
            return previousRoute;
        } else {
            if ((window.location.href).split('/')[(window.location.href).split('/').length - 1] === proposal) {
                return [dashboardRoute];
            }
        }
    }

    private initButtonVisibility(): void {
        this.isHidePagingNextPreviousButton = this.getHidePagingNextPreviousButton();
        this.isSaveAndExitButtonVisible = this.saveAndExitButtonVisibility();
        this.isDisableSaveAndExitButton = this.getDisableSaveAndExitButton();
        this.flexContainerVisibility = this.getFlexContainerVisibility();
        this.isOffsetVisibility = this.getOffsetVisibility();
        this.isAddSelectedAccountsButtonVisible = this.getAddSelectedAccountsVisibility();
    }
}
