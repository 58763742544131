/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@CarComponents/common/common.module';
import { AccountGoalFactory } from '@CarFactories/account-goal-factory';
import { WipGoalsDetailsComponent } from './wip-goals-details/wip-goals-details.component';
import { WipGoalsComponent } from './wip-goals-main/wip-goals-main.component';
import { WipGoalsStrategiesComponent } from './wip-goals-strategies/wip-goals-strategies.component';

@NgModule({
    imports: [CommonModule],
    declarations: [
        WipGoalsComponent,
        WipGoalsDetailsComponent,
        WipGoalsStrategiesComponent
    ],
    providers: [AccountGoalFactory],
    exports: [WipGoalsComponent]
})
export class WipGoalsModule {}
