/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { ApigeeTokenService } from '@CarAuth/apigee-token.service';
import { SeiSwpOauthResponse } from '@CarInterfaces/oauth';
import { EnvironmentMode } from '@CarModels/enums';
import { GlobalService } from '@CarSystem/global.service';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store, createAction } from '@ngrx/store';
import { SeiCommonAppStore } from '@sei/common-swp-components-lib-ux';
import { Observable } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';


/**
 * Intercepts the URLs and rewrites them if URL is a CAR related
 */
@Injectable({ providedIn: 'root' })
export class HttpErrorHandlerInterceptor implements HttpInterceptor {

    public readonly skipURLDevMode: string = 'assets/config/env.json';
    invalidAccessCode = createAction('[Apigee] Access Token Invalid');
    private lock: boolean = false;

    constructor(
        private readonly global: GlobalService,
        private readonly appStore: Store<SeiCommonAppStore>,
        private readonly tokenService: ApigeeTokenService
    ) {
    }

    public intercept(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        req: HttpRequest<any>,
        next: HttpHandler
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Observable<HttpEvent<any>> {

        if (this.tokenService.isTokenExpiredNow()) {
            if (!this.lock) {
                this.lock = true;
                this.appStore.dispatch(this.invalidAccessCode());
            }
            return this.tokenService.getUnexpiredAccessToken().pipe(
                mergeMap(() => {
                    return this.handleNext(req, next);
                })
            );
        }
        this.lock = false;
        if (this.global.configService.environment.mode === EnvironmentMode.Dev &&
            req.url.includes(this.skipURLDevMode)) {
            return next.handle(req);
        }

        return this.handleNext(req, next);
    }

    private handleNext(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        req: HttpRequest<any>,
        next
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(map((event) => {
            if (event instanceof HttpResponse) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const res = (event as HttpResponse<any>);
                if (res.url && this.tokenService.isOauthRequestURL(res.url) && res.body) {
                    const jwt = res.body as SeiSwpOauthResponse;
                    jwt.diff_time = jwt.issued_at - Date.now();
                    this.tokenService.response = jwt;
                }
            }
            return event;
        })).pipe(
            catchError((errorResponse: HttpErrorResponse) => {
                if ((errorResponse && errorResponse.message === 'Access Token expired')
                    || (errorResponse && errorResponse.error && errorResponse.error.message === 'Access Token expired')
                    || (errorResponse && errorResponse.error && errorResponse.error.message === 'Invalid Access Token') ||
                    (errorResponse && errorResponse.message === 'Invalid Access Token')
                ) {
                    return this.tokenService.getUnexpiredAccessToken().pipe(
                        mergeMap(() => {
                            return next.handle(req);
                        })
                    );
                } else {
                    return next.handle(req);
                }
            })
        );
    }
}
