<ng-template #recursiveList let-list>
    <ng-container *ngFor="let section of list" [ngSwitch]="section.name">
        <div class="row toggle-row" *ngIf="!section.hidden">
            <div class="col-xs-9 toggle-row-left indent{{section.level}}">
                {{section.name}}
            </div>
            <div class="col-xs-3" *ngIf="section.level === 2">
                <sei-car-toggle-sei-wizard-nav (onChangeEvent)="parentToggleChange($event, section)"
                    [(ngModel)]="section.include"></sei-car-toggle-sei-wizard-nav>
            </div>
            <div class="col-xs-3" *ngIf="section.inputProperty.elementType === elementTypes.DropDown">
                <sei-car-settings-dropdown id="dropdown-{{section.id}}" [dropDownContent]="getDropDownSource(section)"
                    [(ngModel)]="section.inputProperty.value" [selectWidth]="'55'"
                    (ngModelChange)="dropdownOptionSelected(proposalSection)">
                </sei-car-settings-dropdown>
            </div>
            <div class="col-xs-3 toggle-row-center" *ngIf="section.level >= 4">
                <sei-icon-toggle name="icon-toggle-{{section.id}}" [(ngModel)]="section.include"
                    (ngModelChange)="modelChanged(proposalSection)" [trueIcon]="trueIcon" [falseIcon]="falseIcon"
                    [iconClass]="iconClass" [iconSize]="iconSize" [offIsGrey]="offIsGrey" [disabled]="disabled">
                </sei-icon-toggle>
            </div>
        </div>
        <ng-container *ngIf="section.sections.length > 0">
            <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: section.sections }"></ng-container>
        </ng-container>
    </ng-container>
</ng-template>
<ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: proposalSection.sections }"></ng-container>