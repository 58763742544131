/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { AdditionalWipSection } from '@CarInterfaces/account';
import { WipChangeState, WipSections } from '@CarServices/wip/wip-enums';

export enum InvestmentProgramType {
    MutualFundsId = 1,
    MutualFundsName = 'Mutual Funds',
    ManagedAccountsId = 2,
    ManagedAccountName = 'Managed Account Solution',
    DistributionFocusedStrategiesId = 3,
    DistributionFocusedStrategiesName = 'Distribution Focused Strategies',
    ETFId = 4,
    ETFName = 'ETF',
    FixedIncomeId = 5,
    FixedIncomeName = 'Fixed Income',
    CustomMutualFundId = 999,
    CustomMutualFundName = 'Custom Firm Mutual Funds'
}

export interface InvestmentTypeOptionSettings {
    investmentProgramId: number;
    name: string;
    additionalWipSections: AdditionalWipSection[];
    wipSectionSettings?: WipSectionSettings[];
}

export interface WipSectionSettings {
    sectionId: number;
    disableWipActions?: boolean;
}

/**
 * Contains values for special logic the UI needs to perform for a specific investment program type
 * @param additionalWipSections WIP sections could be added
 */
export const investmentTypeOptionsData: InvestmentTypeOptionSettings[] = [
    {
        investmentProgramId: InvestmentProgramType.MutualFundsId.valueOf(),
        name: InvestmentProgramType.MutualFundsName.valueOf(),
        additionalWipSections: [
            {
                id: WipSections.SeiScheduleDistribution,
                changeState: WipChangeState.Incomplete
            },
            {
                id: WipSections.Goals,
                changeState: WipChangeState.Incomplete
            },
            {
                id: WipSections.PurchaseOutSideStrategy,
                changeState: WipChangeState.Incomplete
            },
            {
                id: WipSections.SeiCashAccessAccount,
                changeState: WipChangeState.Incomplete
            }
        ]
    },
    {
        investmentProgramId: InvestmentProgramType.ManagedAccountsId.valueOf(),
        name: InvestmentProgramType.ManagedAccountName.valueOf(),
        additionalWipSections: [
            {
                id: WipSections.StrategyFundingPriority,
                changeState: WipChangeState.Incomplete
            },
            {
                id: WipSections.SeiScheduleDistribution,
                changeState: WipChangeState.Incomplete
            },
            {
                id: WipSections.PurchaseOutSideStrategy,
                changeState: WipChangeState.Incomplete
            },
            {
                id: WipSections.SeiCashAccessAccount,
                changeState: WipChangeState.Incomplete
            }
        ]
    },
    {
        investmentProgramId: InvestmentProgramType.DistributionFocusedStrategiesId.valueOf(),
        name: InvestmentProgramType.DistributionFocusedStrategiesName.valueOf(),
        wipSectionSettings: [
            {
                sectionId: WipSections.FundingDirection,
                disableWipActions: true
            }
        ],
        additionalWipSections: [
            // TODO: MVP2 => Uncomment options to show WIP sections needed for these investment types
            // {
            //     id: WipSections.SeiScheduleDistribution,
            //     changeState: WipChangeState.Incomplete,
            //     required: true,
            //     isOptional: false
            // },
            // {
            //     id: WipSections.PurchaseOutSideStrategy,
            //     changeState: WipChangeState.Incomplete
            // },
            // {
            //     id: WipSections.SeiCashAccessAccount,
            //     changeState: WipChangeState.Incomplete
            // }
        ]
    },
    // {
    //     investmentProgramId: InvestmentProgramType.ETFId.valueOf(),
    //     name: InvestmentProgramType.ETFName.valueOf(),
    //     additionalWipSections: [
    //         {
    //             id: WipSections.SeiScheduleDistribution,
    //             changeState: WipChangeState.Incomplete
    //         },
    //         {
    //             id: WipSections.PurchaseOutSideStrategy,
    //             changeState: WipChangeState.Incomplete
    //         },
    //         {
    //             id: WipSections.SeiCashAccessAccount,
    //             changeState: WipChangeState.Incomplete
    //         }
    //     ]
    // },
    {
        investmentProgramId: InvestmentProgramType.FixedIncomeId.valueOf(),
        name: InvestmentProgramType.FixedIncomeName.valueOf(),
        additionalWipSections: [
            {
                id: WipSections.SeiScheduleDistribution,
                changeState: WipChangeState.Incomplete
            },
            {
                id: WipSections.PurchaseOutSideStrategy,
                changeState: WipChangeState.Incomplete
            },
            {
                id: WipSections.SeiCashAccessAccount,
                changeState: WipChangeState.Incomplete
            }
        ]
    }
];
