/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { FeatureFlagService } from '@CarServices/feature-flag/feature-flag.service';
import { Component, Input } from '@angular/core';
import { DisplayNullOptions, DisplayPercentOptions } from '@sei/common-components-lib-ux';
import { PercentFormatPipe } from 'projects/advisor-client-review-proposal-ux-app/src/app/core/pipes/percent-format.pipe';
import { ManagementFeeBreakdown, ProposalPercentFormat } from '../../model/account-fee';
import { FeeScheduleGroupService } from '../../service/fee-schedule-group.service';


@Component({
    selector: 'sei-car-fee-breakdown-tooltip',
    templateUrl: './fee-breakdown-tooltip.component.html',
    styleUrls: ['./fee-breakdown-tooltip.component.scss'],
    providers: [PercentFormatPipe]
})
export class FeeBreakdownTooltipComponent {
    @Input()
    set managementFeeBreakdown(value: ManagementFeeBreakdown[]) {
        if (value !== undefined && value !== null && value.length > 0) {
            this._managementFeeBreakdown = this.feeScheduleGroupService.filterManagementFeeBreakdown(value);
        } else {
            this._managementFeeBreakdown = [];
        }

    }

    get managementFeeBreakdown(): ManagementFeeBreakdown[] {
        return this._managementFeeBreakdown;
    }

    @Input()
    includeBackground: boolean = false;

    private displayPercentOptions: DisplayPercentOptions = {
        nullDisplay: DisplayNullOptions.ZERO,
        displayPercentSymbol: true,
        roundToDecimal: 3,
        maxDecimalPlaces: 3
    };

    public proposalPercentFormatOptions: ProposalPercentFormat = {
        isFeeBreakdown: true,
        displayPercentOptions: this.displayPercentOptions
    };

    private _managementFeeBreakdown: ManagementFeeBreakdown[];

    constructor(private feeScheduleGroupService: FeeScheduleGroupService,
        private featureFlagService: FeatureFlagService) { }

    public isFeeIconVisible(): boolean {
        return this.feeScheduleGroupService.checkFeeIconVisible(this.managementFeeBreakdown) &&
            this.featureFlagService.isManagementFeeBreakdownEnabled();
    }

    public getDisplayPercent(value: number): number {
        return Number((value * 100).toFixed(4));
    }

}
