/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';

import { select, Store } from '@ngrx/store';

import { Account, AccountGroup } from '@CarInterfaces/account';
import { AccountResponse } from '@CarInterfaces/account-response';
import { RepositoryEndPoint } from '@CarInterfaces/repository-base';
import { AccountStatuses, InvestmentProgramType } from '@CarModels/enums';
import { AccountRepository } from '@CarRepos/account.repository';
import { GenericErrorService } from '@CarServices/system/generic-error.service';
import { GlobalService } from '@CarServices/system/global.service';
import * as _ from 'lodash';
import { BehaviorSubject, EMPTY, noop, Observable, of } from 'rxjs';
import { catchError, filter, map, share, tap } from 'rxjs/operators';
import { UpdateAccount } from './account-store/account.actions';
import { getAccount } from './account-store/account.selectors';

/**
 * Provides services related for the Account and Account Store
 * Along with exposing the Account Group thats maintain with the account
 * Do not confuse this Account Group and the Account Group objects needed for signers and pending accounts
 */
@Injectable({
    providedIn: 'root'
})
export class AccountService {
    private workingAccount: Account;
    private workingAccountGroup: AccountGroup;
    private accountGroupStream: BehaviorSubject<AccountGroup>;
    private accountEndpoints: RepositoryEndPoint;

    private routeAccountNumber: number;

    private get editableStatuses(): AccountStatuses[] {
        return [AccountStatuses.Draft];
    }

    constructor(
        private readonly global: GlobalService,
        private readonly activeRoute: ActivatedRoute,
        private readonly accountRepository: AccountRepository,
        private readonly store: Store<Account>,
        private readonly genericErrorService: GenericErrorService
    ) {
        this.initializeAccountService();
    }

    /**
     * Get the account number
     */
    public get accountNumber(): number {
        return this.routeAccountNumber;
    }

    /**
     * Set account number
     */
    public set accountNumber(value: number) {
        this.routeAccountNumber = value;
    }

    /**
     * Get account from store
     */
    public getAccountStreaming(): Observable<Account> {
        return this.store.pipe(select(getAccount)).pipe(
            share(),
            tap((account: Account) => {
                this.workingAccount = this.validateInvestmentProgram(account);
            })
        );
    }

    /**
     * Updates the Account and AccountStream to new instance of an account
     * Updates the Account Group from Account since this could of came from a new account opening to the group
     * @param updatedAccount instance of account to update too
     */
    public setAccount(updatedAccount: Account) {
        this.workingAccount = updatedAccount;

        if (this.accountNumber !== this.workingAccount.accountNumber) {
            this.accountNumber = this.workingAccount.accountNumber;
        }

        this.store.dispatch(new UpdateAccount({ account: updatedAccount }));

        this.setAccountGroup(updatedAccount.accountGroup);
    }

    /**
     * Returns the current Account Group Stream
     */
    public getAccountGroupStreaming(): Observable<AccountGroup> {
        if (!this.accountGroupStream) {
            return EMPTY;
        }
        return this.accountGroupStream.asObservable();
    }

    /**
     * Updates the Account group object that is part of the account object when an account is requested
     * @param updatedAccountGroup Account Group object to update
     */
    public setAccountGroup(updatedAccountGroup: AccountGroup) {
        if (!this.workingAccountGroup) {
            this.workingAccountGroup = updatedAccountGroup;
        }

        // Need to refresh the group since there
        if (!this.accountGroupStream) {
            this.accountGroupStream = new BehaviorSubject<AccountGroup>(
                this.workingAccountGroup
            );
        } else {
            this.accountGroupStream.next(updatedAccountGroup);
        }
    }

    /**
     * Used to validate and update the investment program description strategy from account
     * when investment program is a custom program.
     */
    public validateInvestmentProgram(account): Account {
        if (!account) {
            return;
        }
        // eslint-disable-next-line @typescript-eslint/tslint/config
        const { investmentProgram = '', firm = '' } = account || {};
        if (
            investmentProgram.investmentProgramId ===
            InvestmentProgramType.CustomFirmMutualFunds
        ) {
            investmentProgram.investmentProgramDescription = `${firm.firmName} Custom Strategies`;
            account = { ...account, investmentProgram };
        }
        return account;
    }

    /**
     * Used to get an account from the store
     * Supporting right from REST for now. Have some work to do on the WIP Submit
     * Since that is not updating the Store just yet
     * Also need to figure out WIP initialize when an account has WIP data
     */
    public fetchAccountStreaming(full: boolean = false): Observable<Account> {
        // Set account endpoints because there are methods that overwrite the accountRepository endpoints
        this.accountRepository.endPoint = this.getAccountEndpoints();

        // If full account object
        if (full) {
            this.accountRepository.endPoint.read = this.global.configService.getEndPoint(
                'accountFetchFull'
            );
        }

        const subscription = this.accountRepository
            .read(this.routeAccountNumber)
            .pipe(
                share(),
                tap((serviceAccount: Account) => {
                    this.setAccount(serviceAccount);
                })
            );

        // Restore Endpoints
        this.accountRepository.endPoint = this.getAccountEndpoints();

        return subscription;
    }

    /**
     * Creates the Account
     * If Group is present it wil inject the group so Account is added to Group
     * Updates the Store with new Account Object
     */
    public createAccount(account: Account): Observable<Account> {
        // Set account endpoints because there are methods that overwrite the accountRepository endpoints
        this.accountRepository.endPoint = this.getAccountEndpoints();

        if (account.accountNumber && account.accountNumber > 0) {
            delete account.accountNumber;
        }

        if (this.workingAccountGroup) {
            account.accountGroup = this.workingAccountGroup;
        }

        return this.accountRepository.create(account).pipe(
            tap((response: Account) => {
                this.setAccount(response);
                this.assignServerAccountData(account, response);
            })
        );
    }

    /**
     * Saves the account object back to REST
     * Updates the Store with updates data
     * @param account Object to update
     * @param httpPost True to use POST, default PUT
     * @param endPoint route overrides to save account object too
     */
    public saveAccount(
        account?: Account,
        httpPost?: boolean,
        endPoint?: RepositoryEndPoint,
        propertyObjectKey?: string
    ): Observable<Account> {
        if (endPoint) {
            this.accountRepository.endPoint = endPoint;
        }

        if (!account) {
            account = this.workingAccount;
        }

        if (httpPost) {
            return this.accountRepository.updatePost(account).pipe(
                tap((response: Account) => {
                    this.updateAccountFromResponse(response, propertyObjectKey);
                    this.assignServerAccountData(account, response);
                })
            );
        } else {
            return this.accountRepository.update(account).pipe(
                tap((response: Account) => {
                    this.updateAccountFromResponse(response, propertyObjectKey);
                    this.assignServerAccountData(account, response);
                })
            );
        }
    }

    public fetchAccountData(
        endPoint?: RepositoryEndPoint
    ): Observable<Account> {
        if (endPoint) {
            this.accountRepository.endPoint = endPoint;
        }

        return this.accountRepository.read(this.accountNumber);
    }

    public deleteWorkingAccount(): Observable<boolean> {
        // Set account endpoints because there are methods that overwrite the accountRepository endpoints
        this.accountRepository.endPoint = this.getAccountEndpoints();

        return this.accountRepository
            .delete(this.workingAccount.accountNumber, this.workingAccount)
            .pipe(
                tap((account: Account) => {
                    if (!account) {
                        this.store.dispatch(
                            new UpdateAccount({ account: undefined })
                        );
                    }
                }),
                map((account: Account) => {
                    if (!account) {
                        return true;
                    }
                    return false;
                }),
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                catchError((error: any) => {
                    this.genericErrorService.setGenericError(error.message);
                    return of(false);
                })
            );
    }

    /**
     * Convenience methods to convert AccountResponse object to CAR Account Object
     * @param accountResponse object to convert
     */
    public convertAccountObject(accountResponse: AccountResponse): Account {
        return { ...{ createDate: new Date() }, ...accountResponse };
    }

    /**
     * Sets a deep or shallow property in the account object using lodash _.set property
     * no key checking. If key exists, it will replace object at that key
     * If key does not exist, it will create property and assign object
     * @param key shallow or deep key
     * @param propertyObject typed object of property value.
     */
    public setAccountProperty<T>(key: string, propertyObject: T) {
        if (!this.workingAccount) {
            throw new Error(
                `No Account Object exists to add ${key}. Please create account first`
            );
        }

        _.set(this.workingAccount, key, propertyObject);
        this.setAccount(this.workingAccount);
    }

    /**
     * Determines if the account is in a state that allows editing.
     * @param account The account to check.
     * @returns True if the account does not have a status, or its state is one that allows editing.
     */
    public accountIsEditable(account: Account): boolean {
        return (
            !account.accountStatus ||
            this.editableStatuses.indexOf(
                account.accountStatus.accountStatusId
            ) > -1
        );
    }

    /**
     * Returns an Account to the Draft state.
     * @param account The Account to make editable.
     * @returns The Account that has been moved back to the Draft state.
     */
    public editAccount(account: Account): Observable<Account> {
        return this.accountRepository.editAccount(account);
    }

    /**
     * Sets the service class with initial values if provided by route.
     * Still have to work on this logic. If the route account is different,
     * then store needs to be updated to the route account
     */
    private initializeAccountService() {
        if (this.activeRoute && this.activeRoute.paramMap) {
            this.activeRoute.paramMap
                .pipe(
                    filter(Boolean),
                    map(
                        (routeMap: ParamMap) =>
                            (this.accountNumber = Number(
                                routeMap.get('accountNumber')
                            ))
                    )
                )
                .subscribe(noop);
        }
    }

    private getAccountEndpoints(): RepositoryEndPoint {
        if (!this.accountEndpoints) {
            this.accountEndpoints = {
                read: this.global.configService.getEndPoint('accountFetch'),
                create: this.global.configService.getEndPoint('accountCreate'),
                update: this.global.configService.getEndPoint('accountSave'),
                delete: this.global.configService.getEndPoint('accountDelete')
            };
        }

        return this.accountEndpoints;
    }

    private updateAccountFromResponse(
        accountResponse: Account,
        propertyObjectKey?: string
    ) {
        if (propertyObjectKey) {
            this.setAccountProperty(
                propertyObjectKey,
                _.get(accountResponse, propertyObjectKey)
            );
        } else {
            this.setAccount(accountResponse);
        }
    }

    private assignServerAccountData(account: Account, response: Account): void {
        account.accountNumber = response.accountNumber;
        account.accountId = response.accountId;
    }
}
