/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { CdkDragDrop, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ChecklistDocument, DocumentSection } from '../../common/proposal-presentation-checklist.interfaces';
import { SidebarItemComponent } from './sidebar-item/sidebar-item.component';

@Component({
    standalone: true,
    selector: 'sei-car-sidebar-item-list',
    templateUrl: './sidebar-item-list.component.html',
    styleUrls: ['./sidebar-item-list.component.scss'],
    imports: [
        SidebarItemComponent,
        CommonModule,
        DragDropModule
    ]
})
export class SidebarItemListComponent {
    @Input()
    public document: ChecklistDocument;

    public collapsed: boolean = false;

    public toggleCollapsed(): void {
        this.collapsed = !this.collapsed;
    }

    drop(event: CdkDragDrop<DocumentSection[]>) {
        if (event.currentIndex === 0) {
            event.currentIndex = 1;
        }
        moveItemInArray(this.document?.sections, event.previousIndex, event.currentIndex);
        this.document?.sections?.forEach((section: DocumentSection, index: number) => section.sortOrder = index);
    }


}
