
/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */


import { Injectable } from '@angular/core';
import { HudSummary, HudFeeSchedule } from '../account-fee';
import { BaseFactoryAbstract } from './base-factory-abstract';
import { AccountFactory } from './account-factory';


@Injectable()
export class HudSummaryFactory extends BaseFactoryAbstract<HudSummary> {

    constructor() {
        super();
    }

    public createObject(): HudSummary {
        return {
            feeBasedAUM: 0,
            investmentCost: { rate: 0, amount: 0 },
            fundExpense: { rate: 0, amount: 0 },
            managementExpense: { rate: 0, amount: 0 },
            dfsFee: { rate: 0, amount: 0 },
            platformFee: { rate: 0, amount: 0 },
            estimatedAdvisorFee: { rate: 0, amount: 0 },
            costToClient: { rate: 0, amount: 0 }
        };

    }
    public createHudFee(): HudFeeSchedule {
        return {
            total: 0,
            min: 0,
            account: new AccountFactory().createObject(1)
        };
    }
}
