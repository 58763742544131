/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { ModuleWithProviders, NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { EnrollmentService } from '@CarServices/enrollment/enrollment.service';
import { PollingService } from '@CarServices/enrollment/polling.service';
import { EnrollmentEffects } from '@CarServices/enrollment/state/enrollment.effects';
import { reducer } from '@CarServices/enrollment/state/enrollment.reducers';
import * as fromEnrollment from '@CarServices/enrollment/state/enrollment.selectors';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
    imports: [
        StoreModule.forFeature(
            fromEnrollment.enrollmentModuleSliceName,
            reducer
        ),
        EffectsModule.forFeature([EnrollmentEffects])
    ]
})
export class EnrollmentModule {
    static forRoot(): ModuleWithProviders<EnrollmentModule> {
        return {
            ngModule: EnrollmentModule,
            providers: [EnrollmentService, PollingService]
        };
    }
}
