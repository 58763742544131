/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
    template: ''
})
export class CoverPageBaseComponent implements AfterViewInit {
    @Input()
    public set clientName(value: string) {
        this._clientName = value;
        this.resizeClientName();
    }

    public get clientName(): string {
        return this._clientName;
    }

    @Input()
    public advisorName: string;

    @Input()
    public firmName: string;

    @Input()
    public advisorEmail: string;

    @Input()
    public advisorPhone: string;

    @Input()
    public verticalBarColorStyle: {};

    @Input()
    public coverTitleData: string;

    @Input()
    public isCoverTitleDataVisible: boolean = true;

    @Input()
    public titleBarColor: string;

    @Input()
    public titleBarTextColor: string;

    @Input()
    public previewImage: string | ArrayBuffer;

    @Input()
    public isCoverImageDataVisible: boolean;

    @Input()
    public coverImage: string | ArrayBuffer;

    @Input()
    public themeColor: string;

    @Input()
    public coverSubtitle: string;

    @Input()
    public showCoverSubtitle: boolean;

    @ViewChild('client', { read: ElementRef })
    private clientNameElement: ElementRef<HTMLElement>;

    public dateNow: number = Date.now();

    private _clientName: string;

    public desiredClientNameHeight: number;

    public ngAfterViewInit(): void {
        this.resizeClientName();
    }

    private resizeClientName(): void {
        if (this.clientNameElement) {
            const baseFontSize: number = 15;
            this.clientNameElement.nativeElement.style.fontSize = `${baseFontSize}px`;
            let currentHeight: number = this.clientNameElement.nativeElement.clientHeight;
            let fontSize: number = parseInt(this.clientNameElement.nativeElement.style.fontSize, 10);
            while (currentHeight > this.desiredClientNameHeight) {
                fontSize--;
                this.clientNameElement.nativeElement.style.fontSize = `${fontSize}px`;
                currentHeight = this.clientNameElement.nativeElement.clientHeight;
            }
        }
    }
}
