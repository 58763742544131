/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, EventEmitter, Input, OnChanges, Output, OnInit } from '@angular/core';
import { RiskLevelClasses, RiskLevels, RiskLevelValues, RiskWidgetTypes } from '../../model/enums';
import { NumberEvaluator } from '../../utilities/number-evaluator.utility';
import { RiskWidget } from '../../model/risk-widget';
import { RiskWidgetFactory } from '../../model/factory/risk-widget-factory';

@Component({
    selector: 'sei-car-risk-summary-widget',
    templateUrl: './risk-summary-widget.component.html'
})

export class RiskSummaryWidgetComponent implements OnChanges, OnInit {
    @Input()
    public clickableSnapPoints: number[];

    @Input()
    public riskToleranceSnapPoint: number;

    @Input()
    public riskRankSnapPoint: number = 0;

    @Output()
    private snapPointSelected = new EventEmitter<number>();

    public riskWidget: RiskWidget[] = [];
    public riskTolerance: string = RiskLevels.None;
    public riskLevelClasses: typeof RiskLevelClasses = RiskLevelClasses;

    private previousRiskLevelSelected: string;

    constructor(private numberEvaluator: NumberEvaluator) {
    }

    ngOnInit(): void {
        this.initRiskWidget();
    }

    ngOnChanges(): void {
        this.initRiskWidget();
    }

    private initRiskWidget(): void {
        this.riskWidget = [];

        const riskLevelLow = Object.assign({}, new RiskWidgetFactory().createRiskLevel(RiskLevels.Low));
        const riskLevelMed = Object.assign({}, new RiskWidgetFactory().createRiskLevel(RiskLevels.Medium));
        const riskLevelHigh = Object.assign({}, new RiskWidgetFactory().createRiskLevel(RiskLevels.High));


        this.setCapacityRiskLevel();

        this.riskWidget.push(riskLevelLow);
        this.riskWidget.push(riskLevelMed);
        this.riskWidget.push(riskLevelHigh);

        this.setSnapPoints();
    }

    private setCapacityRiskLevel(): void {
        this.riskTolerance =
            (this.riskToleranceSnapPoint === null) ? RiskLevelClasses.NoneClass :
                this.numberEvaluator.between(
                    this.riskToleranceSnapPoint,
                    RiskLevelValues.LowMin,
                    RiskLevelValues.LowMax) ? RiskLevelClasses.LowMediumClass :
                    this.numberEvaluator.between(
                        this.riskToleranceSnapPoint,
                        RiskLevelValues.MediumMin,
                        RiskLevelValues.MediumMax) ? RiskLevelClasses.MediumMediumClass :
                        this.numberEvaluator.between(
                            this.riskToleranceSnapPoint,
                            RiskLevelValues.HighMin,
                            RiskLevelValues.HighMax) ? RiskLevelClasses.HighMediumClass : RiskLevelClasses.NoneClass;
    }

    private setSnapPoints(): void {
        if (this.clickableSnapPoints && this.clickableSnapPoints.length > 0) {
            this.riskWidget.forEach(
                (riskLevel: RiskWidget) => {
                    riskLevel.low.enabled = this.clickableSnapPoints.includes(Number(riskLevel.low.classId.replace('_', '')));
                    riskLevel.medium.enabled = this.clickableSnapPoints.includes(Number(riskLevel.medium.classId.replace('_', '')));
                    riskLevel.high.enabled = this.clickableSnapPoints.includes(Number(riskLevel.high.classId.replace('_', '')));

                    if (this.riskRankSnapPoint === Number(riskLevel.low.classId.replace('_', ''))) {
                        riskLevel.low.active = true;
                        riskLevel.active = true;
                    }
                    if (this.riskRankSnapPoint === Number(riskLevel.medium.classId.replace('_', ''))) {
                        riskLevel.medium.active = true;
                        riskLevel.active = true;
                    }
                    if (this.riskRankSnapPoint === Number(riskLevel.high.classId.replace('_', ''))) {
                        riskLevel.high.active = true;
                        riskLevel.active = true;
                    }
                }
            );
        }
        this.previousRiskLevelSelected = `_${this.riskRankSnapPoint}`;
    }

    private resetDisplayValues(): void {
        this.riskWidget.
            forEach((riskLevel: RiskWidget) => {
                riskLevel.active = false;
                riskLevel.low.active = false;
                riskLevel.medium.active = false;
                riskLevel.high.active = false;
            });
    }

    public onSnapPointSelected(riskLevel: string, riskLevelClassSelected: string): void {
        if (this.clickableSnapPoints.length > 1) {
            if (this.previousRiskLevelSelected !== riskLevelClassSelected) {
                this.riskWidget.
                    forEach((riskLevelRow: RiskWidget) => {
                        riskLevelRow.active = riskLevelRow.riskLevel === riskLevel;
                        riskLevelRow.low.active = riskLevelRow.low.classId === riskLevelClassSelected;
                        riskLevelRow.medium.active = riskLevelRow.medium.classId === riskLevelClassSelected;
                        riskLevelRow.high.active = riskLevelRow.high.classId === riskLevelClassSelected;
                    });

                this.previousRiskLevelSelected = riskLevelClassSelected;
            } else {
                this.previousRiskLevelSelected = RiskLevelClasses.NoneClass;
                riskLevelClassSelected = RiskLevelClasses.NoneClass;
                this.resetDisplayValues();
            }

            this.snapPointSelected.emit(
                Number(riskLevelClassSelected.replace('_', ''))
            );
        }
    }
}
