/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

/* eslint-disable */
import { ENVIRONMENT } from '@CarModels/constants';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import 'zone.js';
import { AppModule } from './app/app.module';

if (window.location.href.indexOf('devmode') < 0) {
    // devmode not found in the URL ... we are in prod mode
    enableProdMode();
}

fetch('/assets/env/config.json')
    .then((response) => response.json())
    .then((data) => {
        platformBrowserDynamic([
            { provide: ENVIRONMENT, useValue: data }
        ])
            .bootstrapModule(AppModule, {
                preserveWhitespaces: true
            });
    }).catch((error) => console.error('/assets/env/config.json file is missing'));
