<div class="indent fee-schedule-fee-rate">
    <div class="account-ribbon section-margin account-ribbon-border">
        <select id="fees"
                class="form-control dropdown-webkit-appearance"
                name="fees"
                [(ngModel)]="feeTypeId"
                ngDefaultControl
                (change)="onFeeTypeChange($event)">
            <option *ngFor="let feeType of feesTypes"
                    [value]="feeType.id"
                    [selected]="feeType.id === feeTypeId">{{feeType.description}}</option>
        </select>
    </div>
    <div *ngIf="showFlatFeeView; then isFlatFee; else isFirmFeeSchedule"></div>
    <ng-template #isFlatFee>
        <sei-car-fee-schedule-flat-fee [advisorFee]="advisorFee"
                                       [scenarioId]="scenarioId"
                                       [feeGroup]="feeGroup"
                                       (onCanceFlatFeelEvent)="onCancelFlatFeeEvent()"
                                       (onDoneFeeRibbonEvent)="onDoneFeeRibbonEvent($event)">
        </sei-car-fee-schedule-flat-fee>

    </ng-template>
    <ng-template #isFirmFeeSchedule>
        <sei-car-fee-schedule-firm [advisorFee]="advisorFee"
                                   [scenarioId]="scenarioId"
                                   [feeGroup]="feeGroup"
                                   [totalAccountBalance]="totalAccountBalance"
                                   [scenarioFeeSchedules]="scenarioFeeSchedules"
                                   (onCancelFirmFeeEvent)="onCancelFlatFeeEvent()"
                                   (onDoneFeeRibbonEvent)="onDoneFeeRibbonEvent($event)">
        </sei-car-fee-schedule-firm>
    </ng-template>
</div>
