/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, Input } from '@angular/core';
import { SubpageheaderModel } from '@sei/common-swp-components-lib-ux';
import { Portfolio } from '@sei/ias-applications-lib-ux';
import { ProposalAccountService } from '../../service/proposal-account.service';

@Component({
    selector: 'car-wip-checklist-non-models',
    templateUrl: './wip-checklist-non-models.component.html',
    styleUrls: ['./wip-checklist-non-models.component.scss']
})
export class WipChecklistNonModelsComponent {

    @Input()
    public portfolios: Portfolio[] = [];

    @Input()
    public isPortfolioAssetDataLoading: boolean = false;

    public showNonModelInvestmentDetails: boolean = true;

    public readonly SHOW_MORE_MESSAGE: string = 'Use the arrow to expand the section details';
    public headingLevel: number = 5;
    public nonModelPageHeaderProperties: SubpageheaderModel = { title: 'Non Model Investments', switcherAvailable: false };

    constructor(private proposalAccountService: ProposalAccountService) {
    }

    public calculateNonModelInvestmentTotal(portfolios: Portfolio[]): number {
        return this.proposalAccountService.calculateNonModelInvestmentTotal(portfolios);
    }

    public calculateValueOfNonModelPortfolio(portfolio: Portfolio): number {
        return this.proposalAccountService.calculateValueOfNonModelPortfolio(portfolio);
    }

    public toggleShowNonModelInvestmentDetails() {
        this.showNonModelInvestmentDetails = !this.showNonModelInvestmentDetails;
    }
}
