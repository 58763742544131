/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { Orientation } from '../common/proposal-presentation-checklist.enums';
import { ProposalPresentationChecklistService } from '../common/proposal-presentation-checklist.service';
import { ContentAreaPaginationComponent } from './content-area-pagination/content-area-pagination.component';

@Component({
    standalone: true,
    selector: 'sei-car-proposal-presentation-content-area',
    templateUrl: './proposal-presentation-content-area.component.html',
    styleUrls: ['./proposal-presentation-content-area.component.scss'],
    imports: [
        CommonModule,
        ContentAreaPaginationComponent
    ]
})
export class ProposalPresentationContentAreaComponent extends ParentComponentSubscriptionManager {
    public orientation: Orientation;

    constructor(private proposalPresentationChecklistService: ProposalPresentationChecklistService) {
        super('ProposalPresentationContentAreaComponent');
        this.subscriptions.push(
            this.proposalPresentationChecklistService.selectedOrientation.subscribe((orientation: Orientation) => {
                this.orientation = orientation;
            })
        );
    }
}
