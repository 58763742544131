/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { CommonModule } from '@CarComponents/common/common.module';
import { NgModule } from '@angular/core';
import { WipPowerAttorneyAddressComponent } from './wip-power-attorney-address/wip-power-attorney-address.component';
import { WipPowerAttorneyAgentComponent } from './wip-power-attorney-agent/wip-power-attorney-agent.component';
import { WipPowerAttorneyMainComponent } from './wip-power-attorney-main/wip-power-attorney-main.component';
import { WipPowerAttorneyParentComponent } from './wip-power-attorney-parent/wip-power-attorney-parent.component';
import { WipPowerAttorneyPowersComponent } from './wip-power-attorney-powers/wip-power-attorney-powers.component';
import { WipPowerAttorneyRegulatoryComponent } from './wip-power-attorney-regulatory/wip-power-attorney-regulatory.component';

@NgModule({
    declarations: [
        WipPowerAttorneyMainComponent,
        WipPowerAttorneyAgentComponent,
        WipPowerAttorneyAddressComponent,
        WipPowerAttorneyRegulatoryComponent,
        WipPowerAttorneyPowersComponent,
        WipPowerAttorneyParentComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        WipPowerAttorneyMainComponent,
        WipPowerAttorneyAgentComponent,
        WipPowerAttorneyAddressComponent,
        WipPowerAttorneyRegulatoryComponent,
        WipPowerAttorneyPowersComponent,
        WipPowerAttorneyParentComponent
    ]
})
export class PowerOfAttorneyModule { }
