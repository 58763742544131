/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
/* eslint-disable @typescript-eslint/no-shadow */
import { AccountGoalFactory } from '@CarFactories/account-goal-factory';
import { AccountDetailFactory } from './account-detail-factory';
import { AccountMrdcFactory } from './account-mrdc-factory';
import { AccountOwnerDetailFactory } from './account-owner-detail-factory';
import { BeneficiaryFactory } from './beneficiary-factory';
import { DelegationFactory } from './delegation.factory';
import { FundingSourceFactory } from './funding-source-factory';
import { RelatedPartyFactory } from './related-party-factory';
import { ScheduledWithdrawalsFactory } from './scheduled-withdrawals-factory';

export enum Factories {
    AccountMrdc,
    AccountOwnerDetail,
    FundingSource,
    RelatedParty,
    AccountGoalFactory,
    AccountDetailFactory,
    DelegationFactory,
    BeneficiaryFactory,
    ScheduledWithdrawalsFactory
}

export class ObjectFactory {
    public static createObject(
        factory: Factories,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        type?: any,
        id?: number
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): any {
        switch (factory) {
            case Factories.AccountMrdc:
                return new AccountMrdcFactory().createObject();
            case Factories.AccountOwnerDetail:
                return new AccountOwnerDetailFactory().createObject(type, id);
            case Factories.FundingSource:
                return new FundingSourceFactory().createObject(type, id);
            case Factories.RelatedParty:
                return new RelatedPartyFactory().createObject(type);
            case Factories.AccountGoalFactory:
                return new AccountGoalFactory().createObject(type);
            case Factories.DelegationFactory:
                return new DelegationFactory().createObject(id);
            // Note to Miguel - AccountGoalFactory should be removed. Use REST to create Goal JSON object
            case Factories.AccountDetailFactory:
                return new AccountDetailFactory().createObject();
            case Factories.BeneficiaryFactory:
                return new BeneficiaryFactory().createObject();
            case Factories.ScheduledWithdrawalsFactory:
                return new ScheduledWithdrawalsFactory().createObject();
            default:
                return null;
        }
    }
}
