<div class="toggle-row indent">
    <div class="toggle-row-left indent">
        <h3>Document Creation</h3>
    </div>
</div>
<div class="document-settings">
    <div class="document-setting"><img src="../../../../assets/guide/images/icons/icon-pdf-doc.svg" alt="pdf doc">
        <h4>Single<br>
            Document</h4>
        <p>1 PDF</p>
        <input type="radio" name="documentSetting" [disabled]="'true'" value="1" [checked]="documentSelection===1"
            (change)="onChange(1)" />
    </div>
    <div class="document-setting"><img src="../../../../assets/guide/images/icons/icon-pdf-doc.svg" alt="pdf doc">
        <h4>Seperate<br>
            Documents</h4>
        <p>4 PDFs</p>
        <input type="radio" name="documentSetting" value="2" [checked]="documentSelection===2" (change)="onChange(2)" />
    </div>
    <div class="document-setting"><img src="../../../../assets/guide/images/icons/icon-pdf-doc.svg" alt="pdf doc">
        <h4>Single Document<br>
            Per Scenario</h4>
        <p>2 PDFs</p>
        <input type="radio" name="documentSetting" [disabled]="'true'" value="3" [checked]="documentSelection===3"
            (change)="onChange(3)" />
    </div>
</div>