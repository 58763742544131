<ng-container *ngFor="let section of proposalSection.sections; let sectionIndex = index;">
    <div class="row toggle-row" [ngSwitch]="section.name">
        <div class="col-xs-7 toggle-row-left indent2">
            <p>{{section.name}} <a *ngIf="section.name==='Show Advisor Fee?'" (click)="onFeesNavigateClick()">Adjust
                    your fees<i class="far fa-arrow-alt-circle-right"></i></a></p>
        </div>
        <div class="col-xs-5 toggle-row-center">
            <div class="toggle-wizard-nav" id="proposal-toggle-{{sectionIndex}}">
                <sei-car-toggle-include-exclude *ngSwitchCase="'Reporting Level'"
                    [isDisabled]="section.isToggleDisabled" [wizardSteps]="reportingLevelWizardModel"
                    [value]="section.include"
                    (onChangeEvent)="onReportingLevelToggleChange($event)"></sei-car-toggle-include-exclude>
                <sei-car-toggle-sei-wizard-nav *ngSwitchDefault
                    [(ngModel)]="section.include"></sei-car-toggle-sei-wizard-nav>
            </div>
        </div>
    </div>
    <!-- sub section -->
    <div class="row toggle-row" *ngFor="let subLevelSection of section.sections; let subSectionIndex = index;"
        [ngSwitch]="subLevelSection.name">
        <div class="col-xs-8 toggle-row-left indent3">
            <p>{{subLevelSection.name}}</p>
        </div>
        <div class="col-xs-3 toggle-row-center">
            <div class="toggle-wizard-nav" id="proposal-toggle-{{subSectionIndex}}">
                <sei-car-html-form-elements *ngIf="subLevelSection.name === proposalSectionLabel.ReportingMethod"
                    [proposalSection]="subLevelSection" [dropDownContent]="reportingMethods"
                    [selectWidth]="'55'"></sei-car-html-form-elements>
            </div>
        </div>
        <div class="col-xs-1">
            <!--empty to align previous column-->
        </div>
    </div>
</ng-container>