/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { ToggleButton } from '../../common/toggle-button/toggle-button';
import { FeeSchedule } from '../../model/account-fee';
import { FeeScheduleTieredBanded, FeeScheduleTypes } from '../../model/enums';
import { AdvisorFeeFactory } from '../../model/factory/advisor-fee-factory';

@Component({
    selector: 'sei-car-fee-schedule-tier',
    templateUrl: './fee-schedule-tier.component.html'
})
export class FeeScheduleTierComponent extends ParentComponentSubscriptionManager {

    @Input() set feeSchedules(value: FeeSchedule[]) {
        this._feeSchedules = value;
        this._feeSchedules.sort((a, b) => a.slab - b.slab);
    }

    get feeSchedules(): FeeSchedule[] {
        return this._feeSchedules;
    }

    @Input()
    public chargeInputView: boolean;
    @Input()
    public feeScheduleType: number;
    @Input()
    public feeGroupId: number;

    private _feeSchedules: FeeSchedule[];

    @Output()
    public onFeeScheduleTierChange: EventEmitter<FeeSchedule[]> = new EventEmitter();
    @Output()
    public onFeeScheduleToggleChange: EventEmitter<number> = new EventEmitter();

    public scheduleSetting: typeof FeeScheduleTypes = FeeScheduleTypes;

    public toggleTierOptions: Array<ToggleButton<FeeScheduleTypes>> = [
        {
            label: FeeScheduleTieredBanded.Tiered,
            data: FeeScheduleTypes.Tiered
        },
        {
            label: FeeScheduleTieredBanded.Banded,
            data: FeeScheduleTypes.Banded
        }
    ];

    public constructor() {
        super('FeeScheduleTierComponent');
    }

    private reorderTierIndexes(): void {
        this.feeSchedules.forEach((feeSchedule, index) => {
            feeSchedule.index = index;
            feeSchedule.slab = index + 1;
        });
    }

    public onAddTierButtonClick(): void {
        this.feeSchedules.forEach((tier) => {
            tier.isValid = tier.highValue > tier.lowValue;
        });

        const newFeeSchedule = new AdvisorFeeFactory().creteFeeSchedule();
        newFeeSchedule.lowValue = this.feeSchedules[this.feeSchedules.length - 2].highValue + 1;

        this.feeSchedules[this.feeSchedules.length - 1].lowValue = 0;
        this.feeSchedules.splice(this.feeSchedules.length - 1, 0, newFeeSchedule);
        this.reorderTierIndexes();
        this.onFeeScheduleTierChange.emit(this.feeSchedules);
    }

    public updateFeeSchedulesOnchange(event): void {
        if (this.feeSchedules.length > 0) {
            this.feeSchedules[event.index].highValue = event.highValue;
            if (this.feeSchedules[event.index + 1] && this.feeSchedules[event.index].highValue > 0) {
                this.feeSchedules[event.index + 1].lowValue = event.highValue;
            }
        }
        this.onFeeScheduleTierChange.emit(this.feeSchedules);
    }


    public deleteTier(index): void {
        if (index === this.feeSchedules.length - 2) {
            this.feeSchedules[index + 1].lowValue = this.feeSchedules[index - 1] ?
                this.feeSchedules[index - 1].highValue :
                this.feeSchedules[index].highValue;

        } else {
            this.feeSchedules[index + 1].lowValue = this.feeSchedules[index - 1] ?
                this.feeSchedules[index - 1].highValue + 1 :
                this.feeSchedules[index].lowValue;
        }

        this.feeSchedules.splice(index, 1);
        this.reorderTierIndexes();
        this.onFeeScheduleTierChange.emit(this.feeSchedules);

    }
    public onToggleFeeSchedule(event): void {
        this.onFeeScheduleToggleChange.emit(event);
    }
}
