/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { ActionTags } from '../enums';
import { Account, RiskTolerance } from '../proposal';
import { BaseFactoryAbstract } from './base-factory-abstract';

@Injectable()
export class AccountFactory extends BaseFactoryAbstract<Account> {

    constructor() {
        super();
    }

    public createObject(accountId: number): Account {
        return {
            id: accountId,
            type: {
                id: 0,
                description: '',
                sortOrder: 0,
                taxQualified: false,
                qualifiedPlan: false
            },
            custodian: '',
            name: '',
            balance: 0,
            status: 0,
            riskCapacity: {
                fillQuestionnaire: false,
                clientEmail: '',
                clientGreeting: '',
                score: 0
            },
            riskTolerance: {
                calculatedQuestionnaireRisk: null,
                fillQuestionnaire: false,
                riskMethod: null,
                selectedLowMedHighRisk: null,
                clientEmail: '',
                clientGreeting: '',
                questionnaire: []
            },
            owners: [],
            advisors: [],
            actionTag: {
                id: ActionTags.New,
                description: 'New'
            },
            strategies: [],
            // Note: This is for existing SEI accounts can be Maintain or Change;
            // For Non-SEI accounts Maintain, Partial Transition, Full Transition
            // For New SEI accounts default to New
            isSEIAccount: true,
            copyToProposed: false,
            sweepModelElection: {
                isFdic: false,
                platformSweepModelId: 0,
                currencySweepModelAllocDescription: ''
            }
        };
    }

    public createRiskTolerance(): RiskTolerance {
        return {
            calculatedQuestionnaireRisk: null,
            fillQuestionnaire: false,
            riskMethod: null,
            selectedLowMedHighRisk: null,
            clientEmail: '',
            clientGreeting: '',
            questionnaire: []
        };
    }
}


