<div class="yes-no-toggle-button btn-group"
     data-toggle="buttons">
    <label *ngFor="let toggleOption of toggleOptions"
           class="btn car-yesno-btn-toggle"
           [class.active]="isEqual(toggleOption.data)"
           [class.control-highlight]="disabled"
           (click)="clickToggleOption(toggleOption.data)">
        <input type="radio"
               name="optradio">
        {{ toggleOption.label }}
    </label>
</div>
