/*
Copyright: This information constitutes the exclusive property of SEI
Investments Company, and constitutes the confidential and proprietary
information of SEI Investments Company.  The information shall not be
used or disclosed for any purpose without the written consent of SEI
Investments Company.
*/
import { AfterViewInit, Compiler, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '@CarAuth/auth.service';
import { EnvironmentMode } from '@CarModels/enums';
import { ExternalKeepAliveService } from '@CarServices/keep-alive/external-keep-alive-service';
import { GlobalService } from '@CarServices/system/global.service';
import { SpinnerService } from '@CarServices/system/spinner.service';
import { Store } from '@ngrx/store';
import { LoadingOverlayStyle } from '@sei/common-components-lib-ux';
import { CountdownTimerComponent, Global, KassoComponent, ParentComponentSubscriptionManager, SeiCommonAppStore, TimerEvent } from '@sei/common-swp-components-lib-ux';
import { IASCommonAppStore, SpinnerSlice } from '@sei/ias-applications-lib-ux';
import { interval, Observable, Subscription } from 'rxjs';
import { skipWhile } from 'rxjs/operators';

@Component({
    selector: 'car-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent extends ParentComponentSubscriptionManager implements OnDestroy, AfterViewInit, OnInit {
    private static LOGOFF_BUTTON_ID = 'sei-kasso-default-logoff-ok-btn';

    public firstAuthCompleted: boolean = false;
    private devEnvironmentTag = 'DEV';
    public enableKasso: boolean = true;
    private userRecentActivity: boolean = false;
    public appName: string;
    public brandId: string;
    public showOverlayLoader: boolean;
    readonly loaderStyle: LoadingOverlayStyle = LoadingOverlayStyle.Translucent;

    @ViewChild(KassoComponent, { static: false })
    kassoComponent: KassoComponent;

    logOffPopupButton: ElementRef;
    countdownTimer: CountdownTimerComponent;

    // Monitor for user events, when triggered, the active flag will be passed to the heartbeat
    @HostListener('window:keypress')
    @HostListener('window:click')
    @HostListener('window:scroll')
    onUserActivity(): void {
        this.userRecentActivity = true;
    }

    subscriptions: Subscription[] = [];

    public get logOffUrl(): string {
        return this.authService.logOffUrl;
    }

    constructor(
        public readonly global: Global,
        private readonly compiler: Compiler,
        private readonly carGlobal: GlobalService,
        private readonly spinnerService: SpinnerService,
        private readonly authService: AuthService,
        private readonly externalKeepAliveService: ExternalKeepAliveService,
        private appStore: Store<SeiCommonAppStore>
    ) {

        super('car-root');
        // clear angular's html template cache for cache busting
        this.compiler.clearCache();
        this.carGlobal.startup();

        this.appName = this.carGlobal.configService.environment.authConfig.kassoAppName;

        if (!this.appName || !this.logOffUrl) {
            throw new Error(
                'KASSO Application Name not supplied in configuration.'
            );
        }
        this.setLoaderState();
    }

    public ngOnInit(): void {
        const sessionSubscriptionObservable: Observable<number> = interval(30000);
        this.subscriptions.push(sessionSubscriptionObservable
            .subscribe((): void => {
                this.handleSessionCheck();
            }));
    }

    public ngAfterViewInit(): void {
        document.getElementById(AppComponent.LOGOFF_BUTTON_ID).onclick = () => this.externalLogoutOnClick(this.externalKeepAliveService);
        this.kassoComponent.countdownTimer.onComplete.subscribe({
            next(value: TimerEvent) {
                if (TimerEvent.COMPLETE === value) {
                    this.externalKeepAliveService.notifyExternalLogout();
                }
            }
        });
    }

    private externalLogoutOnClick(externalKeepAliveService: ExternalKeepAliveService): void {
        externalKeepAliveService.notifyExternalLogout();
    }

    private setLoaderState(): void {
        this.subscriptions.push(this.appStore.select((slices: IASCommonAppStore) =>
            slices.spinnerSlice)
            .pipe(skipWhile((value: SpinnerSlice) => this.global.isEmpty(value)))
            .subscribe((overLayLoader: SpinnerSlice) => {
                this.showOverlayLoader = overLayLoader.isOverlayLoaderVisible;
            }));
    }

    private handleSessionCheck() {
        if (this.userRecentActivity && this.carGlobal.configService.environment.mode !== EnvironmentMode.Dev) {
            this.externalKeepAliveService.notifyExternalKeepAlive();
            this.userRecentActivity = false;
        }
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription): void => subscription.unsubscribe());
    }
}
