/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@CarComponents/common/common.module';
import { AccountDetailFactory } from '@CarFactories/account-detail-factory';
import { CheckOptionDynamicSectionComponent } from './check-option-dynamic-section.component/check-option-dynamic-section.component';
import { DetailDividendCapitalSectionComponent } from './detail-dividend-capital-section/detail-dividend-capital-section.component';
import { DynamicSectionComponent } from './dynamic-section/dynamic-section.component';
import { FatcaSectionComponent } from './fatca-section/fatca-section.component';
import { InvestorTaxRateSectionComponent } from './investor-tax-rate-section/investor-tax-rate-section.component';
import { PayToRecipientSectionComponent } from './pay-to-recipient-section/pay-to-recipient-section.component';
import { PaymentOfAdvisorFeesSectionComponent } from './payment-of-advisor-fees-section/payment-of-advisor-fees-section.component';
import { WipAccountDetailsMainComponent } from './wip-account-details-main/wip-account-details-main.component';
import { ContributionDetailsSectionComponent } from './contribution-details-section/contribution-details-section.component';

@NgModule({
    imports: [CommonModule],
    declarations: [
        WipAccountDetailsMainComponent,
        DetailDividendCapitalSectionComponent,
        InvestorTaxRateSectionComponent,
        PayToRecipientSectionComponent,
        FatcaSectionComponent,
        DynamicSectionComponent,
        PaymentOfAdvisorFeesSectionComponent,
        CheckOptionDynamicSectionComponent,
        ContributionDetailsSectionComponent
    ],
    providers: [AccountDetailFactory],
    exports: [WipAccountDetailsMainComponent]
})
export class WipAccountDetailsModule {}
