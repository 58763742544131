<div id="presentation-preview"
     class="container">
    <div class="row center pager-row"
         [hidden]="proposalSection?.name === proposalSectionLabel.CoverPage && !isLoading">
        <div class="col-xs-12">
            <sei-car-navigator [currentIndex]="currentIndex"
                               [totalItems]="totalItems"
                               (previous)="previousClicked($event)"
                               (next)="nextClicked($event)"
                               (currentIndexChange)="currentIndexChange($event)">
            </sei-car-navigator>
        </div>
    </div>
    <div class="pres-prev animated fadeIn"
         #mainScreen
         (window:resize)="calculatePreviewScale()">
        <sei-loading class="loadingSpinner center"
                     [showLoadingIcon]="isLoading"
                     [multiplier]="5">
        </sei-loading>
        <div *ngIf="proposalSection?.name === proposalSectionLabel.CoverPage">
            <div [ngSwitch]="getTheme(proposalSection)"
                 [class.cover-page]="COVER_PAGE_THEME_IDS.Custom !== getTheme(proposalSection)"
                 #coverPage>
                <sei-car-cover-page-custom *ngSwitchCase="COVER_PAGE_THEME_IDS.Custom"
                                           [clientName]="pdfCheckList.proposalDisplayProperty.clientName"
                                           [advisorName]="pdfCheckList.proposalDisplayProperty.advisorName"
                                           [firmName]="pdfCheckList.proposalDisplayProperty.firmName"
                                           [advisorEmail]="pdfCheckList.proposalDisplayProperty.advisorEmail"
                                           [advisorPhone]="pdfCheckList.proposalDisplayProperty.advisorPhone"
                                           [verticalBarColorStyle]="verticalBarColorStyle"
                                           [coverTitleData]="coverTitleData?.inputProperty.value"
                                           [isCoverTitleDataVisible]="isCoverTitleDataVisible"
                                           [titleBarColor]="titleBarColorData?.inputProperty.value"
                                           [titleBarTextColor]="titleBarTextColorData?.inputProperty.value"
                                           [previewImage]="previewImage"
                                           [isCoverImageDataVisible]="isCoverImageDataVisible"
                                           [coverImage]="coverImage"></sei-car-cover-page-custom>
                <sei-car-cover-page-simplistic *ngSwitchCase="COVER_PAGE_THEME_IDS.Simplistic"
                                               [clientName]="pdfCheckList.proposalDisplayProperty.clientName"
                                               [advisorName]="pdfCheckList.proposalDisplayProperty.advisorName"
                                               [firmName]="pdfCheckList.proposalDisplayProperty.firmName"
                                               [advisorEmail]="pdfCheckList.proposalDisplayProperty.advisorEmail"
                                               [advisorPhone]="pdfCheckList.proposalDisplayProperty.advisorPhone"
                                               [verticalBarColorStyle]="verticalBarColorStyle"
                                               [coverTitleData]="coverTitleData?.inputProperty.value"
                                               [isCoverTitleDataVisible]="isCoverTitleDataVisible"
                                               [titleBarColor]="titleBarColorData?.inputProperty.value"
                                               [titleBarTextColor]="titleBarTextColorData?.inputProperty.value"
                                               [previewImage]="previewImage"
                                               [isCoverImageDataVisible]="isCoverImageDataVisible"
                                               [coverImage]="coverImage"
                                               [coverSubtitle]="coverSubtitle?.inputProperty?.value"
                                               [themeColor]="themeColor?.inputProperty?.value"
                                               [showCoverSubtitle]="showCoverSubtitle"></sei-car-cover-page-simplistic>
                <sei-car-cover-page-abstract *ngSwitchCase="COVER_PAGE_THEME_IDS.Abstract"
                                             [clientName]="pdfCheckList.proposalDisplayProperty.clientName"
                                             [advisorName]="pdfCheckList.proposalDisplayProperty.advisorName"
                                             [firmName]="pdfCheckList.proposalDisplayProperty.firmName"
                                             [advisorEmail]="pdfCheckList.proposalDisplayProperty.advisorEmail"
                                             [advisorPhone]="pdfCheckList.proposalDisplayProperty.advisorPhone"
                                             [verticalBarColorStyle]="verticalBarColorStyle"
                                             [coverTitleData]="coverTitleData?.inputProperty.value"
                                             [isCoverTitleDataVisible]="isCoverTitleDataVisible"
                                             [titleBarColor]="titleBarColorData?.inputProperty.value"
                                             [titleBarTextColor]="titleBarTextColorData?.inputProperty.value"
                                             [previewImage]="previewImage"
                                             [isCoverImageDataVisible]="isCoverImageDataVisible"
                                             [coverImage]="coverImage"
                                             [coverSubtitle]="coverSubtitle?.inputProperty?.value"
                                             [themeColor]="themeColor?.inputProperty?.value"
                                             [showCoverSubtitle]="showCoverSubtitle"></sei-car-cover-page-abstract>
            </div>
        </div>
        <section *ngIf="!isLoading && proposalSection?.name !== proposalSectionLabel.CoverPage"
                 id="non-cover-page"
                 class="us-letter-display">
            <div *ngIf="presentationImage && !isLoading"
                 id="backgroundImage"
                 class="background-image-variable"
                 style="background-position: unset;height: inherit;"
                 [ngStyle]="{'background-image': !isImageLoading ? 'url(' + (imageToShow) +  ')' : '', 'background-repeat': 'no-repeat'} ">
            </div>

        </section>
    </div>
    <div class="row center pager-row"
         [hidden]="proposalSection?.name === proposalSectionLabel.CoverPage && !isLoading">
        <div class="col-xs-12">
            <sei-car-navigator [currentIndex]="currentIndex"
                               [totalItems]="totalItems"
                               (previous)="previousClicked($event)"
                               (next)="nextClicked($event)"
                               (currentIndexChange)="currentIndexChange($event)">
            </sei-car-navigator>
        </div>
    </div>
</div>
