<form role="form" #clientForm="ngForm">
    <div class="proposalClientsForm row">
        <div class="col-xs-12">
            <h2>Client</h2>
        </div>
    </div>

    <div class="form-group" *ngIf="shouldDisplayClientForm">
        <div class="indent-1" *ngFor="let client of proposal.clients; let i = index;" [ngModelGroup]="i">
            <div *ngIf="this.proposal.clients.length > 1" class="row">
                <div class="col-xs-6 text-left">
                    <p>Client {{i+1}}</p>
                </div>
                <div *ngIf="!isEditProposal" [hidden]="!(this.proposal.clients.length > 1)"
                    class="col-xs-6 text-right delete-icon">
                    <i (click)=deleteClient(i) class="fas fa-trash-alt"></i>
                </div>
                <div *ngIf="isEditProposal" class="col-xs-6 text-right delete-icon">
                    <i (click)=setCurrentClientToDelete(i) containerClass="action-tooltip-popover"
                        [class.disabled]="client.isAccountOwner" placement="left" container="body"
                        class="fas fa-trash-alt" [isOpen]="showDeleteConfirmPopover"
                        [popover]="confirm_delete_client_tooltip_template" [outsideClick]="true"
                        #popConfirm="bs-popover"></i>
                </div>
            </div>
            <div [class.form-margin]="!(this.proposal.clients.length > 1)" class="row">
                <div class="col-xs-6 text-left">
                    <p>Individual or Organization?</p>
                </div>
                <div class="col-xs-6 text-right">
                    <car-toggle-button [toggleOptions]="toggleOptions" [selectedValue]="client.contactType.id === contactTypes.Individual
                                       ? this.toggleOptions[0].data
                                       : this.toggleOptions[1].data" (onChange)="onToggleChange($event, i)"
                        [disabled]="!client.isNewClient">
                    </car-toggle-button>
                </div>
            </div>
            <div class="row" [hidden]="!client.isIndividual">
                <div class="col-xs-4">
                    <label for="firstName">First Name</label>
                    <input type="text" class="form-control" [(ngModel)]="client.firstName"
                        (ngModelChange)="modelChanged()" #firstName="ngModel" name="firstName" required minlength="2"
                        [ngClass]="{'is-invalid': this.submitted && firstName.invalid }"
                        [disabled]="!client.isNewClient">
                </div>
                <div class="col-xs-2">
                    <label for="middleInitial">MI</label>
                    <input type="text" class="form-control" name="middleInitial" id="middleInitial" maxlength="1"
                        [(ngModel)]="client.middleInitial" [disabled]="!client.isNewClient">
                </div>
                <div class="col-xs-4">
                    <label for="lastName">Last Name</label>
                    <input type="text" class="form-control" [(ngModel)]="client.lastName"
                        (ngModelChange)="modelChanged()" #lastName="ngModel" name="lastName" id="lastName" required
                        minlength="2" [ngClass]="{'is-invalid': this.submitted && lastName.invalid}"
                        [disabled]="!client.isNewClient">
                </div>
                <div class="col-xs-2">
                    <label for="clientSuffix">Suffix</label>
                    <select id="clientSuffix" class="form-control" name="suffix" [(ngModel)]="client.entitySuffix"
                        [disabled]="!client.isNewClient">
                        <option *ngFor="let item of suffix" [ngValue]="item">{{item.suffixName}}</option>
                    </select>
                </div>
            </div>
            <div class="row" [hidden]="client.isIndividual">
                <div class="col-xs-12">
                    <label for="organizationName">Organization Name</label>
                    <input type="text" class="form-control" [(ngModel)]="client.organizationName"
                        (ngModelChange)="modelChanged()" #organizationName="ngModel" name="organizationName"
                        id="organizationName" required minlength="2"
                        [ngClass]="{'is-invalid': this.submitted && lastName.invalid}" [disabled]="!client.isNewClient">
                </div>
            </div>
        </div>
        <br />
    </div>
    <!-- Client dropdown -->
    <div class="row" *ngIf="showClientAdditionDropDown">
        <div class="col-xs-12 form-group">
            <div class="row" *ngIf="shouldDisplayClientForm">
                <div class="col-xs-12">
                    Client
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <sei-car-entity-search [firmId]="proposal?.firm?.swpFirmId" [contactType]="contactType.id"
                        [controlType]="elasticControlTypes.Clients" [placeholder]="'Search Clients or Households'"
                        (onEntitySelect)="onEntitySelect($event)" (onNewEntityName)="onNewEntityName($event)"
                        (onError)="onElasticSearchError($event)"></sei-car-entity-search>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="shouldDisplayClientForm">
        <div class="col-xs-12 indent-1">
            <button type="button" class="btn add-content unselectable button-gap" [disabled]="disabledAddClient"
                (click)="onAddClientButtonClick()">
                Add Client
            </button>
        </div>
    </div>
</form>

<ng-template #confirm_delete_client_tooltip_template>
    <div class="delete-tooltip-panel">
        <div class="row">
            <div class="col-xs-12">
                <h3> Delete Client? </h3>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 car-main-sm-body-copy form-group delete-text">
                Are you sure you wish to delete this client form the proposal, you cannot undo this action?
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 car-main-sm-body-copy form-group">
                <sei-button class="btn-in-tooltip single-button" buttonText="Delete"
                    buttonRightFontAwesomeIcon="far fa-trash-alt trash-button-icon" buttonClass="btn-default"
                    (buttonClicked)="onConfirmDeleteClientClick()">
                </sei-button>
            </div>
        </div>
    </div>
</ng-template>
