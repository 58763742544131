/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalService } from '../service/system/global.service';
import { Contact } from '../model/contact';
import { BaseRepository } from './base-repository';

@Injectable({
    providedIn: 'root'
})
export class ContactRepository extends BaseRepository<Contact> {
    constructor(protected global: GlobalService) {
        super(global);
        this.returnDataKey = 'contact';
        this.endPoint = {
            read: this.global.configService.getEndPoint('contacts')
        };
    }

    /**
     * Gets the Elastic Search Related Party object from REST for a given entity Id
     * @param Id Entity Id to get
     */
    public read(id: number | string): Observable<Contact> {
        this.global.logger.log(
            `Contact object object requested from carWebService-Contacts: ${id.toString()}`
        );

        return this.global.httpUtilityService.doGet<Contact>(
            this.global.configService.routeFormatter(this.endPoint.read, {
                key: 'contactId',
                value: id
            }),
            undefined,
            {
                returnDataKey: this.returnDataKey
            }
        );
    }
}
