/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { ExistingAccountData } from '@CarModels/existing-accounts';
import { ExistingAccountsService } from '@CarServices/existing-accounts/existing-accounts.service';
import { FeatureFlagService } from '@CarServices/feature-flag/feature-flag.service';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DateHelperService, FormatDateConstants, ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import * as _ from 'lodash';
import { Subscription, catchError, take, throwError } from 'rxjs';
import { CommandText, UserLevelEntitlement, WipAccountStatus, WipChecklistLabels } from '../../model/enums';
import { Account, Proposal, Scenario, WipCheckList } from '../../model/proposal';
import { DelegationService } from '../../service/delegation.service';
import { ProposalAccountService } from '../../service/proposal-account.service';
import { ProposalService } from '../../service/proposal.service';
import { UserProfileService } from '../../service/user-profile.service';

@Component({
    selector: 'car-wip-checklist-account-types',
    templateUrl: './wip-checklist-account-types.component.html',
    styleUrls: ['./wip-checklist-account-types.component.scss']
})
export class WipChecklistAccountTypesComponent extends ParentComponentSubscriptionManager implements OnInit {
    public wipAccountStatus: typeof WipAccountStatus = WipAccountStatus;
    public sectionLabels: typeof WipChecklistLabels = WipChecklistLabels;
    public showStart: boolean = true;
    public startButtonDisabled: boolean = false;

    @Input()
    public wipCheckList: WipCheckList[];

    @Input()
    public existingAccountsWipCheckList: WipCheckList[];

    @Input()
    public sectionType: string;

    @Input()
    public accountTypeLabel: string;

    @Input()
    public proposalId: number;

    @Input()
    public checkListId: number;

    @Input()
    public set proposal(value: Proposal) {
        this._proposal = value;
    }

    public get proposal(): Proposal {
        return this._proposal;
    }

    public proposalBackup: Proposal;

    public isPortfolioAssetDataLoading: boolean;

    public totalBalance: number;

    public sectionHidden: boolean = false;
    public proposedSectionHidden: boolean = false;
    public existingSectionHidden: boolean = false;
    public isClientCallInProgress: boolean = false;
    public dateTimeForPipe: Date | string;
    public format: string = FormatDateConstants.USER_FORMAT_DATE;
    public userTimezone: string;
    private scenarioId: number;
    private currentScenario: Scenario;
    private isNonModelFeatureEnabled: boolean = false;
    private firmId: number;
    private _proposal: Proposal;

    constructor(
        private router: Router,
        private featureFlagService: FeatureFlagService,
        private proposalAccountService: ProposalAccountService,
        private proposalService: ProposalService,
        private existingAccountsService: ExistingAccountsService,
        private dateHelperService: DateHelperService,
        private userProfileService: UserProfileService,
        private delegationService: DelegationService
    ) {
        super('WipChecklistAccountTypesComponent');
        this.userTimezone = this.dateHelperService.getUserSystemTimezone();
    }

    ngOnInit(): void {
        this.showStart = this.proposal?.scenarios[0]?.accounts.length < 1;
        this.scenarioId = this.proposal.scenarios[0].id;
        this.currentScenario = this.proposal?.scenarios[0];
        this.startButtonDisabled = false;
        this.isNonModelFeatureEnabled = this.featureFlagService.isNonModelFeatureEnabled();
        const nonModelLoadingSubscription: Subscription =
            this.proposalAccountService.isPortfolioCallInProgress.subscribe((portfolioLoadingStatus: boolean): void => {
                this.isPortfolioAssetDataLoading = portfolioLoadingStatus;
            });
        this.subscriptions.push(nonModelLoadingSubscription);
        this.subscriptions.push(this.existingAccountsService.isExistingAccountCallInProgress.subscribe((isLoading: boolean) => {
            this.isClientCallInProgress = isLoading;
        }));
        this.retrieveClientExistingAccounts(false);
        this.subscriptions.push(this.existingAccountsService.existingAccountsAsOfDate.subscribe((asOfDate: string) => {
            this.dateTimeForPipe = asOfDate;
        }));

        this.subscriptions.push(this.existingAccountsService.existingAccounts
            .subscribe((accountMap: Map<number, ExistingAccountData[]>) => {
                const allExistingAccounts: ExistingAccountData[] =
                    this.existingAccountsService.convertExistingAccountsMapToArray(accountMap);
                const selectedExistingAccounts: ExistingAccountData[] = allExistingAccounts.
                    filter((existingAccount: ExistingAccountData) => this.proposal.scenarios[0].accounts
                        .some((account: Account) => account?.currentAccountId === existingAccount?.accountId));
                this.existingAccountsService.existingAccountsToBeAddedToProposal.next(selectedExistingAccounts);

            })
        );
        this.subscriptions.push(this.delegationService.refresh().subscribe((commandText: CommandText) => {
            switch (commandText) {
                case CommandText.AccountsFlyoutCancel:
                    this.startButtonDisabled = false;
                    this.proposal = _.cloneDeep(this.proposalBackup);
                    this.currentScenario = this.proposal?.scenarios[0];
                    this.existingAccountsService.removeExistingAccountsNotInProposal(this.proposal);
                    this.existingAccountsService.addExistingAccountsFromProposal(this.proposal);
                    break;
                case CommandText.AddOwnerToProposal:
                    this.retrieveClientExistingAccounts(true);
            }
        }));

    }

    public isSectionHidden(): void {
        this.sectionHidden = !this.sectionHidden;
    }

    public isProposedSectionHidden(): void {
        this.proposedSectionHidden = !this.proposedSectionHidden;
    }

    public isExistingSectionHidden(): void {
        this.existingSectionHidden = !this.existingSectionHidden;
    }

    public navigateToProposedAccounts(): void {
        this.startButtonDisabled = true;
        this.proposalBackup = _.cloneDeep(this.proposal);
        this.router.navigate([`/Proposal/WIP/${this.proposalId}/Scenarios/${this.scenarioId}/ProposedAccounts`]);
    }

    public getTotalBalance(): number {
        return this.proposalService.getTotalBalanceOfAllAccounts(this.currentScenario, this.isNonModelFeatureEnabled);
    }

    public isCurrentAccountsEnabled(): boolean {
        return this.featureFlagService.checkExistingAccountsSectionEnabled();
    }

    public hasCurrentAccounts(): boolean {
        return this.proposal?.scenarios[0]?.accounts?.some((account: Account) => account?.currentAccountId);
    }

    public hasProposedAccounts(): boolean {
        return this.proposal?.scenarios[0]?.accounts?.some((account: Account) => !account?.currentAccountId);
    }


    private retrieveClientExistingAccounts(forceRefresh: boolean = false): void {
        if (!this.isClientCallInProgress) {
            this.getFirmId();
            const scenarioId: number = this.proposal?.scenarios[0]?.id;
            const clientIds: number[] = this.existingAccountsService.retrieveClientIdListFromProposal(this.proposal);
            const primaryAdvisorId: number =
                Number(this.proposal?.scenarios[0]?.accounts[0]?.advisors[0]?.entityId || this.proposal?.advisors[0]?.entityId);
            if (clientIds.length > 0) {
                // This subscription is not added to this.subscriptions to avoid unsubscribing when navigating away from component.
                // take(1) will unsubscribe after the subscription sends a single response.
                this.existingAccountsService
                    .retrieveExistingAccountsByIdList(clientIds, this.firmId, primaryAdvisorId,
                        scenarioId, this.proposal.id, forceRefresh, this.proposal)
                    .pipe(take(1),
                        catchError(() => {
                            this.existingAccountsService.isExistingAccountCallInProgress.next(false);
                            this.existingAccountsService.existingAccountCallFailed.next(true);
                            return throwError(() => new Error('Error Retrieving Clients Existing Accounts'));
                        }))
                    .subscribe(() => {
                        this.existingAccountsService.removeUnusedClientAccounts(this.proposal.clients);
                        this.existingAccountsService.existingAccountCallFailed.next(false);
                    });
            }
        }
    }

    private getFirmId(): void {
        const userProfile = this.userProfileService.getCurrentUserProfile;
        const isInstanceUser: boolean = userProfile.entitlements.userLevelId === UserLevelEntitlement.PO ||
            userProfile.entitlements.userLevelId === UserLevelEntitlement.Instance;
        isInstanceUser ? this.firmId = userProfile.firm.swpFirmId : this.firmId = this.userProfileService.firm.swpFirmId;
    }

}
