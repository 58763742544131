/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { CommonModule } from '@CarComponents/common/common.module';
import { NgModule } from '@angular/core';
import { WipRelatedPartiesComponent } from './wip-related-parties-main/wip-related-parties-main.component';
import { WipRelatedPartyAddressComponent } from './wip-related-party-address/wip-related-party-address.component';
import { WipRelatedPartyDetailComponent } from './wip-related-party-detail/wip-related-party-detail.component';
import { WipRelatedPartyIndividualComponent } from './wip-related-party-individual/wip-related-party-individual.component';
import { WipRelatedPartySelectorComponent } from './wip-related-party-selector/wip-related-party-selector.component';

@NgModule({
    imports: [CommonModule
    ],
    declarations: [
        WipRelatedPartiesComponent,
        WipRelatedPartyIndividualComponent,
        WipRelatedPartyAddressComponent,
        WipRelatedPartySelectorComponent,
        WipRelatedPartyDetailComponent
    ],
    providers: [],
    exports: [
        WipRelatedPartiesComponent,
        WipRelatedPartyIndividualComponent,
        WipRelatedPartyAddressComponent
    ]
})
export class WipRelatedPartiesModule { }
