/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { Factories, ObjectFactory } from '@CarFactories/object-factory';
import { createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { InitializeAccount } from './account.actions';

@Injectable()
export class AccountEffects {

    initStream = createEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return of(
            new InitializeAccount(
                ObjectFactory.createObject(Factories.AccountMrdc)
            )
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ) as any;
    });
}
