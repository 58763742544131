/*
 Copyright: This information constitutes the exclusive property of SEI
 Investments Company, and constitutes the confidential and proprietary
 information of SEI Investments Company.  The information shall not be
 used or disclosed for any purpose without the written consent of SEI
 Investments Company.
*/

import { Blob } from 'buffer';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from '@CarServices/system/global.service';
import { Observable, map, throwError } from 'rxjs';
import { ContentTypes } from 'projects/advisor-client-review-proposal-ux/src/lib/model/enums';
import { FeatureFlagService } from '@CarServices/feature-flag/feature-flag.service';

@Component({
    selector: 'sei-car-playwright-pdf-viewer',
    templateUrl: './playwright-pdf-viewer.component.html',
    styleUrls: ['./playwright-pdf-viewer.scss']
})
export class PlaywrightPdfViewerComponent implements OnInit {

    public maxNumberOfProposalPages: number = 10;
    public maxNumberOfIpsPages: number = 18;
    public pdfDocument: ArrayBuffer;
    private proposalId: string;
    private fileName: string;
    private documentType: string;
    public pageNumber: number = 1;

    constructor(private readonly route: ActivatedRoute,
        private carGlobal: GlobalService,
        private http: HttpClient,
        private router: Router,
        private featureFlagService: FeatureFlagService) {}

    ngOnInit(): void {
        if (!this.featureFlagService.isPlaywrightPdfViewerEnabled()) {
            this.router.navigate(['Proposal/Dashboard/']);
        }
        this.proposalId = this.route.snapshot.params.proposalId;
        this.documentType = this.route.snapshot.params.documentType;
        this.fileName = this.generateFileName(this.proposalId);
        this.getPresentationCheckListPdfDocument(Number(this.proposalId), this.fileName).subscribe((pdfAsBlob) => {
            pdfAsBlob.arrayBuffer().then((pdfAsArrayBuffer: ArrayBuffer) => {
                this.pdfDocument = pdfAsArrayBuffer;
            });
        });
    }

    private getPresentationCheckListPdfDocument(proposalId: number, fileName: string): Observable<Blob> {

        try {
            const apiUrl: string = this.carGlobal.configService.routeFormatter(
                this.carGlobal.configService.getEndPoint('previewPresentationPdf'),
                [
                    {
                        key: 'proposalId',
                        value: proposalId
                    },
                    {
                        key: 'fileName',
                        value: fileName
                    }
                ]
            );

            return this.http.get<Blob>(apiUrl, {
                headers: new HttpHeaders({ 'Content-Type': ContentTypes.Pdf }),
                responseType: 'blob' as 'json'
            }).pipe(
                map((objectResponse: Blob) => {
                    return objectResponse;
                })
            );
        } catch (error) {
            return throwError(() => {
                throw new Error(error);
            });
        }
    }

    private generateFileName(proposalId): string {
        if (this.documentType.toLowerCase() === 'proposal') {
            return `${proposalId}_Playwright_Test_Proposal.pdf`;
        } else {
            return `${proposalId}_Playwright_Test_IPS.pdf`;
        }
    }

    public incrementPageNumber(): void {
        if (this.documentType.toLowerCase() === 'proposal') {
            if (this.pageNumber < this.maxNumberOfProposalPages) {
                this.pageNumber++;
            }
        } else {
            if (this.pageNumber < this.maxNumberOfIpsPages) {
                this.pageNumber++;
            }
        }
    }

    public decrementPageNumber(): void{
        if (this.pageNumber > 1) {
            this.pageNumber--;
        }

    }
}
