<div class="row toggle-row">
    <div class="col-xs-9">
        <span *ngIf="!disableDragDrop"
              cdkDragHandle
              class="wip-menu-grabber">
            <i class="fa fas fa-grip-vertical"></i>
        </span>
        <h2>{{group.name}}</h2>
    </div>
    <div class="col-xs-2">
        <div class="toggle-wizard-nav"
             id="proposal-toggle-{{groupIndex}}">
            <sei-car-toggle-sei-wizard-nav *ngIf="group.toggle"
                                           (onChangeEvent)="parentToggleChange($event, group)"
                                           [(ngModel)]="group.include"></sei-car-toggle-sei-wizard-nav>
        </div>
    </div>
    <div class="col-xs-1">
        <span class="wip-menu-icon menu-caret"
              (click)="chevronClick(group)"
              [style.z-index]="'unset'">
            <i *ngIf="proposalSectionLabel.SeiStrategyDetails !== group.name"
               [class.fa-angle-right]="group.isCollapsed"
               [class.fa-angle-down]="!group.isCollapsed"
               class="fa fas"></i>
        </span>
    </div>
</div>
