/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BannerMessageConstants, ImplementProposalConstants } from '@CarModels/constants';
import { ImplementModalState } from '@CarModels/enums';
import { AccountTypesService } from '@CarServices/account/account-types.service';
import { ExistingAccountsService } from '@CarServices/existing-accounts/existing-accounts.service';
import { FeatureFlagService } from '@CarServices/feature-flag/feature-flag.service';
import { ImplementModalService } from '@CarServices/implement-modal/implement-modal.service';
import { ReactivateModalService } from '@CarServices/reactivate-modal/reactivate-modal.service';
import { PropertyService } from '@CarServices/system/property.service';
import { SpinnerService } from '@CarServices/system/spinner.service';
import { Account, CommandText, DelegationService, EnrichedAccountAndFeesResponse, GenericErrorService, GlobalService, ImplementedAccountResponse, ImplementProposalOptions, MinDataProposal, PostProposalImplementRedirectActions, Proposal, ProposalAccountService, ProposalReactivateResponse, ProposalResolverService, ProposalService, ProposalStatusId, ProposalStatusResponse, ToolTipLabels, ToolTipOptions, ToolTipOptionsFactory, UserLevelEntitlement, UserProfileService, ValidationMessage, WipCheckList, WipChecklistLabels } from '@sei/advisor-client-review-proposal-ux';
import { BannerMessageType, LoadingOverlayStyle, ModalWindowComponent } from '@sei/common-components-lib-ux';
import { DateHelperService, FormatDateConstants, ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import * as _ from 'lodash';
import { forkJoin, Observable, Subscription, throwError } from 'rxjs';
import { catchError, share, take, tap } from 'rxjs/operators';


@Component({
    selector: 'car-wip-checklist-component',
    templateUrl: './wip-checklist.component.html',
    styleUrls: ['./wip-checklist.component.scss']
})
export class WipCheckListComponent extends ParentComponentSubscriptionManager implements OnInit, OnDestroy {
    public proposal: Proposal;
    public enablePresentationButton: boolean = false;
    public showOptionsPopover: boolean = false;
    public showEditMode: boolean = false;
    public proposalSubscription: Subscription;
    public showImplementModal: boolean = false;
    public bannerMessageTypes: typeof BannerMessageType = BannerMessageType;
    public isReadUser: boolean = false;
    public resolverActivity: boolean = false;
    public minDataProposal: MinDataProposal;
    public isProposalGenerated: boolean = false;
    public proposalAccountStatuses: ProposalStatusResponse;
    public styleInput: LoadingOverlayStyle = LoadingOverlayStyle.Translucent;
    public showReactivateModal: boolean = false;
    public showRefreshBanner: boolean = false;
    public dateTimeForPipe: Date | string;
    public format: string = FormatDateConstants.USER_FORMAT_DATE;
    public userTimezone: string;
    @ViewChild('refreshModal')
    public refreshModalcomponent: ModalWindowComponent;
    public today: Date = new Date();

    public toolTipOptions: ToolTipOptions[] = [];

    public infoType: BannerMessageType = BannerMessageType.info;

    public isDisplayCurrentAccountRefreshBanner: boolean = false;

    private enrichedAccounts: Account[];
    private portfolioSubscription: Subscription;
    private lastSeenProposalId: Number;
    private firmId: number;
    private delegationServiceSubscription: Subscription;
    private disableRefreshBannerDelay: boolean = false;

    constructor(
        private router: Router,
        private proposalService: ProposalService,
        private delegationService: DelegationService,
        private spinnerService: SpinnerService,
        private proposalResolver: ProposalResolverService,
        private genericErrorService: GenericErrorService,
        private propertyService: PropertyService,
        private userProfileService: UserProfileService,
        private globalService: GlobalService,
        private accountTypeService: AccountTypesService,
        private proposalAccountService: ProposalAccountService,
        private implementModalService: ImplementModalService,
        private featureFlagService: FeatureFlagService,
        private reactivateModalService: ReactivateModalService,
        private route: ActivatedRoute,
        private existingAccountsService: ExistingAccountsService,
        private dateHelperService: DateHelperService
    ) {
        super('WipCheckListComponent');
    }

    ngOnInit(): void {
        this.proposalResolver.isLoading.subscribe((isLoadingFlag) => {
            this.resolverActivity = isLoadingFlag;
        });
        this.disableRefreshBannerDelay = this.featureFlagService.isDisableRefreshBannerDelay();
        this.isReadUser = this.userProfileService.isReadUser;
        this.showImplementModal = false;
        this.subscriptions.push(
            this.proposalService.currentProposal
                .pipe(tap(() => this.spinnerService.start()))
                .subscribe({
                    next: (response) => {
                        if (response) {
                            if (this.proposal) {
                                this.lastSeenProposalId = this.proposal.id;
                            }
                            this.proposal = response;
                            this.subscriptions.push(this.accountTypeService.getAccountTypes(this.proposal?.advisors[0]?.entityId ||
                                this.proposal.scenarios[0]?.accounts[0]?.advisors[0]?.entityId).subscribe());
                            if (this.featureFlagService.isNonModelFeatureEnabled() && !this.enrichedAccounts) {
                                this.getNonModelAssetDataAndFees();
                            }
                            this.spinnerService.stop();
                        }
                    },
                    error: (error) => {
                        this.genericErrorService.setGenericError(error);
                        this.spinnerService.stop();
                    }
                })
        );
        if (!this.delegationServiceSubscription) {
            this.delegationServiceSubscription = this.delegationService.refresh().subscribe((data: string) => {
                if (data === CommandText.ImplementProposal) {
                    this.spinnerService.start();
                    this.proposalService.getMinDataProposalById(this.proposal.id).subscribe(
                        (proposal) => {
                            this.minDataProposal = proposal;
                            this.showImplementModal = true;
                            this.spinnerService.stop();
                            this.isProposalGenerated = this.proposal.statusId === ProposalStatusId.Generated;
                        },
                        (error) => {
                            this.spinnerService.stop();
                        });
                    this.delegationService.clearPublishedData();
                } else if (data === CommandText.UpdateWipCheckList) {
                    if (this.featureFlagService.isNonModelFeatureEnabled()) {
                        this.getNonModelAssetDataAndFees();
                    }
                }
            }
            );
            this.subscriptions.push(this.delegationServiceSubscription);
        }


        this.subscriptions.push(
            this.proposalService.getProposalChangedEvent().subscribe((proposal) => {
                if (this.lastSeenProposalId &&
                    this.lastSeenProposalId !== proposal.id &&
                    this.featureFlagService.isNonModelFeatureEnabled()) {
                    this.getNonModelAssetDataAndFees();
                }
            })
        );

        this.toolTipOptions = [
            new ToolTipOptionsFactory().createOption(ToolTipLabels.Rename),
            new ToolTipOptionsFactory().createOption(
                ToolTipLabels.EditProposal
            ),
            new ToolTipOptionsFactory().createRemoveOption(
                ToolTipLabels.RemoveProposal,
                ToolTipLabels.RemoveProposalTitle,
                ToolTipLabels.RemoveProposalBody
            )
        ];

        this.subscriptions.push(this.implementModalService.getRefreshDashboardBehaviorSubject().subscribe((value: boolean) => {
            if (value) {
                this.router.navigate(['Proposal/Dashboard']);
            }
        }));

        this.subscriptions.push(this.existingAccountsService.existingAccountsAsOfDate.subscribe((date: string) => {
            if (date) {
                const asOfDate: Date = new Date(date);
                const sameDay: boolean = asOfDate.getFullYear() === this.today.getFullYear() &&
                    asOfDate.getMonth() === this.today.getMonth() &&
                    asOfDate.getDate() === this.today.getDate();

                if (!sameDay || this.disableRefreshBannerDelay) {
                    this.isDisplayCurrentAccountRefreshBanner = true;
                    this.dateTimeForPipe = date;
                } else {
                    this.isDisplayCurrentAccountRefreshBanner = false;
                }

            } else {
                this.isDisplayCurrentAccountRefreshBanner = false;
            }
        }));
        this.getFirmId();
        this.userTimezone = this.dateHelperService.getUserSystemTimezone();
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
        this.existingAccountsService.resetExistingAccounts();
    }

    public onOptionSelected(event: ToolTipLabels, proposalId: number): void {
        switch (event) {
            case ToolTipLabels.Rename:
                this.showEditMode = true;
                break;
            case ToolTipLabels.EditProposal:
                this.goToEditProposal(proposalId);
                break;
            case ToolTipLabels.RemoveProposal:
                this.proposalService.deleteProposal(proposalId).subscribe(() => {
                    this.router.navigate(['Proposal/Dashboard']);
                });
                break;
        }
    }

    public onEditionConfirmed(event: string, proposalId: number): void {
        this.showEditMode = false;
        this.spinnerService.start();
        this.proposalService.editProposalName(proposalId, event)
            .subscribe((name: string) => {
                this.proposal.name = name;
                const scenarioWipIndex: number = this.proposal?.wipCheckList.findIndex(
                    (wipChecklist: WipCheckList): boolean => wipChecklist.sectionType === WipChecklistLabels.ProposalScenario
                );
                if (this.propertyService.exists(() => scenarioWipIndex) && scenarioWipIndex !== -1) {
                    this.proposal.wipCheckList[scenarioWipIndex].name = name;
                }
                this.proposal.scenarios[0].name = name;
                this.proposalService.updateProposalWipCheckList(this.proposal.wipCheckList).subscribe();
                this.proposalService.changedProposal(this.proposal);
                this.spinnerService.stop();
            }
            );
    }

    public goToEditProposal(proposalId: number): void {
        const scenarioId: number = this.proposal?.scenarios[0]?.id;
        this.router.navigate([`Proposal/WIP/${proposalId}/Scenarios/${scenarioId}`]);
    }

    public cancel() {
        this.showImplementModal = false;
    }

    public implement(event: {
        id: number;
        redirectAction: PostProposalImplementRedirectActions;
        implementProposalOptions: ImplementProposalOptions;
    }) {
        const carConfig = this.globalService.configService.environment.carConfig;
        const hostApplicationRedirect: string = `${carConfig.transportProtocol}://${carConfig.server}`;
        const newAccountProxyPath: string = `${carConfig.newAccountProxyGroup}`;
        if (event && !this.featureFlagService.isDAOIntegrationEnabled()) {
            this.proposalService.implementProposal(event.id).subscribe(() => {
                if (
                    event.redirectAction === PostProposalImplementRedirectActions.Dashboard
                ) {
                    this.router.navigate(['Proposal/Dashboard']);
                } else if (
                    event.redirectAction === PostProposalImplementRedirectActions.Account
                ) {
                    window.location.href = `${hostApplicationRedirect}/${newAccountProxyPath}/#/PendingAccounts/NewAccount`;
                } else if (
                    event.redirectAction === PostProposalImplementRedirectActions.SWP
                ) {
                    window.location.href = `${hostApplicationRedirect}/ADE/#/dashboard`;
                }
                this.implementModalService.setIsLoading(false);
            });
        } else {
            switch (event.redirectAction) {
                case PostProposalImplementRedirectActions.Dashboard:
                    this.proposalService.archiveProposal(event.id).subscribe(
                        () => {
                            this.implementModalService.setIsLoading(false);
                            this.router.navigate(['Proposal/Dashboard']);
                        }
                    );
                    break;
                case PostProposalImplementRedirectActions.Account:
                    this.implementModalService.createAccountsInDAO(event.implementProposalOptions).subscribe(
                        (response: ImplementedAccountResponse) => {
                            const validationErrors = [];
                            response.validationMessages?.forEach((validationMessage: ValidationMessage) => {
                                validationErrors.push(validationMessage.validationMessage);
                            });
                            const success: boolean = validationErrors?.length === 0;
                            if (success) {
                                this.implementModalService.setValidationErrors([]);
                                this.implementModalService.setSuccessfulAccountStatuses(response?.proposalAccountStatuses);
                                this.implementModalService.updateModalState(ImplementModalState.ConfirmationPage);
                                this.implementModalService.resetErrorCount();
                            } else {
                                this.implementModalService.setValidationErrors(validationErrors);
                                this.implementModalService.updateModalState(ImplementModalState.ImplementForm);
                                this.implementModalService.addErrorCount();
                            }
                        },
                        (error) => {
                            this.implementModalService.addErrorCount();
                            this.implementModalService.hasExceededMaxErrors() ?
                                this.implementModalService
                                    .setValidationErrors([ImplementProposalConstants.IMPLEMENT_SERVICE_MAX_ERROR_MESSAGE]) :
                                this.implementModalService
                                    .setValidationErrors([ImplementProposalConstants.GENERIC_IMPLEMENT_SERVICE_ERROR_MESSAGE]);
                            this.implementModalService.updateModalState(ImplementModalState.ImplementForm);
                            this.implementModalService.setIsLoading(false);
                        },
                        () => {
                            this.implementModalService.setIsLoading(false);
                        });
                    break;
            }
        }
    }

    public showAdvisorChangeNotification(proposal: Proposal): boolean {
        return proposal.advisorChangeFlag && proposal.advisorChangeFlag === true;
    }

    public isChecklistLocked(): boolean {
        return this.proposalService.isProposalChecklistLocked();
    }

    public getLockedProposalAlertMessage(): string {
        return BannerMessageConstants.PROPOSAL_LOCKED_INFO_MESSAGE;
    }

    public onReactivateClick(): void {
        if (!this.isReadUser) {
            if (!this.featureFlagService.isDAOIntegrationEnabled()) {
                if (confirm('Are you sure you want to reactivate this proposal?')) {
                    this.proposalService.reactivateProposal(this.proposal.id).subscribe(
                        () => {
                            this.proposalResolver.resolve(this.route.snapshot);
                            this.ngOnInit();
                        },
                        (error) =>
                            this.genericErrorService.setGenericError({
                                code: '',
                                description: error
                            })
                    );
                }
            } else {
                this.resetReactivateModalState();
                this.spinnerService.start();
                const minDataProposalSubscription: Observable<MinDataProposal> =
                    this.proposalService.getMinDataProposalById(this.proposal.id);
                const retrievePendingAccountStatusesSubscription: Observable<ProposalStatusResponse> =
                    this.reactivateModalService.retrieveProposalPendingAccountStatuses(this.proposal.id);
                this.subscriptions.push(
                    forkJoin({
                        statuses: retrievePendingAccountStatusesSubscription,
                        minDataProposal: minDataProposalSubscription
                    }).subscribe((response: { statuses: ProposalStatusResponse; minDataProposal: MinDataProposal }) => {
                        this.minDataProposal = response.minDataProposal;
                        this.proposalAccountStatuses = response.statuses;
                        this.showReactivateModal = true;
                        this.spinnerService.stop();
                    }, (error) => {
                        this.spinnerService.stop();
                    })
                );
            }

        }
    }

    public submitReactivation(proposalId: number): void {
        this.spinnerService.start();
        this.reactivateModalService.deleteProposalPendingAccountsAndReactivateProposal(proposalId)
            .subscribe((response: ProposalReactivateResponse) => {
                this.spinnerService.stop();
                if (response.success) {
                    this.showReactivateModal = false;
                    this.resetReactivateModalState();
                    this.proposalResolver.resolve(this.route.snapshot);
                    this.ngOnInit();
                } else {
                    this.proposalAccountStatuses.proposalAccountStatuses = response.proposalAccounts;
                    this.proposalAccountStatuses.eligibleForReactivation = response?.reactivationErrors?.length > 0;
                }
            }, (error) => {
                this.spinnerService.stop();
            });
    }

    public onReactivateModalClosed(): void {
        this.resetReactivateModalState();
        this.showReactivateModal = false;
    }

    public onRefreshClick(): void {
        this.refreshModalcomponent.showModalBox();
    }

    public onRefreshConfirm(): void {
        // Implement Confirm Functionality
        this.retrieveClientExistingAccounts(true);
        this.refreshModalcomponent.hideModalBox();
    }

    public onRefreshCancel(): void {
        this.refreshModalcomponent.hideModalBox();
    }

    private getFirmId(): void {
        const userProfile = this.userProfileService.getCurrentUserProfile;
        const isInstanceUser: boolean = userProfile.entitlements.userLevelId === UserLevelEntitlement.PO ||
            userProfile.entitlements.userLevelId === UserLevelEntitlement.Instance;
        isInstanceUser ? this.firmId = userProfile.firm.swpFirmId : this.firmId = this.userProfileService.firm.swpFirmId;
    }

    private retrieveClientExistingAccounts(refreshChecklist?: boolean): void {
        const scenarioId: number = this.proposal?.scenarios[0]?.id;
        const clientIds: number[] = this.existingAccountsService.retrieveClientIdListFromProposal(this.proposal);
        const primaryAdvisorId: number =
            Number(this.proposal?.scenarios[0]?.accounts[0]?.advisors[0]?.entityId || this.proposal?.advisors[0]?.entityId);
        if (clientIds.length > 0) {
            // This subscription is not added to this.subscriptions to avoid unsubscribing when navigating away from component.
            // take(1) will unsubscribe after the subscription sends a single response.
            this.existingAccountsService
                .retrieveExistingAccountsByIdList(clientIds,
                    this.firmId, primaryAdvisorId, scenarioId, this.proposal.id, true, this.proposal)
                .pipe(take(1),
                    share(),
                    catchError(() => {
                        this.existingAccountsService.isExistingAccountCallInProgress.next(false);
                        this.existingAccountsService.existingAccountCallFailed.next(true);
                        return throwError(() => new Error('Error Retrieving Clients Existing Accounts'));
                    }))
                .subscribe(() => {
                    this.existingAccountsService.removeUnusedClientAccounts(this.proposal.clients);
                    this.existingAccountsService.existingAccountCallFailed.next(false);
                    this.existingAccountsService.existingAccountsAsOfDate.next(undefined);
                    this.proposalService
                        .updateProposalScenario(this.proposal, this.proposal?.scenarios[0]?.id)
                            .subscribe((proposalWithUpdatedCurrentAccountSnapshots: Proposal) => {
                                if (proposalWithUpdatedCurrentAccountSnapshots) {
                                    this.proposalService.changedProposal(proposalWithUpdatedCurrentAccountSnapshots);
                                    if (refreshChecklist) {
                                        this.delegationService.publish(CommandText.UpdateWipCheckList);
                                        this.proposalResolver.resolve(this.route.snapshot);
                                        this.ngOnInit();
                                    }
                                }
                        });
                });
        }
    }

    private resetReactivateModalState(): void {
        this.minDataProposal = undefined;
        this.proposalAccountStatuses = undefined;
    }

    private getNonModelAssetDataAndFees(): void {
        if (this.portfolioSubscription && !this.proposalSubscription?.closed) {
            this.portfolioSubscription.unsubscribe();
        }
        if (this.proposal && this.proposal.id > 0) {
            this.portfolioSubscription = this.proposalAccountService.getEnrichedAccountFees(this.proposal)
                .pipe(take(1), share())
                .subscribe({
                    next: (response: EnrichedAccountAndFeesResponse) => {
                        this.enrichedAccounts = response.accountList;
                        this.proposal = this.proposalService.getCurrentProposal();
                        this.enrichedAccounts.forEach((enrichedAccount: Account) => {
                            const accountInProposal: Account = this.proposal.scenarios[0].accounts
                                .find((account: Account) => account.id === enrichedAccount.id);
                            if (accountInProposal) {
                                accountInProposal.portfolios = enrichedAccount.portfolios;
                            }
                        });
                        this.proposalService.changedProposal(this.proposal);
                        this.proposalService.proposalBackup ? this.proposalService.proposalBackup = _.cloneDeep(this.proposal) : undefined;
                    },
                    error: () => {
                        this.proposalAccountService.isPortfolioCallInProgress.next(false);
                    },
                    complete: () => {
                        this.spinnerService.stop();
                    }
                });
            this.subscriptions.push(this.portfolioSubscription);
        }

    }
}
