/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { StoreModule } from '@ngrx/store';

import { AccountMrdcFactory } from '@CarFactories/account-mrdc-factory';
import { AccountOwnerDetailFactory } from '@CarFactories/account-owner-detail-factory';
import { ObjectFactory } from '@CarFactories/object-factory';
import { AccountRepository } from '@CarRepos/account.repository';
import { ContactRepository } from '@CarRepos/contact.repository';
import { ElasticSearchRepository } from '@CarRepos/elastic-search-repository';
import { InvestmentProgramRepository } from '@CarRepos/investment-program.repository';
import { UserProfileRepository } from '@CarRepos/user-profile-repository';
import { GlobalService } from '@CarServices/system/global.service';
import { LoggerService } from '@CarServices/system/logger.service';
import { UserProfileService } from '@CarServices/system/user-profile.service';
import { CommonModule as AngularCommon, CurrencyPipe, DatePipe, PercentPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OrderByPipe } from '@sei/advisor-client-review-proposal-ux';
import { ClientAccountAdministrationLibUxModule } from '@sei/client-account-administration-lib-ux';
import { SeiCommonComponentsLibraryModule } from '@sei/common-components-lib-ux';
import { SeiCommonSwpComponentsLibraryModule } from '@sei/common-swp-components-lib-ux';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { FileDropDirective } from '../../core/directives/file-drop.directive';
import { TinFormatterDirective } from '../../core/directives/tin-formatter/tin-formatter.directive';
import { ShortNumberPipe } from '../../core/pipes/short-number.pipe';
import { AltSourceImageComponent } from './alt-source-image/alt-source-image.component';
import { AssetComponent } from './asset/asset.component';
import { ButtonRiskComponent } from './button-risk/button-risk.component';
import { CheckAddressComponent } from './check-address/check-address.component';
import { CheckBoxComponent } from './check-box/check-box.component';
import { CreateAccountNumberButtonComponent } from './create-account-number-button/create-account-number-button.component';
import { DatePickerInputComponent } from './date-picker-input/date-picker-input.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { DeleteAccountComponent } from './delete-account/delete-account.component';
import { AssetDropdownComponent } from './drop-down-type-ahead/asset-dropdown/asset-dropdown.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { EntitySearchComponent } from './entity-search/entity-search.component';
import { FileUploadBoxComponent } from './file-upload/file-upload-box/file-upload-box.component';
import { FileUploadListComponent } from './file-upload/file-upload-list/file-upload-list.component';
import { FileUploadModalBodyComponent } from './file-upload/file-upload-modal-body/file-upload-modal-body.component';
import { FileUploadModalFooterComponent } from './file-upload/file-upload-modal-footer/file-upload-modal-footer.component';
import { FileUploadModalPanelComponent } from './file-upload/file-upload-modal-panel/file-upload-modal-panel.component';
import { FileUploadSummaryComponent } from './file-upload/file-upload-summary/file-upload-summary.component';
import { GenericErrorNotificationComponent } from './generic-error-notification/generic-error-notification.component';
import { NavigationTabMenuComponent } from './navigation-tab-menu/navigation-tab-menu.component';
import { OptionSelectionActionsComponent } from './option-selection-actions/option-selection-actions.component';
import { OptionSelectionComponent } from './option-selection/option-selection.component';
import { PageTemplateComponent } from './page-template/page-template.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { RightHandDialogFooterComponent } from './right-hand-dialog-footer/right-hand-dialog-footer.component';
import { RightHandDialogComponent } from './right-hand-dialog/right-hand-dialog.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { ToggleButtonComponent } from './toggle-button/toggle-button.component';
import { AssetSearchComponent } from './type-ahead/asset-search/asset-search.component';
import { TypeAheadComponent } from './type-ahead/type-ahead.component';
import { UpDownToggleComponent } from './up-down-toggle/up-down-toggle.component';
import { WipSubmitButtonComponent } from './wip-submit-button/wip-submit-button.component';
import { WireInstructionsComponent } from './wire-instructions/wire-instructions.component';

@NgModule({
    imports: [
        // Angular
        BrowserModule,
        BrowserAnimationsModule,
        AngularCommon,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        // ngrx/store
        StoreModule,
        // ngx-bootstrap
        ButtonsModule,
        PopoverModule,
        TooltipModule,
        BsDatepickerModule.forRoot(),
        TypeaheadModule.forRoot(),
        BsDropdownModule.forRoot(),
        // SEI
        SeiCommonSwpComponentsLibraryModule,
        SeiCommonComponentsLibraryModule,
        ClientAccountAdministrationLibUxModule,
        OrderByPipe
    ],
    declarations: [
        ButtonRiskComponent,
        CreateAccountNumberButtonComponent,
        GenericErrorNotificationComponent,
        OptionSelectionComponent,
        OptionSelectionActionsComponent,
        ProgressBarComponent,
        RightHandDialogFooterComponent,
        WipSubmitButtonComponent,
        CheckBoxComponent,
        FileUploadModalPanelComponent,
        FileUploadModalFooterComponent,
        FileUploadModalBodyComponent,
        FileUploadSummaryComponent,
        FileUploadBoxComponent,
        TypeAheadComponent,
        AssetSearchComponent,
        FileDropDirective,
        ShortNumberPipe,
        EntitySearchComponent,
        ToggleButtonComponent,
        RightHandDialogComponent,
        PageTemplateComponent,
        FileUploadListComponent,
        DatePickerInputComponent,
        TinFormatterDirective,
        DatepickerComponent,
        DeleteAccountComponent,
        UpDownToggleComponent,
        DropdownComponent,
        CheckAddressComponent,
        WireInstructionsComponent,
        AssetDropdownComponent,
        AssetComponent,
        AltSourceImageComponent,
        NavigationTabMenuComponent,
        SpinnerComponent
    ],
    providers: [
        // pipes
        CurrencyPipe,
        DatePipe,
        PercentPipe,
        // Services
        UserProfileService,
        LoggerService,
        GlobalService,
        // factories
        AccountMrdcFactory,
        AccountOwnerDetailFactory,
        ObjectFactory,
        // repositories
        UserProfileRepository,
        AccountRepository,
        InvestmentProgramRepository,
        ElasticSearchRepository,
        ContactRepository,
        OrderByPipe
    ],
    exports: [
        // Angular
        BrowserModule,
        AngularCommon,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        // ngrx/store
        StoreModule,
        // ngx-bootstrap
        ButtonsModule,
        PopoverModule,
        TooltipModule,
        BsDatepickerModule,
        // SEI
        SeiCommonSwpComponentsLibraryModule,
        SeiCommonComponentsLibraryModule,
        ClientAccountAdministrationLibUxModule,
        // Car Reusable Components
        ButtonRiskComponent,
        DatepickerComponent,
        CreateAccountNumberButtonComponent,
        DeleteAccountComponent,
        GenericErrorNotificationComponent,
        OptionSelectionComponent,
        OptionSelectionActionsComponent,
        ProgressBarComponent,
        RightHandDialogFooterComponent,
        WipSubmitButtonComponent,
        CheckBoxComponent,
        FileUploadModalPanelComponent,
        TypeAheadComponent,
        AssetSearchComponent,
        ShortNumberPipe,
        EntitySearchComponent,
        ToggleButtonComponent,
        RightHandDialogComponent,
        PageTemplateComponent,
        FileUploadListComponent,
        DatePickerInputComponent,
        TinFormatterDirective,
        UpDownToggleComponent,
        DropdownComponent,
        CheckAddressComponent,
        WireInstructionsComponent,
        AssetDropdownComponent,
        AssetComponent,
        AltSourceImageComponent,
        NavigationTabMenuComponent,
        SpinnerComponent
    ]
})
export class CommonModule { }
