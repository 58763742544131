/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { EnumTranslator } from '@CarInterfaces/enum-translator';
import { StatementFrequency } from '@CarModels/enums';

@Injectable({
    providedIn: 'root'
})
export class StatementFrequencyFactory
    implements EnumTranslator<StatementFrequency> {
    public getDescription(type: StatementFrequency): string {
        switch (type) {
            case StatementFrequency.Quarterly:
                return 'Quarterly';
            case StatementFrequency.Annually:
                return 'Annually';
            case StatementFrequency.Monthly:
            default:
                return 'Monthly';
        }
    }
}
