/*
 * Copyright: This information co../../../model/presentationty of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProposalService } from '../../../../service/proposal.service';
import { SettingsElementComponent } from '../../../../common/settings-element/settings-element.component';
import { ProposalSectionLabel } from '../../../../model/enums';
import { ProposalSection, SelectOption } from '../../../../model/presentation';
import { DataSharingService } from '../../../../service/data-sharing.service';
import { PresentationSettingService } from '../../../../service/presentation-setting.service';

@Component({
    selector: 'sei-car-report-options',
    templateUrl: './report-options.component.html'
})
export class ReportOptionsComponent extends SettingsElementComponent implements OnInit {

    public reportingLevels: SelectOption[] =
        [
            { label: 'Detailed (e.g. Large Cap Value)', value: 'Detailed (e.g. Large Cap Value)' },
            { label: 'Summary (e.g. Equity)', value: 'Summary (e.g. Equity)' }
        ];

    public returnByHypothetical: SelectOption[] = [];

    public returnBy: SelectOption[] =
        [
            { label: 'Asset Class Index Returns', value: 'Asset Class Index Returns' }
        ];


    public chartTypes: SelectOption[] =
        [
            { label: 'Segment by 1, 3, 5, 10 yr', value: 'Segment by 1, 3, 5, 10 yr' },
            { label: 'Legacy', value: 'Legacy' }
        ];
    public existingAccountChartTypes: SelectOption[] =
        [
            { label: 'Segment by 1, 3, 5, 10 yr', value: 'Segment by 1, 3, 5, 10 yr' }
        ];
    public switchReturnByHypothetical: boolean = false;
    public isCurrentAccountsPresentInProposal: boolean = false;

    constructor(
        presentationSettingService: PresentationSettingService,
        dataSharingService: DataSharingService,
        route: ActivatedRoute,
        router: Router,
        proposalService: ProposalService) {
        super(presentationSettingService, dataSharingService, router, route, proposalService);
    }

    ngOnInit() {
        super.ngOnInit();
        this.isCurrentAccountsPresentInProposal = this.proposalService.isExistingAccountsPresentOnProposal;
        this.setReturnByHypotheticalValues();
        this.subscriptions.push(this.presentationSettingService.hypotheticalReturnBYDropDownValues.subscribe(
            (isReturnByDropdownEnabled: boolean) => {
                if (isReturnByDropdownEnabled) {
                this.setReturnByHypotheticalValues();
                }
            }));
    }


    public setReturnByHypotheticalValues(): void {
        this.returnByHypothetical = [];
        if (this.businessRules.HypotheticalUprLwrAssetClas) {
            this.returnByHypothetical.push({ label: 'Asset Class Index Returns', value: 'Asset Class Index Returns' });
        }
        if (this.businessRules.HypotheticalUprLwrCMA) {
            this.returnByHypothetical.push({ label: 'Capital Market Assumptions', value: 'Capital Market Assumptions' });
        }
    }

    public getDropDownSource(section: ProposalSection): SelectOption[] {
        switch (section.name) {
            case ProposalSectionLabel.ReportingLevel:
                return this.reportingLevels;
            case ProposalSectionLabel.ChartType:
                return this.isCurrentAccountsPresentInProposal ? this.existingAccountChartTypes : this.chartTypes;
            case ProposalSectionLabel.ReturnsBy:
                this.isReturnByDropdownHypothetical(section.id);
                return this.switchReturnByHypothetical ? this.returnByHypothetical : this.returnBy;
            default:
                return this.returnBy;
        }
    }

    public parentToggleChange(event: boolean, subSection: ProposalSection) {
        // for now , any change in subsections , passing on wrapper section to backend.
        this.dataSharingService.proposalSectionChanged.next(this.proposalSection);
    }

    public modelChanged(subSection: ProposalSection) {
        // for now , any change in subsections , passing on wrapper section to backend.
        this.dataSharingService.proposalSectionChanged.next(this.proposalSection);
    }

    public dropdownOptionSelected(subSection: ProposalSection): void {
        if (subSection.isActive) {
            // for now , any change in subsections , passing on wrapper section to backend.
            this.dataSharingService.proposalSectionChanged.next(this.proposalSection);
        }
    }

    private isReturnByDropdownHypothetical(sectionId: number): void {
        this.switchReturnByHypothetical = false;
        this.proposalSection.sections.some((sectionReportOptionChild: ProposalSection) => {
                if (sectionReportOptionChild.name === ProposalSectionLabel.ReturnProjectionUpperAndLowerBoundary) {
                    sectionReportOptionChild.sections.some((subSection: ProposalSection) => {
                        this.switchReturnByHypothetical = subSection.id === sectionId;
                        return this.switchReturnByHypothetical;
                    });
                }
                return this.switchReturnByHypothetical;
        });
    }
}
