/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ModalWindowComponent } from '@sei/common-components-lib-ux';

@Component({
    selector: 'sei-car-warning-modal',
    templateUrl: 'warning-modal.component.html',
    styleUrls: ['./warning-modal.component.scss']
})
export class WarningModalComponent {
    @Input()
    public message: string;

    @Input()
    public set showModal(value: boolean) {
        this._showModal = value;
        this.showModal ? this?.warningModal?.showModalBox() : this?.warningModal?.hideModalBox();
    }

    public get showModal() {
        return this._showModal;
    }

    @Input()
    public modalTitle: string;

    @Input()
    public modalHeader: string;

    @Output()
    public cancelClicked: EventEmitter<boolean> = new EventEmitter();

    @Output()
    public continueClicked: EventEmitter<boolean> = new EventEmitter();

    private _showModal: boolean = false;

    @ViewChild('warningModal')
    warningModal: ModalWindowComponent;

    onCancelClicked(): void {
        this.cancelClicked.emit(true);
    }

    onContinueClicked(): void {
        this.continueClicked.emit(true);
    }
}
