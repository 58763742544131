<div class="not-auth-page">
    <car-page-template>
        <div class="row">
            <div class="col-xs-12">
                <h1>Not Authorized</h1>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 car-main-body-copy">
                <p>We encountered an invalid session.</p>
                <p>
                    Please ensure you have a valid session in order to use the
                    SEI Advisor Center.
                </p>
            </div>
        </div>
        <div *ngIf="showImpersonation; then impersonationTemplate"></div>
    </car-page-template>
</div>

<ng-template #impersonationTemplate>
    <div class="row">
        <div class="col-xs-12 car-border-bottom"></div>
    </div>
    <div class="row">
        <div class="col-xs-12">
            <car-impersonation></car-impersonation>
        </div>
    </div>
</ng-template>

<ng-template #kassoDiagnostics>
    <div class="row">
        <div class="col-xs-12 car-border-bottom"></div>
    </div>
    <div class="row">
        <div class="col-xs-12">
            <h3>Siteminder Values</h3>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-12">
            <ul>
                <li>sm user: {{ smUser }}</li>
                <li>sm universal id: {{ smUniversalId }}</li>
            </ul>
        </div>
    </div>
</ng-template>
