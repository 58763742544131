/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Account, AccountType, Client, CommandText, DelegationService, EntitySuffix, GenericErrorService, Proposal, ProposalService, ReferenceDataService } from '@sei/advisor-client-review-proposal-ux';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import * as _ from 'lodash';
import { Observable, Subscription, forkJoin } from 'rxjs';

@Component({
    selector: 'sei-car-edit-proposal',
    templateUrl: './edit-proposal.component.html'
})
export class EditProposalComponent extends ParentComponentSubscriptionManager implements OnInit {

    @Input()
    public proposal: Proposal;
    @Input()
    public proposalStream: Observable<Proposal>;
    public suffixData: EntitySuffix[];
    public accountTypeData: AccountType[] = [];
    public accountData: Account[] = [];
    public proposalEditable: Proposal;
    private subscriptionData: Subscription;

    public disabledAddClient: boolean = false;

    constructor(
        private referenceDataService: ReferenceDataService,
        private genericErrorService: GenericErrorService,
        private delegationService: DelegationService,
        private proposalService: ProposalService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        super('EditProposalComponent');
    }

    public ngOnInit(): void {
        this.initializeComponent();

    }

    private initializeComponent(): void {
        if (this.proposal) {
            const suffixData = this.referenceDataService.getData('suffixes');

            forkJoin([suffixData])
                .subscribe(
                    (results) => {
                        if (results) {
                            this.suffixData = results[0]['suffixes'];
                        }
                    }
                );
        }

        this.subscriptionData = this.delegationService.refresh().subscribe(
            (data) => {
                if (data === CommandText.SaveEditProposal) {
                    this.saveAndExitProposal();
                }
            }
        );

        this.subscriptions.push(this.subscriptionData);
        this.proposalEditable = _.cloneDeep(this.proposal);
    }

    private saveAndExitProposal(): void {
        if (this.proposalEditable) {
            // NOTE: Update the proposal name and clients from passed in proposal
            this.proposal = {
                ...this.proposal,
                name: this.proposalEditable.name,
                clients: this.proposalEditable.clients
            };

            this.proposalService.proposalUpsert(this.proposal)
                .subscribe(
                    (responseProposal) => {
                        this.proposalService.changedProposal(responseProposal);
                        this.delegationService.publish(CommandText.UpdateWipCheckList);
                        this.router.navigate(['../Proposal/WIP/', this.route.snapshot.parent.params.proposalId]);
                    },
                    (error) =>
                        this.genericErrorService.setGenericError({
                            code: '',
                            description: error
                        })
                );
        }
    }

    public validateAddClients(): void {
        let clientValid: boolean = false;

        if (this.proposalEditable.clients.length > 0) {
            clientValid = this.proposalEditable.clients.every(
                (client: Client): boolean => {
                    return (
                        client.isIndividual
                        && client.firstName.length > 1
                        && client.lastName.length > 1)
                        || (!client.isIndividual && client.organizationName.length > 1);
                });
        }
        this.disabledAddClient = !clientValid;
    }
}
