<div class="current-account-form">
    <h1 class="current-account">{{title}} Current Account</h1>
    <h2 class="account-owner-name text-muted sei-margin-bottom-md">{{subTitle}}</h2>

    <div class="account-details-header standard-bottom-margin">
        <sei-pageheader [pageHeaderProperties]="accountDetailsHeaderProperties"
                        [headingLevel]="headingLevel"></sei-pageheader>
    </div>

    <div class="row">
        <div class="col-xs-6">
            <div class="form-group account-owner">
                <label for="accountOwner">Account Owner</label>
                <sei-dropdown id="accountOwner"
                              name="accountOwner"
                              [items]="accountOwnerData"
                              [ngModelOptions]="{standalone: true}"
                              [multiSelect]="multiSelect"
                              [autocomplete]="true"
                              [autocompleteMessage]="autoCompleteMessage"
                              [autocompleteState]="autocompleteState"
                              [(ngModel)]="selectedAccountOwnerData"
                              (displayItemsChange)="changeAccountOwner()">
                </sei-dropdown>
            </div>
        </div>
        <div class="col-xs-6">
            <div class="form-group account-name">
                <label for="accountName">Account Name</label>
                <input type="text"
                       class="form-control"
                       id="accountName"
                       [(ngModel)]="accountName"
                       (ngModelChange)="onAccountNameChange()">
            </div>
        </div>
    </div>

    <div class="form-group standard-bottom-margin">
        <sei-pageheader [pageHeaderProperties]="taxLotDetailsHeaderProperties"
                        [headingLevel]="headingLevel"></sei-pageheader>
    </div>
    <sei-ias-document-uploader [allowMultiple]="allowMultiple"
                               [currentAccountInfo]="currentAccountDetails"
                               [taxLotDetails]="taxLotDetails"
                               [isReadUser]="isReadUser"
                               (documentsEvent)="uploadDocuments($event)"></sei-ias-document-uploader>
</div>
