/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { SpinnerService } from '@CarServices/system/spinner.service';
import { HttpResponse, HttpStatusCode } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { EnvironmentMode, GlobalService } from '@sei/advisor-client-review-proposal-ux';
import { Logger } from '@sei/common-swp-components-lib-ux';
import { SiteminderValidatorService } from './siteminder-validator.service';


@Component({
    selector: 'sei-car-siteminder-validator',
    template: ''
})
export class SiteminderValidatorComponent implements OnInit {

    @Output() completedFirstAuth: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(private readonly siteminderValidatorService: SiteminderValidatorService,
        private readonly spinnerService: SpinnerService,
        private readonly global: GlobalService,
        private readonly logger: Logger) {

    }

    ngOnInit(): void {
        if (this.global.configService.environment.mode === EnvironmentMode.Dev) {
            this.completedFirstAuth.emit(true);
            return;
        }
        this.spinnerService.start();
        const locationHash: string = this.siteminderValidatorService.getLocationHash();
        const carConfig = this.global.configService.environment.carConfig;
        const proxyEndpoint = this.global.configService.environment.proxyGroup;
        const proposalDestination: string = `${proxyEndpoint}/${locationHash}`;
        const hostApplicationRedirect: string = `${carConfig.transportProtocol}://${carConfig.server}`;
        this.siteminderValidatorService.requestApigeeAuthCode().subscribe({
            next: (): void => this.completedFirstAuth.emit(true),
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            error: (response: HttpResponse<any>): void => {
                if (response.status === HttpStatusCode.Unauthorized) {
                    this.spinnerService.stop();
                    window.location.href = hostApplicationRedirect;
                } else {
                    this.completedFirstAuth.emit(true);
                    this.spinnerService.stop();
                }
            },
            complete: (): void => this.completedFirstAuth.emit(true)
        });
    }
}
