/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
import { Injectable } from '@angular/core';
import {
    CreateDocumentRequest,
    TradeCreateDocumentResponse,
    TradeDocumentStatusResponse
} from '@CarInterfaces/trade';
import { Observable, throwError, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { GlobalService } from '@CarServices/system/global.service';

/**
 * Document Repository Class to manage Rest communications
 */
@Injectable({
    providedIn: 'root'
})
export class DocumentRepository {
    constructor(
        private http: HttpClient,
        private globalService: GlobalService
    ) {}
    public loadDocument(accountNumber: string): Observable<ArrayBuffer> {
        const url = this.globalService.configService.routeFormatter(
            this.globalService.configService.getEndPoint('documentFetch'),
            [
                {
                    key: 'accountNumber',
                    value: accountNumber
                }
            ]
        );

        return this.http.get<ArrayBuffer>(url, {
            responseType: 'blob' as 'json'
        });
    }

    public create(
        request: CreateDocumentRequest
    ): Observable<TradeCreateDocumentResponse> {
        return this.http
            .post<TradeCreateDocumentResponse>(
                this.globalService.configService.routeFormatter(
                    this.globalService.configService.getEndPoint(
                        'documentCreate'
                    ),
                    [
                        {
                            key: 'accountNumber',
                            value: request.accountNumber
                        }
                    ]
                ),
                JSON.stringify({
                    data: [
                        {
                            initiations: [
                                {
                                    legalAgreementId: request.legalAgreementId,
                                    subForms: request.additionalForms
                                }
                            ]
                        }
                    ]
                })
            )
            .pipe(
                map((response: TradeCreateDocumentResponse) => {
                    if (response.error && response.error.message) {
                        throw new Error(response.error.message);
                    }
                    return response;
                }),
                catchError(this.handleError)
            );
    }

    public getStatus(
        accountNumber: string
    ): Observable<TradeDocumentStatusResponse> {
        return this.http
            .get<TradeDocumentStatusResponse>(
                this.globalService.configService.routeFormatter(
                    this.globalService.configService.getEndPoint(
                        'documentGetStatus'
                    ),
                    [
                        {
                            key: 'accountNumber',
                            value: accountNumber
                        }
                    ]
                )
            )
            .pipe(
                map((response: TradeDocumentStatusResponse) => {
                    if (response.error && response.error.message) {
                        throw new Error(response.error.message);
                    }
                    return response;
                }),
                catchError(this.handleError)
            );
    }

    private handleError(err) {
        return throwError(err);
    }
}
