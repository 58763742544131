/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class PreviousRouteService {
    private previousUrl: string;
    private currentUrl: string;

    constructor(private router: Router) {
        this.currentUrl = this.router.url;
        router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.previousUrl = this.currentUrl;
                this.currentUrl = event.url;
            }
        });
    }

    public getPreviousUrl(applicationUrls) {
        this.previousUrl = null;
        applicationUrls.forEach((appUrl) => {
            if (this.currentUrl.includes(appUrl)) {
                switch (appUrl) {
                    case '/AccountDetails': {
                        const currentUrlSplit = this.currentUrl.split(/\/AccountDetails\/\d+/);
                        this.previousUrl = currentUrlSplit[0];
                        break;
                    }
                }
                return [this.previousUrl];
            }
        });
        return [this.previousUrl];
    }
}
