<car-generic-error-notification></car-generic-error-notification>
<div class="row">
    <div class="col-xs-12">
        <sei-modal-window #impersonationModal
                          [modalType]="'lg'"
                          [modalTitle]="'On behalf of'"
                          [isBackdrop]="true"
                          [isDraggable]="true"
                          [footerEnable]="true"
                          [isDismissible]="true"
                          (modalClosed)="cancelImpersonate($event)">
            <div class="container modal-body">
                <sei-car-select-firm-advisor *ngIf="!showSearchResults"
                                             [firm]="firm"
                                             [entitledAdvisorsEntityIds]="entitledAdvisorsEntityIds"
                                             [displayMode]="displayModes.Dashboard"
                                             (firmSelect)="dashboardFirmSelected($event)"
                                             (advisorsUpdated)="advisorsUpdated($event)"
                                             [firmAdvisorLabel]="firmAdvisorLabels.Firm"
                                             (firmCleared)="firmCleared()"
                                             (onError)="impersonationError();"></sei-car-select-firm-advisor>
            </div>
            <footer class="modalbox-footer">
                <button type="button"
                        class="btn btn-default pull-left"
                        [class.disabled]="false"
                        (click)="cancelImpersonate(true)">Cancel</button>
                <button type="button"
                        class="btn btn-default"
                        [class.disabled]="!isImpersonationValid"
                        (click)="executeImpersonate()">Go To Dashboard <i class="fas fa-arrow-circle-right"></i></button>
            </footer>
        </sei-modal-window>
    </div>
</div>
<div class="row dash-info-row">
    <div class="col-xs-4 text-left">
        <div class="dash-totals">
            <div>Active Proposals<br> {{totalProposalCount}}</div>
            <div>Value<br> {{ totalAccountValue | currency }}</div>
        </div>
    </div>
    <div class="col-xs-4 text-center">
        <div id="sei_advisor"
             class="sei-advisor-block"
             *ngIf="userProfile?.entitlements.userLevelId === userLevel.PO || userProfile?.entitlements.userLevelId === userLevel.Instance">
            <div>On behalf of:<br>{{primaryAdvisorName}}<br>{{firm?.firmName}}<br>{{firm?.officeLocation?.city}}</div>
            <button type="button"
                    class="btn primary sml"
                    (click)="changeFirm()">Change<i class="fa fas fa-pencil-alt"></i></button>
        </div>

    </div>
    <div *ngIf="!isReadUser"
         class="col-xs-4 text-right">
        <a [routerLink]="['/Proposal']"
            routerLinkActive="router-link-active">
        <div class="start-new-proposal">
            <i class="fa fas fa-plus-circle"></i>&nbsp; Start New Proposal
        </div>
    </a>
    </div>
</div>
<sei-car-dashboard-table class="hidden-print"
                         *ngIf="showSearchResults"
                         [firmId]="firm?.firmId"
                         [onBehalfOfUserId]="firm?.primaryAdvisor?.entityId ?? userProfile?.entityId"
                         [advisorIds]="entitledAdvisorsEntityIds"
                         [isReadUser]="isReadUser"
                         (totalAccountValue)="readTotalAccountOutputEmitted($event)"
                         (totalProposalCount)="readTotalProposalCountEmitted($event)"
                         (implementProposal)="implementProposalClick($event)">
</sei-car-dashboard-table>
<car-implement-modal *ngIf="showImplementModal"
                     [proposal]="proposalStream | async"
                     [enableImplementButton]="enableImplementButton"
                     [isProposalGenerated]="isProposalGenerated"
                     (onImplement)="implement($event)"
                     (onCancel)="cancelImplement()">
</car-implement-modal>
