/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { ReactivateModalService } from '@CarServices/reactivate-modal/reactivate-modal.service';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { BannerMessageType, ModalWindowComponent } from '@sei/common-components-lib-ux';
import { ParentComponentSubscriptionManager, SubpageheaderModel } from '@sei/common-swp-components-lib-ux';
import { Account, MinDataProposal, ProposalPendingAccountStatus, ProposalStatusResponse } from '../../model/proposal';

@Component({
    selector: 'sei-car-reactivate-modal',
    templateUrl: './reactivate-modal.component.html',
    styleUrls: ['./reactivate-modal.component.scss']
})
export class ReactivateModalComponent extends ParentComponentSubscriptionManager implements AfterViewInit, OnDestroy, OnInit {

    @ViewChild('reactivateModal', { static: false })
    public reactivateModalComponent: ModalWindowComponent;

    @Input()
    public proposal: MinDataProposal;

    @Input()
    public proposalStatusResponse: ProposalStatusResponse;

    @Output()
    public onContinueClicked: EventEmitter<number> = new EventEmitter();

    @Output()
    public modalClosed: EventEmitter<boolean> = new EventEmitter();

    public proposalDetailsHeaderProperties: SubpageheaderModel;
    public accountDetailsHeaderProperties: SubpageheaderModel;
    public errorType: BannerMessageType = BannerMessageType.danger;
    public warningType: BannerMessageType = BannerMessageType.warning;

    private readonly PENDING_ACCOUNTS_MESSAGE: string = '';

    private readonly PENDING_ACCOUNTS_INVALID_ERROR_MESSAGE: string = 'All Pending Accounts must be in either "Draft" ' +
        'or "Awaiting Approval from Advisor" status to be reactivated. If allowed, accounts can be returned to "Draft" status in ' +
        'Digital Account Open. Click Review Accounts to access the Pending Accounts dashboard.';

    private readonly NO_PENDING_ACCOUNTS_MESSAGE: string = 'Proposal will be reactivated and moved to the Active tab of the Dashboard. ' +
        'Are you sure you want to continue?';

    private readonly ERROR_BANNER_INVALID_STATUS_MESSAGE: string =
        'At least one Pending Account in the proposal is not in a status that is eligible for reactivation.';

    private readonly VALID_DAO_STATUS_ID_LIST: number[] = [0, 1, 2, 3, 11];

    constructor(private reactivateModalService: ReactivateModalService) {
        super('ReactivateModalComponent');
    }

    ngOnInit(): void {
        this.setHeaderProperties();
    }

    ngAfterViewInit(): void {
        this.reactivateModalComponent.showModalBox();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.closeModal();
    }

    public proposalContainsPendingAccounts(): boolean {
        return this.proposal?.proposalAccounts
            ?.some((account: Account) => !!account?.implementedAccountNumber) && !!this.proposalStatusResponse.proposalAccountStatuses;
    }

    public getModalWarningText(): string {
        if (this.proposalContainsPendingAccounts()) {
            if (this.proposalStatusResponse.eligibleForReactivation) {
                return this.PENDING_ACCOUNTS_MESSAGE;
            } else {
                return this.PENDING_ACCOUNTS_INVALID_ERROR_MESSAGE;
            }
        } else {
            return this.NO_PENDING_ACCOUNTS_MESSAGE;
        }
    }

    public showErrorBanner(): boolean {
        return !this.proposalStatusResponse?.eligibleForReactivation;
    }

    public getValidationError(): string {
        return this.ERROR_BANNER_INVALID_STATUS_MESSAGE;
    }

    public isAccountStatusValid(account: Account): boolean {
        const accountStatus: ProposalPendingAccountStatus = this.getStatusObjectForProposalAccount(account);
        return this.VALID_DAO_STATUS_ID_LIST.includes(accountStatus.statusId);
    }

    public getAccountNumberForAccount(account: Account): string {
        const accountStatus: ProposalPendingAccountStatus = this.getStatusObjectForProposalAccount(account);
        return accountStatus?.accountNumber;
    }

    public getStatusForAccount(account: Account): string {
        const accountStatus: ProposalPendingAccountStatus = this.getStatusObjectForProposalAccount(account);
        return accountStatus?.status;
    }

    public navigateToDAO(): void {
        this.reactivateModalService.navigateToDAO();
    }

    public closeModal(): void {
        this.reactivateModalComponent.hideModalBox();
        this.modalClosed.emit(true);
    }

    public continueModal(): void {
        this.onContinueClicked.emit(this.proposal.proposalId);
    }

    public getModalSize(): string {
        return this.proposalContainsPendingAccounts() || this.showErrorBanner() ? 'lg' : 'sm';
    }

    public getPendingAccounts(): Account[] {
        return this.proposal?.proposalAccounts
            ?.filter((account: Account) => !!account.implementedAccountNumber && !!this.getStatusObjectForProposalAccount(account));
    }

    private getStatusObjectForProposalAccount(account: Account): ProposalPendingAccountStatus {
        return this.proposalStatusResponse.proposalAccountStatuses?.find((pendingAccountStatus: ProposalPendingAccountStatus) =>
            pendingAccountStatus?.proposalAccountId === account.id);
    }

    private setHeaderProperties(): void {
        this.proposalDetailsHeaderProperties = {
            title: this.proposal.proposalName,
            switcherAvailable: false
        };
        this.accountDetailsHeaderProperties = {
            title: 'Account Details',
            switcherAvailable: false
        };
    }

}
