
/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */


import { Injectable } from '@angular/core';
import { FeeAccount } from '../account-fee';
import { BaseFactoryAbstract } from './base-factory-abstract';


@Injectable()
export class FeeAccountFactory extends BaseFactoryAbstract<FeeAccount> {

    constructor() {
        super();
    }

    public createObject(idFeeAccount: number): FeeAccount {
        return {
            id: idFeeAccount,
            name: '',
            investmentCost: { rate: 0, amount: 0 },
            type: {
                id: 0,
                description: '',
                sortOrder: 0,
                taxQualified: false,
                qualifiedPlan: false
            },
            balance: 0,
            strategies: []
        };

    }

}


