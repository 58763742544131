/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DynamicErrorInfo } from '@CarModels/dynamic-error-info';
import { GlobalService } from '@CarServices/system/global.service';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { GenericError } from '../../model/generic-error';

/**
 * Global Error Handling service.  Used with GenericErrorNotificationComponent to
 * publish and display errors
 */
@Injectable({
    providedIn: 'root'
})
export class GenericErrorService {
    private errorText: BehaviorSubject<GenericError>;
    private _hasError: BehaviorSubject<boolean>;

    constructor(private global: GlobalService, private http: HttpClient) {
        this.errorText = new BehaviorSubject<GenericError>(undefined);
        this._hasError = new BehaviorSubject<boolean>(false);
    }

    public get hasError(): Observable<boolean> {
        return this._hasError.asObservable();
    }

    public getErrorStream(): Observable<GenericError> {
        return this.errorText ? this.errorText.asObservable() : EMPTY;
    }

    public setGenericError(error: GenericError) {
        // Silence KASSO errors because they do not impact business functionality.
        if (error?.description?.includes('KASSO')) {
            return;
        }
        if (!this.errorText) {
            this.errorText = new BehaviorSubject<GenericError>(error);
            return;
        }
        this._hasError.next(true);
        this.errorText.next(error);
    }

    public clearGenericError() {
        this._hasError.next(false);
        if (this.errorText) {
            this.errorText.next(undefined);
        }
    }

    public getErrorNumber() {
        const apiUrl: string = this.global.configService.routeFormatter(
            this.global.configService.getEndPoint('getErrorInfo'),
            [{
                key: 'error',
                value: 'GetContactPhoneNumberOnError'
            }]
        );
        return this.http.get<DynamicErrorInfo>(apiUrl);
    }
}
