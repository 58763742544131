/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Factories, ObjectFactory } from '@CarFactories/object-factory';
import { Account } from '@CarInterfaces/account';
import { AccountActions, AccountActionTypes } from './account.actions';

export const carAccountModuleSliceName = 'carAccountSlice';

export interface AccountState {
    account: Account;
}

export const initialAccountState: AccountState = {
    account: ObjectFactory.createObject(Factories.AccountMrdc)
};

export function AccountSlice(
    state: AccountState = initialAccountState,
    action: AccountActions
): AccountState {
    switch (action.type) {
        case AccountActionTypes.InitializeAccountAction:
        case AccountActionTypes.UpdateAccountAction:
            return {
                account: action.payload.account
            };
        default:
            return state;
    }
}
