/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, timer } from 'rxjs';
import { filter, mergeMap, takeUntil, tap } from 'rxjs/operators';

// TODO: I plan on using this for the document status check where I need to poll for 60 seconds.  Its not integrated or used yet
@Injectable()
export class PollingService<T> implements OnDestroy {

    private destroyedStream = new Subject<T>();
    /**
     *
     * @param intervalTime
     * @param pollFunction
     */
    public poll<PollResultType>(intervalTime: number, pollFunction: () => Observable<PollResultType>): Observable<PollResultType> {
        let isRequesting = false;
        return timer(0, intervalTime)
            .pipe(
                // When the service is destroyed, all polls will be unsubscribed from
                takeUntil(this.destroyedStream), // eslint-disable-line rxjs/no-unsafe-takeuntil
                // tap(tick => console.log('tick', tick)),
                // Only continue if isRequesting is false
                filter(() => !isRequesting),
                // Set isRequesting to true before requesting data
                tap(() => isRequesting = true),
                // Execute your poll function
                mergeMap(pollFunction),
                // Set isRequesting to false, so the next poll can come through
                tap(() => isRequesting = false)
            );
    }
    /**
     *
     */
    public ngOnDestroy() {
        // When the service gets destroyed, all existing polls will be destroyed as well
        this.destroyedStream.next(undefined); // eslint-disable-line rxjs/no-unsafe-subject-next
        this.destroyedStream.complete();
    }
}
