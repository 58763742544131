/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { CommonModule } from '@CarComponents/common/common.module';
import { MaterialModule } from '@CarComponents/material/material.module';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SeiCommonComponentsLibraryModule } from '@sei/common-components-lib-ux';
import { SignerSelectionAccountSignerComponent } from './signer-selection-account-signer/signer-selection-account-signer.component';
import { SignerSelectionAccountComponent } from './signer-selection-account/signer-selection-account.component';
import { SignerSelectionAdvisorComponent } from './signer-selection-advisor/signer-selection-advisor.component';
import { SignerSelectionFooterComponent } from './signer-selection-footer/signer-selection-footer.component';
import { SignerSelectionFundingCheckComponent } from './signer-selection-funding-check/signer-selection-funding-check.component';
import { SignerSelectionFundingWireComponent } from './signer-selection-funding-wire/signer-selection-funding-wire.component';
import { SignerSelectionComponent } from './signer-selection-main/signer-selection-main.component';
import { SignerSelectionSignersComponent } from './signer-selection-signers/signer-selection-signers.component';

@NgModule({
    imports: [CommonModule, MaterialModule, FormsModule, SeiCommonComponentsLibraryModule],
    declarations: [
        SignerSelectionComponent,
        SignerSelectionSignersComponent,
        SignerSelectionAdvisorComponent,
        SignerSelectionFooterComponent,
        SignerSelectionAccountComponent,
        SignerSelectionAccountSignerComponent,
        SignerSelectionFundingCheckComponent,
        SignerSelectionFundingWireComponent
    ],
    exports: [SignerSelectionComponent]
})
export class SignerSelectionModule { }
