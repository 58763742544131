/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { DisplayAmount } from '@sei/common-components-lib-ux';

@Component({
    selector: 'sei-car-display-monetary-amount',
    templateUrl: './display-monetary-amount.component.html'
})
export class DisplayMonetaryAmountComponent implements OnInit, OnChanges {

    @Input()
    public amount: number;

    @Input()
    public actualCurrency: string = '';

    public displayAmount: DisplayAmount;

    public ngOnInit(): void {
        this.setDisplayAmount();
    }

    public ngOnChanges(): void {
        this.setDisplayAmount();
    }

    private setDisplayAmount(): void {
        this.displayAmount = { value: this.amount, actualCurrency: this.actualCurrency };
    }

}
