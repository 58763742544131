<div class="item-navigator-container">
    <button type="button"
            class="btn-link previous-button"
            (click)="previousClicked()"
            [disabled]="disablePrevLink()">
        <i class="fa fas fa-chevron-left"></i><span class="previous-text">Previous</span>
    </button>
    <span class="count-container">
    <span class="current-index"> {{currentIndex|seiDisplayCount}} </span>of
    <span class="total-items">{{totalItems|seiDisplayCount}}</span>
    </span>
    <button type="button"
            class="btn-link next-button"
            (click)="nextClicked()"
            [disabled]="disableNextLink()">
        <span class="next-text">Next</span><i class="fa fas fa-chevron-right"></i>
    </button>
</div>
