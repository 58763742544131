/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { CommonModule } from '@CarComponents/common/common.module';
import { NgModule } from '@angular/core';
import { EditMonetaryAmountDirective } from '@sei/common-components-lib-ux';
import { SelectOnFocusDirective } from '../../core/directives/select-on-focus.directive';
import { FundingSourceAchComponent } from './funding-source-ach/funding-source-ach.component';
import { FundingSourceCheckComponent } from './funding-source-check/funding-source-check.component';
import { FundingSourceSelectorComponent } from './funding-source-selector/funding-source-selector.component';
import { FundingSourceSummaryAchComponent } from './funding-source-summary-ach/funding-source-summary-ach.component';
import { FundingSourceSummaryTransferComponent } from './funding-source-summary-transfer/funding-source-summary-transfer.component';
import { FundingSourceSummaryComponent } from './funding-source-summary/funding-source-summary.component';
import {
    FundingSourceTransferTypeSelectorComponent
} from './funding-source-transfer-type-selector/funding-source-transfer-type-selector.component';
import { FundingSourceTransferComponent } from './funding-source-transfer/funding-source-transfer.component';
import { FundingSourceWireComponent } from './funding-source-wire/funding-source-wire.component';
import { WIPFundingSourcesComponent } from './wip-funding-sources-main/wip-funding-sources-main.component';

@NgModule({
    imports: [CommonModule,
        EditMonetaryAmountDirective
    ],
    declarations: [
        FundingSourceCheckComponent,
        FundingSourceSelectorComponent,
        FundingSourceSummaryComponent,
        WIPFundingSourcesComponent,
        FundingSourceWireComponent,
        FundingSourceAchComponent,
        FundingSourceSummaryAchComponent,
        FundingSourceTransferComponent,
        FundingSourceTransferTypeSelectorComponent,
        FundingSourceSummaryTransferComponent,
        FundingSourceTransferTypeSelectorComponent,
        SelectOnFocusDirective
    ],
    providers: [],
    exports: [
        FundingSourceCheckComponent,
        FundingSourceSelectorComponent,
        FundingSourceSummaryComponent,
        WIPFundingSourcesComponent
    ]
})
export class WipFundingSourcesModule { }
