/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import {
    CoverPageImage, CoverPageImageTheme, CoverPageThemeIds, PresentationSettingService,
    SelectOption
} from '@sei/advisor-client-review-proposal-ux';
import { forkJoin, map, Observable, of, share, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CoverPageImagesService {

    firmLogos: SelectOption[] = [];
    coverImages: CoverPageImageTheme[] = [];
    private subjectName = new Subject<number>();

    constructor(
        private readonly presentationSettingService: PresentationSettingService
    ) { }

    updateFirmLogos(firmLogos: SelectOption[], proposalId: number) {
        this.firmLogos = firmLogos;
        this.subjectName.next(proposalId);
    }

    getUpdate(): Observable<number> {
        return this.subjectName.asObservable();
    }

    loadCoverPageImages(proposalId: number): Observable<void> {
        if (this.coverImages.length === 0) {
            return this.forceLoadCoverPageImages(proposalId);
        }
        return of(void 0);
    }

    forceLoadCoverPageImages(proposalId: number): Observable<void> {
        const referenceValueStream = forkJoin({
            firmLogos: this.presentationSettingService.getProposalFirmLogo(proposalId),
            coverImages: this.presentationSettingService.getCoverImages(proposalId)
        });

        return referenceValueStream.pipe(share(), map((response) => {
            if (response.firmLogos) {
                this.firmLogos = response.firmLogos;
            }

            this.coverImages = response.coverImages;
        }));
    }

    getFirmLogos(): SelectOption[] {
        return this.firmLogos;
    }

    getCoverImages(themeId: CoverPageThemeIds): SelectOption[] {
        const theme: CoverPageImageTheme =
            this.coverImages.find((coverPageImageTheme: CoverPageImageTheme) => coverPageImageTheme.themeId === themeId);
        return theme?.coverPageImages?.map((coverPageImage: CoverPageImage) => {
            return {
                value: coverPageImage.documentSource.replace(/ /g, '%20'),
                label: coverPageImage.displayName
            };
        });
    }
}
