/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Type } from '../../model/proposal';


@Component({
    selector: 'sei-car-strategy-dropdown',
    templateUrl: './strategy-dropdown.component.html'
})
export class StrategyDropdownComponent {
    @Input()
    strategyDropDownInputData: Type[];

    @Input()
    isLabelHidden: boolean = false;

    @Input()
    labelStrategyDropDown: String;

    @Input()
    setDefaultDropDownValue: number;

    @Output()
    public strategyDropdownSelected = new EventEmitter<number>();

    public onChangeStrategyDropDown(event): void {
        this.setDefaultDropDownValue = Number(event.target.value);
        this.strategyDropdownSelected.emit(this.setDefaultDropDownValue);
    }
}
