/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WizardModel, WizardState } from '@sei/common-components-lib-ux';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { HtmlElementType, InputPropertyType, ProposalSectionLabel, SectionId, ToolTipLabels } from '../../model/enums';
import { ToolTipOptionsFactory } from '../../model/factory/tooltip-options-factory';
import { PresentationBusinessRules, ProposalSection, SelectOption } from '../../model/presentation';
import { ToolTipOptions } from '../../model/tooltip-options';
import { DataSharingService } from '../../service/data-sharing.service';
import { PresentationSettingService } from '../../service/presentation-setting.service';
import { ProposalService } from '../../service/proposal.service';

@Component({
    selector: 'sei-car-settings-element',
    templateUrl: './settings-element.component.html'
})
export class SettingsElementComponent extends ParentComponentSubscriptionManager implements OnInit {

    @Input()
    groupIndex: number;

    @Input()
    proposalSection: ProposalSection;

    @Input()
    public dropDownContent: SelectOption[] = [];

    @Input()
    public businessRules: PresentationBusinessRules;

    @Output()
    public toggleChange = new EventEmitter();

    @Output()
    public updateParentSection = new EventEmitter<string>();

    @Output()
    public savePdfStatement = new EventEmitter();

    @Output()
    public renameCustomContent: EventEmitter<string> = new EventEmitter();

    @Output()
    public renameOptionSelected: EventEmitter<boolean> = new EventEmitter(false);

    @Output()
    public onDeleteSection: EventEmitter<string> = new EventEmitter();

    public showImageModal: boolean = false;
    public showFileUploadModal: boolean = false;
    public selectedColor: SelectOption;
    public proposalId: number;

    public proposalSectionLabel: typeof ProposalSectionLabel = ProposalSectionLabel;
    public elementTypes: typeof HtmlElementType = HtmlElementType;
    public inputPropertyType: typeof InputPropertyType = InputPropertyType;
    public toolTipOptions: ToolTipOptions[];
    public renameSelected: boolean = false;
    public advisorContentSectionId: number = SectionId.AdvisorCustomContent;

    // Note: settings for sei-icon-toggle
    public offIsGrey: boolean = false;
    public disabled: boolean = false;
    public isActive: boolean = true;
    public trueIcon: string = 'fa-fw fas fa-toggle-on fa-rotate-180';
    public falseIcon: string = 'fa-fw fas fa-toggle-off fa-rotate-180';
    public iconSize: string = 'fa-2x';
    public iconClass: string = 'info';

    public reportingLevelWizardModel: WizardModel[] = [
        { name: 'Roll Up', state: WizardState.ENABLE },
        { name: 'By Acct', state: WizardState.ENABLE }
    ];

    public reportingMethods: SelectOption[] =
        [{ label: 'Summary with Fund Expenses', value: 'Summary with Fund Expenses' },
        { label: 'Detail w/out Fund Expenses', value: 'Detail w/out Fund Expenses' },
        { label: 'Detail with Fund Expenses', value: 'Detail with Fund Expenses' }];

    constructor(
        public readonly presentationSettingService: PresentationSettingService,
        public readonly dataSharingService: DataSharingService,
        public readonly router: Router,
        public readonly route: ActivatedRoute,
        public readonly proposalService: ProposalService
    ) {
        super('SettingsElementComponent');
    }

    ngOnInit(): void {
        this.route.paramMap.subscribe((params) => {
            this.proposalId = Number(params.get('proposalId'));
        });
        this.toolTipOptions = [
            new ToolTipOptionsFactory().createOption(ToolTipLabels.Rename),
            new ToolTipOptionsFactory().createOption(ToolTipLabels.Delete)
        ];
    }

    onOptionSelected(option: string, subSectionName?: string) {
        switch (option) {
            case ToolTipLabels.Rename:
                this.renameSelected = true;
                this.renameOptionSelected.emit(this.renameSelected);
                break;
            case ToolTipLabels.Delete:
                this.onDeleteSection.emit(subSectionName);
                break;
            default:
                break;
        }
    }

    public onViewClick(): void {
        this.showImageModal = true;
    }

    public closeImageModal(): void {
        this.showImageModal = false;
    }

    public optionSelected(event): void {
        this.proposalSection.inputProperty.value = event;
        this.updatePreviewSection();
    }

    public updatePreviewSection(): void {
        this.dataSharingService.proposalSectionChanged.next(this.proposalSection);
    }

    public onImageUploadClick(event): void {
        this.showFileUploadModal = true;
    }

    public onImageUploadModalCancel(): void {
        this.showFileUploadModal = false;
    }

    public onFeesNavigateClick(): void {
        this.savePdfStatement.emit(`/Proposal/WIP/${this.proposalId}/Fees`);
    }

    public onReportingLevelToggleChange(event): void {
        this.proposalSection.include = event;
        this.updatePreviewSection();
    }

    public modelChanged(section: ProposalSection): void {
        this.toggleChange.emit(section);
    }

    public onRenameConfirm(name: string): void {
        this.proposalSection.name = name;
        this.renameSelected = false;
        this.proposalService.setUpdatedSectionName(this.proposalSection.name);
        this.renameOptionSelected.emit(this.renameSelected);
        this.renameCustomContent.emit(this.proposalSection.name);
    }
}
