<div class="col-xs-12 fee-component">
    <div class="fee-header">
        <div class="flex-left">
            <h1>Fee Analysis for Me</h1>
            <h2>{{scenario.name}}</h2>
        </div>
    </div>
    <div class="section-gap fee-wrapper">
        <h4>SEI Accounts</h4>
        <div cdkDropListGroup>
            <sei-car-fee-schedule *ngFor="let feeGroup of feeGroups"
                                  [feeGroup]="feeGroup"
                                  [scenarioId]="scenario.id"
                                  [proposalId]="proposalId"
                                  [scenarioFeeSchedules]="scenarioFeeSchedules"
                                  [showScheduleFlatFeeRateDetails]="feeGroup.id === newAccountFeeId"
                                  (onAccountEnter)="onAccountEnter()"
                                  (onCancelNewAccountFee)="onCancelNewAccountFee($event)"
                                  (onChangeAsDefault)="onChangeAsDefault($event)"
                                  (onHudSummaryUpdate)="onHudSummaryUpdate($event)">
            </sei-car-fee-schedule>
            <sei-car-fee-schedule-drop-container (onAccountDropped)='onAccountDropped($event)'
                                                 [hidden]='hiddenDropContainer'></sei-car-fee-schedule-drop-container>
        </div>
    </div>
    <sei-car-heads-up-display [hudSummary]="hudSummary"
                              [managementFeeBreakdown]="managementFeeBreakdown"
                              [scenario]="scenario"></sei-car-heads-up-display>
</div>
