/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TooltipConstants } from '@CarModels/constants';

import { DisplayNullOptions, DisplayPercentOptions } from '@sei/common-components-lib-ux';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { PercentFormatPipe } from 'projects/advisor-client-review-proposal-ux-app/src/app/core/pipes/percent-format.pipe';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { ProposalPercentFormat, ScenarioAccountFee } from '../../model/account-fee';
import { CommandText, CurrencyTypes, WipAccountStatus } from '../../model/enums';
import { Proposal, Scenario, WipCheckList } from '../../model/proposal';
import { DelegationService } from '../../service/delegation.service';
import { FeeScheduleGroupService } from '../../service/fee-schedule-group.service';
import { ProposalAccountService } from '../../service/proposal-account.service';

@Component({
    selector: 'car-wip-checklist-fees',
    templateUrl: './wip-checklist-fees.component.html',
    styleUrls: ['./wip-checklist-fees.component.scss'],
    providers: [PercentFormatPipe]
})
export class WipChecklistFeesComponent extends ParentComponentSubscriptionManager implements OnInit, OnDestroy {
    @Input()
    public wipCheckList: WipCheckList;
    @Input()
    public hudSummaryStream: Observable<ScenarioAccountFee>;
    @Input()
    public proposal: Proposal;

    public showInvestmentSectionDetails: boolean = false;
    public showSectionDetail: boolean = false;
    public feesEditDisabled: boolean = true;
    public currencyTypes: typeof CurrencyTypes = CurrencyTypes;
    public wipAccountStatus: typeof WipAccountStatus = WipAccountStatus;
    public readonly currencySymbol: string = 'USD';

    private displayPercentOptions: DisplayPercentOptions = {
        displayPercentSymbol: true,
        roundToDecimal: 2,
        nullDisplay: DisplayNullOptions.BLANK
    };

    public proposalPercentFormatOptions: ProposalPercentFormat = {
        isFeeBreakdown: false,
        displayPercentOptions: this.displayPercentOptions
    };

    constructor(private router: Router,
        private feeScheduleGroupService: FeeScheduleGroupService,
        private delegationService: DelegationService,
        private proposalAccountService: ProposalAccountService) {
        super('WipChecklistFeesComponent');
    }

    public ngOnInit(): void {
        this.subscriptions.push(this.delegationService.refresh()
            .subscribe(
                (command: string) => {
                    if (command === CommandText.UpdateWipCheckList) {
                        this.refreshFees();
                    }
                    if (command === CommandText.SaveFeeGroup) {
                        this.wipCheckList.mdcSnapShot.completed = WipAccountStatus.Complete;
                    }
                }));

        this.refreshFees();
    }

    public goToFees(): void {
        this.router.navigate(['/Proposal/WIP/' + this.proposal.id + '/Fees']);
    }

    public onShowInvestmentDetailsClick(): void {
        this.showInvestmentSectionDetails = !this.showInvestmentSectionDetails;
    }

    public onShowDetailsClick(): void {
        this.showSectionDetail = !this.showSectionDetail;
    }

    private refreshFees(): void {
        this.hudSummaryStream = this.feeScheduleGroupService.getProposalFees(this.proposal.id).pipe(share());
        if (this.proposedAccountsAvailable()) {
            this.feesEditDisabled = false;
        }
        this.showSectionDetail = true;
    }

    public proposedAccountsAvailable(): boolean {
        let proposedAccountsAvailable: boolean = false;
        this.proposal.scenarios?.forEach((scenario: Scenario): void => {
            proposedAccountsAvailable = scenario.accounts && scenario.accounts.length > 0;
        });
        return proposedAccountsAvailable;
    }

    public isClientDirectedPresent(): boolean {
        return this.proposalAccountService.isClientDirectedPresentInProposal(this.proposal);
    }

    public getTooltipMessage(): string {
        return TooltipConstants.CLIENT_DIRECTED_MESSAGE;
    }
}
