<ul class="nav nav-sidebar">
    <div class="document-header"
         (click)="toggleCollapsed()">
        <span>{{ document?.name }}</span>
        <i class="chevron far" [ngClass]="collapsed ? 'fa-chevron-right' : 'fa-chevron-down'"></i>
    </div>
    <div class="sidebar-item-list"
         [class.collapsed]="collapsed"
         [class.open]="!collapsed">
        <div *ngIf="!collapsed"
             cdkDropList
             cdkDropListData="document?.sections"
             (cdkDropListDropped)="drop($event)">
            <div *ngFor="let section of document?.sections; let i = index;"
                 class="item"
                 cdkDrag
                 [cdkDragDisabled]="i === 0">
                <sei-car-sidebar-item [documentSection]="section">
                </sei-car-sidebar-item>
            </div>
        </div>
    </div>
</ul>
