/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { NgModule } from '@angular/core';
import { CommonModule as AngularCommonModule } from '@angular/common';
import { CommonModule } from '@CarComponents/common/common.module';
import { AccountStatusActionsComponent } from './account-status-actions/account-status-actions.component';
import { AccountStatusAdvisorsComponent } from './account-status-advisors/account-status-advisors.component';
import { AccountStatusCircleComponent } from './account-status-circle/account-status-circle.component';
import { AccountStatusDescriptionComponent } from './account-status-description/account-status-description.component';
import { AccountStatusComponent } from './account-status-main/account-status-main.component';
import { AccountStatusRemindersComponent } from './account-status-reminders/account-status-reminders.component';
import { AccountStatusStepsComponent } from './account-status-steps/account-status-steps.component';
import { AccountStatusDocumentsComponent } from './account-status-documents/account-status-documents.component';

@NgModule({
    declarations: [
        AccountStatusComponent,
        AccountStatusStepsComponent,
        AccountStatusCircleComponent,
        AccountStatusDescriptionComponent,
        AccountStatusRemindersComponent,
        AccountStatusActionsComponent,
        AccountStatusAdvisorsComponent,
        AccountStatusDocumentsComponent
    ],
    imports: [
        AngularCommonModule,
        CommonModule
    ],
    exports: [
        AccountStatusComponent
    ]
})
export class AccountStatusModule {}
