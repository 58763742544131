<div *ngIf="checked; then isChecked else notChecked"></div>

<ng-template #notChecked>
    <div class="row pick-list-item" (click)="onChange()">
        <div class="col-xs-12">
            {{ pickListItemText }}
        </div>
    </div>
</ng-template>

<ng-template #isChecked>
    <div class="row pick-list-item selected">
        <div class="col-xs-8">
            {{ pickListItemText }}
        </div>
        <div class="col-xs-4">
            <a class="pull-right text-danger" (click)="onChange()">Change</a>
        </div>
    </div>
    <div class="row" *ngIf="showOwnerControls">
        <div class="col-xs-12">
            <div class="form-group">
                <car-account-owner-dropdown *ngIf="showAccountOwnerDropdown" [elasticSearchData]="elasticSearchData"
                    (accountOwnersDropdownSelected)="onAccountOwnersDropdownSelected($event)">
                </car-account-owner-dropdown>
                <car-proposal-account-owner *ngIf="!showAccountOwnerDropdown" [accountOwnerData]="accountOwner"
                    [accountData]="accountData" [defaultOwnerId]="defaultAdvisorEntityId"></car-proposal-account-owner>
            </div>
        </div>
    </div>
</ng-template>