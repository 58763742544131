/*
* Copyright: This information constitutes the exclusive property of SEI
* Investments Company, and constitutes the confidential and proprietary
* information of SEI Investments Company.  The information shall not be
* used or disclosed for any purpose without the written consent of SEI
* Investments Company.
*/

import { ProposalTaxRateComponent } from '@CarComponents/tax-rate/tax-rate.component';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MdcSnapshot, Proposal, WipCheckList } from '@sei/advisor-client-review-proposal-ux';
import { AlertComponent, DropdownComponent, DropdownItem, LoadingOverlayComponent, LoadingOverlayStyle, LoadingStates } from '@sei/common-components-lib-ux';
import { BannerMessageType, PageHeaderMenuComponent, SubpageheaderModel } from '@sei/common-swp-components-lib-ux';
import { ProposalWipAccountStatus, TaxRateDetails, TaxRateFormResponse } from '@sei/ias-applications-lib-ux';
import { OrderedSet } from 'immutable';
import { AccountOwnerDO, CurrentAccountsData, DataDO, DispositionMethod, DispositionMethodData, EditDataObject, EditTaxTransitionData, EnableButtons, ProposedAccountData, TaxTransitionDTO, TaxTransitionDataDetails, TaxTransitionResponse, TitleHeading } from '../model/tax-transition-analysis.model';
import { TaxTransitionAnalysisProxy } from '../proxy/tax-transition-analysis.proxy';

@Component({
    standalone: true,
    selector: 'sei-car-proposal-tax-transition-analysis-form',
    templateUrl: './tax-transition-analysis-form.component.html',
    imports: [
        DropdownComponent,
        FormsModule,
        LoadingOverlayComponent,
        PageHeaderMenuComponent,
        ProposalTaxRateComponent,
        AlertComponent,
        CommonModule
    ]
})

export class ProposalTaxTransitionAnalysisFormComponent implements OnInit {
    @Input()
    public editDataObject: EditTaxTransitionData;
    @Input()
    public proposalId: string;
    @Input()
    public accountOwners: AccountOwnerDO[];
    @Input()
    public mainTitle: string;
    @Input()
    public subtitle?: string;
    @Input()
    set accountOwnerDisable(accountOwnerDisable: boolean) {
        this._accountOwnerDisable = accountOwnerDisable;
        if (accountOwnerDisable) {
            this.isTaxRateDisabled = true;
        } else {
            this.isTaxRateDisabled = false;
        }
    }

    get accountOwnerDisable(): boolean {
        return this._accountOwnerDisable;
    }
    @Output() public disableButtonsEvent: EventEmitter<EnableButtons> = new EventEmitter<EnableButtons>();
    @Output() public taxTransitionDetailsOut: EventEmitter<TaxTransitionDataDetails> = new EventEmitter<TaxTransitionDataDetails>();

    @Input()
    private proposal: Proposal;

    public federalIncome: string = '0.00';
    public federalCapitalGains: string = '0.00';
    public stateIncome: string = '0.00';
    public stateCapitalGains: string = '0.00';
    public autoCompleteMessage: string = '';
    public isTaxRateOk: boolean = true;
    public isDisableSubmitFinal: boolean = true;
    public accountOwner: OrderedSet<DropdownItem> = OrderedSet<DropdownItem>([]);
    public accountOwnerListItems: OrderedSet<DropdownItem> = OrderedSet<DropdownItem>([]);
    public currAccountsSelected: OrderedSet<DropdownItem> = OrderedSet<DropdownItem>([]);
    public currentAccountListItems: OrderedSet<DropdownItem> = OrderedSet<DropdownItem>([]);
    public proposedAccountSelected: OrderedSet<DropdownItem> = OrderedSet<DropdownItem>([]);
    public proposedAccountListItems: OrderedSet<DropdownItem> = OrderedSet<DropdownItem>();
    public taxTransitionHeaderProperties: SubpageheaderModel;
    public taxTransitionSubheaderProperties: SubpageheaderModel;
    public accountDetailsHeaderProperties: SubpageheaderModel = {
        title: TitleHeading.AccountDetails,
        switcherAvailable: false
    };
    public taxRateHeaderProperties: SubpageheaderModel = {
        title: TitleHeading.TaxRateDetails,
        switcherAvailable: false
    };
    public isDisableSubmit: boolean = true;
    public taxRateData: TaxRateDetails;
    public taxTransitionDataOut: TaxTransitionDataDetails;
    public autocompleteState: LoadingStates = LoadingStates.COMPLETE;
    public isTaxRateDisabled: boolean = true;
    public proposedAccountValid: boolean = true;
    public currentAccountValid: boolean = true;
    public _accountOwnerDisable: boolean;
    public selectedEntityId: number = null;
    public selectedEntityType: string = null;
    public typeWarning: BannerMessageType = BannerMessageType.warning;
    public contentTaxRateWarning: string = TitleHeading.ProspectTaxRateWarning;
    public showLoadingIcon: boolean = false;
    public styleInput: LoadingOverlayStyle = LoadingOverlayStyle.Translucent;
    public dispositionMethodSelected: OrderedSet<DropdownItem> = OrderedSet<DropdownItem>([]);
    public dispositionMethodItems: OrderedSet<DropdownItem> = OrderedSet<DropdownItem>([]);
    public dispositionMethodValid: boolean = true;


    constructor(private taxTransitionProxy: TaxTransitionAnalysisProxy) { }
    ngOnInit(): void {
        this.setSubtitle();
        this.fetchTaxTransitionData();
    }

    public formatData(field: number): string {
        const value: string = parseFloat(field.toString()).toFixed(2);
        return value;
    }

    public fetchEditObjDetails(editObj: EditDataObject): void {
        if (editObj.data) {
            editObj.data.forEach((item: DataDO): void => {
                item.taxTransitionDTO.forEach((dtoObj: TaxTransitionDTO): void => {
                    const currentAccounts: DropdownItem[] = [];
                    const acc: DropdownItem[] = [];
                    dtoObj.accountDetails.currentAccounts.forEach((ao: CurrentAccountsData): void => {
                        currentAccounts.push(new DropdownItem(ao.id, ao.currentAccountName));
                        acc.push(new DropdownItem(ao.accountOwners.id, ao.accountOwners.name));
                    });
                    this.currAccountsSelected = OrderedSet<DropdownItem>(currentAccounts);
                    const acOw: DropdownItem[] = acc.filter((v: DropdownItem, i: number, a: DropdownItem[]): boolean => a.indexOf(v) === i);
                    this.accountOwner = OrderedSet<DropdownItem>(acOw);
                    this.getTaxRates();
                    const proposedAccount: DropdownItem[] = [];
                    dtoObj.accountDetails.proposedAccounts.forEach((pa: ProposedAccountData): void => {
                        proposedAccount.push(new DropdownItem(pa.proposalAccountId, pa.accountName));
                    });
                    this.proposedAccountSelected = OrderedSet<DropdownItem>(proposedAccount);
                    if (dtoObj.dispositionMethod && dtoObj.dispositionMethod.length !== 0) {
                        this.dispositionMethodSelected = OrderedSet(this.dispositionMethodItems
                            .filter((ddItem: DropdownItem): boolean => ddItem.name === dtoObj.dispositionMethod
                            ));
                    } else {
                        this.resetDispositionMethod();
                    }
                });
            });
        }
    }

    public getEntityDetails(id: number | string): void {
        if (id && this.accountOwners) {
            this.accountOwners.forEach((accOwner: AccountOwnerDO): void => {
                if (accOwner.id) {
                    if (accOwner.id === id) {
                        this.selectedEntityId = accOwner.entityId ? accOwner.entityId : 0;
                        this.selectedEntityType = accOwner.entityType ? accOwner.entityType : '';
                    }
                }
            });
        }
    }

    public getTaxRates(): void {
        this.showLoadingIcon = true;
        if (this.accountOwner && this.accountOwner.size !== 0) {
            this.accountOwner.forEach((accOwnerObj: DropdownItem): void => {
                this.getEntityDetails(accOwnerObj.id);
            });
            if (this.selectedEntityId || this.selectedEntityType) {
                this.taxTransitionProxy.getTaxRates(this.selectedEntityId, this.selectedEntityType).subscribe(
                    (res: TaxRateFormResponse): void => {
                        if (res && res.data && res.data.taxRatesDto) {
                            if (res.data.taxRatesDto.federalIncome && res.data.taxRatesDto.federalIncome !== 'null') {
                                this.federalIncome = res.data.taxRatesDto.federalIncome;
                            }

                            if (res.data.taxRatesDto.federalCapitalGains && res.data.taxRatesDto.federalCapitalGains !== 'null') {
                                this.federalCapitalGains = res.data.taxRatesDto.federalCapitalGains;
                            }

                            if (res.data.taxRatesDto.stateIncome && res.data.taxRatesDto.stateIncome !== 'null') {
                                this.stateIncome = res.data.taxRatesDto.stateIncome;
                            }
                            if (res.data.taxRatesDto.stateCapitalGains && res.data.taxRatesDto.stateCapitalGains !== 'null') {
                                this.stateCapitalGains = res.data.taxRatesDto.stateCapitalGains;
                            }
                            if (this.federalIncome && this.federalCapitalGains && this.federalIncome !== '0.00' &&
                                this.federalCapitalGains !== '0.00') {
                                this.isTaxRateOk = true;
                            } else {
                                this.isTaxRateOk = false;
                            }
                            this.enableSubmitButton();
                        }
                        this.showLoadingIcon = false;
                    },
                    (): void => {
                        this.resetTaxRates();
                        this.showLoadingIcon = false;
                    }
                );
            } else {
                this.showLoadingIcon = false;
            }
        } else {
            this.showLoadingIcon = false;
        }
    }

    public resetTaxRates(): void {
        this.federalIncome = '0.00';
        this.federalCapitalGains = '0.00';
        this.stateCapitalGains = '0.00';
        this.stateIncome = '0.00';
    }

    public setSubtitle(): void {
        this.taxTransitionHeaderProperties = {
            title: this.mainTitle ? this.mainTitle : '',
            switcherAvailable: false
        };

        this.taxTransitionSubheaderProperties = {
            title: this.subtitle ? this.subtitle : '',
            switcherAvailable: false
        };
    }

    public taxRateOut(event: boolean): void {
        this.isTaxRateOk = event;
        this.enableSubmitButton();
    }

    public taxRateDataOut(event: TaxRateDetails): void {
        this.taxRateData = event;
    }

    public sendTaxTransitionDataOut(): void {
        this.taxTransitionDataOut = {
            accountOwner: this.accountOwner ? this.accountOwner : null,
            taxRateData: this.taxRateData ? this.taxRateData : null,
            currentAccounts: this.currAccountsSelected ? this.currAccountsSelected : null,
            proposedAccount: this.proposedAccountSelected ? this.proposedAccountSelected : null,
            dispositionMethod: this.dispositionMethodSelected
        };
        this.taxTransitionDetailsOut.emit(this.taxTransitionDataOut);
    }

    public fetchTaxTransitionData(): void {
        this.accountOwnerListItems = this.assignAccountOwner();
        if (this.proposalId) {
            this.taxTransitionProxy.getTaxTransitionData(this.proposalId).subscribe(
                (response: TaxTransitionResponse): void => {
                    if (response && response.data) {
                        if (response.data.currentAccounts) {
                            this.currentAccountListItems = this.processCurrentAccountList(response.data.currentAccounts);
                        }

                        if (response.data.proposedAccounts) {
                            this.proposedAccountListItems = this.processProposedAccountList(response.data.proposedAccounts);
                        }
                        this.dispositionMethodItems = this.populateDispositionMethod();
                        this.resetDispositionMethod();
                        if (this.editDataObject && this.editDataObject.isAddFlag) {
                            this.showLoadingIcon = true;
                            this.resetTaxRates();
                            this.showLoadingIcon = false;
                        }
                        if (this.editDataObject && !this.editDataObject.isAddFlag && this.editDataObject.editDO) {
                            this.fetchEditObjDetails(this.editDataObject.editDO);
                        }
                    }
                    this.enableSubmitButton();
                },
                (): void => {
                    this.resetFetchedDropdownValues();
                }
            );
        } else {
            this.resetFetchedDropdownValues();
        }
    }

    public processProposedAccountList(data: ProposedAccountData[]): OrderedSet<DropdownItem> {
        const proposed: ProposedAccountData[] = data;
        const dropdownListPropAcc: DropdownItem[] = [];
        if (proposed !== null && proposed.length !== 0) {
            proposed.forEach((element: ProposedAccountData): void => {
                if (this.isAccountComplete(Number(element.proposalAccountId))) {
                    dropdownListPropAcc.push(new DropdownItem(element.proposalAccountId, element.accountName));
                }
            });
        }
        return OrderedSet<DropdownItem>(dropdownListPropAcc);
    }

    public processCurrentAccountList(data: CurrentAccountsData[]): OrderedSet<DropdownItem> {
        const curr: CurrentAccountsData[] = data;
        const dropdownListCurrAcc: DropdownItem[] = [];
        if (curr !== null && curr.length !== 0) {
            curr.forEach((element: CurrentAccountsData): void => {
                dropdownListCurrAcc.push(new DropdownItem(element.id, element.currentAccountName));
            });
        }
        return OrderedSet<DropdownItem>(dropdownListCurrAcc);
    }

    public resetFetchedDropdownValues(): void {
        this.currentAccountListItems = OrderedSet<DropdownItem>([]);
        this.proposedAccountListItems = OrderedSet<DropdownItem>([]);
        this.resetDispositionMethod();
    }

    public assignAccountOwner(): OrderedSet<DropdownItem> {
        const dropdownListAcc: DropdownItem[] = [];
        if (this.accountOwners) {
            this.accountOwners.forEach((accOwner: AccountOwnerDO): void => {
                dropdownListAcc.push(new DropdownItem(accOwner.id, accOwner.name));
            });
        }
        return OrderedSet<DropdownItem>(dropdownListAcc);
    }

    public onAccountOwnerChange(): void {
        this.getTaxRates();
        this.isTaxRateDisabled = (this.accountOwner === undefined);
        this.enableSubmitButton();
    }

    public enableSubmitButton(): void {
        this.isTaxRateDisabled = (this.accountOwner === undefined) ? (this.accountOwner === undefined) : (this.accountOwner.size === 0);
        if (this.proposedAccountSelected && this.proposedAccountSelected.size !== 0) {
            this.proposedAccountSelected.forEach((item: DropdownItem): void => {
                if (item.id === undefined || item.id === null) {
                    this.proposedAccountValid = false;
                } else {
                    this.proposedAccountValid = true;
                }
            });
        } else {
            this.proposedAccountValid = false;
        }

        if (this.currAccountsSelected !== undefined) {
            this.currAccountsSelected.forEach((dataItem: DropdownItem): void => {
                if (dataItem.id === undefined || dataItem.id === null) {
                    this.currentAccountValid = false;
                } else {
                    this.currentAccountValid = true;
                }
            });
        }

        if (this.dispositionMethodSelected && this.dispositionMethodSelected.size !== 0) {
            this.dispositionMethodSelected.forEach((item: DropdownItem): void => {
                if (item.id === undefined || item.id === null) {
                    this.dispositionMethodValid = false;
                } else {
                    this.dispositionMethodValid = true;
                }
            });
        } else {
            this.dispositionMethodValid = false;
        }

        if (this.accountOwner !== undefined) {
            if (this.proposedAccountSelected && this.proposedAccountSelected.size !== 0 && this.currAccountsSelected !== undefined
                && this.accountOwner.size !== 0) {
                this.isDisableSubmit = !(this.currAccountsSelected.size !== 0 && this.proposedAccountValid
                    && this.currentAccountValid && this.dispositionMethodValid);
            } else {
                this.isDisableSubmit = true;
            }
        } else {
            this.isDisableSubmit = true;
        }

        this.isDisableSubmitFinal = (!this.isDisableSubmit && this.isTaxRateOk) ? false : true;
        const buttonStatus: EnableButtons = {
            disableSaveExit: this.isTaxRateDisabled,
            disableSubmit: this.isDisableSubmitFinal
        };
        this.disableButtonsEvent.emit(buttonStatus);
        this.sendTaxTransitionDataOut();
    }

    private resetDispositionMethod(): void {
        if (this.dispositionMethodItems && this.dispositionMethodItems.size > 0) {
            this.dispositionMethodSelected = OrderedSet(this.dispositionMethodItems
                .filter((item: DropdownItem): boolean => item.id === 'LT'));
        }
    }

    private populateDispositionMethod(): OrderedSet<DropdownItem> {
        const dispositionMethod: DropdownItem[] = [];
        const dispositionMethodArr: DispositionMethodData[] = Object.keys(DispositionMethod).map(
            function (type: string): DispositionMethodData {
                return { id: type, value: DispositionMethod[type] };
            });
        if (dispositionMethodArr !== null && dispositionMethodArr.length !== 0) {
            dispositionMethodArr.forEach((element: DispositionMethodData): void => {
                dispositionMethod.push(new DropdownItem(element.id, element.value));
            });
        }
        return OrderedSet<DropdownItem>(dispositionMethod);
    }

    private isAccountComplete(proposalAccountId: number): boolean {
        let isAccountComplete: boolean = false;
        if (this.proposalId && this.proposal && this.proposal?.wipCheckList?.length) {
            const proposalWipChecklist: WipCheckList = this.proposal?.wipCheckList?.find(
                (checklist: WipCheckList): boolean => Number(this.proposalId) === this.getIdFromRoute(checklist?.route));
            if (proposalWipChecklist && proposalWipChecklist?.subCheckList?.length) {
                isAccountComplete = proposalWipChecklist?.subCheckList?.some((checklist: WipCheckList): boolean => {
                    return this.getIdFromRoute(checklist?.route) === proposalAccountId &&
                        !this.isMrdcIncomplete(checklist?.mdcSnapShot);
                });
            }
        }
        return isAccountComplete;
    }

    private isMrdcIncomplete(mrdcSnapshot: MdcSnapshot): boolean {
        return (mrdcSnapshot?.completed === ProposalWipAccountStatus.Incomplete) ||
            (mrdcSnapshot?.completed === ProposalWipAccountStatus.New || !mrdcSnapshot);
    }

    private getIdFromRoute(route: string): number {
        return Number(route.split('/').pop());
    }
}
