
<div class="wip-summary-row pageheader account-body-row">
    <div class="col-xs-12 indent-left">
        <sei-pageheader [headingLevel]="5"
                        [pageHeaderProperties]="nonModelPageHeaderProperties">
            <div extra-content>
                <span *ngIf="!isPortfolioAssetDataLoading" class="header-amount">
                {{ { value: calculateNonModelInvestmentTotal(portfolios), actualCurrency: 'USD' } | seiDisplayMonetaryAmount:amountOptions:roundFractionalNumber:displayNumericScaleSuffix}}
                </span>
                <span *ngIf="isPortfolioAssetDataLoading">
                    <sei-loading multiplier="1" [showLoadingIcon]="true"></sei-loading>
                </span>
                <a class="section-arrow" [class.open]="showNonModelInvestmentDetails" aria-label="Expand Investment Details Section" (click)="toggleShowNonModelInvestmentDetails()">
                    <i class="fas" [class.fa-chevron-right]="!showNonModelInvestmentDetails" [class.fa-chevron-down]="showNonModelInvestmentDetails"></i>
                </a>
            </div>
        </sei-pageheader>
    </div>
</div>
<div *ngIf="showNonModelInvestmentDetails">
    <div *ngIf="!isPortfolioAssetDataLoading">
        <div class="wip-summary-row account-body-row"
             *ngFor="let nonModelPortfolio of portfolios">
            <div class="indent-left investment-row">
                <div class="col-xs-6">
                    <span>{{ nonModelPortfolio.portfolioName }}</span>
                </div>
                <div class="col-xs-6">
                    <span class="pull-right">{{ {value: calculateValueOfNonModelPortfolio(nonModelPortfolio), actualCurrency: 'USD' } | seiDisplayMonetaryAmount:amountOptions:roundFractionalNumber:displayNumericScaleSuffix}}</span>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="isPortfolioAssetDataLoading"
         class="wip-summary-row account-body-row">
        <div class="indent-left investment-row text-center">
            <sei-loading multiplier="1"
                         [showLoadingIcon]="true"></sei-loading>
        </div>
    </div>
</div>
<div *ngIf="!showNonModelInvestmentDetails && portfolios.length > 0"
     class="wip-summary-row account-body-row">
    <div class="indent-left investment-row">
        <span class="show-more">
                <i class="fas fa-info-circle"></i> {{ SHOW_MORE_MESSAGE }}
            </span>
    </div>
</div>