/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, OnInit } from '@angular/core';
import { AccountType, CommandText, DelegationService, EntitySuffix, Firm, FirmAdvisorDisplayMode, FirmAdvisorLabel, Proposal, ProposalService, ReferenceDataService, UserLevelEntitlement, UserProfile, UserProfileService } from '@sei/advisor-client-review-proposal-ux';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { ScenarioFactory } from 'projects/advisor-client-review-proposal-ux/src/lib/model/factory/scenario-factory';
import { Observable, forkJoin } from 'rxjs';

@Component({
    selector: 'car-mdc-proposal-component',
    templateUrl: './proposal.component.html'
})
export class MdcProposalComponent extends ParentComponentSubscriptionManager
    implements OnInit {
    public showProposedScenario = false;
    public accountTypeData: AccountType[] = [];
    public suffixData: EntitySuffix[] = [];
    public proposal: Proposal;
    public userProfile: UserProfile;
    private displayModes: typeof FirmAdvisorDisplayMode = FirmAdvisorDisplayMode;
    private firmAdvisorLabels: typeof FirmAdvisorLabel = FirmAdvisorLabel;
    private userLevel: typeof UserLevelEntitlement = UserLevelEntitlement;
    public proposalStream: Observable<Proposal>;
    private entitledAdvisorsEntityIds: number[] = [];
    private firm: Firm;
    private showFirms: boolean = false;
    private showProposal: boolean = true;
    private firmId: number = 1;

    constructor(
        private delegationService: DelegationService,
        private referenceDataService: ReferenceDataService,
        private proposalService: ProposalService,
        private userProfileService: UserProfileService
    ) {
        super('MdcProposalComponent');
        // Note: So for now we will always create new proposal.
        this.proposal = this.proposalService.createFactoryProposal();
        this.proposalService.changedProposal(this.proposal);
        this.proposalStream = this.proposalService.currentProposal;
        this.userProfile = this.userProfileService.getCurrentUserProfile;
    }

    ngOnInit(): void {
        if (this.userProfile && this.userProfile.firm) {
            this.firmId = this.userProfile.firm.firmId;
            if (this.proposal.firmId === 1) {
                this.proposal.firmId = this.firmId;
            }
            if (this.proposal.firm.firmId === 0) {
                this.proposal.firm = this.userProfile.firm;
            }
        }
        // Note: check first if logged in user is instance and grant access to only selected advisors.
        if (this.userProfile.entitlements.userLevelId === UserLevelEntitlement.PO ||
            this.userProfile.entitlements.userLevelId === UserLevelEntitlement.Instance) {
            this.showFirms = true;
            this.showProposal = false;
        }
        const delegateService = this.delegationService
            .refresh()
            .subscribe((data) => {
                if (data === CommandText.ProposeScenario && !this.proposal.scenarios[0]) {
                    // Update proposal object in behavior subject to next
                    const scenario = new ScenarioFactory().createObject(0);
                    scenario.accounts = [];
                    scenario.name = '';
                    this.proposal.scenarios.push(scenario);
                    this.proposalService.changedProposal(this.proposal);
                    // Show Proposed Scenario
                    this.showProposedScenario = true;
                    // Add clients to elastic search
                    this.addClientsToElasticSearch();
                }
            });

        const suffixData = this.referenceDataService.getData('suffixes');

        const forkedServices = forkJoin([
            suffixData
        ]).subscribe((results) => {
            if (results) {
                this.suffixData = results[0]['suffixes'];
            }
        });

        this.subscriptions.push(delegateService, forkedServices);
        this.delegationService.publish(CommandText.ProposeScenario);
    }

    private addClientsToElasticSearch(): void {
        this.proposalService.addClientsToElasticSearch(this.proposal.clients);
    }

    public firmCleared() {
        this.proposal.clients = [];
        this.entitledAdvisorsEntityIds = [];
        this.userProfileService.setEtitledAdvisorsEntityIds(this.entitledAdvisorsEntityIds);
        this.userProfile.firm = null;
        this.showProposal = false;
        this.proposal.firmId = null;
        this.proposal.firm = null;
        this.firm = null;
        this.userProfileService.updateCurrentUserProfileFirm(this.firm);
    }

    public firmSelected(event: Firm): void {
        this.showProposal = true;
        this.proposal.firmChangeFlag = this.proposal.clients.length > 0;
        this.firm = event;
        this.userProfile.firm = event;
        this.proposal.firmId = event.firmId;
        this.proposal.firm = event;
        this.userProfileService.updateCurrentUserProfileFirm(this.firm);
    }
}
