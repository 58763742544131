/*
 Copyright: This information constitutes the exclusive property of SEI
 Investments Company, and constitutes the confidential and proprietary
 information of SEI Investments Company.  The information shall not be
 used or disclosed for any purpose without the written consent of SEI
 Investments Company.
*/

export class InfoMessages {
    public static goalDateSelection(): string {
        return `The Goal Start Date cannot predate either July 1st 2000 or
         the earliest account inception date of any account supporting a given goal`;

    }
}
