/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { AchFrequency } from '@CarInterfaces/ach-frequency';
import {
    FundingSourceDetail,
    FundingSourceEntity
} from '@CarInterfaces/funding';
import { AchFrequencyType, AchQuarterly } from '@CarModels/enums';

export abstract class FundingSourceDetailBase implements FundingSourceDetail {
    fundingSourceEntity: FundingSourceEntity;

    constructor(fundingSourceEntity: FundingSourceEntity) {
        this.fundingSourceEntity = fundingSourceEntity;
    }

    public validateMinimumData(): boolean {
        if (!this.fundingSourceEntity || this.fundingSourceEntity.amount <= 0) {
            return false;
        }

        return true;
    }

    public isComplete(): boolean {
        if (!this.validateMinimumData()) {
            return false;
        }

        if (this.fundingSourceEntity && this.fundingSourceEntity.amount > 0) {
            return true;
        }

        return false;
    }

    public getSourceName(): string {
        if (
            this.fundingSourceEntity &&
            this.fundingSourceEntity.fundingSource &&
            this.fundingSourceEntity.fundingSource.fundingSourceDescription
        ) {
            return this.fundingSourceEntity.fundingSource
                .fundingSourceDescription;
        }

        return '';
    }
}

export class FundingSourceCheck extends FundingSourceDetailBase {
    constructor(fundingSourceEntity: FundingSourceEntity) {
        super(fundingSourceEntity);
    }
}

export class FundingSourceWire extends FundingSourceDetailBase {
    constructor(fundingSourceEntity: FundingSourceEntity) {
        super(fundingSourceEntity);
    }
}

export class FundingSourceAch extends FundingSourceDetailBase {
    constructor(fundingSourceEntity: FundingSourceEntity) {
        super(fundingSourceEntity);
    }

    public validateMinimumData(): boolean {
        if (!super.validateMinimumData()) {
            return false;
        }

        const frequency: AchFrequency = this.fundingSourceEntity.achFrequency;
        const startDate: Date = this.fundingSourceEntity.achStartDate;
        const endDate: Date = this.fundingSourceEntity.achEndDate;
        const startDateOption = this.fundingSourceEntity.achStartDateOption;

        if (!frequency || frequency.achFrequencyId === 0) {
            return false;
        }

        if (frequency.achFrequencyId !== AchFrequencyType.OneTime) {
            if (
                !startDateOption ||
                startDateOption.achStartDateOptionId === 0
            ) {
                return false;
            }

            if (startDate && endDate && startDate < endDate) {
                switch (frequency.achFrequencyId) {
                    case AchFrequencyType.Monthly:
                    case AchFrequencyType.Quarterly:
                        {
                            if (
                                startDate.getDate() > AchQuarterly.MaxDate ||
                                endDate.getDate() > AchQuarterly.MaxDate
                            ) {
                                return false;
                            }
                        }
                        break;
                }
            } else {
                return false;
            }
        }

        return true;
    }
}

export class FundingSourceTransfer extends FundingSourceDetailBase {
    constructor(fundingSourceEntity: FundingSourceEntity) {
        super(fundingSourceEntity);
    }

    public validateMinimumData(): boolean {
        if (!super.validateMinimumData()) {
            return false;
        }

        const transferType = this.fundingSourceEntity.fundingSourceTransferType;
        const custodian = this.fundingSourceEntity.transferFromCustodian;
        const externalAccountType = this.fundingSourceEntity
            .transferFromExternalAccountType;

        if (
            !externalAccountType ||
            externalAccountType.transferFromExternalAccountTypeId <= 0
        ) {
            return false;
        }

        if (!custodian || !custodian.organizationName) {
            return false;
        }

        if (!transferType || transferType.fundingSourceTransferTypeId === 0) {
            return false;
        }

        return true;
    }

    public isComplete(): boolean {
        if (!super.isComplete()) {
            return false;
        }

        return true;
    }

    public getSourceName(): string {
        let sourceName = super.getSourceName();

        if (
            this.fundingSourceEntity &&
            this.fundingSourceEntity.fundingSourceTransferType &&
            this.fundingSourceEntity.fundingSourceTransferType
                .fundingSourceTransferTypeDescription
        ) {
            sourceName = `${sourceName} ${this.fundingSourceEntity.fundingSourceTransferType.fundingSourceTransferTypeDescription}`;
        }

        return sourceName;
    }
}
