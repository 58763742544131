<div class="row">
    <div class="col-xs-12 account-panel-title">
        <h1>{{bookMarkName}}</h1>
        <h2>{{account?.name}}</h2>
    </div>
</div>

<section id="select-strategy" *ngIf="!toggleSummarySelection">
    <sei-car-strategy-menu-component [selectedMenuItem]="selectedMenuItem"
        [filteredStrategiesExist]="!this.filteredStrategies || this.filteredStrategies.length === 0"
        (menuItemClicked)="menuItemClicked($event)"></sei-car-strategy-menu-component>

    <div *ngIf="this.enumMenuSelection.ExploreStrategy === selectedMenuItem" class="section-gap">
        <sei-car-explore-strategy [account]="account" [firmId]="this.firmId" [riskToleranceScore]="riskToleranceScore"
            [strategyCategories]="strategyCategories">
        </sei-car-explore-strategy>
    </div>

    <div *ngIf="this.enumMenuSelection.PreviouslyUsed === selectedMenuItem" class="section-gap">
        <sei-car-previously-used-strategy [account]="account" [filteredStrategies]="filteredStrategies"
            [riskToleranceScore]="riskToleranceScore">
        </sei-car-previously-used-strategy>
    </div>

    <div *ngIf="this.enumMenuSelection.CustomizeStrategy === selectedMenuItem" class="section-gap">
        ...Customize Strategy
    </div>
</section>

<section id="SummarySelection" *ngIf="toggleSummarySelection && account">
    <div class="strategy-summary-error" *ngIf="validationErrorMessage && snapPointCombinedStrategyRisk">
        <span class="pill alert">{{ validationErrorMessage }} </span>
    </div>
    <car-risk-widget class="animated fadein slow" [riskToleranceScore]="riskToleranceScore"
        [snapPointCombinedStrategyRisk]="snapPointCombinedStrategyRisk" [strategyRiskScore]="strategyRiskScore"
        [riskWidgetType]="riskWidgetTypes.StrategySummary">
    </car-risk-widget>
    <car-strategy-ribbon [strategy]="strategy" [strategyIndex]="i" [account]="account"
        [ribbonType]="ribbonTypes.StrategySummary" (accountChange)="onAccountChange()"
        *ngFor="let strategy of account.strategies; let i = index">
    </car-strategy-ribbon>
    <div class="section-gap gap-12">
        <button type="button" class="btn add-content unselectable" (click)="exploreStrategies()"
            [disabled]="disableAdditionalStrategy">
            <i class="fas fa-plus-circle"></i>
            Additional Strategy
        </button>
    </div>
</section>

<sei-car-heads-up-display *ngIf="account && account.strategies.length > 1 && toggleSummarySelection"
    [hudFeeStrategy]="hudFeeSchedule">
</sei-car-heads-up-display>
