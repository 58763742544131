<div class="strategy-description">
    {{(allocationStrategy.hudSummary.investmentCost?.rate) * 100 | seiDisplayPercent:displayPercentOptions}} Investment
    Cost
    = {{(allocationStrategy.hudSummary.fundExpense?.rate) * 100 | seiDisplayPercent:displayPercentOptions}} Fund Expense
    + {{(allocationStrategy.hudSummary.managementExpense?.rate) * 100 | seiDisplayPercent:displayPercentOptions}}
    Management Fee
    <!-- 0.0% Platform Fee<br> Take out per Paul Flamiano 3/3/2020 - Not necesary for M1-->
</div>
<article class="form-row strategy-description"
         *ngFor="let item of allocationStrategy.allocations; let i=index;">
    <label for="strategy-allocation-dropdown"
           class="bold strategy-drawer-item-label">{{ item.percentage | percent:
        '1.1-2' }}</label>
    <select id="strategy-allocation-dropdown"
            [disabled]="disableStrategyAllocation"
            class="custom-select-control">
        <option [value]="item.detailId">{{ item.displayName }}
        </option>
    </select>
</article>
