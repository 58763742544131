/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { DynamicErrorInfo } from '@CarModels/dynamic-error-info';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { Observable, Subscription } from 'rxjs';
import { GenericError } from '../../model/generic-error';
import { GenericErrorService } from '../../service/system/generic-error.service';

@Component({
    selector: 'car-generic-error-notification',
    templateUrl: './generic-error-notification.component.html'
})
export class GenericErrorNotificationComponent extends ParentComponentSubscriptionManager implements OnInit, OnDestroy {
    public error: GenericError;
    public errorDescription: DynamicErrorInfo;
    error$: Observable<GenericError>;

    public constructor(private errorHandler: GenericErrorService) {
        super('GenericErrorNotificationComponent');
    }

    public ngOnInit() {
        this.error$ = this.errorHandler.getErrorStream();
        this.subscriptions.push(this.errorHandler.getErrorNumber().subscribe((errorString: DynamicErrorInfo) => {
            this.errorDescription = errorString;
        }));
    }

    public ngOnDestroy() {
        this.errorHandler.clearGenericError();
        if (this.subscriptions) {
            this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
        }
    }
}
