<div class="generic-error-notification"
     *ngIf="error$ | async as error">
    <div class="error-container">
        <i class="fas fa-exclamation-triangle error-icon"></i>
        <div class="error-text">
            <h1>System Error</h1>
            <label class="car-main-sm-body-copy"
                   class="error"><br>
                Error {{ error.code }} {{error.description}}</label>
            <label class="car-main-sm-body-copy">
                Please reach out to your SEI Service Team at {{errorDescription.errorInfo}}.
            </label>
        </div>
    </div>
</div>
