/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { Observable, Subscription, of } from 'rxjs';
import { EntityType, FirmAdvisorLabel } from '../../../model/enums';
import { Firm, FirmAdvisorResult } from '../../../model/user-profile';
import { FirmAdvisorService } from '../../../service/firm-advisor.service';

@Component({
    selector: 'sei-car-firm-advisor-search',
    templateUrl: './firm-advisor-search.component.html',
    encapsulation: ViewEncapsulation.None
})
export class FirmAdvisorSearchComponent extends ParentComponentSubscriptionManager implements OnInit {

    @Input()
    public firm: Firm;

    @Input()
    public entitledAdvisorsEntityIds: number[];

    @Output()
    public selectedValue = new EventEmitter<FirmAdvisorResult>();

    @Output()
    public onCreateCustomAsset = new EventEmitter();

    @Output()
    public onKeyUp = new EventEmitter();

    public firmName: string;
    public searchStream: Observable<FirmAdvisorResult[]>;

    private subscriptionToDataService: Subscription;

    constructor(
        private firmAdvisorService: FirmAdvisorService
    ) {
        super('FirmAdvisorSearchComponent');
    }

    ngOnInit(): void {
        if (this.firm && this.firm.firmId > 0) {
            this.firmName = this.firm.firmName;
            const firmAdvisorResult: FirmAdvisorResult = {
                id: this.firm.firmId,
                name: this.firm.firmName,
                type: EntityType.Firm
            };
            this.selectedValue.emit(firmAdvisorResult);
        }
    }

    public searchFirmAdvisor(event) {
        this.onKeyUp.emit();
        const searchTerm = event.target.value;
        if (searchTerm.length > 2) {
            this.retrieveSearchAssetResults(searchTerm);
        } else {
            this.searchStream = of([]);
        }
    }

    private retrieveSearchAssetResults(term: string) {
        this.subscriptionToDataService = this.firmAdvisorService.getFirmAdvisorsSearch(term).subscribe(
            (result: FirmAdvisorResult[]) => {
                if (result.length > 0) {
                    result = result.filter((firmAdvisorResult: FirmAdvisorResult) => firmAdvisorResult.type === FirmAdvisorLabel.Firm);
                    this.searchStream = of(result);
                } else {
                    this.searchStream = of([]);
                }
            }
        );
        this.subscriptions.push(this.subscriptionToDataService);
    }

    public onTypeAheadSelect(asset: FirmAdvisorResult): void {
        this.selectedValue.emit(asset);
        this.firmName = asset.name;
        this.searchStream = of([]);
    }
}
