/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
import { Component, EventEmitter, Output } from '@angular/core';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';

@Component({
    selector: 'car-file-upload-modal-footer',
    templateUrl: './file-upload-modal-footer.component.html'
})
export class FileUploadModalFooterComponent extends ParentComponentSubscriptionManager {


    @Output()
    public onCancel = new EventEmitter();

    constructor(
    ) {
        super('FileUploadModalFooterComponent');
    }

    public onClickCancel() {
        this.onCancel.emit();
    }

}
