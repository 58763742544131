/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

/* eslint-disable */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WizardModel, WizardState } from '@sei/common-components-lib-ux';
import { AbstractValueAccessor, MakeValueAccessorProvider } from '@sei/common-swp-components-lib-ux';

@Component({
    selector: 'sei-car-toggle-include-exclude',
    templateUrl: './toggle-include-exclude.component.html',
    providers: [MakeValueAccessorProvider(ToggleIncludeExcludeComponent)]
})
export class ToggleIncludeExcludeComponent extends AbstractValueAccessor<boolean> implements OnInit {

    @Input('value')
    public toggleValue: boolean;

    @Input('minWidth')
    minWidth: number = 15;

    @Input('wizardSteps')
    wizardSteps: WizardModel[] = [
        { name: 'Include', state: WizardState.ACTIVE },
        { name: 'Exclude', state: WizardState.ENABLE }
    ];

    @Input('isCustomModel')
    public isCustomModel: boolean = false;

    @Input('isDisabled')
    public isDisabled: boolean = false;

    @Input()
    public isReadUser: boolean;

    @Output()
    public onChangeEvent = new EventEmitter();

    public onChange: any = () => { };

    get value() {
        return this.toggleValue;
    }

    set value(value) {
        this.toggleValue = value;
        this.setToggleValueState();
        this.onChange(value);
    }

    ngOnInit(): void {
        this.setToggleValueState();
    }

    private setToggleValueState(): void {

        if (this.isReadUser) {
            this.wizardSteps[0].state = WizardState.DISABLE;
            this.wizardSteps[1].state = WizardState.DISABLE;
            return;
        }

        if (this.isDisabled) {
            this.wizardSteps[0].state = WizardState.DISABLE;
            this.wizardSteps[1].state = WizardState.ACTIVE;
            return;
        }

        if (this.toggleValue) {
            // Note: We will set 1st item as active
            this.wizardSteps[0].state = WizardState.ACTIVE;
            this.wizardSteps[1].state = WizardState.ENABLE;
        } else {
            this.wizardSteps[0].state = WizardState.ENABLE;
            this.wizardSteps[1].state = WizardState.ACTIVE;
        }
    }

    writeValue(value: boolean): void {
        if (value) {
            this.value = value;
        }
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        // this.onTouched = fn;
    }

    onWizardStepselect(selectedItem: WizardModel): void {
        if (selectedItem && selectedItem.state !== WizardState.DISABLE && selectedItem.state !== WizardState.HIDDEN) {
            if (!this.isCustomModel) {
                this.value = (selectedItem.name === 'Include');
            } else if (this.wizardSteps && this.wizardSteps[0]) {
                this.value = selectedItem.name === this.wizardSteps[0].name;
            }
            if (this.value !== undefined) {
                this.removeActive();
                selectedItem.state = WizardState.ACTIVE;
                this.onChangeEvent.emit(this.value);
            }
        }
    }

    removeActive(): void {
        if (this.wizardSteps.find((step) => step.state === WizardState.ACTIVE)) {
            this.wizardSteps.find((step) => step.state === WizardState.ACTIVE).state = WizardState.ENABLE;
        }
    }
}
