
/*
 Copyright: This information constitutes the exclusive property of SEI
 Investments Company, and constitutes the confidential and proprietary
 information of SEI Investments Company.  The information shall not be
 used or disclosed for any purpose without the written consent of SEI
 Investments Company.
*/

import { Injectable } from '@angular/core';

@Injectable()
export class NumberEvaluator {
    public between(value: number, min: number, max: number) {
        return value >= min && value <= max;
    }
}
