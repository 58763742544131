/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { Account } from '@CarInterfaces/account';
import { BaseFactory } from '@CarInterfaces/base-factory';
import { AccountOwnerDetailFactory } from './account-owner-detail-factory';

@Injectable({
    providedIn: 'root'
})
export class AccountMrdcFactory implements BaseFactory<Account> {
    public createObject(): Account {
        const newAccountOwner = new AccountOwnerDetailFactory().createObject();

        // For MRDC we need to delete contactDeliveryPreferences to avoid overwrite
        // its values when a new account is created with a pre-existing CAR owner
        delete newAccountOwner.contactDeliveryPreferences;

        return {
            accountId: 0,
            accountNumber: 0,
            accountType: undefined,
            investmentProgram: undefined,
            accountOwners: [newAccountOwner],
            accountAdvisors: [],
            firm: {
                firmId: 1,
                firmName: '',
                swpFirmId: -1,
                advisors: [],
                locations: []
            },
            createDate: new Date()
        };
    }
}
