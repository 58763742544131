/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, OnInit } from '@angular/core';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { Subscription } from 'rxjs';
import { CommandText } from '../../model/enums';
import { DelegationService } from '../../service/delegation.service';

@Component({
    selector: 'sei-car-paging-next-previous-button',
    templateUrl: './paging-next-previous-button.component.html'
})
export class PagingNextPreviousButtonComponent extends ParentComponentSubscriptionManager implements OnInit {
    public disableNextButton: boolean = false;
    public disablePrevButton: boolean = false;
    public subscriptionToDataService: Subscription;

    public constructor(private delegationService: DelegationService) {
        super('PagingNextPreviousButtonComponent');
    }

    public ngOnInit(): void {
        this.subscriptionToDataService = this.delegationService.refresh().subscribe(
            (data: string) => {
                if (data === CommandText.DisableNextButton) {
                    this.disableNextButton = true;
                }
                if (data === CommandText.EnableNextButton) {
                    this.disableNextButton = false;
                }
                if (data === CommandText.DisablePrevButton) {
                    this.disablePrevButton = true;
                }
                if (data === CommandText.EnablePrevButton) {
                    this.disablePrevButton = false;
                }

            }
        );

        this.subscriptions.push(this.subscriptionToDataService);
        super.ngOnInit();
    }

    public goToPrev(): void {
        this.delegationService.publish(CommandText.GoToPrev);
    }

    public goToNext(): void {
        this.delegationService.publish(CommandText.GoToNext);
    }
}
