<sei-dashboard-tile [title]="labelText"
                    panelClass="panel-default">
    <div panel-heading-extra-content>
        <sei-button *ngIf="!checkAccountInProgress()"
                    [buttonDisabled]="!enableInvestmentSelection()"
                    buttonText="Select Investments"
                    buttonClass="btn-link"
                    (buttonClicked)="navigateToInvestmentSummary()"></sei-button>
        <sei-button *ngIf="checkAccountInProgress()"
                    [buttonDisabled]="!enableInvestmentSelection()"
                    buttonText="Modify Investments"
                    buttonClass="btn-link"
                    (buttonClicked)="navigateToInvestmentSummary()"></sei-button>
        <span class="card-pipe">|</span>
        <sei-button buttonRightFontAwesomeIcon="fa-trash-alt"
                    buttonClass="btn-link"
                    (buttonClicked)="accountDeleteClicked()"></sei-button>
    </div>
    <div panel-content>
        <div class="row">
            <div class="col-xs-6 form-group"
                 [ngClass]="{'has-error': !isAmountValid}">
                <label class="card-label"
                       for="amountInput">Amount</label>
                <input class="form-control"
                       seiEditMonetaryAmount
                       [amountOptions]="amountOptions"
                       actualCurrency="USD"
                       type="text"
                       id="amountInput"
                       [value]="account?.balance"
                       (output)="onAccountBalanceChange($event)"
                       (error)="onAccountBalanceError($event)">
                <span class="help-block" *ngFor="let errorMsg of amountErrors">{{ errorMsg }}</span>
            </div>
            <div class="col-xs-6 form-group">
                <label class="card-label"
                       for="accountTypeDropdown">Account Type</label>
                <sei-dropdown id="accountTypeDropdown"
                              [items]="accountTypesSet"
                              [ngModel]="selectedAccountTypeSet"
                              (ngModelChange)="onAccountTypeDropdownChange($event)"></sei-dropdown>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-6">
                <label class="card-label">Investment Program</label>
                <sei-dropdown id="investmentProgramDropdown"
                              [items]="investmentProgramDropdownItems"
                              [disabled]="!this.accountTypeId"
                              [ngModel]="selectedInvestmentProgramSet"
                              (ngModelChange)="onInvestmentProgramChange($event)"></sei-dropdown>
            </div>
            <div class="col-xs-6"
                 *ngIf="isTypeOfOwnerRequired()">
                <label class="card-label">Type of Owner</label>
                <sei-dropdown id="typeOfOwnerDropdown"
                              [items]="typeOfOwnersDropdownItems"
                              [disabled]="!this.accountTypeId"
                              [ngModel]="selectedtypeOfOwnersDropdownItems"
                              (ngModelChange)="onTypeOfOwnerChange($event)"></sei-dropdown>
            </div>
        </div>
        <div class="row card-owner-section"
             *ngFor="let client of account?.owners; let i=index">
            <div class="col-xs-12 form-group">
                <sei-car-search-owner-form [index]="i"
                                           [client]="client"
                                           [entitySearchEnabled]="false"
                                           [clientList]="proposalClients"
                                           [selectedClientList]="clientList"
                                           [isHeaderLabel]="true"
                                           [accountTypeId]="accountTypeId"
                                           [typeOfOwnerId]="typeOfOwnerId"
                                           [requireTypeOfOwner]="isTypeOfOwnerRequired()"
                                           (onClientChange)="onClientChange($event, i)"
                                           (onOwnerDelete)="onClientDeleted($event)"></sei-car-search-owner-form>
            </div>
        </div>
        <div class="row">
            <sei-button *ngIf="enableAddOwner && clientList.length < 10 && allowJointOwners"
                        buttonText="Add Owner"
                        (buttonClicked)="onAddOwner()"></sei-button>
        </div>
    </div>
</sei-dashboard-tile>
<sei-car-warning-modal [message]="modalMessage"
                       [showModal]="showModal"
                       (continueClicked)="onModalContinue()"
                       (cancelClicked)="onModalCancel()"></sei-car-warning-modal>
