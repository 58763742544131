/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { SpinnerService } from '@CarServices/system/spinner.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DisplayNullOptions, DisplayPercentOptions } from '@sei/common-components-lib-ux';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { PercentFormatPipe } from 'projects/advisor-client-review-proposal-ux-app/src/app/core/pipes/percent-format.pipe';
import { tap } from 'rxjs';
import { HudSummary, ProposalPercentFormat } from '../../../model/account-fee';
import { CommandText } from '../../../model/enums';
import { HudSummaryFactory } from '../../../model/factory/hud-summary-factory';
import { Account, Strategy } from '../../../model/proposal';
import { DelegationService } from '../../../service/delegation.service';
import { FeeScheduleGroupService } from '../../../service/fee-schedule-group.service';
import { GenericErrorService } from '../../../service/system/generic-error.service';

@Component({
    selector: 'sei-car-fee-strategy-summary',
    templateUrl: './fee-strategy-summary.component.html',
    styleUrls: ['./fee-strategy-summary.component.scss'],
    providers: [PercentFormatPipe]
})
export class FeeStrategySummaryComponent extends ParentComponentSubscriptionManager implements OnInit {
    @Input() public account: Account;

    @Output()
    public feeSummaryLoaded: EventEmitter<boolean> = new EventEmitter(false);

    public proposalId: number;
    public hudSummary: HudSummary = new HudSummaryFactory().createObject();
    private displayPercentOptions: DisplayPercentOptions;
    public proposalPercentFormatOptions: ProposalPercentFormat;

    constructor(private delegationService: DelegationService,
        private feeScheduleGroupService: FeeScheduleGroupService,
        private genericErrorService: GenericErrorService,
        private spinnerService: SpinnerService
    ) {
        super('FeeStrategySummaryComponent');
    }

    public ngOnInit(): void {
        this.getFeeStrategy();
        const getFeeStrategyService = this.delegationService.refresh()
            .subscribe(
                (data: string) => {
                    if (data === CommandText.GetFeeStrategy) {
                        this.getFeeStrategy();
                    }
                });
        this.subscriptions.push(getFeeStrategyService);

        this.displayPercentOptions = {
            displayPercentSymbol: true,
            roundToDecimal: 2, nullDisplay: DisplayNullOptions.BLANK
        };

        this.proposalPercentFormatOptions = {
            isFeeBreakdown: false,
            displayPercentOptions: this.displayPercentOptions
        };
    }

    private getFeeStrategy(): void {
        this.delegationService.publish(CommandText.ClearService);
        if (this.feeScheduleGroupService) {
            if (
                this.account.strategies.every((strategy: Strategy) => !!strategy.amount)
            ) {
                this.feeScheduleGroupService.getFeeStrategyCalculation(this.account).pipe(tap(() => this.spinnerService.start()))
                    .subscribe({
                        next: (response) => {
                            if (response) {
                                this.hudSummary = response.hudSummary;
                                this.feeSummaryLoaded.emit(true);
                            }
                        },
                        error: (error) => {
                            this.spinnerService.stop();
                            this.genericErrorService.setGenericError({ code: '500', description: error });
                            this.feeSummaryLoaded.emit(true);
                        },
                        complete: () => {
                            this.spinnerService.stop();
                            this.feeSummaryLoaded.emit(true);
                        }
                    });
            }
        }
    }
}
