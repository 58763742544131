/*
Copyright: This information constitutes the exclusive property of SEI
Investments Company, and constitutes the confidential and proprietary
information of SEI Investments Company.  The information shall not be
used or disclosed for any purpose without the written consent of SEI
Investments Company.
*/

import { PresentationThemeConstants } from '@CarModels/constants';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import * as _ from 'lodash';
import { CoverPageThemeIds, DefaultImage, FileUploadViewMode, HtmlElementType, InputPropertyType, ProposalSectionLabel, ProposalStatementSectionIds, YesNoLabels } from '../../../model/enums';
import { InputProperty, ProposalSection, SelectOption } from '../../../model/presentation';
import { DataSharingService } from '../../../service/data-sharing.service';
import { PresentationSettingService } from '../../../service/presentation-setting.service';
import { CoverPageImagesService } from './cover-page-images.service';

@Component({
    selector: 'sei-car-cover-page',
    templateUrl: './cover-page-component.html'
})
export class CoverPageComponent extends ParentComponentSubscriptionManager implements OnInit, OnDestroy {
    public elementTypes: typeof HtmlElementType = HtmlElementType;
    public inputPropertyType: typeof InputPropertyType = InputPropertyType;
    public proposalSectionLabel: typeof ProposalSectionLabel = ProposalSectionLabel;

    // Note: settings for sei-icon-toggle
    public offIsGrey: boolean = false;
    public disabled: boolean = false;
    public isActive: boolean = true;
    public trueIcon: string = 'fa-fw fas fa-toggle-on fa-rotate-180';
    public falseIcon: string = 'fa-fw fas fa-toggle-off fa-rotate-180';
    public iconSize: string = 'fa-2x';
    public iconClass: string = 'info';

    @Input()
    groupIndex: number;

    @Input()
    proposalSection: ProposalSection;

    @Input()
    presentationType: string;

    @Input()
    public coverImages: SelectOption[] = [];

    @Input()
    public firmLogos: SelectOption[] = [];

    public themeOptions: SelectOption[] = PresentationThemeConstants.THEME_OPTIONS;
    public themeValue: CoverPageThemeIds;
    public showImageModal: boolean = false;
    public showFileUploadModal: boolean = false;
    public selectedColor: SelectOption;
    public proposalId: number;
    public sectionName: string;
    public subSectionName: string;
    private defaultFirmLogoProposalIdIndex: number = 4;

    public colorSwatches: SelectOption[] = [];
    public coverTitleDisabled: boolean = true;
    public showBrandLogoUpload: boolean = true;
    public fileUploadViewMode: FileUploadViewMode;
    private previouslySelectedCoverImageMap: Map<CoverPageThemeIds, InputProperty> = new Map();

    constructor(
        public presentationSettingService: PresentationSettingService,
        public dataSharingService: DataSharingService,
        public route: ActivatedRoute,
        public coverPageImagesService: CoverPageImagesService
    ) {
        super('CoverPageComponent');
        this.dataSharingService.coverImageUpload.subscribe((currentUploadSection) => {
            if (currentUploadSection) {
                if (currentUploadSection !== this.presentationType && this.presentationType !== undefined) {
                    this.dataSharingService.proposalSectionChanged.next(this.proposalSection);
                    this.proposalSection.isCollapsed = !this.proposalSection.isCollapsed;
                }
            }
        });
    }

    public ngOnInit(): void {
        this.proposalId = Number(this.route.snapshot.params.proposalId);
        // Note: disable title input for all statement types except proposal
        if (this.presentationType === ProposalSectionLabel.Proposal) {
            this.coverTitleDisabled = false;
        }

        this.subscriptions.push(this.dataSharingService.proposalSectionChanged.subscribe((response) => {
            if (response) {
                if (response.statementSectionId === ProposalStatementSectionIds.ProposalCoverPage) {
                    response.sections.forEach((coverPageSubSection) => {
                        if (coverPageSubSection.statementSectionId === ProposalStatementSectionIds.ProposalCoverTitle) {
                            if (coverPageSubSection.include) {
                                this.coverTitleDisabled = false;
                            } else {
                                this.coverTitleDisabled = true;
                            }
                        }
                    });
                }
            }
        }));

        this.subscriptions.push(this.dataSharingService.imageUpload.subscribe((response) => {
            if (response) {
                this.firmLogos = [];
                this.firmLogos.push(response);
                this.firmLogos.push({ value: `${DefaultImage.NoImagePath}${DefaultImage.NoImagePng}`, label: DefaultImage.NoImage });
                this.proposalSection.sections
                    .find((section) => section.name === ProposalSectionLabel.BrandLogo).inputProperty.value = response.value;
            }
            this.onImageUploadModalCancel();
            this.coverPageImagesService.updateFirmLogos(this.firmLogos, this.proposalId);
        }));
        const themeSection: ProposalSection =
            this.proposalSection.sections.find((subSection: ProposalSection) => subSection.name === ProposalSectionLabel.PresentationTheme);
        this.themeValue = Number(themeSection.inputProperty.value);

        this.refreshImagesAndSubscribeToFutureUpdates();

        this.proposalSection.sections.sort((a: ProposalSection, b: ProposalSection) => a.sortOrder - b.sortOrder);
    }

    private refreshImagesAndSubscribeToFutureUpdates() {
        this.proposalId = Number(this.route.snapshot.params.proposalId);
        this.subscriptions.push(this.coverPageImagesService.loadCoverPageImages(this.proposalId).subscribe(
            () => {
                this.firmLogos = this.coverPageImagesService.getFirmLogos();
                this.coverImages = this.coverPageImagesService.getCoverImages(this.themeValue);

                this.subscriptions.push(this.coverPageImagesService.getUpdate().subscribe((message) => {
                    const incomingFirmLogos: SelectOption[] = this.coverPageImagesService.getFirmLogos();
                    const incomingCoverPageImages: SelectOption[] = this.coverPageImagesService.getCoverImages(this.themeValue);
                    if (incomingFirmLogos.length > this.firmLogos.length) {
                        this.firmLogos = incomingFirmLogos;
                        this.processFirmDefaults();
                    }
                    if (incomingCoverPageImages.length > this.coverImages.length) {
                        this.coverImages = incomingCoverPageImages;
                        this.processFirmDefaults();
                    }
                }));

                this.processFirmDefaults();
            },
            (error) => {
                // console.log(error);
            }
        ));
    }

    private setDefaultValuesBrandLogo(section: ProposalSection): void {
        if (section.defaultProperties) {
            if (section.defaultProperties.defaultBDLogoRequiredOnProposal &&
                section.defaultProperties.defaultBDLogoRequiredOnProposal === YesNoLabels.Yes) {
                section.hidden = true;
            } else {
                section.hidden = false;
            }
            this.showBrandLogoUpload = !section.hidden;

            const defaultFirmLogo: string = section.defaultProperties.defaultFirmLogo;
            if (defaultFirmLogo) {
                if (this.firmLogos && this.firmLogos.length > 0 && defaultFirmLogo) {
                    const foundFirmLogo: SelectOption =
                        this.firmLogos.find((firmLogo: SelectOption) => {
                            return firmLogo.value.includes(defaultFirmLogo) ||
                                firmLogo.label.includes(defaultFirmLogo);
                        });
                    if (foundFirmLogo) {
                        section.inputProperty.value = foundFirmLogo.value;
                    }
                }
            }
        }

        const DefaultbrandLogo: SelectOption[] =
            this.firmLogos.filter((Logo: SelectOption) =>
                Logo.label === DefaultImage.NoImage && Logo.value === `${DefaultImage.NoImagePath}${DefaultImage.NoImagePng}`);

        if (DefaultbrandLogo && DefaultbrandLogo.length === 0) {
            this.firmLogos.push({ value: `${DefaultImage.NoImagePath}${DefaultImage.NoImagePng}`, label: DefaultImage.NoImage });
        }

        if (this.firmLogos) {
            // To fix the happening due to failing firmlogo service
            const sectionInputLogo: SelectOption =
                this.firmLogos.find((firmLogo: SelectOption) => firmLogo.value === section.inputProperty.value);

            if (!sectionInputLogo) {
                const defaultFirmLogoProposalId: number =
                    Number(section.inputProperty.value.split('/')[this.defaultFirmLogoProposalIdIndex]);
                this.presentationSettingService.getFirmLogoDisplayName(defaultFirmLogoProposalId).subscribe((response) => {
                    this.firmLogos.push(
                        { label: response, value: section.inputProperty.value });
                });
            }
        }
    }

    private processFirmDefaults() {
        this.proposalSection.sections.forEach((section: ProposalSection) => {
            switch (section.name) {
                case ProposalSectionLabel.CoverTitle:
                    section.hidden = false;
                    if (section.statementSectionId === ProposalStatementSectionIds.ProposalCoverTitle && !section.include) {
                        this.coverTitleDisabled = true;
                    }
                    break;
                case ProposalSectionLabel.TitleBarColor:
                case ProposalSectionLabel.TitleBarTextColor: {
                    const colorOptionFound: SelectOption =
                        this.colorSwatches.find((color: SelectOption) => color.label === section.defaultProperties.defaultValue);
                    section.defaultProperties.defaultValue =
                        colorOptionFound ?
                            colorOptionFound.value :
                            section.defaultProperties.defaultValue;
                    break;
                }
                case ProposalSectionLabel.CoverImage: {
                    this.coverImages = this.coverPageImagesService.getCoverImages(this.themeValue);
                    const imageOptionFound: SelectOption =
                        this.coverImages.find((color: SelectOption) => color.value === section.defaultProperties.defaultValue);
                    section.defaultProperties.defaultValue =
                        imageOptionFound ?
                            imageOptionFound.value :
                            undefined;
                    if (!section.inputProperty.value.includes('/')) {
                        section.inputProperty.value = `${DefaultImage.NoImagePath}${DefaultImage.NoImagePng}`;
                    }
                    section.inputProperty.value = section.inputProperty.value.replace(/ /g, '%20');
                    break;
                }
                case ProposalSectionLabel.BrandLogo:
                    this.setDefaultValuesBrandLogo(section);
                    break;
                default:
                    break;
            }
        });
    }

    public onViewClick() {
        this.showImageModal = true;
    }

    public closeImageModal() {
        this.showImageModal = false;
    }

    public onImageUploadClick(event) {
        this.showFileUploadModal = true;
        this.fileUploadViewMode = FileUploadViewMode.BrandLogo;
        this.sectionName = ProposalSectionLabel.CoverPage;
        this.subSectionName = ProposalSectionLabel.BrandLogo;
    }

    public onImageUploadModalCancel() {
        this.showFileUploadModal = false;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public updatePreviewSection(event: any, statementSectionId: number): void {
        if (typeof (event) === 'boolean' && statementSectionId === ProposalStatementSectionIds.ProposalCoverTitle) {
            this.coverTitleDisabled = !this.coverTitleDisabled;
        }

        this.dataSharingService.proposalSectionChanged.next(this.proposalSection);
    }

    public onCoverImageUploadClick(event) {
        this.showFileUploadModal = true;
        this.fileUploadViewMode = FileUploadViewMode.CoverImage;
        this.sectionName = ProposalSectionLabel.CoverPage;
        this.subSectionName = ProposalSectionLabel.CoverImage;
    }

    public updateCoverImageDropdown(imageUploadedPath: string) {
        this.showFileUploadModal = false;
        this.coverPageImagesService.forceLoadCoverPageImages(this.proposalId).subscribe(() => {
            this.coverImages = Object.assign(this.coverPageImagesService.getCoverImages(this.themeValue));
            this.proposalSection.sections
                .find((section) => section.name === ProposalSectionLabel.CoverImage).inputProperty.value = imageUploadedPath;
            this.proposalSection.sections
                .find((section) => section.name === ProposalSectionLabel.CoverImage).defaultProperties.defaultValue = imageUploadedPath;

            this.dataSharingService.coverImageUpload.next(this.presentationType);
        });
    }

    public onThemeChange(event: ProposalSection): void {
        this.themeValue = Number(event.inputProperty.value) as CoverPageThemeIds;
        switch (this.themeValue) {
            case CoverPageThemeIds.Abstract:
                // Show Cover Image
                this.toggleSectionVisibility(ProposalSectionLabel.CoverImage, true);
                // Hide Title Bar Color
                this.toggleSectionVisibility(ProposalSectionLabel.TitleBarColor, false);
                // Hide Title Bar Text Color
                this.toggleSectionVisibility(ProposalSectionLabel.TitleBarTextColor, false);
                // Show Cover Subtitle
                this.toggleSectionVisibility(ProposalSectionLabel.CoverSubtitle, true);
                // Show Theme Color
                this.toggleSectionVisibility(ProposalSectionLabel.ThemeColor, true);
                break;
            case CoverPageThemeIds.Simplistic:
                // Hide Cover Image
                this.toggleSectionVisibility(ProposalSectionLabel.CoverImage, false);
                // Hide Title Bar Color
                this.toggleSectionVisibility(ProposalSectionLabel.TitleBarColor, false);
                // Hide Title Bar Text Color
                this.toggleSectionVisibility(ProposalSectionLabel.TitleBarTextColor, false);
                // Show Cover Subtitle
                this.toggleSectionVisibility(ProposalSectionLabel.CoverSubtitle, true);
                // Show Theme Color
                this.toggleSectionVisibility(ProposalSectionLabel.ThemeColor, true);
                break;
            case CoverPageThemeIds.Custom:
                // Show Cover Image
                this.toggleSectionVisibility(ProposalSectionLabel.CoverImage, true);
                // Show Title Bar Color
                this.toggleSectionVisibility(ProposalSectionLabel.TitleBarColor, true);
                // Show Title Bar Text Color
                this.toggleSectionVisibility(ProposalSectionLabel.TitleBarTextColor, true);
                // Hide Cover Subtitle
                this.toggleSectionVisibility(ProposalSectionLabel.CoverSubtitle, false);
                // Hide Theme Color
                this.toggleSectionVisibility(ProposalSectionLabel.ThemeColor, false);
                break;
        }
        this.coverImages = this.coverPageImagesService.getCoverImages(this.themeValue);
        const coverImageSectionIndex: number =
            this.proposalSection.sections.findIndex((subSection: ProposalSection) => subSection.name === ProposalSectionLabel.CoverImage);
        const selectedImageNotInCoverImageDropdown: boolean = !this.coverImages?.some((imageOption) =>
            imageOption?.value === this.proposalSection.sections[coverImageSectionIndex]?.inputProperty?.value);
        if (this.previouslySelectedCoverImageMap.has(this.themeValue)) {
            this.proposalSection.sections[coverImageSectionIndex].inputProperty = this.previouslySelectedCoverImageMap.get(this.themeValue);
        } else if (selectedImageNotInCoverImageDropdown && this.coverImages) {
            this.proposalSection.sections[coverImageSectionIndex].inputProperty.value = this.coverImages[0]?.value;
            this.proposalSection.sections[coverImageSectionIndex] =
                _.cloneDeep(this.proposalSection.sections[coverImageSectionIndex]);
        } else {
            this.proposalSection.sections[coverImageSectionIndex] =
                _.cloneDeep(this.proposalSection.sections[coverImageSectionIndex]);
        }
    }

    public isIPS(): boolean {
        return this.proposalSection.statementSectionId === ProposalStatementSectionIds.IPSCoverPage;
    }

    private toggleSectionVisibility(sectionLabel: ProposalSectionLabel, showSection: boolean): void {
        const selectedSection: ProposalSection =
            this.proposalSection.sections.find((section: ProposalSection) => section.name === sectionLabel);
        selectedSection.hidden = !showSection;
    }

    public checkThemeAllowUpload(): boolean {
        return this.themeValue === CoverPageThemeIds.Custom;
    }

    public onCoverImageChange(section: ProposalSection): void {
        this.previouslySelectedCoverImageMap.set(this.themeValue, _.cloneDeep(section.inputProperty));
    }

    public disableRevertByTheme(): boolean {
        const themeSection: ProposalSection =
            this.proposalSection?.sections?.find((section: ProposalSection) => section?.name === ProposalSectionLabel.PresentationTheme);
        return Number(themeSection?.inputProperty?.value) !== CoverPageThemeIds.Custom;
    }

}
