<div class="row group-gap">
    <div class="col-sm-12">
        <div class="tab-cap">
            <div class="tab-plain"
                 [class.active]="activeTab"
                 (click)="tabClicked('active')">Active</div>
            <div class="tab-plain"
                 [class.active]="!activeTab"
                 (click)="tabClicked('archived')">Archived</div>
            <div class=“tab-bar”></div>
            <div class="tab-cap-right flex-row">
                <div class="flx-sz size-30">
                    <label for="field_3A">Filter by Advisor(s)</label>
                    <select id="advisors-dropdown"
                            class="form-control"
                            [(ngModel)]="selectedAdvisor"
                            (change)="advisorDropdownSelected($event)">
                        <option [value]="-1">All Advisors</option>
                        <option *ngFor="let advisor of advisors"
                                [value]="advisor.advisorName">{{advisor.advisorName}}
                        </option>
                    </select>
                </div>&nbsp;&nbsp;
                <div class="flx-sz size-70"><label>Search</label>
                    <input type="text"
                           [formControl]="searchFilter"
                           placeholder="Filter proposals...">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-sm-12 dashboard-row">
        <div class="dashboard-cell flx-sz size-20 header"
             (click)="sortList('name')"
             [class.sorted]="key === 'name'"
             [class.up]="reverse"
             [class.down]="!reverse">Proposal Name</div>
        <div class="dashboard-cell flx-sz size-20 header"
             (click)="sortList('status.description')"
             [class.sorted]="key === 'status.description'"
             [class.up]="reverse"
             [class.down]="!reverse">Status</div>
        <div class="dashboard-cell flx-sz size-20 header truncate"
             (click)="sortList('advisorName')"
             [class.sorted]="key === 'advisorName'"
             [class.up]="reverse"
             [class.down]="!reverse">Advisor Name</div>
        <div class="dashboard-cell flx-sz size-15 header"
             (click)="sortList('targetFunding')"
             [class.sorted]="key === 'targetFunding'"
             [class.up]="reverse"
             [class.down]="!reverse">Target Funding
        </div>
        <div class="dashboard-cell flx-sz size-15 header"
             (click)="sortList('statusDate')"
             [class.sorted]="key === 'statusDate'"
             [class.up]="reverse"
             [class.down]="!reverse">Last Changed</div>
        <div class="dashboard-cell flx-sz size-25 header">Actions</div>
    </div>
</div>
<div class="row"
     *ngFor="let proposal of filteredProposalsStream | async | orderBy: key : !reverse">
    <div class="col-sm-12 dashboard-row">
        <div class="dashboard-cell flx-sz size-20 text-left">{{proposal.name}}</div>
        <div class="dashboard-cell flx-sz size-20 text-left">{{proposal.status.description}}</div>
        <div class="dashboard-cell flx-sz size-20 text-left truncate">{{proposal.advisorName}}</div>
        <div class="dashboard-cell flx-sz size-15 text-right"
             *ngIf="proposal.targetFunding > 0.00">{{proposal.targetFunding | currency}}</div>
        <div class="dashboard-cell flx-sz size-15 text-right"
             *ngIf="proposal.targetFunding == 0.00">-</div>
        <div class="dashboard-cell flx-sz size-15 text-left">{{proposal.statusDate | date: 'MM/dd/yyyy' }}</div>
        <div class="dashboard-cell flx-sz size-25 text-right dash-actions"
             *ngIf="activeTab">
            <span [routerLink]="['/Proposal/WIP/', proposal.id]">Edit</span>
            <span [class.disabled]="proposal.status.description !== proposalStatus.Generated"
                (click)="openDownloadedDocument(proposal)">Download</span>
            <span (click)="onImplementProposal(proposal)"
                [class.disabled]="!proposal.complete || proposal.advisorChangeFlag || isReadUser">Implement</span>
            <span (click)="deleteProposal(proposal.id)" [class.disabled]="isReadUser"
                *ngIf="proposal.status.description !== proposalStatus.Generated">Delete</span>
            <span (click)="archiveProposal(proposal.id)" [class.disabled]="isReadUser"
                *ngIf="proposal.status.description === proposalStatus.Generated">Archive</span>
        </div>
        <div class="dashboard-cell flx-sz size-25 text-right dash-actions"
             *ngIf="!activeTab">
            <span (click)="openDownloadedDocument(proposal)"
                [class.disabled]="proposal.archivedStatus === null || proposal?.archivedStatus?.description !== proposalStatus.Generated">Download</span>
            <span (click)="reactivateProposal(proposal)"
                [class.disabled]="isReactivateDisabled(proposal)">Reactivate</span>
        </div>
    </div>
</div>
<sei-car-reactivate-modal *ngIf="showReactivateModal"
                          [proposal]="minDataProposal"
                          [proposalStatusResponse]="proposalAccountStatuses"
                          (onContinueClicked)="submitReactivation($event)"
                          (modalClosed)="onReactivateModalClosed()"></sei-car-reactivate-modal>
