/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'currencySuffix'
})
export class CurrencySuffixPipe implements PipeTransform {

    transform(input: number, args?: number): string {
        // let exponential: number;
        // const suffixes = ['K', 'M', 'G', 'T', 'P', 'E'];
        const dollar = '$';

        if ((input === null) || Number.isNaN(input) || (input === 0)) {
            return dollar + 0;
        }

        if (input < 1000) {
            return dollar + input.toString();
        }

        let abs = Math.abs(input);
        let rounder = Math.pow(10, 1); // Note: When this value exceeds 1 million dollars rounding to one decimal place (nearest 100K)
        const isNegative = input < 0;
        let key = '';

        const powers = [
            { key: 'Q', value: Math.pow(10, 15) },
            { key: 'T', value: Math.pow(10, 12) },
            { key: 'B', value: Math.pow(10, 9) },
            { key: 'M', value: Math.pow(10, 6) },
            { key: 'K', value: 1000 }
        ];

        for (let i = 0; i < powers.length; i++) {
            let reduced = abs / powers[i].value;

            if (powers[i].value === 1000) {  // Note: Do not round to nearest decimal if less than 1 million
                rounder = Math.pow(10, 0);
            }

            reduced = Math.round(reduced * rounder) / rounder;
            if (reduced >= 1) {
                abs = reduced;
                key = powers[i].key;
                break;
            }
        }

        return dollar + (isNegative ? '-' : '') + abs + key;
    }
}
