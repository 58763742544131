<div class="fee-account-group">
    <section id="clientRiskPreference">
        <div class="fee-group-header">
            <div class="snipe "
                 [class.default]="feeGroup.advisorFee.isDefault">
            </div>
            <div class="flex-left">
                <h4>{{feeGroup.name}}</h4>
                <p class="x-small">
                    {{(calculatedRate * 100) | seiDisplayPercent : displayPercentOptions}}
                    / {{(totalAccountStrategyBalance) | currencySuffix}}
                </p>
                <div *ngIf="feeGroup.advisorFee.feeType === feeTypes.FirmFeeSchedule"
                     class="pill primary tier-margin-left">Tiered w/ Fee Group</div>
                <span class="wip-menu-icon menu-ellipsis"></span>
            </div>
            <div class="flex-right">
                <i class="fa fas" [class.fa-pencil-alt]="!showScheduleFlatFeeRateDetails"
                    [class.fa-times-circle]="showScheduleFlatFeeRateDetails" (click)="onCancelFeeGroup()">
                </i>
            </div>
        </div>
        <div [hidden]="!showScheduleFlatFeeRateDetails">
            <sei-car-fee-schedule-fee-rate [advisorFee]="feeGroup.advisorFee"
                                           [feeGroup]="feeGroup"
                                           [totalAccountBalance]="totalAccountStrategyBalance"
                                           [scenarioId]="scenarioId"
                                           [scenarioFeeSchedules]="scenarioFeeSchedules"
                                           (onCancelEvent)="onCancelFeeGroup()"
                                           (onSaveEvent)="onSaveFeeGroup($event)">
            </sei-car-fee-schedule-fee-rate>
        </div>

        <div cdkDropList
             class="account-list"
             #accountList="cdkDropList"
             [cdkDropListData]="feeGroup.accounts"
             (cdkDropListDropped)="drop($event)"
             (cdkDropListEntered)="enter($event)">
            <div class="drag-box"
                 *ngFor="let feeAccount of feeGroup.accounts"
                 cdkDrag>
                <sei-car-fee-schedule-account [account]="feeAccount"
                                              [advisorFee]="feeGroup.advisorFee"></sei-car-fee-schedule-account>
            </div>
        </div>
    </section>
</div>
