/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, OnInit, ViewChild } from '@angular/core';
import { ImplementProposalConstants } from '@CarModels/constants';
import { ImplementModalState } from '@CarModels/enums';
import { FeatureFlagService } from '@CarServices/feature-flag/feature-flag.service';
import { ImplementModalService } from '@CarServices/implement-modal/implement-modal.service';
import { SpinnerService } from '@CarServices/system/spinner.service';
import {
    DataSharingService,
    Firm,
    FirmAdvisorDisplayMode,
    FirmAdvisorLabel,
    GenericErrorService,
    GlobalService,
    ImplementedAccountResponse, ImplementProposalOptions,
    MinDataProposal,
    PostProposalImplementRedirectActions, ProposalService,
    ProposalStatus,
    ProposalView,
    User,
    UserLevelEntitlement,
    UserProfile,
    UserProfileService,
    ValidationMessage
} from '@sei/advisor-client-review-proposal-ux';
import { ModalWindowComponent } from '@sei/common-components-lib-ux';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { Observable, of } from 'rxjs';

@Component({
    selector: 'car-dashboard-component',
    templateUrl: './dashboard.component.html'
})
export class DashboardComponent extends ParentComponentSubscriptionManager implements OnInit {
    public totalAccountValue: number;
    public totalProposalCount: number;
    public showSearchResults: boolean = true;
    public userProfile: UserProfile;
    public firm: Firm;
    // public locationsSelected: OfficeLocation[] = [];
    public advisorsSelected: User[] = [];
    public entitledAdvisorsEntityIds: number[] = [];

    public displayModes: typeof FirmAdvisorDisplayMode = FirmAdvisorDisplayMode;
    public firmAdvisorLabels: typeof FirmAdvisorLabel = FirmAdvisorLabel;
    public userLevel: typeof UserLevelEntitlement = UserLevelEntitlement;
    public proposalStream: Observable<MinDataProposal>;
    public isProposalGenerated: boolean;
    public showImplementModal: boolean;
    public isImpersonationValid: boolean = false;
    public previousUserProfile: UserProfile = undefined;
    public previousFirm: Firm = undefined;
    public previousEntitledAdvisorsEntityIds: number[] = [];
    public clearImpersonationModal: boolean = false;
    public isReadUser: boolean = false;
    public enableImplementButton = true;
    public proposalStatus: typeof ProposalStatus = ProposalStatus;

    // NOTE: This is for teh impersonate 'change' button
    // the first entitledAdvisor is the primary
    public get primaryAdvisorName(): string {
        if (this.entitledAdvisorsEntityIds.length > 0) {
            return this.firm.advisors.find((advisor) => advisor.entityId === this.entitledAdvisorsEntityIds[0]).advisorName;
        }
        return undefined;
    }

    @ViewChild('impersonationModal', { static: false })
    impersonationModalElementRef: ModalWindowComponent;

    constructor(
        private userProfileService: UserProfileService,
        private proposalService: ProposalService,
        private genericErrorService: GenericErrorService,
        private dataSharingService: DataSharingService,
        private globalService: GlobalService,
        private implementModalService: ImplementModalService,
        private featureFlagService: FeatureFlagService,
        private spinnerService: SpinnerService
    ) {
        super('DashboardComponent');

    }

    ngOnInit(): void {
        this.isReadUser = this.userProfileService.isReadUser;
        this.userProfile = this.userProfileService.getCurrentUserProfile;
        // Reset current proposal
        this.proposalService.changedProposal(undefined);
        this.initializeComponent();
    }

    private initializeComponent() {
        if (this.userProfile && this.userProfile.firm) {
            this.firm = this.userProfile.firm;
            // TODO: Confirm primaryAdvisor for firm and office location
            this.firm.primaryAdvisor = this.userProfile.firm.primaryAdvisor;
            this.firm.officeLocation = this.userProfile.firm.officeLocation;

            // Note: check first if logged in user is instance and grant access to only selected advisors.
            if (this.userProfile.entitlements.userLevelId === UserLevelEntitlement.PO ||
                this.userProfile.entitlements.userLevelId === UserLevelEntitlement.Instance) {
                this.entitledAdvisorsEntityIds = this.userProfileService.getEtitledAdvisorsEntityIds();
            }

            if (this.userProfile.entitlements && this.userProfile.entitlements.userLevelId === UserLevelEntitlement.FirmAdvisor) {
                // Note: check first if logged in user has entitled advisors and grant access to only their advisors.
                if (this.userProfile.entitlements.advisors) {
                    this.userProfile.entitlements.advisors.forEach((advisor) => {
                        this.entitledAdvisorsEntityIds.push(advisor.entityId);
                    });
                } else if (this.firm.advisors) {
                    this.firm.advisors.forEach((advisor) => {
                        this.entitledAdvisorsEntityIds.push(advisor.entityId);
                    });
                }
            }
        }
    }

    public setUserType(): void {
        if (this.userProfile.entitlements.userLevelId === UserLevelEntitlement.PO) {
            this.showSearchResults = false;
        }
    }

    public changeFirm(): void {
        this.genericErrorService.clearGenericError();
        this.previousUserProfile = { ...this.userProfile };
        this.previousFirm = { ...this.firm };
        this.previousEntitledAdvisorsEntityIds = Object.assign([], this.entitledAdvisorsEntityIds);
        this.showSearchResults = false;
        this.clearImpersonationModal = false;
        this.totalAccountValue = 0;
        this.totalProposalCount = 0;
        this.impersonationModalElementRef.showModalBox();
    }

    public dashboardFirmSelected(advisorFirm: Firm): void {
        this.isImpersonationValid = true;
        this.firm = advisorFirm;
        this.userProfile.firm = advisorFirm;
        this.userProfileService.updateCurrentUserProfileFirm(this.firm);
        this.userProfile = this.userProfileService.getCurrentUserProfile;

        if (
            this.firm &&
            this.firm.locations
        ) {
            this.firm.officeLocation = this.firm.locations[0];
        }
    }

    public advisorsUpdated(advisorIds: string[]): void {
        this.advisorsSelected = [];
        this.entitledAdvisorsEntityIds = [];
        advisorIds.forEach((advisorId, index) => {
            const advisorFound = this.firm.advisors.find(
                (advisor) => advisor.entityId === Number(advisorId)
            );

            if (index === 0) {
                this.firm.primaryAdvisor = advisorFound;
            }

            if (advisorFound) {
                this.advisorsSelected.push(advisorFound);
                this.entitledAdvisorsEntityIds.push(advisorFound.entityId);
            }
        });
        this.userProfile.firm = this.firm;
        this.userProfileService.setCurrentUserProfile(this.userProfile);
    }

    readTotalAccountOutputEmitted(val: number) {
        setTimeout(() => {
            this.totalAccountValue = val;
        }, 100);
    }

    public readTotalProposalCountEmitted(val: number): void {
        setTimeout(() => {
            this.totalProposalCount = val;
        }, 100);
    }

    // NOTE event click from dashboard table to say they want to
    // implement the Proposal, it loads proposal and shows modal
    public implementProposalClick(event: ProposalView): void {
        if (!this.showImplementModal) {
            this.spinnerService.start();
            this.proposalService.getMinDataProposalById(event.id).subscribe((proposal) => {
                this.proposalStream = of(proposal);
                this.showImplementModal = true;
                this.spinnerService.stop();
                this.isProposalGenerated = event?.status?.description === this.proposalStatus.Generated;
            },
                (error) => {
                    this.spinnerService.stop();
                });
        }

    }

    // NOTE: this is from the modal dialog to actually implement
    // and redirect accordingly
    public implement(event: {
        id: number;
        redirectAction: PostProposalImplementRedirectActions;
        implementProposalOptions?: ImplementProposalOptions;
    }) {
        this.enableImplementButton = false;
        const carConfig = this.globalService.configService.environment.carConfig;
        const hostApplicationRedirect: string = `${carConfig.transportProtocol}://${carConfig.server}`;
        const newAccountProxyPath: string = `${carConfig.newAccountProxyGroup}`;
        if (event && !this.featureFlagService.isDAOIntegrationEnabled()) {
            this.subscriptions.push(this.proposalService.implementProposal(event.id).subscribe(() => {
                if (
                    event.redirectAction === PostProposalImplementRedirectActions.Dashboard
                ) {
                    // NOTE: refresh the search results
                    location.reload();
                } else if (
                    event.redirectAction === PostProposalImplementRedirectActions.Account
                ) {
                    window.location.href = `${hostApplicationRedirect}/${newAccountProxyPath}/#/PendingAccounts/NewAccount`;
                } else if (
                    event.redirectAction === PostProposalImplementRedirectActions.SWP
                ) {
                    window.location.href = `${hostApplicationRedirect}/ADE/#/dashboard`;
                }
            }, (error) => {
                this.enableImplementButton = true;
            }));
        } else {
            switch (event.redirectAction) {
                case PostProposalImplementRedirectActions.Dashboard:
                    this.proposalService.archiveProposal(event.id).subscribe(
                        () => {
                            this.implementModalService.refreshDashboardProposals();
                            this.implementModalService.resetRefreshSubject();
                            this.showImplementModal = false;
                            this.enableImplementButton = true;
                        },
                        (error) => {
                            this.enableImplementButton = true;
                        });
                    break;
                case PostProposalImplementRedirectActions.Account:
                    this.implementModalService.createAccountsInDAO(event.implementProposalOptions).subscribe(
                        (response: ImplementedAccountResponse) => {
                            const validationErrors = [];
                            response.validationMessages?.forEach((validationMessage: ValidationMessage) => {
                                validationErrors.push(validationMessage.validationMessage);
                            });
                            const success: boolean = validationErrors?.length === 0;
                            if (success) {
                                this.implementModalService.setValidationErrors([]);
                                this.implementModalService.setSuccessfulAccountStatuses(response?.proposalAccountStatuses);
                                this.implementModalService.updateModalState(ImplementModalState.ConfirmationPage);
                                this.implementModalService.resetErrorCount();
                            } else {
                                this.implementModalService.setValidationErrors(validationErrors);
                                this.implementModalService.updateModalState(ImplementModalState.ImplementForm);
                                this.implementModalService.addErrorCount();
                            }
                            this.enableImplementButton = true;
                        },
                        (error) => {
                            this.implementModalService.addErrorCount();
                            this.implementModalService.hasExceededMaxErrors() ?
                                this.implementModalService
                                    .setValidationErrors([ImplementProposalConstants.IMPLEMENT_SERVICE_MAX_ERROR_MESSAGE]) :
                                this.implementModalService
                                    .setValidationErrors([ImplementProposalConstants.GENERIC_IMPLEMENT_SERVICE_ERROR_MESSAGE]);
                            this.implementModalService.updateModalState(ImplementModalState.ImplementForm);
                            this.enableImplementButton = true;
                            this.implementModalService.setIsLoading(false);
                        });
                    break;
            }
        }
    }

    public cancelImplement() {
        this.showImplementModal = false;
    }

    public executeImpersonate() {
        this.userProfileService.setCurrentUserProfile(this.userProfile);
        this.userProfileService.setEtitledAdvisorsEntityIds(this.entitledAdvisorsEntityIds);
        this.showSearchResults = true;
        this.clearImpersonationModal = false;
        this.impersonationModalElementRef.hideModalBox();
        this.dataSharingService.imageUpload.next(undefined);
    }

    public cancelImpersonate(event) {
        // NOTE: This event gets called on impersonate modal close (regardless of how it gets closed),
        // we do not want to execute when user hit 'Go To Dashbaord', search results will be true
        // in that use case.  This is how  the Close X and  isDismissible works on control
        if (this.showSearchResults) {
            return;
        }
        this.userProfileService.setCurrentUserProfile(this.previousUserProfile);
        this.firm = this.previousFirm;
        this.userProfileService.updateCurrentUserProfileFirm(this.firm);
        this.userProfileService.setEtitledAdvisorsEntityIds(this.previousEntitledAdvisorsEntityIds);
        this.entitledAdvisorsEntityIds = this.previousEntitledAdvisorsEntityIds;
        this.userProfile = this.userProfileService.getCurrentUserProfile;
        this.showSearchResults = true;
        this.clearImpersonationModal = true;
        this.impersonationModalElementRef.hideModalBox();
    }

    public impersonationError() {
        this.impersonationModalElementRef.hideModalBox();
    }

    public firmCleared() {
        this.isImpersonationValid = false;
        this.entitledAdvisorsEntityIds = [];
        this.userProfileService.setEtitledAdvisorsEntityIds(this.entitledAdvisorsEntityIds);
    }
}
