/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
/* eslint-disable @typescript-eslint/no-shadow */
import { Action } from '@ngrx/store';

import {
    CreateDocumentRequest,
    TradeCreateDocumentResponse
} from '@CarInterfaces/trade';
import { getGeneratingDocument } from './enrollment.selectors';

export enum EnrollmentActionTypes {
    RedirectToWipDetails = '[Print Dialog] Additional Forms Redirect To WIP Details',
    InitializeState = '[Print Dialog] InitializeState',
    GenerateDocuments = '[Print Dialog] Generate Documents',
    GenerateDocumentsSuccess = '[Print Dialog] Generate Documents Success',
    GenerateDocumentsFail = '[Print Dialog] Generate Documents Fail',
    DocumentStatus = '[Print Dialog] Document Status',
    DocumentStatusSuccess = '[Print Dialog] Document Status Success',
    DocumentStatusFail = '[Print Dialog] Document Status Fail',
    DocumentLoad = '[Print Dialog] Document Load',
    DocumentLoadSuccess = '[Print Dialog] Document Load Success',
    DocumentLoadFail = '[Print Dialog] Document Load Fail'
}

export class RedirectToWipDetails implements Action {
    readonly type = EnrollmentActionTypes.RedirectToWipDetails;

    constructor(public payload: number) {}
}

export class InitializeState implements Action {
    readonly type = EnrollmentActionTypes.InitializeState;

    constructor(
        public payload: {
            generatingDocuments: { [key: string]: boolean };
            documentGenerated: { [key: string]: boolean };
        }
    ) {}
}

export class GenerateDocuments implements Action {
    readonly type = EnrollmentActionTypes.GenerateDocuments;

    constructor(public payload: CreateDocumentRequest) {}
}

export class GenerateDocumentsSuccess implements Action {
    readonly type = EnrollmentActionTypes.GenerateDocumentsSuccess;

    constructor(public payload: TradeCreateDocumentResponse) {}
}

export class GenerateDocumentsFail implements Action {
    readonly type = EnrollmentActionTypes.GenerateDocumentsFail;

    constructor(
        public payload: {
            errorMessage: string;
            generatingDocuments: { [key: string]: boolean };
        }
    ) {}
}

export class DocumentStatus implements Action {
    readonly type = EnrollmentActionTypes.DocumentStatus;

    constructor(public payload: TradeCreateDocumentResponse) {}
}

export class DocumentStatusSuccess implements Action {
           readonly type = EnrollmentActionTypes.DocumentStatusSuccess;

           constructor(
               public payload: {
                   documentUrl: string;
                   documentGenerated: { [key: string]: boolean };
                   generatingDocuments: { [key: string]: boolean };
               }
           ) {}
       }

export class DocumentStatusFail implements Action {
    readonly type = EnrollmentActionTypes.DocumentStatusFail;

    constructor(
        public payload: {
            errorMessage: string;
            documentGenerated: { [key: string]: boolean };
        }
    ) {}
}

export class DocumentLoad implements Action {
    readonly type = EnrollmentActionTypes.DocumentLoad;
}

export class DocumentLoadSuccess implements Action {
    readonly type = EnrollmentActionTypes.DocumentLoadSuccess;

    constructor(public payload: string) {
        window.open(payload);
    }
}

export class DocumentLoadFail implements Action {
    readonly type = EnrollmentActionTypes.DocumentLoadFail;

    constructor(public payload: string) {}
}

export type EnrollmentActions =
    | RedirectToWipDetails
    | InitializeState
    | GenerateDocuments
    | GenerateDocumentsSuccess
    | GenerateDocumentsFail
    | DocumentStatus
    | DocumentStatusSuccess
    | DocumentStatusFail
    | DocumentLoad
    | DocumentLoadSuccess
    | DocumentLoadFail;
