/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ContentChildren, ElementRef, QueryList, ViewChild } from '@angular/core';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import * as _ from 'lodash';
import { Orientation } from '../../common/proposal-presentation-checklist.enums';
import { ProposalPresentationChecklistService } from '../../common/proposal-presentation-checklist.service';

@Component({
    standalone: true,
    selector: 'sei-car-content-area-pagination',
    templateUrl: './content-area-pagination.component.html',
    styleUrls: ['./content-area-pagination.component.scss'],
    imports: [
        CommonModule
    ]
})
export class ContentAreaPaginationComponent extends ParentComponentSubscriptionManager implements AfterViewInit {

    @ViewChild('pages') pages: ElementRef<HTMLElement>;

    @ViewChild('content') content: ElementRef<HTMLElement>;

    @ContentChildren('pageContent', { read: ElementRef, descendants: true }) elements: QueryList<ElementRef>;

    public pageHeight: number = 1056;

    public readonly PAGE_PADDING_SIZE: number = 20;

    public orientation: Orientation;

    public elementBeforeBreaks: ElementRef<HTMLElement>;

    constructor(private proposalPresentationChecklistService: ProposalPresentationChecklistService) {
        super('ProposalPresentationContentAreaComponent');
        this.subscriptions.push(
            this.proposalPresentationChecklistService.selectedOrientation.subscribe((orientation: Orientation) => {
                this.orientation = orientation;
            })
        );
    }

    ngAfterViewInit(): void {
        this.elements.forEach((el: ElementRef<HTMLElement>) => {
            this.elementBeforeBreaks = _.cloneDeep(el);
            this.paginateContent(el);
        });
        this.subscriptions.push(this.elements.changes.subscribe((el: ElementRef<HTMLElement>) => {
            this.elementBeforeBreaks = _.cloneDeep(el);
            this.paginateContent(el);
        }));
    }

    public paginateContent(el: ElementRef<HTMLElement>) {
        const pageContent: HTMLElement = el.nativeElement;
        if (pageContent.clientHeight > this.pageHeight) {
            this.insertSpacerDiv(pageContent.children, pageContent.clientHeight, this.pageHeight);
        }
        const sizeOfContainer = el.nativeElement.clientHeight;
        const numPageBreaks = Math.ceil(sizeOfContainer / this.pageHeight) - 1;
        const pageBreakSpacingSize = numPageBreaks * this.PAGE_PADDING_SIZE;
        const remainingHeight = (sizeOfContainer % this.pageHeight) - pageBreakSpacingSize;
        const pageBreak: HTMLDivElement = document.createElement('div');
        pageBreak.classList.add('page-break');
        pageBreak.style.borderTop = `${remainingHeight}px solid white`;
        el.nativeElement.after(pageBreak);
    }

    public insertSpacerDiv(children: HTMLCollection, pageHeight: number, remainingHeight) {
        for (let i = 0; i < children.length; i++) {
            const child: HTMLElement = children.item(i) as HTMLElement;
            if (child.clientHeight > remainingHeight) {
                if (child.children && child.children.length > 0) {
                    this.insertSpacerDiv(child.children, pageHeight, remainingHeight);
                } else {
                    const pageBreak: HTMLDivElement = document.createElement('div');
                    pageBreak.classList.add('page-break');
                    pageBreak.style.borderTop = `${remainingHeight}px solid white`;
                    pageBreak.style.paddingBottom = '20px';
                    child.before(pageBreak);
                    remainingHeight = pageHeight;
                }
            }
            remainingHeight -= child.clientHeight;
        }
    }
}
