<div>
    <div *ngIf="wipCheckList.mdcSnapShot.completed === wipAccountStatus.New || wipCheckList.mdcSnapShot.completed === wipAccountStatus.Incomplete">
        <sei-button buttonText="Select Investments"
                    buttonClass="sei-btn-link-padding-none"
                    [buttonDisabled]="buttonDisabled"
                    (buttonClicked)="navigate()"></sei-button>
    </div>
    <div *ngIf="wipCheckList.mdcSnapShot.completed === wipAccountStatus.Complete">
        <sei-button buttonText="Modify Investments"
                    buttonClass="sei-btn-link-padding-none"
                    [buttonDisabled]="buttonDisabled"
                    (buttonClicked)="navigate()"></sei-button>
    </div>
</div>
