/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Pipe, PipeTransform } from '@angular/core';
import { ProposalPercentFormat } from '@sei/advisor-client-review-proposal-ux';
import { DisplayPercentPipe } from '@sei/common-components-lib-ux';

@Pipe({
    name: 'proposalFormatPercentWithLessThan'
})
export class PercentFormatPipe extends DisplayPercentPipe implements PipeTransform {
    private readonly ZERO_PERCENT = '0.00%';
    private readonly LESS_THAN_THRESHOLD = '< .01%';
    private readonly LESS_THAN_THRESHOLD_FEE_BREAKDOWN = '< .001%';

    // For method overriding
    public transform(value: unknown, displayPercentOptions: unknown, displayNumericScaleSuffix: unknown): string;
    public transform(value: number, formattingOptions: ProposalPercentFormat): string {
        if (value === 0) {
            return this.ZERO_PERCENT;
        } else {
            if (!formattingOptions.isFeeBreakdown) {
                if (value < .01) {
                    return this.LESS_THAN_THRESHOLD;
                } else {
                    return super.transform(value, formattingOptions.displayPercentOptions);
                }
            } else {
                if (value < .001) {
                    return this.LESS_THAN_THRESHOLD_FEE_BREAKDOWN;
                } else {
                    return super.transform(value, formattingOptions.displayPercentOptions);
                }
            }
        }
    }
}
