/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { UserProfile } from '@CarInterfaces/user-profile';
import { AuthActions, AuthActionTypes } from './auth.actions';

/**
 * Sets the slice name for NGRX since we are using lazy loading
 * Not needed if used at app.module
 */
export const authModuleSliceName = 'carAuthenticationSlice';

/**
 * CAR Authentication State object for Ngrx
 * @param loggedIn boolean value if siteminder ids translate to a CAR user profile object
 * @param userProfile CAR user profile object generated by translating siteminder to CAR
 */
export interface AuthState {
    loggedIn: boolean;
    userProfile: UserProfile;
}

/**
 * initial state of CarAUth Slice
 */
export const initialCarAuthState: AuthState = {
    loggedIn: false,
    userProfile: {
        userId: -1,
        userName: undefined,
        entityId: -1,
        smBrandId: undefined
    }
};

/**
 * NGRX Reducer for CarAuth SLice
 * @param state state to update NGRX, if non provided uses initialCarAuthState
 * @param action reducer action to preform
 */
export function AuthenticationSlice(
    state = initialCarAuthState,
    action: AuthActions
): AuthState {
    switch (action.type) {
        case AuthActionTypes.UpdatePrimaryAdvisorAction:
        case AuthActionTypes.LoginAction:
            return {
                loggedIn: true,
                userProfile: action.payload.userProfile
            };
        case AuthActionTypes.LogoutAction:
            return {
                loggedIn: false,
                userProfile: undefined
            };

        default:
            return state;
    }
}
