/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { RepositoryBase, RepositoryEndPoint } from '@CarInterfaces/repository-base';
import { GlobalService } from '@CarServices/system/global.service';
import { EMPTY, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export abstract class BaseRepository<T> implements RepositoryBase<T> {
    private localEndPoints: RepositoryEndPoint;
    private localReturnKey: string;

    constructor(protected readonly global: GlobalService) {}

    public get returnDataKey(): string {
        return this.localReturnKey;
    }

    public set returnDataKey(value: string) {
        this.localReturnKey = value;
    }

    public get endPoint() {
        return this.localEndPoints;
    }

    public set endPoint(item: RepositoryEndPoint) {
        this.localEndPoints = item;
    }

    public create(item: T): Observable<T> {
        this.global.logger.warn('create method not implemented');
        return EMPTY;
    }

    public update(item: T): Observable<T> {
        this.global.logger.warn('update method not implemented');
        return EMPTY;
    }

    public updatePost(item: T): Observable<T> {
        this.global.logger.warn('updatePost method not implemented');
        return EMPTY;
    }

    public read(id: number | string): Observable<T> {
        this.global.logger.warn('read method not implemented');
        return EMPTY;
    }

    public list(): Observable<T[]> {
        this.global.logger.warn('list method not implemented');
        return EMPTY;
    }

    public delete(id: number): Observable<T> {
        this.global.logger.warn('delete method not implemented');
        return EMPTY;
    }
}
