<div class="strategy-drawer"
     *ngIf="strategy && allocationStrategyStream | async as allocationStrategy">
    <div class="strategy-description">
        {{ strategyDescription }}
        <br>
        Projected Return {{(strategy.projectedReturn *  100)  | seiDisplayPercent:displayPercentOptions}} |
        Standard Deviation {{(strategy.standardDeviation *  100)  | seiDisplayPercent:displayPercentOptions }}
        <ng-container *ngIf="strategy.investmentObjectiveNm"> | {{ strategy.investmentObjectiveNm }} Focus </ng-container><br>
    </div>
    <sei-car-strategy-allocations [allocationStrategy]="allocationStrategy"></sei-car-strategy-allocations>
</div>
