<div class="wip-summary zero-padding">
    <div class="wip-summary-row sectionhead wip-header-row wip-checklist-section-subheader wip-checklist-section-header-bg-white">
        <div class="wip-summary-left subhead">
            <h4>Current Accounts</h4>
        </div>
        <div class="wip-summary-right">
            <div class="wip-summary-icon menu-caret"
                 *ngIf="isCurrentAccountAddEnable && currentAccountCheckLists.length > 0">
                <a (click)="currentAccountExpand()" href="javascript:void(0)">
                    <i class="fas" [class.fa-chevron-down]="isAccountsExpand"
                        [class.fa-chevron-right]="!isAccountsExpand" aria-hidden="true"></i>
                </a>
            </div>
            <div class="wip-summary-icon"
                 [class.disabled]="!isCurrentAccountAddEnable"
                 (click)="loadCurrentAccountForm()">Add</div>
        </div>
    </div>

    <div *ngIf="isAccountsExpand">
        <div *ngFor="let account of currentAccountList">
            <div [ngClass]="proposalAccountService.isAccountIncomplete(account?.mdcSnapShot) ? 'wip-section-background-gray' : 'wip-section-background-green'" class="wip-section-border wip-section-padding-bottom">
                <div class="wip-summary-row head zero-padding"
                    *ngIf="deleteError && account.id === currentAccountId">
                    <p class="text-warning delete-error"><i aria-hidden="true" class="fa-times-circle fas left-fa-icon"
                            (click)="closeErrorMessage()"></i> Account removal failed - Please try again</p>
                </div>
                <div class="wip-summary-row head zero-padding account-name">
                    <div class="wip-summary-left"
                        *ngIf="account.name">
                        <car-wip-checklist-status-indicator [mdcSnapShot]="account.mdcSnapShot"></car-wip-checklist-status-indicator>
                        {{account.name}}
                        <i *ngIf="showAccountNameInfo(account.id)" class="fas fa-info-circle fas-info icon-color-blue fa-sm"
                            [popover]="tooltipContentAccountName" container="body" #pop="bs-popover" [triggers]="'hover'"
                            [placement]="'right'">
                        </i>
                    </div>
                    <div class="wip-summary-left"
                        *ngIf="!account.name">
                        <car-wip-checklist-status-indicator [mdcSnapShot]="account.mdcSnapShot"></car-wip-checklist-status-indicator>
                        Account Name not Provided.
                    </div>
                    <div class="wip-summary-right">
                        <div *ngIf="!isReadUser"
                            class="wip-summary-icon menu-caret"
                            (click)="displayWarning(account.id, account.name)">
                            <a href="javascript:void(0)"><i class="fas fa-trash-alt" aria-hidden="true"></i></a>
                        </div>
                        <div *ngIf="isReadUser"
                            class="wip-summary-icon menu-caret">
                            <a href="javascript:void(0)"><i class="fas fa-trash-alt disabled" aria-hidden="true"></i></a>
                        </div>
                        <div class="wip-summary-icon"
                            (click)="getCurrentAccount(account.id)">Edit</div>
                    </div>
                </div>
                <div class="wip-summary-row account-details">
                    <div class="wip-summary-left row">
                        <div class="col-xs-6">
                            <span>Market Value</span>
                        </div>
                        <div class="col-xs-6">
                            <div>{{ account.marketValueDisplay | seiDisplayMonetaryAmount : displayMonetaryAmountOptions :
                                roundFractionalNumber : displayNumericScaleSuffix }}</div>                            
                        </div>
                    </div>
                </div>
                <div class="wip-summary-row account-details">
                    <div class="wip-summary-left row">
                        <div class="col-xs-6">
                            <span>Pricing as Of
                                <i class="fas fa-info-circle fas-info icon-color-blue" [popover]="tooltipContentPrice"
                                    container="body" #pop="bs-popover" [triggers]="'hover'" [placement]="'right'">
                                </i>
                            </span>
                        </div>
                        <div class="col-xs-6">
                            <span *ngIf="displayPriceAsOf(account.id)">
                                {{account.priceAsOfDate | seiDate }}
                            </span>
                            <span *ngIf="!displayPriceAsOf(account.id)">
                                N/A
                            </span>                  
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="current-account-page"
     *ngIf="loadCurrentAccount">
    <div class="right-modal-container"
         #rightHandModal>
        <div class="right-modal-content">
            <div class="right-modal-body">
                <sei-car-proposal-tax-transition-analysis-current-account [title]="title"
                                               [currentAccount]="currentAccount"
                                               [proposal]="proposal"
                                               [allowMultiple]="allowMultiple"
                                               [isReadUser]="isReadUser"
                                               [documents]="documents"
                                               (accountDetailsEvent)="getSelectedAccountDetails($event)"
                                               (documentsEvent)="uploadDocuments($event)"></sei-car-proposal-tax-transition-analysis-current-account>
            </div>
            <div class="worksheet-footer gradient-standard container-fluid fadeInUp">
                <div class="row">
                    <div class="col-xs-6">
                        <sei-button [buttonText]="'Cancel'"
                                    [buttonClass]="'btn-default'"
                                    [buttonLeftFontAwesomeIcon]="'fas fa-times-circle'"
                                    (buttonClicked)="onCancel()">
                        </sei-button>
                    </div>
                    <div class="col-xs-6">

                        <div class="row btn-toolbar pull-right">
                            <div class="col-xs-6">
                                <sei-button *ngIf="!overrideError"
                                            buttonText="Save & Exit"
                                            buttonRightFontAwesomeIcon="fas fa-save"
                                            buttonClass="btn-default"
                                            [buttonDisabled]="isSubmitDisabled || isReadUser"
                                            [tooltip]="getSaveAndExitTooltip()"
                                            container="sei-car-account-checklist"
                                            (buttonClicked)="submitCurrentAccount()">
                                </sei-button>
                                <sei-button *ngIf="overrideError"
                                            buttonText="Save & Exit"
                                            buttonRightFontAwesomeIcon="fas fa-save"
                                            buttonClass="btn-default"
                                            [buttonDisabled]="isSubmitEnabled"
                                            [popover]="'To continue, you can override the errors or upload an updated file.'"
                                            container="body"
                                            #pop="bs-popover"
                                            [triggers]="'hover'"
                                            [placement]="'left'">
                                </sei-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #tooltipContentPrice>{{infoTextPriceAsOf}}</ng-template>
<ng-template #tooltipContentAccountName>{{infoTextAccountName}}</ng-template>

<div class="delete-current-account-modal">
    <sei-modal-window #exitWarningModal
                      [modalType]="'sm'"
                      [modalTitle]="'Delete Current Account'"
                      [isBackdrop]="true"
                      [isDraggable]="false"
                      [footerEnable]="true"
                      (modalClosed)="onModalClosed();">
        <div class="modal-body">
            <p>You are about to delete this current account: {{currentAccountName}}</p>
            <p>Are you sure you want to continue?</p>
        </div>
        <footer class="modalbox-footer">
            <button type="button"
                    id="stay"
                    class="btn btn-default pull-left"
                    (click)="onModalClosed()">
                Cancel
            </button>
            <button type="button"
                    id="leave"
                    class="btn btn-primary"
                    (click)="deleteCurrentAccount()">
                Continue
            </button>
        </footer>
    </sei-modal-window>
</div>
