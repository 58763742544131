
/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */


import { Injectable } from '@angular/core';
import { AdvisorFee, FeeSchedule } from '../account-fee';
import { BaseFactoryAbstract } from './base-factory-abstract';


@Injectable()
export class AdvisorFeeFactory extends BaseFactoryAbstract<AdvisorFee> {

    constructor() {
        super();
    }

    public createObject(isNewAdvisor: boolean = false): AdvisorFee {
        return {
            feeAmount: 0,
            feeRate: 0,
            feeGroupDiscount: false,
            isDefault: false,
            scheduleSettings: false,
            excludeFeeOnCash: false,
            feeType: 0,
            flatFeeRate: 0,
            feeScheduleId: 0,
            feeScheduleName: '',
            feeScheduleType: 1,
            feeScheduleOptionalDiscount: false,
            feeSchedule: [],
            isNew: isNewAdvisor
        };
    }
    public creteFeeSchedule(): FeeSchedule {
        return {
            id: 0,
            slab: 0,
            lowValue: 0,
            highValue: 0,
            rate: 0,
            index: 0,
            isValid: true
        };
    }
}
