/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { Scenario } from '../proposal';
import { BaseFactoryAbstract } from './base-factory-abstract';
@Injectable()
export class ScenarioFactory extends BaseFactoryAbstract<Scenario> {
    constructor() {
        super();
    }
    public createObject(accountIndex: number): Scenario {
        return {
            id: accountIndex,
            name: '',
            accountBalance: 0.00,
            type:
            {
                id: 1,
                description: 'Proposed'
            },
            accounts: [],
            goals: []
        };
    }
}
