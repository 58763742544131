/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class PropertyService {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public exists(obj: any): boolean {
        try {
            return obj !== undefined;
        } catch {
            return false;
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public notExists(obj: any): boolean {
        return !this.exists(obj);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public existsAndTruthy(obj: any): boolean {
        return this.exists(obj) && !!obj;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public notExistsOrFalsey(obj: any): boolean {
        return this.notExists(obj) || !obj;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public allExist(objects: any[]): boolean {
        for (let i = 0; i < objects.length; i++) {
            if (!this.exists(objects[i])) {
                return false;
            }
        }

        return true;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public anyExist(objects: any[]): boolean {
        for (let i = 0; i < objects.length; i++) {
            if (this.exists(objects[i])) {
                return true;
            }
        }

        return false;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public allExistAndTruthy(objects: any[]): boolean {
        for (let i = 0; i < objects.length; i++) {
            if (!this.existsAndTruthy(objects[i])) {
                return false;
            }
        }

        return true;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public anyExistAndTruthy(objects: any[]): boolean {
        for (let i = 0; i < objects.length; i++) {
            if (this.existsAndTruthy(objects[i])) {
                return true;
            }
        }

        return false;
    }
}
