/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { createSelector } from '@ngrx/store';

/**
 * Returns the state from the slice
 * For testing purposes, we need an empty object to return or undefined is encountered.
 * @param state
 */
export const selectAccountState = (state) =>
    state.carAccountSlice ? state.carAccountSlice : { account: {} };

export const getAccount = createSelector(
    selectAccountState,
    (state) => state.account
);
