/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@CarComponents/common/common.module';
import { EntityDropdownEsComponent } from '@sei/client-account-administration-lib-ux';
import { AccountOwnerComponent } from './account-owner/account-owner.component';
import { AccountTypeListingComponent } from './account-type-listing/account-type-listing.component';
import { AdvisorComponent } from './advisor/advisor.component';
import { InvestmentProgramListingComponent } from './investment-program-listing/investment-program-listing.component';
import { MrdcAccountFormComponent } from './mrdc-account-form/mrdc-account-form.component';
import { MrdcMainComponent } from './mrdc-main/mrdc-main.component';
import { OfficeLocationComponent } from './office-location/office-location.component';

@NgModule({
    imports: [CommonModule],
    declarations: [
        AccountOwnerComponent,
        AccountTypeListingComponent,
        AdvisorComponent,
        InvestmentProgramListingComponent,
        MrdcAccountFormComponent,
        OfficeLocationComponent,
        MrdcMainComponent
    ],
    providers: [],
    exports: [
        AccountOwnerComponent,
        AccountTypeListingComponent,
        AdvisorComponent,
        InvestmentProgramListingComponent,
        MrdcAccountFormComponent,
        OfficeLocationComponent,
        EntityDropdownEsComponent
    ]
})
export class MrdcModule { }
