/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { ActionFrom, AssetActivityType, AssetAllocationMetrics, ModelAllocation, ModelType, ProductDetail, ProductScope } from '@sei/ias-applications-lib-ux';
import { BaseFactoryAbstract } from './base-factory-abstract';

@Injectable()
export class ProductDetailFactory extends BaseFactoryAbstract<ProductDetail> {

        constructor() {
                super();
        }

        public createProductDetail(
                name: string,
                investmentRiskType: string,
                currentMarketValue: number,
                firmIdValue: number,
                modelAllocation: ModelAllocation[],
                assetAllocationMetrics: AssetAllocationMetrics,
                isCustom: boolean
        ): ProductDetail {
                return {
                        activityType: AssetActivityType.AddStrategy,
                        modelType: ModelType.UMAModel,
                        pmeModelId: 0,
                        swpStrategyId: 0,
                        productScope: ProductScope.SEI,
                        name,
                        investmentRiskType,
                        previousAllocationId: 0,
                        previousAllocationName: '',
                        previousAllocationType: '',
                        isModified: true,
                        portfolioId: 0,
                        processingOrgId: 0,
                        firmId: firmIdValue,
                        currentMarketValue,
                        previousMarketValue: 0,
                        actionFrom: ActionFrom.UMA,
                        modelAllocation,
                        assetAllocationMetrics,
                        isNameValid: true,
                        isCustom
                };
        }
}
