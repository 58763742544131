/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Type } from '../model/proposal';
import { SeiPayload } from '../model/sei-payload';
import { InvestmentProgram } from '../model/investment-program';
import { GlobalService } from './system/global.service';


@Injectable({
    providedIn: 'root'
})
export class InvestmentProgramService {

    constructor(
        private readonly global: GlobalService,
        private http: HttpClient
    ) { }

    public getInvestmentPrograms(
        firmId: number
    ): Observable<InvestmentProgram[]> {
        const apiUrl = this.global.configService.routeFormatter(
            this.global.configService.getEndPoint(
                'investmentProgramsByFirmId'
            ),
            [
                {
                    key: 'firmId',
                    value: firmId
                }
            ]
        );

        return this.http.get(apiUrl).pipe(
            map((response: SeiPayload) => {
                if (response &&
                    response.data) {
                    return response.data[0].investmentPrograms;
                }
            })
        );
    }
}
