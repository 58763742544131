/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component } from '@angular/core';

@Component({
    selector: 'sei-car-wip-checklist-spacer',
    templateUrl: './wip-checklist-spacer.component.html',
    styleUrls: ['./wip-checklist-spacer.component.scss']
})
export class WipChecklistSpacerComponent {

}
