<div (click)="onClick($event)">
    <select *ngIf="contentType !== inputPropertyType.Color"
            [style.width.%]="selectWidth"
            [(ngModel)]="value">
        <option disabled>Select an option</option>
        <option *ngFor="let dropDownContent of dropDownContent;"
                [disabled]="!dropDownContent.value"
                [ngValue]="dropDownContent.value">{{dropDownContent.label}}
        </option>
    </select>
</div>
<input class="form-control"
       *ngIf="contentType === inputPropertyType.Color"
       type="text"
       [cpPresetColors]="['#2aa5d6', '#aad037', '#f58020', '#e26fab', '#fed000', '#ffffff', '#037EA6', '#66953D', '#13BFB1', '#EFD900',
       '#8E0C71', '#C3CD19', '#173B6B', '#00692D', '#0B6D65', '#580C41', '#F0500A', '#91140F']"
       [cpOutputFormat]="'hex'"
       [cpAlphaChannel]="'disabled'"
       [(colorPicker)]="value"
       [(ngModel)]="value"
       (ngModelChange)="onColorPickerChange($event)"
       [disabled]="(presentationType === proposalSectionLabel.Proposal && coverTitleDisabled)">

<div *ngIf="contentType === inputPropertyType.Color"
     class="color-block"
     [ngStyle]="{'background-color': value}">
</div>
