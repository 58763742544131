/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AccountOwnerDropdownStaticOptions } from '../../model/enums';

@Component({
    selector: 'car-account-owner-dropdown',
    templateUrl: './account-owner-dropdown.component.html'
})
export class AccountOwnerDropdownComponent {
    @Input()
    elasticSearchData: Array<{ value: string; label: string }>;
    @Input()
    hideLabel: boolean = false;
    @Output()
    public accountOwnersDropdownSelected = new EventEmitter();
    public staticOptions = AccountOwnerDropdownStaticOptions;

    public onChange(event): void {
        if (
            event &&
            event.target &&
            event.target.value
        ) {
            if (event.target.value === AccountOwnerDropdownStaticOptions.StartTypingName) {
                return;
            } else {
                this.accountOwnersDropdownSelected.emit(event.target.value);
            }
        }
    }
}
