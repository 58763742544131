/*
* Copyright: This information constitutes the exclusive property of SEI
* Investments Company, and constitutes the confidential and proprietary
* information of SEI Investments Company.  The information shall not be
* used or disclosed for any purpose without the written consent of SEI
* Investments Company.
*/

import { Injectable } from '@angular/core';
import { AccountTypeOptionSettings, accountTypeOptionsData, AccountOwnerType } from '../model/account-type-options';

import { elasticSearchAccountOwnerData } from '../model/proposal';
import { ContactTypes } from '../model/enums';

@Injectable({
    providedIn: 'root'
})
export class AccountTypeOptionsService {

    /**
     * gets the options object for a requested account type
     * @param accountTypeId account type id to search
     */
    public getOptions(accountTypeId: number): AccountTypeOptionSettings {
        return accountTypeOptionsData.find(
            (option: AccountTypeOptionSettings) =>
                option.accountTypeId === accountTypeId
        );
    }


    public getElasticSearchAccountOwnerDataFilterByAccountType(accountTypeId: number): AccountOwnerType[] {
        let elasticSearchDataFilter: AccountOwnerType[];
        const elasticSearchData: Array<{ value: string; label: string; contactTypeId: number }> = elasticSearchAccountOwnerData;
        const accountOptions = this.getOptions(accountTypeId);
        if (accountOptions && accountOptions.accountContactTypes) {

            if (accountOptions.accountContactTypes.find((accountTypeItem) => accountTypeItem === ContactTypes.Individual) &&
                (accountOptions.accountContactTypes.find((accountTypeItem) => accountTypeItem === ContactTypes.Organization))) {

                elasticSearchDataFilter = elasticSearchData;

            } else if (accountOptions.accountContactTypes.find((accountTypeItem) => accountTypeItem === ContactTypes.Individual)) {

                elasticSearchDataFilter = elasticSearchData.filter(
                    (data) =>
                        data.contactTypeId === ContactTypes.Individual
                );
            } else {
                elasticSearchDataFilter = elasticSearchData.filter(
                    (data) =>
                        data.contactTypeId === ContactTypes.Organization
                );
            }
        } else {
            // Note: i'm not sure this should be default, I would suspect
            // that we should throw an error if the account type does not
            // have the contact type configuration
            elasticSearchDataFilter = elasticSearchData;
        }
        return elasticSearchDataFilter;
    }
}

