<car-right-hand-dialog [footerFunction]="footerFunction">
    <car-generic-error-notification *ngIf="!getHoldingsCallFailed"></car-generic-error-notification>
    <div class="header-padding" [ngClass]="isExistingAccountsFeatureEnabled ? 'fixed' : ''">
        <div class="row">
            <div class="col-xs-12">
                <h1>{{ isExistingAccountsFeatureEnabled ? 'Accounts' : 'Proposed Accounts' }}</h1>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <sei-button
                    *ngIf="isExistingAccountsFeatureEnabled"
                    class="add-account-button"
                    buttonClass="btn-primary"
                    buttonText="Add Proposed Accounts"
                    [buttonDisabled]="isAddProposedAccountsButtonDisabled  || !isMaximumNumberOfAccountsReached()"
                    (buttonClicked)="addProposedAccountsClicked()">
                </sei-button>
    
                <sei-button
                    *ngIf="showSelectExistingAccountsButton()"
                    buttonClass="btn-primary"
                    buttonText="Select Existing Accounts"
                    (buttonClicked)="selectExistingAccountsClicked()">
                </sei-button>
            </div>
        </div>
    </div>

    <sei-car-proposed-accounts-section *ngIf="isProposedAccountsSectionVisible()"
        [proposal]="proposal"
        [scenario]="scenario">
    </sei-car-proposed-accounts-section>

    <sei-button
        *ngIf="!isExistingAccountsFeatureEnabled"
        class="add-account-button"
        buttonClass="btn-primary"
        buttonText="Add Account"
        [buttonDisabled]="isAddProposedAccountsButtonDisabled  || !isMaximumNumberOfAccountsReached()"
        (buttonClicked)="addProposedAccountsClicked()">
    </sei-button>

    <sei-car-existing-accounts-section
        [existingAccounts]="selectedExistingAccounts"
        [proposal]="proposal"
        [scenario]="scenario">
    </sei-car-existing-accounts-section>
    
</car-right-hand-dialog>
