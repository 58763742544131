/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { PercentPipe } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { DisplayNullOptions, DisplayPercentOptions } from '@sei/common-components-lib-ux';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { StrategyAllocation } from '../../model/allocation';

@Component({
    selector: 'sei-car-strategy-allocations',
    templateUrl: './strategy-allocations.component.html',
    styleUrls: ['./strategy-allocations.component.scss']
})
export class StrategyAllocationsComponent extends ParentComponentSubscriptionManager implements OnInit, AfterViewInit {

    @Input()
    public allocationStrategy: StrategyAllocation;
    public strategyDescription = '';

    public displayPercentOptions: DisplayPercentOptions;

    // Note: This functionality is built for future substitution options, but this is not applicable for M1
    public disableStrategyAllocation: boolean = true;

    constructor(private percent: PercentPipe) {
        super('StrategyAllocationsComponent');
        super.ngOnDestroy();

        this.displayPercentOptions = {
            displayPercentSymbol: true,
            roundToDecimal: 2, nullDisplay: DisplayNullOptions.BLANK
        };
    }

    public ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.allocationStrategy && this.allocationStrategy.assetClasses) {
                this.allocationStrategy.assetClasses.forEach((assetClass) => {
                    this.strategyDescription = this.strategyDescription
                        + `${this.percent.transform(assetClass.percentage, '1.1-2')} ${assetClass.assetClassLevel1} / `;
                });

                this.strategyDescription = this.strategyDescription.slice(0, -2);
            }
        }, 100);
    }
}
