<div class="hud-wrapper"
     [class.hud-wrapper-closed]="hudCompressed">
    <div class="hud animated fadeIn slow delay-500 open"
         id="hud-fees">
        <div class="hud-main">
            <div *ngIf="hudMode === hudModes.Questionnaire; then Questionnaire"></div>
            <div *ngIf="hudMode === hudModes.FeeSummary; then Fees"></div>
            <div *ngIf="hudMode === hudModes.Strategy; then Strategy"></div>

            <ng-template #Questionnaire>
                <div *ngIf="!hudCompressed">
                    <div class="flex-row attributes dblock flx-sz pill">
                        <div class="pill"
                             [class.na]="hudQuestionnaire.riskLevel === riskLevels.None"
                             [class.low]="hudQuestionnaire.riskLevel === riskLevels.Low"
                             [class.medium]="hudQuestionnaire.riskLevel === riskLevels.Medium"
                             [class.high]="hudQuestionnaire.riskLevel === riskLevels.High">
                            {{ hudQuestionnaire.riskLevel }}
                        </div>
                    </div>
                    <div class="flex-row attributes text-center">
                        <div class="flx-sz size-100">
                            <span class="copy-xsmall">Complete</span>
                            <h1 class="indicator-percentage" *ngIf="hudQuestionnaire.progressPercent > 0"> {{
                                hudQuestionnaire.progressPercent |
                                percent}}
                            </h1>
                            <h1 class="indicator-percentage" *ngIf="hudQuestionnaire.progressPercent <= 0"> - - </h1>
                            <div class="indicator-remaining">
                                <div class="indicator-completed"
                                     [style.width.%]="hudQuestionnaire.progressBarPercent">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="hudCompressed">
                    <div class="flex-row attributes single text-center compressed">
                        <div class="flx-sz size-100">
                            <div class="pill compressed"
                                 [class.na]="hudQuestionnaire.riskLevel === riskLevels.None"
                                 [class.low]="hudQuestionnaire.riskLevel === riskLevels.Low"
                                 [class.medium]="hudQuestionnaire.riskLevel === riskLevels.Medium"
                                 [class.high]="hudQuestionnaire.riskLevel === riskLevels.High">
                                {{ hudQuestionnaire.riskLevel }}
                            </div>
                            <span class="copy-xsmall">Complete</span>
                            <h1 class="indicator-percentage" *ngIf="hudQuestionnaire.progressPercent > 0"> {{
                                hudQuestionnaire.progressPercent |
                                percent}}
                            </h1>
                            <h1 class="indicator-percentage" *ngIf="hudQuestionnaire.progressPercent <= 0"> - - </h1>
                            <div class="indicator-remaining">
                                <div class="indicator-completed"
                                     [style.width.%]="hudQuestionnaire.progressBarPercent">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>

            <ng-template #Fees>
                <ng-container *ngIf="!hudCompressed">
                    <div class="flex-row attributes minor">
                        <div class="flx-sz size-20 padding">
                            <span class="wrap">
                                <span class="inline">Fee Based AUM</span>
                            <span class="fa-stack inline stack-margin" *ngIf="isClientDirectedPresent()">
                                    <i class="fas fa-circle fa-stack-1x background-white"></i>
                                    <i class="fas fa-info-circle fa-stack-1x text-info" [tooltip]="getTooltipMessage()" containerClass="client-directed"></i>
                                </span>
                            </span>
                            <span>{{hudSummary.feeBasedAUM | currencySuffix }}</span>
                        </div>
                        <div class="flx-sz size-20 padding">
                            <span>Inv. Cost</span>
                            <span>
                                <span class="avoid-line-break">
                                    {{ (hudSummary.investmentCost.rate * 100) | proposalFormatPercentWithLessThan:proposalPercentFormatOptions }}
                                </span> <span class="avoid-line-break">| <sei-car-display-monetary-amount [amount]="hudSummary?.investmentCost.amount"
                                            [actualCurrency]="currencyTypes.USD"></sei-car-display-monetary-amount></span>
                            </span>
                        </div>
                        <div class="flx-sz size-60 no-border">
                            <div class="fees-ribbon">
                                <div class="arrow-left"></div>
                                <div class="padding-left">
                                    <span>{{hudSummary.fundExpense.label}}</span>
                                    <span>
                                        <span class="avoid-line-break">
                                            {{ (hudSummary?.fundExpense.rate * 100) | proposalFormatPercentWithLessThan:proposalPercentFormatOptions }}
                                        </span> <span class="avoid-line-break">| <sei-car-display-monetary-amount [amount]="hudSummary?.fundExpense.amount"
                                                    [actualCurrency]="currencyTypes.USD"></sei-car-display-monetary-amount></span>
                                    </span>
                                </div>
                                <div class="fee-icon-container">
                                    <span class="prevent-overflow">
                                        <span class="inline top-align">{{hudSummary.managementExpense.label}}</span>
                                    <span class="inline top-align icon-margin">
                                        <sei-car-fee-breakdown-tooltip class="stack-margin"
                                                                    [managementFeeBreakdown]="managementFeeBreakdown"
                                                                    [includeBackground]="true"></sei-car-fee-breakdown-tooltip>
                                        </span>
                                    </span>

                                    <span>
                                        <span class="avoid-line-break">
                                            {{ (hudSummary.managementExpense.rate * 100) | proposalFormatPercentWithLessThan:proposalPercentFormatOptions }}
                                        </span> <span class="avoid-line-break">| <sei-car-display-monetary-amount [amount]="hudSummary?.managementExpense.amount"
                                                    [actualCurrency]="currencyTypes.USD"></sei-car-display-monetary-amount></span>
                                    </span>
                                </div>
                                <div class="padding-right">
                                    <span>{{hudSummary.platformFee.label}}</span>
                                    <span>
                                        <span class="avoid-line-break">
                                            {{ (hudSummary.platformFee.rate * 100) | proposalFormatPercentWithLessThan:proposalPercentFormatOptions }}
                                        </span> <span class="avoid-line-break">| <sei-car-display-monetary-amount [amount]="hudSummary?.platformFee.amount"
                                                    [actualCurrency]="currencyTypes.USD"></sei-car-display-monetary-amount></span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex-row attributes major">
                        <div class="flx-sz size-50 text-center">
                            <span>Est. Advisor Fee</span>
                            <span>
                                <span class="avoid-line-break">
                                    {{ (hudSummary.estimatedAdvisorFee.rate * 100) | proposalFormatPercentWithLessThan:proposalPercentFormatOptions }}
                                </span> <span class="avoid-line-break">| {{ hudSummary.estimatedAdvisorFee.amount | currency }}</span>
                            </span>
                        </div>
                        <div class="flx-sz size-50 text-center">
                            <span>Cost to Client</span>
                            <span>
                                <span class="avoid-line-break">
                                    {{ (hudSummary.costToClient.rate * 100) | proposalFormatPercentWithLessThan:proposalPercentFormatOptions }}
                                </span> <span class="avoid-line-break">| <sei-car-display-monetary-amount [amount]="hudSummary.costToClient.amount"
                                            [actualCurrency]="currencyTypes.USD"></sei-car-display-monetary-amount></span>
                            </span>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="hudCompressed">
                    <div class="flex-row attributes single">
                        <div class="flx-sz size-100 text-center">
                            <span>Est. Advisor Fee</span>
                            <span>
                                <span class="avoid-line-break">
                                    {{ (hudSummary.estimatedAdvisorFee.rate * 100) | proposalFormatPercentWithLessThan:proposalPercentFormatOptions }}
                                </span> <span class="avoid-line-break">| <sei-car-display-monetary-amount [amount]="hudSummary?.estimatedAdvisorFee.amount"
                                            [actualCurrency]="currencyTypes.USD"></sei-car-display-monetary-amount></span>
                            </span>
                        </div>
                    </div>
                </ng-container>
            </ng-template>

            <ng-template #Strategy>
                <div *ngIf="!hudCompressed">
                    <div class="flex-row attributes major">
                        <div class="flx-sz size-50 text-center fee-strategy-text">
                            <span>Total</span><br />
                            <span *ngIf="feeSummaryLoaded">{{hudFeeStrategy.total | currencySuffix }}</span>
                        </div>
                        <div class="flx-sz size-50 text-center fee-strategy-text">
                            <span>Min</span><br />
                            <span *ngIf="feeSummaryLoaded">{{hudFeeStrategy.min | currencySuffix }}</span>
                        </div>
                        <div class="flx-sz size-50 text-center fee-strategy">
                            <sei-car-fee-strategy-summary [account]="hudFeeStrategy.account"
                                                          (feeSummaryLoaded)="setFeeSummaryLoaded($event)"></sei-car-fee-strategy-summary>
                        </div>
                    </div>
                </div>
                <div *ngIf="hudCompressed">
                    <div class="flex-row attributes single">
                        <div class="flx-sz size-100 text-center fee-strategy-text-compressed">
                            <span>Total</span><br />
                            <span>{{hudFeeStrategy.total | currencySuffix }}</span>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
        <div class="hud-tab">
            <i class="fas" [class.fa-compress]="!hudCompressed" [class.fa-expand]="hudCompressed" aria-hidden="true"
                (click)="onHudCompressToggle()"></i>
            <i class="fas fa-info-circle fas-info" placement="left" [tooltip]="tipContent"></i>
        </div>
    </div>
</div>

<ng-template #tipContent>{{hudInfoText}}</ng-template>
