/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
import { FileUploadForm } from '../../model/file-upload';
import { BaseFactoryAbstract } from './base-factory-abstract';

export class FileUploadFactory extends BaseFactoryAbstract<FileUploadForm> {
    constructor() {
        super();
    }

    public createObject(
        files?: File[],
        documentTypeId?: number
    ): FileUploadForm {
        return {
            files: files ? files : undefined,
            fileTypeId: documentTypeId ? documentTypeId : undefined
        };
    }
}
