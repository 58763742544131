/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { CommonModule } from '@CarComponents/common/common.module';
import { NgModule } from '@angular/core';
import { SeiCommonComponentsLibraryModule } from '@sei/common-components-lib-ux';
import { FundingDirectionCashComponent } from './funding-direction-cash/funding-direction-cash.component';
import { FundingDirectionDcaContributionOptionComponent } from './funding-direction-dca-contribution-option/funding-direction-dca-contribution-option.component';
import { FundingDirectionDcaComponent } from './funding-direction-dca/funding-direction-dca.component';
import { FundingDirectionSummaryDcaComponent } from './funding-direction-summary-dca/funding-direction-summary-dca.component';
import { FundingDirectionSummaryComponent } from './funding-direction-summary/funding-direction-summary.component';
import { FundingDirectionTransferTypeSelectorComponent } from './funding-direction-transfer-type-selector/funding-direction-transfer-type-selector.component';
import { FundingDirectionTransferComponent } from './funding-direction-transfer/funding-direction-transfer.component';
import { WIPFundingDirectionsComponent } from './wip-funding-directions-main/wip-funding-directions-main.component';

@NgModule({
    imports: [CommonModule,
        SeiCommonComponentsLibraryModule
    ],
    declarations: [
        WIPFundingDirectionsComponent,
        FundingDirectionCashComponent,
        FundingDirectionSummaryComponent,
        FundingDirectionTransferComponent,
        FundingDirectionTransferTypeSelectorComponent,
        FundingDirectionDcaComponent,
        FundingDirectionDcaContributionOptionComponent,
        FundingDirectionSummaryDcaComponent
    ],
    providers: [],
    exports: [WIPFundingDirectionsComponent, FundingDirectionSummaryComponent]
})
export class WipFundingDirectionsModule { }
