/*
Copyright: This information constitutes the exclusive property of SEI
Investments Company, and constitutes the confidential and proprietary
information of SEI Investments Company.  The information shall not be
used or disclosed for any purpose without the written consent of SEI
Investments Company.
*/

import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
    name: 'orderBy',
    standalone: true
})
export class OrderByPipe<T> implements PipeTransform {
    // Built to replace ngx-order-pipe package as it is no longer compatible with newer versions of Angular.
    transform(data: T[], orderBy: string, direction: boolean = true): T[] {
        const sortDirection: 'asc' | 'desc' = direction ? 'asc' : 'desc';
        return _.orderBy(data, [orderBy], [sortDirection]);
    }
}
