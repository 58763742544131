/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
/* eslint-disable */
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { delay, dematerialize, materialize, mergeMap } from 'rxjs/operators';
import allocations from '../../../assets/mock-data/allocations.json';
import coverImages from '../../../assets/mock-data/coverImages.json';
import firmsAdvisors from '../../../assets/mock-data/firmsAdvisors.json';
import previouslyUsedStrategies from '../../../assets/mock-data/previosly-used-strategies.json';
import presentationSections from '../../../assets/mock-data/static-presentation-sections.json';
import feesStrategy from '../../../assets/mock-data/feesStrategy.json';
import { UserProfile } from '../../../../../advisor-client-review-proposal-ux/src/lib/model/user-profile';

@Injectable()
export class FakeBackendInterceptor implements HttpInterceptor {

    constructor() { }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const { url, method, headers, body } = request;

        // array in local storage for proposals
        let proposals = JSON.parse(localStorage.getItem('proposals')) || [];

        // array in local storage for prestationSettings
        let presentationSettings = JSON.parse(localStorage.getItem('presentationSettings')) || [];

        // array in local storage for prestationSettings
        let checkList = JSON.parse(localStorage.getItem('checkList')) || [];

        // array in local storage for FeeGroups
        let feeGroupList = JSON.parse(localStorage.getItem('feeGroups')) || [];

        // wrap in delayed observable to simulate server api call
        return of(null)
            .pipe(mergeMap(handleRoute))
            // tslint:disable-next-line:max-line-length
            .pipe(materialize())
            .pipe(delay(500))
            .pipe(dematerialize());

        function handleRoute() {
            switch (true) {
                // case url.endsWith('/proposals') && method === 'POST':
                //     return createProposal();
                // case url.match(/\/proposals\/\d+$/) && method === 'PUT':
                //     return updateProposal();
                // case url.match(/\/proposals\/\d+$/) && method === 'DELETE':
                //     return deleteProposal();
                // case url.endsWith('/proposals') && method === 'GET':
                //     return getProposals();
                // case url.match(/\/proposals\/\d+$/) && method === 'GET':
                //     return getProposalById();
                // case url.match(/\/advisors\/firms\/\d+$/) && method === 'GET':
                //     return getAdvisorsByFirmId();
                // case url.match(/\/advisors\/stratgies\/\d+$/) && method === 'GET':
                //     return getStrategiseByAdvisorIdStream();
                // case url.match(/\/advisors\/stratgies\/allocation\/\d+$/) && method === 'GET':
                //     return getAllocationByStrategyIdStream();
                // case url.match(/\/presentationSetting\/\d+$/) && method === 'GET':
                //     return getPresentationSettingById();
                // case url.endsWith('/presentationSetting') && method === 'POST':
                //     return upsertPresentationSetting();
                // case url.match(/\/presentationChecklist\/\d+$/) && method === 'GET':
                //     return getCheckListById();
                // case url.endsWith('/presentationChecklist') && method === 'POST':
                //     return upsertCheckList();
                case url.endsWith('/presentationSections') && method === 'GET':
                    return getPresentationSections();
                // case url.endsWith('/presentationSections/coverImages') && method === 'GET':
                //     return getPresentationSectionCoverImages();
                // case url.endsWith('firmsAdvisors') && method === 'GET':
                //     return getFirmsAdvisors();
                // case url.endsWith('/user/profile') && method === 'GET':
                //     return getCurrentUserProfile();
                // case url.endsWith('/user/profile/firm') && method === 'PUT':
                //     return updateCurrentUserProfileFirm();
                // case url.endsWith('/FeeGroup') && method === 'POST':
                //     return CreateFeeGroup();
                // case url.endsWith('/FeeStrategy') && method === 'GET':
                //     return getFeeStrategyCalculation();
                // case url.includes('/getPdfExistingStatus') && method === 'GET':
                //     return getPDFExistingStatus();
                // case url.includes('/generatePresentationPdf') && method === 'GET':
                //     return generatePresentationPDF();
                default:
                    // pass through any requests not handled above
                    return next.handle(request);
            }
        }

        function getPDFExistingStatus() {
            let json = {
                data: [{
                    pdfStatusFile: true
                }]
            };
            return ok(json);
        }

        function generatePresentationPDF() {
            let json = {
                data: [{
                    presentationPDFPath: 'scr/assets/pdf/sample.pdf'
                }]
            };
            return ok(json);
        }

        function CreateFeeGroup() {

            let feeGroup = request.body;
            let currentFeeGroup = [];

            if (feeGroupList && feeGroupList.body && feeGroupList.body.data) {
                currentFeeGroup = feeGroupList.body.data[0].feeGroups;
            }
            currentFeeGroup.push(feeGroup);

            let json = {
                body: {
                    data: [{
                        feeGroups: currentFeeGroup
                    }]
                }
            };

            localStorage.removeItem('feeGroups');
            localStorage.setItem('feeGroups', JSON.stringify(json));

            return ok();
        }


        function createProposal() {
            // get new user object from post body
            let newProposal = request.body;
            let currentProposals = [];

            if (proposals && proposals.body && proposals.body.data) {
                currentProposals = proposals.body.data[0].proposals;
                // Note: this is put in place so we clear local storage after number of proposals and just keep last five
                if (currentProposals.length >= 10) {
                    currentProposals = currentProposals.slice(5, 10);
                }
            }

            // Note: taken out for debugging purposes
            // if (currentProposals.find(x => x.name === newProposal.name)) {
            //     return error('Proposal "' + newProposal.name + '" is already taken')
            // }

            newProposal.id = currentProposals.length ? Math.max(...currentProposals.map((x) => x.id)) + 1 : 1;

            currentProposals.push(newProposal);
            localStorage.removeItem('proposals');

            let json = {
                body: {
                    data: [{
                        proposals: currentProposals
                    }]
                }
            };

            localStorage.setItem('proposals', JSON.stringify(json));

            return ok();
        }

        function updateProposal() {
            let requestProposal = request.body;
            let currentProposals = [];

            currentProposals = proposals.body.data[0].proposals;

            const index = currentProposals.findIndex((proposal) => proposal.id === idFromUrl());
            currentProposals.splice(index, 1);
            currentProposals.push(requestProposal);

            let json = {
                body: {
                    data: [{
                        proposals: currentProposals
                    }]
                }
            };


            localStorage.removeItem('proposals');
            localStorage.setItem('proposals', JSON.stringify(json));

            return ok();
        }

        function deleteProposal() {
            // let requestProposal = request.body;
            let currentProposals = [];

            currentProposals = proposals.body.data[0].proposals;

            const index = currentProposals.findIndex((proposal) => proposal.id === idFromUrl());
            currentProposals.splice(index, 1);
            // currentProposals.push(requestProposal);

            let json = {
                body: {
                    data: [{
                        proposals: currentProposals
                    }]
                }
            };


            localStorage.removeItem('proposals');
            localStorage.setItem('proposals', JSON.stringify(json));

            return ok();
        }

        // function getAdvisorsByFirmId() {
        //     const response = {
        //         body: {
        //             data: [
        //                 {
        //                     firms: [
        //                         {
        //                             firmId: idFromUrl(),
        //                             firmName: '',
        //                             advisors: adivsorsMock
        //                         }
        //                     ]
        //                 }
        //             ]
        //         }
        //     };
        //     return ok(response);
        // }

        // route functions
        function getProposals() {
            // if (proposals.length === 0 || proposals.body.data[0].proposals.length === 0) {
            //     localStorage.setItem('proposals', JSON.stringify(proposalMock));
            //     proposals = JSON.parse(localStorage.getItem('proposals'))
            // }

            // if (!isLoggedIn()) { return unauthorized(); }
            return ok(proposals);
        }

        function getProposalById() {
            const matchedProposal = proposals.body.data[0].proposals.filter((proposal) => { return proposal.id === idFromUrl(); });
            const response = {
                data: [{
                    proposals: matchedProposal
                }]
            };
            return ok(response);
        }

        function getStrategiseByAdvisorIdStream() {
            // const strategies = require("../../assets/mock-data/json/previosly-used-strategies.json");
            const strategies = previouslyUsedStrategies.body.data[0].strategies;
            return ok(strategies);
        }

        function getAllocationByStrategyIdStream() {
            // const jsonAllocations = require("../../assets/mock-data/json/allocations.json");
            const strategyAllocations = allocations.body.data[0].strategyAllocations;

            // let allocations = JSON.parse(jsonAllocations);
            let matchedAllocation = strategyAllocations.filter((strategy) => { return strategy.strategyId === idFromUrl(); });

            const response = {
                body: {
                    data: [
                        {
                            strategyAllocation: matchedAllocation.shift()
                        }
                    ]
                }
            };

            return ok(response);
        }

        function getPresentationSections() {
            return ok(presentationSections);
        }

        function getCurrentPresentationSetting(id: number) {
            let matchedPresentationSetting = null;
            if (presentationSettings.body && presentationSettings.body.data && presentationSettings.body.data.length > 0) {
                matchedPresentationSetting =
                    presentationSettings.body.data[0].presentationSettings.find((presentationSetting) => presentationSetting.id === id);
            }
            return matchedPresentationSetting;
        }

        function getCurrentCheckList(id: number) {
            let matchedCheckList = null;
            if (checkList.body && checkList.body.data && checkList.body.data.length > 0) {
                matchedCheckList = checkList.body.data[0].checkList.find((checkList) => checkList.proposalId === id);
            }
            return matchedCheckList;
        }

        function getPresentationSettingById() {
            let matchedPresentationSetting = getCurrentPresentationSetting(idFromUrl());
            if (matchedPresentationSetting) {
                const response = {
                    body: {
                        data: [{
                            presentationSetting: matchedPresentationSetting
                        }]
                    }
                };
                return ok(response);
            }

            return ok();
        }

        function upsertPresentationSetting() {

            let newPresentationSetting = request.body;
            let currentPresentationSettings = [];

            if (presentationSettings && presentationSettings.body && presentationSettings.body.data) {
                currentPresentationSettings = presentationSettings.body.data[0].presentationSettings;
                // Note: this is put in place so we clear local storage after number of settings and just keep last five
                if (currentPresentationSettings.length >= 10) {
                    currentPresentationSettings = currentPresentationSettings.slice(5, 10);
                }
            }

            let matchedPresentationSetting = getCurrentPresentationSetting(newPresentationSetting.id);
            if (matchedPresentationSetting) {
                // Found one setting so update
                const foundIndex = currentPresentationSettings.findIndex((item) => item.id === matchedPresentationSetting.id);
                if (foundIndex >= 0) {
                    currentPresentationSettings[foundIndex] = newPresentationSetting;
                }
            }
            else {
                currentPresentationSettings.push(newPresentationSetting);
            }

            localStorage.removeItem('presentationSettings');

            let json = {
                body: {
                    data: [{
                        presentationSettings: currentPresentationSettings
                    }]
                }
            };

            localStorage.setItem('presentationSettings', JSON.stringify(json));

            return ok();
        }

        function getCheckListById() {
            let matchedCheckList = getCurrentCheckList(idFromUrl());
            if (matchedCheckList) {
                const response = {
                    body: {
                        data: [{
                            checkList: matchedCheckList
                        }]
                    }
                };
                return ok(response);
            }

            return ok();
        }

        function upsertCheckList() {

            let newCheckList = request.body;
            let currentCheckList = [];

            // if (checkList && checkList.body && checkList.body.data) {
            //     currentCheckList = checkList.body.data[0].checkList
            //     //Note: this is put in place so we clear local storage after number of settings and just keep last five
            //     if (currentCheckList.length >= 10) {
            //         currentCheckList = currentCheckList.slice(5, 10);
            //     }
            // }

            let matchedCheckList = getCurrentCheckList(newCheckList.id);
            if (matchedCheckList) {
                // Found one setting so update
                const foundIndex = currentCheckList.findIndex((item) => item.id === matchedCheckList.id);
                if (foundIndex >= 0) {
                    currentCheckList[foundIndex] = newCheckList;
                }
            }
            else {
                currentCheckList.push(newCheckList);
            }

            localStorage.removeItem('checkList');

            let json = {
                body: {
                    data: [{
                        checkList: currentCheckList
                    }]
                }
            };

            localStorage.setItem('checkList', JSON.stringify(json));

            return ok();
        }

        function getPresentationSectionCoverImages() {
            return ok(coverImages);
        }

        function getFirmsAdvisors() {
            return ok(firmsAdvisors);
        }

        // helper functions
        // tslint:disable-next-line:no-shadowed-variable
        function ok(body?) {
            return of(new HttpResponse({ status: 200, body }));
        }

        function unauthorized() {
            return throwError({ status: 401, error: { message: 'Unauthorised' } });
        }

        function error(message) {
            return throwError({ error: { message } });
        }

        function isLoggedIn() {
            return headers.get('Authorization') === 'Bearer fake-jwt-token';
        }

        function idFromUrl() {
            const urlParts = url.split('/');
            // eslint-disable-next-line radix
            return parseInt(urlParts[urlParts.length - 1]);
        }

        function getCurrentUserProfile() {
            const userProfile = JSON.parse(localStorage.getItem('userProfile')) || [];
            const response = {
                body: {
                    data: [{
                        userProfile: userProfile
                    }]
                }
            };
            return ok(response);
        }

        function updateCurrentUserProfileFirm() {
            let firm = request.body;
            const userProfile: UserProfile = JSON.parse(localStorage.getItem('userProfile')) || [];
            userProfile.firm = firm;

            localStorage.removeItem('userProfile');
            localStorage.setItem('userProfile', JSON.stringify(userProfile));

            return ok();
        }

        function getFeeStrategyCalculation() {
            return ok(feesStrategy);
        }
    }
}
