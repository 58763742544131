/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Orientation } from './proposal-presentation-checklist.enums';

@Injectable({
    providedIn: 'root'
})
export class ProposalPresentationChecklistService {
    public activeChecklistSectionId: BehaviorSubject<number> = new BehaviorSubject(1);
    public selectedOrientation: BehaviorSubject<Orientation> = new BehaviorSubject(Orientation.Portrait);

    public setActiveChecklistSection(value: number): void {
        this.activeChecklistSectionId.next(value);
    }

    public setActiveOrientation(value: Orientation) {
        this.selectedOrientation.next(value);
    }

}
