/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalService } from '@sei/advisor-client-review-proposal-ux';
import { Logger } from '@sei/common-swp-components-lib-ux';
import moment from 'moment';

@Injectable({ providedIn: 'root' })
export class ExternalKeepAliveService {

    private kassoBaseUrl: string;

    private logger: Logger;
    private global: GlobalService;
    private httpClient: HttpClient;

    constructor(logger: Logger,
        global: GlobalService,
        httpClient: HttpClient) {
        this.logger = logger;
        this.global = global;
        this.httpClient = httpClient;
        const kassoProtocol: string = this.global.configService.environment.carConfig.transportProtocol;
        const kassoServer: string = this.global.configService.environment.authConfig.kassoServer;
        this.kassoBaseUrl = `${kassoProtocol}://${kassoServer}`;
    }

    notifyExternalKeepAlive(): void {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'image/png'
            }),
            withCredentials: true
        };

        const cacheBusterArgStr: string = `?_=${this.cacheBusterArg()}`;
        this.httpClient.get(`${this.kassoBaseUrl}/KASSO/resources/keep-alive/keep-alive.png${cacheBusterArgStr}`, options).subscribe({
            next(value) { /* no next implementation */ },
            error: (): void => this.logger.warn('failed to callback to KASSO keep alive'),
            complete: (): void => this.logger.log('successful keep alive callback')
        });
    }

    notifyExternalLogout(): void {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'image/gif'
            }),
            withCredentials: true
        };
        const cacheBusterArgStr: string = `?_=${this.cacheBusterArg()}`;
        this.httpClient.get(`${this.kassoBaseUrl}/KASSO/resources/external-logout/logout.gif${cacheBusterArgStr}`, options).subscribe({
            next(value) { /* no next implementation */ },
            error: (): void => this.logger.warn('Failed to callback to KASSO resource for logout'),
            complete: (): void => this.logger.log('successful logout callback')
        });
    }

    private cacheBusterArg(): string {
        return moment().unix().toString();
    }
}
