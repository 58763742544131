
/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

export interface TaxRateFormRequest {
    id: string | number;
}

export interface TaxRateFormResponse {
    data: TaxData;
    paging?: string;
    error?: ErrorDetails;
}

export interface ErrorDetails {
    code: string;
    message: string;
    trackingId: string;
    errors: string[];
}

export interface TaxData {
    taxRateType: string;
    taxRatesDto: TaxRateDetails;
}

export interface TaxRateDetails {
    federalIncome: string;
    stateIncome: string;
    federalCapitalGains: string;
    stateCapitalGains: string;
}

export enum TaxRateLiterals {
    FederalIncome = 'federalIncome',
    FederalCapitalGains = 'federalCapitalGains',
    StateIncome = 'stateIncome',
    StateCapitalGains = 'stateCapitalGains'
}
