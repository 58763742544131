/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

export enum ChecklistSectionIds {
    ProposalCoverPage = 1,
    AdvisorContent = 2,
    MarketingContent = 3,
    ProposedScenario = 4,
    SEIStrategyDetails = 5,
    CostOverview = 6,
    IPSCoverPage = 7,
    ReportingLevels = 8,
    ReportOptions = 9,
    Analytics = 10
}

export enum Orientation {
    Portrait = 'portrait',
    Landscape = 'landscape',
    Unbound = 'unbound'
}
