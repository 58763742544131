/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { BaseFactory } from '@CarInterfaces/base-factory';
import { FundingSource, FundingSourceEntity } from '@CarInterfaces/funding';
import { TransferAssets } from '@CarInterfaces/transfer';
import { FundingSourceTypes } from '@CarModels/enums';
import {
    FundingSourceAch,
    FundingSourceCheck,
    FundingSourceDetailBase,
    FundingSourceTransfer,
    FundingSourceWire
} from '@CarModels/funding-source-detail';

@Injectable({
    providedIn: 'root'
})
export class FundingSourceFactory
    implements BaseFactory<FundingSourceDetailBase> {
    // the end point is not supporting a common Funding Source object. It behaves in error if you provide to many properties
    public createObject(
        type?: FundingSource,
        typeId?: number,
        fundingSourceEntity?: FundingSourceEntity
    ): FundingSourceDetailBase {
        let fundingSource: FundingSourceDetailBase;

        if (!fundingSourceEntity) {
            if (!type && typeId > 0) {
                type = this.createFundingSource(typeId);
            }

            if (!type) {
                type = this.createFundingSource(FundingSourceTypes.Check);
            }

            fundingSourceEntity = {
                amount: 0,
                fundingSource: type,
                transferFromAccountNumber: ''
            };
        }

        if (fundingSourceEntity && fundingSourceEntity.transferAssets) {
            fundingSourceEntity.transferAssets = fundingSourceEntity.transferAssets.map(
                (asset: TransferAssets) => {
                    return { ...asset };
                }
            );
        }

        switch (fundingSourceEntity.fundingSource.fundingSourceId) {
            case FundingSourceTypes.Check:
                fundingSource = new FundingSourceCheck(fundingSourceEntity);
                break;
            case FundingSourceTypes.Wire:
                fundingSource = new FundingSourceWire(fundingSourceEntity);
                break;
            case FundingSourceTypes.Ach:
                fundingSource = new FundingSourceAch(fundingSourceEntity);
                break;
            case FundingSourceTypes.Transfer:
                fundingSource = new FundingSourceTransfer(fundingSourceEntity);
                break;
            default:
                throw new Error('funding source type is not implemented');
        }
        return fundingSource;
    }

    private createFundingSource(typeId: number): FundingSource {
        switch (typeId) {
            case FundingSourceTypes.Ach:
                return {
                    fundingSourceId: FundingSourceTypes.Ach,
                    fundingSourceDescription: 'ACH'
                };
            case FundingSourceTypes.Wire:
                return {
                    fundingSourceId: FundingSourceTypes.Wire,
                    fundingSourceDescription: 'Wire'
                };
            case FundingSourceTypes.Transfer:
                return {
                    fundingSourceId: FundingSourceTypes.Transfer,
                    fundingSourceDescription: 'Transfer'
                };
            default:
                return {
                    fundingSourceId: FundingSourceTypes.Check,
                    fundingSourceDescription: 'Check'
                };
        }
    }
}
