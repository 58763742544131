/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DisplayNullOptions, EditPercentDirective, EditPercentOptions } from '@sei/common-components-lib-ux';
import * as _ from 'lodash';
import { TaxRateDetails, TaxRateLiterals } from './model/tax-rate.model';

@Component({
    standalone: true,
    selector: 'sei-car-proposal-tax-rate-form',
    templateUrl: './tax-rate.component.html',
    imports: [EditPercentDirective]
})

export class ProposalTaxRateComponent implements OnInit {

    @Input() public set federalIncome(value: string) {
        if (this._federalIncome !== value) {
            this._federalIncome = _.cloneDeep(value);
            this.checkValue();
        }
    }
    public get federalIncome(): string {
        return this._federalIncome;
    }
    @Input() public set federalCapitalGains(value: string) {
        if (this._federalCapitalGains !== value) {
            this._federalCapitalGains = _.cloneDeep(value);
            this.checkValue();
        }

    }
    public get federalCapitalGains(): string {
        return this._federalCapitalGains;
    }
    @Input() public set stateIncome(value: string) {
        if (this._stateIncome !== value) {
            this._stateIncome = _.cloneDeep(value);
            this.checkValue();
        }
    }
    public get stateIncome(): string {
        return this._stateIncome;
    }
    @Input() public set stateCapitalGains(value: string) {
        if (this._stateCapitalGains !== value) {
            this._stateCapitalGains = _.cloneDeep(value);
            this.checkValue();
        }
    }
    public get stateCapitalGains(): string {
        return this._stateCapitalGains;
    }
    @Input() public isDisabled: boolean;
    @Output() public istaxRateValidEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public taxRateDataEvent: EventEmitter<TaxRateDetails> = new EventEmitter<TaxRateDetails>();
    private _federalIncome: string;
    private _stateIncome: string;
    private _stateCapitalGains: string;
    private _federalCapitalGains: string;

    public taxRateData: TaxRateDetails;
    public percentOptions: EditPercentOptions = {
        maxDecimalPlaces: 2,
        displayMinDecimals: 2,
        displayPercentSymbol: true,
        minValueAllowed: '0',
        nullDisplay: DisplayNullOptions.BLANK
    };


    ngOnInit(): void {
        this.checkValue();
        this.validateTaxFields();
    }

    public checkValue(val?: string, field?: string): void {
        if (field === TaxRateLiterals.FederalIncome) {
            this.federalIncome = val;
        } else if (field === TaxRateLiterals.FederalCapitalGains) {
            this.federalCapitalGains = val;
        } else if (field === TaxRateLiterals.StateCapitalGains) {
            this.stateCapitalGains = val;
        } else if (field === TaxRateLiterals.StateIncome) {
            this.stateIncome = val;
        }
        this.taxRateData = {
            federalIncome: this.federalIncome ? this.federalIncome : '0:00',
            federalCapitalGains: this.federalCapitalGains ? this.federalCapitalGains : '0.00',
            stateIncome: this.stateIncome ? this.stateIncome : '0.00',
            stateCapitalGains: this.stateCapitalGains ? this.stateCapitalGains : '0.00'
        };
        this.taxRateDataEvent.emit(this.taxRateData);
        this.validateTaxFields();
    }

    public validateTaxFields(): void {
        if (this.federalIncome && this.federalCapitalGains && this.federalIncome !== '0.00' && this.federalCapitalGains !== '0.00') {
            this.istaxRateValidEvent.emit(true);
        } else {
            this.istaxRateValidEvent.emit(false);
        }
    }
}
