/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Global, HttpUtil } from '@sei/common-swp-components-lib-ux';
import { SaveAccountDetails } from '@sei/ias-applications-lib-ux';
import { Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { CurrentAccountDetails } from '../model/proposal';
import { GlobalService } from './system/global.service';

@Injectable({
    providedIn: 'root'
})

export class AccountChecklistService {

    constructor(private httpUtil: HttpUtil, private global: Global, protected carGlobal: GlobalService, private http: HttpClient) { }

    public submitCurrentAccountDetails(params: SaveAccountDetails): Observable<SaveAccountDetails> {
        if (this.global.isMockDataMode()) {
            const mockURL: string = 'assets/mock-data/current-account.json';
            return this.httpUtil.doMockPostStronglyTyped<SaveAccountDetails>(mockURL, 1000);
        } else {
            const apiUrl: string = this.carGlobal.configService.routeFormatter(
                this.carGlobal.configService.getEndPoint('saveAccountDetails'),
                []
            );
            return this.http.post<SaveAccountDetails>(apiUrl, params).pipe(
                map((response: SaveAccountDetails): SaveAccountDetails => {
                    return response;
                })
            );
        }
    }

    getCurrentAccount(currentAccountId: number): Observable<SaveAccountDetails> {
        if (this.global.isMockDataMode()) {
            const mockURL: string = 'assets/mock-data/current-account.json';
            return this.httpUtil.doMockPostStronglyTyped<SaveAccountDetails>(mockURL, 0);
        } else {
            const apiUrl: string = this.carGlobal.configService.routeFormatter(
                this.carGlobal.configService.getEndPoint('currentAccount'),
                [{
                    key: 'currentAccountId',
                    value: currentAccountId
                }]
            );
            return this.http.get<SaveAccountDetails>(apiUrl).pipe(
                share(),
                map((response: SaveAccountDetails): SaveAccountDetails => {
                    return response;
                })
            );
        }
    }

    getCurrentAccountDetails(proposalId: number): Observable<CurrentAccountDetails> {
        if (this.global.isMockDataMode()) {
            const mockURL: string = 'assets/mock-data/current-account-details.json';
            return this.httpUtil.doMockPostStronglyTyped<CurrentAccountDetails>(mockURL, 0);
        } else {
            const apiUrl: string = this.carGlobal.configService.routeFormatter(
                this.carGlobal.configService.getEndPoint('proposal'),
                [{
                    key: 'proposalId',
                    value: proposalId
                }]
            );
            return this.http.get<CurrentAccountDetails>(apiUrl).pipe(
                share(),
                map((response: CurrentAccountDetails): CurrentAccountDetails => {
                    return response;
                })
            );
        }
    }

    deleteCurrentAccount(currentAccountId: number): Observable<SaveAccountDetails> {
        const apiUrl: string = this.carGlobal.configService.routeFormatter(
            this.carGlobal.configService.getEndPoint('currentAccount'),
            [{
                key: 'currentAccountId',
                value: currentAccountId
            }]
        );
        return this.http.delete(apiUrl);
    }

}
