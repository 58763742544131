/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { WipSection } from '@CarInterfaces/wip-section';
import { WipSectionSettings } from '@CarModels/account-type-options';
import { WipChangeState, WipOptionalSectionType, WipSections } from '@CarServices/wip/wip-enums';

export const wipSectionArrayMock: WipSection[] = [
    {
        // Because of unit testing, mapping to id here
        id: WipSections.AccountOverview,
        sectionId: WipSections.AccountOverview,
        name: 'Account Overview',
        sectionDescription: 'Account Type, Advisor and Investment Program',
        completed: true,
        required: true,
        changeState: WipChangeState.Saved,
        route: 'EditAccount'
    },
    {
        id: WipSections.AccountOwner,
        sectionId: WipSections.AccountOwner,
        name: 'Primary and Joint Account Owner',
        sectionDescription: 'Identify information about the individuals owning the account',
        completed: false,
        required: true,
        changeState: WipChangeState.Incomplete,
        route: 'AccountOwners'
    },
    {
        id: WipSections.RelatedParties,
        sectionId: WipSections.RelatedParties,
        name: 'Required Related Parties',
        sectionDescription: 'Provide information about the individual acting on behalf of the account owner.',
        completed: false,
        changeState: WipChangeState.Incomplete,
        route: 'RelatedParties'
    },
    {
        id: WipSections.AccountDetails,
        sectionId: WipSections.AccountDetails,
        name: 'Account Details',
        sectionDescription: 'Dividend Payments, Pay To, Standing Instructions, Statements, Fees, etc.',
        completed: false,
        changeState: WipChangeState.Incomplete,
        route: 'AccountDetails'
    },
    {
        id: WipSections.StrategySelection,
        sectionId: WipSections.StrategySelection,
        name: 'Strategy Selection',
        sectionDescription: 'Select the Investment Strategy for this account',
        completed: false,
        changeState: WipChangeState.Incomplete,
        route: 'StrategySelection'
    },
    {
        id: WipSections.Funding,
        sectionId: WipSections.Funding,
        name: 'Funding',
        sectionDescription: 'Specify sources and directions',
        completed: false,
        changeState: WipChangeState.Incomplete,
        sections: [
            {
                id: WipSections.FundingSources,
                sectionId: WipSections.FundingSources,
                name: 'Sources',
                sectionDescription: 'Transfers, Check, Wire and One-Time or Recurring ACH',
                completed: false,
                changeState: WipChangeState.Incomplete,
                route: 'Funding/Sources'
            },
            {
                id: WipSections.FundingDirection,
                sectionId: WipSections.FundingDirection,
                name: 'Directions',
                sectionDescription: 'Allocate to the Strategy, DCA or Holding Money Market',
                disabled: true,
                completed: false,
                changeState: WipChangeState.Incomplete,
                route: 'Funding/Direction'
            }
        ]
    },
    {
        id: WipSections.DeathBeneficiary,
        sectionId: WipSections.DeathBeneficiary,
        name: 'Transfer on Death Beneficiary',
        sectionDescription: 'Identify Primary and Contingent Beneficiaries',
        completed: false,
        changeState: WipChangeState.Incomplete,
        route: 'Beneficiaries'
    },
    {
        id: WipSections.RiskTolerance,
        sectionId: WipSections.RiskTolerance,
        name: 'Risk Tolerance',
        sectionDescription: 'Risk Tolerance Questionnaire & Suitability Questions',
        completed: false,
        changeState: WipChangeState.Incomplete,
        route: 'RiskTolerance'
    }
];

export const wipOptionalSectionArrayMock: WipSection[] = [
    {
        id: WipSections.PowerAttorney,
        sectionId: WipSections.PowerAttorney,
        name: 'Power of Attorney (POA)',
        description: 'Optionally identify Power(s) of Attorney for Account Owners',
        sectionDescription: 'Optionally identify Power(s) of Attorney for Account Owners',
        completed: false,
        required: false,
        isOptional: true,
        changeState: WipChangeState.Incomplete,
        route: 'PowerOfAttorney',
        optionalType: WipOptionalSectionType.AdditionalInstruction
    },
    {
        id: WipSections.PurchaseOutSideStrategy,
        sectionId: WipSections.PurchaseOutSideStrategy,
        name: 'Purchases Outside Strategy',
        description: 'Purchases Outside Strategy',
        sectionDescription: 'Purchases Outside Strategy',
        completed: false,
        required: false,
        isOptional: true,
        changeState: WipChangeState.Incomplete,
        route: 'OutsidePurchases',
        optionalType: WipOptionalSectionType.AdditionalInstruction
    },
    {
        id: WipSections.StrategyFundingPriority,
        sectionId: WipSections.StrategyFundingPriority,
        name: 'Strategy Funding Priority',
        description: 'Strategy Funding Priority',
        sectionDescription: 'Strategy Funding Priority',
        completed: false,
        required: false,
        isOptional: true,
        changeState: WipChangeState.Incomplete,
        route: 'FundingPriorities',
        optionalType: WipOptionalSectionType.AdditionalInstruction
    },
    {
        id: WipSections.Goals,
        sectionId: WipSections.Goals,
        name: 'Goals',
        description: 'Goals: Financial targets and timeframes',
        sectionDescription: 'Goals: Financial targets and timeframes',
        completed: false,
        required: false,
        isOptional: true,
        changeState: WipChangeState.Incomplete,
        route: 'Goals',
        optionalType: WipOptionalSectionType.AdditionalInstruction
    },
    {
        id: WipSections.SeiScheduleDistribution,
        sectionId: WipSections.SeiScheduleDistribution,
        name: 'Scheduled Distributions',
        description: 'Scheduled Distributions',
        sectionDescription: 'Scheduled Distributions',
        completed: false,
        required: false,
        isOptional: true,
        changeState: WipChangeState.Incomplete,
        route: 'ScheduledDistribution',
        optionalType: WipOptionalSectionType.SeiService
    },
    {
        id: WipSections.SeiCashAccessAccount,
        sectionId: WipSections.SeiCashAccessAccount,
        name: 'Cash Access Account (CAA)',
        description: 'Cash Access Account: Defines funds in the account acces may be accessed',
        sectionDescription: 'Cash Access Account: Defines funds in the account acces may be accessed',
        completed: false,
        required: false,
        isOptional: true,
        changeState: WipChangeState.Incomplete,
        route: '',
        optionalType: WipOptionalSectionType.SeiService
    }
];

export const wipSectionOrderArrayMock: WipSectionSettings[] = [
    {
        sectionId: WipSections.AccountOverview,
        sortOrder: 1
    },
    {
        sectionId: WipSections.AccountOwner,
        sortOrder: 2
    },
    {
        sectionId: WipSections.RelatedParties,
        sortOrder: 3
    },
    {
        sectionId: WipSections.RiskTolerance,
        sortOrder: 5
    },
    {
        sectionId: WipSections.StrategySelection,
        sortOrder: 6
    },
    {
        sectionId: WipSections.Goals,
        sortOrder: 7
    },
    {
        sectionId: WipSections.PurchaseOutSideStrategy,
        sortOrder: 8
    },
    {
        sectionId: WipSections.Funding,
        sortOrder: 9
    },
    {
        sectionId: WipSections.StrategyFundingPriority,
        sortOrder: 10
    },
    {
        sectionId: WipSections.AccountDetails,
        sortOrder: 11
    },
    {
        sectionId: WipSections.DeathBeneficiary,
        sortOrder: 12
    },
    {
        sectionId: WipSections.SeiScheduleDistribution,
        sortOrder: 13
    },
    {
        sectionId: WipSections.PowerAttorney,
        sortOrder: 14
    },
    {
        sectionId: WipSections.SeiCashAccessAccount,
        sortOrder: 15
    },
    {
        sectionId: WipSections.FundingSources,
        sortOrder: 1
    },
    {
        sectionId: WipSections.FundingDirection,
        sortOrder: 2
    }
];
