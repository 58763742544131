/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { Goal, GoalDetail } from '../proposal';
import { BaseFactoryAbstract } from './base-factory-abstract';

@Injectable()
export class GoalFactory extends BaseFactoryAbstract<Goal> {

    constructor() {
        super();
    }

    public createGoal(id: number, name?: string): GoalDetail {
        return {
            id: id || undefined,
            name: name || ''
        };
    }

    public createGoalDetail(goalId: number): GoalDetail {
        const today = new Date();
        return {
            id: goalId,
            name: '',
            goalType: {
                id: undefined,
                description: ''
            },
            priority: {
                id: undefined,
                description: ''
            },
            startDate: today,
            endDate: today,
            endValue: 0,
            frequency: {
                id: undefined,
                description: ''
            },
            isValid: false
        };
    }

}
