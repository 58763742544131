<div class="row account-ribbon">
    <div class="col-xs-12">
        <label for="scheduleList">Existing Schedule</label>
        <select id="scheduleList" class="form-control dropdown-webkit-appearance" name="scheduleList"
            (change)="scheduleDropdownSelected($event)" [(ngModel)]="advisorFee.feeScheduleId">
            <option *ngFor="let schedule of scenarioFeeSchedules" [value]="schedule.scheduleId">
                {{schedule.scheduleName}}</option>
        </select>
    </div>
</div>
<div [hidden]="(scheduleSelected < 0 && advisorFee.feeType !== feeType.FirmFeeSchedule)">
    <!-- For M1 this is not needed. -->
    <!-- <div class="row account-ribbon ">
        <div class="col-xs-12">
            <label for="scheduleName">Firm Fee Schedule Name</label>
            <input type="text"
                   class="form-control"
                   id="scheduleName"
                   name="scheduleName"
                   ngDefaultControl
                   [(ngModel)]="advisorFee.feeScheduleName" />
        </div>
    </div> -->
    <div class="account-ribbon tier-section-margin">
        <div class="text-left">
            <p>Fee Group Discount</p>
        </div>
        <div class="text-right">
            <sei-car-toggle-sei-wizard-nav (ngModelChange)="onToggleDiscountGroup($event)"
                [(ngModel)]="feeGroupDiscount">
            </sei-car-toggle-sei-wizard-nav>
        </div>
    </div>

    <div class="account-ribbon tier-section-margin">
        <div class="text-left">
            <p>Schedule Settings</p>
        </div>
        <div class="text-right">
            <car-toggle-button [toggleOptions]="toggleOptions"
                [selectedValue]="scheduleSettings ? toggleOptions[0].data:toggleOptions[1].data"
                (onChange)="onToggleScheduleSettings($event)">
            </car-toggle-button>
        </div>
    </div>

    <sei-car-fee-schedule-tier [feeSchedules]="feeSchedules" [feeGroupId]="feeGroup?.id"
        [chargeInputView]="scheduleSettings" [feeScheduleType]="feeScheduleType"
        (onFeeScheduleTierChange)="onFeeScheduleTiersChange($event)"
        (onFeeScheduleToggleChange)="onToggleFeeSchedule($event)">

    </sei-car-fee-schedule-tier>

    <div *ngIf="!hiddenControl" class="account-ribbon section-margin">
        <div class="flex-right">
            <p>Apply Discount </p>
        </div>
        <div class="flex-left">
            <input *ngIf="scheduleSettings" type="text" class="percentage-basis-point-input"
                [(ngModel)]="applyDiscountPercent" min="0" max="3" (change)="onApplyDiscountChange()"
                ngDefaultControl />
            <input *ngIf="!scheduleSettings" type="number" class="percentage-basis-point-input" min="0" max="300"
                [(ngModel)]="applyDiscountBps" (change)="onApplyDiscountBpsChange()" ngDefaultControl />
        </div>
        <div class="flex-left">
            <sei-car-toggle-sei-wizard-nav [(ngModel)]="applyDiscount">
            </sei-car-toggle-sei-wizard-nav>
        </div>
    </div>
    <div *ngIf="!hiddenControl" class="account-ribbon section-margin account-ribbon-border">
        <div class="flex-left">
            <p>Exclude Fee on Cash</p>
        </div>
        <div class="flex-left">
            <sei-car-toggle-sei-wizard-nav [(ngModel)]="advisorFee.excludeFeeOnCash">
            </sei-car-toggle-sei-wizard-nav>
        </div>
    </div>

    <div class="account-ribbon section-margin ">
        <div class="flex-left">
            <p>Save as my default</p>
        </div>
        <div class="flex-left">
            <sei-car-toggle-sei-wizard-nav [(ngModel)]="advisorFee.isDefault">
            </sei-car-toggle-sei-wizard-nav>
        </div>
    </div>
</div>
<sei-car-fee-schedule-ribbon (onCancelEvent)="onCancelEventRibbon()" (onDoneFeeRibbonEvent)="onDoneFeeRibbon()">
</sei-car-fee-schedule-ribbon>
