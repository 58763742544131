<div class="section-margin fee-tier">
    <div class="account-ribbon section-margin">
        <div class="text-left">
            <p class="subhead">Fee Schedule</p>
        </div>
        <div class="text-right">
            <car-toggle-button [toggleOptions]="toggleTierOptions" [selectedValue]="feeScheduleType"
                [disabled]="'true'">
            </car-toggle-button>
        </div>
    </div>

    <sei-car-fee-schedule-tier-ribbon *ngFor="let tier of feeSchedules; let i = index" [feeGroupId]="feeGroupId"
        [tier]="tier" [index]="i" [chargeInputView]="chargeInputView"
        (onFeeTierChange)="updateFeeSchedulesOnchange($event)" (onFeeTierRemove)="deleteTier($event)">

    </sei-car-fee-schedule-tier-ribbon>

    <div class="row section-margin" *ngIf="false">
        <div class="col-xs-12">
            <button type="button" disabled class="btn add-content unselectable button-gap"
                (click)="onAddTierButtonClick()">
                Add Tier
            </button>
        </div>
    </div>
</div>
