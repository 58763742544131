/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { FeatureFlagService } from '@CarServices/feature-flag/feature-flag.service';
import { ImplementModalService } from '@CarServices/implement-modal/implement-modal.service';
import { ReactivateModalService } from '@CarServices/reactivate-modal/reactivate-modal.service';
import { SpinnerService } from '@CarServices/system/spinner.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { Observable, combineLatest, forkJoin, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ProposalView } from '../model/dashboard';
import { DashboardTabOptions, ProposalStatus } from '../model/enums';
import { Advisor, MinDataProposal, Proposal, ProposalReactivateResponse, ProposalStatusResponse } from '../model/proposal';
import { CarElasticSearchService } from '../service/car-elastic-search.service';
import { PresentationSettingService } from '../service/presentation-setting.service';
import { ProposalService } from '../service/proposal.service';
import { GenericErrorService } from '../service/system/generic-error.service';
import { UserProfileService } from '../service/user-profile.service';

@Component({
    selector: 'sei-car-dashboard-table',
    templateUrl: './dashboard-table.component.html'
})
export class DashboardTableComponent extends ParentComponentSubscriptionManager implements OnInit {
    @Input()
    public firmId: number;
    @Input()
    public onBehalfOfUserId: number;
    @Input()
    public advisorIds: number[];
    @Input()
    public isReadUser: boolean;
    @Output()
    public totalAccountValue = new EventEmitter<number>();
    @Output()
    public totalProposalCount = new EventEmitter<number>();
    @Output()
    public implementProposal = new EventEmitter<ProposalView>();

    public filteredProposalsStream: Observable<ProposalView[]>;
    public searchFilter: UntypedFormControl;
    public filterStream: Observable<string>;

    // sorting logic
    public key = 'statusDate'; // sort default by name
    public reverse = true;
    public searchProposal: UntypedFormGroup;

    public activeTab: boolean = true;
    public selectedAdvisor: string;
    public advisors: Array<{ advisorName: string; entityId: number }>;
    public proposalStatus: typeof ProposalStatus = ProposalStatus;
    public minDataProposal: MinDataProposal;
    public proposalAccountStatuses: ProposalStatusResponse;
    public showReactivateModal: boolean;

    constructor(
        private readonly proposalService: ProposalService,
        private readonly genericErrorService: GenericErrorService,
        private readonly userProfileService: UserProfileService,
        private readonly carElasticSearchService: CarElasticSearchService,
        private readonly presentationSettingService: PresentationSettingService,
        private readonly implementModalService: ImplementModalService,
        private readonly featureFlagService: FeatureFlagService,
        private readonly reactivateModalService: ReactivateModalService,
        private readonly spinnerService: SpinnerService
    ) {
        super('DashboardTableComponent');
    }

    public ngOnInit(): void {
        this.searchFilter = new UntypedFormControl('');

        this.filterStream = this.searchFilter.valueChanges.pipe(startWith(''));

        if (this.firmId) {
            this.getProposalData();
        }

        this.subscriptions.push(this.implementModalService.getRefreshDashboardBehaviorSubject().subscribe((refresh: boolean) => {
            if (refresh) {
                this.getProposalData();
            }
        }));
    }

    private getProposalData(): void {
        this.tabClicked(DashboardTabOptions.Active);
    }

    private loadData(): void {
        this.spinnerService.start();
        this.advisors = [];

        const dashboardSubscription = this.proposalService
            .getDashboardProposals(this.firmId, this.onBehalfOfUserId, this.advisorIds, this.activeTab)
            .subscribe(
                () => {
                    const proposalStream = this.proposalService.dashBoardView;
                    this.filteredProposalsStream = combineLatest(
                        [proposalStream,
                            this.filterStream]
                    ).pipe(
                        map(([proposals, filterString]) => {
                            let filteredProposal = proposals.filter(
                                (proposal: ProposalView) =>
                                    proposal.name
                                        .toLowerCase()
                                        .indexOf(filterString.toLowerCase()) !==
                                    -1
                            );

                            if (
                                this.selectedAdvisor &&
                                Number(this.selectedAdvisor) !== -1
                            ) {
                                filteredProposal = filteredProposal.filter(
                                    (proposal: ProposalView) => {
                                        return (
                                            proposal.advisorName.indexOf(
                                                this.selectedAdvisor
                                            ) !== -1
                                        );
                                    }
                                );
                            }
                            this.emitDataToDashboard(filteredProposal);
                            filteredProposal.forEach((proposal) => {
                                if (
                                    proposal.advisors &&
                                    proposal.advisors.length > 0
                                ) {
                                    proposal.advisors.forEach(
                                        (advisor: Advisor) => {
                                            const filterAdvisor = {
                                                advisorName: advisor.name,
                                                entityId: advisor.id
                                            };
                                            if (
                                                !this.advisors.some(
                                                    (filterAdvisorPushed) =>
                                                        filterAdvisorPushed.advisorName ===
                                                        filterAdvisor.advisorName
                                                )
                                            ) {
                                                this.advisors.push(
                                                    filterAdvisor
                                                );
                                            }
                                        }
                                    );
                                }
                            });
                            return filteredProposal;
                        })
                    );
                },
                ((error) => {
                    this.genericErrorService.setGenericError({ code: '500', description: error });
                    this.spinnerService.stop();
                }),
                () => {
                    this.spinnerService.stop();
                }
            );

        this.subscriptions.push(dashboardSubscription);
    }

    public sortList(key): void {
        this.key = key;
        this.reverse = !this.reverse;
    }

    public tabClicked(tabName: string): void {
        if (tabName === DashboardTabOptions.Active) {
            this.activeTab = true;
        } else if (tabName === DashboardTabOptions.Archived) {
            this.activeTab = false;
        }
        this.loadData();
    }

    public deleteProposal(proposalId: number): void {
        if (!this.isReadUser) {
            const proposal = this.proposalService
                .getProposalById(proposalId)
                .pipe(
                    map((proposalResponse: Proposal) => {
                        if (this.userProfileService.verifyAccess(proposalResponse)) {
                            return of(true);
                        } else {
                            this.genericErrorService.setGenericError({
                                code: '403',
                                description: `Current user does not have entitlements to the proposal: ${proposalId}`
                            });
                            return of(false);
                        }
                    })
                );

            if (confirm('Are you sure you want to delete this proposal?')) {
                this.subscriptions.push(
                    proposal
                        .pipe(
                            map((userConfirmed) => {
                                if (userConfirmed) {
                                    this.proposalService
                                        .deleteProposal(proposalId)
                                        .subscribe(
                                            () => {
                                                this.loadData();
                                            },
                                            (error) =>
                                                this.genericErrorService.setGenericError(
                                                    { code: '', description: error }
                                                )
                                        );
                                }
                            })
                        )
                        .subscribe()
                );
            }
        }
    }

    public onImplementProposal(proposal: ProposalView): void {
        if (proposal.complete && !this.isReadUser) {
            this.implementProposal.emit(proposal);
        }
    }

    public archiveProposal(proposalId: number): void {
        if (!this.isReadUser) {
            if (confirm('Are you sure you want to archive this proposal?')) {
                this.proposalService.archiveProposal(proposalId).subscribe(
                    () => {
                        this.loadData();
                    },
                    (error) => this.genericErrorService.setGenericError(error)
                );
            }
        }
    }

    public reactivateProposal(proposal: ProposalView): void {
        if (!this.isReadUser && !this.isReactivateDisabled(proposal)) {
            if (!this.featureFlagService.isDAOIntegrationEnabled()) {
                if (confirm('Are you sure you want to reactivate this proposal?')) {
                    this.proposalService.reactivateProposal(proposal.id).subscribe(
                        () => {
                            this.loadData();
                        },
                        (error) =>
                            this.genericErrorService.setGenericError({
                                code: '',
                                description: error
                            })
                    );
                }
            } else {
                this.resetReactivateModalState();
                this.spinnerService.start();
                const minDataProposalSubscription: Observable<MinDataProposal> = this.proposalService.getMinDataProposalById(proposal.id);
                const retrievePendingAccountStatusesSubscription: Observable<ProposalStatusResponse> =
                    this.reactivateModalService.retrieveProposalPendingAccountStatuses(proposal.id);
                this.subscriptions.push(
                    forkJoin({
                        statuses: retrievePendingAccountStatusesSubscription,
                        minDataProposal: minDataProposalSubscription
                    }).subscribe((response: { statuses: ProposalStatusResponse; minDataProposal: MinDataProposal }) => {
                        this.minDataProposal = response.minDataProposal;
                        this.proposalAccountStatuses = response.statuses;
                        this.showReactivateModal = true;
                        this.spinnerService.stop();
                    }, (error) => {
                        this.spinnerService.stop();
                    })
                );
            }

        }
    }

    public submitReactivation(proposalId: number): void {
        this.spinnerService.start();
        this.reactivateModalService.deleteProposalPendingAccountsAndReactivateProposal(proposalId)
            .subscribe((response: ProposalReactivateResponse) => {
                this.spinnerService.stop();
                if (response.success) {
                    this.showReactivateModal = false;
                    this.resetReactivateModalState();
                    this.tabClicked(DashboardTabOptions.Archived);
                } else {
                    this.proposalAccountStatuses.proposalAccountStatuses = response.proposalAccounts;
                    this.proposalAccountStatuses.eligibleForReactivation = response?.reactivationErrors?.length > 0;
                }
            }, (error) => {
                this.spinnerService.stop();
            });
    }

    public onReactivateModalClosed(): void {
        this.resetReactivateModalState();
        this.showReactivateModal = false;
    }

    public isReactivateDisabled(proposal: ProposalView): boolean {
        return proposal?.archivedStatus === null ||
            proposal?.archivedStatus?.description === this.proposalStatus?.Implemented ||
            this.isReadUser;
    }

    public advisorDropdownSelected(event): void {
        if (event) {
            this.selectedAdvisor = event.target.value;
            this.loadData();
        }
    }

    public openDownloadedDocument(proposal: ProposalView): void {
        if (proposal?.status?.description === this.proposalStatus.Generated ||
            proposal?.archivedStatus?.description === this.proposalStatus.Generated) {
            this.spinnerService.start();
            this.presentationSettingService
                .getGeneratedDocument(proposal.id, proposal.name)
                // eslint-disable-next-line  @typescript-eslint/no-empty-function
                .subscribe(() => { },
                    (err) => {
                        this.genericErrorService.setGenericError({ code: '', description: `${err.message}` });
                        this.spinnerService.stop();
                    },
                    () => {
                        this.spinnerService.stop();
                    });
        }
    }

    private emitDataToDashboard(proposals: ProposalView[]): void {
        const initialValue: number = 0;
        const totalAccountValue: number = proposals.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.targetFunding;
        }, initialValue);

        if (this.activeTab) {
            this.totalProposalCount.emit(proposals.length);
            this.totalAccountValue.emit(totalAccountValue);
        }
    }

    private resetReactivateModalState(): void {
        this.minDataProposal = undefined;
        this.proposalAccountStatuses = undefined;
    }
}
