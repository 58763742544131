/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { TooltipConstants } from '@CarModels/constants';
import { PropertyService } from '@CarServices/system/property.service';
import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    DataSharingService,
    GenericErrorService,
    PdfStatement,
    PresentationChecklistComponent,
    PresentationSettingService,
    Proposal, ProposalSection,
    ProposalSectionLabel,
    ProposalService,
    ProposalStatement,
    ProposalStatementType,
    ProposalStatus,
    Scenario,
    UserProfileService
} from '@sei/advisor-client-review-proposal-ux';
import { BannerMessageType, ButtonClass, LoadingOverlayStyle, ModalWindowComponent } from '@sei/common-components-lib-ux';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import * as _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { finalize, mergeMap, share } from 'rxjs/operators';
import { AsyncOperationType } from '../../../../../../advisor-client-review-proposal-ux/src/lib/model/enums';
import { AsyncAddUpdateParams, DeleteSectionRequestParams } from '../../../../../../advisor-client-review-proposal-ux/src/lib/model/presentation';

@Component({
    selector: 'car-checklist',
    templateUrl: './checklist.component.html',
    providers: [DatePipe]
})
export class ChecklistComponent extends ParentComponentSubscriptionManager implements OnInit {

    constructor(
        private presentationSettingService: PresentationSettingService,
        private genericErrorService: GenericErrorService,
        private dataSharingService: DataSharingService,
        private proposalService: ProposalService,
        private route: ActivatedRoute,
        private router: Router,
        private userProfileService: UserProfileService,
        private readonly propertyService: PropertyService,
        private datePipe: DatePipe,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        super('ChecklistComponent');
    }

    public pdfCheckListStream: Observable<PdfStatement>;
    public pdfCheckList: PdfStatement;
    public pdfCheckListConfirmed: PdfStatement;
    public pdfCheckListDefault: PdfStatement;
    public proposalSection: ProposalSection;
    public proposalId: number;
    public proposalStatement: ProposalStatement;
    public proposalStatementIndex: number;

    public isPDFCreated: boolean = false;
    public isPopoverOpen: boolean = false;
    public isLoading: boolean = false;
    public isSaveDefaultActive: boolean = false;
    public modifiedSections: number[] = [];
    public setModifiedSection: Set<number> = new Set<number>();
    public styleInput: LoadingOverlayStyle = LoadingOverlayStyle.Translucent;
    public isReadUser: boolean = false;

    @ViewChild('deleteModal', { static: false })
    public deleteModal: ModalWindowComponent;

    @ViewChild(PresentationChecklistComponent, { static: false })
    public presentationChecklistComponent: PresentationChecklistComponent;
    private checklist: ProposalStatement;

    public modalTitle: string = 'Delete Advisor Content Document';
    public cancelButtonClass: ButtonClass = ButtonClass.Default;
    public confirmButtonClass: ButtonClass = ButtonClass.Primary;
    public initialTopPosition: string = '140px';

    public infoType: BannerMessageType.info;

    private sectionToDelete: string;
    private proposalGenerationDate: string = this.datePipe.transform(Date.now(), 'longDate').toString();


    public ngOnInit(): void {
        this.isReadUser = this.userProfileService.isReadUser;
        this.proposalId = Number(this.route.snapshot.params.proposalId);

        this.isLoading = true;
        this.pdfCheckListStream = this.presentationSettingService.getCheckListById(this.proposalId).pipe(share(),
            finalize(() => {
                this.isLoading = false;
            }));

        this.setProposedScenarioSectionsBasedOnGoals();
        this.subscriptions.push(this.presentationSettingService.getPresentationCheckListStatus(this.proposalId)
            .subscribe((responseProposalStatus) => {
                this.isPDFCreated = (responseProposalStatus && responseProposalStatus === ProposalStatus.Generated);
                this.subscriptions.push(this.pdfCheckListStream.subscribe((localPdfStatement: PdfStatement) => {
                    this.pdfCheckListDefault = _.cloneDeep(localPdfStatement);

                    if (this.pdfCheckListDefault) {
                        const dataSubscription: Subscription =
                            this.dataSharingService.proposalSectionChanged.subscribe((modifiedSection: ProposalSection) => {
                                if (modifiedSection) {
                                    let buttonEnable: boolean = false;
                                    this.pdfCheckListDefault.proposalStatement.forEach((proposalStatement: ProposalStatement) => {
                                        const defaultSection: ProposalSection =
                                            proposalStatement.sections.find((sectionItem: ProposalSection) =>
                                                sectionItem.id === modifiedSection.id
                                            );
                                        if (defaultSection &&
                                            !this.presentationSettingService.compareSections(modifiedSection, defaultSection)
                                        ) {
                                            buttonEnable = true;
                                            this.setModifiedSection.add(modifiedSection.id);
                                            return;
                                        }
                                    });
                                    if (!buttonEnable) {
                                        this.setModifiedSection.delete(modifiedSection.id);
                                    }
                                    this.isSaveDefaultActive = !(!buttonEnable && this.setModifiedSection.size === 0);
                                }
                            });
                        this.subscriptions.push(dataSubscription);
                    }
                }));

            }));
    }

    public onSavePdfStatement(routerPath: string, pdfCheckList: PdfStatement): void {
        this.onSaveCheckList(pdfCheckList, routerPath);
    }

    public onSaveCheckList(pdfCheckList: PdfStatement, routerPath?: string): void {
        this.isLoading = true;
        this.subscriptions.push(this.presentationSettingService.savePdfStatement(pdfCheckList)
            .pipe(
                finalize(() => {
                    this.isLoading = false;
                })
            )
            .subscribe(() => {
                if (!routerPath) {
                    this.router.navigate(['Proposal/Dashboard/']);
                } else {
                    this.router.navigate([routerPath]);
                }
            },
                (err) => {
                    this.isLoading = false;
                    this.genericErrorService.setGenericError({ code: '', description: `${err.message}` });
                    this.scrollToTop();
                },
                () => this.isLoading = false
            ));
    }

    onSaveAdvisorContent(pdfCheckList, isRename?: boolean) {
        this.checklist = this.presentationChecklistComponent.proposalStatement;
        this.isLoading = true;
        // Only send the advisor content section to be updated.
        const advisorContentOnlyChecklist = _.cloneDeep(pdfCheckList);
        const advisorContentIndex: number = advisorContentOnlyChecklist.proposalStatement[0].sections
            .findIndex((section: ProposalSection) => section.name === ProposalSectionLabel.AdvisorContent);
        advisorContentOnlyChecklist.proposalStatement[0].sections =
            [_.cloneDeep(advisorContentOnlyChecklist.proposalStatement[0].sections[advisorContentIndex])];
        if (this.propertyService.exists(() => advisorContentOnlyChecklist.proposalStatement[1])) {
            advisorContentOnlyChecklist.proposalStatement = [advisorContentOnlyChecklist.proposalStatement[0]];
        }
        // Send checklist to PUT service.
        this.subscriptions.push(this.presentationSettingService.savePdfStatement(advisorContentOnlyChecklist)
            .pipe(
                finalize(() => {
                    this.isLoading = false;
                })
            ).subscribe((response) => {
                this.isLoading = false;
                const responseSectionIndex = response.presentationChecklist.data[0].checkList[0].proposalStatement[0].sections
                    .findIndex((proposalSection: ProposalSection) => proposalSection.name === ProposalSectionLabel.AdvisorContent);
                const newAdvisorContentSection =
                    response.presentationChecklist.data[0].checkList[0].proposalStatement[0].sections[responseSectionIndex];
                const savedChecklistIndex = this.checklist.sections
                    .findIndex((section: ProposalSection) => section.name === ProposalSectionLabel.AdvisorContent);
                this.checklist.sections[savedChecklistIndex] = newAdvisorContentSection;
                this.updatePreviewSection(this.checklist.sections[savedChecklistIndex]);
                this.saveSelectionsAsDefaultAfterUpload(pdfCheckList);
                if (!isRename) {
                    const asyncInsertParams: AsyncAddUpdateParams = {
                        proposalId: this.proposalId,
                        documentId: newAdvisorContentSection.sections[0].sectionContent.documentId,
                        subSectionName: encodeURIComponent(newAdvisorContentSection.sections[0].name),
                        operationType: AsyncOperationType.Insert
                    };
                    this.proposalService.asynchronousAddUpdate(asyncInsertParams).subscribe();
                } else {
                    const updatedSectionIndex: number = newAdvisorContentSection.sections.findIndex(
                        (proposalSection: ProposalSection) => proposalSection.name === this.proposalService.getUpdatedSectionName());
                    const asyncUpdateParams: AsyncAddUpdateParams = {
                        proposalId: this.proposalId,
                        documentId: newAdvisorContentSection.sections[updatedSectionIndex].sectionContent.documentId,
                        subSectionName: encodeURIComponent(newAdvisorContentSection.sections[updatedSectionIndex].name),
                        operationType: AsyncOperationType.Update
                    };
                    this.subscriptions.push(this.proposalService.asynchronousAddUpdate(asyncUpdateParams).subscribe());
                }

            },
                (err) => {
                    this.isLoading = false;
                    this.genericErrorService.setGenericError({ code: '', description: `${err.message}` });
                }));
    }

    public onGeneratePresentationClick(pdfCheckList: PdfStatement): void {
        if (!this.isPDFCreated) {
            this.savePresentationPDF(pdfCheckList);
        } else {
            this.isPopoverOpen = true;
            this.pdfCheckListConfirmed = pdfCheckList;
        }
    }

    public updatePreviewSection(proposalSection: ProposalSection): void {
        this.dataSharingService.proposalSectionChanged.next(proposalSection);
    }

    public onConfirmOverrideClick(): void {
        this.savePresentationPDF(this.pdfCheckListConfirmed);
    }

    public onConfirmOverrideCancel(): void {
        this.isPopoverOpen = false;
    }

    public saveSelectionsAsDefault(pdfCheckList: PdfStatement): void {
        this.isSaveDefaultActive = false;
        this.isLoading = true;
        const advisorContentSection = _.cloneDeep(pdfCheckList.proposalStatement[0].sections.find(
            (statementSection: ProposalSection) => statementSection.name === ProposalSectionLabel.AdvisorContent));
        this.subscriptions.push(this.presentationSettingService.saveProposalSectionSortAsync(this.proposalId, advisorContentSection)
            .subscribe());
        this.subscriptions.push(this.presentationSettingService.saveDefaultSelections(pdfCheckList)
            .subscribe(
                (response) => {
                    this.pdfCheckListDefault = _.cloneDeep(pdfCheckList);
                    this.setModifiedSection.clear();

                    this.presentationSettingService.savePdfStatement(pdfCheckList).subscribe(() => {
                        this.isLoading = false;
                        this.ngOnInit();
                    });
                },
                () => {
                    this.isLoading = false;
                }
            ));
    }

    private saveSelectionsAsDefaultAfterUpload(pdfCheckList: PdfStatement): void {
        // Only send Advisor Content Section to save default selections service after upload.
        const advisorContentSection = _.cloneDeep(pdfCheckList.proposalStatement[0].sections.find(
            (statementSection: ProposalSection) => statementSection.name === ProposalSectionLabel.AdvisorContent));
        const advisorContentOnlyPdfChecklist: PdfStatement = _.cloneDeep(pdfCheckList);
        advisorContentOnlyPdfChecklist.proposalStatement = _.cloneDeep([advisorContentOnlyPdfChecklist.proposalStatement[0]]);
        advisorContentOnlyPdfChecklist.proposalStatement[0].sections = _.cloneDeep([advisorContentSection]);
        this.subscriptions.push(this.presentationSettingService.saveDefaultSelections(advisorContentOnlyPdfChecklist)
            .subscribe());
        this.subscriptions.push(this.presentationSettingService.saveProposalSectionSortAsync(this.proposalId, advisorContentSection)
            .subscribe());
    }

    private savePresentationPDF(pdfCheckList: PdfStatement): void {
        this.isLoading = true;
        this.subscriptions.push(this.presentationSettingService.savePdfStatement(pdfCheckList)
            .pipe(
                mergeMap(() => {
                    return this.presentationSettingService
                        .generateDocumentsPresentationChecklist(this.proposalId, this.proposalGenerationDate)
                        .pipe(
                            mergeMap((data) => {
                                return this.presentationSettingService.getPresentationCheckListDocument(this.proposalId, data);
                            })
                        );
                })
            )
            .subscribe(
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                (response) => { },
                (err) => {
                    this.isLoading = false;
                    this.genericErrorService.setGenericError({ code: '', description: `${err.message}` });
                    this.scrollToTop();
                    this.isPopoverOpen = false;
                },
                () => {
                    this.isLoading = false;
                    this.isPDFCreated = true;
                    this.isPopoverOpen = false;
                }
            )
        );
    }

    private scrollToTop() {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    private setProposedScenarioSectionsBasedOnGoals(): void {
        this.subscriptions.push(
            this.proposalService.currentProposal.subscribe((proposal: Proposal) => {
                if (proposal) {
                    const itHasGoals: boolean =
                        proposal.scenarios.some((scenario: Scenario) =>
                            scenario.goals && scenario.goals.length > 0
                        );
                    this.subscriptions.push(this.pdfCheckListStream.subscribe((localPdfStatement: PdfStatement) => {
                        if (localPdfStatement) {
                            const sectionGroup: ProposalSection =
                                this.presentationSettingService
                                    .getGroupPdfStatement(
                                        localPdfStatement,
                                        ProposalStatementType.Proposal,
                                        ProposalSectionLabel.ProposedScenario
                                    );

                            if (sectionGroup) {
                                const sectionsToUpdate: string[] = [
                                    ProposalSectionLabel.BenefitsOfaGoalsDrivenApproach,
                                    ProposalSectionLabel.YourVisionOfFinancialWellness,
                                    ProposalSectionLabel.GoalsBasedSummary
                                ];

                                sectionsToUpdate.forEach((sectionName: string) => {
                                    const currentSection: ProposalSection =
                                        this.presentationSettingService.getSectionFromGroup(sectionGroup, sectionName);
                                    this.presentationSettingService.setValuesToSection(currentSection, !itHasGoals);
                                });

                                let sectionNameToFind: string;
                                let sectionNameToSet: string;

                                if (itHasGoals) {
                                    sectionNameToFind = ProposalSectionLabel.ProposalSummary;
                                    sectionNameToSet = ProposalSectionLabel.NonGoalsBasedSummary;
                                } else {
                                    sectionNameToFind = ProposalSectionLabel.NonGoalsBasedSummary;
                                    sectionNameToSet = ProposalSectionLabel.ProposalSummary;
                                }

                                const section: ProposalSection =
                                    this.presentationSettingService.getSectionFromGroup(sectionGroup, sectionNameToFind);
                                this.presentationSettingService.setValuesToSection(section, false, sectionNameToSet);
                            }
                        }
                    }));
                }
            })
        );
    }

    public cancelModal(): void {
        this.deleteModal.hideModalBox();
        this.sectionToDelete = undefined;
    }

    public confirmModal(): void {
        this.checklist = this.presentationChecklistComponent.proposalStatement;
        const advisorContentSectionIndex = this.checklist.sections
            .findIndex((proposalSection: ProposalSection) => proposalSection.name === ProposalSectionLabel.AdvisorContent);
        const advisorContentSection =
            _.cloneDeep(this.checklist.sections[advisorContentSectionIndex]);
        const sectionToDeleteIndex = advisorContentSection.sections
            .findIndex((section: ProposalSection) => section.name === this.sectionToDelete);
        const nameOfDeletedSection: string = _.cloneDeep(advisorContentSection.sections[sectionToDeleteIndex].name);
        const sectionToDelete: ProposalSection = _.cloneDeep(advisorContentSection.sections[sectionToDeleteIndex]);
        advisorContentSection.sections.splice(sectionToDeleteIndex, 1);
        this.checklist.sections[advisorContentSectionIndex] = _.cloneDeep(advisorContentSection);
        const deleteParams: DeleteSectionRequestParams = {
            proposalId: this.proposalId,
            presentationType: 'Proposal',
            sectionName: ProposalSectionLabel.AdvisorContent,
            subSectionName: nameOfDeletedSection
        };
        this.subscriptions.push(this.proposalService.deleteSection(sectionToDelete, deleteParams).subscribe());
        this.deleteModal.hideModalBox();
        this.sectionToDelete = undefined;
        this.updatePreviewSection(this.checklist.sections[advisorContentSectionIndex]);
        this.presentationChecklistComponent.proposalStatement = Object.assign({}, this.checklist);
    }

    public onDeleteSection(event: string): void {
        this.sectionToDelete = event;
        this.deleteModal.showModalBox();
    }

    public onBrandLogoUpload(imagePath: string, pdfChecklist: PdfStatement) {
        if (imagePath && pdfChecklist) {
            const brandLogoOnlyChecklist: PdfStatement = _.cloneDeep(pdfChecklist);
            // Find Index of Cover Page section
            const firstStatement: ProposalStatement = brandLogoOnlyChecklist.proposalStatement[0];
            const coverPageProposalIndex: number = firstStatement.sections
                .findIndex((section: ProposalSection) => section.name === ProposalSectionLabel.CoverPage);
            // Make Cover Page Section the Only section
            firstStatement.sections =
                [_.cloneDeep(firstStatement.sections[coverPageProposalIndex])];
            // Make Brand Logo the only subsection of Cover Page
            firstStatement.sections[0].sections =
                firstStatement.sections[0].sections.filter(
                    (section: ProposalSection) => section.name === ProposalSectionLabel.BrandLogo);
            // Set Property Value to Image path for Brand Logo section.
            firstStatement.sections[0].sections[0].inputProperty.value = imagePath;
            firstStatement.sections[0].sections[0].hidden = false;

            // Do the same for IPS if Both Proposal and IPS are included in the checklist
            if (this.propertyService.exists(() => brandLogoOnlyChecklist.proposalStatement[1])) {
                const secondStatement: ProposalStatement = brandLogoOnlyChecklist.proposalStatement[1];
                const coverPageIPSIndex: number = secondStatement.sections
                    .findIndex((section: ProposalSection) => section.name === ProposalSectionLabel.CoverPage);
                secondStatement.sections =
                    [_.cloneDeep(secondStatement.sections[coverPageIPSIndex])];
                secondStatement.sections[0].sections =
                    secondStatement.sections[0].sections.filter(
                        (section: ProposalSection) => section.name === ProposalSectionLabel.BrandLogo);
                secondStatement.sections[0].sections[0].inputProperty.value = imagePath;
                secondStatement.sections[0].sections[0].hidden = false;

            } else {
                // If only Proposal OR IPS is included, then add the missing section to save as default.
                brandLogoOnlyChecklist.proposalStatement.push(_.cloneDeep(firstStatement));
                const secondStatement: ProposalStatement = brandLogoOnlyChecklist.proposalStatement[1];
                secondStatement.sections[0].sections[0].hidden = false;
                if (firstStatement.statementType.id === ProposalStatementType.Proposal) {
                    secondStatement.statementType.id = ProposalStatementType.IPS;
                    secondStatement.statementType.description = ProposalSectionLabel.IPS;
                } else {
                    secondStatement.statementType.id = ProposalStatementType.Proposal;
                    secondStatement.statementType.description = ProposalSectionLabel.Proposal;
                }

            }
            this.subscriptions.push(this.presentationSettingService.saveDefaultSelections(brandLogoOnlyChecklist).subscribe());
        }
    }

    public isProposalGenerationLocked(): boolean {
        return this.proposalService.isProposalChecklistLocked();
    }

    public getLockedTooltip(): string {
        return TooltipConstants.PROPOSAL_GENERATION_LOCKED_MESSAGE;
    }

}
