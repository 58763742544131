<car-right-hand-dialog dialogTitle=""
                       footerFunction="WIP-Account">
    <car-generic-error-notification></car-generic-error-notification>
    <div *ngIf="isManagedNonManagedCombination">
        <sei-banner-message [messageConfig]="modifyInvestmentBannerConfig"></sei-banner-message>
    </div>
    <sei-loading class="loadingSpinner"
                 [showLoadingIcon]="isLoading"
                 [multiplier]="5">
    </sei-loading>
    <section class="tab-container">
        <ul class="nav nav-tabs"
            [class.disabled]="isManagedNonManagedCombination">
            <li *ngFor="let navTab of navigationTabs "
                class="nav-item custom-tab"
                (click)="saveLastTabWorkedOn(navTab.accountDetailTabs)"
                [attr.data-target]="'#' + navTab.dataTarget"
                [class.active]="navTab.isActive"
                [class.disabled]="isManagedNonManagedCombination"
                [attr.data-toggle]="navTab.dataToggle">
                {{navTab.description}}
                <i *ngIf="showErrorExclamation && navTab.showErrorExclamation"
                   class="fa fa-exclamation-triangle error-icon"></i>
                <span class="navtabright"></span>
            </li>
        </ul>

        <div class="tab-content">
            <div class="tab-pane"
                 [ngClass]="{active: lastTabWorkedOn === accountDetailTabs.RiskCapacity}"
                 id="tabRiskCapacity">
                <sei-car-risk-selection [account]="account"
                                        [riskToleranceQuestionnaireMessage]="riskToleranceQuestionnaireMessage"
                                        (riskChanged)="validateAccount()">
                </sei-car-risk-selection>
            </div>
            <div class="tab-pane"
                 [ngClass]="{active: lastTabWorkedOn === accountDetailTabs.StrategySelection}"
                 id="tabStrategySelection">
                <div class="row">
                    <div class="col-xs-12 account-panel-title">
                        <h1>{{bookMarkName}}</h1>
                        <h2>{{account?.name}}</h2>
                    </div>
                </div>
                <ng-container *ngIf="showRiskWidget">
                    <sei-ias-risk-widget [riskWidgetType]="selectedRiskWidgetType"
                                         [riskToleranceScore]="riskToleranceScore"
                                         [investmentRiskScore]="investmentRiskScore"
                                         [showRiskToleranceLegend]="showRiskToleranceLegend"
                                         [showInvestmentLegend]="showInvestmentLegend"
                                         [highlightRiskLevelActive]="highlightRiskLevelActive"></sei-ias-risk-widget>
                    <div *ngIf="errorMessageCombinedStrategy"
                         class="form-group has-error">
                        <span class="help-block">{{ errorMessageCombinedStrategy }} </span>
                    </div>
                    <div *ngIf="showErrorExclamation && generalErrorMessage"
                         class="form-group has-error">
                        <span class="help-block">{{ generalErrorMessage }} </span>
                    </div>
                </ng-container>

                <sei-car-existing-accounts-alert></sei-car-existing-accounts-alert>

                <sei-ias-investment-selection *ngIf="showInvestmentSelectionComponent()"
                                              [investmentSummary]="investmentSummary"
                                              [platformFeeBasisPoint]="platformFeeBasisPoint"
                                              [accountDetails]="accountDetails"
                                              [accountName]="accountName"
                                              [businessRuleFlags]='businessRuleFlags'
                                              [isActionButtonsEnabled]="true"
                                              [proposal]="proposal"
                                              [riskToleranceScore]="riskToleranceScore"
                                              [componentVisibility]="componentVisibility"
                                              [createUMAModelRoute]="createUmaModelRoute"
                                              [accountCharacteristicsInfo]="accountCharacteristicsInfo"
                                              [apiConfiguration]="apiConfiguration"
                                              [isShowSearch]="investmentSelectionOptions?.isShowSearch"
                                              [isDisableAdditionalInvestment]="isManagedNonManagedCombination"
                                              [primaryDsrId]="primaryDsrId"
                                              [isEnableManagerGateway]="isEnableManagerGateway"
                                              [isFdicEnabled]="true"
                                              [currencySweepModelAllocation]="accountDetails.currencySweepModelAlloc"
                                              [selectedInvestmentProgramId]="account.investmentProgramId"
                                              [selectedInvestmentProgramType]="investmentSelectionOptions?.selectedInvestmentProgramType"
                                              [isCashNodeAllocationConfigEnabled]="isCashNodeAllocationConfigEnabled"
                                              [isMASProgram]="investmentSelectionOptions?.isMASProgram"
                                              [isDfsAccount]="investmentSelectionOptions?.isDfsAccount"
                                              [isRochdaleAccount]="investmentSelectionOptions?.isRochdaleAccount"
                                              [isShowFavorties]="investmentSelectionOptions?.isShowFavorties"
                                              [isShowExploreSEI]="investmentSelectionOptions?.isShowExploreSEI"
                                              [isShowSearch]="investmentSelectionOptions?.isShowSearch"
                                              [isViewAllStrategies]="investmentSelectionOptions?.isViewAllStrategies"
                                              [strategyToggleDisabled]="investmentSelectionOptions?.strategyToggleDisabled"
                                              [showZeroAmountOnSummary]="showZeroAmountOnSummary"
                                              [nonModelPortfolioTypes]="nonModelPortfolioType?.nonModelPortfolioTypes"
                                              [enableNonModelPortfolioAndAssetSearch]="enableNonModelPortfolioAndAssetSearch"
                                              [nonModelData]="createNonModelInvestmentsFromExisting"
                                              [isAllowFirmCustomStrategiesManagedAcc]="checkFirmCustomStrategiesAllowed()"
                                              [proposalOverrideSellsOnly]="nonModelSellsOnlyOverride"
                                              [isSubstitutionEnabled]="isSubstitutionEnabled"
                                              [isEnableMoneyMarket]="enableThirdPartyMoneyMarket"
                                              [isInvestorPlatformFeeEnabled]="isInvestorPlatformFeeEnabled"
                                              (nonModelPortfolioChange)="onNonModelPortfolioChange($event)"
                                              (disableFooterButtons)="validateFooterButtonStatus($event)"
                                              (selectedInvestmentProductDetail)="displayInvestmentProductDetail($event)"
                                              (saveAndExitDisable)="saveAndExitDisable($event)">
                </sei-ias-investment-selection>

                <sei-car-heads-up-display *ngIf="showHUD && bookMarkName === strategyMenu.StrategySummary && !isAssetAllocation"
                                          [hudFeeStrategy]="hudFeeSchedule">
                </sei-car-heads-up-display>

                <router-outlet (activate)="routerActivated($event)"
                               (deactivate)="routerDeactivated()"></router-outlet>
            </div>
        </div>
    </section>
</car-right-hand-dialog>
