/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, Input } from '@angular/core';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { Questionnaire } from '../../model/questionnaire';
import { QuestionnaireService } from '../../service/questionnaire.service';

@Component({
    selector: 'sei-car-questionnaire-question',
    templateUrl: './questionnaire-question.component.html'
})
export class QuestionnaireQuestionComponent extends ParentComponentSubscriptionManager {
    @Input() public questions: Questionnaire[];
    @Input() public accountType: number;

    public constructor(
        private questionnaireService: QuestionnaireService
    ) {
        super('QuestionnaireQuestionComponent');
    }

    public onAnswerChange(questionSelected): void {
        this.questionnaireService.getNextQuestionSelectedUpdated(questionSelected);
    }
}
