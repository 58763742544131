/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { CoverPageBaseComponent } from '../cover-page-base';

@Component({
    standalone: true,
    selector: 'sei-car-cover-page-simplistic',
    templateUrl: './cover-page-simplistic.component.html',
    styleUrls: ['./cover-page-simplistic.component.scss'],
    imports: [
        CommonModule
    ]
})
export class CoverPageSimplisticComponent extends CoverPageBaseComponent {
    constructor() {
        super();
        this.desiredClientNameHeight = 100;
    }
}
