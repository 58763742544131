<sei-modal-window #reactivateModal
                  [modalType]="getModalSize()"
                  [modalTitle]="'Reactivate Proposal'"
                  [isBackdrop]="true"
                  [isDraggable]="false"
                  [footerEnable]="true"
                  (modalClosed)="closeModal()">
    <div class="modal-body">
        <ng-container *ngTemplateOutlet="reactivate"></ng-container>
    </div>
    <footer class="modalbox-footer">
        <div *ngIf="showErrorBanner(); then errorFooter else defaultFooter"></div>
    </footer>
</sei-modal-window>
<ng-template #reactivate>
    <div class="container">
        <div *ngIf="showErrorBanner()">
            <sei-alert [type]="errorType">
                <span>{{ getValidationError() }}</span>
            </sei-alert>
        </div>
        <div>
            <sei-alert *ngIf="proposalContainsPendingAccounts() && !showErrorBanner()"
                       [type]="warningType">
                <span>By reactivating {{ this.proposal?.proposalName }} all pending accounts </span>
                <span>will be deleted from Digital Account Open. Any changes made to the pending accounts will be lost.</span>
                <p>Are you sure you want to continue?</p>
            </sei-alert>
            <div *ngIf="!proposalContainsPendingAccounts()">
                <h3>{{ this.proposal?.proposalName }}</h3>
            </div>
            <div>
                <p>{{ getModalWarningText() }}</p>
            </div>
        </div>
        <div *ngIf="proposalContainsPendingAccounts()"
             class="accounts-container">
            <sei-pageheader [headingLevel]="3"
                            [pageHeaderProperties]="accountDetailsHeaderProperties">
            </sei-pageheader>
            <div class="form-group has-info">
                <p class="help-block text-info">Below is a list of generated pending accounts and their respective statuses.</p>
            </div>
            <div class="row section-header">
                <div class="col-xs-4">
                    <p>Account Name</p>
                </div>
                <div class="col-xs-4">
                    <p>Account Number</p>
                </div>
                <div class="col-xs-4">
                    <p>Pending Account Status</p>
                </div>
            </div>
            <div class="row"
                 *ngFor="let account of getPendingAccounts()">
                <div class="col-xs-4 col-padding">
                    <p>{{ account.name }}</p>
                </div>
                <div class="col-xs-4">
                    <p>{{ getAccountNumberForAccount(account) }}</p>
                </div>
                <div class="col-xs-4 form-group has-error">
                    <p [class.text-error]="!isAccountStatusValid(account)"
                       [class.help-block]="!isAccountStatusValid(account)">{{ getStatusForAccount(account) }}</p>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #errorFooter>
    <sei-button class="pull-right"
                *ngIf="proposalContainsPendingAccounts()"
                [buttonClass]="'btn-primary'"
                [buttonText]="'Review Accounts'"
                (buttonClicked)="navigateToDAO()"></sei-button>
    <sei-button class="pull-right button-margin"
                [buttonClass]="'btn-default'"
                [buttonText]="'Close'"
                (buttonClicked)="closeModal()"></sei-button>
</ng-template>
<ng-template #defaultFooter>
    <sei-button class="pull-left"
                [buttonClass]="'btn-default'"
                [buttonText]="'Cancel'"
                (buttonClicked)="closeModal()"></sei-button>
    <sei-button class="pull-right"
                [buttonClass]="'btn-primary'"
                [buttonText]="'Continue'"
                (buttonClicked)="continueModal()"></sei-button>
</ng-template>
