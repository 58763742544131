/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { userProfileResponseMock15960077 } from '@CarMock/user-profile-response.15960077.mock';
import { Observable, of } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { AdvisorFactory } from '../model/factory/advisor-factory';
import { SeiPayload } from '../model/sei-payload';
import { Firm, FirmAdvisorResult, ProcessingRule, UserProfile } from '../model/user-profile';
import { GlobalService } from './system/global.service';
import { PropertyService } from './system/property.service';
import { UtilityService } from './system/utility.service';
import { UserProfileService } from './user-profile.service';


@Injectable({
    providedIn: 'root'
})
export class FirmAdvisorService {
    constructor(
        private http: HttpClient,
        private global: GlobalService,
        private readonly utilityService: UtilityService,
        private readonly propertyService: PropertyService,
        private readonly userProfileService: UserProfileService
    ) { }

    public getFirmAdvisorsByFirmId(firmId: number): Observable<Firm> {
        if (!firmId) {
            return of(new AdvisorFactory().createFirmAdvisor());
        }
        const apiUrl = this.global.configService.routeFormatter(
            this.global.configService.getEndPoint('advisors'),
            [
                {
                    key: 'firmId',
                    value: firmId
                },
                {
                    key: 'smUniversalId',
                    value: this.userProfileService.getCurrentUserProfile.entityId
                }
            ]
        );

        return this.http.get<SeiPayload>(apiUrl).pipe(
            share(),
            map((response) => {
                if (response && response.data) {
                    this.userProfileService.getCurrentUserProfile.firm = response.data[0].firms[0];
                    return response.data[0].firms[0];
                }
            })
        );
    }

    public getFirmAdvisorsByFirmIdAndUserId(swpFirmId: number, entityId: number) {
        const apiUrl = this.global.configService.routeFormatter(
            this.global.configService.getEndPoint('firmIdAndUserId'),
            [
                {
                    key: 'userEntityId',
                    value: entityId
                },
                {
                    key: 'swpFirmId',
                    value: swpFirmId
                }
            ]
        );
        return this.http.get<SeiPayload>(apiUrl).pipe(
            share(),
            map((response) => {
                if (this.propertyService.exists(() => response.data[0].userProfile.entitlements.advisors)) {
                    return response.data[0].userProfile.entitlements.advisors;
                }
            })
        );

    }

    public getFirmAdvisorsSearch(
        searchText: string
    ): Observable<FirmAdvisorResult[]> {

        const apiUrl = this.global.configService.routeFormatter(
            this.global.configService.getEndPoint(
                'getProposalDashboardTypeAhead'
            ),
            [
                {
                    key: 'searchText',
                    value: searchText
                }
            ]
        );

        return this.http.get(apiUrl).pipe(
            map((response: SeiPayload) => {
                if (response && response.data) {
                    const result: FirmAdvisorResult[] = response.data;
                    return result;
                }
            })
        );
    }

    public getFirmDataByEntityId(entityId: number): Observable<UserProfile> {
        // Note: Use this pattern which has config key in endpoint.
        // if (
        //     this.global.configService.environment.mode ===
        //     EnvironmentMode.Dev &&
        //     (this.global.configService.environment.mock || this.global.isMockDataMode())
        // ) {
        //     this.sortProcessingRules(userProfileResponseMock15960077);
        //     return of(userProfileResponseMock15960077);
        // }

        const apiUrl = this.global.configService.routeFormatter(
            this.global.configService.getEndPoint('userProfile'),
            [
                {
                    key: 'userEntityId',
                    value: entityId
                }
            ]
        );

        return this.http.get<SeiPayload>(apiUrl).pipe(
            share(),
            map((response) => {
                if (response) {
                    return response.data[0].userProfile;
                }
            })
        );
    }

    private sortProcessingRules(userProfile: UserProfile) {
        if (this.propertyService.exists(() => userProfile.processingRules)) {
            userProfile.processingRules = this.utilityService.sortObjectKeys<
                ProcessingRule
            >(userProfile.processingRules);
        }
    }
}

