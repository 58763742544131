<div class="mrdc-page">
    <h3 class="section-header">Advisors</h3>
    <sei-car-advisors-form [proposal]="proposal"
                           (onAdvisorChange)="onAdvisorChange($event)"></sei-car-advisors-form>
</div>
<div>
    <h3 class="section-header">Search Owners</h3>
    <div *ngFor="let client of proposal?.clients; let i=index">
        <sei-car-search-owner-form [client]="client"
                                   [firmId]="firmId"
                                   [index]="i"
                                   (onClientChange)="onClientAdded($event, i)"
                                   (onOwnerDelete)="deleteOwner($event, client)"></sei-car-search-owner-form>
    </div>
    <sei-button class="btn-in-tooltip"
                [buttonClass]="'btn-default'"
                [buttonText]="'Add Owner'"
                [hidden]="!allOwnersValid || proposal?.clients.length >= 10"
                (buttonClicked)="onAddOwnerClick()"></sei-button>
</div>
<sei-modal-window #existingAccountsResetModal
    [footerEnable]="true"
    [isDismissible]="true"
    [isBackdrop]="true"
    [modalTitle]="existingAccountsResetModalTitle"
    (modalClosed)="onExistingAccountsResetModalCancel()">
        <div class="modal-body">
            {{ existingAccountsResetModalBodyMessage }}
        </div>

        <footer class="modalbox-footer">
            <sei-button class="pull-left"
                buttonText="Cancel"
                buttonClass="btn-default"
                (buttonClicked)="onExistingAccountsResetModalCancel()"></sei-button>
            <sei-button buttonText="Continue"
                buttonClass="btn-primary"
                (buttonClicked)="onExistingAccountsResetModalContinue()"></sei-button>
        </footer>
</sei-modal-window>