<div class="wip-summary-row sectionhead wip-checklist-section-header wip-checklist-section-header-bg-gray"
    [class.wip-checklist-section-header-collapsed]="!areGoalsPresent() || showGoalsDetail">
    <div class="wip-summary-left subhead">
        <car-wip-checklist-status-indicator class="header-icon"
                              [mdcSnapShot]="wipCheckListGoals?.mdcSnapShot"></car-wip-checklist-status-indicator><h4>Goals</h4>
    </div>
    <div class="wip-summary-right">
        <sei-button buttonText="Edit"
                    buttonClass="btn-link"
                    (buttonClicked)="navigate()"></sei-button> <span [hidden]="!wipCheckListGoals?.subCheckList">|</span>
        <a  [hidden]="!wipCheckListGoals.subCheckList"
            class="section-arrow"
            aria-label="Expand Proposed Accounts Section"
            (click)="onShowDetailClick()">
                <i class="fas" [class.fa-chevron-right]="showGoalsDetail" [class.fa-chevron-down]="!showGoalsDetail"></i>
        </a>
    </div>
</div>
<div *ngIf="wipCheckListGoals"
     [hidden]="showGoalsDetail">
    <div *ngFor="let subCheckList of wipCheckListGoals?.subCheckList; let isLast = last"
        class="wip-goals-summary goals-head wip-section-background-green wip-section-border wip-section-border-rounded"
        [class.wip-section-border-rounded]="isLast">

        <div class="wip-summary-row ">
            <div class="wip-summary-left ">
                <p class="subhead section-name">
                    {{subCheckList.name }}
                </p>
            </div>
        </div>
        <ng-container *ngIf="subCheckList.mdcSnapShot.completed !== goalStatus.New">
            <div class="wip-summary-row">
                <div class="wip-summary-left fee-margin-left">
                    <span>Goal Type</span>
                </div>
                <div class="wip-summary-right">
                    <span>{{subCheckList.mdcSnapShot.goalType || '&mdash;'}}</span>
                </div>
            </div>
            <div class="wip-summary-row">
                <div class="wip-summary-left fee-margin-left">
                    <span>Priority</span>
                </div>
                <div class="wip-summary-right">
                    <span>{{subCheckList.mdcSnapShot.goalPriority || '&mdash;'}}</span>
                </div>
            </div>
            <div class="wip-summary-row">
                <div class="wip-summary-left fee-margin-left">
                    <span>End Date</span>
                </div>
                <div class="wip-summary-right">
                    <span>{{subCheckList.mdcSnapShot.goalEndDate || '&mdash;'}}</span>
                </div>
            </div>
            <div class="wip-summary-row">
                <div class="wip-summary-left fee-margin-left">
                    <span>Frequency</span>
                </div>
                <div class="wip-summary-right">
                    <span>{{subCheckList.mdcSnapShot.goalFrequency || '&mdash;'}}</span>
                </div>
            </div>
            <div class="wip-summary-row">
                <div class="wip-summary-left fee-margin-left">
                    <span>Amount</span>
                </div>
                <div class="wip-summary-right">
                    <sei-car-display-monetary-amount *ngIf="subCheckList?.mdcSnapShot?.goalAmount"
                                                     [amount]="subCheckList?.mdcSnapShot?.goalAmount"
                                                     [actualCurrency]="currencyTypes.USD">
                    </sei-car-display-monetary-amount>

                    <span *ngIf="!subCheckList?.mdcSnapShot?.goalAmount">
                    &mdash;
                </span>

                </div>
            </div>
        </ng-container>
    </div>
</div>
