<sei-dashboard-tile [title]="titleText"
                    panelClass="panel-default">
    <div panel-heading-extra-content>
        <sei-button buttonClass="btn-link"
                    buttonRightFontAwesomeIcon="fa-trash-alt"
                    (buttonClicked)="fireRemoveEvent()"></sei-button>
    </div>
    <div panel-content>
        <div class="form-group">
            <div class="row">
                <div class="col-xs-6">
                    <label for="name-{{goal.id}}"
                           class="card-label">Goal Name</label>
                    <input type="text"
                           class="form-control"
                           [class.border-red]="isNameOverMaximumLength"
                           id="name-{{goal.id}}"
                           name="name-{{goal.id}}"
                           [ngModel]="goal.name"
                           (ngModelChange)="onGoalNameChange($event)" />
                    <span *ngIf="isNameOverMaximumLength" class="name-max-length-warning-label">
                        Goal Name must be less than 66 characters
                    </span>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-6">
                    <label for="goalType"
                           class="card-label">Type</label>
                    <sei-dropdown name="StrategyFilter"
                                  [items]="goalCatalogs.types"
                                  placeholder="Select"
                                  [ngModel]="selectedOptionValues.types"
                                  (ngModelChange)="onDropDownChange($event, goalSelectionOptions.GoalType)">
                    </sei-dropdown>
                </div>
                <div class="col-xs-6">
                    <label for="priority"
                           class="card-label">Priority</label>
                    <sei-dropdown name="StrategyFilter"
                                  [items]="goalCatalogs.priorities"
                                  placeholder="Select"
                                  [ngModel]="selectedOptionValues.priorities"
                                  (ngModelChange)="onDropDownChange($event, goalSelectionOptions.Priority)">
                    </sei-dropdown>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-6">
                    <label for="goalType"
                           class="card-label">Start Date <i class="card-tooltip-icon fas fa-info-circle fas-info circle-icon" placement="right" [tooltip]="tipContent"></i></label>

                    <sei-calendar [minDate]="minDateAllowed"
                                  [startAt]="today"
                                  [ngModel]="goal.startDate"
                                  (ngModelChange)="onStartDateChange($event)">
                    </sei-calendar>
                </div>
                <div class="col-xs-6">
                    <label for="goalType"
                           class="card-label">End Date</label>

                    <sei-calendar [minDate]="goal.startDate"
                                  [startAt]="tomorrow"
                                  [ngModel]="goal.endDate"
                                  (ngModelChange)="onEndDateChange($event)">
                    </sei-calendar>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-6">
                    <label for="endValue-{{goal.id}}"
                           class="card-label">Amount</label>
                    <input type="text"
                           placeholder="$"
                           class="form-control"
                           id="endValue-{{goal.id}}"
                           name="endValue-{{goal.id}}"
                           ngDefaultControl
                           seiEditMonetaryAmount
                           [amountOptions]="amountOptions"
                           [actualCurrency]="this.currencyTypes.USD"
                           [class.alert]="!this.endValueIsValid"
                           [value]="goal.endValue"
                           (output)="onEndValueChange($event)"
                           maxlength="15" />
                    <car-alert-popover *ngIf="!this.endValueIsValid"
                                       [alertType]="alertPopoverTypes.Error"
                                       [alertMessage]="balanceErrorMessage"></car-alert-popover>
                </div>
                <div class="col-xs-6">
                    <label for="frequency"
                           class="card-label">Frequency</label>
                    <sei-dropdown name="StrategyFilter"
                                  [items]="goalCatalogs.frequencies"
                                  placeholder="Select"
                                  [ngModel]="selectedOptionValues.frequencies"
                                  (ngModelChange)="onDropDownChange($event,goalSelectionOptions.Frequency)">
                    </sei-dropdown>
                </div>
            </div>
        </div>
        <ng-template #tipContent>{{dateInfoText}}</ng-template>
    </div>
</sei-dashboard-tile>
