/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
import { UserProfile } from '@CarInterfaces/user-profile';

// Do not delete. Used in Unit Tests
export const userProfileResponseMock15960077: UserProfile = {
    userId: 5,
    userName: 'Book Of Bus, Q05 CAR',
    entityId: 15960077,
    smBrandId: '1',
    entitlements: {
        userAccessType: 2,
        entityId: 15960077,
        userName: 'Q05CARBookOfBus',
        isEmployee: true,
        swpFirmId: 4050473,
        userLevelId: 3,
        totalEmployeeDSRCount: 1,
        advisors: [
            {
                advisorId: 0,
                advisorName: 'Craven, Francis B',
                entityId: 15960077,
                userId: null,
                email: null,
                addressLine1: null,
                businessPhone: null,
                mobilePhone: null,
                faxNumber: null
                // smUniversalId: 15960077,
                // smUser: null,
                // crmGuid: null,
                // admin: null
            }
        ],
        userRoles: [
            {
                userRoleId: 4050961,
                userRoleName: 'Financial Advisor'
            }
        ]
    },
    firm: {
        firmId: 2,
        firmName: 'Arky & Miller Financial Group',
        swpFirmId: 4050473,
        riaFlag: null,
        brokerDealer: null,
        advisors: [
            {
                advisorId: 5,
                advisorName: 'Book Of Bus, Q05 CAR',
                entityId: 15960077,
                userId: 5,
                email: 'CARBookOfBus@seic.com',
                addressLine1: '1 freedom valley dr',
                businessPhone: '(610) 676-1901',
                mobilePhone: '(610) 676-1900',
                faxNumber: null,
                smUniversalId: 15960077,
                smUser: null,
                // crmGuid: '40f2f256-fd61-e911-a2dd-00505680df6f',
                admin: null
            },
            {
                advisorId: 6,
                advisorName: 'Full Firm Adm, Q05 CAR',
                entityId: 15960078,
                userId: 6,
                email: 'q05carfullfirmadm@seic.com',
                addressLine1: '1 freedom valley dr',
                businessPhone: '(610) 676-1997',
                mobilePhone: '(610) 676-1905',
                faxNumber: null,
                smUniversalId: 15960078,
                smUser: null,
                // crmGuid: '3c315bdc-fe61-e911-a2dd-00505680df6f',
                admin: null
            },
            {
                advisorId: 29,
                advisorName: 'Book Of Bus, Q05 CAR',
                entityId: 14999319,
                userId: 29,
                email: 'CARBookOfBus@seic.com',
                addressLine1: '1 freedom valley dr',
                businessPhone: '(610) 676-1906',
                mobilePhone: '(610) 676-1900',
                faxNumber: null,
                smUniversalId: 14999319,
                smUser: null,
                // crmGuid: '40f2f256-fd61-e911-a2dd-00505680df6f',
                admin: null
            },
            {
                advisorId: 36,
                advisorName: 'Q02CAR, BookOfBus',
                entityId: 17150086,
                userId: 36,
                email: 'fcraven@seic.fr',
                addressLine1: '1 freedom valley dr',
                businessPhone: '(610) 676-1909',
                mobilePhone: null,
                faxNumber: null,
                smUniversalId: 17150086,
                smUser: null,
                // crmGuid: 'd796a74d-2099-e911-a2de-00505680df6f',
                admin: null
            },
            {
                advisorId: 37,
                advisorName: 'Q02CAR, FullFirmAdm',
                entityId: 17150088,
                userId: 37,
                email: 'fcraven@seic.dk',
                addressLine1: '1 freedom valley dr',
                businessPhone: '(610) 676-1909',
                mobilePhone: null,
                faxNumber: null,
                smUniversalId: 17150088,
                smUser: null,
                // crmGuid: '721dac66-2099-e911-a2de-00505680df6f',
                admin: null
            }
        ],
        locations: [
            {
                officeLocationId: 7,
                officeName: 'New Century Financial',
                // swpOfficeId: null,
                addressLine1: '230 Second Street SE',
                addressLine2: null,
                city: 'Oaks',
                state: {
                    stateId: 38,
                    stateCode: 'PA',
                    sortOrder: 0
                },
                zipCode: '19999',
                phone: '(866) 123-5678'
            },
            {
                officeLocationId: 8,
                officeName: 'Farmers Online',
                // swpOfficeId: null,
                addressLine1: '8001 Centerview Parkway',
                addressLine2: null,
                city: 'Port Charlotte',
                state: {
                    stateId: 9,
                    stateCode: 'FL',
                    sortOrder: 0
                },
                zipCode: '33952',
                phone: '(866) 123-5678'
            }
        ],
        processingRules: null
    },
    functionPoints: [
        {
            functionPointId: 6466,
            functionPointDescription: 'CAR_BASIC_FUNCTIONALITY',
            permissionLevel: 15,
            permissionLevelDescription: 'Full access'
        },
        {
            functionPointId: 6467,
            functionPointDescription: 'CAR_SUMBIT_AND_PRINT_APPLICATIONS',
            permissionLevel: 15,
            permissionLevelDescription: 'Full access'
        }
    ],
    processingRules: {
        FeesDiscount: 'No',
        Securities_IncludingETF: 'Buys & Sells',
        CashDisbursementApproval: 'No',
        BrokerDealerFee: 'No',
        PlatformFee_Vs_TradingFee: 'Platform Fee',
        AddressChangeApproval: 'No',
        SEIStrategyApproval: 'No',
        ManagedAccountsDFS: 'Approved',
        RBPS: 'Not Approved',
        AllowCustomFirmNameOnProposal: 'No',
        ManagedAccountsProgramType: 'Standard',
        NonSEI_MutualFunds_BDApprovalRequired: 'No',
        RetireOne: 'Not Approved',
        CAA: 'Approved',
        FeesMinValue_Percent: '',
        CustomStrategyApproval: 'No',
        SubAdvisory: 'Not Approved',
        SuitabilityOverride_CountUp: '12',
        StatementRIATag: 'No',
        EnhancedAdvisorySolutions: 'Approved',
        YShares: 'Approved',
        Allow403b: 'No',
        StatementPerformance: 'Yes',
        FeesMaxValue_Percent: '',
        SBLOC: 'Approved',
        FeeApproval: 'Yes',
        DelegateSSNCaptureToInvestor: 'No',
        SEICustodyProgram: 'SEI Investment Models Plus Custom Models',
        Proxy: 'Investor',
        AccountOpenBDApproval: 'BDC',
        TradingApproval: 'No',
        AllowQualifiedPlans: 'No',
        FeesClearingAccount: 'BD Level',
        FormsManagement: 'Yes',
        CustomLogo: 'No',
        ReturnPackageOption: 'Default - Managed Account Default',
        StatementFrequency: 'Quarterly',
        SuitabilityOverride_CountDown: '12',
        FeesFrequency: 'Quarterly',
        StatementPackage: 'PO Level',
        AllowsESig: 'Yes',
        PersonalTrust: 'Approved',
        DonorAdvised: 'Approved',
        IndexPackage: 'Default Benchmark Package',
        RecurringInvestmentsApproval_UCA_DCA: 'No',
        OverlayApproval: 'No',
        Securities_IncludingETFs_BDApprovalRequired: 'No',
        AllowSuitabilityOverrides: 'Yes',
        CashTransferApproval: 'No',
        FeesMinMax_Applied: 'No',
        NonSEI_MutualFunds: 'Buys & Sells'
    },
    isSwpWalkMeServiceConfigured: true
};
