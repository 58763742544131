/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Action } from '@ngrx/store';

import { Account } from '@CarInterfaces/account';

export enum AccountActionTypes {
    InitializeAccountAction = '[CAR Account Setup] Setup Account State',
    UpdateAccountAction = '[CAR Account Service] Update Account'
}

export class InitializeAccount implements Action {
    readonly type = AccountActionTypes.InitializeAccountAction;
    constructor(public payload: { account: Account }) {}
}

export class UpdateAccount implements Action {
    readonly type = AccountActionTypes.UpdateAccountAction;

    constructor(public payload: { account: Account }) {}
}

export type AccountActions = InitializeAccount | UpdateAccount;
