<sei-header seiBrand="car"
            [style]="selectedStyle">
    <div logo-content>
        <sei-logo hideHomeTab="true" (click)="navigateToDashboard()"></sei-logo>
    </div>
    <div environment-content>
        <div class="environment-container">{{ environment }}</div>
    </div>
    <div logoff-content>
        <sei-logoff (onLogOff)="handleLogOff()"></sei-logoff>
    </div>
</sei-header>
<div id="walkmeParams"
     *ngIf="profile && userRoles">
    <input type="hidden"
           name="userId"
           id="userId"
           value="{{profile?.entitlements?.userName}}" />
    <input type="hidden"
           name="userDisplayName"
           id="userDisplayName"
           value="{{profile?.userName}}" />
    <input type="hidden"
           name="firmId"
           id="firmId"
           value="{{profile?.firm?.swpFirmId}}" />
    <input type="hidden"
           name="firmName"
           id="firmName"
           value="{{profile?.firm?.firmName}}" />
    <input type="hidden"
           name="firmGroupId"
           id="firmGroupId"
           value="{{profile?.firm?.firmGroupId}}" />
    <input type="hidden"
           name="firmGroupName"
           id="firmGroupName"
           value="{{profile?.firm?.firmGroupName}}" />
    <input type="hidden"
           name="userRoles"
           id="userRoles"
           value="{{userRoles}}" />
</div>
