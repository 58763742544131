<sei-car-risk-summary-widget [clickableSnapPoints]="riskWidgetAvailableValues"
                             (snapPointSelected)="onEventRiskLevelSelected($event)">
</sei-car-risk-summary-widget>
<div *ngIf="filteredStrategiesByRiskRank && filteredStrategiesByRiskRank.length === 0"
     class="text-center">
    <h6>
        <i class="fa fa-exclamation-triangle"></i>
        There are no available strategies that fit your criteria.
    </h6>
</div>
<div *ngIf="filteredStrategiesByRiskRank && filteredStrategiesByRiskRank.length > 0">
    <car-strategy-ribbon [strategy]="previouslyUsedStrategy"
                         [account]="account"
                         [ribbonType]="ribbonTypes.PreviouslyUsed"
                         *ngFor="let previouslyUsedStrategy of filteredStrategiesByRiskRank">
    </car-strategy-ribbon>
</div>