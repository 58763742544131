/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
import { TooltipConstants } from '@CarModels/constants';
import { SpinnerService } from '@CarServices/system/spinner.service';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Client, CurrentAccountDetails, Proposal, ProposalObjects, ProposalTaxTransitionService, SubmitButtonErrorMessages, SubmitTaxTransitionResponse, TaxAnalysisStates, TaxTransitionAnalysis, TaxTransitionEventData, WipCheckList, WipCheckListCurrentAccountDisplay } from '@sei/advisor-client-review-proposal-ux';
import { AlertComponent, BannerMessageType, ButtonComponent, DropdownItem, ModalWindowComponent } from '@sei/common-components-lib-ux';
import { BannerMessageComponent, BannerMessageModel } from '@sei/common-swp-components-lib-ux';
import { AccountDetailsObject, AccountDetailsSubmitDO, AccountOwnerDO, CurrentAccountsData, CurrentAccountsSubmitDO, DataDO, DataSubmitDO, DispositionMethod, EditDataObject, EditTaxTransitionData, EnableButtons, ProposedAccountData, SubmitDataObject, TaxRateFormat, TaxTransitionDTO, TaxTransitionDataDetails, TaxTransitionSubmitDTO } from '@sei/ias-applications-lib-ux';
import * as _ from 'lodash';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ProposalTaxTransitionAnalysisFormComponent } from './tax-transition-analysis-form/tax-transition-analysis-form.component';
@Component({
    standalone: true,
    selector: 'sei-car-proposal-tax-transition-analysis',
    templateUrl: './tax-transition-analysis.component.html',
    imports: [
        ButtonComponent,
        ModalWindowComponent,
        ProposalTaxTransitionAnalysisFormComponent,
        TooltipModule,
        BannerMessageComponent,
        AlertComponent,
        CommonModule
    ]
})
export class ProposalTaxTransitionAnalysisComponent {
    @Input()
    public proposal: Proposal;
    @Input()
    public editObject: EditTaxTransitionData;
    @Input()
    public proposalId: number;
    @Input()
    public accountOwners: AccountOwnerDO[];
    @Input()
    public mainTitle: string;
    @Input()
    public subtitle: string;
    @Input()
    public isDataSaved: boolean;
    @Input()
    public isReadUser: boolean;
    @Input()
    public showLockTooltip: boolean = false;
    @Output()
    public displayModalEvent: EventEmitter<TaxTransitionEventData> = new EventEmitter<TaxTransitionEventData>();
    @ViewChild('submitWarningModal') submitWarningModal: ModalWindowComponent;
    public taxDataIn: TaxTransitionDataDetails;
    public isSubmitDisabled: boolean;
    public taxTransitionData: EditDataObject;
    public accountOwnerDetails: AccountOwnerDO;
    public currentAccount: CurrentAccountsData[] = [];
    public currentAccountSubmit: CurrentAccountsSubmitDO[] = [];
    public proposedAccount: ProposedAccountData[] = [];
    public accountDetailObj: AccountDetailsObject = {
        currentAccounts: this.currentAccount,
        proposedAccounts: this.proposedAccount
    };
    public accountDetailSubmitObj: AccountDetailsSubmitDO = {
        currentAccounts: this.currentAccountSubmit,
        proposedAccounts: this.proposedAccount
    };
    public initialTaxRates: TaxRateFormat;
    public counter: number = 0;
    public isTaxRateEdited: boolean = false;
    public isStoreNewTaxRates: boolean = false;
    public displaySaveTaxModal: boolean = false;
    public displaySubmitConfirmModal: boolean = false;
    public buttonStatus: EnableButtons;
    public isSaveExitDisabled: boolean;
    public showErrorBanner: boolean = false;
    public reportGenerated: boolean = false;
    public showInProcessBanner: boolean = false;
    public contentGenericError: string = SubmitButtonErrorMessages.GenericError;
    public typeError: BannerMessageType = BannerMessageType.danger;
    public proposalObj: Proposal;
    public isSubmitClicked: boolean = false;
    public isSaveExitClicked: boolean = false;
    public resetObj: TaxRateFormat = {
        federalCapitalGains: '0.00',
        federalIncome: '0.00',
        stateCapitalGains: '0.00',
        stateIncome: '0.00'
    };
    public taxTransitionDataSubmit: SubmitDataObject;
    public selectedAccountOwner: Client;
    public showErrorMessage: boolean = false;
    public warningMessages: BannerMessageModel = {
        messages: ['Your Tax Rate Details has been changed. Do you want to save the rates?'],
        messageType: BannerMessageType.warning,
        display: true,
        showMessageContent: false
    };
    public bannerMessageConfig: BannerMessageModel[] = [
        this.warningMessages
    ];
    public taxRateSoftWarning: boolean;
    public accountOwnerDisable: boolean = false;
    public submitErrorFlag: string = '';
    public showTaxRateSaveFailedInfo: boolean = false;
    public isTaxRatesApiFailed: boolean = false;
    public dispositionMethodTTA: string = '';
    constructor(private proposalTaxservice: ProposalTaxTransitionService, private spinnerService: SpinnerService) {
    }

    public cancel(): void {
        this.spinnerService.start();
        this.proposalTaxservice.getTaxAnalysisDetails(this.proposalId).subscribe((response: CurrentAccountDetails): void => {
            if (response && response.data && response.data.length !== 0) {
                const proposalObjects: ProposalObjects = response.data[0];
                this.proposalObj = proposalObjects.proposals[0];
                if (this.proposalObj.wipCheckList) {
                    this.proposalObj.wipCheckList.forEach((checklist: WipCheckList): void => {
                        if (checklist.name === this.proposalObj.name) {
                            checklist.subCheckList.forEach((subChecklist: WipCheckListCurrentAccountDisplay): void => {
                                if (subChecklist.name === 'TaxLots') {
                                    const subListItem: WipCheckListCurrentAccountDisplay = subChecklist.subCheckList[0] ?
                                        subChecklist.subCheckList[0] : null;
                                    if (subListItem) {
                                        const taxAnalysisStatus: number = subListItem.mdcSnapShot.completed;
                                        this.showInProcessBanner = (!this.showErrorBanner && !this.reportGenerated) &&
                                            (taxAnalysisStatus === TaxAnalysisStates.InProgress
                                                || taxAnalysisStatus === TaxAnalysisStates.Completed);
                                        this.showErrorBanner = (!this.showInProcessBanner && !this.reportGenerated) &&
                                            (taxAnalysisStatus === TaxAnalysisStates.AnalysisInException ||
                                                taxAnalysisStatus === TaxAnalysisStates.ReportException);
                                        this.reportGenerated = (!this.showInProcessBanner && !this.showErrorBanner &&
                                            taxAnalysisStatus === TaxAnalysisStates.ReportGenerated);
                                        if (this.reportGenerated || this.isDataSaved) {
                                            this.showTaxRateSaveFailedInfo = subListItem.isSaveTaxRatesFailed ?
                                                subListItem.isSaveTaxRatesFailed : false;
                                        }
                                    }
                                }
                            });
                        }
                    });
                }
            }
            document.body.style.removeProperty('overflow');
            const obj: TaxTransitionEventData = {
                loadTaxTransition: false,
                addEditButtonText: this.editObject.isAddFlag ? TaxTransitionAnalysis.Add : TaxTransitionAnalysis.Edit,
                showError: this.showErrorBanner,
                showInProcess: this.showInProcessBanner,
                isAddEnabled: !this.showInProcessBanner,
                showSuccess: this.reportGenerated,
                showSaved: this.isDataSaved,
                showInfo: this.showTaxRateSaveFailedInfo
            };
            this.displayModalEvent.emit(obj);
            this.spinnerService.stop();
        },
            (): void => {
                this.spinnerService.stop();
            });
    }

    public saveAndExit(): void {
        this.isSaveExitClicked = true;
        this.displaySubmitConfirmModal = false;
        if (this.isTaxRateEdited && this.selectedAccountOwner.entityType === 'Client') {
            this.accountOwnerDisable = true;
            this.taxRateSoftWarning = true;
            this.displaySaveTaxModal = true;
            this.disableButtons();
        } else {
            this.isStoreNewTaxRates = false;
            this.setDetails(this.isStoreNewTaxRates);
        }
    }

    public submitTaxTransitionAnalysis(): void {
        this.displaySubmitConfirmModal = true;
        if (this.isTaxRateEdited && this.selectedAccountOwner.entityType === 'Client') {
            this.displaySaveTaxModal = true;
            this.accountOwnerDisable = true;
            this.taxRateSoftWarning = true;
            this.disableButtons();
        } else {
            this.accountOwnerDisable = false;
            this.taxRateSoftWarning = false;
            this.submitWarningModal.showModalBox();
        }
    }

    public getDisableButtons(event): void {
        this.buttonStatus = event;
        if (!this.taxRateSoftWarning) {
            this.isSubmitDisabled = this.buttonStatus.disableSubmit;
            this.isSaveExitDisabled = this.buttonStatus.disableSaveExit;
        }
    }

    public confirmStoreNewTaxrates(): void {
        this.displaySaveTaxModal = false;
        this.isStoreNewTaxRates = true;
        if (this.displaySubmitConfirmModal) {
            this.accountOwnerDisable = false;
            this.taxRateSoftWarning = false;
            this.submitWarningModal.showModalBox();
        } else {
            this.setDetails(this.isStoreNewTaxRates);
        }
    }

    public onModalClosed(): void {
        if (this.displaySaveTaxModal) {
            this.displaySaveTaxModal = false;
            this.isStoreNewTaxRates = false;
            if (this.displaySubmitConfirmModal) {
                this.accountOwnerDisable = false;
                this.taxRateSoftWarning = false;
                this.submitWarningModal.showModalBox();
            } else {
                this.setDetails(this.isStoreNewTaxRates);
            }
        }
    }

    public onFinalModalClosed(): void {
        if (this.displaySubmitConfirmModal) {
            this.displaySubmitConfirmModal = false;
            this.accountOwnerDisable = false;
            this.taxRateSoftWarning = false;
            this.disableButtons();
            this.submitWarningModal.hideModalBox();
        }
    }

    public confirmSubmit(): void {
        this.isSubmitClicked = true;
        this.setDetails(this.isStoreNewTaxRates);
        this.accountOwnerDisable = false;
        this.taxRateSoftWarning = false;
        this.disableButtons();
        this.submitWarningModal.hideModalBox();
    }

    public setDetails(isNewTaxRate: boolean): void {
        this.spinnerService.start();
        if (this.taxDataIn) {
            if (this.taxDataIn.accountOwner) {
                this.taxDataIn.accountOwner.forEach((item: DropdownItem): void => {
                    this.accountOwnerDetails = {
                        id: item.id,
                        name: item.name,
                        entityId: this.selectedAccountOwner.entityId ? this.selectedAccountOwner.entityId : null,
                        entityType: this.selectedAccountOwner.entityType ? this.selectedAccountOwner.entityType : null
                    };
                });
            }

            if (this.taxDataIn.currentAccounts && this.taxDataIn.currentAccounts.size !== 0) {
                this.taxDataIn.currentAccounts.toArray().forEach((item: DropdownItem): void => {
                    const currObj: CurrentAccountsData = {
                        id: item.id,
                        proposalId: this.proposalId,
                        currentAccountName: item.name,
                        accountOwners: this.accountOwnerDetails
                    };
                    this.accountDetailObj.currentAccounts.push(currObj);

                    const currObjSubmit: CurrentAccountsSubmitDO = {
                        id: item.id,
                        proposalId: this.proposalId,
                        currentAccountName: item.name,
                        accountOwners: this.accountOwnerDetails
                    };
                    this.accountDetailSubmitObj.currentAccounts.push(currObjSubmit);
                });
            } else {
                const currentAccountObj: CurrentAccountsData = {
                    id: null,
                    proposalId: this.proposalId,
                    currentAccountName: null,
                    accountOwners: this.accountOwnerDetails
                };
                this.accountDetailObj.currentAccounts.push(currentAccountObj);

                const currentAccountSubmitObj: CurrentAccountsSubmitDO = {
                    id: null,
                    proposalId: this.proposalId,
                    currentAccountName: null,
                    accountOwners: this.accountOwnerDetails
                };
                this.accountDetailSubmitObj.currentAccounts.push(currentAccountSubmitObj);
            }


            if (this.taxDataIn.proposedAccount && this.taxDataIn.proposedAccount.size !== 0) {
                this.taxDataIn.proposedAccount.toArray().forEach((item: DropdownItem): void => {
                    const proposedObj: ProposedAccountData = {
                        proposalAccountId: item.id,
                        accountName: item.name
                    };
                    this.accountDetailObj.proposedAccounts.push(proposedObj);
                });
            } else {
                const proposeAccountdObj: ProposedAccountData = {
                    proposalAccountId: null,
                    accountName: null
                };
                this.accountDetailObj.proposedAccounts.push(proposeAccountdObj);
            }

            if (this.taxDataIn.dispositionMethod && this.taxDataIn.dispositionMethod.size !== 0) {
                this.taxDataIn.dispositionMethod.toArray().forEach((dispMethod: DropdownItem): void => {
                    this.dispositionMethodTTA = dispMethod.name;
                });
            } else {
                this.dispositionMethodTTA = DispositionMethod.LT;
            }
        }

        const taxDataObj: TaxRateFormat = this.taxDataIn.taxRateData;
        const initialTax: TaxRateFormat = this.initialTaxRates ? this.initialTaxRates : this.resetObj;

        if (this.isSubmitClicked) {
            const taxTransitionSubmitDTO: TaxTransitionSubmitDTO = {
                accountDetails: this.accountDetailSubmitObj,
                federalCapitalGains: parseFloat(taxDataObj.federalCapitalGains),
                federalIncome: parseFloat(taxDataObj.federalIncome),
                stateCapitalGains: parseFloat(taxDataObj.stateCapitalGains),
                stateIncome: parseFloat(taxDataObj.stateIncome),
                taxEfficiency: ['Non-Tax Managed'],
                isSaveTaxRates: isNewTaxRate,
                dispositionMethod: this.dispositionMethodTTA
            };

            const dataSubmitDO: DataSubmitDO = {
                taxTransitionDTO: [taxTransitionSubmitDTO]
            };

            this.taxTransitionDataSubmit = {
                data: [dataSubmitDO]
            };
            this.submitAPICalled();
        }
        if (this.isSaveExitClicked) {
            const taxTransitionDO: TaxTransitionDTO = {
                accountDetails: this.accountDetailObj,
                federalCapitalGains: isNewTaxRate ? parseFloat(taxDataObj.federalCapitalGains) : parseFloat(initialTax.federalCapitalGains),
                federalIncome: isNewTaxRate ? parseFloat(taxDataObj.federalIncome) : parseFloat(initialTax.federalIncome),
                stateCapitalGains: isNewTaxRate ? parseFloat(taxDataObj.stateCapitalGains) : parseFloat(initialTax.stateCapitalGains),
                stateIncome: isNewTaxRate ? parseFloat(taxDataObj.stateIncome) : parseFloat(initialTax.stateIncome),
                isSaveTaxRates: isNewTaxRate,
                dispositionMethod: this.dispositionMethodTTA
            };

            const dataDO: DataDO = {
                taxTransitionDTO: [taxTransitionDO]
            };

            this.taxTransitionData = {
                data: [dataDO]
            };
            this.saveAndExitAPICalled();
        }
        this.spinnerService.stop();
    }

    private submitAPICalled(): void {
        this.spinnerService.start();
        this.proposalTaxservice.submitTaxTransitionDetails(this.taxTransitionDataSubmit).subscribe(
            (res: SubmitTaxTransitionResponse): void => {
                if (res && res.data) {
                    if (res.data.isInvestmentCalcApiFailed) {
                        if (res.data.isInvestmentCalcApiFailed) {
                            this.showErrorMessage = true;
                            this.submitErrorFlag = SubmitButtonErrorMessages.GenericFailure;
                            this.spinnerService.stop();
                        }
                    } else {
                        this.showErrorMessage = false;
                        this.submitErrorFlag = '';
                        this.proposalTaxservice.getTaxAnalysisDetails(this.proposalId).subscribe(
                            (response: CurrentAccountDetails): void => {
                                if (response && response.data && response.data.length !== 0) {
                                    const proposalObjects: ProposalObjects = response.data[0];
                                    this.proposalObj = proposalObjects.proposals[0];
                                    if (this.proposalObj.wipCheckList) {
                                        this.proposalObj.wipCheckList.forEach((checklist: WipCheckList): void => {
                                            if (checklist.name === this.proposalObj.name) {
                                                checklist.subCheckList.forEach((subChecklist: WipCheckListCurrentAccountDisplay): void => {
                                                    if (subChecklist.name === 'TaxLots') {
                                                        const subListItem: WipCheckListCurrentAccountDisplay =
                                                            subChecklist.subCheckList[0] ? subChecklist.subCheckList[0] : null;
                                                        if (subListItem) {
                                                            const taxAnalysisStatus: number = subListItem.mdcSnapShot.completed;
                                                            this.showInProcessBanner = (!this.showErrorBanner && !this.reportGenerated) &&
                                                                (taxAnalysisStatus === TaxAnalysisStates.InProgress
                                                                    || taxAnalysisStatus === TaxAnalysisStates.Completed);
                                                            this.showErrorBanner = (!this.showInProcessBanner && !this.reportGenerated) &&
                                                                (taxAnalysisStatus === TaxAnalysisStates.AnalysisInException ||
                                                                    taxAnalysisStatus === TaxAnalysisStates.ReportException);
                                                            this.reportGenerated = (!this.showInProcessBanner && !this.showErrorBanner &&
                                                                taxAnalysisStatus === TaxAnalysisStates.ReportGenerated);
                                                            if (this.reportGenerated) {
                                                                this.showTaxRateSaveFailedInfo = subListItem.isSaveTaxRatesFailed ?
                                                                    subListItem.isSaveTaxRatesFailed : false;
                                                            }
                                                        }
                                                    }
                                                });
                                            }
                                        });
                                    }
                                }
                                const obj: TaxTransitionEventData = {
                                    loadTaxTransition: false,
                                    addEditButtonText: TaxTransitionAnalysis.Edit,
                                    showError: this.showErrorBanner,
                                    showInProcess: this.showInProcessBanner,
                                    isAddEnabled: !this.showInProcessBanner,
                                    showSuccess: this.reportGenerated,
                                    showSaved: false,
                                    showInfo: this.showTaxRateSaveFailedInfo
                                };
                                this.displayModalEvent.emit(obj);
                                document.body.style.removeProperty('overflow');
                                this.spinnerService.stop();
                            });
                    }
                } else {
                    this.showErrorMessage = true;
                    this.submitErrorFlag = SubmitButtonErrorMessages.GenericFailure;
                    this.spinnerService.stop();
                }
                this.accountOwnerDisable = false;
                this.taxRateSoftWarning = false;
            },
            (): void => {
                this.showErrorMessage = true;
                this.accountOwnerDisable = false;
                this.taxRateSoftWarning = false;
                this.submitErrorFlag = SubmitButtonErrorMessages.GenericFailure;
                this.spinnerService.stop();
            });
        this.isSubmitClicked = false;
    }

    private saveAndExitAPICalled(): void {
        this.spinnerService.start();
        this.showErrorMessage = false;
        this.submitErrorFlag = '';
        this.showInProcessBanner = false;
        this.proposalTaxservice.saveExitTaxTransitionDetails(this.taxTransitionData).subscribe(
            (response: EditDataObject): void => {
                if (response) {
                    if (response.data && response.data[0] && response.data[0].taxTransitionDTO
                        && response.data[0].taxTransitionDTO[0]) {
                        this.isTaxRatesApiFailed = response.data[0].taxTransitionDTO[0].isTaxRatesApiFailed ?
                            response.data[0].taxTransitionDTO[0].isTaxRatesApiFailed : false;
                    }
                    const obj: TaxTransitionEventData = {
                        loadTaxTransition: false,
                        addEditButtonText: TaxTransitionAnalysis.Edit,
                        showError: false,
                        showInProcess: false,
                        isAddEnabled: true,
                        showSuccess: false,
                        showSaved: true,
                        showInfo: this.isTaxRatesApiFailed
                    };
                    this.displayModalEvent.emit(obj);
                    document.body.style.removeProperty('overflow');
                    this.spinnerService.stop();
                } else {
                    this.showErrorMessage = true;
                    this.submitErrorFlag = SubmitButtonErrorMessages.GenericFailure;
                    this.spinnerService.stop();
                }
                this.accountOwnerDisable = false;
                this.taxRateSoftWarning = false;
            },
            (): void => {
                this.accountOwnerDisable = false;
                this.taxRateSoftWarning = false;
                this.showErrorMessage = true;
                this.submitErrorFlag = SubmitButtonErrorMessages.GenericFailure;
                this.spinnerService.stop();
            });
        this.isSaveExitClicked = false;
    }

    public getTaxData(event): void {
        if (event) {
            if (event.accountOwner) {
                if (this.taxDataIn && this.taxDataIn.accountOwner && this.taxDataIn.accountOwner !== event.accountOwner) {
                    this.accountOwnerDisable = false;
                    this.taxRateSoftWarning = false;
                }
            } else {
                this.accountOwnerDisable = false;
                this.taxRateSoftWarning = false;
            }
            if (this.taxDataIn && this.taxDataIn.accountOwner &&
                this.taxDataIn.accountOwner !== event.accountOwner) {
                if (this.taxRateSoftWarning) {
                    this.accountOwnerDisable = false;
                    this.taxRateSoftWarning = false;
                }
            }
            this.taxDataIn = event;
            this.getEntityTypeDetails();
            const data: TaxRateFormat = this.taxDataIn.taxRateData;
            if (data) {
                if (data.federalCapitalGains && data.federalIncome && data.stateCapitalGains && data.stateIncome) {
                    if (data.federalCapitalGains !== '0.00' && data.federalIncome !== '0.00') {
                        this.counter++;
                        if (this.counter === 1) {
                            this.initialTaxRates = data;
                        }
                    }
                }
            }
            if (data && this.initialTaxRates) {
                data.federalIncome = parseFloat(data.federalIncome).toFixed(2);
                data.federalCapitalGains = parseFloat(data.federalCapitalGains).toFixed(2);
                data.stateCapitalGains = parseFloat(data.stateCapitalGains).toFixed(2);
                data.stateIncome = parseFloat(data.stateIncome).toFixed(2);

                this.initialTaxRates.federalIncome = parseFloat(this.initialTaxRates.federalIncome).toFixed(2);
                this.initialTaxRates.federalCapitalGains = parseFloat(this.initialTaxRates.federalCapitalGains).toFixed(2);
                this.initialTaxRates.stateCapitalGains = parseFloat(this.initialTaxRates.stateCapitalGains).toFixed(2);
                this.initialTaxRates.stateIncome = parseFloat(this.initialTaxRates.stateIncome).toFixed(2);
            }

            this.isTaxRateEdited = (data === null) ? false : !(_.isEqual(data, this.initialTaxRates));
        }

    }

    private getEntityTypeDetails(): void {
        let accountOwnerId;
        if (this.taxDataIn && this.taxDataIn.accountOwner.size > 0) {
            this.taxDataIn.accountOwner.forEach((item: DropdownItem): void => {
                accountOwnerId = item.id;
            });
            if (this.proposal) {
                if (this.proposal.clients && this.proposal.clients.length > 0) {
                    this.proposal.clients.forEach((data) => {
                        if (data.id === accountOwnerId) {
                            this.selectedAccountOwner = data;
                        }
                    });
                }
            }
        }
    }

    public disableButtons(): void {
        if (this.taxRateSoftWarning) {
            this.isSaveExitDisabled = true;
            this.isSubmitDisabled = true;
        } else {
            this.isSaveExitDisabled = false;
            this.isSubmitDisabled = false;
        }
    }

    public getSaveAndExitTooltip(): string {
        if (this.showLockTooltip) {
            return TooltipConstants.PROPOSAL_CHECKLIST_LOCKED_MESSAGE;
        }
        return undefined;
    }
}
