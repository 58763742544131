<car-right-hand-dialog dialogTitle="New Proposal" footerFunction="PROPOSAL" *ngIf="proposalStream | async as propsal">
    <car-generic-error-notification></car-generic-error-notification>
    <h1>Create New Proposal</h1>
    <div id="main-content" class="section-gap">
        <div *ngIf="showFirms">
            <sei-car-select-firm-advisor [firm]="firm" [clearForm]="true" [displayMode]="displayModes.Proposal"
                [entitledAdvisorsEntityIds]="entitledAdvisorsEntityIds" (firmSelect)="firmSelected($event)"
                [firmAdvisorLabel]="firmAdvisorLabels.Firm" (firmCleared)="firmCleared()"></sei-car-select-firm-advisor>
        </div>
        <div *ngIf="showProposal">
            <car-proposal-scenario [proposal]="proposal" [accountTypeData]="accountTypeData"
                [scenario]="proposal.scenarios[0]" [suffix]="suffixData"></car-proposal-scenario>
        </div>
    </div>
</car-right-hand-dialog>
