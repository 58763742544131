/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { AdditionalWipSection } from '@CarInterfaces/account';
import { OwnerType } from '@CarInterfaces/account-owner-detail';
import { OwnerTypes } from '@CarModels/enums';
import { WipChangeState, WipSections } from '@CarServices/wip/wip-enums';

/**
 * Account Types Ids and Names
 * Use Account Type Service if you need the complete list
 */
export enum AccountTypes {
    // Please keep in Alpha sorted
    // only add items if you need to perform logic against a specific account type
    // Use naming pattern by suffixing ids with Id and description/name with Name
    // This is so dynamic logic can be written
    _403BId = 2,
    _403BName = '403(B)',
    CommunityPropertyId = 3,
    CommunityPropertyName = 'Community Property',
    CommunityPropertyWithRightsOfSurvivorshipId = 4,
    CommunityPropertyWithRightsOfSurvivorshipName = 'Community Property with Rights of Survivorship',
    GuardianshipId = 6,
    GuardianshipName = 'Guardianship',
    IndividualOwnerId = 7,
    IndividualOwnerName = 'Individual Owner',
    EstateId = 14,
    EstateName = 'Estate',
    InheritedRothIraId = 16,
    InheritedRothIraName = 'Inherited Roth IRA',
    InheritedTraditionalIraId = 17,
    IraId = 18,
    IraName = 'IRA',
    InheritedTraditionalIraName = 'Inherited Traditional IRA',
    JointTenantsWithRightsOfSurvivorshipId = 19,
    JointTenantsWithRightsOfSurvivorshipName = 'Joint Tenants with Rights of Survivorship',
    RothIraId = 22,
    RothIraName = 'Roth IRA',
    SepIraId = 23,
    SepIraName = 'SEP IRA',
    SimpleIraId = 24,
    SimpleIraName = 'Simple IRA',
    PartnershipId = 26,
    PartnershipName = 'Partnership',
    PrivateCorporationId = 27,
    PrivateCorporationName = 'Private Corporation',
    TenantsByEntiretyId = 29,
    TenantsByEntiretyName = 'Tenants by Entirety',
    TenantsInCommonId = 30,
    TenantsInCommonName = 'Tenants in Common',
    TaxExemptNonProfitId = 33,
    TaxExemptNonProfitName = 'Tax Exempt / Non - Profit',
    TrustId = 34,
    TrustName = 'Trust',
    UgmaId = 35,
    UgmaName = 'UGMA',
    UtmaId = 36,
    UtmaName = 'UTMA'
}

// From database incase its needed for enum above
// 10	401(k) including Roth 401(k)
// 2	403(B)
// 1	Charitable Trust
// 3	Community Property
// 4	Community Property with Rights of Survivorship
// 11	Crummey Trust
// 12	Defined Benefit(including cash balance)
// 13	Endowment
// 14	Estate
// 15	Foundation
// 5	Grantor Retained Annuity Trust
// 8	Grantor Retained Unitrust
// 6	Guardianship
// 7	Individual Owner
// 16	Inherited Roth IRA
// 17	Inherited Traditional IRA
// 18	IRA
// 20	Irrevocable Trust
// 9	Irrevocable Trust Due to Death
// 19	Joint Tenants with Rights of Survivorship
// 21	Money Purchase
// 26	Partnership
// 37	Personal Trust(Agent for Trustee)
// 38	Personal Trust(Discretionary)
// 39	Personal Trust(Investment Directed)
// 27	Private Corporation
// 28	Profit Sharing
// 31	Public Corporation
// 32	Revocable Trust
// 22	Roth IRA
// 23	SEP IRA
// 24	SIMPLE IRA
// 25	Sole Proprietorship
// 40	Solo 401(k)
// 33	Tax Exempt / Non - Profit
// 29	Tenants by Entirety
// 30	Tenants in Common
// 34	Trust
// 35	UGMA
// 36	UTMA

export interface AccountTypeOptionSettings {
    accountTypeId: number;
    name: string;
    additionalWipSections: AdditionalWipSection[];
    wipSectionSettings?: WipSectionSettings[];
}

export interface WipSectionSettings {
    sectionId: number;
    displayUploadDocuments?: boolean;
    displayTypeOfOwner?: boolean;
    defaultTypeOfOwner?: OwnerType;
    fieldLabelMappings?: {};
    showInvestorTaxRateSection?: boolean;
    displayMaritalStatus?: boolean;
    sortOrder?: number;
}

/**
 * Contains values for special login the UI needs to perform for a specific account type
 * @param recommendedWipSections WIP sections to add as recommended
 * @param fieldNameMappings Labels for fields that need to change for the account type per WIP section
 */
export const accountTypeOptionsData: AccountTypeOptionSettings[] = [
    {
        accountTypeId: AccountTypes._403BId.valueOf(),
        name: AccountTypes._403BName.valueOf(),
        additionalWipSections: [
            {
                id: WipSections.PowerAttorney,
                changeState: WipChangeState.Incomplete
            }
        ],
        wipSectionSettings: [
            {
                sectionId: WipSections.AccountDetails,
                showInvestorTaxRateSection: true
            }
        ]
    },
    {
        accountTypeId: AccountTypes.CommunityPropertyId.valueOf(),
        name: AccountTypes.CommunityPropertyName.valueOf(),
        additionalWipSections: [
            {
                id: WipSections.PowerAttorney,
                changeState: WipChangeState.Incomplete
            }
        ],
        wipSectionSettings: [
            {
                sectionId: WipSections.AccountOwner,
                fieldLabelMappings: {
                    companyName: 'Property Name',
                    addressLine1: 'Property Address Line 1'
                }
            },
            {
                sectionId: WipSections.AccountDetails,
                showInvestorTaxRateSection: true
            }
        ]
    },
    {
        accountTypeId: AccountTypes.CommunityPropertyWithRightsOfSurvivorshipId.valueOf(),
        name: AccountTypes.CommunityPropertyWithRightsOfSurvivorshipName.valueOf(),
        additionalWipSections: [
            {
                id: WipSections.PowerAttorney,
                changeState: WipChangeState.Incomplete
            }
        ],
        wipSectionSettings: [
            {
                sectionId: WipSections.AccountDetails,
                showInvestorTaxRateSection: true
            }
        ]
    },
    {
        accountTypeId: AccountTypes.GuardianshipId.valueOf(),
        name: AccountTypes.GuardianshipName.valueOf(),
        additionalWipSections: [],
        wipSectionSettings: [
            {
                sectionId: WipSections.AccountOwner,
                displayUploadDocuments: true
            },
            {
                sectionId: WipSections.AccountDetails,
                showInvestorTaxRateSection: true
            }
        ]
    },
    {
        accountTypeId: AccountTypes.IndividualOwnerId.valueOf(),
        name: AccountTypes.IndividualOwnerName.valueOf(),
        additionalWipSections: [
            {
                id: WipSections.PowerAttorney,
                changeState: WipChangeState.Incomplete
            }
        ],
        wipSectionSettings: [
            {
                sectionId: WipSections.AccountOwner,
                displayUploadDocuments: true
            },
            {
                sectionId: WipSections.AccountDetails,
                showInvestorTaxRateSection: true
            }
        ]
    },
    {
        accountTypeId: AccountTypes.EstateId.valueOf(),
        name: AccountTypes.EstateName.valueOf(),
        additionalWipSections: [],
        wipSectionSettings: [
            {
                sectionId: WipSections.AccountOwner,
                displayUploadDocuments: true
            },
            {
                sectionId: WipSections.AccountDetails,
                showInvestorTaxRateSection: true
            }
        ]
    },
    {
        accountTypeId: AccountTypes.InheritedRothIraId.valueOf(),
        name: AccountTypes.InheritedRothIraName.valueOf(),
        additionalWipSections: [
            {
                id: WipSections.PowerAttorney,
                changeState: WipChangeState.Incomplete
            }
        ],
        wipSectionSettings: [
            {
                sectionId: WipSections.AccountOwner,
                displayTypeOfOwner: true,
                defaultTypeOfOwner: {
                    typeOfOwnerId: OwnerTypes.IndividualAdult,
                    typeOfOwnerDescription: 'Individual (Adult)'
                },
                displayMaritalStatus: true
            },
            {
                sectionId: WipSections.AccountDetails,
                showInvestorTaxRateSection: false
            }
        ]
    },
    {
        accountTypeId: AccountTypes.InheritedTraditionalIraId.valueOf(),
        name: AccountTypes.InheritedTraditionalIraName.valueOf(),
        additionalWipSections: [
            {
                id: WipSections.PowerAttorney,
                changeState: WipChangeState.Incomplete
            }
        ],
        wipSectionSettings: [
            {
                sectionId: WipSections.AccountOwner,
                displayTypeOfOwner: true,
                defaultTypeOfOwner: {
                    typeOfOwnerId: OwnerTypes.IndividualAdult,
                    typeOfOwnerDescription: 'Individual (Adult)'
                },
                displayMaritalStatus: true
            },
            {
                sectionId: WipSections.AccountDetails,
                showInvestorTaxRateSection: false
            }
        ]
    },
    {
        accountTypeId: AccountTypes.IraId.valueOf(),
        name: AccountTypes.IraName.valueOf(),
        additionalWipSections: [
            {
                id: WipSections.PowerAttorney,
                changeState: WipChangeState.Incomplete
            }
        ],
        wipSectionSettings: [
            {
                sectionId: WipSections.AccountOwner,
                displayMaritalStatus: true
            },
            {
                sectionId: WipSections.AccountDetails,
                showInvestorTaxRateSection: false
            }
        ]
    },
    {
        accountTypeId: AccountTypes.JointTenantsWithRightsOfSurvivorshipId.valueOf(),
        name: AccountTypes.JointTenantsWithRightsOfSurvivorshipName.valueOf(),
        additionalWipSections: [
            {
                id: WipSections.PowerAttorney,
                changeState: WipChangeState.Incomplete
            }
        ],
        wipSectionSettings: [
            {
                sectionId: WipSections.AccountOwner,
                displayUploadDocuments: true
            },
            {
                sectionId: WipSections.AccountDetails,
                showInvestorTaxRateSection: true
            }
        ]
    },
    {
        accountTypeId: AccountTypes.RothIraId.valueOf(),
        name: AccountTypes.RothIraName.valueOf(),
        additionalWipSections: [
            {
                id: WipSections.PowerAttorney,
                changeState: WipChangeState.Incomplete
            }
        ],
        wipSectionSettings: [
            {
                sectionId: WipSections.AccountOwner,
                displayMaritalStatus: true
            },
            {
                sectionId: WipSections.AccountDetails,
                showInvestorTaxRateSection: false
            }
        ]
    },
    {
        accountTypeId: AccountTypes.SepIraId.valueOf(),
        name: AccountTypes.SepIraName.valueOf(),
        additionalWipSections: [
            {
                id: WipSections.PowerAttorney,
                changeState: WipChangeState.Incomplete
            }
        ],
        wipSectionSettings: [
            {
                sectionId: WipSections.AccountOwner,
                displayMaritalStatus: true
            },
            {
                sectionId: WipSections.AccountDetails,
                showInvestorTaxRateSection: false
            }
        ]
    },
    {
        accountTypeId: AccountTypes.SimpleIraId.valueOf(),
        name: AccountTypes.SimpleIraName.valueOf(),
        additionalWipSections: [
            {
                id: WipSections.PowerAttorney,
                changeState: WipChangeState.Incomplete
            }
        ],
        wipSectionSettings: [
            {
                sectionId: WipSections.AccountOwner,
                displayMaritalStatus: true
            },
            {
                sectionId: WipSections.AccountDetails,
                showInvestorTaxRateSection: false
            }
        ]
    },
    {
        accountTypeId: AccountTypes.PartnershipId.valueOf(),
        name: AccountTypes.PartnershipName.valueOf(),
        additionalWipSections: [],
        wipSectionSettings: [
            {
                sectionId: WipSections.AccountOwner,
                displayUploadDocuments: true
            },
            {
                sectionId: WipSections.AccountDetails,
                showInvestorTaxRateSection: true
            }
        ]
    },
    {
        accountTypeId: AccountTypes.PrivateCorporationId.valueOf(),
        name: AccountTypes.PrivateCorporationName.valueOf(),
        additionalWipSections: [],
        wipSectionSettings: [
            {
                sectionId: WipSections.AccountOwner,
                displayUploadDocuments: true
            },
            {
                sectionId: WipSections.AccountDetails,
                showInvestorTaxRateSection: true
            }
        ]
    },
    {
        accountTypeId: AccountTypes.TenantsByEntiretyId.valueOf(),
        name: AccountTypes.TenantsByEntiretyName.valueOf(),
        additionalWipSections: [
            {
                id: WipSections.PowerAttorney,
                changeState: WipChangeState.Incomplete
            }
        ],
        wipSectionSettings: [
            {
                sectionId: WipSections.AccountDetails,
                showInvestorTaxRateSection: true
            }
        ]
    },
    {
        accountTypeId: AccountTypes.TenantsInCommonId.valueOf(),
        name: AccountTypes.TenantsInCommonName.valueOf(),
        additionalWipSections: [
            {
                id: WipSections.PowerAttorney,
                changeState: WipChangeState.Incomplete
            }
        ],
        wipSectionSettings: [
            {
                sectionId: WipSections.AccountOwner,
                displayUploadDocuments: true
            },
            {
                sectionId: WipSections.AccountDetails,
                showInvestorTaxRateSection: true
            }
        ]
    },
    {
        accountTypeId: AccountTypes.TaxExemptNonProfitId.valueOf(),
        name: AccountTypes.TaxExemptNonProfitName.valueOf(),
        additionalWipSections: [],
        wipSectionSettings: [
            {
                sectionId: WipSections.AccountOwner,
                displayUploadDocuments: true
            },
            {
                sectionId: WipSections.AccountDetails,
                showInvestorTaxRateSection: true
            }
        ]
    },
    {
        accountTypeId: AccountTypes.TrustId.valueOf(),
        name: AccountTypes.TrustName.valueOf(),
        additionalWipSections: [],
        wipSectionSettings: [
            {
                sectionId: WipSections.AccountOwner,
                displayUploadDocuments: true
            },
            {
                sectionId: WipSections.AccountDetails,
                showInvestorTaxRateSection: true
            }
        ]
    }
];
