/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
import { SpinnerService } from '@CarServices/system/spinner.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { EntityType, FirmAdvisorDisplayMode, FirmAdvisorLabel } from '../../model/enums';
import { AdvisorFactory } from '../../model/factory/advisor-factory';
import { Advisor } from '../../model/proposal';
import { Firm, FirmAdvisorResult, OfficeLocation, UserProfile } from '../../model/user-profile';
import { DataSharingService } from '../../service/data-sharing.service';
import { FirmAdvisorService } from '../../service/firm-advisor.service';

@Component({
    selector: 'sei-car-select-firm-advisor',
    templateUrl: './select-firm-advisor.component.html'
})
export class SelectFirmAdvisorComponent extends ParentComponentSubscriptionManager implements OnInit {

    @Input()
    public firm: Firm;

    @Input()
    public entitledAdvisorsEntityIds: number[];

    @Input()
    public firmAdvisorLabel: string;

    @Input()
    public displayMode: string;

    @Input()
    public clearForm: boolean = false;

    @Output()
    private firmAdvisorSelect = new EventEmitter<Advisor[]>();

    @Output()
    private firmSelect = new EventEmitter<Firm>();

    @Output()
    private firmOfficeSelect = new EventEmitter<OfficeLocation>();

    @Output()
    private advisorsUpdated = new EventEmitter<string[]>();

    @Output()
    public firmCleared = new EventEmitter();

    @Output()
    public onError = new EventEmitter();


    public elasticSearchError: string;
    public firmAdvisor: Firm;
    public firmAdvisorType: string;
    public selectedOffice: OfficeLocation;
    public selectedAdvisorIds: string[];
    public officesArray: Array<{ value: number; label: string }> = [];
    public advisorsArray: Advisor[];
    public firmsArray: Array<{ value: number; label: string }> = [];
    public displayModes: typeof FirmAdvisorDisplayMode = FirmAdvisorDisplayMode;
    public entityTypes: typeof EntityType = EntityType;
    public firmId: number;
    public defaultAdvisorId?: number;

    constructor(
        private firmAdvisorService: FirmAdvisorService,
        private spinnerService: SpinnerService,
        private dataSharingService: DataSharingService
    ) {
        super('SelectFirmAdvisorComponent');
    }

    ngOnInit(): void {
        if (!this.firmAdvisorLabel) {
            this.firmAdvisorLabel = FirmAdvisorLabel.FirmOrAdvisor;
        }
    }

    public firmSelected(firmAdvisor: FirmAdvisorResult): void {
        if (firmAdvisor !== undefined) {
            this.spinnerService.start();
            this.officesArray = [];
            this.advisorsArray = [];
            this.firmAdvisorType = firmAdvisor.type;
            if (firmAdvisor.type === EntityType.Firm) {
                this.dataSharingService.imageUpload.next(undefined);
                this.firmAdvisorService
                    .getFirmAdvisorsByFirmId(firmAdvisor.id)
                    .subscribe(
                        (firm: Firm) => {
                            this.firmId = firm.firmId;
                            this.firmAdvisor = firm
                                ? firm
                                : new AdvisorFactory().createFirmAdvisor(
                                    firmAdvisor.id,
                                    firmAdvisor.name
                                );
                            this.setOfficesArray();
                            // NOTE: If we come from Proposal MRDC as user that can impersonate and we didn't do the Impersonate settings.
                            // We just went directly to 'Start New Proposal' button we have to set the Primary Advisor
                            if (this.displayMode === FirmAdvisorDisplayMode.Proposal && this.entitledAdvisorsEntityIds.length === 0) {
                                const primaryAdvisor = firm.advisors.find((advisor) => advisor.entityId !== undefined);
                                this.entitledAdvisorsEntityIds.push(
                                    primaryAdvisor.entityId
                                );
                                this.advisorsUpdated.emit([primaryAdvisor.entityId.toString()]);
                                this.firmAdvisor.primaryAdvisor = primaryAdvisor;

                            }

                            this.firmSelect.emit(this.firmAdvisor);
                            if (this.entitledAdvisorsEntityIds.length === 0) {
                                this.setAdvisorsArray();

                            } else {

                                this.entitledAdvisorsEntityIds.forEach(
                                    (selectedAdvisorId) => {
                                        const advisor = firm.advisors.find(
                                            (fa) => {
                                                return (
                                                    fa.entityId ===
                                                    selectedAdvisorId
                                                );
                                            }
                                        );
                                        this.advisorsArray.push(
                                            new AdvisorFactory().createAdvisor(
                                                advisor.advisorId,
                                                advisor.entityId,
                                                advisor.advisorName
                                            )
                                        );
                                        this.firmAdvisorSelect.emit(this.advisorsArray);
                                    }
                                );
                            }

                            this.spinnerService.stop();
                        },
                        (error) => {
                            this.spinnerService.stop();
                            this.onError.emit();
                        }
                    );
            } else if (firmAdvisor.type === EntityType.Advisor) {
                this.firmAdvisorService
                    .getFirmDataByEntityId(firmAdvisor.id)
                    .subscribe(
                        (userProfile: UserProfile) => {
                            this.firmId = userProfile.firm.firmId;
                            this.firmAdvisor = userProfile.firm;
                            const searchedAdvisor = userProfile.firm.advisors.find(
                                (a) => a.entityId === userProfile.entityId
                            );
                            this.defaultAdvisorId = searchedAdvisor.entityId;

                            const selectedAdvisor = new AdvisorFactory().createAdvisor(
                                searchedAdvisor.advisorId,
                                searchedAdvisor.entityId,
                                searchedAdvisor.advisorName
                            );
                            this.advisorsArray.push(selectedAdvisor);
                            this.setOfficesArray();
                            this.firmSelect.emit(this.firmAdvisor);
                            this.spinnerService.stop();
                        },
                        (error) => {
                            this.spinnerService.stop();
                            this.onError.emit();
                        }
                    );
            }
        }
    }

    public setOfficesArray() {
        this.firmAdvisor.locations.map((office: OfficeLocation) => {
            this.officesArray.push({
                value: office.officeLocationId,
                label: office.officeName
            });
        });
    }

    public setAdvisorsArray() {
        this.advisorsArray.push(new AdvisorFactory().createAdvisor());
    }

    public officeSelected(event): void {
        this.selectedOffice = this.firmAdvisor.locations.find((office: OfficeLocation) => office.officeLocationId === Number(event));
        this.firmOfficeSelect.emit(this.selectedOffice);
        // this.locationsUpdated.emit(this.firmAdvisor.locations);
    }

    public advisorDropdownUpdated(event: string[]): void {
        this.advisorsUpdated.emit(event);
    }

    public advisorDropdownDeleted(event: string[]): void {
        this.advisorsUpdated.emit(event);
    }

    public onAddAdvisorClick(): void {
        this.setAdvisorsArray();
        this.firmAdvisorSelect.emit(this.advisorsArray);
    }

    public onGoToDashboardClick(): void {
        this.firmSelect.emit(this.firmAdvisor);
        this.firmAdvisorSelect.emit(this.advisorsArray);
    }

    public newSearch() {
        this.firmAdvisor = undefined;
        this.advisorsArray = [];
        this.entitledAdvisorsEntityIds = [];
        this.firmCleared.emit();
    }
}
