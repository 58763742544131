/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DisplayNullOptions, DropdownItem, EditAmountOptions } from '@sei/common-components-lib-ux';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { OrderedSet } from 'immutable';
import * as _ from 'lodash';
import { AlertPopoverTypes, CurrencyTypes, DefaultSelection, GoalSelectionOptions, ToolTipLabels } from '../../model/enums';
import { ErrorMessages } from '../../model/error-messages';
import { ToolTipOptionsFactory } from '../../model/factory/tooltip-options-factory';
import { InfoMessages } from '../../model/info-messages';
import { GoalCatalogs, GoalDetail, Type } from '../../model/proposal';
import { ToolTipOptions } from '../../model/tooltip-options';
import { GoalService } from '../../service/goal.service';

@Component({
    selector: 'sei-car-edit-goal-ribbon',
    templateUrl: './edit-goal-ribbon.component.html',
    styleUrls: ['./edit-goal-ribbon.component.scss']
})
export class EditGoalRibbonComponent extends ParentComponentSubscriptionManager implements OnInit {

    @Input()
    public goal: GoalDetail;

    @Input()
    public goalCatalogs: GoalCatalogs;

    @Input()
    set index(value: number) {
        this._index = value;
        this.titleText = `Goal ${this._index + 1}`;
    }

    get index(): number {
        return this._index;
    }

    @Output()
    public removeGoal = new EventEmitter();

    @Output()
    public isFormValid = new EventEmitter();

    public editModeOn: boolean = false;
    public toolTipOptions: ToolTipOptions[] = [];
    public dateInfoText: string;
    public minDateAllowed: Date = new Date(2000, 6, 1);
    public amountOptions: EditAmountOptions;
    public balanceErrorMessage: string;
    public nameDuplicatedErrorMessage: string;
    public endValueIsValid: boolean = true;
    public isNameValid: boolean = true;
    public isNameOverMaximumLength: boolean = false;
    public isNewGoal: boolean = true;
    public currencyTypes: typeof CurrencyTypes = CurrencyTypes;
    public alertPopoverTypes: typeof AlertPopoverTypes = AlertPopoverTypes;
    public defaultSelection: typeof DefaultSelection = DefaultSelection;
    public goalSelectionOptions: typeof GoalSelectionOptions = GoalSelectionOptions;
    public selectedOptionValues: GoalCatalogs;
    public titleText: string;

    public readonly today: Date = new Date();
    public tomorrow: Date = new Date();

    private _index: number;
    private readonly NAME_MAX_LENGTH: number = 65;

    constructor(
        private readonly goalService: GoalService
    ) {
        super('EditGoalRibbonComponent');
        this.tomorrow.setDate(this.today.getDate() + 1);
    }

    public ngOnInit(): void {

        this.initializeComponent();
    }

    public onGoalNameChange(name: string) {
        this.isNameOverMaximumLength = name?.length > this.NAME_MAX_LENGTH;
        this.goal.name = name;
        this.validateForm();
    }

    private initializeComponent(): void {
        this.amountOptions = {
            minValueAllowed: '0',
            nullDisplay: DisplayNullOptions.ZERO
        };

        this.toolTipOptions = [
            new ToolTipOptionsFactory().createOption(
                ToolTipLabels.EditGoal
            ),
            new ToolTipOptionsFactory().createOption(
                ToolTipLabels.RemoveGoal
            )
        ];

        this.editModeOn = !this.goal.name;
        this.isNewGoal = this.editModeOn;

        this.dateInfoText = InfoMessages.goalDateSelection();

        this.balanceErrorMessage = ErrorMessages.clientDisplayInvalidTierAmount();
        this.nameDuplicatedErrorMessage = ErrorMessages.goalDisplayDuplicatedOrEmpty();

        if (!this.goal.id || !this.goal.startDate || !this.goal.endDate) {
            this.goal.startDate = new Date();
            this.goal.endDate = _.cloneDeep(this.tomorrow);
        }

        this.goal.startDate = this.setDateValue(this.goal.startDate);
        this.goal.endDate = this.setDateValue(this.goal.endDate);

        this.goal.priority = this.formatGoalSection(this.goal.priority);
        this.goal.frequency = this.formatGoalSection(this.goal.frequency);
        this.goal.goalType = this.formatGoalSection(this.goal.goalType);

        this.selectedOptionValues = {
            priorities: OrderedSet<DropdownItem>(this.getDefaultValue(this.goal.priority)),
            frequencies: OrderedSet<DropdownItem>(this.getDefaultValue(this.goal.frequency)),
            types: OrderedSet<DropdownItem>(this.getDefaultValue(this.goal.goalType))
        };

        this.isNameOverMaximumLength = this.goal?.name?.length > this.NAME_MAX_LENGTH;
    }

    private setDateValue(date: Date | string): Date {
        if (typeof date === 'string') {
            return new Date(date);
        }
        return date;
    }

    private formatGoalSection(type: Type): Type {
        if (!type || !type.id) {
            type = {
                id: undefined,
                description: ''
            };
        }
        return type;
    }

    private getDefaultValue(type: Type): DropdownItem[] {
        const dropdownList: DropdownItem[] = [];
        dropdownList.push(new DropdownItem(type.id, type.description));
        return dropdownList;
    }

    public validateName(): void {
        if (!this.isGoalNameValid()) {
            this.goal.isValid = false;
            this.isNameValid = false;
            this.isFormValid.emit(this.goal.isValid);
        } else {
            this.isNameValid = true;
            this.validateForm();
        }
    }

    private isGoalNameValid(): boolean {
        return !!this.goal.name.trim() && !this.goalService.isNameDuplicated();
    }

    public validateForm(): void {
        this.goal.isValid = this.goalService.isThisGoalValid(this.goal) && !this.isNameOverMaximumLength;
        this.isFormValid.emit(this.goal.isValid);
    }

    public onDropDownChange(event: OrderedSet<DropdownItem>, dropdownSelected: GoalSelectionOptions): void {

        const selectedId: number = +event.first().id !== 0
            ? +event.first().id
            : undefined;

        const selectedDescription: string = selectedId
            ? event.first().name
            : '';

        switch (dropdownSelected) {
            case GoalSelectionOptions.GoalType:
                this.goal.goalType.id = selectedId;
                this.goal.goalType.description = selectedDescription;
                break;
            case GoalSelectionOptions.Priority:
                this.goal.priority.id = selectedId;
                this.goal.priority.description = selectedDescription;
                break;
            case GoalSelectionOptions.Frequency:
                this.goal.frequency.id = selectedId;
                this.goal.frequency.description = selectedDescription;
                break;
            default:
                break;
        }
        this.validateForm();
    }

    public onStartDateChange(startDate: Date): void {
        this.goal.startDate = startDate;
        this.validateForm();
    }

    public onEndDateChange(endDate: Date): void {
        this.goal.endDate = endDate;
        this.validateForm();
    }

    public onEndValueChange(endValue: number): void {
        this.goal.endValue = endValue;
        this.validateForm();
    }

    public onEditClick(): void {
        this.editModeOn = true;
    }

    public onOptionEllipsisSelected(event: ToolTipLabels): void {
        switch (event) {
            case ToolTipLabels.EditGoal:
                this.editModeOn = true;
                break;
            case ToolTipLabels.RemoveGoal:
                this.removeGoal.emit(this.goal);
                break;
            default:
                break;
        }
    }

    public onEditConfirmClick(): void {
        if (this.isGoalNameValid()) {
            this.editModeOn = false;
            this.isNewGoal = false;
            this.isNameValid = true;
            this.validateForm();
        } else {
            this.isNameValid = false;
        }
    }
    public onEndValueAmountValidation(): void {
        this.endValueIsValid = this.goal.endValue > Number(this.amountOptions.minValueAllowed);
        if (!this.endValueIsValid) {
            this.goal.isValid = false;
            this.isFormValid.emit(this.goal.isValid);
        } else {
            this.validateForm();
        }
    }

    public validateMinimumEndDate(): void {
        if (this.goal.startDate > this.goal.endDate) {
            this.goal.endDate = this.goal.startDate;
        }
    }

    public fireRemoveEvent(): void {
        this.removeGoal.emit(this.goal);
    }
}
