/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { SpinnerService } from '@CarServices/system/spinner.service';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import * as _ from 'lodash';
import { AdvisorSelected } from '../../model/advisor-selected';
import { AccountAdvisorsDropdownLabels, advisorLabel, AdvisorRole, UserLevelEntitlement } from '../../model/enums';
import { AdvisorFactory } from '../../model/factory/advisor-factory';
import { Advisor } from '../../model/proposal';
import { UserProfile } from '../../model/user-profile';
import { FirmAdvisorService } from '../../service/firm-advisor.service';
import { UserProfileService } from '../../service/user-profile.service';

@Component({
    selector: 'sei-car-account-advisors-dropdown',
    templateUrl: './account-advisors-dropdown.component.html'
})

export class AccountAdvisorsDropdownComponent extends ParentComponentSubscriptionManager implements OnInit, OnDestroy {
    @Input()
    public currentAdvisors: Advisor[];
    @Input()
    public firmAdvisorMode: boolean;
    @Input()
    public showMore?: boolean = false;
    @Input()
    public defaultAdvisorId?: number;
    @Input()
    public firmId: number;
    @Output()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public accountAdvisorDropdownSelected = new EventEmitter<any>();
    @Output()
    public accountAdvisorDropdownDeleted = new EventEmitter();

    public advisors: Advisor[] = [];
    public selectedAdvisor: Advisor;

    public labels = AccountAdvisorsDropdownLabels;
    public selectedAdvisorIds: string[] = [];
    public advisorUpdated: AdvisorSelected;
    private userProfile: UserProfile;
    private readonly primaryAdvisorIndex: number = 0;

    constructor(
        private userProfileService: UserProfileService,
        private firmAdvisorService: FirmAdvisorService,
        private spinnerService: SpinnerService
    ) {
        super('AccountAdvisorsDropdownComponent');
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.initComponent();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    private initComponent() {
        this.userProfile = this.userProfileService.getCurrentUserProfile;

        this.currentAdvisors.forEach((advisor) => {
            if (advisor.entityId) {
                this.selectedAdvisorIds.push(advisor.entityId.toString());
            }
        });
        this.spinnerService.start();
        if (this.userProfile && this.userProfile.entitlements
            && this.userProfile.entitlements.userLevelId === UserLevelEntitlement.PO
            || this.userProfile.entitlements.userLevelId === UserLevelEntitlement.Instance) {

            // Note: this is for instance/sales users and using the firm attached to it to retrieve advisors
            this.firmAdvisorService.getFirmAdvisorsByFirmIdAndUserId(this.userProfile.firm.swpFirmId, this.userProfile.entityId)
                .subscribe((advisors: Advisor[]) => {
                    if (advisors) {
                        const firmAdvisors = this.userProfile.firm.advisors;
                        const entitledAdvisorsInFirmAdvisors = _.intersectionBy(firmAdvisors, advisors, 'entityId');
                        entitledAdvisorsInFirmAdvisors.map(
                            (advisor: Advisor) => {
                                if (
                                    !this.advisors.some((currentAdvisor) => currentAdvisor.entityId === advisor.entityId)
                                ) {
                                    this.advisors.push(
                                        new AdvisorFactory().createAdvisor(advisor.userId, advisor.entityId, advisor.advisorName)
                                    );
                                }
                            }
                        );
                    }
                    this.advisors.sort((a, b) => a.name.localeCompare(b.name));
                    this.spinnerService.stop();
                });
        } else {
            this.firmAdvisorService.getFirmDataByEntityId(this.userProfile.entityId).subscribe((userProfile: UserProfile) => {
                if (userProfile) {
                    // Note: Need to take entitled advisors and take only the one that have advisor id in firm advisors.
                    const entitledAdvisors = userProfile.entitlements.advisors;
                    const firmAdvisors = userProfile.firm.advisors;

                    if (entitledAdvisors && firmAdvisors) {
                        const entitledAdvisorsInFirmAdvisors = _.intersectionBy(firmAdvisors, entitledAdvisors, 'entityId');
                        this.advisors = entitledAdvisorsInFirmAdvisors;
                    }
                }
                this.spinnerService.stop();
            });
        }
    }

    public deleteAdvisor(index): void {
        this.currentAdvisors.splice(index, 1);
        this.selectedAdvisorIds.splice(index, 1);
        this.accountAdvisorDropdownDeleted.emit(this.selectedAdvisorIds);
    }

    public onAdvisorSelection(event, index: number): void {
        if (event) {
            const selectedAdvisor = this.advisors.find((advisor) => +advisor.entityId === +event);
            if (selectedAdvisor) {
                const selectedId = event;
                const advisorUpdate = this.currentAdvisors[index];
                advisorUpdate.id = selectedAdvisor.id ? selectedAdvisor.id : selectedAdvisor.userId;
                advisorUpdate.entityId = selectedAdvisor.entityId;
                advisorUpdate.advisorName = selectedAdvisor.advisorName;
                selectedAdvisor.hasAdvisorsAvailable = (this.advisors.length > this.selectedAdvisorIds.length);

                // Note: The first advisor is the Primary for the Proposed Account...and Additional for any other ones.
                if (index === 0) {
                    advisorUpdate.role = {
                        id: AdvisorRole.Primary,
                        description: advisorLabel.get(AdvisorRole.Primary)
                    };
                } else {
                    advisorUpdate.role = {
                        id: AdvisorRole.Additional,
                        description: advisorLabel.get(AdvisorRole.Additional)
                    };
                }


                if (this.selectedAdvisorIds.indexOf(selectedId.toString()) < 0) {
                    this.selectedAdvisorIds.push(selectedId.toString());
                }
                if (this.firmAdvisorMode) {
                    this.accountAdvisorDropdownSelected.emit(this.selectedAdvisorIds);
                } else {
                    this.accountAdvisorDropdownSelected.emit(selectedAdvisor);
                }
            } else {
                this.accountAdvisorDropdownSelected.emit(event);
            }
        }
    }

    public shouldHideAdvisorOption(advisorId: number): boolean {
        return !!this.currentAdvisors.find((advisor) => +advisor.entityId === advisorId);
    }

    trackByIndex(index): void {
        return index;
    }

    public getAdvisorValue(index: number): number {
        if (this.advisors && this.advisors.length > 0) {
            if (this.defaultAdvisorId === undefined) {
                if (this.currentAdvisors.filter((advisor) => advisor.entityId !== undefined).length === 0) {
                    this.onAdvisorSelection(this.advisors[index].entityId, index);
                    return this.advisors[index].entityId;
                } else {
                    this.onAdvisorSelection(this.currentAdvisors[index].entityId, index);
                    return this.currentAdvisors[index].entityId;
                }
            } else {
                this.onAdvisorSelection(this.defaultAdvisorId, index);
                return this.defaultAdvisorId;
            }
        }
    }
}
