
/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Output } from '@angular/core';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { FeeAccount } from '../../model/account-fee';

@Component({
    selector: 'sei-car-fee-schedule-drop-container',
    templateUrl: './fee-schedule-drop-container.component.html'
})
export class FeeScheduleDropContainerComponent extends ParentComponentSubscriptionManager {

    public accountsSelected: FeeAccount[] = [];

    @Output()
    public onAccountDropped: EventEmitter<FeeAccount> = new EventEmitter();

    constructor() {
        super('FeeScheduleDropContainerComponent');
    }

    public drop(event: CdkDragDrop<FeeAccount[]>): void {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex);
        }

        this.onAccountDropped.emit(event.container.data[0]);
        this.accountsSelected = [];
    }
}
