/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { Fees } from '../proposal';
import { BaseFactoryAbstract } from './base-factory-abstract';

@Injectable()
export class FeesFactory extends BaseFactoryAbstract<Fees> {

    constructor() {
        super();
    }

    public createFees(
        advisorFeeAmount: number,
        managementExpenseAmount: number,
        dfsFee: number,
        seiFundExpenseAmount: number,
        nonSeiFundExpenseAmount: number,
        advisorPlatformFeeAmount: number,
        clientPlatformFeeAmount: number,
        platformFeeBasisPoints?: number
    ): Fees {
        return {
            advisorFeeAmount,
            managementExpenseAmount,
            dfsFee,
            seiFundExpenseAmount,
            nonSeiFundExpenseAmount,
            advisorPlatformFeeAmount,
            clientPlatformFeeAmount,
            platformFeeBasisPoints
        };
    }
}

