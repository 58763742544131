/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, Input, OnInit } from '@angular/core';
import { GlobalService } from '../../service/system/global.service';

@Component({
    selector: 'sei-car-alt-source-image',
    templateUrl: './alt-source-image.component.html'
})
export class AltSourceImageComponent implements OnInit {
    @Input()
    public className: string;
    @Input()
    public fileName: string;

    public imageSource: string = '';
    public reverseProxyPath: string;

    constructor(
        protected carGlobal: GlobalService
    ) { }

    public ngOnInit(): void {
        this.reverseProxyPath = this.carGlobal.configService.environment.proxyGroup;
        if (this.fileName) {
            this.imageSource = this.getImagePath();
        }
    }

    private getImagePath(): string {
        return `./assets/guide/images/${this.fileName}`;
    }
}
