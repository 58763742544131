<div *ngIf="checked; then isChecked else notChecked"></div>

<ng-template #notChecked>
    <div class="row pick-list-item" (click)="onChange()" containerClass="action-tooltip-popover" placement="bottom"
        container="body" [outsideClick]="true" [popover]="warning_popopver" #pop="bs-popover">
        <div class="col-xs-12">
            {{ pickListItemText }}
        </div>
    </div>

    <ng-template #warning_popopver>
        <div class="delete-tooltip-panel">
            <div class="row">
                <div class="col-xs-12">
                    <h3> Invalid Account Type </h3>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 car-main-sm-body-copy form-group delete-text">
                    This account is restricted by some of your primary owners
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 car-main-sm-body-copy form-group">
                    <sei-button class="btn-in-tooltip single-button" buttonText="Okay"
                        buttonRightFontAwesomeIcon="fas fa-arrow-circle-right" buttonClass="btn-default"
                        (buttonClicked)="pop.hide()">
                    </sei-button>
                </div>
            </div>
        </div>
    </ng-template>
</ng-template>

<ng-template #isChecked>
    <div class="row pick-list-item selected">
        <div class="col-xs-8">
            {{ pickListItemText }}
        </div>
        <div class="col-xs-4">
            <a *ngIf="!disabledChangeOption" class="pull-right text-danger" (click)="onChange()">Change</a>
        </div>
    </div>

    <div class="row">
        <div class="col-xs-12">
            <div class="form-group">
                <car-proposal-account-owner [proposal]="proposal" [accountTypeId]="id" [accountOwnerData]="accountOwner"
                    [defaultOwnerId]="defaultAdvisorEntityId" [accountData]="accountData"></car-proposal-account-owner>
            </div>
        </div>
    </div>
</ng-template>