/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { SpinnerService } from '@CarServices/system/spinner.service';
import { Component, Input, OnInit } from '@angular/core';
import { DropdownItem } from '@sei/common-components-lib-ux';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { OrderedSet } from 'immutable';
import * as _ from 'lodash';
import { forkJoin } from 'rxjs';
import { CommandText } from '../../model/enums';
import { Account } from '../../model/proposal';
import { CompletedRiskAccounts, QuestionAnswer, Questionnaire } from '../../model/questionnaire';
import { DelegationService } from '../../service/delegation.service';
import { QuestionnaireService } from '../../service/questionnaire.service';

@Component({
    selector: 'sei-car-questionnaire-main',
    templateUrl: './questionnaire-main.component.html'
})

export class QuestionnaireMainComponent extends ParentComponentSubscriptionManager implements OnInit {
    @Input() public account: Account;

    public selectedAnswers: QuestionAnswer[];
    public accountToCopyId: number;
    public showCopyPopover: boolean = false;
    public showCopyOption: boolean = false;
    public completedAccounts: OrderedSet<DropdownItem> = OrderedSet<DropdownItem>();
    public questionnaire: Questionnaire[];
    public accountToCopy: OrderedSet<DropdownItem>;
    private templateQuestionnaire: Questionnaire[];

    public constructor(
        private questionnaireService: QuestionnaireService,
        private delegationService: DelegationService,
        private spinnerService: SpinnerService
    ) {
        super('QuestionnaireMainComponent');
    }

    public ngOnInit(): void {
        this.getQuestionnaire();
        this.getCompletedAccounts();

        this.questionnaireService.getQuestionSelectedUpdated().subscribe(() => {
            if (
                this.questionnaireService.isQuestionnaireComplete() ||
                this.questionnaireService.isQuestionnaireInProgress()
            ) {
                this.account.riskTolerance.fillQuestionnaire = this.questionnaireService.isQuestionnaireComplete();
                this.account.riskTolerance.calculatedQuestionnaireRisk = this.questionnaireService.getScore();
                this.account.riskTolerance.questionnaire = this.questionnaireService.getQuestionnaireChoices();
                this.delegationService.publish(CommandText.ChangeRiskCapacity);
            }
        });
    }


    private initializeComponent(): void {
        this.questionnaire = this.templateQuestionnaire;
        this.questionnaireService.getNextQuestionnaire(this.questionnaire);
    }

    public getQuestionnaire(): void {
        this.spinnerService.start();
        this.questionnaireService.resetQuestionnaire();
        if (this.account.isNewAccount) {
            this.subscriptions.push(
                this.questionnaireService.getQuestionnaireByAccountGroupType(this.account.type.groupType.groupTypeId).subscribe({
                    next: (result: Questionnaire[]) => {
                        if (result) {
                            result = result.sort((a: Questionnaire, b: Questionnaire) => a.questionId - b.questionId);
                            this.templateQuestionnaire = result;
                            this.initializeComponent();
                            this.questionnaireService.setAnsweredQuestions(this.account.riskTolerance.questionnaire, result);
                        }
                        this.spinnerService.stop();
                    },
                    error: (err) => {
                        this.spinnerService.stop();
                    },
                    complete: () => {
                        this.spinnerService.stop();
                    }
                }));
        } else if (this.account.isAccountTypeChanged) {
            const questionnaireServicesStream = forkJoin(
                {
                    groupTypeQuestionnaire: this.questionnaireService
                        .getQuestionnaireByAccountGroupType(this.account.type.groupType.groupTypeId),
                    answeredQuestionnaire: this.questionnaireService.getQuestionnaire(this.account.id)
                }
            );
            this.subscriptions.push(questionnaireServicesStream.
                subscribe((questionnaireResponses: { groupTypeQuestionnaire: Questionnaire[]; answeredQuestionnaire: Questionnaire[] }) => {
                    const groupTypeQuestionnaire = questionnaireResponses.groupTypeQuestionnaire;
                    const answeredQuestionnaire = questionnaireResponses.answeredQuestionnaire;
                    answeredQuestionnaire.forEach((answeredItem: Questionnaire) => {
                        const question: Questionnaire = groupTypeQuestionnaire.find((groupTypeQuestionnaireItem: Questionnaire) =>
                            answeredItem.questionId === groupTypeQuestionnaireItem.questionId);
                        if (question) {
                            question.investorChoices = answeredItem.investorChoices;
                        }
                    });
                    this.templateQuestionnaire = groupTypeQuestionnaire;
                    this.initializeComponent();
                    this.questionnaireService.setAnsweredQuestions(this.account.riskTolerance.questionnaire, groupTypeQuestionnaire);
                    this.spinnerService.stop();
                }));

        } else {
            this.subscriptions.push(this.questionnaireService.getQuestionnaire(this.account.id).subscribe(
                (result: Questionnaire[]) => {
                    if (result) {
                        this.templateQuestionnaire = result;
                        this.initializeComponent();
                        this.questionnaireService.setAnsweredQuestions(this.account.riskTolerance.questionnaire, result);
                    }
                    this.spinnerService.stop();
                },
                (err) => {
                    this.spinnerService.stop();
                }
            ));
        }

    }

    public getCompletedAccounts(): void {
        this.spinnerService.start();
        if (!this.account.isNewAccount) {
            this.questionnaireService.getCompletedAccounts(this.account.id).subscribe(
                (result: CompletedRiskAccounts[]) => {
                    if (result.length > 0) {
                        this.showCopyOption = true;
                        this.completedAccounts = this.setCompletedAccounts(result);
                    } else {
                        this.showCopyOption = false;
                    }
                    this.spinnerService.stop();
                },
                (err) => {
                    this.spinnerService.stop();
                }
            );
        }

    }

    public onAccountToCopySelected(selectedAccountToCopy: OrderedSet<DropdownItem>): void {
        this.accountToCopyId = Number(selectedAccountToCopy.first().id);
    }

    public onCopyToggleClick(): void {
        this.accountToCopyId = 0;
        this.showCopyPopover = !this.showCopyPopover;
    }

    public onConfirmCopyClick(): void {
        this.showCopyPopover = false;
        this.spinnerService.start();
        this.questionnaireService.getRiskToleranceChoices(this.accountToCopyId).subscribe(
            (result: QuestionAnswer[]) => {
                if (result) {
                    this.questionnaire = this.questionnaireService.setAnsweredQuestions(result, this.getTemplateQuestionnaire());
                    this.questionnaireService.getQuestionSelectedUpdated();
                }
                this.spinnerService.stop();
            },
            (err) => {
                this.spinnerService.stop();
            }
        );
    }

    public getTemplateQuestionnaire(): Questionnaire[] {
        return _.cloneDeep(this.templateQuestionnaire);
    }

    private setCompletedAccounts(accounts: CompletedRiskAccounts[]): OrderedSet<DropdownItem> {
        let itemList: OrderedSet<DropdownItem> = OrderedSet<DropdownItem>();
        accounts.forEach((account) => {
            if (account.accountId !== this.account.id) {
                itemList = itemList.add(new DropdownItem(account.accountId, account.accountName));
            }
        });
        return itemList;
    }
}
