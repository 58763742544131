/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { RiskToleranceQuestionInsertImage } from '../../model/enums';
import { InvestorChoice, Questionnaire } from '../../model/questionnaire';

@Component({
    selector: 'sei-car-questionnaire-question-selection',
    templateUrl: './questionnaire-question-selection.component.html'
})
export class QuestionnaireQuestionSelectionComponent extends ParentComponentSubscriptionManager {
    @Input() public questionNumber: number;
    @Input() public question: Questionnaire;
    @Input() public accountType: number;
    @Output() public onAnswerChange = new EventEmitter<Questionnaire>();

    public questionEnum: typeof RiskToleranceQuestionInsertImage = RiskToleranceQuestionInsertImage;

    public get optionSelectedId() {
        if (this.question && this.question.investorChoices) {
            const choiceSelected = this.question.investorChoices.find(
                (option: InvestorChoice) => option.selected
            );

            return choiceSelected ? choiceSelected.choiceId : 0;
        }
        return 0;
    }

    public constructor() {
        super('QuestionnaireQuestionSelectionComponent');
    }

    public optionSelectedChange(optionId: number): void {
        if (this.optionSelectedId !== optionId) {
            this.question.investorChoices.forEach(
                (option: InvestorChoice) => (option.selected = false)
            );

            const optionSelected = this.question.investorChoices.find(
                (option: InvestorChoice) => option.choiceId === optionId
            );

            optionSelected.selected = true;
            this.onAnswerChange.emit(this.question);
        }
    }
}
