<div class="col-sm-4 text-center">
    <sei-button buttonText="Prev"
                buttonLeftFontAwesomeIcon="fas fa-arrow-left gray"
                buttonClass="btn-default"
                [buttonDisabled]="disablePrevButton"
                (buttonClicked)="goToPrev()">
    </sei-button>
    <sei-button buttonText="Next"
                buttonRightFontAwesomeIcon="fas fa-arrow-right gray"
                buttonClass="btn-default"
                [buttonDisabled]="disableNextButton"
                (buttonClicked)="goToNext()">
    </sei-button>
</div>
