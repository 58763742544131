/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
import { Component, OnInit } from '@angular/core';
import { AccountType, CommandText, DelegationService, Proposal, ProposalService, ReferenceDataService } from '@sei/advisor-client-review-proposal-ux';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { Observable } from 'rxjs';

@Component({
    selector: 'sei-car-scenario-edit',
    templateUrl: './scenario-edit.component.html'
})
export class ScenarioEditComponent extends ParentComponentSubscriptionManager
    implements OnInit {
    public proposalStream: Observable<Proposal>;
    public proposal: Proposal;
    public accountTypeData: AccountType[] = [];

    constructor(
        private proposalService: ProposalService,
        private referenceDataService: ReferenceDataService,
        private delegationService: DelegationService
    ) {
        super('ScenarioEditComponent');
    }

    public ngOnInit(): void {
        this.subscriptions.push(this.proposalService.currentProposal.subscribe((proposal) => {
            this.proposal = proposal;

            this.subscriptions.push(this.referenceDataService
                .getAccountTypes(this.proposal?.advisors[0]?.entityId || this.proposal.scenarios[0]?.accounts[0]?.advisors[0]?.entityId)
                .subscribe((accountTypes) => {
                    this.accountTypeData = accountTypes;

                    this.delegationService.publish(
                        CommandText.EnableModifyProposalScenario
                    );
                }));
        }));
    }
}
