/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { EntityDropdownEsResult } from '@sei/client-account-administration-lib-ux';
import { EntitySuffix } from '../contact';
import { ElasticSearchRelatedPartyResult } from '../elastic-search-related-party';
import { ContactTypes, ContactTypesDescription, EntityType, OwnerRole, OwnerRoleDescription } from '../enums';
import { AccountOwner, Client } from '../proposal';
import { BaseFactoryAbstract } from './base-factory-abstract';

@Injectable()
export class ClientFactory extends BaseFactoryAbstract<Client> {

    constructor() {
        super();
    }

    public clientFromElasticSearchRelatedPartyResult(clientEntity: ElasticSearchRelatedPartyResult): Client {
        return {
            id: undefined,
            firstName: clientEntity.attributes.partyName.firstName || '',
            middleInitial: '',
            organizationName: `${clientEntity.attributes.partyName.firstName || ''} ${clientEntity.attributes.partyName.lastName || ''}`,
            lastName: clientEntity.attributes.partyName.lastName || '',
            isIndividual: true,
            entitySuffix: {
                suffixId: null,
                suffixName: '',
                sortOrder: 0
            },
            contactType: {
                id: ContactTypes.Individual,
                description: ContactTypesDescription.Individual
            },
            isNewClient: true,
            entityType: EntityType.Prospect
        };
    }

    public clientFromEntityDropdownEsResult(clientEntity: EntityDropdownEsResult): Client {
        if (
            clientEntity &&
            clientEntity.party &&
            clientEntity.party.firstName
        ) {
            return {
                id: undefined, // Note: needs to be undefined, until BE is fixed
                swpEntityId: clientEntity.entity.entityId,
                entityId: clientEntity.entity.entityId,
                entityType: clientEntity.entity.searchType.type,
                firstName: clientEntity.party.firstName,
                middleInitial: clientEntity.party.middleName,
                organizationName: '',
                lastName: clientEntity.party.lastName,
                isIndividual: true,
                entitySuffix: {
                    suffixId: clientEntity.party.suffix
                        ? clientEntity.party.suffix.value
                        : 0,
                    suffixName: clientEntity.party.suffix
                        ? clientEntity.party.suffix.longDescription
                        : '',
                    sortOrder: 0
                },
                contactType: {
                    id: ContactTypes.Individual,
                    description: ContactTypesDescription.Individual
                },
                contactId: clientEntity.entity.sourceSystem === 'CAR' &&
                    clientEntity.party.id === clientEntity.entity.entityId &&
                    clientEntity.entity.searchType.type === 'Prospect' ? clientEntity.entity.entityId : undefined,
                isNewClient: false,
                externalVendor: clientEntity.entity.externalVendor,
                emailAddress: clientEntity?.emailAddress,
                phoneNumber: clientEntity?.phone
            };
        } else {
            return {
                id: undefined, // Note: needs to be undefined, until BE is fixed
                swpEntityId: clientEntity.entity.entityId,
                entityId: clientEntity.entity.entityId,
                entityType: clientEntity.entity.searchType.type,
                firstName: '',
                middleInitial: '',
                organizationName: clientEntity.name,
                lastName: clientEntity.name,
                isIndividual: false,
                entitySuffix: {},
                contactType: {
                    id: ContactTypes.Organization,
                    description: ContactTypesDescription.Organization
                },
                contactId: clientEntity.entity.sourceSystem === 'CAR' &&
                    clientEntity.party.id === clientEntity.entity.entityId &&
                    clientEntity.entity.searchType.type === 'Prospect' ? clientEntity.entity.entityId : undefined,
                isNewClient: false,
                externalVendor: clientEntity.entity.externalVendor
            };
        }
    }

    public createAccountOwner(identifier: number, labelName?: string, ownerFirstName?: string,
        ownerMiddleInitial?: string, ownerLastName?: string, ownerSuffix?: EntitySuffix): AccountOwner {
        return {
            id: identifier,
            name: labelName,
            ownerRole: {
                id: OwnerRole.Primary,
                description: OwnerRoleDescription.Primary
            },
            contactType: {
                id: ContactTypes.Individual,
                description: ContactTypesDescription.Individual
            },
            isIndividual: true,
            firstName: ownerFirstName,
            middleInitial: ownerMiddleInitial,
            lastName: ownerLastName,
            organizationName: '',
            entitySuffix: ownerSuffix,
            isNewClient: true
        };
    }

    public createAccountOwnerFromElasticSearch(client: Client, labelName: string): AccountOwner {
        return {
            id: client.id,
            entityId: client.entityId,
            entityType: client.entityType,
            name: labelName,
            ownerRole: {
                id: OwnerRole.Primary,
                description: OwnerRoleDescription.Primary
            },
            contactType: {
                id: ContactTypes.Individual,
                description: ContactTypesDescription.Individual
            },
            isIndividual: true,
            firstName: client.firstName,
            middleInitial: client.middleInitial,
            lastName: client.lastName,
            organizationName: '',
            entitySuffix: client.entitySuffix,
            isNewClient: false
        };
    }

    public clientFromAccountOwner(accountOwner: AccountOwner): Client {
        return {
            id: accountOwner.id,
            firstName: accountOwner.firstName || '',
            middleInitial: accountOwner.middleInitial || '',
            organizationName: accountOwner.organizationName,
            lastName: accountOwner.lastName || '',
            isIndividual: accountOwner.isIndividual,
            entitySuffix: accountOwner.entitySuffix,
            contactType: accountOwner.contactType,
            isNewClient: accountOwner.isNewClient,
            entityType: accountOwner.entityType,
            swpEntityId: accountOwner.entityId,
            entityId: accountOwner.entityId,
            labelName: accountOwner.name
        };
    }

    public createOrganizationAccountOwner(labelName: string, ownerLastName: string): AccountOwner {
        return {
            name: labelName,
            ownerRole: {
                id: OwnerRole.Primary,
                description: OwnerRoleDescription.Primary
            },
            contactType: {
                id: ContactTypes.Organization,
                description: ContactTypesDescription.Organization
            },
            isIndividual: false,
            firstName: '',
            middleInitial: '',
            lastName: ownerLastName,
            organizationName: ownerLastName,
            isNewClient: true
        };
    }
}
