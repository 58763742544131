/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpUtil } from '@sei/common-swp-components-lib-ux';
import { Observable, of } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { EntitySuffix } from '../model/contact';
import { AccountType } from '../model/proposal';
import { SeiPayload } from '../model/sei-payload';
import { GlobalService } from './system/global.service';

@Injectable({
    providedIn: 'root'
})
export class ReferenceDataService {

    accountTypes: AccountType[] = [];
    suffixes: EntitySuffix[] = [];

    constructor(private http: HttpClient,
        protected carGlobal: GlobalService,
        private httpUtil: HttpUtil) { }

    public getAccountTypes(advisorId: number): Observable<AccountType[]> {
        const apiUrl = this.carGlobal.configService.routeFormatter(
            this.carGlobal.configService.getEndPoint('AccountTypes'), [
            {
                key: 'advisorId',
                value: advisorId
            }
        ]);

        if (this.carGlobal.isMockDataMode()) {
            const mockURL: string = 'assets/mock-data/firms.json';
            const firmsMock = this.httpUtil.doMockPostStronglyTyped<SeiPayload>(mockURL, 1000);
            return firmsMock.pipe(
                share(),
                map((result) => {
                    if (result && result.data) {
                        const accountTypes: AccountType[] = [];
                        result.data[0].accountTypes.forEach((element) => {
                            accountTypes.push({
                                id: element.accountTypeId,
                                description: element.accountTypeDescription,
                                sortOrder: element.sortOrder,
                                taxQualified: element.taxQualified,
                                qualifiedPlan: element.qualifiedPlan,
                                investmentPrograms: element.investmentPrograms,
                                allowJointOwners: element.allowJointOwners,
                                nonTaxableStatus: element.nonTaxableStatus
                            });
                        });
                        return accountTypes;
                    }
                })
            );
        } else if (this.accountTypes.length === 0) {
            return this.http.get<SeiPayload>(apiUrl).pipe(
                share(),
                map((response) => {
                    if (response && response.data) {

                        response.data[0].accountTypes.forEach((element) => {
                            this.accountTypes.push({
                                id: element.accountTypeId,
                                description: element.accountTypeDescription,
                                sortOrder: element.sortOrder,
                                taxQualified: element.taxQualified,
                                qualifiedPlan: element.qualifiedPlan,
                                investmentPrograms: element.investmentPrograms,
                                allowJointOwners: element.allowJointOwners,
                                nonTaxableStatus: element.nonTaxableStatus
                            });
                        });
                        return this.accountTypes;
                    }
                })
            );
        } else {
            return of(this.accountTypes);
        }
    }

    public getData(endPoint: string): Observable<EntitySuffix[]> {
        const apiUrl = this.carGlobal.configService.routeFormatter(
            this.carGlobal.configService.getEndPoint(endPoint)
        );
        if (this.carGlobal.isMockDataMode()) {
            const mockURL: string = 'assets/mock-data/suffixes.json';
            return this.httpUtil.doMockPostStronglyTyped<SeiPayload>(mockURL, 1000).pipe(
                share(),
                map((response) => {
                    if (response && response.data) {
                        return response.data[0];
                    }
                })
            );
        } else {
            if (!this.suffixes || this.suffixes?.length === 0) {
                return this.http.get<SeiPayload>(apiUrl).pipe(
                    share(),
                    map((response) => {
                        if (response && response.data) {
                            this.suffixes = response?.data[0];
                            return response?.data[0];
                        }
                    })
                );
            } else {
                return of(this.suffixes);
            }

        }
    }
}
