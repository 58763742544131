/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FeatureFlagService } from '@CarServices/feature-flag/feature-flag.service';
import { HttpUtil } from '@sei/common-swp-components-lib-ux';
import { merge, Observable } from 'rxjs';
import { map, share, shareReplay } from 'rxjs/operators';
import { FeeAnalysis, HudSummary, ManagementFeeBreakdown, ScenarioAccountFee, ScenarioFeeSchedule } from '../model/account-fee';
import { HUDSummaryLabel, ScenarioFeeScheduleTypes } from '../model/enums';
import { Account } from '../model/proposal';
import { SeiPayload } from '../model/sei-payload';
import { ProposalAccountService } from './proposal-account.service';
import { GenericErrorService } from './system/generic-error.service';
import { GlobalService } from './system/global.service';

@Injectable({
    providedIn: 'root'
})
export class FeeScheduleGroupService {

    constructor(private http: HttpClient,
        private genericErrorService: GenericErrorService,
        private httpUtil: HttpUtil,
        private proposalAccountService: ProposalAccountService,
        private featureFlagService: FeatureFlagService,
        protected carGlobal: GlobalService) { }

    public calculateAdvisorFees(feeAnalysis: FeeAnalysis, proposalId: number, scenarioId: number) {
        const apiUrl = this.carGlobal.configService.routeFormatter(
            this.carGlobal.configService.getEndPoint('calculateAdvisorFee'),
            [
                {
                    key: 'proposalId',
                    value: proposalId
                },
                {
                    key: 'scenarioId',
                    value: scenarioId
                }
            ]
        );
        if (this.carGlobal.isMockDataMode()) {
            const mockURL: string = 'assets/mock-data/calculate-advisor-fee.json';
            return this.httpUtil.doMockPostStronglyTyped<HudSummary>(mockURL, 1000).pipe(
                map((result: HudSummary) => {
                    return this.setHudSummaryLabels(result);
                }));
        }

        return this.http.post(apiUrl, feeAnalysis).pipe(
            map((response: HudSummary) => {
                if (response) {
                    return this.setHudSummaryLabels(response);
                }
            }),
            share());
    }

    public saveFeeAnalysis(feeAnalysis: FeeAnalysis, proposalId: number, scenarioId: number): Observable<SeiPayload> {
        const apiUrl = this.carGlobal.configService.routeFormatter(
            this.carGlobal.configService.getEndPoint('postFeeAnalysis'),
            [
                {
                    key: 'proposalId',
                    value: proposalId
                },
                {
                    key: 'scenarioId',
                    value: scenarioId
                }
            ]
        );
        if (this.carGlobal.isMockDataMode()) {
            const mockURL: string = 'assets/mock-data/fee-analysis.json';
            return this.httpUtil.doMockPostStronglyTyped<SeiPayload>(mockURL, 1000);
        }
        return this.http.post(apiUrl, feeAnalysis).pipe(
            map((response: SeiPayload) => {
                if (response &&
                    response.data) {
                    if (response.error && response.error.length > 0) {
                        this.genericErrorService.setGenericError({
                            code: response.error.code,
                            description: response.error.message
                        });
                    }
                    return response;
                }
            }));
    }

    public getProposalFees(proposalId: number): Observable<ScenarioAccountFee> {
        const apiUrl = this.carGlobal.configService.routeFormatter(
            this.carGlobal.configService.getEndPoint('retrieveProposalFees'),
            [
                {
                    key: 'proposalId',
                    value: proposalId
                }
            ]
        );
        if (this.carGlobal.isMockDataMode()) {
            const mockURL: string = 'assets/mock-data/fees.json';
            return this.httpUtil.doMockPostStronglyTyped<ScenarioAccountFee>(mockURL, 1000).pipe(
                map((response: ScenarioAccountFee) => {
                    if (response) {
                        response.hudSummary = this.setHudSummaryLabels(response.hudSummary);
                        return response;
                    }
                }));
        }
        if (this.featureFlagService.isNonModelFeatureEnabled()) {
            return this.proposalAccountService.feesHudSummary.pipe(
                map((response: HudSummary) => {
                    if (response) {
                        response = this.setHudSummaryLabels(response);
                        return { hudSummary: response, feeAnalysis: undefined };
                    }
                }));
        } else {
            return this.http.get(apiUrl).pipe(
                map((response: ScenarioAccountFee) => {
                    if (response) {
                        response.hudSummary = this.setHudSummaryLabels(response.hudSummary);
                        return response;
                    }
                }));
        }

    }

    public getFeeAnalysis(proposalId: number, scenarioId: number) {
        const apiUrl = this.carGlobal.configService.routeFormatter(
            this.carGlobal.configService.getEndPoint('retrieveFeeAnalysis'),
            [
                {
                    key: 'proposalId',
                    value: proposalId
                },
                {
                    key: 'scenarioId',
                    value: scenarioId
                }
            ]
        );
        if (this.carGlobal.isMockDataMode()) {
            const mockURL: string = 'assets/mock-data/fee-analysis.json';
            return this.httpUtil.doMockPostStronglyTyped<ScenarioAccountFee>(mockURL, 1000).pipe(
                map((result: ScenarioAccountFee) => {
                    if (result) {

                        result.hudSummary = this.setHudSummaryLabels(result.hudSummary);
                        return result;
                    }
                }));
        }
        return this.http.get(apiUrl).pipe(
            map((response: ScenarioAccountFee) => {
                if (response) {

                    response.hudSummary = this.setHudSummaryLabels(response.hudSummary);
                    return response;
                }
            }));
    }

    public getFeeSchedulesList(proposalId: number, scenarioId: number) {
        const apiUrl = this.carGlobal.configService.routeFormatter(
            this.carGlobal.configService.getEndPoint('getFeeSchedules'),
            [
                {
                    key: 'proposalId',
                    value: proposalId
                },
                {
                    key: 'scenarioId',
                    value: scenarioId
                }
            ]
        );
        if (this.carGlobal.isMockDataMode()) {
            const mockURL: string = 'assets/mock-data/fee-schedules.json';
            return this.httpUtil.doMockPostStronglyTyped<SeiPayload>(mockURL, 1000).pipe(
                map((result: SeiPayload) => {
                    return result.data;
                }));
        }
        return this.http.get(apiUrl).pipe(
            map((response: SeiPayload) => {
                if (response && response.data) {
                    if (response.error && response.error.length > 0) {
                        this.genericErrorService.setGenericError({
                            code: response.error.code,
                            description: response.error.message
                        });
                    }
                    // response.data.forEach((schedule) => {
                    //     schedule.scheduleName = schedule.scheduleName + ' (' + schedule.scheduleType + ')';

                    // });
                    return response.data;
                }
            }));

    }

    public getFeeSchedulesDetail(proposalId: number, scenarioId: number, feeSchedule: ScenarioFeeSchedule) {

        let isSwp: boolean = false;
        let apiUrl = '';
        switch (feeSchedule.scheduleType) {
            case ScenarioFeeScheduleTypes.SWP:
                apiUrl = this.carGlobal.configService.routeFormatter(
                    this.carGlobal.configService.getEndPoint(
                        'getFeeSchedulesDetail'
                    ),
                    [
                        {
                            key: 'proposalId',
                            value: proposalId
                        },
                        {
                            key: 'scenarioId',
                            value: scenarioId
                        },
                        {
                            key: 'scheduleId',
                            value: feeSchedule.scheduleId
                        }
                    ]
                );
                isSwp = true;

                break;
            case ScenarioFeeScheduleTypes.Custom:
                apiUrl = this.carGlobal.configService.routeFormatter(
                    this.carGlobal.configService.getEndPoint(
                        'getFeeSchedulesCustomDetail'
                    ),
                    [
                        {
                            key: 'proposalId',
                            value: proposalId
                        },
                        {
                            key: 'scenarioId',
                            value: scenarioId
                        },
                        {
                            key: 'feeGroupId',
                            value: feeSchedule.scheduleId
                        }
                    ]
                );

                break;

        }

        return this.http.get(apiUrl).pipe(
            map((response: SeiPayload) => {
                if (response) {
                    if (response) {
                        if (response.error && response.error.length > 0) {
                            this.genericErrorService.setGenericError({
                                code: response.error.code,
                                description: response.error.message
                            });
                        }
                        return response.data[0].feeSchedule;
                    }
                }
            }));
    }

    public getFeeStrategyCalculation(account: Account) {
        const apiUrl = this.carGlobal.configService.routeFormatter(
            this.carGlobal.configService.getEndPoint('retrieveAccountFees')
        );


        return merge(this.http.post(apiUrl, account).pipe(
            // eslint-disable-next-line rxjs/no-sharereplay
            shareReplay(1),
            map((response: ScenarioAccountFee) => {
                if (response) {
                    if (response) {
                        response.hudSummary = this.setHudSummaryLabels(response.hudSummary);
                        return response;
                    }
                }
            })));
    }

    public setHudSummaryLabels(hudSummary: HudSummary): HudSummary {
        if (hudSummary.dfsFee && hudSummary.dfsFee.amount > 0) {
            hudSummary.managementExpense.label = HUDSummaryLabel.MgmtDFS;

        } else {
            hudSummary.managementExpense.label = HUDSummaryLabel.MgmtFee;
        }
        hudSummary.fundExpense.label = HUDSummaryLabel.FundExp;
        hudSummary.platformFee.label = HUDSummaryLabel.PlatformFee;
        return hudSummary;
    }

    public checkFeeIconVisible(managementFeeBreakdown: ManagementFeeBreakdown[]) {
        const validList: Boolean = managementFeeBreakdown && managementFeeBreakdown.length > 0;
        const hasFees: boolean = validList && managementFeeBreakdown.some((fee: ManagementFeeBreakdown) => fee.rate > 0);
        return hasFees;
    }

    public filterManagementFeeBreakdown(managementFeeBreakdown: ManagementFeeBreakdown[]) {
        return managementFeeBreakdown?.filter((fee: ManagementFeeBreakdown) => fee.amount && fee.amount > 0);
    }


}
