/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Action } from '@ngrx/store';

import { UserProfile } from '@CarInterfaces/user-profile';
import { Advisor } from '@CarInterfaces/advisor';

export enum AuthActionTypes {
    LoginAction = '[CAR Login] Action',
    LogoutAction = '[CAR Logout] Action',
    UpdatePrimaryAdvisorAction = '[CAR Primary Advisor Update] Action'
}

/**
 * For now calling this login. This really just sets the Car User Profile
 * Not really log in. But following traditional naming to make things easy to follow
 */
export class Login implements Action {
    readonly type = AuthActionTypes.LoginAction;

    constructor(public payload: { userProfile: UserProfile }) { }
}

/**
 * Same here. Logout will just be for local host purposes
 * We just need a way to clear local storage and reset authentication in a development mode
 * Will work this in with using DevParams, but for now following traditional naming conventions
 */

export class Logout implements Action {
    readonly type = AuthActionTypes.LogoutAction;
}

/**
 * Basically the same as login but just has the primary advisor.
 * Separated the action to track changes when Primary Advisor changes in the store
 */
export class UpdatePrimaryAdvisor implements Action {
           readonly type = AuthActionTypes.UpdatePrimaryAdvisorAction;

           constructor(public payload: { userProfile: UserProfile }) {
               if (payload.userProfile &&
                   payload.userProfile.entitlements &&
                   payload.userProfile.entitlements.advisors) {
                   // TODO HACK to minimize data footprint in NGRX store
                   // all of this data is repeated and null in payload.
                   payload.userProfile.entitlements.advisors.forEach(
                       (advisor: Advisor) => {
                           delete advisor.advisorId;
                           delete advisor.userId;

                           delete advisor.email;
                           delete advisor.addressLine1;
                           delete advisor.businessPhone;
                           delete advisor.mobilePhone;
                           delete advisor.faxNumber;
                           delete advisor.smUser;
                           delete advisor.crmGuid;
                           delete advisor.addressLine1;
                           delete advisor.admin;
                       }
                   );
               }
           }
       }

export type AuthActions = Login | Logout | UpdatePrimaryAdvisor;
