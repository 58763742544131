/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { BaseFactory } from '@CarInterfaces/base-factory';
import { ScheduledWithdrawals } from '@CarInterfaces/scheduled-withdrawals';

@Injectable({
    providedIn: 'root'
})
export class ScheduledWithdrawalsFactory
    implements BaseFactory<ScheduledWithdrawals> {
    public createObject(): ScheduledWithdrawals {
        const scheduleWithdrawals: ScheduledWithdrawals = {
            scheduledWithdrawalId: -1,
            isIraTypeAccount: false
        };

        return scheduleWithdrawals;
    }
}
