/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

export enum WipViews {
    None = 0,
    List = 1,
    Edit = 2,
    Summary = 3,
    FileUpload = 4
}

export enum WipChangeState {
    Defer = 'DEFER',
    Incomplete = 'INCOMPLETE',
    Recommended = 'RECOMMENDED',
    Started = 'STARTED',
    Saved = 'SAVED'
}

/**
 * Wip Section Ids
 * values under 50 should match database table dbo.sections
 */
export enum WipSections {
    // Wip Sections
    AccountOverview = 1,
    AccountOwner = 2,
    AccountDetails = 4,
    DeathBeneficiary = 10,
    Funding = 7,
    FundingSources = 8,
    FundingDirection = 9,
    RelatedParties = 3,
    RiskTolerance = 6,
    StrategySelection = 5,
    // Optional Wip Sections
    Goals = 52,
    PurchaseOutSideStrategy = 50,
    SeiCashAccessAccount = 54,
    SeiScheduleDistribution = 53,
    StrategyFundingPriority = 51,
    PowerAttorney = 55
}

export enum WipOptionalSectionType {
    AdditionalInstruction = 1,
    SeiService = 2
}

export enum WipBeneficiaryValidation {
    IsDataToAddBeneficiary = 1,
    CanAddAdditionalBeneficiary = 2
}

export enum WipBeneficiaryCategory {
    Named = 1,
    ToAllMyChildren = 2,
    ToMySpouse = 3
}

export enum WipBeneficiaryRelationshipType {
    None = 0,
    Spouse = 1,
    NonSpouse = 2
}

export enum WipBeneficiaryComplete {
    PercentageComplete = 1
}
export enum WipAccountAction {
    Edit = 'EditAccount',
    Save = 'SaveAccount'
}

export const wipGoalMonths = [
    { text: 'Jan', value: 1 },
    { text: 'Feb', value: 2 },
    { text: 'Mar', value: 3 },
    { text: 'Apr', value: 4 },
    { text: 'May', value: 5 },
    { text: 'Jun', value: 6 },
    { text: 'Jul', value: 7 },
    { text: 'Aug', value: 8 },
    { text: 'Sep', value: 9 },
    { text: 'Oct', value: 10 },
    { text: 'Nov', value: 11 },
    { text: 'Dec', value: 12 }
];

export enum WipSummaryGenericLabelType {
    Header = 'HEADER',
    Spacing = 'SPACING'
}
