/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { StrategyMenu } from '../../model/enums';

@Component({
    selector: 'sei-car-strategy-menu-component',
    templateUrl: './strategy-menu.component.html'
})
export class StrategyMenuComponent {

    @Input()
    public selectedMenuItem: string = StrategyMenu.ExploreStrategy;

    @Input()
    public filteredStrategiesExist: boolean = false;

    @Output()
    public menuItemClicked = new EventEmitter<StrategyMenu>();

    public enumMenuSelection = StrategyMenu;

    public toggleMenu(event: StrategyMenu) {
        this.menuItemClicked.emit(event);
    }
}
