<div *ngIf="this.selectedOptionExploreStrategy !== this.optionExploreStrategy.InvestmentRisk"
     class="col-xs-5">
    <sei-car-strategy-dropdown *ngIf="strategyFilterLoaded"
                               [strategyDropDownInputData]="filterStrategyDropDownData"
                               [isLabelHidden]="false"
                               [labelStrategyDropDown]="exploreStrategyDropDownLabel"
                               [setDefaultDropDownValue]="strategyFilterDropDownDefaultValue"
                               (strategyDropdownSelected)="onStrategyFilterDropdownSelected($event)">
    </sei-car-strategy-dropdown>
</div>
<div class="col risk-widget-left">
    <sei-car-risk-summary-widget [clickableSnapPoints]="riskWidgetAvailableValues"
                                 [riskToleranceSnapPoint]="riskToleranceScore"
                                 [riskRankSnapPoint]="snapDefaultRiskScoreValue"
                                 (snapPointSelected)="onEventRiskLevelSelected($event)">
    </sei-car-risk-summary-widget>
</div>
<div class="col-xs-12 sort-by-background">
    <div class="row">
        <sei-car-strategy-dropdown class="col-xs-5"
                                   [strategyDropDownInputData]="filterStrategySorByOptionsInput"
                                   [isLabelHidden]="true"
                                   [setDefaultDropDownValue]="indexSelectedSortByValue"
                                   (strategyDropdownSelected)="onSortByDropDownSelected($event)">
        </sei-car-strategy-dropdown>
    </div>
</div>
<div class="col-xs-12">
    <div *ngIf="filteredSortedByStrategies && filteredSortedByStrategies.length === 0"
         class="text-center">
        <h6>
            <i class="fa fa-exclamation-triangle"></i>
            There are no available strategies that fit your criteria.
        </h6>
    </div>
    <div class="strategy-ribbon-row"
         *ngIf="filteredSortedByStrategies && filteredSortedByStrategies.length > 0">
        <car-strategy-ribbon class="animated fadeIn fast"
                             [strategy]="strategy"
                             [account]="account"
                             [ribbonType]="this.ribbonTypes.ExploreStrategy"
                             (accountChange)="onAccountChange()"
                             *ngFor="let strategy of filteredSortedByStrategies">
        </car-strategy-ribbon>
    </div>
</div>
