/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
import { Injectable } from '@angular/core';
import { OwnerType } from '@CarInterfaces/account-owner-detail';
import {
    accountTypeOptionsData,
    AccountTypeOptionSettings,
    WipSectionSettings
} from '@CarModels/account-type-options';
import { OwnerTypes } from '@CarModels/enums';
import { WipSections } from '@CarServices/wip/wip-enums';

@Injectable({
    providedIn: 'root'
})
export class AccountTypeOptionsService {
    /**
     * gets the options object for a requested account type
     * @param accountTypeId account type id to search
     */
    public getOptions(accountTypeId: number): AccountTypeOptionSettings {
        return accountTypeOptionsData.find(
            (option: AccountTypeOptionSettings) =>
                option.accountTypeId === accountTypeId
        );
    }

    /**
     * Determines if the Owner Type dropdown is to be shown
     * for a specific account type in the requested wip section
     * @param accountTypeId account id to search account type options
     * @param wipSection wip section to pull options from
     */
    public isTypeOfOwnerOptionDisplayed(
        accountTypeId: number,
        wipSection: WipSections
    ): boolean {
        const accountTypeOption = this.getOptions(accountTypeId);

        if (!accountTypeOption) {
            return false;
        }

        if (!accountTypeOption.wipSectionSettings) {
            return false;
        }

        const sectionOptions = accountTypeOption.wipSectionSettings.find(
            (section: WipSectionSettings) => section.sectionId === wipSection
        );

        return sectionOptions && sectionOptions.displayTypeOfOwner
            ? sectionOptions.displayTypeOfOwner
            : false;
    }

    public getDefaultRelatedPartiesOwnerTypeOption(
        accountTypeId: number,
        wipSection: WipSections
    ): OwnerType {
        const relatedPartyOwnerType: OwnerType = {
            typeOfOwnerId: OwnerTypes.IndividualAdult,
            typeOfOwnerDescription: 'Individual (Adult)'
        };

        const accountTypeOption = this.getOptions(accountTypeId);

        if (!accountTypeOption || !accountTypeOption.wipSectionSettings) {
            return relatedPartyOwnerType;
        }

        const sectionOptions = accountTypeOption.wipSectionSettings.find(
            (section: WipSectionSettings) => section.sectionId === wipSection
        );

        return sectionOptions && sectionOptions.defaultTypeOfOwner
            ? sectionOptions.defaultTypeOfOwner
            : relatedPartyOwnerType;
    }

    /**
     * Determines if the upload documents behavior is to be shown
     * for a specific account type in the requested wip section
     * @param accountTypeId account id to search account type options
     * @param wipSection wip section to pull options from
     */
    public isUploadDocumentDisplayed(
        accountTypeId: number,
        wipSection: WipSections
    ): boolean {
        const wipSectionSettings = this.getWipSectionSettings(
            accountTypeId,
            wipSection
        );

        return wipSectionSettings && wipSectionSettings.displayUploadDocuments
            ? wipSectionSettings.displayUploadDocuments
            : false;
    }

    public showInvestorTaxRateSection(
        accountTypeId: number,
        wipSection: WipSections
    ): boolean {
        const wipSectionSettings = this.getWipSectionSettings(
            accountTypeId,
            wipSection
        );

        return wipSectionSettings &&
            wipSectionSettings.showInvestorTaxRateSection
            ? wipSectionSettings.showInvestorTaxRateSection
            : false;
    }

    public displayMaritalStatus(
        accountTypeId: number,
        wipSection: WipSections
    ): boolean {
        const wipSectionSettings = this.getWipSectionSettings(
            accountTypeId,
            wipSection
        );

        return wipSectionSettings && wipSectionSettings.displayMaritalStatus
            ? wipSectionSettings.displayMaritalStatus
            : false;
    }

    private getWipSectionSettings(
        accountTypeId: number,
        wipSection: WipSections
    ): WipSectionSettings {
        let wipSectionSettings: WipSectionSettings;

        const accountTypeOption = this.getOptions(accountTypeId);

        if (accountTypeOption && accountTypeOption.wipSectionSettings) {
            wipSectionSettings = accountTypeOption.wipSectionSettings.find(
                (section: WipSectionSettings) =>
                    section.sectionId === wipSection
            );
        }

        return wipSectionSettings;
    }
}
