/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { UserProfile } from '@CarInterfaces/user-profile';
import { GlobalService } from '@CarServices/system/global.service';
import { UserProfileService } from '@CarServices/system/user-profile.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuListModel, NavbarStyle, ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { List } from 'immutable';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';


/**
 * Wraps the sei-header component to keep app.component.ts cleaner
 */
@Component({
    selector: 'car-header',
    templateUrl: './header.component.html'
})
export class HeaderComponent extends ParentComponentSubscriptionManager implements OnInit {
    public environment: string = '';
    public menuList: Observable<List<MenuListModel>>;
    private localHostUrl = 'localhost';
    private localHostEnvText = 'LOCAL';
    public profile: UserProfile;
    public userRoles: string = '';
    selectedStyle: NavbarStyle = NavbarStyle.Default;

    public get isAuthenticated(): boolean {
        return this.authService.isAuthenticated;
    }

    constructor(
        private readonly global: GlobalService,
        private readonly authService: AuthService,
        private readonly userProfileService: UserProfileService,
        private router: Router

    ) {
        super('HeaderComponent');
    }

    ngOnInit() {
        this.initializeEnvironmentDisplay();
        this.setWalkmeParameters(this.isAuthenticated);
        this.subscriptions.push(
            this.authService.userAuthenticated.subscribe((res) => {
                this.setWalkmeParameters(res);
            })
        );
    }

    // Log Off Handler.
    public handleLogOff() {
        this.authService.kill();
        this.authService.logoutInitiated();
    }

    private initializeEnvironmentDisplay() {
        // Don't show environment if in PROD
        if (!this.global.configService.environment.production) {
            this.environment = this.global.configService.getOption('mode');
            // Override localhost
            if (window.location.href.indexOf(this.localHostUrl) > 0) {
                this.environment = this.localHostEnvText;
            }
        }
    }

    private setWalkmeParameters(isAuthenticated: boolean) {
        if (isAuthenticated && this.userProfileService.profile) {
            this.profile = this.userProfileService.profile;
            if (this.profile.entitlements && this.profile.entitlements.userRoles) {
                if (this.profile.entitlements.userRoles.length === 1) {
                    this.userRoles = this.profile.entitlements.userRoles[0].userRoleName;
                } else {
                    for (let i = 0; i < this.profile.entitlements.userRoles.length; i++) {
                        this.userRoles = this.userRoles + this.profile.entitlements.userRoles[i].userRoleName;
                        if (i !== this.profile.entitlements.userRoles.length - 1) {
                            this.userRoles = this.userRoles + ' | ';
                        }
                    }
                }
            }
        }
    }

    public navigateToDashboard() {
        const navigateUrl = '/Proposal/Dashboard';
        this.router.navigate([navigateUrl]);
    }
}
