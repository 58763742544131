/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Action } from '@ngrx/store';

import {
    TradeCreateDocumentResponse,
    TradeDocumentStatusResponse
} from '@CarInterfaces/trade';
import { EnrollmentService } from '@CarServices/enrollment/enrollment.service';
import { GenericErrorService } from '@CarServices/system/generic-error.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import * as enrollmentActions from './enrollment.actions';

@Injectable()
export class EnrollmentEffects {
    constructor(
        private readonly actionsStream: Actions,
        private readonly enrollmentService: EnrollmentService,
        private readonly router: Router,
        private readonly errorService: GenericErrorService
    ) { }


    redirectWipDetailsStream = createEffect(() => this.actionsStream.pipe(
        ofType(enrollmentActions.EnrollmentActionTypes.RedirectToWipDetails),
        map((action: enrollmentActions.RedirectToWipDetails) => action.payload),
        tap((accountNumber: number) =>
            this.router.navigate(['PendingAccounts/' + accountNumber])
        )
    ), { dispatch: false });


    generateDocumentsStream: Observable<Action> = createEffect(() => this.actionsStream.pipe(
        ofType(enrollmentActions.EnrollmentActionTypes.GenerateDocuments),
        mergeMap((action: enrollmentActions.GenerateDocuments) =>
            this.enrollmentService.generateDocuments(action.payload).pipe(
                map(
                    (response: TradeCreateDocumentResponse) => {
                        action.payload.generatingDocuments[action.payload.accountNumber] = false;
                        return new enrollmentActions.GenerateDocumentsSuccess({
                            ...response,
                            generatingDocuments: action.payload.generatingDocuments,
                            documentGenerated: action.payload.documentGenerated
                        });
                    }

                ),
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                catchError((err: any) => {
                    this.errorService.setGenericError(err);
                    return of(
                        new enrollmentActions.GenerateDocumentsFail({
                            errorMessage: err,
                            generatingDocuments:
                                action.payload.generatingDocuments
                        })
                    );
                })
            )
        )
    ));


    getDocumentStatusStream: Observable<Action> = createEffect(() => this.actionsStream.pipe(
        ofType(
            enrollmentActions.EnrollmentActionTypes.GenerateDocumentsSuccess
        ),
        map(
            (action: enrollmentActions.GenerateDocumentsSuccess) =>
                new enrollmentActions.DocumentStatus(action.payload)
        )
    ));


    getDocumentAvailableStatusSteam: Observable<
        Action
    > = createEffect(() => this.actionsStream.pipe(
        ofType(enrollmentActions.EnrollmentActionTypes.DocumentStatus),
        mergeMap((action: enrollmentActions.DocumentStatus) =>
            this.enrollmentService
                .getDocumentAvailableStatus(
                    action.payload.data[0].values[0].investor.accountNumber
                )
                .pipe(
                    map(
                        (response: TradeDocumentStatusResponse) => {
                            action.payload.documentGenerated[response.data[0].statuses[0].investor.accountNumber] = true;
                            action.payload.generatingDocuments[response.data[0].statuses[0].investor.accountNumber] = false;
                            return new enrollmentActions.DocumentStatusSuccess({
                                documentUrl: response.data[0].statuses[0].investor.accountNumber,
                                documentGenerated: action.payload.documentGenerated,
                                generatingDocuments: action.payload.generatingDocuments
                            });
                        }

                    ),
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    catchError((err: any) => {
                        this.errorService.setGenericError(err);
                        return of(
                            new enrollmentActions.DocumentStatusFail(err)
                        );
                    })
                )
        )
    ));


    loadDocumentAfterSuccessStatusStream: Observable<
        Action
    > = createEffect(() => this.actionsStream.pipe(
        ofType(enrollmentActions.EnrollmentActionTypes.DocumentStatusSuccess),
        map(
            (action: enrollmentActions.DocumentStatusSuccess) =>
                new enrollmentActions.DocumentLoad()
        )
    ));


    loadDocumentStream: Observable<Action> = createEffect(() => this.actionsStream.pipe(
        ofType(enrollmentActions.EnrollmentActionTypes.DocumentLoad),
        map(
            (action: enrollmentActions.DocumentLoad) =>
                new enrollmentActions.DocumentLoadSuccess(
                    `${window.location.href}/displaydocument`
                )
        )
    ));
}
