<sei-entity-dropdown-es id="entityPartyDropdown"
                        [firmId]="firmId"
                        [sourceSystem]="sourceSystem"
                        [entityTypes]="entityTypes"
                        [limitResults]="limitResults"
                        [clientStatus]="clientStatusOptions"
                        [additionalActionLink]="additionalActionLink"
                        [multiSelect]="multiSelect"
                        [placeholder]="placeholder"
                        [searchMinimumCharacters]="searchMinimumCharacters"
                        [placeholder]="placeholder"
                        (onChange)="onControlSelect($event)"
                        (newEntityName)="onNewEntityItem($event)">
</sei-entity-dropdown-es>