/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { FileUpload, FileUploadViewMode, ProposalSectionLabel } from '../../../model/enums';
import { FileUploadForm } from '../../../model/file-upload';
import { ProposalSection, ProposalStatement } from '../../../model/presentation';
import { SeiPayload } from '../../../model/sei-payload';
import { DataSharingService } from '../../../service/data-sharing.service';
import { UploadFormService } from '../../../service/upload-forms.service';

@Component({
    selector: 'car-file-multiple-upload',
    templateUrl: './file-multiple-upload.component.html',
    styleUrls: ['./file-multiple-upload.component.scss']
})
export class FileMultipleUploadComponent extends ParentComponentSubscriptionManager
    implements OnInit, OnChanges {

    @Input()
    public statement?: ProposalStatement;

    @Input()
    public sectionSelected: FileUploadViewMode;

    @Input()
    public proposalId: number;

    @Input()
    public presentationType: string;

    @Input()
    public sectionName: string;

    @Input()
    public subSectionName: string;

    @Output()
    public onSuccessfullUpload = new EventEmitter<string>();

    @Output()
    public advisorContentUploaded = new EventEmitter<SeiPayload>();

    public acceptAttributeMultiple: string;
    public allowedExtensions: string[];
    public fileUploadForm: FileUploadForm;
    public hasErrors = false;
    public invalidMultipleFiles: string;
    public invalideFile: string[];
    public maxFileAllowedToUpload = 10;
    public tooManyFilesCount = 0;
    public uploadingDocumentIndexList = [];
    public documentUploadedIndexList = [];
    public disableUploadSection: boolean = false;
    public clickedOnRemoveDocumentIndexList = [];
    public uploadedFileResponse: string[] = [];
    public clickedSection: FileUploadViewMode;
    public isUploadingServiceError: boolean = false;
    public reUploadingEnableIndexList = [];
    public uploadErrorMessage: Error[];
    public fileName: string[] = [];
    public removeButtonDisplay = [];
    public files: File[];
    public fileSizeExceededLimit: number = 0;
    public hasFileSizeExceeded: boolean = false;
    public fileAlreadyExistsError: boolean = false;
    public hasInvalidFileName: boolean = false;


    constructor(private readonly uploadFormService: UploadFormService, private dataSharingService: DataSharingService) {
        super('FileMultipleUploadComponent');
    }

    ngOnInit() {
        this.maxFileAllowedToUpload = FileUpload.MaxFilesAllowedByUpload;
        // Max file upload is set to 1 by default, if in future multiupload is required
        // it needs to be updated in below condition
        if (this.sectionSelected === FileUploadViewMode.AdvisorContent) {
            this.acceptAttributeMultiple = this.uploadFormService.acceptPDFExtension;
            this.allowedExtensions = this.uploadFormService.getAllowedPDFExtension();
        } else {
            this.acceptAttributeMultiple = this.uploadFormService.acceptMultipleUploadExtension;
            this.allowedExtensions = this.uploadFormService.getAllowedExtensionForMultipleUpload();
        }

        this.subscriptions.push(
            this.uploadFormService
                .getFileUploadFormStream()
                .subscribe(
                    (data: FileUploadForm) => {
                        this.fileUploadForm = data;
                        if (this.fileUploadForm && this.fileUploadForm.files
                            && this.fileUploadForm.files.length > 0) {
                            this.files = Object.values(this.fileUploadForm.files);
                        }
                    }
                )
        );

        this.subscriptions.push(
            this.uploadFormService.invalidFilesErrorStream.subscribe(
                (files: File[]) => this.invalidFilesAdded(files)
            )
        );

        this.subscriptions.push(
            this.uploadFormService.tooManyFilesErrorStream.subscribe(
                (fileCount: number) => this.tooManyFilesAdded(fileCount)
            )
        );

        this.subscriptions.push(
            this.uploadFormService.currentFileSizeErrorStream.subscribe(
                (file: File) => this.maxFileSizeViolated(file.size)
            )
        );

        this.subscriptions.push(
            this.uploadFormService.invalidFileName.subscribe(
                (isPresent: boolean) => this.invalidFileName(isPresent)
            )
        );
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.bannerMessageConfig &&
            changes.bannerMessageConfig.currentValue !== undefined) {
            this.disableUploadSection = true;
        } else {
            this.disableUploadSection = false;
        }
        if (changes && changes.sectionSelected && changes.sectionSelected.currentValue) {
            this.clickedSection = changes.sectionSelected.currentValue;
            this.resetInProgress();
        }
        if (changes && (changes.changedAccountNumber ||
            (changes.preAssignedAccountNotSelected))) {
            this.resetInProgress();
        }
    }

    public onChangeFileUpload(files: File[]): void {
        this.onStartFileDrop();
        if (!(this.fileUploadForm.files && this.fileUploadForm.files.length > 0)) {
            this.uploadFormService.addMultipleFiles(files, undefined, this.sectionSelected === FileUploadViewMode.AdvisorContent);
            if (this.fileUploadForm && this.fileUploadForm.files && this.fileUploadForm.files.length > 0) {
                this.files = Object.values(this.fileUploadForm.files);
            }
        } else {
            const checkedFileAlreadySelected = Object.values(files);
            const checkFilteredFile = checkedFileAlreadySelected.filter((attachedFile) => {
                return !this.fileUploadForm.files.some((acceptedFile) => {
                    return acceptedFile.name === attachedFile.name &&
                        acceptedFile.size === attachedFile.size;
                });
            });
            if (checkFilteredFile && checkFilteredFile.length > 0) {
                this.uploadFormService.addMultipleFiles(checkFilteredFile);
                if (this.fileUploadForm && this.fileUploadForm.files && this.fileUploadForm.files.length > 0
                    && this.tooManyFilesCount <= 10) {
                    const previousTotalFile = this.files.length;
                    for (let index = 0; index < checkFilteredFile.length; index++) {
                        const currentFile = checkFilteredFile[index];
                        if (currentFile.size <= this.uploadFormService.getMaxFileSize()) {
                            this.files[previousTotalFile + index] = checkFilteredFile[index];
                        }
                    }
                }

            }
        }
    }

    public invalidFilesAdded(fileList: File[]): void {
        if (fileList && fileList.length > 0) {
            this.invalidMultipleFiles = fileList
                .map((file: File) => file.name)
                .join(':|');
        }
    }

    public checkInValidFile(fileName: string): boolean {
        if (this.invalidMultipleFiles) {
            this.invalideFile = this.invalidMultipleFiles.split(':|');
            if (this.invalideFile.length > 0) {
                return this.invalideFile.includes(fileName);
            }
        } else {
            delete this.invalideFile;
        }
    }

    public onStartFileDrop(): void {
        this.hasErrors = false;
        this.tooManyFilesCount = 0;
        this.fileSizeExceededLimit = 0;
        this.hasInvalidFileName = false;
        this.uploadFormService.cleanFiles(this.fileUploadForm);
    }

    public onFileNameClick(event): void {
        const fileURL = URL.createObjectURL(event);
        window.open(fileURL, '_blank');
    }

    public tooManyFilesAdded(fileCount: number): void {
        this.hasErrors = fileCount > 10;
        this.tooManyFilesCount = fileCount;
    }

    private invalidFileName(isPresent: boolean) {
        if (isPresent) {
            this.hasErrors = true;
            this.hasInvalidFileName = true;
        }
    }

    public maxFileSizeViolated(fileSize: number): void {
        this.hasErrors = fileSize >= this.uploadFormService.getMaxFileSize();
        this.fileSizeExceededLimit = fileSize;
        this.hasFileSizeExceeded = this.hasErrors;
    }

    public removeFile(index?: number) {
        this.clickedOnRemoveDocumentIndexList[index] = true;
        this.disableUploadSection = true;
    }

    public yesToRemoveFile(fileName: string, index?: number) {
        this.tooManyFilesCount = this.tooManyFilesCount - 1;
        const remainingFile: File[] =
            this.files.filter(((fileFilter) => fileFilter.name !== fileName));
        this.uploadFormService.cleanFiles(this.fileUploadForm);
        this.fileUploadForm.files = [];
        this.uploadFormService.setFileUploadFormStream(this.fileUploadForm);
        this.uploadFormService.validateData(this.fileUploadForm);
        this.uploadFormService.addMultipleFiles(remainingFile, index);
        delete this.files[index];
        this.clickedOnRemoveDocumentIndexList[index] = false;
        if (this.reUploadingEnableIndexList[index] === true &&
            this.fileName[index] === fileName) {
            delete this.reUploadingEnableIndexList[index];
            this.reUploadingEnableIndexList =
                this.reUploadingEnableIndexList.filter((indexFile) => indexFile !== undefined);
        }
        if (this.documentUploadedIndexList[index] === true &&
            this.fileName[index] === fileName) {
            delete this.documentUploadedIndexList[index];
            this.documentUploadedIndexList =
                this.documentUploadedIndexList.filter((indexFile) => indexFile !== undefined);
        }
        this.disableUploadSection = false;
        this.fileAlreadyExistsError = false;
        this.hasInvalidFileName = false;
        this.hasFileSizeExceeded = false;
    }

    public noToRemoveFile(index?: number) {
        this.clickedOnRemoveDocumentIndexList[index] = false;
        if (!this.clickedOnRemoveDocumentIndexList[index]) {
            this.disableUploadSection = false;
        }
    }

    public uploadFile(file: File, index?: number) {
        const fileUpload: FileUploadForm = Object.assign({}, this.fileUploadForm);
        fileUpload.files = [];
        this.hasErrors = false;
        fileUpload.files.push(file);
        this.uploadingDocumentIndexList[index] = true;
        this.documentUploadedIndexList[index] = false;
        this.reUploadingEnableIndexList[index] = false;
        this.removeButtonDisplay[index] = true;
        this.isUploadingServiceError = false;
        this.fileAlreadyExistsError = false;

        switch (this.clickedSection) {
            case FileUploadViewMode.CoverImage:
                this.uploadFormService.upload(this.fileUploadForm,
                    this.proposalId, this.sectionSelected,
                    this.presentationType, this.sectionName,
                    this.subSectionName).subscribe((response) => {
                        this.reUploadingEnableIndexList[index] = false;
                        this.uploadingDocumentIndexList[index] = false;
                        this.removeButtonDisplay[index] = true;
                        this.documentUploadedIndexList[index] = true;
                        this.fileName[index] = file.name;
                        this.onSuccessfullUpload.emit(encodeURI(response.data[0].documents[0].documentPath));
                    },
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        (error: any) => {
                            this.hasErrors = true;
                            this.uploadingDocumentIndexList[index] = false;
                            this.reUploadingEnableIndexList[index] = true;
                            this.uploadErrorMessage = error.message;
                            this.fileName[index] = file.name;
                            this.removeButtonDisplay[index] = false;
                            this.isUploadingServiceError = true;
                        });
                break;

            case FileUploadViewMode.BrandLogo:
                this.uploadFormService.upload(this.fileUploadForm,
                    this.proposalId, this.sectionSelected,
                    this.presentationType, this.sectionName,
                    this.subSectionName).subscribe((response) => {
                        this.reUploadingEnableIndexList[index] = false;
                        this.uploadingDocumentIndexList[index] = false;
                        this.removeButtonDisplay[index] = true;
                        this.documentUploadedIndexList[index] = true;
                        this.fileName[index] = file.name;
                        this.dataSharingService.brandLogoUpload.next((encodeURI(response.data[0].documents[0].documentPath)));
                    },
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        (error: any) => {
                            this.hasErrors = true;
                            this.uploadingDocumentIndexList[index] = false;
                            this.reUploadingEnableIndexList[index] = true;
                            this.uploadErrorMessage = error.message;
                            this.fileName[index] = file.name;
                            this.removeButtonDisplay[index] = false;
                            this.isUploadingServiceError = true;
                        });
                break;

            case FileUploadViewMode.AdvisorContent: {
                this.subSectionName = this.fileUploadForm.files[0].name.substring(0, this.fileUploadForm.files[0].name.lastIndexOf('.'));
                // Check if file of the same name already exists
                const advisorContentSection: ProposalSection = this.statement.sections.find(
                    (section: ProposalSection) => section.name === ProposalSectionLabel.AdvisorContent);
                advisorContentSection.sections.forEach((section: ProposalSection) => {
                    if (section.name === this.subSectionName) {
                        this.hasErrors = true;
                        this.fileAlreadyExistsError = true;
                        this.uploadingDocumentIndexList[index] = false;
                        this.fileName[index] = file.name;
                        this.removeButtonDisplay[index] = false;
                    }
                });
                if (!this.fileAlreadyExistsError) {
                    this.uploadFormService.upload(
                        this.fileUploadForm,
                        this.proposalId, this.sectionSelected,
                        this.presentationType, this.sectionName,
                        this.subSectionName).subscribe((response) => {
                            this.reUploadingEnableIndexList[index] = false;
                            this.uploadingDocumentIndexList[index] = false;
                            this.removeButtonDisplay[index] = true;
                            this.documentUploadedIndexList[index] = true;
                            this.fileName[index] = file.name;
                            response.data[0].documents[0].truncatedName = this.subSectionName;
                            this.advisorContentUploaded.emit(response);
                        },
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            (error: any) => {
                                this.hasErrors = true;
                                this.uploadingDocumentIndexList[index] = false;
                                this.reUploadingEnableIndexList[index] = true;
                                this.uploadErrorMessage = error.message;
                                this.fileName[index] = file.name;
                                this.removeButtonDisplay[index] = false;
                                this.isUploadingServiceError = true;
                            });
                }
                break;
            }
        }
    }

    public checkRemoveButtonVisibility(index: number, fileName: string): boolean {
        if (!(this.removeButtonDisplay[index]) && (!this.fileName[index] || this.fileName[index] === fileName)) {
            return true;
        } else {
            return false;
        }
    }

    public checkUploadButtonVisibilty(index: number): boolean {
        if (!(this.documentUploadedIndexList[index]) && !(this.uploadingDocumentIndexList[index])
            && !(this.reUploadingEnableIndexList[index]) && !(this.hasInvalidFileName)) {
            return true;
        } else {
            return false;
        }
    }

    public checkUploadingIsInProgress(index: number): boolean {
        if (this.uploadingDocumentIndexList[index] && !(this.reUploadingEnableIndexList[index])) {
            return true;
        } else {
            return false;
        }
    }

    public resetSelectFile(fileInput) {
        fileInput.value = null;
    }

    private resetInProgress() {
        this.uploadingDocumentIndexList = [];
        this.documentUploadedIndexList = [];
        this.clickedOnRemoveDocumentIndexList = [];
        this.reUploadingEnableIndexList = [];
        this.uploadedFileResponse = [];
        this.removeButtonDisplay = [];
        this.fileName = [];
        this.files = [];
        this.invalideFile = [];
        this.invalidMultipleFiles = '';
        this.uploadFormService.cleanFiles(this.fileUploadForm);
    }
}
