<ng-container *ngIf="proposalSection">
    <ng-container [ngSwitch]="proposalSection.inputProperty?.elementType">
        <ng-container *ngSwitchCase="elementTypes.DropDown">
            <div class="checklist-form-control">
                <sei-car-settings-dropdown [dropDownContent]="dropDownContent"
                                           [selectWidth]="selectWidth"
                                           [contentType]="proposalSection.inputProperty?.contentType"
                                           [presentationType]="presentationType"
                                           [coverTitleDisabled]="coverTitleDisabled"
                                           [(ngModel)]="proposalSection.inputProperty.value"
                                           (click)="onClick()"
                                           (ngModelChange)="optionSelected($event)">
                </sei-car-settings-dropdown>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="elementTypes.TextBox">
            <div class="checklist-form-control">
                <input class="form-control"
                       type="text"
                       [disabled]="disableInput || (presentationType === proposalSectionLabel.Proposal && coverTitleDisabled)"
                       [(ngModel)]="proposalSection.inputProperty.value">
            </div>
        </ng-container>
        <i *ngIf="showRevertOption"
           class="fas fa-undo"
           aria-hidden="true"
           [class.disabled]="disableRevertOption || disableInput || (proposalSection.inputProperty.value === proposalSection.defaultProperties.defaultValue)"
           (click)="revertToDefaultValue()"
           placement="right"
           [tooltip]="tipContent"></i>
    </ng-container>
</ng-container>
<ng-template #tipContent>{{tooltipText}}</ng-template>
