/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Account, State } from '@CarInterfaces/account';
import {
    AccountStrategies,
    FundedRange,
    InvestmentProgram,
    InvestmentStrategyAttribute,
    Strategy,
    StrategyAttribute,
    StrategyAttributeLevel,
    StrategyLevel
} from '@CarInterfaces/investment-program';
import { InvestmentProgram as AccountInvestmentProgram } from '@CarInterfaces/investment-program-response';
import { RepositoryEndPoint } from '@CarInterfaces/repository-base';
import { SeiPayload } from '@CarInterfaces/sei-payload';
import { WipSectionSummary } from '@CarInterfaces/wip-main';
import { InvestmentProgramType, RiskLevel, States } from '@CarModels/enums';
import { InvestmentProgramRepository } from '@CarRepos/investment-program.repository';
import { AccountService } from '@CarServices/account/account.service';
import { ProcessingRulesService } from '@CarServices/processing-rules/processing-rules.service';
import { GlobalService } from '@CarServices/system/global.service';
import { PropertyService } from '@CarServices/system/property.service';
import { UserProfileService } from '@CarServices/system/user-profile.service';
import * as _ from 'lodash';
import { BehaviorSubject, EMPTY, Observable, of } from 'rxjs';
import { catchError, filter, share, tap } from 'rxjs/operators';

/**
 * Main Investment Program Service Class
 * Used to get Strategies based on whats going on with the account object
 * works with a single Investment Program for both available and selected strategies
 * changes are based on updates from changes of CRM data
 *
 * ToDo MVP2 -> finish rest of Manage Accounts
 */
@Injectable({
    providedIn: 'root'
})
export class InvestmentProgramsService {
    public readonly quaterlyRebalanceAttributePathName = 'Quarterly Rebalance';
    public readonly bondAttributePathName = 'Bond Preference';

    // Observable for the Investment Program in its entirety
    private investmentProgramStream: BehaviorSubject<InvestmentProgram>;
    private _investmentProgram: InvestmentProgram;

    // Observable for the Strategies and Strategies Level
    // Not, first Parent is always the program from sourced data
    // this means nothing to the UI so we start at the first child
    private programLevelsStream: BehaviorSubject<StrategyLevel[]>;

    private programStrategyAttributesLevelsStream: BehaviorSubject<
        StrategyAttributeLevel[]
    >;

    // Pointer to the account.accountStrategy.selectedStrategies
    // Will treat build your own as a separate array pointer to do array logic
    // for custom strategies.
    private selectedStrategies: Strategy[] = new Array<Strategy>();

    // Pointer to the account.accountStrategy
    private accountStrategies: AccountStrategies;

    private levelCount: number = -1;

    // Controls the ribbon for MAS
    // Work in progress to support Fixed Income.
    private currentFundedRanges: FundedRange[] = [
        {
            id: 1,
            minValue: 0,
            maxValue: 499999,
            text: '$250-499K',
            selected: true
        },
        {
            id: 2,
            minValue: 500000,
            maxValue: 999999,
            text: '$500-999K',
            selected: false
        },
        {
            id: 3,
            minValue: 1000000,
            maxValue: 1499999,
            text: '$1M-1.499M',
            selected: false
        },
        {
            id: 4,
            minValue: 1500000,
            maxValue: Infinity,
            text: '+$1.5M',
            selected: false
        }
    ];

    // default to MF since thats 90% of strategy selection
    // mostly to support old legacy strategy code still in place.
    public investmentProgramType: InvestmentProgramType =
        InvestmentProgramType.MutualFunds;

    // Account property for deep lodash set
    private selectedStrategiesAccountKey: string =
        'accountStrategy.selectedStrategies';

    // Strategy Account property
    private accountStrategyAccountKey: string = 'accountStrategy';

    // End point object to pass to account repository for GET and POST calls
    // against the account object
    private accountEndPoints: RepositoryEndPoint;

    private get bondStates(): States[] {
        return [States.CA, States.MA, States.NJ, States.NY, States.PA];
    }

    constructor(
        private readonly accountService: AccountService,
        private readonly global: GlobalService,
        private readonly userProfileService: UserProfileService,
        private readonly investmentProgramRepository: InvestmentProgramRepository,
        private readonly processingRulesService: ProcessingRulesService,
        private readonly propertyService: PropertyService
    ) {
        this.initializeService();
    }

    /**
     * cleans up subscriptions in service
     */
    public destroy() {
        if (this.investmentProgramStream) {
            this.investmentProgramStream.unsubscribe(); // eslint-disable-line rxjs/no-subject-unsubscribe
        }
        if (this.programLevelsStream) {
            this.programLevelsStream.unsubscribe(); // eslint-disable-line rxjs/no-subject-unsubscribe
        }
    }

    /**
     * gets the funded ranges to display in range selector
     */
    public get fundedRanges(): FundedRange[] {
        return this.currentFundedRanges;
    }

    /**
     * gets the selected funded range thats was set in the range selector
     * based on which one is selected.
     */
    public get selectedFundedRange(): FundedRange {
        return this.fundedRanges.find(
            (fundedRange: FundedRange) => fundedRange.selected === true
        );
    }

    /**
     * sets the selected funded range in the funded range array
     */
    public set selectedFundedRange(selectedFundedRange: FundedRange) {
        this.fundedRanges.forEach(
            (sourceRange: FundedRange) => (sourceRange.selected = false)
        );
        let fundedRange = this.fundedRanges.find(
            (selectedRange: FundedRange) =>
                selectedRange.id === selectedFundedRange.id
        );
        fundedRange = selectedFundedRange;
        fundedRange.selected = true;
    }

    /**
     * gets the max level in the Investment Program object.
     */
    public get levels(): number {
        return this.levelCount;
    }

    /**
     * gets the investment program type based on what was opened in the MRDC
     */
    public get programType(): InvestmentProgramType {
        return this.investmentProgramType;
    }

    /**
     * gets the Investment Program Level Observable
     */
    public get programLevelsStreaming(): Observable<StrategyLevel[]> {
        if (!this.programLevelsStream) {
            return EMPTY;
        }
        return this.programLevelsStream;
    }

    /**
     * gets the selected strategies pointer
     */
    public get selectedInvestmentPrograms(): Strategy[] {
        return this.selectedStrategies;
    }

    public set selectedInvestmentPrograms(strategies: Strategy[]) {
        this.selectedStrategies = strategies;
    }

    public set strategyAccount(strategiesAccount: AccountStrategies) {
        this.accountStrategies = strategiesAccount;
    }

    /**
     * Adds a new strategy object to the selected strategies array
     * @param strategy object to add
     */
    public addStrategyToProgram(strategy: Strategy) {
        if (!strategy) {
            throw new Error(`must provide strategy object`);
        }

        if (!this.selectedStrategies) {
            this.selectedStrategies = new Array<Strategy>();
        }

        this.removeStrategyFromProgram(strategy.strategyId);
        this.selectedStrategies.push(strategy);
    }

    /**
     * Removes a strategy either by object ref or by strategyId
     */
    public removeStrategyFromProgram(strategyId: number) {
        this.selectedStrategies = this.selectedStrategies.filter(
            (program: Strategy) => program.strategyId !== strategyId
        );
    }

    /**
     * Convenience method for single select investment programs
     */
    public removeAllStrategiesFromProgram() {
        this.selectedStrategies = new Array<Strategy>();
        this.deselectLevelStrategies(this._investmentProgram.strategyLevels);
        this.investmentProgramStream.next(this._investmentProgram);
    }
    /**
     * replaces the strategy in the available strategies with the selected strategy object
     * @param strategy strategy object to replace
     */
    public updateProgramFromStrategy(strategy: Strategy) {
        // if there is nothing selected simply return.
        if (!this.selectedStrategies || this.selectedStrategies.length < 1) {
            return;
        }

        let oldStrategy = this.selectedStrategies.find(
            (selectedStrategy: Strategy) =>
                selectedStrategy.strategyId === strategy.strategyId
        );

        if (oldStrategy) {
            oldStrategy = strategy;
        }
    }

    /**
     * gets the Investment Program Observable
     */
    public getInvestmentProgramStreaming(): Observable<InvestmentProgram> {
        if (!this.investmentProgramStream) {
            return EMPTY;
        }
        return this.investmentProgramStream.asObservable();
    }

    /**
     * Sets the investment program service to an InvestmentProgram Object
     * @param investmentProgram investment program to update service to
     */
    public setInvestmentProgram(investmentProgram: InvestmentProgram) {
        this._investmentProgram = investmentProgram;

        if (!this.investmentProgramStream) {
            this.investmentProgramStream = new BehaviorSubject<
                InvestmentProgram
            >(this._investmentProgram);
        } else {
            this.investmentProgramStream.next(this._investmentProgram);
        }
    }

    /**
     * Finds the strategy level object by name
     * some programs have mixed data in the level array.
     * In the future, this will be worked out with the backend
     * @param programLevels strategy level array to search against
     * @param levelName name of the level
     */
    public findProgramLevels(
        programLevels: StrategyLevel[],
        levelName: string
    ): StrategyLevel[] {
        const levels = programLevels.find(
            (strategyLevel: StrategyLevel) =>
                strategyLevel.strategyLevelName === levelName
        );
        if (
            levels &&
            levels.strategyLevels &&
            levels.strategyLevels.length > 0
        ) {
            return levels.strategyLevels;
        }

        return undefined;
    }

    /**
     * Performs a deep filter on the available strategies starting at the passed in strategyLevel
     * Returns a copy of the  level array to prevent mutation issues
     * @param strategyLevels strategy level array to filter
     */
    public getFilterFundedRangeInvestmentPrograms(
        strategyLevels: StrategyLevel[]
    ): StrategyLevel[] {
        const filterLevels = _.cloneDeep(strategyLevels);
        this.filterFundedRangeInvestmentPrograms(filterLevels);
        return filterLevels;
    }

    /**
     * returns the selected strategies and updates the Account Store
     * Will convert to Effect
     */
    public fetchAccountStrategy(): Observable<Account> {
        return this.accountService.fetchAccountData(this.accountEndPoints).pipe(
            share(),
            filter((response: Account) => response !== undefined),
            tap((response: Account) => {
                if (response.accountStrategy) {
                    this.accountService.setAccountProperty(
                        this.accountStrategyAccountKey,
                        response.accountStrategy
                    );
                }
            }),
            // returning Empty for now. Handled in exception handling feature. Also need to return something or page may not work
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            catchError((error: any) => {
                return EMPTY;
            })
        );
    }

    /**
     * Calls forth the save of the Account Service/Repository since all we need to do is update and flush the account object
     */
    public saveSelectedStrategies(
        strategies?: Strategy[]
    ): Observable<Account> {
        const update: Strategy[] =
            strategies && Array.isArray(strategies) && strategies.length > 0
                ? strategies
                : this.selectedStrategies;

        this.accountService.setAccountProperty(
            this.selectedStrategiesAccountKey,
            // to prevent mutation issues we'll copy the array
            [...update]
        );

        // using the NGRX stored account. No need to mutate the Account object.
        return this.accountService.saveAccount(
            undefined,
            true,
            this.accountEndPoints
        );
    }

    public saveAccountStrategies(
        strategies?: AccountStrategies
    ): Observable<Account> {
        const update: AccountStrategies = strategies
            ? strategies
            : this.accountStrategies;

        this.accountService.setAccountProperty(
            this.accountStrategyAccountKey,
            update
        );

        // using the NGRX stored account. No need to mutate the Account object.
        return this.accountService.saveAccount(
            undefined,
            true,
            this.accountEndPoints
        );
    }

    /**
     * Performs a deep recursive filter of all parent and children of the strategy level array
     * Currently only supports the funded range
     * Work in progress for mass feature support
     * @param strategyLevels recursive parameter for strategy levels
     */
    private filterFundedRangeInvestmentPrograms(
        strategyLevels: StrategyLevel[]
    ) {
        const deleteIndex: number[] = [];

        strategyLevels.forEach((level, index) => {
            if (level.strategyLevels && level.strategyLevels.length > 0) {
                this.filterFundedRangeInvestmentPrograms(level.strategyLevels);
            }

            if (level.strategies && level.strategies.length > 0) {
                level.strategies = level.strategies.filter(
                    (strategy: Strategy) =>
                        strategy.minimum <= this.selectedFundedRange.maxValue &&
                        strategy.minimum >= this.selectedFundedRange.minValue
                );

                // mark the index of the level if no strategies exist.
                if (!level.strategies || level.strategies.length < 1) {
                    deleteIndex.push(index);
                }
            }
        });

        // since we are going to remove levels, we want to remove from bottom up
        // so reverse the delete array
        _.reverse(deleteIndex);
        // Remove any level that has no strategies
        deleteIndex.forEach((index: number) => strategyLevels.splice(index, 1));
    }

    /**
     * GEts the Investment Programs and Strategies that are available to an account
     * Endpoint returns as an array, so there will always be a sub 0 to start off with
     * @param accountNumber account number to fetch, will pull from store if undefined
     */
    public getInvestmentPrograms(
        accountNumber?: number
    ): Observable<InvestmentProgram> {
        if (!accountNumber) {
            accountNumber = this.accountService.accountNumber;
        }

        return this.investmentProgramRepository.read(accountNumber).pipe(
            share(),
            tap((program: InvestmentProgram) => {
                this.setInvestmentProgram(program[0]);
                if (
                    program[0].strategyLevels &&
                    program[0].strategyLevels.length > 0
                ) {
                    this.levelCount = 0;
                    this.calculateLevelCount(
                        program[0].strategyLevels[this.levelCount]
                    );
                }
            })
        );
    }

    public getInvestmentProgramsByAccountTypeId(
        accountTypeId: string
    ): Observable<HttpResponse<SeiPayload>> {
        return this.global.httpService.getData(
            this.global.configService.routeFormatter(
                this.global.configService.getEndPoint('InvestmentPrograms'),
                [
                    {
                        key: 'firmId',
                        value: this.userProfileService.firm.firmId
                    },
                    {
                        key: 'accountTypeId',
                        value: accountTypeId
                    }
                ]
            )
        );
    }

    public getStrategyRiskLevel(strategies: Strategy[]): RiskLevel {
        let strategyRiskLevel: RiskLevel = RiskLevel.None;
        if (strategies && strategies.length > 0) {
            // TODO MVP2: For now we are taking risk level from the first strategy element
            if (strategies[0].risk) {
                switch (strategies[0].risk.toLowerCase()) {
                    case 'low risk':
                        {
                            strategyRiskLevel = RiskLevel.Low;
                        }
                        break;
                    case 'medium risk':
                        {
                            strategyRiskLevel = RiskLevel.Medium;
                        }
                        break;
                    case 'high risk':
                        {
                            strategyRiskLevel = RiskLevel.High;
                        }
                        break;
                }
            }
        }
        return strategyRiskLevel;
    }

    /**
     * recursive function to update available strategies to the selected strategies where found
     * based on starting strategy level array
     * Still work in progress
     * @param account
     * @param strategyLevel
     */
    public setSelectedStrategies(
        account: Account,
        strategyLevel: StrategyLevel[]
    ) {
        // if there are no selected strategies then simply return.
        if (
            !account.accountStrategy ||
            !account.accountStrategy.selectedStrategies
        ) {
            return;
        }
        // find the strategies. This will be where there is no more levels and there is strategies
        strategyLevel.forEach((level: StrategyLevel) => {
            if (level.strategyLevels && level.strategyLevels.length > 0) {
                this.setSelectedStrategies(account, level.strategyLevels);
            }

            if (level.strategies && level.strategies.length > 0) {
                if (this.validateYShares(account.investmentProgram)) {
                    level.strategies = this.selectStrategiesYShares(
                        level.strategies
                    );
                }

                this.updateSelectedStrategies(
                    account.accountStrategy.selectedStrategies,
                    level.strategies
                );
            }
        });
    }

    /**
     * to support recursive this does the update of the actual strategy
     * @param selectedStrategies
     * @param availableStrategies
     */
    private updateSelectedStrategies(
        selectedStrategies: Strategy[],
        availableStrategies: Strategy[]
    ) {
        selectedStrategies.forEach((selectedStrategy: Strategy) => {
            const availableStrategy = availableStrategies.find(
                (strategy: Strategy) =>
                    strategy.strategyId === selectedStrategy.strategyId
            );
            // we may not have any selected strategies in the current level so simply return
            if (!availableStrategy) {
                return;
            }

            // Only setting props that are needed incase there was an update from CRM (or sourced data);
            availableStrategy.selected = true;
            availableStrategy.substitutionInternational =
                selectedStrategy.substitutionInternational;
            availableStrategy.substitutionLargeCap =
                selectedStrategy.substitutionLargeCap;
            availableStrategy.percentage = selectedStrategy.percentage;
            // Mostly for DFS, but setting incase others need it
            availableStrategy.strategyLevels = selectedStrategy.strategyLevels;
        });
    }

    /**
     * Deep calculation to find the lowest level child and store it level id
     * Was supporting legacy strategy code but may not be needed anymore
     * Work in progress
     * @param level
     */
    private calculateLevelCount(level: StrategyLevel) {
        if (!level) {
            return;
        }

        if (level.strategyLevels && level.strategyLevels.length > 0) {
            level.strategyLevels.forEach((item: StrategyLevel) => {
                this.levelCount =
                    item.strategyLevelId &&
                        item.strategyLevelId > this.levelCount
                        ? item.strategyLevelId
                        : this.levelCount;
                this.calculateLevelCount(item);
            });
        }
    }

    /**
     * Sets the program stream to what was loaded by available strategies
     * @param strategyLevels Parent strategy level to load to
     */
    public setProgramLevelsStreams(strategyLevels: StrategyLevel[]) {
        strategyLevels.forEach((level: StrategyLevel) => {
            if (level.strategies) {
                // Clone and remove collections that would create circular JSON
                const clonedLevel = { ...level };
                delete clonedLevel.strategies;
                delete clonedLevel.strategyLevels;

                level.strategies.forEach((strategy: Strategy) => {
                    strategy.parent = clonedLevel;
                });
            }
        });

        if (!this.programLevelsStream) {
            this.programLevelsStream = new BehaviorSubject<StrategyLevel[]>(
                strategyLevels
            );
        } else {
            this.programLevelsStream.next(strategyLevels);
        }
    }

    /**
     * Sets the program strategy attributes stream to what was loaded by available strategies attributes
     * @param strategyAttributeLevels Parent strategy attribute level to load to
     */
    public setProgramStrategyAttributesLevelsStreams(
        strategyAttributeLevels: StrategyAttributeLevel[]
    ) {
        if (!this.programStrategyAttributesLevelsStream) {
            this.programStrategyAttributesLevelsStream = new BehaviorSubject<
                StrategyAttributeLevel[]
            >(strategyAttributeLevels);
        } else {
            this.programStrategyAttributesLevelsStream.next(
                strategyAttributeLevels
            );
        }
    }

    /**
     * Gets the Investment Program Strategy Attributes Observable
     */
    public get programStrategyAttributesLevelsStreaming(): Observable<
        StrategyAttributeLevel[]
    > {
        if (!this.programStrategyAttributesLevelsStream) {
            return EMPTY;
        }
        return this.programStrategyAttributesLevelsStream.asObservable();
    }

    public setSelectedStrategiesAttributes(
        account: Account,
        strategyAttributeLevel: StrategyAttributeLevel[]
    ) {
        // if there are no selected strategies then simply return.
        if (
            !account.accountStrategy ||
            !account.accountStrategy.selectedAttributes
        ) {
            return;
        }
        // find the strategies. This will be where there is no more levels and there is strategies
        strategyAttributeLevel.forEach((level: StrategyAttributeLevel) => {
            if (
                level.strategyAttributes &&
                level.strategyAttributes.length > 0
            ) {
                this.setSelectedStrategiesAttributes(
                    account,
                    level.strategyAttributes
                );
            }

            if (level.attributes && level.attributes.length > 0) {
                this.updateSelectedStrategiesAttributes(
                    account.accountStrategy.selectedAttributes,
                    level.attributes
                );
            }
        });
    }
    /**
     * Gets the Investment Programs and Strategies Attributes that are available to an account.
     * Endpoint returns as an array
     * @param accountNumber account number to fetch, will pull from store if undefined
     */
    public getStrategyAttributesAvailable(
        accountNumber?: number
    ): Observable<InvestmentStrategyAttribute> {
        if (!accountNumber) {
            accountNumber = this.accountService.accountNumber;
        }

        return this.global.httpUtilityService
            .doGet<InvestmentStrategyAttribute>(
                this.global.configService.routeFormatter(
                    this.global.configService.getEndPoint(
                        'investmentProgramsAvailableStrategyAttributes'
                    ),
                    [
                        {
                            key: 'accountNumber',
                            value: accountNumber
                        }
                    ]
                ),
                undefined,
                { returnDataKey: '' }
            )
            .pipe(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                catchError((errorResponse: any) => {
                    if (
                        errorResponse.error.error.message.includes(
                            'No available strategy attributes'
                        )
                    ) {
                        return of(undefined);
                    }
                })
            );
    }

    public displayBondStatePreferenceRebalance(
        accountStrategies: AccountStrategies
    ): boolean {
        let displayBondStatePreference: boolean = false;
        if (accountStrategies && accountStrategies.selectedStrategies) {
            const totalStrategies = accountStrategies.selectedStrategies.length;
            const totalPercent = this.getTotalPercentageAssigned(
                accountStrategies.selectedStrategies
            );

            if (
                (totalStrategies > 1 ||
                    (totalStrategies === 1 && totalPercent === 1)) &&
                this.hasSpecificMunicipalBondEligibleStrategies(
                    accountStrategies.selectedStrategies
                ) &&
                (this.propertyService.notExists(
                    () => accountStrategies.residenceState.stateId
                ) ||
                    this.residenceStateIsValidForBond(
                        accountStrategies.residenceState
                    ))
            ) {
                displayBondStatePreference = true;
            }
        }

        return displayBondStatePreference;
    }

    public displayQuarterlyRebalance(
        accountStrategies: AccountStrategies
    ): boolean {
        return (
            accountStrategies &&
            accountStrategies.selectedStrategies &&
            accountStrategies.selectedStrategies.length > 1
        );
    }

    public residenceStateIsValidForBond(state: State) {
        return state && this.bondStates.indexOf(state.stateId) !== -1;
    }

    public displayMutualRebalanceScreen(
        accountStrategies: AccountStrategies
    ): boolean {
        return (
            this.displayQuarterlyRebalance(accountStrategies) ||
            this.displayBondStatePreferenceRebalance(accountStrategies)
        );
    }

    public getQuaterlyRebalanceAttributeSummary(
        strategyAttributes: StrategyAttribute[]
    ): WipSectionSummary[] {
        const summary: WipSectionSummary[] = [];

        const attributes = this.getStrategyAttributesByPath(
            strategyAttributes,
            this.quaterlyRebalanceAttributePathName
        );

        let summaryText = '';
        if (attributes && attributes.length > 0) {
            // Quaterly Rebalancing is one element always
            summaryText = attributes[0].selected ? 'Yes' : 'No';
        }

        summary.push({
            label: 'Quarterly Rebalancing',
            text: summaryText
        });

        return summary;
    }

    public getBondPreferenceAttributeSummary(
        strategyAttributes: StrategyAttribute[]
    ): WipSectionSummary[] {
        const summary: WipSectionSummary[] = [];

        const attribute = this.getSelectedStrategyAttributesByPath(
            strategyAttributes,
            this.bondAttributePathName
        );

        let summaryText = '';
        if (attribute) {
            summaryText = attribute.strategyAttributeName;
        }

        summary.push({
            label: 'Bond Preference',
            text: summaryText
        });

        return summary;
    }

    public getSelectedStrategyAttributesByPath(
        strategyAttributes: StrategyAttribute[],
        strategyPathName: string
    ): StrategyAttribute {
        return this.getStrategyAttributesByPath(
            strategyAttributes,
            strategyPathName
        ).find((attribute: StrategyAttribute) => attribute.selected);
    }

    public getStrategyAttributesByPath(
        strategyAttributes: StrategyAttribute[],
        strategyPathName: string
    ): StrategyAttribute[] {
        if (strategyAttributes) {
            return strategyAttributes.filter(
                (attribute: StrategyAttribute) =>
                    attribute.path === strategyPathName
            );
        }
        return [];
    }

    private hasSpecificMunicipalBondEligibleStrategies(
        strategies: Strategy[]
    ): boolean {
        return (
            strategies &&
            !!strategies.find(
                (strategy: Strategy) =>
                    strategy.stateSpecificMunicipalBondEligible
            )
        );
    }

    /**
     * initialized the service on instantiation
     */
    private initializeService() {
        if (this.global && this.global.configService) {
            this.accountEndPoints = {
                read: this.global.configService.getEndPoint(
                    'investmentProgramsSelectedStrategies'
                ),

                update: this.global.configService.getEndPoint(
                    'investmentProgramsSelectedStrategies'
                )
            };
        }
    }

    private deselectLevelStrategies(levels: StrategyLevel[]) {
        // cause of recursive, we don't need to through error
        if (!levels) {
            return;
        }

        if (levels.length > 0) {
            levels.forEach((level: StrategyLevel) => {
                if (level.strategyLevels && level.strategyLevels.length > 0) {
                    this.deselectLevelStrategies(level.strategyLevels);
                }

                if (level.strategies && level.strategies.length > 0) {
                    level.strategies.forEach(
                        (strategy: Strategy) => (strategy.selected = false)
                    );
                }
            });
        }
    }

    /**
     * Update of the actual strategy attribute
     * @param selectedStrategies
     * @param availableStrategies
     */
    private updateSelectedStrategiesAttributes(
        selectedStrategiesAttributes: StrategyAttribute[],
        availableStrategiesAttributes: StrategyAttribute[]
    ) {
        selectedStrategiesAttributes.forEach(
            (selectedStrategyAttribute: StrategyAttribute) => {
                const availableStrategy = availableStrategiesAttributes.find(
                    (attribute: StrategyAttribute) =>
                        attribute.strategyAttributeId ===
                        selectedStrategyAttribute.strategyAttributeId
                );

                if (!availableStrategy) {
                    return;
                }

                availableStrategy.selected = selectedStrategyAttribute.selected;
            }
        );
    }

    private validateYShares(investmentProgram: AccountInvestmentProgram) {
        return (
            investmentProgram &&
            (investmentProgram.investmentProgramId ===
                InvestmentProgramType.MutualFunds ||
                investmentProgram.investmentProgramId ===
                InvestmentProgramType.CustomFirmMutualFunds)
        );
    }

    private selectStrategiesYShares(
        availableStrategies: Strategy[]
    ): Strategy[] {
        const useYShares = this.processingRulesService.applyYShareDisplay();

        return availableStrategies.filter((strategy: Strategy) => {
            return (
                strategy.yShare === undefined || strategy.yShare === useYShares
            );
        });
    }

    private getTotalPercentageAssigned(strategies: Strategy[]): number {
        let result = 0;

        if (strategies) {
            result = strategies.reduce((accumulator, currentValue) => {
                if (typeof currentValue.percentage === 'number') {
                    return accumulator + currentValue.percentage;
                }
            }, 0);
        }
        return result;
    }
}
