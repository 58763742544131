<sei-loading-overlay [show]="showLoadingIcon"
                     [style]="styleInput">
</sei-loading-overlay>
<div class="row standard-bottom-margin">
    <div class="col-sm-12">
        <div class="row">
            <div class="col-xs-12">
                <h1 >{{mainTitle}}</h1>
                <h2 class="text-muted">{{subtitle}}</h2>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-sm-12">
        <div class="form-group">
            <label class="control-label"
                   for="accountOwner">Account Owner</label>
            <sei-dropdown id="accountOwner"
                          name="accountOwnerDropdown"
                          [items]="accountOwnerListItems"
                          placeholder="Select"
                          [ngModelOptions]="{standalone: true}"
                          [(ngModel)]="accountOwner"
                          (displayItemsChange)="onAccountOwnerChange()"
                          [autocomplete]="true"
                          [autocompleteMessage]="autoCompleteMessage"
                          [autocompleteState]="autocompleteState"
                          [disabled]="accountOwnerDisable">
            </sei-dropdown>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-sm-12">
        <sei-pageheader [pageHeaderProperties]="taxRateHeaderProperties"
                        [headingLevel]="3"></sei-pageheader>
        <sei-alert *ngIf="selectedEntityType === 'Prospect'"
                   [showCloseIcon]="false"
                   [type]="typeWarning">
            <div [innerHTML]="contentTaxRateWarning"></div>
        </sei-alert>
        <div class="standard-bottom-margin">
            <i class="fas fa-info-circle text-info" aria-hidden="true"></i>
            <span class="text-info"> Tax rates apply across all accounts</span>
        </div>
        <div>
            <sei-car-proposal-tax-rate-form [federalIncome]="federalIncome"
                                   [stateIncome]="stateIncome"
                                   [federalCapitalGains]="federalCapitalGains"
                                   [stateCapitalGains]="stateCapitalGains"
                                   [isDisabled]="isTaxRateDisabled"
                                   (istaxRateValidEvent)="taxRateOut($event)"
                                   (taxRateDataEvent)="taxRateDataOut($event)"></sei-car-proposal-tax-rate-form>
        </div>
    </div>
</div>

<div class="row standard-bottom-margin">
    <div class="col-sm-12">
        <sei-pageheader [pageHeaderProperties]="accountDetailsHeaderProperties"
                        [headingLevel]="3"></sei-pageheader>
    </div>
</div>
<div class="row">
    <div class="col-sm-12">
        <div class="form-group">
            <label class="control-label"
                   for="currentAccount">Current Accounts</label>
            <sei-dropdown id="currentAccount"
                          name="currentAccounts"
                          [multiSelect]="true"
                          [items]="currentAccountListItems"
                          (displayItemsChange)="enableSubmitButton()"
                          placeholder="Select Current Account(s)"
                          [(ngModel)]="currAccountsSelected"
                          [autocomplete]="false"
                          [showSearch]="false"
                          [disabled]="isTaxRateDisabled">
            </sei-dropdown>
            <div>
                <i class="fas fa-info-circle text-primary" aria-hidden="true"></i>
                <span>
                Select one or more accounts for the tax transition analysis
            </span>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-sm-12">
        <div class="form-group">
            <label class="control-label"
                   for="proposedAccount">Proposed Accounts</label>
            <sei-dropdown id="proposedAccount"
                          name="proposedAccounts"
                          [multiSelect]="false"
                          [items]="proposedAccountListItems"
                          (displayItemsChange)="enableSubmitButton()"
                          placeholder="Select Proposed Account"
                          [(ngModel)]="proposedAccountSelected"
                          [autocomplete]="false"
                          [showSearch]="false"
                          [showSelectionOptions]="false"
                          [disabled]="isTaxRateDisabled">
            </sei-dropdown>
            <div>
                <i class="fas fa-info-circle text-primary" aria-hidden="true"></i>
                <span >
                Select only one proposed account for the tax transition analysis
            </span>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-sm-12">
        <div class="form-group">
            <label class="control-label"
                   for="dispositionMethod">Disposition Method</label>
            <sei-dropdown id="dispositionMethod"
                          name="dispositionMethod"
                          [multiSelect]="false"
                          [items]="dispositionMethodItems"
                          (displayItemsChange)="enableSubmitButton()"
                          [(ngModel)]="dispositionMethodSelected"
                          [autocomplete]="false"
                          [showSearch]="false"
                          [showSelectionOptions]="false"
                          [disabled]="isTaxRateDisabled">
            </sei-dropdown>
        </div>
    </div>
</div>
