/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { NotAuthorizedComponent } from '@CarComponents/exceptions/not-authorized/not-authorized.component';
import { ApigeeTokenGuard } from './core/auth/apigee-token.guard';

const appRoutes: Routes = [
    {
        path: 'NotAuthorized',
        component: NotAuthorizedComponent,
        canActivate: [ApigeeTokenGuard]
    },
    {
        path: '',
        component: NotAuthorizedComponent,
        redirectTo: 'Proposal/Dashboard',
        canActivate: [ApigeeTokenGuard]
    },
    {
        path: '*/',
        component: NotAuthorizedComponent,
        redirectTo: 'Proposal/Dashboard',
        canActivate: [ApigeeTokenGuard]
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {
            enableTracing: false,
            useHash: true
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
