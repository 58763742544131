<div class="right-modal-container">
    <div class="right-modal-content">
        <div class="right-modal-body">
            <div *ngIf="taxRateSoftWarning"
                 class="taxrate-warning standard-bottom-margin">
                <sei-banner-message [messageConfig]="bannerMessageConfig"
                                    (warningNoClick)="onModalClosed()"
                                    (warningYesClick)="confirmStoreNewTaxrates()">
                </sei-banner-message>
            </div>
            <div *ngIf="showErrorMessage"
                 class="error-banner">
                <sei-alert *ngIf="submitErrorFlag === 'generic'"
                           [showCloseIcon]="false"
                           [iconOverride]="false"
                           [type]="typeError">
                    <div [innerHTML]="contentGenericError"></div>
                </sei-alert>
            </div>
            <sei-car-proposal-tax-transition-analysis-form [proposalId]="proposalId"
                                             [proposal]="proposal"
                                             [editDataObject]="editObject"
                                             [accountOwners]="accountOwners"
                                             [mainTitle]="mainTitle"
                                             [subtitle]="subtitle"
                                             [accountOwnerDisable]="accountOwnerDisable"
                                             (disableButtonsEvent)="getDisableButtons($event)"
                                             (taxTransitionDetailsOut)="getTaxData($event)"></sei-car-proposal-tax-transition-analysis-form>
        </div>
        <div class="right-modal-footer">
            <div class="row">
                <div class="col-xs-6">
                    <sei-button [buttonText]="'Cancel'"
                                [buttonClass]="'btn-default'"
                                [buttonRightFontAwesomeIcon]="'fas fa-times-circle'"
                                (buttonClicked)="cancel()">
                    </sei-button>
                </div>
                <div class="col-xs-6">

                    <div class="row btn-toolbar pull-right">
                        <div class="col-xs-6">
                            <sei-button [buttonText]="'Save and Exit'"
                                        [buttonClass]="'btn-default'"
                                        [buttonRightFontAwesomeIcon]="'fas fa-save'"
                                        (buttonClicked)="saveAndExit()"
                                        [buttonDisabled]="isSaveExitDisabled || isReadUser"
                                        [tooltip]="getSaveAndExitTooltip()">
                            </sei-button>
                        </div>
                        <div class="col-xs-6">
                            <sei-button [buttonText]="'Submit'"
                                        [buttonClass]="'btn-default'"
                                        [buttonRightFontAwesomeIcon]="'fas fa-check'"
                                        [buttonDisabled]="isSubmitDisabled || isReadUser"
                                        (buttonClicked)="submitTaxTransitionAnalysis()"
                                        [tooltip]="getSaveAndExitTooltip()">
                            </sei-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="delete-current-account-modal">
    <sei-modal-window #submitWarningModal
                      [modalType]="'sm'"
                      [modalTitle]="'Proceed with Tax Transition Analysis'"
                      [isBackdrop]="true"
                      [isDraggable]="false"
                      [footerEnable]="true"
                      (modalClosed)="onFinalModalClosed();">
        <div class="modal-body">
            <p>On submitting this tax transition analysis, any previous Tax Analysis performed will be deleted. Do you
                wish to continue?</p>
        </div>
        <footer class="modalbox-footer">
            <button type="button"
                    id="modalClose"
                    class="btn btn-default pull-left"
                    (click)="onFinalModalClosed()">
                Cancel
            </button>
            <button type="button"
                    id="confirmSubmit"
                    class="btn btn-primary"
                    (click)="confirmSubmit()">
                Continue
            </button>
        </footer>
    </sei-modal-window>
</div>
