

/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */


import { ContactTypes } from './enums';
/**
 * Account Types Ids and Names
 * Use Account Type Service if you need the complete list
 */
export enum AccountTypes {
    // Please keep in Alpha sorted
    // only add items if you need to perform logic against a specific account type
    // Use naming pattern by suffixing ids with Id and description/name with Name
    // This is so dynamic logic can be written

    _401KIncludingRoth401KId = 10,
    _401KIncludingRoth401KName = '401(k) including Roth 401(k)',
    _403BId = 2,
    _403BName = '403(B)',
    CharitableTrustPropertyId = 1,
    CharitableTrustPropertyName = 'Charitable Trust',
    CommunityPropertyId = 3,
    CommunityPropertyName = 'Community Property',
    CommunityPropertyWithRightsOfSurvivorshipId = 4,
    CommunityPropertyWithRightsOfSurvivorshipName = 'Community Property with Rights of Survivorship',
    CrummeyTrustId = 11,
    CrummeyTrustName = 'CrummeyTrust',
    DefinedBenefitIncludingCashBalanceId = 12,
    DefinedBenefitIncludingCashBalanceName = 'Defined Benefit(including cash balance)',
    EndowmentId = 13,
    EndowmentName = 'Endowment',
    FoundationId = 15,
    FoundationName = 'Foundation',
    GrantorRetainedAnnuityTrusId = 5,
    GrantorRetainedAnnuityTrustName = 'Grantor Retained Annuity Trust',
    GrantorRetainedUnitrustId = 8,
    GrantorRetainedUnitrustName = 'Grantor Retained Unitrust',
    GuardianshipId = 6,
    GuardianshipName = 'Guardianship',
    IndividualOwnerId = 7,
    IndividualOwnerName = 'Individual Owner',
    EstateId = 14,
    EstateName = 'Estate',
    InheritedRothIraId = 16,
    InheritedRothIraName = 'Inherited Roth IRA',
    InheritedTraditionalIraId = 17,
    IraId = 18,
    IraName = 'IRA',
    IrrevocableTrustId = 20,
    IrrevocableTrustName = 'Irrevocable Trust',
    IrrevocableTrustDueToDeathId = 9,
    IrrevocableTrustDueToDeathName = 'Irrevocable Trust Due to Death',
    InheritedTraditionalIraName = 'Inherited Traditional IRA',
    JointTenantsWithRightsOfSurvivorshipId = 19,
    JointTenantsWithRightsOfSurvivorshipName = 'Joint Tenants with Rights of Survivorship',
    MoneyPurchaseId = 21,
    MoneyPurchaseName = 'Money Purchase',
    RevocableTrustId = 32,
    RevocableTrustName = 'Revocable Trust',
    RothIraId = 22,
    RothIraName = 'Roth IRA',
    SepIraId = 23,
    SepIraName = 'SEP IRA',
    SimpleIraId = 24,
    SimpleIraName = 'Simple IRA',
    SoleProprietorshipId = 25,
    SoleProprietorshipName = 'Sole Proprietorship',
    Solo401KId = 40,
    solo401KName = 'Solo 401(k)',
    PartnershipId = 26,
    PartnershipName = 'Partnership',
    PersonalTrustAgentForTrusteeId = 37,
    PersonalTrustAgentForTrusteeName = 'Personal Trust(Agent for Trustee)',
    PersonalTrustDiscretionaryId = 38,
    PersonalTrustDiscretionaryName = 'Personal Trust(Discretionary)',
    PersonalTrustInvestmentDirectedId = 39,
    PersonalTrustInvestmentDirectedName = 'Personal Trust(Investment Directed)',
    PublicCorporationId = 31,
    PublicCorporationName = 'Public Corporation',
    PrivateCorporationId = 27,
    PrivateCorporationName = 'Private Corporation',
    ProfitSharingId = 28,
    ProfitSharingName = 'Profit Sharing',
    TenantsByEntiretyId = 29,
    TenantsByEntiretyName = 'Tenants by Entirety',
    TenantsInCommonId = 30,
    TenantsInCommonName = 'Tenants in Common',
    TaxExemptNonProfitId = 33,
    TaxExemptNonProfitName = 'Tax Exempt / Non - Profit',
    TrustId = 34,
    TrustName = 'Trust',
    UgmaId = 35,
    UgmaName = 'UGMA',
    UtmaId = 36,
    UtmaName = 'UTMA'
}

// From database incase its needed for enum above
// 10	401(k) including Roth 401(k)
// 2	403(B)
// 1	Charitable Trust
// 3	Community Property
// 4	Community Property with Rights of Survivorship
// 11	Crummey Trust
// 12	Defined Benefit(including cash balance)
// 13	Endowment
// 14	Estate
// 15	Foundation
// 5	Grantor Retained Annuity Trust
// 8	Grantor Retained Unitrust
// 6	Guardianship
// 7	Individual Owner
// 16	Inherited Roth IRA
// 17	Inherited Traditional IRA
// 18	IRA
// 20	Irrevocable Trust
// 9	Irrevocable Trust Due to Death
// 19	Joint Tenants with Rights of Survivorship
// 21	Money Purchase
// 26	Partnership
// 37	Personal Trust(Agent for Trustee)
// 38	Personal Trust(Discretionary)
// 39	Personal Trust(Investment Directed)
// 27	Private Corporation
// 28	Profit Sharing
// 31	Public Corporation
// 32	Revocable Trust
// 22	Roth IRA
// 23	SEP IRA
// 24	SIMPLE IRA
// 25	Sole Proprietorship
// 40	Solo 401(k)
// 33	Tax Exempt / Non - Profit
// 29	Tenants by Entirety
// 30	Tenants in Common
// 34	Trust
// 35	UGMA
// 36	UTMA

export interface AccountTypeOptionSettings {
    accountTypeId: number;
    name: string;
    accountContactTypes?: ContactTypes[];
}

export interface AccountOwnerType {
    value: string;
    label: string;
    contactTypeId?: number;
    isNewClient?: boolean;
}

/**
 * Contains values for special login the UI needs to perform for a specific account type
 * @param recommendedWipSections WIP sections to add as recommended
 * @param fieldNameMappings Labels for fields that need to change for the account type per WIP section
 */
export const accountTypeOptionsData: AccountTypeOptionSettings[] = [
    {
        accountTypeId: AccountTypes._403BId.valueOf(),
        name: AccountTypes._403BName.valueOf(),

        accountContactTypes: [ContactTypes.Individual]

    },
    {
        accountTypeId: AccountTypes.CommunityPropertyId.valueOf(),
        name: AccountTypes.CommunityPropertyName.valueOf(),
        accountContactTypes: [ContactTypes.Individual]
    },
    {
        accountTypeId: AccountTypes.CommunityPropertyWithRightsOfSurvivorshipId.valueOf(),
        name: AccountTypes.CommunityPropertyWithRightsOfSurvivorshipName.valueOf(),
        accountContactTypes: [ContactTypes.Individual]
    },
    {
        accountTypeId: AccountTypes.GuardianshipId.valueOf(),
        name: AccountTypes.GuardianshipName.valueOf(),
        accountContactTypes: [ContactTypes.Individual]
    },
    {
        accountTypeId: AccountTypes.IndividualOwnerId.valueOf(),
        name: AccountTypes.IndividualOwnerName.valueOf(),
        accountContactTypes: [ContactTypes.Individual]
    },
    {
        accountTypeId: AccountTypes.EstateId.valueOf(),
        name: AccountTypes.EstateName.valueOf(),
        accountContactTypes: [ContactTypes.Organization]
    },
    {
        accountTypeId: AccountTypes.InheritedRothIraId.valueOf(),
        name: AccountTypes.InheritedRothIraName.valueOf(),
        accountContactTypes: [ContactTypes.Individual, ContactTypes.Organization]

    },
    {
        accountTypeId: AccountTypes.InheritedTraditionalIraId.valueOf(),
        name: AccountTypes.InheritedTraditionalIraName.valueOf(),
        accountContactTypes: [ContactTypes.Individual, ContactTypes.Organization]
    },
    {
        accountTypeId: AccountTypes.IraId.valueOf(),
        name: AccountTypes.IraName.valueOf(),
        accountContactTypes: [ContactTypes.Individual]
    },
    {
        accountTypeId: AccountTypes.JointTenantsWithRightsOfSurvivorshipId.valueOf(),
        name: AccountTypes.JointTenantsWithRightsOfSurvivorshipName.valueOf(),
        accountContactTypes: [ContactTypes.Individual]
    },
    {
        accountTypeId: AccountTypes.RothIraId.valueOf(),
        name: AccountTypes.RothIraName.valueOf(),
        accountContactTypes: [ContactTypes.Individual]
    },
    {
        accountTypeId: AccountTypes.SepIraId.valueOf(),
        name: AccountTypes.SepIraName.valueOf(),
        accountContactTypes: [ContactTypes.Individual]
    },
    {
        accountTypeId: AccountTypes.SimpleIraId.valueOf(),
        name: AccountTypes.SimpleIraName.valueOf(),
        accountContactTypes: [ContactTypes.Individual]
    },
    {
        accountTypeId: AccountTypes.PartnershipId.valueOf(),
        name: AccountTypes.PartnershipName.valueOf(),
        accountContactTypes: [ContactTypes.Organization]
    },
    {
        accountTypeId: AccountTypes.PrivateCorporationId.valueOf(),
        name: AccountTypes.PrivateCorporationName.valueOf(),
        accountContactTypes: [ContactTypes.Organization]
    },
    {
        accountTypeId: AccountTypes.TenantsByEntiretyId.valueOf(),
        name: AccountTypes.TenantsByEntiretyName.valueOf(),
        accountContactTypes: [ContactTypes.Individual]
    },
    {
        accountTypeId: AccountTypes.TenantsInCommonId.valueOf(),
        name: AccountTypes.TenantsInCommonName.valueOf(),
        accountContactTypes: [ContactTypes.Individual, ContactTypes.Organization]
    },
    {
        accountTypeId: AccountTypes.TaxExemptNonProfitId.valueOf(),
        name: AccountTypes.TaxExemptNonProfitName.valueOf(),
        accountContactTypes: [ContactTypes.Organization]
    },
    {
        accountTypeId: AccountTypes.TrustId.valueOf(),
        name: AccountTypes.TrustName.valueOf(),
        accountContactTypes: [ContactTypes.Organization]
    },

    {
        accountTypeId: AccountTypes.CharitableTrustPropertyId.valueOf(),
        name: AccountTypes.CharitableTrustPropertyName.valueOf(),
        accountContactTypes: [ContactTypes.Organization]
    },
    {
        accountTypeId: AccountTypes.GrantorRetainedAnnuityTrusId.valueOf(),
        name: AccountTypes.GrantorRetainedAnnuityTrustName.valueOf(),
        accountContactTypes: [ContactTypes.Organization]
    },
    {
        accountTypeId: AccountTypes.GrantorRetainedUnitrustId.valueOf(),
        name: AccountTypes.GrantorRetainedUnitrustName.valueOf(),
        accountContactTypes: [ContactTypes.Organization]
    },
    {
        accountTypeId: AccountTypes.IrrevocableTrustDueToDeathId.valueOf(),
        name: AccountTypes.IrrevocableTrustDueToDeathName.valueOf(),
        accountContactTypes: [ContactTypes.Organization]
    },
    {
        accountTypeId: AccountTypes.CrummeyTrustId.valueOf(),
        name: AccountTypes.CrummeyTrustName.valueOf(),
        accountContactTypes: [ContactTypes.Organization]
    },
    {
        accountTypeId: AccountTypes.EndowmentId.valueOf(),
        name: AccountTypes.EndowmentName.valueOf(),
        accountContactTypes: [ContactTypes.Organization]
    },
    {
        accountTypeId: AccountTypes.FoundationId.valueOf(),
        name: AccountTypes.FoundationName.valueOf(),
        accountContactTypes: [ContactTypes.Organization]
    },
    {
        accountTypeId: AccountTypes.IrrevocableTrustId.valueOf(),
        name: AccountTypes.IrrevocableTrustName.valueOf(),

        accountContactTypes: [ContactTypes.Organization]
    },
    {
        accountTypeId: AccountTypes.SoleProprietorshipId.valueOf(),
        name: AccountTypes.SoleProprietorshipName.valueOf(),
        accountContactTypes: [ContactTypes.Organization]
    },
    {
        accountTypeId: AccountTypes.PublicCorporationId.valueOf(),
        name: AccountTypes.PublicCorporationName.valueOf(),
        accountContactTypes: [ContactTypes.Organization]
    },

    {
        accountTypeId: AccountTypes.RevocableTrustId.valueOf(),
        name: AccountTypes.RevocableTrustName.valueOf(),
        accountContactTypes: [ContactTypes.Individual]
    },
    {
        accountTypeId: AccountTypes.UgmaId.valueOf(),
        name: AccountTypes.UgmaName.valueOf(),
        accountContactTypes: [ContactTypes.Individual]
    },
    {
        accountTypeId: AccountTypes.UtmaId.valueOf(),
        name: AccountTypes.UtmaName.valueOf(),
        accountContactTypes: [ContactTypes.Individual]
    },
    {
        accountTypeId: AccountTypes._401KIncludingRoth401KId.valueOf(),
        name: AccountTypes._401KIncludingRoth401KName.valueOf(),
        accountContactTypes: [ContactTypes.Organization]
    },
    {
        accountTypeId: AccountTypes.DefinedBenefitIncludingCashBalanceId.valueOf(),
        name: AccountTypes.DefinedBenefitIncludingCashBalanceName.valueOf(),
        accountContactTypes: [ContactTypes.Organization]
    },
    {
        accountTypeId: AccountTypes.MoneyPurchaseId.valueOf(),
        name: AccountTypes.MoneyPurchaseName.valueOf(),
        accountContactTypes: [ContactTypes.Organization]
    },
    {
        accountTypeId: AccountTypes.ProfitSharingId.valueOf(),
        name: AccountTypes.ProfitSharingName.valueOf(),
        accountContactTypes: [ContactTypes.Organization]
    },
    {
        accountTypeId: AccountTypes.Solo401KId.valueOf(),
        name: AccountTypes.solo401KName.valueOf(),
        accountContactTypes: [ContactTypes.Organization]
    }


];
