/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalService } from '@CarServices/system/global.service';
import { GenericErrorService } from '@sei/advisor-client-review-proposal-ux';
import { Observable } from 'rxjs';

/**
 * Intercepts the URLs and rewrites them if URL is a CAR related
 */
@Injectable({ providedIn: 'root' })
export class UrlRewriterInterceptor implements HttpInterceptor {

    public static KEEP_ALIVE_IGNORABLE_PATHS: string[] = ['KASSO/resources/keep-alive',
        'KASSO/resources/external-logout'];

    public static OTHER_PATHS_THAT_SHOULD_NOT_CLEAR_ERROR: string[] = ['computerequiredminmaxcash'];

    public static PATHS_THAT_SHOULD_NOT_CLEAR_GENERIC_ERROR: string[] = [
        ...UrlRewriterInterceptor.KEEP_ALIVE_IGNORABLE_PATHS, ...UrlRewriterInterceptor.OTHER_PATHS_THAT_SHOULD_NOT_CLEAR_ERROR];

    constructor(
        private readonly global: GlobalService,
        private readonly genericErrorService: GenericErrorService) { }

    public intercept(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        req: HttpRequest<any>,
        next: HttpHandler
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Observable<HttpEvent<any>> {
        const urlShouldClearGenericError =
            !UrlRewriterInterceptor.PATHS_THAT_SHOULD_NOT_CLEAR_GENERIC_ERROR.some((path) => req.url.includes(path));
        if (urlShouldClearGenericError) {
            this.genericErrorService.clearGenericError();
        }
        if (
            !this.global.configService.environment ||
            !this.global.configService.environment.carConfig ||
            !this.global.configService.environment.carConfig.rewriteKey
        ) {
            return next.handle(req);
        }

        // CAR URLS in end-point-routes.ts start with /Car will be rewritten
        const carKey = this.global.configService.environment.carConfig
            .rewriteKey;

        if (!carKey || carKey.trim() === '') {
            return next.handle(req);
        }

        if (req.url.indexOf(carKey) < 0) {
            return next.handle(req);
        }

        if (this.global.configService.environment.carConfig.apigeeEnabled) {
            const config = {
                ...{
                    rewriteKey: this.global.configService.environment.carConfig
                        .rewriteKey
                },
                ...this.global.configService.apigeeConfiguration
            };
            req = req.clone({
                url: this.global.configService.environmentRouteRewriter(
                    req.url,
                    config
                ),
                withCredentials: true
            });
        } else {
            req = req.clone({
                url: this.global.configService.environmentRouteRewriter(
                    req.url,
                    this.global.configService.environment.carConfig
                ),
                withCredentials: true
            });
        }

        return next.handle(req);
    }
}
