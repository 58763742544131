/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { CommandText, ContactTypes } from '../../model/enums';
import { ClientFactory } from '../../model/factory/client-factory';
import { Account, AccountOwner, AccountType, elasticSearchAccountOwnerData } from '../../model/proposal';
import { AccountTypeOptionsService } from '../../service/account-type-options.service';
import { DelegationService } from '../../service/delegation.service';
import { ProposalService } from '../../service/proposal.service';
import { UserProfileService } from '../../service/user-profile.service';

@Component({
    selector: 'car-option-selection',
    templateUrl: './option-selection.component.html'
})
export class OptionSelectionComponent extends ParentComponentSubscriptionManager implements OnInit {
    @Input()
    public id: number;

    @Input()
    public pickListItemText: string;

    @Input()
    public groupName: string;

    @Input()
    public checked: boolean = false;

    @Input()
    public accountData: Account;

    @Input()
    public showOwnerControls: boolean = true;

    @Output()
    public selectedData = new EventEmitter();

    public accountOwner: AccountOwner;

    public elasticSearchData: Array<{
        value: string; label: string; contactTypeId: number; isNewClient: boolean;
    }> = elasticSearchAccountOwnerData;

    public defaultAdvisorEntityId: number = undefined;

    public showAccountOwnerDropdown: boolean = true;
    public accountDataType: AccountType;


    constructor(public delegationService: DelegationService,
        public accountTypeOptionsService: AccountTypeOptionsService,
        public proposalService: ProposalService,
        private userProfileService: UserProfileService) {
        super('OptionSelectionComponent');
    }

    public ngOnInit(): void {
        if (this.accountData) {
            this.accountDataType = this.accountData.type;
            if (this.accountData.owners.length > 0) {
                this.showAccountOwnerDropdown = false;
                this.accountOwner = this.accountData.owners.find(
                    (owner) => owner.ownerRole.description === 'Primary'
                );
            }
        }
    }

    public onChange(): void {
        this.checked = !this.checked;
        this.selectedData.emit(this.accountDataType);

        // Reset owners and advisors on changing account datatype
        if (this.accountData) {
            this.accountData.owners = [];
            this.accountData.advisors = [];
            this.accountData.balance = 0;
        }
        this.delegationService.publish(CommandText.UpdateBalance);
    }

    public onAccountOwnersDropdownSelected(event): void {
        this.showAccountOwnerDropdown = false;
        this.accountOwner = new ClientFactory().createAccountOwner(1, event);

        if (this.elasticSearchData.some((owner) => owner.label === event && owner.isNewClient)) {
            const user = this.userProfileService.getCurrentUserProfile;
            this.defaultAdvisorEntityId = user.entityId;
        } else {
            this.defaultAdvisorEntityId = undefined;
        }
    }

    public getAccountOwnerName(accountContact: AccountOwner): string {
        if (accountContact.name) {
            return accountContact.name;
        } else if (accountContact.contactType.id === ContactTypes.Individual) {
            return accountContact.firstName + ' ' + accountContact.lastName;
        } else {
            return accountContact.lastName;
        }
    }
}
