/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@CarComponents/common/common.module';
import { ScheduledWithdrawalsFactory } from '@CarFactories/scheduled-withdrawals-factory';
import { AccountTypeComponent } from './account-type-section/account-type-section.component';
import { AnnualInflationSectionComponent } from './annual-inflation-section/annual-inflation-section.component';
import { CheckOptionDynamicSectionComponent } from './check-option-dynamic-section.component/check-option-dynamic-section.component';
import { DistributionReasonSectionComponent } from './distribution-reason-section/distribution-reason-section.component';
import { DistributionTypeComponent } from './distribution-type/distribution-type.component';
import { DistributionViaCheckSectionComponent } from './distribution-via-check-section/distribution-via-check-section.component';
import { DistributionViaWireAchComponent } from './distribution-via-wire-ach/distribution-via-wire-ach.component';
import { FederalWithholdingElectionComponent } from './federal-withholding-election-section/federal-withholding-election-section.component';
import { PaymentInformationSectionComponent } from './payment-information-section/payment-information-section.component';
import { StateWithholdingElectionComponent } from './state-withholding-election-section/state-withholding-election-section.component';
import { WipScheduledWithdrawalsMainComponent } from './wip-scheduled-withdrawals-main/wip-scheduled-withdrawals-main.component';
import { WithholdingComponent } from './withholding-section/withholding-section.component';

@NgModule({
    imports: [CommonModule],
    declarations: [
        WipScheduledWithdrawalsMainComponent,
        DistributionReasonSectionComponent,
        AnnualInflationSectionComponent,
        PaymentInformationSectionComponent,
        WithholdingComponent,
        FederalWithholdingElectionComponent,
        StateWithholdingElectionComponent,
        DistributionViaCheckSectionComponent,
        DistributionViaWireAchComponent,
        AccountTypeComponent,
        DistributionTypeComponent,
        CheckOptionDynamicSectionComponent
    ],
    providers: [ScheduledWithdrawalsFactory],
    exports: [WipScheduledWithdrawalsMainComponent]
})
export class WipScheduledWithDrawlsModule {}
