<div class="row">
    <div class="col-xs-12">
        <h5 *ngIf="!isHeaderLabel">{{ this.ownerLabel }}
        <a><i *ngIf="index !==0 || (index === 0 && !showElastic)"
                  (click)="onDeleteIconClicked()"
                  class="fas fa-trash-alt pull-right"
                  aria-label="Delete Account Owner">
            </i></a>
        </h5>
        <label *ngIf="isHeaderLabel">Account {{ this.ownerLabel }}
            <a><i *ngIf="(index !==0 || (index === 0 && !showElastic)) && !hideDeleteIcon"
                      (click)="onDeleteIconClicked()"
                      class="fas fa-trash-alt pull-right"
                      aria-label="Delete Account Owner">
            </i></a>
        </label>
    </div>
</div>
<div class="row">
    <div *ngIf="!showElastic"
         class="col-xs-12">
        <car-proposal-account-owner [owner]="client"
                                    [contactTypeId]="contactTypeId"
                                    (onClientChange)="onClientChanged($event)"></car-proposal-account-owner>
    </div>
    <div *ngIf="showElastic"
         class="col-xs-12">
        <sei-car-proposal-add-client *ngIf="entitySearchEnabled"
                                     [firmId]="firmId"
                                     [contactType]="owner?.contactType"
                                     (onClientAdded)="onClientAdded($event)"></sei-car-proposal-add-client>
        <sei-dropdown *ngIf="!entitySearchEnabled"
                      [items]="clientSet"
                      [additionalActionLink]="actionLinkText"
                      [ngModel]="selectedClientSet"
                      [disabled]="(!accountTypeId || accountTypeId === 0) || (requireTypeOfOwner && !this.typeOfOwnerId)"
                      (ngModelChange)="onClientDropdownSelected($event)"
                      [additionalActionLinkDisplay]="actionLinkDisplay"
                      (additionalActionLinkClicked)="onClientAdded()"></sei-dropdown>
    </div>
</div>
