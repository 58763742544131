
/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { ExistingAccountData } from '@CarModels/existing-accounts';
import { ExistingAccountsService } from '@CarServices/existing-accounts/existing-accounts.service';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { Subscription } from 'rxjs';

@Component({
    selector: 'sei-car-existing-accounts-pick-list-notification',
    templateUrl: './existing-accounts-pick-list-notification.component.html',
    styleUrls: ['./existing-accounts-pick-list-notification.component.scss']
})
export class ExistingAccountsPickListNotificationComponent extends ParentComponentSubscriptionManager implements OnInit, OnChanges {

    @Input()
    totalNumberOfExistingAccounts: number = 0;
    @Input()
    totalNumberOfProposedAccounts: number = 0;
    @Input()
    isAccountTotalBelowThreshold: boolean = true;
    public numberOfAccountsSelected: number = 0;
    private MAX_ACCOUNTS: number = 10;

    constructor(private existingAccountsService: ExistingAccountsService) {
        super('ExistingAccountsPickListNotificationComponent');
    }

    ngOnInit(): void {
        const selectedExistingAccountsSubscription: Subscription =
            this.existingAccountsService.existingAccountsToBeAddedToProposal.subscribe((existingAccounts: ExistingAccountData[]): void => {
                this.numberOfAccountsSelected = existingAccounts?.length;
            });
        this.subscriptions.push(selectedExistingAccountsSubscription);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.isAccountTotalBelowThreshold = changes?.isAccountTotalBelowThreshold?.currentValue;
    }

    public deselectAllExistingAccounts(): void {
        this.existingAccountsService.deselectAllExistingAccounts();
    }

    public selectAllExistingAccounts(): void {
        this.existingAccountsService.selectAllEligibleExistingAccounts();
    }

    public showSelectedAllButton(): boolean {
        if (this.totalNumberOfExistingAccounts > this.MAX_ACCOUNTS ||
            this.totalNumberOfExistingAccounts + this.totalNumberOfProposedAccounts > this.MAX_ACCOUNTS ||
            this.totalNumberOfExistingAccounts === this.numberOfAccountsSelected) {
                return false;
        }
        return this.isAccountTotalBelowThreshold;
    }
}
