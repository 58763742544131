/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EntitySuffix } from '../../model/contact';
import { AccountTypes, Expand } from '../../model/enums';
import { Account, AccountType, Advisor, Proposal } from '../../model/proposal';
import { GenericErrorService } from '../../service/system/generic-error.service';

@Component({
    selector: 'car-proposal-account-types',
    templateUrl: './proposal-account-types.component.html'
})
export class ProposalAccountTypesComponent implements OnInit {
    public qualifiedAccountTypeData: AccountType[];
    public nonQualifiedAccountTypeData: AccountType[];

    public selectedType: string;
    public moreQualifiedText: string;
    public moreNonQualifiedText: string;
    public showQualifiedContent: boolean;
    public showNonQualifiedContent: boolean;
    public accountTypesEnum = AccountTypes;
    public showAccountTypes: boolean;

    @Input()
    public accountId: number;

    @Input()
    public accountIndex: number;

    @Input()
    public accountTypeData: AccountType[];

    @Input()
    public selectedAccountType: AccountType;

    @Input()
    public accountData: Account;

    @Input()
    public proposal: Proposal;

    @Input()
    public accountsCount: number;

    @Input()
    public suffix: EntitySuffix[];

    @Input()
    public props: {
        id: number;
        text: string;
        groupName: string;
        checked: boolean;
    };

    @Input()
    public accountAdvisorList: Advisor[];

    @Output()
    public onDeleteAccount = new EventEmitter<number>();

    public constructor(private errorHandler: GenericErrorService) { }

    public ngOnInit(): void {
        this.moreQualifiedText = 'More';
        this.moreNonQualifiedText = 'More';
        this.showQualifiedContent = false;
        this.showNonQualifiedContent = false;
        this.initializeAccountTypesData();
    }

    public initializeAccountTypesData() {
        this.qualifiedAccountTypeData = this.getAccountTypesByTaxQualified(
            true,
            true
        );

        this.nonQualifiedAccountTypeData = this.getAccountTypesByTaxQualified(
            false,
            true
        );

        if (
            this.accountData &&
            this.accountData.type &&
            this.accountData.type.id > 0
        ) {
            this.selectedAccountType = this.accountData.type;
            this.showAccountTypes = true;
        }
    }

    public onSelectionChange(data) {
        this.showAccountTypes = true;
        this.selectedAccountType = data as AccountType;
        this.accountData.type = this.selectedAccountType;

        const isQualified = this.qualifiedAccountTypeData.some(
            (qualifiedAccountType) =>
                qualifiedAccountType.id === this.selectedAccountType.id
        );

        this.selectedType =
            isQualified
                ? this.accountTypesEnum.Qualified
                : this.accountTypesEnum.NonQualified;
    }

    public onDisplayMoreClick(taxQualified: boolean) {
        if (taxQualified) {
            if (this.moreQualifiedText === Expand.More) {
                this.qualifiedAccountTypeData = this.getAccountTypesByTaxQualified(
                    true,
                    false
                );
                this.moreQualifiedText = Expand.Less;
                this.showQualifiedContent = true;
            } else {
                this.qualifiedAccountTypeData = this.getAccountTypesByTaxQualified(
                    true,
                    true
                );
                this.moreQualifiedText = Expand.More;
                this.showQualifiedContent = false;
            }
        } else {
            if (this.moreNonQualifiedText === Expand.More) {
                this.nonQualifiedAccountTypeData = this.getAccountTypesByTaxQualified(
                    false,
                    false
                );
                this.moreNonQualifiedText = Expand.Less;
                this.showNonQualifiedContent = true;
            } else {
                this.nonQualifiedAccountTypeData = this.getAccountTypesByTaxQualified(
                    false,
                    true
                );
                this.moreNonQualifiedText = Expand.More;
                this.showNonQualifiedContent = false;
            }
        }
    }

    public onSelectedDataClick() {
        this.showAccountTypes = false;
        this.accountData.type = this.selectedAccountType;
    }

    private getAccountTypesByTaxQualified(
        taxQualified: boolean,
        filtered: boolean
    ): AccountType[] {
        // filter by taxQualified and sort by sortOrder
        const accountTypes = this.accountTypeData.sort(function (a, b) {
            return a.sortOrder - b.sortOrder;
        }).filter((key) => key.taxQualified === taxQualified);
        try {
            // return top 3 if filtered otherwise return all
            if (filtered) {
                return accountTypes.slice(0, 3);
            }

            return accountTypes;
        } catch (e) {
            this.errorHandler.setGenericError({ code: '', description: e });
        }
    }

    public deleteAccount(): void {
        this.onDeleteAccount.emit(this.accountIndex);
    }
}
