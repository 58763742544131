/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { GlobalService } from '@CarServices/system/global.service';
import { Observable } from 'rxjs';
import { ApigeeTokenService } from '../auth/apigee-token.service';

/**
 * Sets the file upload header
 */
@Injectable({
    providedIn: 'root'
})
export class UploadFileInterceptor implements HttpInterceptor {
    constructor(
        private readonly global: GlobalService,
        private readonly apigeeTokenService: ApigeeTokenService
    ) { }
    public intercept(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        req: HttpRequest<any>,
        next: HttpHandler
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Observable<HttpEvent<any>> {
        // Prevent invalid headers during OAuth JWT request
        if (this.apigeeTokenService.isOauthRequest(req)) {
            return next.handle(req);
        }

        // Override the headers if post upload request is raised
        if ((req.url.endsWith('uploadFiles') ||
            req.url.endsWith('submitFiles') ||
            req.url.endsWith('firmlogo') ||
            req.url.includes('coverPageImages') ||
            req.url.includes('advisorCustomContent') ||
            req.url.endsWith('uploadtaxlot'))
            && req.method === 'POST') {
            if (req.headers.has('Content-Type')) {
                req = req.clone({
                    headers: req.headers.delete('Content-Type')
                });
            }

            if (!this.global.isBrowserInternetExplorer()) {
                req = req.clone({
                    headers: req.headers.set('enctype', 'multipart/form-data')
                });
            }

            req = req.clone({
                headers: req.headers.set('Accept', '*/*')
            });
        }
        return next.handle(req);
    }
}
