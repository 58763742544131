/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Orientation } from '../common/proposal-presentation-checklist.enums';
import { ProposalPresentationChecklistService } from '../common/proposal-presentation-checklist.service';
import { ProposalPresentationChecklistButtonComponent } from '../proposal-presentation-checklist-button/proposal-presentation-checklist-button.component';
import { ChecklistZoomSliderComponent } from './checklist-zoom-slider/checklist-zoom-slider.component';

@Component({
    standalone: true,
    selector: 'sei-car-proposal-presentation-checklist-footer',
    templateUrl: './proposal-presentation-checklist-footer.component.html',
    styleUrls: ['./proposal-presentation-checklist-footer.component.scss'],
    imports: [
        ProposalPresentationChecklistButtonComponent,
        ChecklistZoomSliderComponent
    ]
})
export class ProposalPresentationChecklistFooterComponent {
    @Input()
    public proposalId: number;
    private orientation: Orientation = Orientation.Portrait;

    constructor(private router: Router,
        private proposalPresentationChecklistService: ProposalPresentationChecklistService
    ) {

    }

    public onBackButtonClick(): void {
        this.router.navigateByUrl(`Proposal/WIP/${this.proposalId}`);
    }

    public onOrientationClick(): void {
        switch (this.orientation) {
            case Orientation.Portrait:
                this.orientation = Orientation.Landscape;
                break;
            case Orientation.Landscape:
                this.orientation = Orientation.Unbound;
                break;
            case Orientation.Unbound:
                this.orientation = Orientation.Portrait;
                break;
            default:
                this.orientation = Orientation.Portrait;
        }
        this.proposalPresentationChecklistService.setActiveOrientation(this.orientation);
    }

    public onZoomValueChange(value: number): void {
        document.documentElement.style.setProperty('--zoom-multiplier', value.toFixed(2));
    }
}
