/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
import { FeatureFlagService } from '@CarServices/feature-flag/feature-flag.service';
import { Component, Input, OnInit } from '@angular/core';
import { Proposal } from '@sei/advisor-client-review-proposal-ux';
import { ParentComponentSubscriptionManager, SubpageheaderModel } from '@sei/common-swp-components-lib-ux';
import { Scenario } from '@sei/ias-applications-lib-ux';

@Component({
    selector: 'sei-car-proposed-accounts-section',
    templateUrl: './proposed-accounts-section.component.html',
    styleUrls: ['./proposed-accounts-section.component.scss']
})
export class ProposedAccountsSectionComponent extends ParentComponentSubscriptionManager implements OnInit {

    @Input()
    public scenario: Scenario;
    @Input()
    public proposal: Proposal;
    public sectionHeaderProperties: SubpageheaderModel = {
        title: 'Proposed Accounts',
        switcherAvailable: false
    };
    public isExistingAccountsFeatureEnabled: boolean = false;

    constructor(private featureFlagService: FeatureFlagService) {
        super('ProposedAccountsSectionComponent');
    }

    ngOnInit(): void {
        this.isExistingAccountsFeatureEnabled = this.featureFlagService.checkExistingAccountsSectionEnabled();
    }


}
