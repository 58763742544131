/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@CarComponents/common/common.module';
import { AccountListComponent } from './account-list/account-list.component';
import { PrintDialogFooterComponent } from './print-dialog-footer/print-dialog-footer.component';
import { PrintDialogComponent } from './print-dialog-main/print-dialog-main.component';
import { DisplayDocumentComponent } from './display-document/display-document.component';
import { AccountPrintViewComponent } from './account-print-view/account-print-view.component';

@NgModule({
    imports: [CommonModule],
    declarations: [
        PrintDialogComponent,
        AccountListComponent,
        AccountPrintViewComponent,
        PrintDialogFooterComponent,
        DisplayDocumentComponent
    ],
    providers: [],
    exports: [PrintDialogComponent]
})
export class PrintDialogModule {}
