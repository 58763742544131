<span class="margin-left" *ngIf="!includeBackground && isFeeIconVisible()"><i aria-hidden="true" class="fas fa-info-circle fa-left text-info"
        [tooltip]="popOverContent"
        containerClass="asset-allocation-breakup"
    ></i>
    </span>
<span class="margin-left" *ngIf="includeBackground && isFeeIconVisible()">
        <span class="fa-stack">
            <i aria-hidden="true"
            class="fas fa-circle fa-stack-1x fa-left background-white"></i>
            <i aria-hidden="true"
            class="fas fa-info-circle fa-stack-1x fa-left text-info"
            [tooltip]="popOverContent"
            containerClass="asset-allocation-breakup"></i>
        </span>
</span>


<ng-template #popOverContent>
    <div>
        <div *ngFor="let fee of managementFeeBreakdown"
             class="row">
            <div class="col-xs-9">{{fee.label}}</div>
            <div class="col-xs-3 pull-right text-right">
                {{ getDisplayPercent(fee.rate) | proposalFormatPercentWithLessThan:proposalPercentFormatOptions }}
            </div>
        </div>
    </div>
</ng-template>
