<section id="cover-page"
         class="us-letter">
    <div class="firm-logo">
        <img *ngIf="previewImage" [src]="previewImage" alt="Brand Logo">
    </div>
        <div class="top-right-accent"
             [ngStyle]="{'background-color': themeColor}"></div>
        <div class="cover-page-details">
            <div class="cover-title">
                <span *ngIf="isCoverTitleDataVisible">{{coverTitleData}}</span>
            </div>
            <div class="cover-subtitle"
                 [ngStyle]="{'border-left-color': themeColor}">
                <div class="subtitle-text">
                    <p *ngIf="showCoverSubtitle">
                        {{coverSubtitle}}
                    </p>
                </div>
                <div class="client"
                     #client>
                    {{clientName}}
                </div>
            </div>
            <div class="date">
                <p>
                    {{dateNow | date :'longDate'}}
                </p>
            </div>
        </div>
        <div class="bottom-left-accent"
             [ngStyle]="{'background-color': themeColor}"></div>
        <div class="advisor-details">
            <p>
                <span class="presented-by">PRESENTED BY<br></span>
                <br>
                <strong>
                {{advisorName}}<br>
                </strong>
                <span>{{firmName}}</span><br>
                {{advisorEmail}}<br>
                {{advisorPhone}}
            </p>
        </div>

</section>
