/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EntityDropdownEsResult } from '@sei/client-account-administration-lib-ux';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import * as _ from 'lodash';
import { ToggleButton } from '../../common/toggle-button/toggle-button';
import { AccountTypeOptionSettings } from '../../model/account-type-options';
import { EntitySuffix } from '../../model/contact';
import { ElasticSearchRelatedPartyResult } from '../../model/elastic-search-related-party';
import { ContactTypes, ContactTypesDescription, ElasticControlType, IndividualOrganization } from '../../model/enums';
import { ClientFactory } from '../../model/factory/client-factory';
import { Account, AccountOwner, Client, Proposal, Scenario, Type, elasticSearchAccountOwnerData } from '../../model/proposal';
import { ContactTypeUtilityService } from '../../service/contact-type-utility.service';
import { GenericErrorService } from '../../service/system/generic-error.service';

@Component({
    selector: 'car-proposal-clients-form',
    templateUrl: './proposal-clients-form.component.html'
})
export class ProposalClientsFormComponent extends ParentComponentSubscriptionManager implements OnInit {
    @Input()
    public proposal: Proposal;

    @Input()
    public suffix: EntitySuffix[];

    @Input()
    public disabledAddClient: boolean = false;

    @Input()
    public isEditProposal: boolean = false;

    @Input()
    public accountTypeId: number;

    @Output()
    public clientsChanged = new EventEmitter();

    @Output()
    public clientsModelChanged = new EventEmitter();

    public elasticSearchData: Array<{
        contactId: number;
        name: string;
    }> = elasticSearchAccountOwnerData;
    public showClientAdditionDropDown: boolean = true;
    public shouldDisplayClientForm: boolean = false;
    public clientsWithToggleType: number[] = [];
    public clientsToLoad: Client[] = [];
    public submitted: boolean = false;
    public elasticControlTypes: typeof ElasticControlType = ElasticControlType;
    public toggleOptions: Array<ToggleButton<IndividualOrganization>> = [
        {
            label: 'Ind',
            data: 1
        },
        {
            label: 'Org',
            data: 2
        }
    ];
    public toggleOptionSelected: IndividualOrganization = this.toggleOptions[0].data;
    public firmId: number;
    public showDeleteConfirmPopover: boolean = false;
    private clientIndexToDelete: number;
    public contactTypes: typeof ContactTypes = ContactTypes;
    public contactType: Type = {
        id: ContactTypes.Individual,
        description: ContactTypes[ContactTypes.Individual]
    };

    constructor(
        private readonly errorHandler: GenericErrorService,
        private readonly contactTypeUtilityService: ContactTypeUtilityService
    ) {
        super('ProposalClientsFormComponent');
    }

    public ngOnInit(): void {
        this.initializeComponent();
    }

    private initializeComponent(): void {
        if (this.isEditProposal) {
            this.setAccountOwners();
            this.shouldDisplayClientForm = true;
            this.showClientAdditionDropDown = false;
        }
        this.firmId = (this.proposal.firm && this.proposal.firm.firmId)
            ? this.proposal.firm.firmId
            : this.proposal.firmId;

        this.setContactType();
    }

    private setContactType(): void {
        this.contactType = this.contactTypeUtilityService.getContactType(ContactTypes.Individual);
        const accountTypeSettings: AccountTypeOptionSettings = this.contactTypeUtilityService.getContactTypeFromAccountTypeId(
            this.accountTypeId
        );

        if (accountTypeSettings) {
            const contactTypes: ContactTypes[] = accountTypeSettings.accountContactTypes;
            const minimumContactTypesRequired: number = 1;

            if (contactTypes.length === minimumContactTypesRequired) {
                // eslint-disable-next-line @typescript-eslint/tslint/config
                const [contactType] = contactTypes;

                if (this.contactTypeUtilityService.isOrganization(contactType)) {
                    this.contactType = this.contactTypeUtilityService.getContactType(ContactTypes.Organization);
                }
            }
        }
    }

    private setAccountOwners(): void {
        const accountOwners: number[] = [];

        this.proposal.scenarios.forEach((scenario: Scenario) => {
            scenario.accounts.forEach((account: Account) => {
                account.owners.forEach((owner: AccountOwner) => {
                    if (owner.contactId) {
                        accountOwners.push(owner.contactId);
                    }
                });
            });
        });

        this.proposal.clients.forEach((client: Client) => {
            client.isAccountOwner = accountOwners.includes(client.id);
        });
    }

    public deleteClient(index): void {
        this.deleteClientByIndex(index);
    }

    private deleteClientByIndex(index: number): void {
        this.clientsToLoad = _.cloneDeep(this.proposal.clients);
        this.clientsToLoad.splice(index, 1);
        this.proposal.clients = this.clientsToLoad;

        if (this.proposal.clients.length > 0) {
            this.disabledAddClient = false;
        } else {
            this.disabledAddClient = true;
        }
        this.clientsModelChanged.emit();
    }

    private addClient(newClient: Client): void {
        this.toggleOptionSelected = newClient.contactType.description === ContactTypesDescription.Organization ?
            this.toggleOptions[1].data : this.toggleOptions[0].data;
        this.proposal.clients.push(newClient);
        this.shouldDisplayClientForm = true;
        this.showClientAdditionDropDown = false;
        this.onClientsChange();
    }

    public onToggleChange(event, i): void {
        const client = this.proposal.clients[i];
        if (IndividualOrganization.Individual === event) {
            client.isIndividual = true;
            client.organizationName = '';
            client.contactType = {
                id: ContactTypes.Individual,
                description: ContactTypesDescription.Individual
            };
        }
        if (IndividualOrganization.Organization === event) {
            client.isIndividual = false;
            client.firstName = '';
            client.lastName = client.organizationName;
            client.middleInitial = '';
            client.contactType = {
                id: ContactTypes.Organization,
                description: ContactTypesDescription.Organization
            };
        }
    }

    public onEntitySelect(clientEntity: EntityDropdownEsResult): void {
        const newClient = new ClientFactory().clientFromEntityDropdownEsResult(clientEntity);
        this.addClient(newClient);
    }

    public onNewEntityName(clientEntity: ElasticSearchRelatedPartyResult): void {
        // NOTE: ES is prepending a space to last name for some reason
        const removeFirstSpacesRegex: RegExp = /^\s/g;
        if (clientEntity.attributes.partyName.lastName) {
            clientEntity.attributes.partyName.lastName = clientEntity.attributes.partyName.lastName.replace(removeFirstSpacesRegex, '');
        }
        const newClient = new ClientFactory().clientFromElasticSearchRelatedPartyResult(clientEntity);

        if (this.contactTypeUtilityService.isOrganization(this.contactType.id)) {
            newClient.contactType.id = this.contactType.id;
            newClient.contactType.description = this.contactType.description;
            newClient.isIndividual = false;
        }

        this.addClient(newClient);
    }

    public onClientsChange(): void {
        this.clientsChanged.emit(true);
    }

    public onAddClientButtonClick(): void {
        this.showClientAdditionDropDown = true;
    }

    public modelChanged(): void {
        this.clientsModelChanged.emit();
    }

    public onElasticSearchError(event): void {
        this.errorHandler.setGenericError({ code: '', description: event });
    }

    public onConfirmDeleteClientClick(): void {
        this.deleteClientByIndex(this.clientIndexToDelete);
        this.showDeleteConfirmPopover = false;
    }

    public setCurrentClientToDelete(clientIndex: number): void {
        this.clientIndexToDelete = clientIndex;
    }
}
