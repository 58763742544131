 <div class="row">
     <div class="col-xs-12 form-group">
         <sei-car-entity-search [firmId]="firmId"
                                [contactType]="contactType.id"
                                [controlType]="elasticControlTypes.Clients"
                                (onEntitySelect)="onEntitySelect($event)"
                                (onNewEntityName)="onNewEntityName($event)"
                                (onError)="onElasticSearchError($event)"></sei-car-entity-search>
     </div>
 </div>
