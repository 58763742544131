/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { DefaultKassoCallbacks, Global, KassoService } from '@sei/common-swp-components-lib-ux';
import { HttpUtilityService } from '../http/http-utility.service';
import { HttpService } from '../http/http.service';
import { ConfigurationService } from './configuration.service';
import { LoggerService } from './logger.service';

export enum EnvironmentMode {
    Dev = 'DEV',
    Qa = 'QA',
    Pilot1 = 'PILOT-1',
    Pilot2 = 'PILOT-2',
    Prod = 'PROD'
}

@Injectable({
    providedIn: 'root'
})
export class GlobalService extends Global {
    private devEnvironmentTag = 'DEV';

    constructor(
        private readonly seiLogger: LoggerService,
        private readonly config: ConfigurationService,
        private readonly http: HttpService,
        private readonly httpUtility: HttpUtilityService,
        private readonly kasso: KassoService,
        private readonly defaultKassoCallbacks: DefaultKassoCallbacks
    ) {
        super();
    }

    // Accessor for logger services. Extends SEI Logger
    public get logger(): LoggerService {
        return this.seiLogger;
    }

    // exposing if needed, this may change in the future
    public get configService(): ConfigurationService {
        return this.config;
    }

    // exposing if needed, this may change in the future
    public get httpService(): HttpService {
        return this.http;
    }

    public get httpUtilityService(): HttpUtilityService {
        return this.httpUtility;
    }

    public get kassoService(): KassoService {
        return this.kasso;
    }

    public get kassoCallBackService(): DefaultKassoCallbacks {
        return this.defaultKassoCallbacks;
    }

    public startup(): void {
        // prevent initialization of kasso if the app is running in the DEV server
        if (this.config.getOption('mode') === this.devEnvironmentTag) {
            this.logger.error('KASSO not enabled');
            return;
        }
        // Adding try/catch hack to till KASSO is working. Right now common controls is not working
        try {
            const appName = this.configService.environment.authConfig
                .kassoAppName;

            if (!appName) {
                throw new Error(
                    'KASSO Application Name not supplied in configuration.'
                );
            }

            this.kassoService.startKasso(appName, this.kassoCallBackService);
            this.logger.log(
                `kasso timeout: ${this.kassoService.getTimeoutValue}`
            );
        } catch (error) {
            this.logger.error(`KASSO Error: ${error}`);
        }
    }
}
