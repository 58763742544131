/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DropdownItem } from '@sei/common-components-lib-ux';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { OrderedSet } from 'immutable';
import * as _ from 'lodash';
import { CurrentAdvisor } from '../../model/advisor-selected';
import { Advisor } from '../../model/proposal';

@Component({
    selector: 'sei-car-advisor-dropdown',
    templateUrl: './advisor-dropdown.component.html'
})

export class AdvisorDropdownComponent extends ParentComponentSubscriptionManager implements OnInit {
    @Input() advisors: Advisor[];
    @Input() public selectedIds: number[];
    @Input() public currentAdvisors: CurrentAdvisor[];
    @Output() public onAdvisorsChange: EventEmitter<Advisor[]> = new EventEmitter<Advisor[]>();

    public primaryAdvisorDropdownItems: OrderedSet<DropdownItem> = OrderedSet<DropdownItem>([]);
    public additionalAdvisorDropdownItems: OrderedSet<DropdownItem> = OrderedSet<DropdownItem>([]);
    public selectedPrimaryAdvisorSet: OrderedSet<DropdownItem> = OrderedSet<DropdownItem>([]);
    public selectedAdditionalAdvisorSet: OrderedSet<DropdownItem> = OrderedSet<DropdownItem>([]);
    private MAX_ADDITIONAL_ADVISOR_SIZE: number = 4;

    constructor() {
        super('AdvisorDropdownComponent');
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.advisors?.forEach((advisor: Advisor): void => {
            const advisorDropdown: DropdownItem = new DropdownItem(advisor?.id, advisor?.name);
            this.primaryAdvisorDropdownItems = this.primaryAdvisorDropdownItems?.add(_.cloneDeep(advisorDropdown));
            this.additionalAdvisorDropdownItems = this.additionalAdvisorDropdownItems?.add(_.cloneDeep(advisorDropdown));
        });
        this.currentAdvisors?.forEach((currentAdvisor: CurrentAdvisor, index: number) => {
            const advisorId: number = currentAdvisor?.advisorSelected?.id;
            if (index === 0) {
                const selectedAdvisor: DropdownItem =
                    this.primaryAdvisorDropdownItems?.find((dropdownItem: DropdownItem): boolean => dropdownItem?.id === advisorId);
                if (selectedAdvisor) {
                    this.selectedPrimaryAdvisorSet = this.selectedPrimaryAdvisorSet?.add(selectedAdvisor);
                }
            } else {
                const selectedAdvisor: DropdownItem =
                    this.additionalAdvisorDropdownItems?.find((dropdownItem: DropdownItem): boolean => dropdownItem?.id === advisorId);
                if (selectedAdvisor) {
                    this.selectedAdditionalAdvisorSet = this.selectedAdditionalAdvisorSet?.add(selectedAdvisor);
                }
            }
        });
        this.onPrimaryAdvisorChange(this.selectedPrimaryAdvisorSet);
    }

    public onPrimaryAdvisorChange(primaryAdvisorSet: OrderedSet<DropdownItem>) {
        this.selectedPrimaryAdvisorSet = primaryAdvisorSet ? primaryAdvisorSet : OrderedSet([]);
        this.primaryAdvisorDropdownItems?.forEach((item: DropdownItem): void => {
            item.disabled = false;
        });
        this.onAdditionalAdvisorsDropdownChange(this.selectedAdditionalAdvisorSet);
    }

    public onAdditionalAdvisorsDropdownChange(advisors: OrderedSet<DropdownItem>): void {
        this.selectedAdditionalAdvisorSet = advisors ? advisors : OrderedSet([]);
        this.additionalAdvisorDropdownItems?.forEach((item: DropdownItem): void => {
            item.disabled = false;
        });
        this.setListOfDropdownAdvisorsDisabled(this.primaryAdvisorDropdownItems, this.selectedAdditionalAdvisorSet);
        if (this.selectedAdditionalAdvisorSet?.size >= this.MAX_ADDITIONAL_ADVISOR_SIZE) {
            this.additionalAdvisorDropdownItems?.forEach((item: DropdownItem) => {
                if (!this.selectedAdditionalAdvisorSet?.some((selectedItem: DropdownItem) => item?.id === selectedItem?.id)) {
                    item.disabled = true;
                }
            });
        } else {
            this.setListOfDropdownAdvisorsDisabled(this.additionalAdvisorDropdownItems, this.selectedPrimaryAdvisorSet);
        }
        this.emitListOfAdvisors();
    }

    private emitListOfAdvisors(): void {
        const advisorSet: OrderedSet<DropdownItem> =
            this.selectedPrimaryAdvisorSet?.concat(this.selectedAdditionalAdvisorSet)?.toOrderedSet();
        const advisorList: Advisor[] = [];
        advisorSet.forEach((advisorItem: DropdownItem) => {
            const advisor: Advisor = this.advisors?.find((value: Advisor) => value?.id === advisorItem?.id);
            if (advisor) {
                advisorList.push(advisor);
            }
        });
        this.onAdvisorsChange.emit(advisorList);
    }

    private setListOfDropdownAdvisorsDisabled(
        advisorSet: OrderedSet<DropdownItem>,
        advisorsToDisable: OrderedSet<DropdownItem>) {
        advisorSet?.forEach((advisorDropdownItem: DropdownItem) => {
            if (advisorsToDisable?.some((advisorToDisable: DropdownItem) => advisorToDisable?.id === advisorDropdownItem?.id)) {
                advisorDropdownItem.disabled = true;
            } else {
                advisorDropdownItem.disabled = false;
            }
        });
    }
}
