<span class="wip-menu-icon menu-ellipsis">
    <span containerClass="action-tooltip-popover"
          placement="bottom"
          container="body"
          triggers="manual"
          [popover]="options_tooltip_template"
          [outsideClick]="true"
          [isOpen]="showOptionsPopover"
          (click)="onOptionsToggleClick()"
          #pop="bs-popover">
        <i class="fa fas fa-ellipsis-h"
            [class.no-padding]="noPadding"></i>
    </span>

    <ng-template #options_tooltip_template>
        <div class="options-tooltip-panel">
            <span class="option"
                  *ngFor="let option of filterOptionsIfReadOnlyProposal()"
                  [class.disabled]="option.disabled">

                <h2 *ngIf="!option.removeOptions" (click)="onOptionClick(option.label)">
                    {{option.label}}
                </h2>

                <h2 *ngIf="option.removeOptions"
                    containerClass="action-tooltip-popover"
                    placement="right"
                    container="body"
                    [popover]="remove_popopver"
                    #pop="bs-popover">
                    {{option.label}}
                </h2>

                <ng-template #remove_popopver>
                    <div class="delete-tooltip-panel">
                        <div class="row">
                            <div class="col-xs-12">
                                <h3> {{option.removeOptions.titleText}} </h3>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 car-main-sm-body-copy form-group delete-text">
                                {{option.removeOptions.bodyText}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 car-main-sm-body-copy form-group">
                                <sei-button class="btn-in-tooltip"
                                            buttonText="Cancel"
                                            buttonLeftFontAwesomeIcon="fas fa-times-circle"
                                            buttonClass="btn-default"
                                            (buttonClicked)="pop.hide()">
                                </sei-button>
                                <sei-button class="btn-in-tooltip"
                                            buttonText="Remove"
                                            buttonRightFontAwesomeIcon="far fa-trash-alt trash-button-icon"
                                            buttonClass="btn-default"
                                            (buttonClicked)="onRemoveClick()">
                                </sei-button>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </span>


        </div>
    </ng-template>
</span>
