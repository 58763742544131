/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WipAccountStatus, WipChecklistLabels } from '../../model/enums';
import { WipCheckList } from '../../model/proposal';

@Component({
    selector: 'car-wip-checklist-actions',
    templateUrl: './wip-checklist-actions.component.html'
})
export class WipChecklistActionsComponent {
    public sectionLabels: typeof WipChecklistLabels = WipChecklistLabels;
    public wipAccountStatus: typeof WipAccountStatus = WipAccountStatus;

    @Input()
    public wipCheckList: WipCheckList;

    @Input()
    public sectionType: string;

    @Input()
    public buttonDisabled: boolean = false;

    @Output()
    public onExpandDetail = new EventEmitter();

    public showDetail: boolean = false;


    public onSkipClick() {
        this.updateWipChecklist(this.wipCheckList, WipAccountStatus.Complete, true);
    }

    private updateWipChecklist(wipCheckList: WipCheckList, status: number, disabled: boolean) {
        const currentSnapShot = wipCheckList.mdcSnapShot;
        currentSnapShot.completed = status;
        currentSnapShot.disabled = disabled;
    }

    public onShowDetailClick(): void {
        this.showDetail = !this.showDetail;
        this.onExpandDetail.emit();
    }
}
