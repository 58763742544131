<div class="row account-ribbon">
    <div *ngIf="(index===0)" class="text-right col-sm-4">
        <p>From $0 to</p>
    </div>
    <div *ngIf="(index>0 && tier.highValue !== null)" class="text-right col-sm-4">
        <p>From {{tier.lowValue | currency: '$'}} to</p>
    </div>
    <div *ngIf="(index>0 && tier.highValue === null)" class="text-right col-sm-4">
        <p>Above</p>
    </div>
    <div class="fee-tier-ribbon" *ngIf="(tier.highValue !== null)">
        <input seiEditMonetaryAmount carSelectOnFocus type="text" id="{{'tier_input_' + feeGroupId + '_' + index}}"
            name="{{'tier_input_' + feeGroupId + '_' + index}}" [amountOptions]="amountOptions"
            [actualCurrency]="currencyCode" [value]="tier.highValue" (blur)="ontierAmountValidation($event)"
            (output)="setMonetaryAmount($event, index)" class="input-fee-12" ngDefaultControl placeholder="$"
            disabled />
        <!-- <car-alert-popover [hidden]="this.tier.isValid"
                           [alertType]="alertPopoverTypes.Error"
                           [alertMessage]="balanceErrorMessage"></car-alert-popover> -->
    </div>
    <div class="fee-tier-ribbon" *ngIf="(tier.highValue === null)">
        <p>{{tier.lowValue | currency: '$'}}</p>
    </div>
    <div class="text-right col-sm-2">
        <p>Charge</p>
    </div>
    <div class="fee-tier-ribbon" *ngIf="chargeInputView">
        <input seiEditPercent maxDecimalPlaces="2" type="text" class="input-fee" placeholder="%" id="{{'tier' + index}}"
            placeholder="%" name="{{'tier' + index}}" (change)="onInputPercentageValueChange($event, index)"
            [value]="tier.rate / 100" ngDefaultControl readonly />
        <!-- <i *ngIf="(tier.highValue !== null)" (click)=deleteTier(index) [hidden]="(index===0 || tier.highValue=== null)"
       class="fas fa-trash-alt tier-margin-left"></i> -->
    </div>
    <div class="fee-tier-ribbon" *ngIf="!chargeInputView">
        <input readonly type="text" class="input-fee" min="0" max="300" [(ngModel)]="tier.rate"
            (change)="onInputBPSValueChange()" ngDefaultControl />
        <!-- <i *ngIf="(tier.highValue !== null)" (click)=deleteTier(index) [hidden]="(index===0 || tier.highValue=== null)"
          class="fas fa-trash-alt tier-margin-left"></i> -->
    </div>
</div>