/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
import { Component, Input, OnInit } from '@angular/core';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { HudFeeSchedule } from '../model/account-fee';
import {
    CommandText, InvestmentProgramType, InvestmentProgramTypes,
    RiskLevelValues,
    RiskLevels,
    RiskMethods, RiskToleranceLevel,
    RiskWidgetTypes, ServerErrorStatus, StrategyMenu
} from '../model/enums';
import { HudSummaryFactory } from '../model/factory/hud-summary-factory';
import { Account, CombinedStrategyRisk, RiskTolerance, Strategy, Type } from '../model/proposal';
import { DelegationService } from '../service/delegation.service';
import { StrategiesService } from '../service/strategies.service';

@Component({
    selector: 'car-strategy-selection',
    templateUrl: './strategy-selection.component.html'
})

export class StrategySelectionComponent extends ParentComponentSubscriptionManager implements OnInit {

    @Input()
    public strategyCategories: Type[];

    @Input()
    public account: Account;

    @Input()
    public riskPreference: number;

    @Input()
    public filteredStrategies: Strategy[];

    @Input()
    public firmId: number;

    public selectedMenuItem: string = StrategyMenu.ExploreStrategy;
    public bookMarkName: string = StrategyMenu.StrategySelection;
    public enumMenuSelection = StrategyMenu;
    public strategyMouseOver: Strategy;
    public toggleSummarySelection: boolean = false;
    public disableAdditionalStrategy: boolean = false;
    public ribbonTypes: typeof StrategyMenu = StrategyMenu;
    public riskWidgetTypes: typeof RiskWidgetTypes = RiskWidgetTypes;
    public clientRiskLevel: string = RiskLevels.None;
    public activeRiskLevel: string = RiskLevels.None;
    public strategyRiskLevel: number;
    public riskToleranceScore: number;
    public hudFeeSchedule: HudFeeSchedule = new HudSummaryFactory().createHudFee();
    public snapPointCombinedStrategyRisk: number = 0;
    public strategyRiskScore: number = 0;
    public validationErrorMessage: string;

    private subscriptionData: Subscription;
    private strategiesList: Strategy[];
    private riskToleranceValue: RiskTolerance;

    constructor(private delegationService: DelegationService,
        private strategiesService: StrategiesService
    ) {
        super('StrategySelectionComponent');
    }

    public ngOnInit(): void {
        this.subscriptionData = this.delegationService.refresh().subscribe(
            (data) => {
                if (data === CommandText.UpdateStrategy) {
                    this.updateStrategy();
                }
                if (data === CommandText.ChangeStrategy) {
                    this.exploreStrategies();
                }
                if (data === CommandText.ChangeRiskCapacity) {
                    this.setRiskTolerance();
                }
                // This will changes to CommandText.CancelRightHandDialog
                if (data === CommandText.CancelStrategyBalance) {
                    this.restoreBalanceValues();
                    this.restoreRiskLevelSelection();
                }
                if (data === CommandText.UpdateRiskWidget) {
                    this.getSnapPointCombinedStrategyRisk();
                }
            }

        );

        this.subscriptions.push(this.subscriptionData);

        if (this.account && this.account.strategies) {
            this.strategiesList = _.cloneDeep(this.account.strategies);
            this.riskToleranceValue = _.cloneDeep(this.account.riskTolerance);

            this.updateStrategy();
            this.setRiskTolerance();
            this.getSnapPointCombinedStrategyRisk();
        }
    }


    private restoreBalanceValues(): void {
        this.account.strategies = this.strategiesList;
    }

    private restoreRiskLevelSelection(): void {
        this.account.riskTolerance = this.riskToleranceValue;
    }

    private setRiskTolerance(): void {
        if (
            this.account &&
            this.account.riskTolerance
        ) {
            switch (this.account.riskTolerance.riskMethod) {
                case RiskMethods.Questionnaire:
                    this.riskToleranceScore = this.account.riskTolerance.calculatedQuestionnaireRisk;
                    break;
                case RiskMethods.LowMediumHigh:
                    this.getLowMedHighRisk(this.account.riskTolerance.selectedLowMedHighRisk);
                    break;
                case RiskMethods.Skip:
                    this.riskToleranceScore = undefined;
                    break;
            }
        }
    }

    private getLowMedHighRisk(riskLevel): void {
        switch (riskLevel) {
            case RiskToleranceLevel.Low:
                this.riskToleranceScore = RiskLevelValues.LowMin;
                break;
            case RiskToleranceLevel.Medium:
                this.riskToleranceScore = RiskLevelValues.MediumMin;
                break;
            case RiskToleranceLevel.High:
                this.riskToleranceScore = RiskLevelValues.HighMin;
                break;
        }
    }


    public menuItemClicked(event: StrategyMenu): void {
        this.selectedMenuItem = event;
    }

    public updateStrategy(): void {
        if (this.account && this.account.strategies && this.account.strategies.length > 0) {

            this.toggleSummarySelection = true;
            this.bookMarkName = StrategyMenu.StrategySummary;

            this.disableAdditionalStrategy = this.getDisableAdditionalStrategy();
            let totalFee: number = 0;
            let minFee: number = 0;
            this.account.strategies.forEach((strategy) => {
                totalFee += +(strategy.balance ? strategy.balance : 0);
                minFee += +(strategy.minimum ? strategy.minimum : 0);
            });
            this.hudFeeSchedule.total = +totalFee;
            this.hudFeeSchedule.min = +minFee;
            this.hudFeeSchedule.account = this.account;
        } else {
            this.toggleSummarySelection = false;
            this.bookMarkName = StrategyMenu.StrategySelection;
        }
    }

    private getSnapPointCombinedStrategyRisk(): void {
        this.strategiesService.getCombinedStrategyRisk(this.account)
            .subscribe((response: CombinedStrategyRisk) => {
                this.validationErrorMessage = '';
                if (response) {
                    this.snapPointCombinedStrategyRisk = response.strategyRiskSnapPoint;
                    this.strategyRiskScore = response.strategyRiskScore;
                }
            }, (responseError) => {
                if (responseError.status === ServerErrorStatus.ValidationError) {
                    this.validationErrorMessage = responseError.error.error.message;
                }
            });
    }

    public exploreStrategies(): void {
        this.toggleSummarySelection = false;
        this.selectedMenuItem = this.enumMenuSelection.ExploreStrategy;
        this.bookMarkName = StrategyMenu.StrategySelection;
    }

    private getHUDFeeSchedule(): void {
        if (this.account && this.account.strategies && this.account.strategies.length > 0) {

            let totalFee: number = 0;
            let minFee: number = 0;
            this.account.strategies.forEach((strategy) => {
                if (strategy.balance) {
                    totalFee += +(strategy.balance);
                }
                if (strategy.minimum) {
                    minFee += +(strategy.minimum);
                }
            });
            this.hudFeeSchedule.total = totalFee;
            this.hudFeeSchedule.min = minFee;
        }
    }

    public onAccountChange(): void {
        if (this.account.strategies.length > 1) {
            this.getHUDFeeSchedule();
            this.delegationService.publish(CommandText.GetFeeStrategy);
        }

        this.getSnapPointCombinedStrategyRisk();
    }

    private getDisableAdditionalStrategy(): boolean {
        const disableAdditional =
            (InvestmentProgramTypes.DistributionFocused === this.account.strategies[0].investmentProgramId) ||
            (InvestmentProgramTypes.ManagedAccountSolution === this.account.strategies[0].investmentProgramId) ||
            (InvestmentProgramType.Custom === this.account.strategies[0].investmentProgramId);
        return disableAdditional;
    }
}
