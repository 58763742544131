/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */


import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UserProfileService } from '@sei/advisor-client-review-proposal-ux';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';

@Component({
    selector: 'sei-car-fee-schedule-ribbon',
    templateUrl: './fee-schedule-ribbon.component.html'
})

export class FeeScheduleRibbonComponent extends ParentComponentSubscriptionManager
    implements OnInit {

    @Output()
    public onCancelEvent = new EventEmitter();
    @Output()
    public onDoneFeeRibbonEvent = new EventEmitter();

    public isReadUser: boolean = false;

    constructor(private userProfileService: UserProfileService) {
        super('FeeScheduleRibbonComponent');
    }

    public ngOnInit(): void {
        this.isReadUser = this.userProfileService.isReadUser;
    }

    public onCancel(): void {
        this.onCancelEvent.emit();
    }
    public onDone(): void {
        this.onDoneFeeRibbonEvent.emit();
    }
}
