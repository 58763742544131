<ng-template #recursiveList let-list>
    <ng-container *ngFor="let section of list">
        <div class="row toggle-row" *ngIf="!section.hidden">
            <div class="col-xs-9 toggle-row-left indent{{section.level}}">
                {{section.name}}
            </div>
            <div class="col-xs-3">
                <sei-car-toggle-sei-wizard-nav *ngIf="section.toggle" [isDisabled]="section.isToggleDisabled"
                    [(ngModel)]="section.include"></sei-car-toggle-sei-wizard-nav>
            </div>
        </div>
        <ng-container *ngIf="section.sections.length > 0">
            <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: section.sections }"></ng-container>
        </ng-container>
    </ng-container>
</ng-template>
<ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: proposalSection.sections }"></ng-container>