/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { StatementFrequencyFactory } from '@CarFactories/statement-frequency.factory';
import { InvestmentProgram } from '@CarInterfaces/investment-program-response';
import { AdditionalForm } from '@CarInterfaces/trade';
import { ProcessingRule } from '@CarInterfaces/user-profile';
import { WipSection } from '@CarInterfaces/wip-section';
import {
    InvestmentProgramType,
    LegalAgreement,
    StatementFrequency
} from '@CarModels/enums';
import { PropertyService } from '@CarServices/system/property.service';
import { WipSections } from '@CarServices/wip/wip-enums';
import { UserProfileService } from '@CarSystem/user-profile.service';
import { Injectable } from '@angular/core';
import { Strategy, UserProfile } from '@sei/advisor-client-review-proposal-ux';
import { concat } from 'lodash';
import { Observable, of } from 'rxjs';
import { BusinessProcessingRule } from './business-processing-rule';

@Injectable({
    providedIn: 'root'
})
/**
 * This class consolidates all the checks that need to filter data based on processing rules from the selected advisor
 */
export class ProcessingRulesService {
    private ruleDefinition;

    constructor(
        private readonly userProfileService: UserProfileService,
        private readonly statementFrequencyFactory: StatementFrequencyFactory,
        private readonly propService: PropertyService
    ) {
        this.initializeRules();
        this.initializeService();
    }

    private initializeRules(): void {
        // Constants to compare rules against
        this.ruleDefinition = {
            allowSuitabilityOverrides: {
                key: 'AllowSuitabilityOverrides',
                value: 'Yes'
            },
            managedAccountsDfs: {
                key: 'ManagedAccountsDFS',
                approved: 'Approved',
                notApproved: 'Not Approved'
            },
            seiCustodyProgram: {
                key: 'SEICustodyProgram',
                seiInvestmentModelsOnly: 'SEI Investment Models Only',
                seiInvestmentModelAndCustomModel:
                    'SEI Investment Models Plus Custom Models'
            },
            wipRTQDisplay: {
                managedAccountsProgramType: {
                    key: 'ManagedAccountsProgramType',
                    value: 'Standard'
                }
            },
            displayProxyField: {
                key: 'Proxy',
                value: 'Investor'
            },
            yShares: {
                key: 'YShares',
                value: 'Approved'
            },
            validateStatementFrequency: {
                key: 'StatementFrequency',
                value: this.statementFrequencyFactory.getDescription(
                    StatementFrequency.Monthly
                )
            },
            caaApproved: {
                key: 'CAA',
                value: 'Approved'
            },
            sblocApproved: {
                key: 'SBLOC',
                value: 'Approved'
            },
            minFeesValue: {
                key: 'FeesMinValue_Percent',
                value: ''
            },
            maxFeesValue: {
                key: 'FeesMaxValue_Percent',
                value: ''
            },
            minMaxFeeApplies: {
                key: 'FeesMinMax_Applied',
                value: 'Yes'
            },
            hasCrmInvestmentProgramSeiInvestmentModelsOnly: {
                key: 'SEICustodyProgram',
                value: 'SEI Investment Models Only'
            },
            hasCrmInvestmentProgramSeiInvestmentModelsPlusCustomModels: {
                key: 'SEICustodyProgram',
                value: 'SEI Investment Models Plus Custom Models'
            },
            hasCrmInvestmentProgramSeiMutualFund: {
                key: 'SEICustodyProgram',
                value: 'SEI Mutual Fund (Read Only)'
            },
            managedAccountDFSApproved: {
                key: 'ManagedAccountsDFS',
                value: 'Approved'
            },
            hasCoAdvisedManagedProgramType: {
                key: 'ManagedAccountsProgramType',
                value: 'Standard'
            },
            hasSubAdvisedManagedProgramType: {
                key: 'ManagedAccountsProgramType',
                value: 'Sub-Advised'
            },
            enhancedAdvisorySolutionsApproved: {
                key: 'EnhancedAdvisorySolutions',
                value: 'Approved'
            },
            hasNonSeiMutualFundsNotApprovedForEnhanced: {
                key: 'NonSEI_MutualFunds',
                value: 'Not Approved for Enhanced'
            },
            hasNonSeiMutualFundsBuysAndSells: {
                key: 'NonSEI_MutualFunds',
                value: 'Buys & Sells'
            },
            hasNonSeiMutualFundsSellsOnly: {
                key: 'NonSEI_MutualFunds',
                value: 'Sells Only'
            },
            hasNonSeiMutualFundsBdApprovalRequired: {
                key: 'NonSEI_MutualFunds_BDApprovalRequired',
                value: 'Yes'
            },
            hasSecuritiesIncludingEtfNotApprovedForEnhanced: {
                key: 'Securities_IncludingETF',
                value: 'Not Approved for Enhanced'
            },
            hasSecuritiesIncludingEtfBuysAndSells: {
                key: 'Securities_IncludingETF',
                value: 'Buys & Sells'
            },
            hasSecuritiesIncludingEtfSellsOnly: {
                key: 'Securities_IncludingETF',
                value: 'Sells Only'
            },
            hasRequiresSuitabilityAlways: {
                key: 'RequiresSuitability',
                value: 'Always'
            },
            hasRequiresSuitabilityManagedAccountsOnly: {
                key: 'RequiresSuitability',
                value: 'Managed Accounts Only'
            }
        };
    }

    private investmentProgramsDfsRule: InvestmentProgramType[] = [
        InvestmentProgramType.ManagedAccounts,
        InvestmentProgramType.DistributionFocusedStrategies,
        InvestmentProgramType.ETF,
        InvestmentProgramType.FixedIncome,
        InvestmentProgramType.CustomHighNetWorth,
        InvestmentProgramType.SeiAmericanFund
    ];

    private _rules: BusinessProcessingRule[];

    // Rule Objects
    private seiCustodyProgramRule: BusinessProcessingRule;
    private managedAccountsDfsRule: BusinessProcessingRule;
    private managedAccountsProgramTypeStandardRule: BusinessProcessingRule;
    private allowSuitabilityOverridesRule: BusinessProcessingRule;
    private displayProxyField: BusinessProcessingRule;
    private ySharesRule: BusinessProcessingRule;
    private setStatementFrequency: BusinessProcessingRule;
    private caaApproved: BusinessProcessingRule;
    private sblocApproved: BusinessProcessingRule;
    private minFeesRule: BusinessProcessingRule;
    private maxFeesRule: BusinessProcessingRule;
    private minMaxFeesRule: BusinessProcessingRule;

    private hasCrmInvestmentProgramSeiInvestmentModelsOnly: BusinessProcessingRule;
    private hasCrmInvestmentProgramSeiInvestmentModelsPlusCustomModels: BusinessProcessingRule;
    private hasCrmInvestmentProgramSeiMutualFund: BusinessProcessingRule;
    private managedAccountDFSApproved: BusinessProcessingRule;
    private hasCoAdvisedManagedProgramType: BusinessProcessingRule;
    private hasSubAdvisedManagedProgramType: BusinessProcessingRule;
    private enhancedAdvisorySolutionsApproved: BusinessProcessingRule;
    private hasNonSeiMutualFundsNotApprovedForEnhanced: BusinessProcessingRule;
    private hasNonSeiMutualFundsBuysAndSells: BusinessProcessingRule;
    private hasNonSeiMutualFundsSellsOnly: BusinessProcessingRule;
    private hasNonSeiMutualFundsBdApprovalRequired: BusinessProcessingRule;
    private hasSecuritiesIncludingEtfNotApprovedForEnhanced: BusinessProcessingRule;
    private hasSecuritiesIncludingEtfBuysAndSells: BusinessProcessingRule;
    private hasSecuritiesIncludingEtfSellsOnly: BusinessProcessingRule;
    private hasRequiresSuitabilityAlways: BusinessProcessingRule;
    private hasRequiresSuitabilityManagedAccountsOnly: BusinessProcessingRule;

    /**
     * Access to Rules constants incase needed by external classes
     */
    public get rules(): BusinessProcessingRule[] {
        return this._rules;
    }

    /**
     * Filters the investment programs based on Custody and Manage accounts rules
     * @param investmentPrograms investment programs to filter
     */
    public applyRuleMrdcInvestmentProgram(
        investmentPrograms: InvestmentProgram[]
    ): Observable<InvestmentProgram[]> {
        let mergeArray = new Array<InvestmentProgram>();

        // If SEICustodyProgram is SEI Investment Model and Custom Model then show both
        // else show just mutual funds
        if (
            this.seiCustodyProgramRule &&
            this.seiCustodyProgramRule.ruleTruthy()
        ) {
            mergeArray = concat(
                mergeArray,
                investmentPrograms.filter(
                    (program: InvestmentProgram) =>
                        program.investmentProgramId ===
                        InvestmentProgramType.MutualFunds ||
                        program.investmentProgramId ===
                        InvestmentProgramType.CustomFirmMutualFunds
                )
            );
        } else {
            mergeArray = concat(
                mergeArray,
                investmentPrograms.filter(
                    (program: InvestmentProgram) =>
                        program.investmentProgramId ===
                        InvestmentProgramType.MutualFunds
                )
            );
        }

        if (
            this.managedAccountsDfsRule &&
            this.managedAccountsDfsRule.ruleTruthy()
        ) {
            mergeArray = concat(
                mergeArray,
                investmentPrograms.filter((program: InvestmentProgram) =>
                    this.investmentProgramsDfsRule.includes(
                        program.investmentProgramId
                    )
                )
            );
        }

        return of([...mergeArray]);
    }

    /**
     * Entry point to filter WIP sections
     *  calls - applyRuleLegalAgreementRtqDisplay
     * ToDO MVP2 => anticipating this is going to need to be enhanced
     * @param wipSections Section list to filter from
     * @param investmentProgramType Investment program to apply specific rules
     */
    public applyWipRules(
        wipSections: WipSection[],
        investmentProgramType?: InvestmentProgramType
    ): Observable<WipSection[]> {
        // ToDo -> Need to add primary advisor logic when Account is selected
        return this.applyRuleLegalAgreementRtqDisplay(
            investmentProgramType,
            wipSections
        );
    }

    /**
     * Applies the legal agreement requirement specifically to the display of the RTQ
     * @param wipSections Section list to filter from
     * @param investmentProgramType Investment program to apply specific rules -
     * Only applies to manage accounts otherwise RTQ is not required
     */
    public applyRuleLegalAgreementRtqDisplay(
        investmentProgramType: InvestmentProgramType,
        wipSections: WipSection[]
    ): Observable<WipSection[]> {
        let mergeArray = new Array<WipSection>();

        // hide rtq for all MF and CMF regardless of processing rule
        if (
            investmentProgramType === InvestmentProgramType.MutualFunds ||
            investmentProgramType ===
            InvestmentProgramType.CustomFirmMutualFunds ||
            (this.managedAccountsProgramTypeStandardRule &&
                !this.managedAccountsProgramTypeStandardRule.ruleTruthy())
        ) {
            mergeArray = wipSections.filter(
                (section: WipSection) =>
                    section.sectionId !== WipSections.RiskTolerance
            );
        }

        return of(mergeArray.length > 0 ? [...mergeArray] : wipSections);
    }

    public applySuitabilityOverrideDisplay(): boolean {
        return this.allowSuitabilityOverridesRule
            ? this.allowSuitabilityOverridesRule.ruleTruthy()
            : false;
    }

    public applyDisableProxyField(): boolean {
        if (
            this.propService.exists(
                () => this.userProfileService.profile.primaryAdvisor
            )
        ) {
            // eslint-disable-next-line @typescript-eslint/tslint/config
            const { firm = '' } =
                this.userProfileService.profile.primaryAdvisor || {};
            if (firm && firm.brokerDealer) {
                return false;
            } else {
                return true;
            }
        }
        return true;
    }

    public applyYShareDisplay(): boolean {
        return this.ySharesRule.ruleTruthy();
    }

    public validateStatementFrequencyOptions(
        frequency: string,
        sendMonthlyAccountStatement: boolean
    ): boolean {
        if (
            this.propService.exists(
                () => this.userProfileService.profile.primaryAdvisor
            )
        ) {
            const isMonthly = this.setStatementFrequency.ruleTruthy();
            let statementFrequency: boolean;

            if (sendMonthlyAccountStatement === undefined) {
                statementFrequency = undefined;
            } else {
                statementFrequency = !!(
                    sendMonthlyAccountStatement &&
                    isMonthly &&
                    frequency ===
                    this.statementFrequencyFactory.getDescription(
                        StatementFrequency.Monthly
                    )
                );
            }

            return statementFrequency;
        }
        return false;
    }

    /**
     * first filter by account type
     * Next filter by primary advisors entitlements(sbloc and caa) if defined, otherwise show it
     * Last if subform has legal agreement defined filter off that too
     * @param additionalForms
     */
    public filterAdditionalForms(
        additionalForms: AdditionalForm[],
        investmentProgramId: number
    ): AdditionalForm[] {
        const sblocDescription = 'Securities-Backed Line of Credit';
        const caaDescription = 'Cash Access Account';
        // remove CAA if Primary Advisor is not entitled to CAA
        if (this.caaApproved && !this.caaApproved.ruleTruthy()) {
            additionalForms.filter(
                (form: AdditionalForm) => form.description !== caaDescription
            );
        }

        // remove if Primary Advisor no entitled to SBLOC
        if (this.sblocApproved && !this.sblocApproved.ruleTruthy()) {
            additionalForms.filter(
                (form: AdditionalForm) => form.description !== sblocDescription
            );
        }
        return this.filterByLegalAgreement(
            additionalForms,
            investmentProgramId
        );
    }

    private filterByLegalAgreement(
        additionalForms: AdditionalForm[],
        investmentProgramId: number
    ): AdditionalForm[] {
        return additionalForms.filter((form: AdditionalForm) => {
            // if the subForm has a legal agreement defined then look to filter off it
            if (form.legalAgreementId) {
                return (
                    +form.legalAgreementId ===
                    this.getLegalAgreement(investmentProgramId)
                );
            }
            return true;
        });
    }

    public getLegalAgreement(investmentProgramId: number): number {
        // Mutual Funds and Custom Mutual Funds also get Custody.
        if (
            investmentProgramId === InvestmentProgramType.MutualFunds ||
            investmentProgramId === InvestmentProgramType.CustomFirmMutualFunds
        ) {
            return +LegalAgreement.Custody;
        }

        if (
            this.hasSubAdvisedManagedProgramType &&
            this.hasSubAdvisedManagedProgramType.ruleTruthy()
        ) {
            return +LegalAgreement.SubAdvised;
        } else {
            // default
            return +LegalAgreement.CoAdvisedClassic;
        }

        // TODO leave for now, mvp1 rules are not this complex
        // // first you need to have a crmInvestmentProgram populated, 3 possible values.
        // if ((this.hasCrmInvestmentProgramSeiInvestmentModelsOnly &&
        //     this.hasCrmInvestmentProgramSeiInvestmentModelsOnly.ruleTruthy()) ||
        //     (this.hasCrmInvestmentProgramSeiInvestmentModelsPlusCustomModels &&
        //         this.hasCrmInvestmentProgramSeiInvestmentModelsPlusCustomModels.ruleTruthy()) ||
        //     (this.hasCrmInvestmentProgramSeiMutualFund &&
        //         this.hasCrmInvestmentProgramSeiMutualFund.ruleTruthy())) {
        //     // check for Custody legal agreement, requires
        //     //  -Enhanced Advisory Solutions Approved
        //     //  -Non-SEI Mutual Funds to be populated, 3 possible values
        //     //  -Non-SEI Mutual Funds - BD Approval
        //     //  -Security (including ETFs) to be populated, 3 possible values
        //     if ((this.enhancedAdvisorySolutionsApproved &&
        //         this.enhancedAdvisorySolutionsApproved.ruleTruthy()) &&
        //         ((this.hasNonSeiMutualFundsNotApprovedForEnhanced &&
        //             this.hasNonSeiMutualFundsNotApprovedForEnhanced.ruleTruthy()) ||
        //             (this.hasNonSeiMutualFundsBuysAndSells &&
        //                 this.hasNonSeiMutualFundsBuysAndSells.ruleTruthy()) ||
        //             (this.hasNonSeiMutualFundsSellsOnly &&
        //                 this.hasNonSeiMutualFundsSellsOnly.ruleTruthy())) &&
        //         (this.hasNonSeiMutualFundsBdApprovalRequired &&
        //             this.hasNonSeiMutualFundsBdApprovalRequired.ruleTruthy()) &&
        //         ((this.hasSecuritiesIncludingEtfNotApprovedForEnhanced &&
        //             this.hasSecuritiesIncludingEtfNotApprovedForEnhanced.ruleTruthy()) ||
        //             (this.hasSecuritiesIncludingEtfSellsOnly &&
        //                 this.hasNonSeiMutualFundsBuysAndSells.ruleTruthy()) ||
        //             (this.hasNonSeiMutualFundsSellsOnly &&
        //                 this.hasNonSeiMutualFundsSellsOnly.ruleTruthy()))) {
        //         return +LegalAgreement.Custody;
        //         // check for Co-Advised (Complete)
        //         //  -Managed Accounts/DFS Approved
        //         //  -Managed Account Program Type: Standard
        //     } else if (this.managedAccountDFSApproved &&
        //         this.managedAccountDFSApproved.ruleTruthy() &&
        //         this.hasCoAdvisedManagedProgramType &&
        //         this.hasCoAdvisedManagedProgramType.ruleTruthy()) {
        //         return +LegalAgreement.CoAdvisedComplete;
        //         // check for Sub-Advised
        //         //  -Managed Accounts/DFS Approved
        //         //  -Managed Account Program Type: Sub-Advised
        //     } else if (this.managedAccountDFSApproved &&
        //         this.managedAccountDFSApproved.ruleTruthy() &&
        //         this.hasSubAdvisedManagedProgramType &&
        //         this.hasSubAdvisedManagedProgramType.ruleTruthy()) {
        //         return +LegalAgreement.SubAdvised;
        //     }
        // }
        // return +LegalAgreement.Custody;
    }

    public getMinFeesPercentage(): number {
        const defaultMinValue = 0.01;
        if (
            this.minFeesRule &&
            this.userProfileService &&
            this.userProfileService.profile
        ) {
            const minFeesRuleKey = this.minFeesRule.rule.key;
            const minFees = this.minFeesRule.getProcessingRuleValue(
                minFeesRuleKey
            );
            if (
                minFees &&
                minFees !== this.minFeesRule.rule.comparator &&
                minFees !== null
            ) {
                return +minFees;
            }
        }
        return defaultMinValue;
    }

    public getMaxFeesPercentage(): number {
        const defaultMaxValue = 3;
        if (
            this.maxFeesRule &&
            this.userProfileService &&
            this.userProfileService.profile
        ) {
            const maxFeesRuleKey = this.maxFeesRule.rule.key;
            const maxFees = this.maxFeesRule.getProcessingRuleValue(
                maxFeesRuleKey
            );
            if (
                maxFees &&
                maxFees !== this.maxFeesRule.rule.comparator &&
                maxFees !== null
            ) {
                return +maxFees;
            }
        }
        return defaultMaxValue;
    }

    public minMaxFeesApplies(): boolean {
        if (this.minMaxFeesRule) {
            return this.minMaxFeesRule.ruleTruthy();
        }
        return false;
    }

    public getRiskToleranceQuestionnaireMessage(
        primaryAdvisor: UserProfile,
        proposalStrategies: Strategy[]
    ): string {
        // NOTE: we are not using the standard processing rules pattern
        // because we are not always using the logging in userProfile

        const requiredMessage =
            'A risk tolerance questionnaire will be required to implement this account';
        const mayRequireMessage =
            'A risk tolerance questionnaire may be required to implement this account';
        const requiresSuitabilityAlways = primaryAdvisor.processingRules[this.ruleDefinition.hasRequiresSuitabilityAlways.key];
        const requiresSuitabilityManagedAccountsOnly =
            primaryAdvisor.processingRules[this.ruleDefinition.hasRequiresSuitabilityManagedAccountsOnly.key];

        if (requiresSuitabilityAlways && requiresSuitabilityAlways === this.ruleDefinition.hasRequiresSuitabilityAlways.value) {
            return requiredMessage;
        } else if (requiresSuitabilityManagedAccountsOnly &&
            requiresSuitabilityManagedAccountsOnly === this.ruleDefinition.hasRequiresSuitabilityManagedAccountsOnly.value) {
            if (proposalStrategies && proposalStrategies.length > 0) {
                if (proposalStrategies.filter((strategy) => strategy.isManagedInvestmentStrategy).length > 0) {
                    return requiredMessage;
                } else {
                    return undefined;
                }
            } else {
                return mayRequireMessage;
            }
        } else {
            return undefined;
        }
    }

    public initializeService() {
        // Should always be primary advisor
        let userProfilePrimaryAdvisor: UserProfile;
        if (this.userProfileService && this.userProfileService.profile) {
            userProfilePrimaryAdvisor = this.userProfileService.profile
                .primaryAdvisor;
        }

        if (userProfilePrimaryAdvisor) {
            const primaryAdvisorProcessingRules: ProcessingRule =
                userProfilePrimaryAdvisor.processingRules || {};

            if (this.userProfileService && this.userProfileService.firm) {
                this.displayProxyField = new BusinessProcessingRule({
                    key: this.ruleDefinition.displayProxyField.key,
                    comparator: this.userProfileService.firm.brokerDealer,
                    processingRules: primaryAdvisorProcessingRules,
                    undefinedCheck: true
                });
            }

            this.seiCustodyProgramRule = new BusinessProcessingRule({
                key: this.ruleDefinition.seiCustodyProgram.key,
                comparator: this.ruleDefinition.seiCustodyProgram
                    .seiInvestmentModelAndCustomModel,
                processingRules: primaryAdvisorProcessingRules
            });

            this.managedAccountsDfsRule = new BusinessProcessingRule({
                key: this.ruleDefinition.managedAccountsDfs.key,
                comparator: this.ruleDefinition.managedAccountsDfs.approved,
                processingRules: primaryAdvisorProcessingRules
            });

            this.managedAccountsProgramTypeStandardRule = new BusinessProcessingRule(
                {
                    key: this.ruleDefinition.wipRTQDisplay
                        .managedAccountsProgramType.key,
                    comparator: this.ruleDefinition.wipRTQDisplay
                        .managedAccountsProgramType.value,
                    processingRules: primaryAdvisorProcessingRules
                }
            );

            this.allowSuitabilityOverridesRule = new BusinessProcessingRule({
                key: this.ruleDefinition.allowSuitabilityOverrides.key,
                comparator: this.ruleDefinition.allowSuitabilityOverrides.value,
                processingRules: primaryAdvisorProcessingRules
            });

            this.ySharesRule = new BusinessProcessingRule({
                key: this.ruleDefinition.yShares.key,
                comparator: this.ruleDefinition.yShares.value,
                processingRules: primaryAdvisorProcessingRules
            });

            this.setStatementFrequency = new BusinessProcessingRule({
                key: this.ruleDefinition.validateStatementFrequency.key,
                comparator: this.ruleDefinition.validateStatementFrequency
                    .value,
                processingRules: primaryAdvisorProcessingRules,
                undefinedCheck: true
            });

            this.caaApproved = new BusinessProcessingRule({
                key: this.ruleDefinition.caaApproved.key,
                comparator: this.ruleDefinition.caaApproved.value,
                processingRules: primaryAdvisorProcessingRules,
                undefinedCheck: true
            });

            this.sblocApproved = new BusinessProcessingRule({
                key: this.ruleDefinition.sblocApproved.key,
                comparator: this.ruleDefinition.sblocApproved.value,
                processingRules: primaryAdvisorProcessingRules,
                undefinedCheck: true
            });

            this.minFeesRule = new BusinessProcessingRule({
                key: this.ruleDefinition.minFeesValue.key,
                comparator: this.ruleDefinition.minFeesValue.value,
                processingRules: primaryAdvisorProcessingRules,
                undefinedCheck: true
            });

            this.maxFeesRule = new BusinessProcessingRule({
                key: this.ruleDefinition.maxFeesValue.key,
                comparator: this.ruleDefinition.maxFeesValue.value,
                processingRules: primaryAdvisorProcessingRules,
                undefinedCheck: true
            });

            this.minMaxFeesRule = new BusinessProcessingRule({
                key: this.ruleDefinition.minMaxFeeApplies.key,
                comparator: this.ruleDefinition.minMaxFeeApplies.value,
                processingRules: primaryAdvisorProcessingRules,
                undefinedCheck: true
            });

            this.hasCrmInvestmentProgramSeiInvestmentModelsOnly = new BusinessProcessingRule(
                {
                    key: this.ruleDefinition
                        .hasCrmInvestmentProgramSeiInvestmentModelsOnly.key,
                    comparator: this.ruleDefinition
                        .hasCrmInvestmentProgramSeiInvestmentModelsOnly.value,
                    processingRules: primaryAdvisorProcessingRules,
                    undefinedCheck: true
                }
            );

            this.hasCrmInvestmentProgramSeiInvestmentModelsPlusCustomModels = new BusinessProcessingRule(
                {
                    key: this.ruleDefinition
                        .hasCrmInvestmentProgramSeiInvestmentModelsPlusCustomModels
                        .key,
                    comparator: this.ruleDefinition
                        .hasCrmInvestmentProgramSeiInvestmentModelsPlusCustomModels
                        .value,
                    processingRules: primaryAdvisorProcessingRules,
                    undefinedCheck: true
                }
            );

            this.hasCrmInvestmentProgramSeiMutualFund = new BusinessProcessingRule(
                {
                    key: this.ruleDefinition
                        .hasCrmInvestmentProgramSeiMutualFund.key,
                    comparator: this.ruleDefinition
                        .hasCrmInvestmentProgramSeiMutualFund.value,
                    processingRules: primaryAdvisorProcessingRules,
                    undefinedCheck: true
                }
            );

            this.managedAccountDFSApproved = new BusinessProcessingRule({
                key: this.ruleDefinition.managedAccountDFSApproved.key,
                comparator: this.ruleDefinition.managedAccountDFSApproved.value,
                processingRules: primaryAdvisorProcessingRules,
                undefinedCheck: true
            });

            this.hasCoAdvisedManagedProgramType = new BusinessProcessingRule({
                key: this.ruleDefinition.hasCoAdvisedManagedProgramType.key,
                comparator: this.ruleDefinition.hasCoAdvisedManagedProgramType
                    .value,
                processingRules: primaryAdvisorProcessingRules,
                undefinedCheck: true
            });

            this.hasSubAdvisedManagedProgramType = new BusinessProcessingRule({
                key: this.ruleDefinition.hasSubAdvisedManagedProgramType.key,
                comparator: this.ruleDefinition.hasSubAdvisedManagedProgramType
                    .value,
                processingRules: primaryAdvisorProcessingRules,
                undefinedCheck: true
            });

            this.enhancedAdvisorySolutionsApproved = new BusinessProcessingRule(
                {
                    key: this.ruleDefinition.enhancedAdvisorySolutionsApproved
                        .key,
                    comparator: this.ruleDefinition
                        .enhancedAdvisorySolutionsApproved.value,
                    processingRules: primaryAdvisorProcessingRules,
                    undefinedCheck: true
                }
            );

            this.hasNonSeiMutualFundsNotApprovedForEnhanced = new BusinessProcessingRule(
                {
                    key: this.ruleDefinition
                        .hasNonSeiMutualFundsNotApprovedForEnhanced.key,
                    comparator: this.ruleDefinition
                        .hasNonSeiMutualFundsNotApprovedForEnhanced.value,
                    processingRules: primaryAdvisorProcessingRules,
                    undefinedCheck: true
                }
            );

            this.hasNonSeiMutualFundsBuysAndSells = new BusinessProcessingRule({
                key: this.ruleDefinition.hasNonSeiMutualFundsBuysAndSells.key,
                comparator: this.ruleDefinition.hasNonSeiMutualFundsBuysAndSells
                    .value,
                processingRules: primaryAdvisorProcessingRules,
                undefinedCheck: true
            });

            this.hasNonSeiMutualFundsSellsOnly = new BusinessProcessingRule({
                key: this.ruleDefinition.hasNonSeiMutualFundsSellsOnly.key,
                comparator: this.ruleDefinition.hasNonSeiMutualFundsSellsOnly
                    .value,
                processingRules: primaryAdvisorProcessingRules,
                undefinedCheck: true
            });

            this.hasNonSeiMutualFundsBdApprovalRequired = new BusinessProcessingRule(
                {
                    key: this.ruleDefinition
                        .hasNonSeiMutualFundsBdApprovalRequired.key,
                    comparator: this.ruleDefinition
                        .hasNonSeiMutualFundsBdApprovalRequired.value,
                    processingRules: primaryAdvisorProcessingRules,
                    undefinedCheck: true
                }
            );

            this.hasSecuritiesIncludingEtfNotApprovedForEnhanced = new BusinessProcessingRule(
                {
                    key: this.ruleDefinition
                        .hasSecuritiesIncludingEtfNotApprovedForEnhanced.key,
                    comparator: this.ruleDefinition
                        .hasSecuritiesIncludingEtfNotApprovedForEnhanced.value,
                    processingRules: primaryAdvisorProcessingRules,
                    undefinedCheck: true
                }
            );

            this.hasSecuritiesIncludingEtfBuysAndSells = new BusinessProcessingRule(
                {
                    key: this.ruleDefinition
                        .hasSecuritiesIncludingEtfBuysAndSells.key,
                    comparator: this.ruleDefinition
                        .hasSecuritiesIncludingEtfBuysAndSells.value,
                    processingRules: primaryAdvisorProcessingRules,
                    undefinedCheck: true
                }
            );

            this.hasSecuritiesIncludingEtfSellsOnly = new BusinessProcessingRule(
                {
                    key: this.ruleDefinition.hasSecuritiesIncludingEtfSellsOnly
                        .key,
                    comparator: this.ruleDefinition
                        .hasSecuritiesIncludingEtfSellsOnly.value,
                    processingRules: primaryAdvisorProcessingRules,
                    undefinedCheck: true
                }
            );
        }

        this._rules = [
            this.seiCustodyProgramRule,
            this.managedAccountsDfsRule,
            this.managedAccountsProgramTypeStandardRule,
            this.allowSuitabilityOverridesRule,
            this.displayProxyField,
            this.ySharesRule,
            this.setStatementFrequency,
            this.caaApproved,
            this.sblocApproved,
            this.minFeesRule,
            this.maxFeesRule,
            this.minMaxFeesRule,
            this.hasCrmInvestmentProgramSeiInvestmentModelsOnly,
            this.hasCrmInvestmentProgramSeiInvestmentModelsPlusCustomModels,
            this.hasCrmInvestmentProgramSeiMutualFund,
            this.managedAccountDFSApproved,
            this.hasCoAdvisedManagedProgramType,
            this.hasSubAdvisedManagedProgramType,
            this.enhancedAdvisorySolutionsApproved,
            this.hasNonSeiMutualFundsNotApprovedForEnhanced,
            this.hasNonSeiMutualFundsBuysAndSells,
            this.hasNonSeiMutualFundsSellsOnly,
            this.hasNonSeiMutualFundsBdApprovalRequired,
            this.hasSecuritiesIncludingEtfNotApprovedForEnhanced,
            this.hasSecuritiesIncludingEtfBuysAndSells,
            this.hasSecuritiesIncludingEtfSellsOnly
        ];
    }
}
