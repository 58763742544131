/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { AccountDetail } from '@CarInterfaces/account-detail';
import { BaseFactory } from '@CarInterfaces/base-factory';

@Injectable({
    providedIn: 'root'
})
export class AccountDetailFactory implements BaseFactory<AccountDetail> {
    public createObject(): AccountDetail {
        return {
            isIraTypeAccount: false,
            dividendDistributionOption: {
                dividendDistributionOptionId: 0,
                dividendDistributionOptionName: ''
            },
            payToRecipient: {
                payToRecipientDistributionOption: {
                    payToRecipientDistributionOptionId: 0,
                    payToRecipientDistributionOptionName: ''
                }
            },
            paymentOfAdvisorFees: {
                paymentOfAdvisorFeesOption: {
                    paymentOfAdvisorFeesOptionId: 0,
                    paymentOfAdvisorFeesOptionName: ''
                }
            },
            advisorFeeSchedule: {
                advisorFeeScheduleOptionId: 0,
                advisorFeeScheduleOptionName: ''
            }
        };
    }
}
