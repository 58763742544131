<div class="zoom-container">
    <div class="range-container">
        <input type="range"
               class="form-range"
               id="zoom"
               [min]="MIN_ZOOM_VALUE"
               [max]="MAX_ZOOM_VALUE"
               [step]="RANGE_STEP_VALUE"
               [ngModel]="zoomValue"
               (ngModelChange)="onRangeChange($event)">
        <span>{{zoomValue}}%</span>
    </div>
    <span>Zoom</span>
</div>
