/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
import { Component, Input } from '@angular/core';
import { ProposalSectionLabel } from '../../model/enums';
import { ProposalSection } from '../../model/presentation';
import { DataSharingService } from '../../service/data-sharing.service';

@Component({
    selector: 'sei-car-settings-node',
    templateUrl: './settings-node.component.html',
    styleUrls: ['./settings-node.component.scss']
})
export class SettingsNodeComponent {
    public settingToggle: boolean = false;

    // Note: settings for sei-icon-toggle
    public offIsGrey: boolean = false;
    public disabled: boolean = false;
    public isActive: boolean = true;
    public trueIcon: string = 'fa-fw fas fa-toggle-on fa-rotate-180';
    public falseIcon: string = 'fa-fw fas fa-toggle-off fa-rotate-180';
    public iconSize: string = 'fa-2x';
    public iconClass: string = 'info';

    @Input()
    groupIndex: number;

    @Input()
    group: ProposalSection;

    @Input()
    disableDragDrop: boolean = false;

    public proposalSectionLabel: typeof ProposalSectionLabel = ProposalSectionLabel;

    constructor(
        private dataSharingService: DataSharingService) {
    }

    public parentToggleChange(event, section: ProposalSection) {
        section.sections.forEach((element) => {
            if (!element.isToggleDisabled && !element.hidden) {
                element.include = event;
                element.sections.forEach((child) => {
                    if (!child.hidden) {
                        child.include = event;
                    }
                });
            }
        });

        this.dataSharingService.proposalSectionChanged.next(section);
    }

    public chevronClick(section: ProposalSection) {
        if (ProposalSectionLabel.SeiStrategyDetails !== this.group.name) {
            this.dataSharingService.proposalSectionChanged.next(section);
            section.isCollapsed = !section.isCollapsed;
        }
    }

}
