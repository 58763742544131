/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@CarComponents/common/common.module';
import {
    RiskToleranceAlertModalDetailPanelComponent
} from './risk-tolerance-alert-modal-detail-panel/risk-tolerance-alert-modal-detail-panel.component';
import { RiskToleranceAlertModalFooterComponent } from './risk-tolerance-alert-modal-footer/risk-tolerance-alert-modal-footer.component';
import { RiskToleranceAlertModalPanelComponent } from './risk-tolerance-alert-modal-panel/risk-tolerance-alert-modal-panel.component';

@NgModule({
    imports: [CommonModule],
    declarations: [
        RiskToleranceAlertModalPanelComponent,
        RiskToleranceAlertModalFooterComponent,
        RiskToleranceAlertModalDetailPanelComponent
    ],
    providers: [],
    exports: [RiskToleranceAlertModalPanelComponent]
})
export class RiskToleranceAlertModule {}
