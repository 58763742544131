/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { ProcessingRule } from '@CarInterfaces/user-profile';

export interface ProcessingRuleType {
    key: string;
    comparator: string;
    undefinedCheck?: boolean;
}

export interface RuleBaseType {
    ruleTruthy(): boolean;
    getProcessingRuleValue(key: string): string;
}

export abstract class RuleBase implements RuleBaseType {
    private _rule: ProcessingRuleType;
    private _processingRules: ProcessingRule;

    constructor(opts) {
        // eslint-disable-next-line @typescript-eslint/tslint/config
        const { key, comparator, undefinedCheck, processingRules } = opts;
        this._rule = {
            key,
            comparator,
            undefinedCheck
        };

        this._processingRules = processingRules;
    }

    public get rule(): ProcessingRuleType {
        return this._rule;
    }

    public ruleTruthy(): boolean {
        const ruleValue = this.getProcessingRuleValue(this.rule.key);
        if (!ruleValue) {
            return false;
        }
        // for now doing exact match cause processing rules are hand entered at the source
        return ruleValue === this.rule.comparator;
    }

    public getProcessingRuleValue(key: string): string {
        return this._processingRules ? this._processingRules[key] : undefined;
    }
}

export class BusinessProcessingRule extends RuleBase {
    constructor(opts) {
        super(opts);
    }
}
