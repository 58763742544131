/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { EnrollmentActions, EnrollmentActionTypes } from './enrollment.actions';

// State for this feature
export interface EnrollmentState {
    generatingDocumentsStatus: { [key: string]: boolean };
    documentsGeneratedStatus: { [key: string]: boolean };
}

const initialState: EnrollmentState = {
    generatingDocumentsStatus: undefined,
    documentsGeneratedStatus: undefined
};

export function reducer(
    state = initialState,
    action: EnrollmentActions
): EnrollmentState {
    switch (action.type) {
        case EnrollmentActionTypes.GenerateDocuments:
            return {
                ...state,
                generatingDocumentsStatus: action.payload.generatingDocuments,
                documentsGeneratedStatus: action.payload.documentGenerated
            };
        case EnrollmentActionTypes.InitializeState:
            return {
                ...state,
                generatingDocumentsStatus: action.payload.generatingDocuments,
                documentsGeneratedStatus: action.payload.documentGenerated
            };
        case EnrollmentActionTypes.GenerateDocumentsSuccess:
            return {
                ...state,
                generatingDocumentsStatus: action.payload.generatingDocuments
            };

        case EnrollmentActionTypes.GenerateDocumentsFail:
            return {
                ...state,
                generatingDocumentsStatus: action.payload.generatingDocuments
            };

        case EnrollmentActionTypes.DocumentStatus:
            return {
                ...state
            };

        case EnrollmentActionTypes.DocumentStatusSuccess:
            return {
                ...state,
                documentsGeneratedStatus: action.payload.documentGenerated
            };

        case EnrollmentActionTypes.DocumentStatusFail:
            return {
                ...state,
                documentsGeneratedStatus: action.payload.documentGenerated
            };

        case EnrollmentActionTypes.DocumentLoad:
            return {
                ...state
            };

        case EnrollmentActionTypes.DocumentLoadSuccess:
            return {
                ...state
            };

        case EnrollmentActionTypes.DocumentLoadFail:
            return {
                ...state
            };

        default:
            return state;
    }
}
