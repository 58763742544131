
/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'car-document-selection',
    templateUrl: './document-selection.component.html'
})
export class DocumentSelectionComponent {

    @Input() documentSelection: number;

    @Output() documentClick: EventEmitter<number> = new EventEmitter<number>();

    public onChange(radioSelection: number): void {
        this.documentSelection = radioSelection;
        this.documentClick.emit(
            radioSelection
        );
    }
}
