/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { select, Store } from '@ngrx/store';

import { Advisor } from '@CarInterfaces/advisor';
import { PrimaryAdvisor, User, UserProfile } from '@CarInterfaces/user-profile';
import { EnvironmentMode } from '@CarModels/enums';
import { UserProfileRepository } from '@CarRepos/user-profile-repository';
import { GlobalService } from '@CarServices/system/global.service';
import { WalkMeService } from '@CarServices/system/walk-me.service';
import { BrandHeaders, KassoService, SeiCommonAppStore } from '@sei/common-swp-components-lib-ux';
import { Observable, Subject } from 'rxjs';
import { mergeMap, share, skipWhile, tap } from 'rxjs/operators';
import { AuthState } from './auth-slice.reducers';
import { Login, Logout } from './auth.actions';
import { getCarUserProfile, isLoggedIn } from './auth.selectors';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private authenticated: boolean;

    private smBrandId: string;
    private smUser: string;
    private smUniversalId: number;
    private _logOffUrl: string;
    private _proxyKey: string = '{{reverseProxy}}';
    private swpWalkMeServiceConfigured: boolean;
    public userAuthenticated = new Subject<boolean>();

    constructor(
        private readonly global: GlobalService,
        private readonly userProfileRepository: UserProfileRepository,
        private readonly store: Store<AuthState>,
        private readonly appStore: Store<SeiCommonAppStore>,
        private readonly router: Router,
        private kasso: KassoService,
        private readonly walkMeService: WalkMeService
    ) {
        this.initializeService();
    }

    public get externalSiteminderUserId() {
        return this.smUser;
    }

    public set externalSiteminderUserId(smUser: string) {
        this.smUser = smUser;
    }

    public get externalSiteminderUniversalId() {
        return this.smUniversalId;
    }

    public set externalSiteminderUniversalId(smUniversalId: number) {
        this.smUniversalId = smUniversalId;
    }

    public get externalBrandId() {
        return this.smBrandId;
    }

    public set externalBrandId(smBrand: string) {
        this.smBrandId = smBrand;
    }

    /**
     * returns true if Auth Service has determined CAR authentication is valid
     */
    public get isAuthenticated() {
        return this.authenticated;
    }

    public get isSwpWalkMeServiceEnabled() {
        return this.swpWalkMeServiceConfigured;
    }

    public get logOffUrl(): string {
        if (
            this.global.configService.environment.mode === EnvironmentMode.Dev
        ) {
            return '/';
        }

        if (this._logOffUrl) {
            return this._logOffUrl.replace(this._proxyKey, window.location.hostname);
        } else {
            return (
                this.global.configService.environment.authConfig.logOffUrl
                    .replace(
                        '{{brandId}}',
                        this.global.configService.environment.authConfig
                            .logOffBrandId
                    )
                    // Need to support reverse proxy
                    .replace(this._proxyKey, window.location.hostname)
            );
        }
    }

    /**
     * Fetches the CAR User JSON object from REST
     * @param siteminderId entity id from sm_user siteminder header
     * @deprecated
     */
    public getCarUserProfileStreaming(
        siteminderId?: string | number
    ): Observable<UserProfile> {
        return this.userProfileRepository
            .read(this.getSiteminderToken(siteminderId))
            .pipe(
                share(),
                tap((userProfile: UserProfile) => {
                    this.authenticated = true;
                    this.swpWalkMeServiceConfigured = userProfile ? userProfile.isSwpWalkMeServiceConfigured : false;
                    if (this.isSwpWalkMeServiceEnabled) {
                        this.walkMeService.loadWalkMeSnippet();
                    }
                    // this removes the recursive primary advisory issue we are
                    // having and allows the NGRX dev tools to work
                    let primaryAdvisor: PrimaryAdvisor;
                    if (userProfile.primaryAdvisor) {
                        primaryAdvisor = {
                            ...userProfile.primaryAdvisor
                        };
                    } else {
                        primaryAdvisor = {
                            ...userProfile
                        };
                    }

                    const userProfileCleaned: UserProfile = {
                        ...userProfile,
                        primaryAdvisor
                    };

                    this.setEntitledAdvisors(userProfileCleaned);
                    this.store.dispatch(
                        new Login({ userProfile: userProfileCleaned })
                    );
                })
            );
    }

    /**
     * Connect to the Brand reverse proxy to get a payload that will/should have siteminder headers
     * SEI passes these via server requests, so we have to make the secondary call out or CAR never
     * knows about the siteminder.
     */
    public getUser(): Observable<UserProfile> {
        return this.appStore
            .select(
                (seiStore: SeiCommonAppStore) =>
                    seiStore.brandHeadersSlice.brandHeaders
            )
            .pipe(
                skipWhile(
                    (brandHeaders: BrandHeaders) =>
                        this.global.isEmpty(brandHeaders) ||
                        !Object.keys(brandHeaders).length ||
                        (brandHeaders.userId === undefined &&
                            brandHeaders.brandId === undefined &&
                            brandHeaders.userName === undefined)
                ),
                mergeMap((data: BrandHeaders) => {
                    this.externalSiteminderUniversalId = data.userId;
                    this.externalSiteminderUserId = data.userName;
                    this.externalBrandId = data.brandId;

                    return this.userProfileRepository.read(data.userId).pipe(
                        tap((userProfile: UserProfile) => {
                            if (!userProfile) {
                                this.global.logger.error(
                                    'getUser - siteminderId not sent'
                                );
                                throw new Error('Unable to get user profile');
                            }

                            this.authenticated = true;
                            this.setEntitledAdvisors(userProfile);
                            this.store.dispatch(new Login({ userProfile }));
                            this.userAuthenticated.next(this.authenticated);
                        })
                    );
                })
            );
    }

    /**
     * Kills any CAR session, cookie, local storage data set during authentication
     */
    public kill() {
        this.store.dispatch(new Logout());
        this.authenticated = false;
        // this.router.navigate([this.logOffUrl]);
        if (
            this.global.configService.getOption('mode') === EnvironmentMode.Dev
        ) {
            this.router.navigate(['/']);
        }
    }

    public logoutInitiated() {
        this.kasso.logoutInitiated();
    }

    /**
     * Logs the user out of car cleaning up previous store
     */
    public logOff() {
        this.store.dispatch(new Logout());
        this.authenticated = false;
    }

    private initializeService() {
        this.store.pipe(select(isLoggedIn)).subscribe(
            (auth: boolean) => {
                this.authenticated = auth;
            },
            () => {
                this.authenticated = false;
            }
        );

        this.appStore
            .select((seiStore) => seiStore.brandHeadersSlice.brandHeaders)
            .pipe(
                skipWhile(
                    (brandHeaders) =>
                        this.global.isEmpty(brandHeaders) ||
                        !Object.keys(brandHeaders).length
                )
            )
            .subscribe((data) => {
                const brandId: string = data.brandId ? data.brandId : this.global.configService.environment.authConfig.logOffBrandId;
                this._logOffUrl = this.global.configService.environment.authConfig.logOffUrl.replace(
                    '{{brandId}}',
                    brandId
                ).replace(this._proxyKey, window.location.hostname);
            });

        // Get Car UserProfile from store when we refresh the page
        this.store.pipe(select(getCarUserProfile)).subscribe(
            (userData) => {
                this.swpWalkMeServiceConfigured = userData ? userData.isSwpWalkMeServiceConfigured : false;
                if (userData && this.isSwpWalkMeServiceEnabled) {
                    this.userAuthenticated.next(this.authenticated);
                    this.walkMeService.loadWalkMeSnippet();
                }
            }
        );
    }

    private getSiteminderToken(siteminderId: string | number): string | number {
        if (siteminderId) {
            return siteminderId;
        }
        this.global.logger.error('getSiteminderToken - siteminderId not sent');

        if (
            this.global.configService.environment.authConfig.enforceSiteminder
        ) {
            if (this.externalSiteminderUniversalId) {
                return this.externalSiteminderUniversalId;
            }
            this.global.logger.error(
                'getSiteminderToken - enforceSiteminder is enabled and both but id is not set'
            );
        }

        throw new Error('Unable to determine siteminder header or mocked data');
    }

    private setEntitledAdvisors(userProfile: UserProfile) {
        // if no entitlements, through error and break out
        if (!userProfile || !userProfile.entitlements) {
            throw new Error(
                `Entitlements do not exist for this this user. ${userProfile ? userProfile.entityId.toString() : undefined
                }`
            );
        }

        // User could be non admin/advisor user, so then we either have the user select or get from primary advisor
        if (!userProfile.entitlements.advisors) {
            if (userProfile.firm && userProfile.firm.advisors) {
                delete userProfile.firm.advisors;
            }
            return;
        }

        const entitledEntities = userProfile.entitlements.advisors.map(
            (item: Advisor) => {
                return item.entityId;
            }
        );

        userProfile.firm.advisors = entitledEntities
            ? userProfile.firm.advisors.filter((advisor: User) => {
                if (entitledEntities.indexOf(advisor.entityId) >= 0) {
                    return advisor;
                }
            })
            : undefined;
    }
}
