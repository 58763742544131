<ng-template #recursiveList
             let-list>
    <ng-container>
        <div cdkDropList
             [cdkDropListData]="proposalSection.sections"
             (cdkDropListDropped)="dropItem($event)"
             [cdkDropListConnectedTo]="getConnectedList()">
            <div cdkDrag
                 *ngFor="let section of list | orderBy: 'sortOrder';"
                 [cdkDragData]="section"
                 [ngSwitch]="section.name">
                <div class="row toggle-row"
                     *ngIf="!section.hidden">
                    <div class="col-xs-9 toggle-row-left"
                         [class._indent2]="section.level === 2"
                         [class._indent4]="section.level === 3">
                        <span cdkDragHandle
                            *ngIf="!section.pinned"
                            class="wip-menu-grabber">
                            <i class="fa fas fa-grip-vertical"></i>
                        </span>
                        <span *ngIf="section.pinned" class="wip-menu-grabber">&nbsp;</span>
                        <p>{{section.name}}</p>
                    </div>
                    <div class="col-xs-3 toggle-row-center"
                         *ngIf="section.level === 2">
                        <sei-icon-toggle name="icon-toggle-{{section.id}}"
                                         [(ngModel)]="section.include"
                                         (ngModelChange)="modelChanged(proposalSection)"
                                         [trueIcon]="trueIcon"
                                         [falseIcon]="falseIcon"
                                         [iconClass]="iconClass"
                                         [iconSize]="iconSize"
                                         [offIsGrey]="offIsGrey"
                                         [disabled]="disabled">
                        </sei-icon-toggle>
                    </div>
                    <div class="col-xs-3"
                         *ngIf="section.level === 3">
                        <sei-car-html-form-elements *ngIf="section.name === marketIndexSection"
                                                    [proposalSection]="section"
                                                    [dropDownContent]="benchmarkDropdownItems"
                                                    [selectWidth]="'55'"
                                                    (dropdownSelected)="onMarketIndexChange()"></sei-car-html-form-elements>
                    </div>
                </div>
                <ng-container *ngIf="section.sections.length > 0">
                    <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: section.sections }"></ng-container>
                </ng-container>
            </div>
        </div>
    </ng-container>
</ng-template>
<ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: proposalSection.sections }"></ng-container>
