<div class="row nav-container">
    <div class="sidebar sidebar-container">
        <nav *ngFor="let document of checklistDocuments; let i = index;">
            <sei-car-sidebar-item-list [document]="document"></sei-car-sidebar-item-list>
        </nav>
    </div>
    <div class="drawer"
         [class.collapsed]=drawerCollapsed
         [class.open]="!drawerCollapsed">

    </div>
    <div class="drawer-arrow"
         (click)="toggleDrawerCollapsed()">
        <i class="fas" [ngClass]="drawerCollapsed ?  'fa-caret-right' : 'fa-caret-left'"></i>
    </div>
</div>
