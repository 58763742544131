<div class="account-type-listing car-bottom-padding">
    <div class="toggle-row">
        <div class="toggle-row-left">
            <h3 class="section-header form-group">Account {{accountIndex + 1}}
                <div class="pull-right">
                        <div class="icon-delete"
                             (click)="deleteAccount()"
                             [hidden]="accountsCount < 2">
                            <a>
                                <i class="fas fa-trash-alt"
                                   aria-label="Delete Account"></i>
                            </a>
                        </div>
                </div>
            </h3>
        </div>
    </div>
    <div *ngIf="showAccountTypes; then hasSelectedData; else noSelectedData"></div>

    <ng-template #hasSelectedData>
        <div *ngIf="selectedAccountType.taxQualified">
            <h5>IRA Accounts</h5>
        </div>
        <div *ngIf="!selectedAccountType.taxQualified">
            <h5>Non IRA Accounts</h5>
        </div>
        <div>
            <sei-car-proposal-account-option-selection [id]="selectedAccountType.id"
                                                       [pickListItemText]="selectedAccountType.description"
                                                       [groupName]="'AccountTypeListing'"
                                                       [checked]="true"
                                                       [accountData]="accountData"
                                                       [proposal]="proposal"
                                                       [suffix]="suffix"
                                                       [accountAdvisorList]="accountAdvisorList"
                                                       [disabledChangeOption]="!!accountData?.id"
                                                       (selectedData)="onSelectedDataClick()"></sei-car-proposal-account-option-selection>
        </div>
    </ng-template>

    <ng-template #noSelectedData>
        <div>
            <h3>IRA Accounts</h3>
            <sei-car-proposal-account-option-selection *ngFor="let item of qualifiedAccountTypeData"
                                                       [id]="item.id"
                                                       [pickListItemText]="item.description"
                                                       [groupName]="'AccountTypeListing'"
                                                       [accountData]="accountData"
                                                       [proposal]="proposal"
                                                       [suffix]="suffix"
                                                       [accountAdvisorList]="accountAdvisorList"
                                                       (selectedData)="onSelectionChange(item)"
                                                       (invalidAccountType)="onSelectedDataClick()"></sei-car-proposal-account-option-selection>
            <a class="show-content"
               (click)="onDisplayMoreClick(true)">
                <i [className]="showQualifiedContent ? 'fas fa-caret-up' : 'fas fa-caret-right'"></i>
                {{moreQualifiedText}} IRA Accounts
            </a>
            <h3>Non IRA Accounts</h3>
            <sei-car-proposal-account-option-selection *ngFor="let item of nonQualifiedAccountTypeData"
                                                       [id]="item.id"
                                                       [pickListItemText]="item.description"
                                                       [groupName]="'AccountTypeListing'"
                                                       [accountData]="accountData"
                                                       [proposal]="proposal"
                                                       [suffix]="suffix"
                                                       [accountAdvisorList]="accountAdvisorList"
                                                       (selectedData)="onSelectionChange(item)"
                                                       (invalidAccountType)="onSelectedDataClick()"></sei-car-proposal-account-option-selection>
            <a class="show-content closed"
               (click)="onDisplayMoreClick(false)">
                <i [className]="showNonQualifiedContent ? 'fas fa-caret-up' : 'fas fa-caret-right'"></i>
                {{moreNonQualifiedText}} Non IRA Accounts
            </a>
        </div>
    </ng-template>
</div>
