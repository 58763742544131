/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { ContactFactory } from '@CarFactories/contact-factory';
import { BaseFactory } from '@CarInterfaces/base-factory';
import { Beneficiary, BeneficiaryType } from '@CarInterfaces/beneficiary';

@Injectable({
    providedIn: 'root'
})
export class BeneficiaryFactory implements BaseFactory<Beneficiary> {
    public createObject(type?: BeneficiaryType): Beneficiary {
        if (!type) {
            type = {
                beneficiaryTypeId: 1,
                beneficiaryTypeDescription: 'Primary'
            };
        }
        return {
            accountBeneficiaryId: -1,
            beneficiaryType: type,
            ...new ContactFactory().createObject()
        };
    }
}
