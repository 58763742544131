/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, Subject } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { FileUpload, FileUploadDocumentTypes, FileUploadViewMode } from '../model/enums';
import { DocumentUpload, FileUploadEntity, FileUploadForm, UploadListForm } from '../model/file-upload';
import { SeiPayload } from '../model/sei-payload';
import { DataSharingService } from './data-sharing.service';
import { GenericErrorService } from './system/generic-error.service';
import { GlobalService } from './system/global.service';

@Injectable({
    providedIn: 'root'
})
export class UploadFormService {
    private fileUploadFormStream: BehaviorSubject<FileUploadForm>;
    private genericErrorService: GenericErrorService;
    private fileUploadFormValidDataStream: BehaviorSubject<boolean>;
    private allowedExtensions: string[] = ['png', 'jpeg', 'jpg', 'gif'];
    private allowPDFExtension: string[] = ['pdf'];
    private allowedContentTypes: string[] = ['image/'];
    private _tooManyFilesErrorStream = new Subject<number>();
    private _invalidFilesErrorStream = new Subject<File[]>();
    private _invalidFileName = new Subject<boolean>();
    private _currentFileSizeErrorStream = new Subject<File>();
    private fileUploadedStream: BehaviorSubject<UploadListForm>;
    public maxFilesAllowed = Number.MAX_SAFE_INTEGER;
    public maxMultipleFileToUpload = FileUpload.MaxFilesAllowedByUpload;
    public validMultipleFile = [];
    public invalidMultipleFile = [];
    public validSetFile = [];

    constructor(
        private dataSharingService: DataSharingService,
        protected carGlobal: GlobalService,
        private http: HttpClient
    ) { }

    public getFileUploadFormStream(): Observable<FileUploadForm> {
        if (!this.fileUploadFormStream) {
            return EMPTY;
        }

        return this.fileUploadFormStream.asObservable();
    }

    public setFileUploadFormStream(fileUploadForm: FileUploadForm) {
        if (!this.fileUploadFormStream) {
            this.fileUploadFormStream = new BehaviorSubject<FileUploadForm>(
                fileUploadForm
            );
        }
        this.fileUploadFormStream.next(fileUploadForm);
    }

    public getFileUploadFormValidDataStream(): Observable<boolean> {
        if (!this.fileUploadFormValidDataStream) {
            return EMPTY;
        }

        return this.fileUploadFormValidDataStream.asObservable();
    }

    public setFileUploadFormValidDataStream(validData: boolean) {
        if (!this.fileUploadFormValidDataStream) {
            this.fileUploadFormValidDataStream = new BehaviorSubject<boolean>(
                validData
            );
        }
        this.fileUploadFormValidDataStream.next(validData);
    }

    public getFileUploadedStream(): Observable<UploadListForm> {
        if (!this.fileUploadedStream) {
            return EMPTY;
        }

        return this.fileUploadedStream.asObservable();
    }

    public setFileUploadedStream(documentUploadListType: UploadListForm): void {
        if (!this.fileUploadedStream) {
            this.fileUploadedStream = new BehaviorSubject<UploadListForm>(
                documentUploadListType
            );
        }

        this.fileUploadedStream.next(documentUploadListType);
    }

    public getAllowedExtensions(): string[] {
        return this.allowedExtensions;
    }

    public getAllowedPDFExtension(): string[] {
        return this.allowPDFExtension;
    }

    public transformToFileUploadEntity(fileUploadForm: FileUploadForm): FileUploadEntity {
        const fileUploadEntity: FileUploadEntity = fileUploadForm;
        return fileUploadEntity;
    }

    public validateData(fileUploadForm: FileUploadForm): void {
        let success: boolean = false;
        if (fileUploadForm) {
            success = !(!fileUploadForm.files || fileUploadForm.files.length === 0);
            this.setFileUploadFormValidDataStream(success);
        }
    }

    public validateDocumentTypes(
        documentUpload: DocumentUpload,
        allowedFileUploadDocumentTypes: FileUploadDocumentTypes[]
    ): boolean {
        if (documentUpload && documentUpload.documents) {
            documentUpload.documents.forEach((document) => {
                if (
                    !document.fileType.fileTypeId ||
                    allowedFileUploadDocumentTypes.lastIndexOf(document.fileType.fileTypeId) === -1
                ) {
                    return false;
                }
            });

            return true;
        }
    }

    public cleanFiles(fileUploadForm: FileUploadForm): void {
        if (fileUploadForm) {
            fileUploadForm.files = [];
        }
    }

    public setFiles(fileUploadForm: FileUploadForm, fileList: File[]): void {
        if (fileUploadForm && fileList.length > 0) {
            const validFiles: File[] = [];
            for (let i = 0; i < fileList.length; i++) {
                const currentFile = fileList[i];

                if (
                    this.validateFile(currentFile.type)
                ) {
                    validFiles.push(currentFile);
                }
            }

            if (validFiles && validFiles.length > 0) {
                if (!fileUploadForm.files) {
                    fileUploadForm.files = [];
                } else {
                    this.cleanFiles(fileUploadForm);
                }

                for (let i = 0; i < validFiles.length; i++) {
                    const currentFile = fileList[i];
                    fileUploadForm.files.push(currentFile);
                }
            }
        }
    }

    public validateFile(fileType: string): boolean {
        const validExtension = this.allowedExtensions.filter((extension) => fileType.includes(extension.toLowerCase()));
        const validContentType = this.allowedContentTypes.filter((contentType) => fileType.includes(contentType.toLowerCase()));

        return (validExtension.length > 0 && validContentType.length > 0);
    }

    public upload(fileUploadForm: FileUploadForm, proposalId: number, fileUploadViewMode: FileUploadViewMode,
        presentationType: string, sectionName: string, subSectionName: string) {

        if (fileUploadForm) {
            const formdata = new FormData();
            let apiUrl = '';
            if (fileUploadForm.files && fileUploadForm.files.length > 0) {
                for (let i = 0; i < fileUploadForm.files.length; i++) {
                    // 'file' appended is the name  of the  request parameter
                    formdata.append('file', fileUploadForm.files[i]);
                }
                switch (fileUploadViewMode) {

                    case FileUploadViewMode.CoverImage: {
                        apiUrl = this.carGlobal.configService.routeFormatter(
                            this.carGlobal.configService.getEndPoint(
                                'postCoverPageImages'
                            ),
                            [
                                {
                                    key: 'proposalId',
                                    value: proposalId
                                },
                                {
                                    key: 'presentationType',
                                    value: presentationType
                                },
                                {
                                    key: 'sectionName',
                                    value: sectionName
                                },
                                {
                                    key: 'subSectionName',
                                    value: subSectionName
                                }
                            ]
                        );
                        return this.http.post<SeiPayload>(apiUrl, formdata)
                            .pipe(
                                share(),
                                map((response) => {
                                    if (
                                        response &&
                                        response.data &&
                                        response.data.length > 0) {
                                        if (response.error && response.error.length > 0) {
                                            this.genericErrorService.setGenericError({
                                                code: response.error.code,
                                                description: response.error.message
                                            });
                                        }
                                    }
                                    return response;
                                })
                            );
                    }

                    case FileUploadViewMode.BrandLogo: {
                        apiUrl = this.carGlobal.configService.routeFormatter(
                            this.carGlobal.configService.getEndPoint(
                                'postProposalFirmLogo'
                            ),
                            {
                                key: 'proposalId',
                                value: proposalId
                            }
                        );

                        return this.http.post<SeiPayload>(apiUrl, formdata)
                            .pipe(
                                share(),
                                map((response) => {
                                    if (
                                        response &&
                                        response.data &&
                                        response.data.length > 0) {
                                        if (response.error && response.error.length > 0) {
                                            this.genericErrorService.setGenericError({
                                                code: response.error.code,
                                                description: response.error.message
                                            });
                                        }
                                    }
                                    // Get the filename without the extension
                                    const split = fileUploadForm.files[0].name.split('.');
                                    const fileName = split.slice(0, split.length - 1).join('.');
                                    this.dataSharingService.imageUpload
                                        .next({ label: fileName, value: response.data[0].documents[0].documentPath });

                                    return response;
                                })
                            );
                    }
                    case FileUploadViewMode.AdvisorContent: {
                        apiUrl = this.carGlobal.configService.routeFormatter(
                            this.carGlobal.configService.getEndPoint(
                                'advisorCustomContent'
                            ),
                            [
                                {
                                    key: 'proposalId',
                                    value: proposalId
                                },
                                {
                                    key: 'presentationType',
                                    value: presentationType
                                },
                                {
                                    key: 'sectionName',
                                    value: sectionName
                                },
                                {
                                    key: 'subSectionName',
                                    value: encodeURIComponent(subSectionName)
                                }
                            ]
                        );
                        return this.http.post<SeiPayload>(apiUrl, formdata)
                            .pipe(
                                share(),
                                map((response) => {
                                    if (
                                        response &&
                                        response.data &&
                                        response.data.length > 0) {
                                        if (response.error && response.error.length > 0) {
                                            this.genericErrorService.setGenericError({
                                                code: response.error.code,
                                                description: response.error.message
                                            });
                                        }
                                    }
                                    return response;
                                })
                            );
                    }
                }
            }

        }
        return EMPTY;
    }

    public get acceptMultipleUploadExtension(): string {
        return this.allowedExtensions
            .map((extension: string) => '.' + extension)
            .join(', ');
    }

    public get acceptPDFExtension(): string {
        return this.allowPDFExtension.map((extension: string) => '.' + extension)
            .join(',');
    }

    public get invalidFilesErrorStream(): Observable<File[]> {
        return this._invalidFilesErrorStream;
    }

    public get invalidFileName(): Observable<boolean> {
        return this._invalidFileName;
    }

    public get currentFileSizeErrorStream(): Observable<File> {
        return this._currentFileSizeErrorStream;
    }

    public get tooManyFilesErrorStream(): Observable<number> {
        return this._tooManyFilesErrorStream;
    }

    public getAllowedExtensionForMultipleUpload(): string[] {
        return this.allowedExtensions;
    }

    public addMultipleFiles(fileList: File[], index?: number, acceptPdf?: boolean) {
        const fileUploadForm = this.fileUploadFormStream.value;
        this.cleanFiles(fileUploadForm);
        this.validMultipleFile = [];
        if (fileUploadForm && fileList.length > 0) {
            if (fileList.length > this.maxMultipleFileToUpload) {
                this._tooManyFilesErrorStream.next(fileList.length);
                return;
            }
            if (fileUploadForm && fileUploadForm.files && fileUploadForm.files.length > 0) {
                if ((fileList.length + fileUploadForm.files.length) > this.maxMultipleFileToUpload) {
                    this._tooManyFilesErrorStream.next(fileList.length + fileUploadForm.files.length);
                    return;
                }
            }

            for (let ix = 0; ix < fileList.length; ix++) {
                const file = fileList[ix];
                this.validMultipleFile.push(file);
            }
            if (this.validMultipleFile.length) {
                this.setMultipleFiles(fileUploadForm, this.validMultipleFile, acceptPdf);

                if (!this.invalidMultipleFile.length) {
                    this.validateData(fileUploadForm);
                }
            }
        }
    }

    public setMultipleFiles(fileUploadForm: FileUploadForm, fileList: File[], acceptPdf?: boolean) {
        if (fileUploadForm && fileList.length > 0) {
            const validSetFile: File[] = [];
            for (let i = 0; i < fileList.length; i++) {
                const currentFile = fileList[i];
                const extension = currentFile.name.split('.')[
                    currentFile.name.split('.').length - 1
                ].toLowerCase();
                const extensions: String[] = acceptPdf ? this.allowPDFExtension : this.allowedExtensions;
                if (extensions.lastIndexOf(extension) === -1) {
                    this.invalidMultipleFile.push(currentFile);
                }
                if (this.invalidMultipleFile.length) {
                    this._invalidFilesErrorStream.next(this.invalidMultipleFile);
                }
                if (
                    this.getMaxFileSize() >= currentFile.size
                ) {
                    validSetFile.push(currentFile);
                } else {
                    this.invalidMultipleFile.push(currentFile);
                    this._invalidFilesErrorStream.next(this.invalidMultipleFile);
                    fileUploadForm.files.push(currentFile);
                }
                this._currentFileSizeErrorStream.next(currentFile);

                // fileName validation
                const fileName = currentFile.name.substring(0, currentFile.name.lastIndexOf('.'));
                const validCharacters = /^[a-zA-Z0-9!@#^~$&()[\]\-_. ,\/\"\']*$/;
                if (!(validCharacters.test(fileName))) {
                    this._invalidFileName.next(true);
                }
            }

            if (validSetFile && validSetFile.length > 0) {
                if (!fileUploadForm.files) {
                    fileUploadForm.files = [];
                } else {
                    this.cleanFiles(fileUploadForm);
                }

                for (let i = 0; i < validSetFile.length; i++) {
                    const currentFile = validSetFile[i];
                    const currentFileTotalSize = fileUploadForm.files
                        ? fileUploadForm.files.reduce((accumulator, file) => {
                            return accumulator + file.size;
                        }, 0)
                        : 0;

                    if (
                        this.getMaxFileSize() <
                        currentFileTotalSize + currentFile.size
                    ) {
                        break;
                    }

                    fileUploadForm.files.push(currentFile);
                }
            }
        }
    }

    public getMaxFileSize(): number {
        let maxBytesFileSize = 0;
        const maxBytesFileSizeConfig = this.carGlobal.configService.getOption(
            'maxBytesAllFilesSize'
        );

        if (maxBytesFileSizeConfig) {
            maxBytesFileSize = parseInt(maxBytesFileSizeConfig, 10);

            if (isNaN(maxBytesFileSize)) {
                maxBytesFileSize = 0;
            }
        }
        return maxBytesFileSize;
    }

}
