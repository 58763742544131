/*
 Copyright: This information constitutes the exclusive property of SEI
 Investments Company, and constitutes the confidential and proprietary
 information of SEI Investments Company.  The information shall not be
 used or disclosed for any purpose without the written consent of SEI
 Investments Company.
*/
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
    CoverPageThemeIds,
    DataSharingService,
    DefaultImage,
    EnvironmentMode,
    GenericErrorService,
    GlobalService,
    PdfStatement,
    PresentationChecklistService,
    PresentationSettingService,
    ProposalPreviewResponse,
    ProposalSection, ProposalSectionLabel, ProposalStatement, RetrieveFileRequest, SectionPdfImages
} from '@sei/advisor-client-review-proposal-ux';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import * as _ from 'lodash';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';


// eslint-disable-next-line no-var
declare var $: any;
// eslint-disable-next-line no-var
declare var previewIt: any;

@Component({
    selector: 'sei-car-preview',
    templateUrl: './preview.component.html',
    styleUrls: ['./preview.component.scss']
})
export class PreviewComponent extends ParentComponentSubscriptionManager implements OnInit, AfterViewInit, OnDestroy {
    @Input()
    public pdfCheckList: PdfStatement;

    public proposalSection: ProposalSection;

    public proposalStatement: ProposalStatement;

    public coverImageData: ProposalSection;
    public coverTitleData: ProposalSection;
    public brandLogoData: ProposalSection;
    public titleBarColorData: ProposalSection;
    public titleBarTextColorData: ProposalSection;
    public brandLogo: ProposalSection;
    public dateNow: number = Date.now();

    public proposalSectionLabel: typeof ProposalSectionLabel = ProposalSectionLabel;
    public presentationImage: string;

    public currentIndex: number = 0;
    public totalItems: number = 0;
    public previousClickCount: number = 0;
    public nextClickCount: number = 0;
    public currentIndexChangeCount: number = 0;

    public isCoverTitleDataVisible: boolean;
    public isCoverImageDataVisible: boolean;
    public showBrandLogo: boolean = false;

    public reverseProxyPath: string;
    public imagePath: string = '';
    public verticalBarColorStyle: {};
    public isLoading: boolean;
    public previewPath: string;
    public imagePathWithPort: string;
    public coverSubtitle: ProposalSection;
    public showCoverSubtitle: boolean;
    public themeColor: ProposalSection;

    public COVER_PAGE_THEME_IDS = CoverPageThemeIds;

    @ViewChild('mainScreen', { static: false, read: ElementRef })
    public previewElementRef: ElementRef<HTMLElement>;

    private localHostUrl: string = 'localhost';
    private prHostUrl: string = 'seidevjen';

    private currentPageLoading: string;
    private imageToShow: any;
    private isImageLoading: boolean;

    private currentSectionPreviewImages: ProposalSection;
    private previewImage: string | ArrayBuffer;
    private coverImage: string | ArrayBuffer;


    constructor(
        private dataSharingService: DataSharingService,
        private proposalChecklistService: PresentationChecklistService,
        private globalService: GlobalService,
        private genericErrorService: GenericErrorService,
        private presentationSettingService: PresentationSettingService) {
        super('PreviewComponent');
    }

    public ngOnInit(): void {
        if (this.pdfCheckList) {
            // Note: Set the first section in this case cover page section as default for preview.
            this.proposalSection = this.pdfCheckList.proposalStatement[0].sections[0];
        }

        this.refreshCoverPageView();
    }

    public ngOnDestroy(): void {
        this.dataSharingService.proposalSectionChanged.next(undefined);
        super.ngOnDestroy();
    }


    public refreshCoverPageView(): void {
        if (this.proposalSection) {
            if (this.proposalSection.name === ProposalSectionLabel.CoverPage) {
                const previousCoverImageData: ProposalSection = _.cloneDeep(this.coverImageData);
                const previousBrandLogoData: ProposalSection = _.cloneDeep(this.brandLogoData);
                this.coverImageData = _.cloneDeep(this.proposalSection.sections.
                    find((section) => section.name === ProposalSectionLabel.CoverImage));
                this.coverTitleData = this.proposalSection.sections.find((section) => section.name === ProposalSectionLabel.CoverTitle);
                this.brandLogoData = _.cloneDeep(this.proposalSection.sections.
                    find((section) => section.name === ProposalSectionLabel.BrandLogo));
                this.isCoverTitleDataVisible = (this.coverTitleData && this.coverTitleData.include);
                this.isCoverImageDataVisible = (this.coverImageData && this.coverImageData.include);
                this.showBrandLogo = (this.brandLogoData && this.brandLogoData.include);

                this.titleBarColorData =
                    this.proposalSection.sections.find((section) => section.name === ProposalSectionLabel.TitleBarColor);
                this.titleBarTextColorData =
                    this.proposalSection.sections.find((section) => section.name === ProposalSectionLabel.TitleBarTextColor);
                this.brandLogo = this.proposalSection.sections.find((section) => section.name === ProposalSectionLabel.BrandLogo);

                this.themeColor = this.proposalSection.sections.find((section) => section.name === ProposalSectionLabel.ThemeColor);
                this.coverSubtitle = this.proposalSection.sections.find((section) => section.name === ProposalSectionLabel.CoverSubtitle);
                this.showCoverSubtitle = this.coverSubtitle?.include;
                if (!this.brandLogo && !this.brandLogo.inputProperty && !this.brandLogo.inputProperty.value) {
                    this.brandLogo.inputProperty.value =
                        `${DefaultImage.NoImagePath} ${DefaultImage.NoImagePng}`;
                }

                if (this.coverImageData?.inputProperty?.value?.indexOf(DefaultImage.NoImagePng) !== -1 || !this.isCoverImageDataVisible) {
                    this.verticalBarColorStyle = { 'background-color': this.titleBarColorData.inputProperty.value, 'opacity': '0.1' };
                } else {
                    this.verticalBarColorStyle = { 'background-color': 'rgb(255, 255, 255)', 'opacity': '0.7' };
                }
                this.coverImageData?.inputProperty?.value?.replace(/ /g, '%20');
                if (!previousBrandLogoData || previousBrandLogoData.inputProperty.value !== this.brandLogoData.inputProperty.value) {
                    this.getBrandLogoImage();
                }
                if (!previousCoverImageData || previousCoverImageData.inputProperty.value !== this.coverImageData.inputProperty.value) {
                    this.getCoverPageImage();
                }
            }

            this.reverseProxyPath = this.globalService.configService.environment.proxyGroup;
            this.imagePathWithPort = `${this.reverseProxyPath}${this.globalService.configService.environment.proxyGroupSegment}/`;
            // Note: This is so we can test localhost, jen servers
            if (this.globalService.configService.environment.mode === EnvironmentMode.Dev) {
                // eslint-disable-next-line max-len
                this.reverseProxyPath = `${this.globalService.configService.environment.carConfig.transportProtocol}://${this.globalService.configService.environment.carConfig.server}`;
                this.imagePathWithPort =
                    `${this.reverseProxyPath}:${this.globalService.configService.environment.carConfig.serverPort}/`;
            }

            this.previewPath =
                `proposalDocuments/presentation/preview/${this.pdfCheckList.proposalId}/`;
        }
    }

    public ngAfterViewInit(): void {
        $(() => {
            previewIt();
        });

        this.subscriptions.push(this.dataSharingService.proposalSectionChanged.subscribe((proposalSectionResponse: ProposalSection) => {
            if (proposalSectionResponse) {
                this.proposalSection = { ...proposalSectionResponse };

                // Reset loading flag
                this.isLoading = false;
                if (proposalSectionResponse.name === ProposalSectionLabel.CoverPage) {
                    this.refreshCoverPageView();
                } else if (proposalSectionResponse.name === ProposalSectionLabel.ReportOptions) {
                    this.getProposalStatementPreviewImages();
                } else {
                    this.getPreviewImages();
                }
                setTimeout(() => {
                    this.calculatePreviewScale();
                });
            }
        }));
        setTimeout(() => {
            this.calculatePreviewScale();
        });
    }

    public previousClicked(currentIndex: number): void {
        this.isLoading = true;
        this.currentIndex = currentIndex;
        this.previousClickCount += 1;
        this.currentIndexChangeCount += 1;
        this.currentIndexChange(this.currentIndex);
        this.isImageLoading = true;

        if (this.imageToShow) {
            this.imageToShow = null;
        }

        if (this.pdfCheckList) {
            this.getPresentationCheckListPreviewImage(this.pdfCheckList.proposalId, this.presentationImage);
        }

    }

    public nextClicked(currentIndex: number): void {
        this.isLoading = true;
        this.currentIndex = currentIndex;
        this.nextClickCount += 1;
        this.currentIndexChangeCount += 1;
        this.currentIndexChange(this.currentIndex);
        this.isImageLoading = true;

        if (this.imageToShow) {
            this.imageToShow = null;
        }
        if (this.pdfCheckList) {
            this.getPresentationCheckListPreviewImage(this.pdfCheckList.proposalId, this.presentationImage);
        }

    }

    public currentIndexChange(currentIndex: number): number {
        this.currentIndex = currentIndex;
        if (this.proposalSection && this.proposalSection.sectionContent
            && this.proposalSection.previewImages && this.proposalSection.previewImages.length > 0) {
            this.presentationImage = this.proposalSection.previewImages[currentIndex - 1].name;
        }

        return this.currentIndex;
    }

    public bindSectionPreviewImages(): void {

        if (this.proposalSection.name !== ProposalSectionLabel.CoverPage) {

            let includeSections: number[] = [];
            this.proposalSection.sections.sort((a, b) => {
                return a.sortOrder - b.sortOrder;
            });

            includeSections = this.proposalSection.sections.filter((f) => {
                if (f.include) {
                    return f;
                }
            }).map((x) => x.id);

            // Note: indlude parent section
            includeSections.push(this.proposalSection.id);

            this.currentIndex = 0;
            const filteredImages: SectionPdfImages[] = [];

            includeSections.forEach((element) => {
                const items = this.currentSectionPreviewImages.previewImages.filter(
                    (x) => x.id === element && this.proposalSection.include);
                filteredImages.push(...items);
            });

            this.proposalSection.previewImages = filteredImages;

            this.totalItems = this.proposalSection.previewImages.length;

            if (this.totalItems > 0 && this.proposalSection.previewImages[this.currentIndex]) {
                this.presentationImage = this.proposalSection.previewImages[0].name;
            } else {
                this.presentationImage = '';
            }

            if (this.pdfCheckList && this.presentationImage) {

                this.isImageLoading = true;
                if (this.imageToShow) {
                    this.imageToShow = null;
                }

                this.getPresentationCheckListPreviewImage(this.pdfCheckList.proposalId, this.presentationImage);

            }


        }
    }

    public createImageFromBlob(image: Blob) {

        this.isImageLoading = true;
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            this.imageToShow = reader.result;
            this.isImageLoading = false;
            this.isLoading = false;
        }, false);

        if (image) {
            reader.readAsDataURL(image);
        }
    }

    public getPresentationCheckListPreviewImage(proposalId: number, fileName: string): void {

        this.subscriptions.push(this.presentationSettingService.getPresentationCheckListPreviewImage(proposalId, fileName).
            subscribe(
                (response) => {
                    this.createImageFromBlob(response);
                },
                (err) => {
                    this.isLoading = false;
                    this.genericErrorService.setGenericError({ code: '', description: `${err.message}` });
                    this.isImageLoading = false;
                }
            ));
    }

    public getTheme(proposalSection: ProposalSection): CoverPageThemeIds {
        const themeSection: ProposalSection = proposalSection?.sections?.
            find((section: ProposalSection) => section?.name === ProposalSectionLabel.PresentationTheme);
        return Number(themeSection?.inputProperty?.value) as CoverPageThemeIds;
    }

    private getProposalStatement() {
        this.pdfCheckList.proposalStatement
            .forEach((statement) => {
                const foundIndex = statement.sections.findIndex((section) => section.id === this.proposalSection.id);
                if (foundIndex > -1) {
                    this.proposalStatement = statement;
                }
            });
    }

    private getImages(proposalSectionImages: ProposalPreviewResponse[]) {
        this.proposalSection.previewImages = [];
        for (const imageSection of proposalSectionImages) {

            const section: ProposalSection =
                this.recursiveFind(this.proposalSection.sections, imageSection.id, this.proposalSection);

            if (section) {
                for (const image of imageSection.imageNames) {
                    this.proposalSection.previewImages.push({
                        id: section.id,
                        name: image
                    });
                }
            }
        }

        this.currentSectionPreviewImages = { ...this.proposalSection };
        this.bindSectionPreviewImages();
        this.isLoading = false;
    }

    private getPreviewImages() {
        this.isLoading = true;
        this.getProposalStatement();
        this.proposalChecklistService
            .retrievePreviewSectionImages(this.pdfCheckList.proposalId, this.proposalStatement.statementType.id, this.proposalSection)
            .pipe(map((proposalSectionImages: ProposalPreviewResponse[]) => {

                this.getImages(proposalSectionImages);

            }), catchError((err) => of(this.genericErrorService.setGenericError({ code: '100', description: err }))))
            .subscribe();
    }

    private recursiveFind(proposalSections: ProposalSection[], id: number, proposalSection?: ProposalSection): ProposalSection {

        if (proposalSection && proposalSection.statementSectionId === id) {
            // Note: look in parent section
            return proposalSection;
        }
        if (proposalSection && proposalSection.id === id) {
            // Note: look in parent section
            return proposalSection;
        }
        if (proposalSections) {
            for (let i = 0; i < proposalSections.length; i++) {
                if (proposalSections[i].statementSectionId === id) {
                    return proposalSections[i];
                }
                if (proposalSections[i].id === id) {
                    return proposalSections[i];
                }
                const found = this.recursiveFind(proposalSections[i].sections, id);
                if (found) {
                    return found;
                }
            }
        }
    }

    private getProposalStatementPreviewImages() {
        this.isLoading = true;
        this.getProposalStatement();
        this.proposalChecklistService
            .retrievePreviewSectionImagesByProposalStatement(this.pdfCheckList.proposalId, this.proposalStatement)
            .pipe(map((proposalSectionImages: ProposalPreviewResponse[]) => {
                this.getImages(proposalSectionImages);
            }), catchError((err) => of(this.genericErrorService.setGenericError({ code: '100', description: err }))))
            .subscribe();
    }

    private createPreviewImageFromBlob(image: Blob): void {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            this.previewImage = reader.result;
        }, false);
        if (image) {
            reader.readAsDataURL(image);
        }
    }

    private createCoverImageFromBlob(image: Blob): void {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            this.coverImage = reader.result;
        }, false);
        if (image) {
            reader.readAsDataURL(image);
        }
    }

    private getBrandLogoImage() {
        this.previewImage = undefined;
        const request: RetrieveFileRequest = { filePath: this.brandLogo.inputProperty.value };
        this.subscriptions.push(this.presentationSettingService.retrieveFile(request).subscribe((image: Blob): void => {
            this.createPreviewImageFromBlob(image);
        }));
    }

    private getCoverPageImage() {
        this.coverImage = undefined;
        const request: RetrieveFileRequest = { filePath: this.coverImageData.inputProperty.value };
        this.subscriptions.push(this.presentationSettingService.retrieveFile(request).subscribe((image: Blob): void => {
            this.createCoverImageFromBlob(image);
        }));
    }

    public calculatePreviewScale(): void {
        const baseHeight: number = 650;
        const baseWidth: number = 502;
        const currentHeight = this.previewElementRef.nativeElement.clientHeight;
        const calculatedHeightRatio = currentHeight / baseHeight;
        const currentWidth = this.previewElementRef.nativeElement.clientWidth;
        const calculatedWidthRatio = currentWidth / baseWidth;
        if (calculatedHeightRatio > 0 && calculatedWidthRatio > 0) {
            document.body.style.setProperty('--preview-scale-y', calculatedHeightRatio.toFixed(2));
            document.body.style.setProperty('--preview-scale-x', calculatedWidthRatio.toFixed(2));
        }
    }

}
