<!--
  ~ Copyright: This information constitutes the exclusive property of SEI
  ~ Investments Company, and constitutes the confidential and proprietary
  ~ information of SEI Investments Company.  The information shall not be
  ~ used or disclosed for any purpose without the written consent of SEI
  ~ Investments Company.
  -->
<car-spinner [commonComponentSpinnerStatus]="showOverlayLoader"></car-spinner>
<div [seiMatomoTracking]="'23'"></div>

<sei-car-siteminder-validator (completedFirstAuth)="firstAuthCompleted = $event"></sei-car-siteminder-validator>
<sei-apigee *ngIf="firstAuthCompleted"></sei-apigee>

<car-header></car-header>

<br>

<sei-dev-parms></sei-dev-parms>
<div class="app-wrapper">
    <router-outlet></router-outlet>
</div>

<sei-footer></sei-footer>
<sei-toast-notifications></sei-toast-notifications>
<sei-kasso *ngIf="enableKasso"
           [appName]="appName"
           [logOffUrl]="logOffUrl"></sei-kasso>
