/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { SiteminderGuard } from '@CarAuth/siteminder.guard';
import { FirmDataGuard } from '@CarServices/firm-data/firm-data.guard';
import { Routes } from '@angular/router';
import { AssetAllocationComponent } from '@sei/ias-applications-lib-ux';
import { ProposalResolverService } from 'projects/advisor-client-review-proposal-ux/src/lib/service/proposal-resolver.service';
import { AccountDetailsComponent } from './account-details/account-details.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EditGoalContainerComponent } from './edit-goal-container/edit-goal-container.component';
import { EditProposalContainerComponent } from './edit-proposal-container/edit-proposal-container.component';
import { FeeAnalysisComponent } from './fee-analysis/fee-analysis.component';
import { MdcProposalComponent } from './mdc-proposal/proposal.component';
import { ScenarioEditComponent } from './scenario-edit/scenario-edit.component';
import { WipCheckListComponent } from './wip-checklist/wip-checklist.component';
import { AllAccountsComponent } from './all-accounts/all-accounts.component';
import { ExistingAccountsPickListComponent } from './existing-accounts/existing-accounts-pick-list/existing-accounts-pick-list.component';


export const PROPOSAL_ROUTES: Routes = [
    {
        path: 'Proposal',
        component: MdcProposalComponent,
        canActivate: [SiteminderGuard, FirmDataGuard]
    },
    {
        path: 'Proposal/WIP/:proposalId',
        component: WipCheckListComponent,
        canActivate: [SiteminderGuard, FirmDataGuard],
        resolve: {
            wipCheckList: ProposalResolverService
        },
        children: [
            {
                path:
                    'Scenarios/:scenarioId/Accounts/:accountId',
                component: AccountDetailsComponent,
                children: [{
                    path: 'Asset-Allocation/:isInvestmentSelection',
                    component: AssetAllocationComponent
                }]
            },
            {
                path: 'Fees',
                component: FeeAnalysisComponent
            },
            {
                path: 'Scenarios/:scenarioId',
                component: ScenarioEditComponent
            },
            {
                path: 'EditProposal',
                component: EditProposalContainerComponent
            },
            {
                path: 'Scenarios/:scenarioId/Goals',
                component: EditGoalContainerComponent
            },
            {
                path: 'Scenarios/:scenarioId/ProposedAccounts',
                component: AllAccountsComponent
            },
            {
                path: 'Scenarios/:scenarioId/ExistingAccounts',
                component: ExistingAccountsPickListComponent
            }
        ]
    },
    {
        path: 'Proposal/Dashboard',
        component: DashboardComponent,
        canActivate: [SiteminderGuard, FirmDataGuard]
    }
];
