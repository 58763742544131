/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { UserProfile } from '@CarInterfaces/user-profile';
import { UserProfileRepository } from '@CarRepos/user-profile-repository';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { Observable, Subject, Subscription } from 'rxjs';
import { ScenarioAccountFee } from '../model/account-fee';
import { InvestmentProgramType, ProposalSectionLabel, ProposalStatementType } from '../model/enums';
import { PresentationBusinessRules, ProposalSection, ProposalStatement } from '../model/presentation';
import { Account, Proposal, Scenario } from '../model/proposal';
import { DataSharingService } from '../service/data-sharing.service';
import { FeeScheduleGroupService } from '../service/fee-schedule-group.service';
import { PresentationSettingService } from '../service/presentation-setting.service';
import { ProposalService } from '../service/proposal.service';
import { UserProfileService } from '../service/user-profile.service';

@Component({
    selector: 'sei-car-presentation-checklist',
    templateUrl: './presentation-checklist.component.html'
})
export class PresentationChecklistComponent extends ParentComponentSubscriptionManager implements OnInit, OnDestroy {

    @Input()
    public proposalStatement: ProposalStatement;

    @Output()
    public savePdfStatement = new EventEmitter();

    @Output()
    public saveAdvisorContent = new EventEmitter();

    @Output()
    public onDeleteSection = new EventEmitter();

    @Output()
    public brandLogoUpload: EventEmitter<string> = new EventEmitter();

    public proposalId: number;
    public proposalStream: Observable<Proposal>;
    public proposal: Proposal;
    public _DFSdrawdown = new Subject<boolean>();

    public businessRulesOptions: PresentationBusinessRules = {
        showAdvisorFee: false,
        reportingLevelRollUpByAccountOption: false,
        ipsReportingLevelRollUpAllAccounts: false,
        showDfs: false,
        HypotheticalUprLwrAssetClas: false,
        HypotheticalUprLwrCMA: false,
        DFSDrawdown: false
    };

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly proposalService: ProposalService,
        private readonly feeScheduleGroupService: FeeScheduleGroupService,
        private readonly presentationSettingService: PresentationSettingService,
        private readonly userProfileRepository: UserProfileRepository,
        private readonly dataSharingService: DataSharingService,
        private readonly userProfileService: UserProfileService
    ) {
        super('PresentationChecklistComponent');
    }

    public ngOnInit(): void {
        this.proposalId = Number(this.activatedRoute.snapshot.paramMap.get('proposalId'));

        const subscription: Subscription = this.proposalService.currentProposal.subscribe((proposal: Proposal) => {
            if (proposal && this.proposalStatement && this.proposalStatement.statementType) {
                this.applyBusinessRules(proposal?.scenarios[0]?.accounts[0]?.advisors[0]?.entityId);
                if (this.proposalStatement.statementType.id === ProposalStatementType.Proposal) {
                    this.applyReportingLevelRollUpByAccount(proposal);
                    this.applyFeeScheduleBusinessRules();
                } else if (this.proposalStatement.statementType.id === ProposalStatementType.IPS) {
                    this.proposal = proposal;
                    if (proposal && proposal.scenarios) {
                        this.applyDfsBusinessRules(proposal);
                    }
                    this.applyReportingLevelRollUpByAccount(proposal);
                }
            }
        });
        this.subscriptions.push(subscription);
        this.subscriptions.push(this.getdfsDrawdown.subscribe(
            (isReturnByDropdownEnabled: boolean) => {
                this.businessRulesOptions.DFSDrawdown = isReturnByDropdownEnabled;
                if (this.proposal && this.proposal.scenarios) {
                    this.applyDfsBusinessRules(this.proposal);
                }
            }));
        this.subscriptions.push(this.dataSharingService.brandLogoUpload.subscribe((imagePath: string): void => {
            this.brandLogoUpload.emit(imagePath);
        }));
    }

    public onSavePdfStatement(event: string): void {
        this.savePdfStatement.emit(event);
    }

    private applyDfsBusinessRules(proposal: Proposal): void {
        this.businessRulesOptions.showDfs = proposal.scenarios.some((scenario: Scenario) => {
            return scenario.accounts.some((account: Account) => {
                return account.investmentProgramId === InvestmentProgramType.DistributionFocusedStrategies;
            });
        });
    }

    private applyReportingLevelRollUpByAccount(proposal: Proposal): void {
        if (proposal && proposal.scenarios) {
            this.businessRulesOptions.reportingLevelRollUpByAccountOption =
                this.businessRulesOptions.ipsReportingLevelRollUpAllAccounts =
                proposal.scenarios.some((scenario: Scenario) =>
                    scenario.accounts.length > 1
                );

            const sectionGroup = this.presentationSettingService
                .getGroupProposalStatement(
                    this.proposalStatement,
                    ProposalSectionLabel.EstimatedCostOverview
                );
            const sectionReportingLevel: ProposalSection =
                this.presentationSettingService.getSectionFromGroup(sectionGroup, ProposalSectionLabel.ReportingLevel);

            if (sectionReportingLevel) {
                sectionReportingLevel.include = this.businessRulesOptions.reportingLevelRollUpByAccountOption;
                sectionReportingLevel.isToggleDisabled = !this.businessRulesOptions.reportingLevelRollUpByAccountOption;
            }
        }
    }

    /**
     *  Update Section "Show Advisor Fee" under "Cost Overview".
     *  Note: Cost Overview: Validate that the "Show Advisor Fee"Level is set to Yes if there is an advisor fee (more than 0%)
     *  associated to at least one account the “Show Advisor Fee” is checked in presentation
     */
    private applyFeeScheduleBusinessRules(): void {
        if (this.proposalStatement) {
            const subscription: Subscription =
                this.feeScheduleGroupService.getProposalFees(this.proposalId).subscribe((scenario: ScenarioAccountFee) => {
                    if (scenario && scenario.hudSummary) {
                        this.businessRulesOptions.showAdvisorFee =
                            scenario.hudSummary.estimatedAdvisorFee &&
                            scenario.hudSummary.estimatedAdvisorFee.rate &&
                            scenario.hudSummary.estimatedAdvisorFee.rate > 0;

                        const sectionGroup = this.presentationSettingService
                            .getGroupProposalStatement(
                                this.proposalStatement,
                                ProposalSectionLabel.EstimatedCostOverview
                            );
                        const sectionAdvisorFee: ProposalSection =
                            this.presentationSettingService.getSectionFromGroup(sectionGroup, ProposalSectionLabel.ShowAdvisorFee);

                        if (sectionAdvisorFee) {
                            sectionAdvisorFee.include = this.businessRulesOptions.showAdvisorFee;
                            sectionAdvisorFee.isToggleDisabled = !this.businessRulesOptions.showAdvisorFee;
                        }
                    }
                });
            this.subscriptions.push(subscription);
        }
    }

    private onSaveAdvisorContent(isRename: boolean) {
        this.saveAdvisorContent.emit(isRename);
    }

    private onDelete(event: string): void {
        this.onDeleteSection.emit(event);
    }

    private applyBusinessRules(primaryAdvisorForProposalId: number): void {
        this.subscriptions.push(this.userProfileRepository.read(primaryAdvisorForProposalId).subscribe((userProfile: UserProfile) => {
            this.userProfileService.updateCurrentUserProfileFirm(userProfile.firm);
            this.businessRulesOptions.HypotheticalUprLwrAssetClas = userProfile.processingRules.HypotheticalUprLwrAssetClas === 'Approved';
            this.businessRulesOptions.HypotheticalUprLwrCMA = userProfile.processingRules.HypotheticalUprLwrCMA === 'Approved';
            this.presentationSettingService._hypotheticalReturnBYDropDown.next(true);
            this.businessRulesOptions.DFSDrawdown = userProfile.processingRules.DFSDrawdown === 'Approved';
            this._DFSdrawdown.next(this.businessRulesOptions.DFSDrawdown);

            const sectionGroup = this.presentationSettingService.getGroupProposalStatement(
                this.proposalStatement,
                ProposalSectionLabel.ReportOptions
            );
            const sectionDfsDrawdownByAccount: ProposalSection =
                this.presentationSettingService.getSectionFromGroup(sectionGroup, ProposalSectionLabel.DFSDrawdownByAccount);

            const dfsBusinessRules: boolean = this.businessRulesOptions.showDfs && this.businessRulesOptions.DFSDrawdown;
            if (sectionDfsDrawdownByAccount) {
                sectionDfsDrawdownByAccount.hidden = !dfsBusinessRules;
                sectionDfsDrawdownByAccount.include = sectionDfsDrawdownByAccount.hidden ? false : sectionDfsDrawdownByAccount.include;
                sectionDfsDrawdownByAccount.isToggleDisabled = !this.businessRulesOptions.showDfs;
            }
        }));
    }

    private get getdfsDrawdown(): Observable<boolean> {
        return this._DFSdrawdown;
    }
}
