/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NgForm } from '@angular/forms';
import { AuthService } from '@CarAuth/auth.service';
import { LoggerService } from '@CarServices/system/logger.service';

@Component({
    selector: 'car-impersonation',
    templateUrl: './impersonation.component.html'
})

// temp component just to impersonate for developers.
// Will also provide information from AUTH and AUTH for troubleshooting.
// Will work into DevParameters later.
export class ImpersonationComponent implements OnInit {
    public siteminderId: string;
    public accountNumber: number;
    public authenticated = false;
    public returnUrl: string;
    public buttonText = 'Proceed';
    public invalidMessage = 'Please enter a valid siteminder id.';
    public errorDetail;
    public hasError = false;

    constructor(
        private readonly authService: AuthService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly carLogger: LoggerService
    ) { }

    ngOnInit() {
        this.returnUrl =
            this.route.snapshot.queryParams['returnUrl'] || '/Proposal/Dashboard';
        if (this.returnUrl !== '/Proposal/Dashboard') {
            this.buttonText = 'Return';
        }
    }

    onSubmit(form: NgForm) {
        if (!form.valid) {
            return;
        }

        this.siteminderId = this.siteminderId.trim();

        if (this.siteminderId && this.siteminderId.length > 0) {
            this.authService
                .getCarUserProfileStreaming(this.siteminderId)
                .subscribe(
                    () => {
                        this.hasError = false;
                        this.authenticated = this.authService.isAuthenticated;
                        this.routeToCar([this.returnUrl]);
                    },
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (error: any) => {
                        this.hasError = true;
                        this.invalidMessage = `Failed to generate CarUserProfile. Invalid siteminder id. Id doesn't exist in CAR.`;
                        this.errorDetail = error;
                    }
                );
        }
    }

    private routeToCar(route: String[]) {
        this.router.navigate(route);
    }

    public onEnableDevMode(evt) {
        this.carLogger.enableDevMode();
    }
}
