/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Proposal, ProposalService } from '@sei/advisor-client-review-proposal-ux';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { Observable } from 'rxjs';

@Component({
    selector: 'sei-car-edit-goal-container',
    templateUrl: './edit-goal-container.component.html'
})
export class EditGoalContainerComponent extends ParentComponentSubscriptionManager implements OnInit {

    public proposalId: number;
    public proposalStream: Observable<Proposal>;

    constructor(
        private activatedRoute: ActivatedRoute,
        private proposalService: ProposalService
    ) {
        super('EditGoalContainerComponent');
    }

    public ngOnInit(): void {
        this.proposalId = Number(this.activatedRoute.parent.snapshot.paramMap.get('proposalId'));
        this.proposalStream = this.proposalService.currentProposal;
    }
}
