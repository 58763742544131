/*
 Copyright: This information constitutes the exclusive property of SEI
 Investments Company, and constitutes the confidential and proprietary
 information of SEI Investments Company.  The information shall not be
 used or disclosed for any purpose without the written consent of SEI
 Investments Company.
*/

export class ErrorMessages {

    public static clientDisplayNameIsRequired(): string {
        return 'This field is required and cannot be blank/excluded';
    }
    public static clientDisplayInvalidTierAmount(): string {
        return 'This field have to be greater than initial amount';
    }
    public static fieldValueIsDuplicated(): string {
        return 'The value of this field is duplicated, please change it';
    }
    public static goalDisplayDuplicatedOrEmpty(): string {
        return 'This field is required and must not be duplicated';
    }
    public static clientFlatFeePercentage(): string {
        return 'Flat fee rate must be between 0 and 3%';
    }
    public static clientFlatFeeRateBps(): string {
        return 'Flat fee rate must be between 0 and 300';
    }
    public static customUmaNameMustChange(): string {
        return 'Custom UMA Model name must be unique';
    }
    public static investmentAmountGreaterThanZero(): string {
        return 'Investment amount must be greater than 0';
    }
}

export class HttpResponseUrl {
    public static pathsToExclude(): string[] {
        const uri: string[] = ['swp-branding-soa'];
        return uri;
    }
}

