/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { CurrencyTypes, WipAccountStatus } from '../../model/enums';
import { Proposal, WipCheckList } from '../../model/proposal';


@Component({
    selector: 'sei-car-wip-checklist-goals',
    templateUrl: './wip-checklist-goals.component.html',
    styleUrls: ['./wip-checklist-goals.component.scss']
})
export class WipChecklistGoalsComponent extends ParentComponentSubscriptionManager {

    @Input()
    public wipCheckListGoals: WipCheckList;
    @Input()
    public sectionType: string;
    @Input()
    public proposal: Proposal;

    public showGoalsDetail: boolean = false;
    public currencyTypes: typeof CurrencyTypes = CurrencyTypes;
    public goalStatus: typeof WipAccountStatus = WipAccountStatus;

    public constructor(private router: Router) {
        super('WipChecklistGoalsComponent');
    }

    public onShowDetailClick(): void {
        this.showGoalsDetail = !this.showGoalsDetail;
    }

    public navigate() {
        this.router.navigate([`/Proposal/WIP/${this.proposal.id}/Scenarios/${this.proposal.scenarios[0].id}/Goals`]);
    }

    public areGoalsPresent(): boolean {
        return this.wipCheckListGoals?.subCheckList?.length >= 1;
    }

}
