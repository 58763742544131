<ng-container *ngIf="statement.toggle">
    <ng-container (click)="activateSection(sectionGroup)"
                  *ngFor="let sectionGroup of statement.sections;let sectionIndex = index">
        <!-- Section Header -->
        <div class="checklist-list"
             [ngClass]="(sectionGroup.isActive)?'active':'normal'"
             [class.closed]="sectionGroup.isCollapsed"
             *ngIf="!sectionGroup.hidden"
             id="{{sectionIndex===0 ? 'checklist-proposal': 'checklist'+sectionIndex}}">
            <span *ngIf="sectionGroup.name !== proposalSectionLabelTypes.ReportingLevels" class="preview-span"
                (click)="onClickPreview(sectionGroup)">click to preview</span>
            <div class="checklist-list-title">
                <sei-car-settings-node [disableDragDrop]="true"
                                       [groupIndex]="sectionIndex"
                                       [group]="sectionGroup"></sei-car-settings-node>

                <!-- Section Content -->
                <div id="secion-group-{{sectionGroup.id}}"
                     *ngIf="!sectionGroup.isCollapsed">
                    <sei-car-cover-page *ngIf="sectionGroup.name === proposalSectionLabelTypes.CoverPage"
                                        [presentationType]="statement.statementType.description"
                                        [proposalSection]="sectionGroup"></sei-car-cover-page>
                    <sei-car-reporting-levels *ngIf="sectionGroup.name === proposalSectionLabelTypes.ReportingLevels"
                                              [businessRules]="businessRules"
                                              [proposalSection]="sectionGroup"></sei-car-reporting-levels>
                    <sei-car-report-options *ngIf="sectionGroup.name === proposalSectionLabelTypes.ReportOptions"
                                            [businessRules]="businessRules"
                                            [proposalSection]="sectionGroup"></sei-car-report-options>
                    <sei-car-analytics *ngIf="sectionGroup.name === proposalSectionLabelTypes.Analytics"
                                       [businessRules]="businessRules"
                                       [proposalSection]="sectionGroup"></sei-car-analytics>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>
