<section class="us-letter">
    <div class="accent-container">
        <div class="top-right-accent"
             [ngStyle]="{'background-color': themeColor}">
            <img *ngIf="coverImage && isCoverImageDataVisible" alt="cover-image"
                 [src]="coverImage">
            <div *ngIf="coverImage && isCoverImageDataVisible"
                 class="image-overlay"
                 [ngStyle]="{'background-color': themeColor}">
            </div>
            <div *ngIf="!coverImage || !isCoverImageDataVisible"
                 class="no-image-placeholder">
            </div>
        </div>
    </div>
    <div class="int-margins">
        <div class="cover-page-details">
            <div class="cover-title"
                 *ngIf="isCoverTitleDataVisible">
                {{coverTitleData}}
            </div>
            <div class="accent"
                 [ngStyle]="{'background-color': themeColor}"></div>
            <div class="cover-subtitle">
                <p *ngIf="showCoverSubtitle">
                    {{coverSubtitle}}
                </p>
            </div>
            <div class="client"
                 #client>
                {{clientName}}
            </div>
            <div class="date">
                <p>
                    {{dateNow | date :'longDate'}}
                </p>
            </div>
        </div>
    </div>
    <div class="bottom-accent-container">
        <div class="bottom-left-accent"
             [ngStyle]="{'border-left-color': themeColor, 'border-bottom-color': themeColor}">

        </div>
        <div class="firm-logo">
            <img *ngIf="previewImage" [src]="previewImage" alt="Brand Logo">
        </div>
            <div class="advisor-details">
                <p>
                    <span class="presented-by">PRESENTED BY<br></span>
                    <br>
                    <strong>
                    {{advisorName}}<br>
                    </strong>
                    <span>{{firmName}}</span><br>
                    {{advisorEmail}}<br>
                    {{advisorPhone}}
                </p>
            </div>
        </div>
</section>
