/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { CommonModule } from '@CarComponents/common/common.module';
import { RiskToleranceAlertModule } from '@CarComponents/risk-tolerance-alert/risk-tolerance-alert.module';
import { WipRiskToleranceModule } from '@CarComponents/risk-tolerance/wip-risk-tolerance.module';
import {
    WipAccountDetailsSummaryComponent
} from '@CarComponents/wip-account-details/wip-account-details-summary/wip-account-details-summary.component';
import { WipFundingDirectionsModule } from '@CarComponents/wip-funding-directions/wip-funding-directions.module';
import { WipFundingSourcesModule } from '@CarComponents/wip-funding-sources/wip-funding-sources.module';
import { WipProgressBarComponent } from '@CarComponents/wip-main/wip-progress-bar/wip-progress-bar.component';
import { WipOutsideStrategyModule } from '@CarComponents/wip-outside-strategy/wip-outside-strategy.module';
import { NgModule } from '@angular/core';
import { WipAccountGroupItemComponent } from './wip-account-group-item/wip-account-group-item.component';
import { WipDataCaptureStatusComponent } from './wip-data-capture-status/wip-data-capture-status.component';
import { WipDelegationItemComponent } from './wip-delegation-item/wip-delegation-item.component';
import { WipDelegationListComponent } from './wip-delegation-list/wip-delegation-list.component';
import { WIPGenericKeyValueSummaryComponent } from './wip-generic-key-value-summary/wip-generic-key-value-summary.component';
import { WIPMainComponent } from './wip-main/wip-main.component';
import {
    OptionalWipModalDetailPanelComponent
} from './wip-optional-modal/optional-wip-modal-detail/optional-wip-modal-detail-panel.component';
import { OptionalWipModalFooterComponent } from './wip-optional-modal/optional-wip-modal-footer/optional-wip-modal-footer.component';
import { OptionalWipModalItemPanelComponent } from './wip-optional-modal/optional-wip-modal-item/optional-wip-modal-item-panel.component';
import { OptionalWipModalPanelComponent } from './wip-optional-modal/optional-wip-modal-panel/optional-wip-modal-panel.component';
import { WIPSectionActionsComponent } from './wip-section-actions/wip-section-actions.component';
import { WIPSectionFooterComponent } from './wip-section-footer/wip-section-footer.component';
import { WIPSectionItemComponent } from './wip-section-item/wip-section-item.component';
import { WIPSectionListFooterComponent } from './wip-section-list-footer/wip-section-list-footer.component';
import { WIPSectionListComponent } from './wip-section-list/wip-section-list.component';
import { WIPStatusIndicatorComponent } from './wip-status-indicator/wip-status-indicator.component';

@NgModule({
    imports: [
        CommonModule,
        WipFundingSourcesModule,
        WipRiskToleranceModule,
        RiskToleranceAlertModule,
        WipFundingDirectionsModule,
        WipOutsideStrategyModule
    ],
    declarations: [
        WipDataCaptureStatusComponent,
        WIPMainComponent,
        WIPGenericKeyValueSummaryComponent,
        WIPSectionActionsComponent,
        WIPSectionFooterComponent,
        WIPSectionItemComponent,
        WIPSectionListComponent,
        WIPSectionListFooterComponent,
        WIPStatusIndicatorComponent,
        WipProgressBarComponent,
        // Optional Modal
        OptionalWipModalPanelComponent,
        OptionalWipModalDetailPanelComponent,
        OptionalWipModalItemPanelComponent,
        OptionalWipModalFooterComponent,
        WipAccountGroupItemComponent,
        WipDelegationListComponent,
        WipDelegationItemComponent,
        WipAccountDetailsSummaryComponent
    ],
    providers: [],
    exports: [
        WipDataCaptureStatusComponent,
        WIPMainComponent,
        WIPGenericKeyValueSummaryComponent,
        WIPSectionActionsComponent,
        WIPSectionFooterComponent,
        WIPSectionItemComponent,
        WIPSectionListComponent,
        WIPSectionListFooterComponent,
        WIPStatusIndicatorComponent,
        WipProgressBarComponent,
        // Optional Modal
        OptionalWipModalPanelComponent,
        OptionalWipModalDetailPanelComponent,
        OptionalWipModalItemPanelComponent,
        OptionalWipModalFooterComponent,
        WipAccountDetailsSummaryComponent
    ]
})
export class WipMainModule { }
