/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { GlobalService } from '@CarServices/system/global.service';
import { UserProfileService } from '@CarServices/system/user-profile.service';
import { Observable } from 'rxjs';
import { ApigeeTokenService } from '../auth/apigee-token.service';
import { AuthService } from '../auth/auth.service';
import { UrlRewriterInterceptor } from './url-rewriter.interceptor';

/**
 * Sets the siteminder header for all http
 * Keeping it hard coded to test user till siteminder issues
 * with CAR are worked out.
 *
 * Also need it hard coded to support elastic search since
 * test user is not in CAR
 */
@Injectable({ providedIn: 'root' })
export class SiteminderInterceptor implements HttpInterceptor {

    private siteminderUniversalIdHeader = 'sm_universalid';
    private siteminderBrandHeader = 'sei_brand';
    private defaultSiteminderBrandValue = 'advisor1';
    private siteMinderUserName: string = 'sm_user';

    constructor(
        private readonly global: GlobalService,
        private readonly userProfileService: UserProfileService,
        private readonly authService: AuthService,
        private readonly apigeeTokenService: ApigeeTokenService
    ) { }

    public intercept(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        req: HttpRequest<any>,
        next: HttpHandler
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Observable<HttpEvent<any>> {
        // Prevent invalid headers during OAuth JWT request and for KASSO plain keep alive requests
        if (this.apigeeTokenService.isOauthRequest(req) ||
            this.apigeeTokenService.ignoreHeaderSmUniversalId(req) ||
            UrlRewriterInterceptor.KEEP_ALIVE_IGNORABLE_PATHS.some((path) => req.url.includes(path))) {
            return next.handle(req);
        }

        // set sm_user
        if (
            this.userProfileService.profile &&
            this.userProfileService.profile.entityId
        ) {
            req = req.clone({
                headers: req.headers.set(
                    this.siteminderUniversalIdHeader,
                    this.userProfileService.profile.entityId.toString()
                )
            });

            // Note: need username for brandId proxy api
            // req = req.clone({
            //     headers: req.headers.set(
            //         this.siteMinderUserName,
            //         this.userProfileService.profile.userName
            //     )
            // });
        }

        // apigee is blocking sei_brand request header. THis is needed for brand code but not CAR API calls
        if (
            !this.global.configService.environment.carConfig.apigeeEnabled ||
            !req.url.includes(
                this.global.configService.apigeeConfiguration.server
            )
        ) {
            // set sei_brand
            if (this.authService.externalBrandId) {
                req = req.clone({
                    headers: req.headers.set(
                        this.siteminderBrandHeader,
                        this.authService.externalBrandId
                    )
                });
            } else {
                req = req.clone({
                    headers: req.headers.set(
                        this.siteminderBrandHeader,
                        this.defaultSiteminderBrandValue
                    )
                });
            }
        }
        return next.handle(req);
    }
}
