/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { AdvisorFee, FeeGroup, FeeSchedule, ScenarioFeeSchedule } from '../../model/account-fee';
import { FeeType } from '../../model/enums';
import { Type } from '../../model/proposal';
import { FeeScheduleGroupService } from '../../service/fee-schedule-group.service';

@Component({
    selector: 'sei-car-fee-schedule-fee-rate',
    templateUrl: './fee-schedule-fee-rate.component.html'
})
export class FeeScheduleFeeRateComponent extends ParentComponentSubscriptionManager implements OnInit {

    @Input()
    public advisorFee: AdvisorFee;
    @Input()
    public feeGroup: FeeGroup;
    @Input()
    public scenarioId: number;
    @Input()
    public totalAccountBalance: number;
    @Input()
    public scenarioFeeSchedules: ScenarioFeeSchedule[] = [];
    @Output()
    public onCancelEvent = new EventEmitter();
    @Output()
    public onSaveEvent: EventEmitter<AdvisorFee> = new EventEmitter();

    public feesTypes: Type[] = [
        { id: 1, description: 'Flat Percentage Fee' },
        { id: 2, description: 'Firm Fee Schedule' }
    ];

    public feeTypeId: number = 1;
    public showFlatFeeView: boolean = true;
    public feeSchedules: FeeSchedule[] = [];

    constructor(public feeScheduleGroupService: FeeScheduleGroupService, public activatedRoute: ActivatedRoute) {
        super('FeeScheduleFeeRateComponent');
    }

    public ngOnInit(): void {
        if (this.advisorFee.feeType) {
            this.feeTypeId = this.advisorFee.feeType;
            this.showFlatFeeView = this.feeTypeId === FeeType.FlatFee;
        }
    }

    public onFeeTypeChange(data): void {
        this.feeTypeId = +data.target.value;
        this.showFlatFeeView = (+data.target.value === 1);
    }
    public onCancelFlatFeeEvent(): void {
        this.onCancelEvent.emit();
    }
    public onDoneFeeRibbonEvent(event: AdvisorFee): void {
        const advisorFee = event;

        advisorFee.feeType = this.feesTypes.find((feeType) => feeType.id === this.feeTypeId).id;
        this.onSaveEvent.emit(advisorFee);
    }
}
