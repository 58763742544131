/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DisplayPhoneOptions, DropdownItem } from '@sei/common-components-lib-ux';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { OrderedSet } from 'immutable';
import * as _ from 'lodash';
import { forkJoin } from 'rxjs';
import { EntitySuffix } from '../../model/contact';
import { ContactTypes, ContactTypesDescription } from '../../model/enums';
import { Client } from '../../model/proposal';
import { ReferenceDataService } from '../../service/reference-data.service';

@Component({
    selector: 'car-proposal-account-owner',
    templateUrl: './proposal-account-owner.component.html',
    styleUrls: ['./proposal-account-owner.component.scss']
})
export class ProposalAccountOwnerComponent extends ParentComponentSubscriptionManager implements OnInit {
    @Input() public owner: Client;
    @Input() set contactTypeId(value: number) {
        this._contactTypeId = value;
        if (this.contactTypeId !== undefined) {
            this.contactTypeId === ContactTypes.Individual ? this.selectedContactTypeSet = OrderedSet([this.individualContactType]) :
                this.selectedContactTypeSet = OrderedSet([this.organizationContactType]);
            this.selectedContactTypeItem = this.selectedContactTypeSet.first();
            this.onContactTypeChanged(this.selectedContactTypeSet);
        }
    }
    get contactTypeId(): number {
        return this._contactTypeId;
    }
    @Output() public onClientChange: EventEmitter<Client> = new EventEmitter<Client>();
    public suffixes: EntitySuffix[];
    public isEmailValid: boolean = true;
    public isPhoneValid: boolean = true;
    public isNewClient: boolean = true;
    public seiDisplayPhoneOption: DisplayPhoneOptions;
    public contactTypes: OrderedSet<DropdownItem> = OrderedSet([]);
    public selectedContactTypeSet: OrderedSet<DropdownItem> = OrderedSet([]);
    public selectedContactTypeItem: DropdownItem;
    public suffixSet: OrderedSet<DropdownItem> = OrderedSet([]);
    public selectedSuffixSet: OrderedSet<DropdownItem> = OrderedSet([]);
    private individualContactType: DropdownItem = new DropdownItem(ContactTypes.Individual, ContactTypesDescription.Individual);
    private organizationContactType: DropdownItem = new DropdownItem(ContactTypes.Organization, ContactTypesDescription.Organization);
    private _contactTypeId: number;

    constructor(
        private readonly referenceDataService: ReferenceDataService
    ) {
        super('ProposalAccountOwnerComponent');
        this.contactTypes = OrderedSet([this.individualContactType, this.organizationContactType]);
    }

    public ngOnInit(): void {
        this.initializeComponent();
        this.seiDisplayPhoneOption = { country: 'US', format: 'NATIONAL' };
        this.isNewClient = !(this.owner.entityId > 0 || this.owner.id) || this.owner?.isNewClient === true;
        if (!this.isNewClient) {
            this.owner.isOptionalFieldsValid = true;
            this.selectedSuffixSet =
                OrderedSet([new DropdownItem(this.owner?.entitySuffix?.suffixId, this.owner?.entitySuffix?.suffixName)]);
            this.owner.contactType.id === ContactTypes.Individual ? this.selectedContactTypeSet = OrderedSet([this.individualContactType]) :
                this.selectedContactTypeSet = OrderedSet([this.organizationContactType]);
            this.selectedContactTypeItem = this.selectedContactTypeSet.first();
            this.onContactTypeChanged(this.selectedContactTypeSet);
        }
        if (!this.selectedContactTypeSet || this.selectedContactTypeSet.size < 1) {
            this.selectedContactTypeSet = OrderedSet([this.individualContactType]);
            this.selectedContactTypeItem = this.individualContactType;
            this.onContactTypeChanged(this.selectedContactTypeSet);
        }
    }

    private initializeComponent(): void {
        const suffixData = this.referenceDataService.getData('suffixes');
        forkJoin([suffixData])
            .subscribe(
                (results) => {
                    if (results) {
                        this.suffixes = results[0]['suffixes'];
                        this.suffixes = this.suffixes.sort((a, b) => a.sortOrder - b.sortOrder);
                        this.suffixes.forEach((suffix: EntitySuffix) => {
                            const dropdownItem: DropdownItem = new DropdownItem(suffix.suffixId, suffix.suffixName);
                            this.suffixSet = OrderedSet([...this.suffixSet.toArray(), dropdownItem]);
                        });
                    }
                }
            );

    }

    public onOwnerFirstNameChange(firstName: string): void {
        this.owner.firstName = firstName;
        this.emitClient();
    }

    public onMiddleInitialChange(middleInitial: string): void {
        this.owner.middleInitial = middleInitial;
        this.emitClient();
    }

    public onOwnerLastNameChange(lastName: string): void {
        this.owner.lastName = lastName;
        this.emitClient();
    }

    public onOrganizationNameChange(organizationName: string): void {
        const trimmedName: string = organizationName.trim();
        this.owner.lastName = this.owner.labelName = this.owner.organizationName = trimmedName;
        this.emitClient();
    }

    public onSuffixChange(selectedSuffixSet: OrderedSet<DropdownItem>): void {
        const suffixItem: DropdownItem = selectedSuffixSet.first();
        this.owner.entitySuffix = this.suffixes.find((suffixData: EntitySuffix) => suffixData.suffixId === suffixItem.id);
        this.emitClient();
    }

    public onEmailChange(emailAddress: string): void {
        this.owner.emailAddress = emailAddress;
        this.emitClient();
    }

    public checkEmailValid(isValid: boolean): void {
        this.isEmailValid = isValid;
        this.emitClient();
    }

    public onPhoneChange(phoneNumber: string): void {
        this.owner.phoneNumber = phoneNumber;
        this.emitClient();
    }

    public checkPhoneValid(isValid: boolean): void {
        this.isPhoneValid = isValid;
        this.emitClient();
    }

    public onContactTypeChanged(contactType: OrderedSet<DropdownItem>): void {
        this.selectedContactTypeSet = contactType;
        const contactTypeItem = this.selectedContactTypeSet.first();
        this.selectedContactTypeItem = _.cloneDeep(contactTypeItem);
        this.owner.isIndividual = contactTypeItem.id === ContactTypes.Individual;
        if (!this.owner.isIndividual) {
            this.owner.firstName = '';
        }

        if (contactTypeItem.id !== this.owner.contactType.id) {
            this.owner.contactType =
                { id: Number(contactTypeItem.id), description: contactTypeItem.name };
            this.owner.isNewClient = true;
            this.resetFields();
            this.emitClient();
        }
    }

    private validateOptionalFields() {
        this.owner.isOptionalFieldsValid =
            (this.owner.emailAddress ? this.isEmailValid : true) &&
            (this.owner.phoneNumber ? this.isPhoneValid : true);
    }

    private emitClient(): void {
        this.validateOptionalFields();
        this.onClientChange.emit(this.owner);
    }

    private resetFields(): void {
        this.owner.emailAddress = '';
        this.owner.phoneNumber = '';
        this.owner.firstName = '';
        this.owner.middleInitial = '';
        this.owner.lastName = '';
        this.owner.organizationName = '';
        this.owner.labelName = '';
        this.owner.entitySuffix = undefined;
    }

}
