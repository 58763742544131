/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'highlight'
})

export class HighlightSearchTextPipe implements PipeTransform {
    private highlightMethod: string = 'b';

    transform(value: string, args: string): string {
        if (args && value) {
            args = args.trim();
            const startIndex = value.toLowerCase().indexOf(args.toLowerCase());
            if (startIndex !== -1) {
                const matchingString = value.substr(
                    startIndex,
                    args.length
                );
                return value.replace(matchingString,
                    '<' + this.highlightMethod + '>'
                    + matchingString +
                    '</' + this.highlightMethod + '>'
                );
            }

        }
        return value;
    }
}
