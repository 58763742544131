/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { ModuleWithProviders, NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { EffectsModule } from '@ngrx/effects';
import * as fromAccountReducer from './account-slice.reducers';
import { AccountEffects } from './account.effects';

@NgModule({
    imports: [
        StoreModule.forFeature(
            fromAccountReducer.carAccountModuleSliceName,
            fromAccountReducer.AccountSlice
        ),
        EffectsModule.forFeature([AccountEffects])
    ]
})
export class AccountStoreModule {
    static forRoot(): ModuleWithProviders<AccountStoreModule> {
        return {
            ngModule: AccountStoreModule
        };
    }
}
