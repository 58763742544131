<div class="fee-group-ribbon">
    <div class="strategy-ribbon section-gap">
        <div class="row">
            <div class="col-sm-6">
                <sei-button buttonText="Cancel" buttonLeftFontAwesomeIcon="fas fa-times-circle"
                    buttonClass="btn-default" (buttonClicked)="onCancel()">
                </sei-button>
            </div>
            <div class="col-sm-6 text-right">
                <sei-button buttonText="Done" buttonRightFontAwesomeIcon="fas fa-arrow-alt-circle-right"
                    buttonClass="btn-default" [buttonDisabled]="isReadUser" (buttonClicked)="onDone()">
                </sei-button>
            </div>
        </div>
    </div>
</div>