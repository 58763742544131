/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { KeyValue } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT } from '@CarModels/constants';
import * as _ from 'lodash';
import { endPointBe2Routes } from 'projects/advisor-client-review-proposal-ux-app/src/environments/end-point-be2.routes';
import { EndPoint } from '../../model/http-types';
import { ApigeeConfig } from '../../model/oauth';
import { PropertyService } from './property.service';

/* eslint-disable @typescript-eslint/no-explicit-any */
@Injectable({
    providedIn: 'root'
})
export class ConfigurationService {

    constructor(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        @Inject(ENVIRONMENT) private readonly environmentValue: any,
        private readonly propertyService: PropertyService
    ) {
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public get environment(): any {
        return this.environmentValue;
    }

    private get replacePrefix(): string {
        return '{';
    }
    private get replaceSuffix(): string {
        return '}';
    }
    private get replaceApigeeVersionKey(): string {
        return '{apiVersion}';
    }

    public get apigeeConfiguration(): ApigeeConfig {
        if (
            !this.propertyService.allExist([
                () => this.environment.apigeeConfig,
                () => this.environment.carConfig.apigeeEnvironmentKey
            ])
        ) {
            throw new Error(
                `No apigee configuration given and/or key provided in apigeeEnvironmentKey`
            );
        }

        return this.environment.apigeeConfig[
            this.environment.carConfig.apigeeEnvironmentKey
        ];
    }

    // Gets an Endpoint value based on the EndPoints key
    public getEndPoint(key: string): string {
        key = _.camelCase(key);

        if (!this.environment) {
            return;
        }

        const endPoint: EndPoint = endPointBe2Routes[key];

        if (!endPoint) {
            throw new Error(`Endpoint object does not exists for key: ${key}`);
        }

        return this.environment.carConfig.apigeeEnabled
            ? this.apigeeEndpointFormatter(endPoint)
            : endPoint.url;
    }

    // Gets an Option value based on the Options Key
    public getOption(key: string): string | undefined {
        key = _.camelCase(key);

        // To support property moves with out going back through code.
        // Mock Data Services will address this
        const valueObj = this.environment.carConfig[key]
            ? this.environment.carConfig[key]
            : this.environment[key];

        return valueObj ? valueObj : undefined;
    }

    public routeFormatter(
        route: string,
        options?:
            | KeyValue<string, string | number>
            | Array<KeyValue<string, string | number>>
    ): string {
        if (!route) {
            throw new Error('No route key provided. Route is undefined.');
        }

        if (options) {
            if (!Array.isArray(options)) {
                const replaceKey =
                    this.replacePrefix + options.key + this.replaceSuffix;
                route = route.replace(replaceKey, options.value.toString());
            } else {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                options.forEach((item: any) => {
                    const replaceKey =
                        this.replacePrefix + item.key + this.replaceSuffix;
                    route = route.replace(replaceKey, item.value);
                });
            }
        }

        return route;
    }

    public environmentRouteRewriter(
        route: string,
        config: {
            rewriteKey: string;
            transportProtocol: string;
            server: string;
            serverPort?: string;
        }
    ) {
        return route.replace(
            config.rewriteKey,
            `${config.transportProtocol}://${config.server}${config.serverPort ? ':' + config.serverPort : ''
            }`
        );
    }

    public apigeeEndpointFormatter(route: EndPoint): string {
        if (this.environment.carConfig.apigeeEnabled && !route.groupName) {
            throw new Error('When Apigee is enabled, group name is required.');
        }

        // need to add rewriteKey back in to URL so interceptor triggers
        return `${this.environment.carConfig.rewriteKey
            }${this.apigeeConfiguration.urlPrefix.replace(
                this.replaceApigeeVersionKey,
                route.apiVersion
            )}/${route.url.replace(
                this.environment.carConfig.rewriteKey,
                route.groupName
            )}`;
    }
}
