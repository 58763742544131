/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';

import { AdditionalWipSection } from '@CarInterfaces/account';
import { Pagination } from '@CarInterfaces/pagination';
import { WipSection } from '@CarInterfaces/wip-section';
import { wipOptionalSectionArrayMock, wipSectionArrayMock, wipSectionOrderArrayMock } from '@CarMock/wip-section.mock';
import { InvestmentProgramType } from '@CarModels/enums';
import { WipSectionSettings } from '@CarModels/investment-type-options';
import { AccountTypeOptionsService } from '@CarServices/system/account-type-options.service';
import { InvestmentTypeOptionsService } from '@CarServices/system/investment-type-options.service';
import * as _ from 'lodash';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WipChangeState, WipSections } from './wip-enums';

@Injectable({
    providedIn: 'root'
})
export class WipSectionService {
    // setting to support unit testing.
    private sections: WipSection[] = wipSectionArrayMock;

    public readonly updatedSectionStream = new BehaviorSubject<WipSections>(null);

    private dataStream = new BehaviorSubject<WipSection[]>(this.sections);

    private selectedSectionStream: BehaviorSubject<WipSection>;

    public pagination = new BehaviorSubject<Pagination>({
        currentScreen: 1,
        totalScreens: 1
    });

    constructor(
        private readonly router: Router,
        private readonly accountTypeOptionsService: AccountTypeOptionsService,
        private readonly investmentTypeOptionsService: InvestmentTypeOptionsService
    ) {}

    /**
     * Set up WIP Sections from the account Object.
     * @param initialWipSections WipSection array to set the WIP up too based on account type.
     */
    public initializeSections(initialWipSections?: WipSection[]) {
        if (!initialWipSections) {
            // if undefined then set mock. We need to make sure
            // We have an array incase something broke with wip sections
            this.sections = wipSectionArrayMock;
        } else {
            this.sections = initialWipSections;
        }

        this.configureIndividualObjects(this.sections);
        this.dataStream.next(this.sections);
    }

    /**
     * Adds recommended sections to the WIP if an account type is configured for such
     * @param accountTypeId Account type id to test
     */
    public initializeRecommendedSections(accountTypeId: number) {
        // 1. Get the option object for the account type if exists
        const accountTypeOption = this.accountTypeOptionsService.getOptions(accountTypeId);
        // 2. if we have an option object, add optional wip sections to the end
        if (accountTypeOption) {
            accountTypeOption.additionalWipSections
                .filter(
                    (additionalSection: AdditionalWipSection) =>
                        WipChangeState.Recommended === additionalSection.changeState
                )
                .forEach((recommendedSection: AdditionalWipSection) => {
                    let optionalWipSection = wipOptionalSectionArrayMock.find(
                        (optionalSection: WipSection) => optionalSection.id === recommendedSection.id
                    );

                    // 3.a we should have a match, but protect if we don't
                    if (optionalWipSection) {
                        // 4. override any option properties needed
                        optionalWipSection = {
                            ...optionalWipSection,
                            ...recommendedSection
                        };

                        // 5. add to wip section and update observable
                        optionalWipSection.visible = true;
                        this.sections.push(optionalWipSection);
                        this.dataStream.next(this.sections);
                    }
                });
        }
    }

    public initializeRequiredSections(investmentTypeId: number) {
        // 1. Get the option object for the investment type if exists
        const investmentTypeOption = this.investmentTypeOptionsService.getOptions(investmentTypeId);

        // 2. if we have an option object, add required wip sections after the last required section
        if (investmentTypeOption) {
            investmentTypeOption.additionalWipSections
                .filter((additionalSection: AdditionalWipSection) => additionalSection.required)
                .forEach((additionalSection: AdditionalWipSection) => {
                    // Validate if the section was not added before
                    if (
                        !this.sections.find((wipSection: WipSection) => wipSection.sectionId === additionalSection.id)
                    ) {
                        let optionalWipSection = wipOptionalSectionArrayMock.find(
                            (optionalSection: WipSection) => optionalSection.id === additionalSection.id
                        );

                        // 3.a we should have a match, but protect if we don't
                        if (optionalWipSection) {
                            // 4. override any option properties needed
                            optionalWipSection = {
                                ...optionalWipSection,
                                ...additionalSection
                            };

                            // 5. add to wip section after last required section and update observable
                            const lastRequiredIndex = _.findLastIndex(this.sections, (section: WipSection) => {
                                return section.required;
                            });

                            optionalWipSection.visible = true;
                            this.sections.splice(lastRequiredIndex + 1, 0, optionalWipSection);

                            // optionalWipSection.visible = true;
                            // this.sections.push(optionalWipSection);
                            this.dataStream.next(this.sections);
                        }
                    }
                });
        }
    }

    public updateRelatedPartyRoute(accountTypeId: number) {
        const RELATED_PARTY_ROUTE = 'RelatedParty';
        const relatedPartyIndex = this.sections.findIndex(
            (section: WipSection) => section.route === RELATED_PARTY_ROUTE
        );

        if (relatedPartyIndex >= 0) {
            this.sections[relatedPartyIndex].route = 'RelatedParty/' + accountTypeId;
        }
    }

    public getSelectedSectionStreaming(): Observable<WipSection> {
        if (!this.selectedSectionStream) {
            return this.getSelectedSectionByRouteStreaming();
        }
        return this.selectedSectionStream;
    }

    public getSelectedSectionByRouteStreaming(): Observable<WipSection> {
        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                return this.dataStream.pipe(
                    map((sections: WipSection[]) => {
                        return sections.find(
                            (section: WipSection) =>
                                section.route === event.url.substring(event.url.lastIndexOf('/') + 1, event.url.length)
                        );
                    })
                );
            }
        });

        return EMPTY;
    }

    /**
     * TODO MVP2 => Remove map function to make Benefits section visible
     */
    public getSectionsStreaming(investmentProgramId: number = 0): Observable<WipSection[]> {
        const description = 'This investment program only allows allocation to the strategy.';
        return this.dataStream.pipe(
            map((sections: WipSection[]) => {
                const fundingDirection = this.findSection(WipSections.FundingDirection);
                if (
                    fundingDirection &&
                    investmentProgramId &&
                    investmentProgramId === InvestmentProgramType.DistributionFocusedStrategies
                ) {
                    fundingDirection.sectionDescription = description;
                    fundingDirection.description = description;
                }
                return sections.filter((section: WipSection) => section.id !== WipSections.DeathBeneficiary);
            })
        );
    }

    public setSelectedSection(section: WipSection) {
        if (!this.selectedSectionStream) {
            this.selectedSectionStream = new BehaviorSubject<WipSection>(section);
        }
        this.selectedSectionStream.next(section);
    }

    public setSelectedSectionById(id: number) {
        this.setSelectedSection(this.findSection(id));
    }

    public setSectionDisabled(section: WipSection, disabled: boolean) {
        section.disabled = disabled;
        this.updateSections(section);
    }

    public setSectionInProgress(section: WipSection) {
        section.completed = false;
        section.changeState = WipChangeState.Started;
        this.updateSections(section);
    }

    public setSectionDefer(section: WipSection) {
        section.changeState = WipChangeState.Defer;
        this.updateSections(section);
    }

    public setSectionIncomplete(section: WipSection) {
        section.changeState = WipChangeState.Incomplete;
        section.completed = false;
        this.updateSections(section);
    }

    public setSectionCompleted(section: WipSection, completed: boolean) {
        section.changeState = completed ? WipChangeState.Saved : WipChangeState.Started;
        section.completed = completed;
        this.updateSections(section);
    }

    public updateSections(section: WipSection) {
        this.updatedSectionStream.next(section.sectionId);

        this.dataStream.next(this.sections);
    }

    public findSection(id: number): WipSection {
        let wipSection = this.sections.find((item: WipSection) => item.id === id);

        // might be a child section
        // perform deep find
        if (!wipSection) {
            for (const item of this.sections) {
                wipSection = item.sections ? item.sections.find((child: WipSection) => child.id === id) : undefined;

                if (wipSection) {
                    break;
                }
            }
        }

        return wipSection;
    }

    public findSectionByRoute(route: string): WipSection {
        let wipSection = this.sections.find((item: WipSection) => item.route === route);

        // might be a child section
        // perform deep find
        if (!wipSection) {
            for (const item of this.sections) {
                wipSection = item.sections
                    ? item.sections.find((child: WipSection) => child.route === route)
                    : undefined;

                if (wipSection) {
                    break;
                }
            }
        }

        return wipSection;
    }

    public addSections(newSections: WipSection[]) {
        const sectionsToAdd = newSections.filter(
            (newSection: WipSection) =>
                this.sections.filter((section: WipSection) => section.id === newSection.id).length === 0
        );

        sectionsToAdd.forEach((section: WipSection) => (section.visible = true));
        this.sections.push(...sectionsToAdd);
        this.dataStream.next(this.sections);
    }

    public removeSection(section: WipSection) {
        let sectionIndex = this.sections.findIndex((item: WipSection) => item.id === section.id);
        if (sectionIndex !== -1) {
            this.sections.splice(sectionIndex, 1);
            this.dataStream.next(this.sections);
            return;
        }

        // might be a child section
        // perform deep find
        for (const item of this.sections) {
            if (item.sections) {
                sectionIndex = item.sections.findIndex((i: WipSection) => i.id === section.id);
                if (sectionIndex !== -1) {
                    item.sections.splice(sectionIndex, 1);
                    this.dataStream.next(this.sections);
                    return;
                }
            }
        }
    }

    public sortSections(sections: WipSection[]): WipSection[] {
        sections.forEach((wipSection: WipSection) => {
            const wipSectionSetting = wipSectionOrderArrayMock.find(
                (sectionSetting: WipSectionSettings) => sectionSetting.sectionId === wipSection.sectionId
            );

            if (wipSectionSetting) {
                wipSection.sortOrder = wipSectionSetting.sortOrder;
            }

            if (wipSection.sections) {
                // Recursive child sections
                wipSection.sections = this.sortSections(wipSection.sections);
            }

            if (wipSection.visible === undefined) {
                wipSection.visible = true;
            }
        });

        return _.sortBy(sections, [
            'sortOrder'
        ]);
    }

    /**
     * Handle specific mappings that are needed
     * To assist REST and reduce refactor, map section id to id and sectionDescription to description
     * Supports infinite recursive for parent sections
     * @param sections sections array o update.
     */
    private configureIndividualObjects(sections: WipSection[]) {
        if (sections) {
            sections.forEach((item: WipSection) => {
                // Configure child sections
                if (item.sections && item.sections.length > 0) {
                    this.configureIndividualObjects(item.sections);
                }
                item.id = item.sectionId;
                item.description = item.sectionDescription;

                // Set visible as true by default when the
                // property has not been initialized.
                if (item.visible === undefined) {
                    item.visible = true;
                }

                // Since we are in the WIP, make sure Overview is completed.
                // REST is having difficulties making this a default.

                if (item.id === WipSections.AccountOverview) {
                    item.completed = true;
                }
            });
        }
    }
}
