/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { ContactType, PartyType, RelatedPartyDetail } from '@CarInterfaces/account';
import { BaseFactory } from '@CarInterfaces/base-factory';
import { ContactFactory } from './contact-factory';

@Injectable({
    providedIn: 'root'
})
export class RelatedPartyFactory implements BaseFactory<RelatedPartyDetail> {
    public createObject(type?: PartyType): RelatedPartyDetail {
        const contactType: ContactType = {
            contactTypeId: type.contactTypeId,
            contactTypeDescription: type.contactTypeName
        };

        return {
            partyType: {
                partyTypeId: type.partyTypeId,
                partyTypeName: type.partyTypeName
            },
            contact: new ContactFactory().createObject(contactType),
            accountPartyId: -1
        };
    }
}
