/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@CarComponents/common/common.module';
import { AccountOwnerSelectorComponent } from './account-owner-selector/account-owner-selector.component';
import { OwnerContactInfoComponent } from './owner-contact-info/owner-contact-info.component';
import { OwnerDetailComponent } from './owner-detail/owner-detail.component';
import { OwnerLegalAddressComponent } from './owner-legal-address/owner-legal-address.component';
import { OwnersAdditionalAddressComponent } from './owners-additional-address/owners-additional-address.component';
import { OwnersDeliveryPreferencesComponent } from './owners-delivery-preferences/owners-delivery-preferences.component';
import { OwnersFileUploadComponent } from './owners-file-upload/owners-file-upload.component';
import { OwnersHeaderComponent } from './owners-header/owners-header.component';
import { OwnersRegulatoryInformationComponent } from './owners-regulatory-information/owners-regulatory-information.component';
import { WIPAccountOwnersComponent } from './wip-account-owners-main/wip-account-owners-main.component';

@NgModule({
    imports: [CommonModule],
    declarations: [
        AccountOwnerSelectorComponent,
        OwnerDetailComponent,
        OwnerLegalAddressComponent,
        OwnersAdditionalAddressComponent,
        OwnersDeliveryPreferencesComponent,
        OwnersRegulatoryInformationComponent,
        OwnersHeaderComponent,
        WIPAccountOwnersComponent,
        OwnersFileUploadComponent,
        OwnerContactInfoComponent
    ],
    providers: [],
    exports: [
        AccountOwnerSelectorComponent,
        OwnerDetailComponent,
        OwnerLegalAddressComponent,
        OwnersAdditionalAddressComponent,
        OwnersDeliveryPreferencesComponent,
        OwnersRegulatoryInformationComponent,
        OwnersHeaderComponent,
        WIPAccountOwnersComponent
    ]
})
export class WipAccountOwnerModule {}
