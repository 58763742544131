/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'sei-car-inline-edit-component',
    templateUrl: './inline-edit.component.html'
})
export class InlineEditComponent {
    @Input()
    public inputText;

    @Input()
    public showDeleteButton: boolean = false;

    @Output()
    public editionConfirmed = new EventEmitter();

    public onRemoveNameClick() {
        this.inputText = '';
    }

    public onEditConfirmClick() {
        if (this.inputText.length > 0) {
            this.editionConfirmed.emit(this.inputText);
        }
    }
}
