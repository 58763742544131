/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';

function _window(): Window {
    // return the global native browser window object
    return window;
}

@Injectable({
    providedIn: 'root'
})
export class WindowRef {
    get nativeWindow(): Window {
        return _window();
    }
}
