<div>
    <sei-modal-window #warningModal
                      footerEnable="true"
                      isDismissible="false"
                      [isBackdrop]="true"
                      [modalTitle]="modalTitle">
        <div class="modal-body">
            <h2 *ngIf="modalHeader">{{modalHeader}}</h2>
            <p>{{message}}</p>
        </div>
        <footer class="modalbox-footer">
            <sei-button class="pull-left"
                        buttonText="Cancel"
                        buttonClass="btn-default"
                        (buttonClicked)="onCancelClicked()"></sei-button>
            <sei-button buttonText="Continue"
                        buttonClass="btn-primary"
                        (buttonClicked)="onContinueClicked()"></sei-button>
        </footer>
    </sei-modal-window>
</div>
