/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { ToggleButton } from './toggle-button';

@Component({
    selector: 'car-toggle-button',
    templateUrl: './toggle-button.component.html',
    styleUrls: ['./toggle-button.component.scss']
})
export class ToggleButtonComponent<T> implements OnInit {
    public selectedOption?: T;

    @Input()
    public disabled = false;

    @Input()
    public toggleOptions: Array<ToggleButton<T>>;

    @Input()
    public selectedValue?: T;

    @Output()
    public onChange = new EventEmitter();

    ngOnInit() {
        this.selectedOption = this.selectedValue;
    }

    public clickToggleOption(event) {
        if (this.selectedOption !== event) {
            this.selectedOption = event;
            this.onChange.emit(this.selectedOption);
        }
    }

    public isEqual(option): boolean {
        this.selectedOption = this.selectedValue;
        return _.isEqual(this.selectedOption, option);
    }
}
