<h6 for="strategy-dropdown"
    [hidden]="isLabelHidden">{{labelStrategyDropDown}}</h6>
<select id="strategy-dropdown"
        class="form-control"
        [ngModel]="setDefaultDropDownValue"
        (change)="onChangeStrategyDropDown($event)">
    <option *ngFor="let strategyFilterInput of strategyDropDownInputData"
            [value]="strategyFilterInput.id">{{strategyFilterInput.description}}
    </option>
</select>
