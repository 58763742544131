<div class="wip-summary tax-padding">
    <div class="wip-summary-row sectionhead wip-header-row wip-checklist-section-header wip-checklist-section-header-bg-gray"
         [class.wip-checklist-section-header-collapsed]="isSectionHidden">
        <div class="header-contents">
            <div class="wip-summary-left subhead">
                <h4>{{ existingAccountLabel }}</h4>
                <span *ngIf="!isAccountsCallInProgress && !resolverLoading" class="wip-checklist-section-header-balance">
                <h4>{{ existingAccountsTotal | currencySuffix }}</h4>
            </span>
                <span *ngIf="isAccountsCallInProgress" class="portfolio-loading wip-checklist-section-header-balance">
                <sei-loading multiplier="1" [showLoadingIcon]="true"></sei-loading>
            </span>
            </div>
            <div class="wip-summary-right header-buttons">
                <sei-button buttonText="Edit"
                            buttonClass="btn-link"
                            [buttonDisabled]="isAccountsCallInProgress || this.accountList?.length === 0 || resolverLoading"
                            (buttonClicked)="navigateToExistingAccounts()"></sei-button><span>|</span>
                <a class="section-arrow" aria-label="Expand Existing Accounts Section" (click)="onShowSectionDetailsClick()">
                            <i class="fas" [class.fa-chevron-right]="isSectionHidden" [class.fa-chevron-down]="!isSectionHidden"></i></a>
            </div>
        </div>
        <div class="as-of-date"
             *ngIf="!isAccountsCallInProgress && !resolverLoading && this.accountList?.length > 0">
            <p>Values are as of {{dateTimeForPipe | seiDateTime: format }}</p>
        </div>
    </div>
</div>

<div *ngIf="!isSectionHidden">
    <sei-car-wip-checklist-loading-spinner *ngIf="isAccountsCallInProgress || resolverLoading"></sei-car-wip-checklist-loading-spinner>
    <div *ngIf="!isAccountsCallInProgress && accountList?.length === 0 && !resolverLoading">
        <sei-alert><span>The selected client(s) are not the primary owner on any accounts.</span></sei-alert>
    </div>
    <div *ngIf="!isAccountsCallInProgress && !resolverLoading"
         class="wip-section-background-green wip-section-border-rounded">
        <div *ngFor="let account of visibleAccountList; let i = index; let isLast = last"
             id="existing-account-{{i}}">
            <div [ngClass]="proposalAccountService.isAccountIncomplete(getMdcSnapshot(i)) ? 'wip-section-background-gray' : 'wip-section-background-green'"
                 [class.wip-section-border-rounded]="isLast && VISIBLE_ACCOUNT_INCREMENT > this.accountList?.length"
                 class="wip-section-border wip-section-padding-bottom">
                <div class="wip-summary-row head">
                    <div class="wip-summary-left">
                        <p class="indent-overflow account-text">
                            <car-wip-checklist-status-indicator [mdcSnapShot]="getMdcSnapshot(i)"
                                                                [indentContent]="true"></car-wip-checklist-status-indicator>{{account.accountName}}
                        </p>
                    </div>
                    <div class="wip-summary-right">
                        <sei-button buttonText="Modify Investments"
                                    buttonClass="sei-btn-link-padding-none"
                                    (buttonClicked)="onModifyInvestmentsClicked(i)"></sei-button>
                    </div>
                </div>
                <div class="wip-summary-row account-body-row"
                     id="marketValueSection">
                    <div class="wip-summary-left indent-left">
                        <div class="col-xs-6">
                            <span>Account Market Value</span>
                        </div>
                        <div class="col-xs-6">
                            <span>{{ {value: getAccountMarketValue(account), actualCurrency: 'USD' } | seiDisplayMonetaryAmount:amountOptions:roundFractionalNumber:displayNumericScaleSuffix}}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="wip-summary-row account-body-row"
                     id="newFundsValueSection"
                     *ngIf="isCurrentAccountInProposal(account)">
                    <div class="wip-summary-left indent-left">
                        <div class="col-xs-6">
                            <span>{{ removeFundsFromExistingAccount ? 'Removed Funds' : 'Additional Funds' }}</span>
                        </div>
                        <div class="col-xs-6">
                            <span>{{ {value: getNewFunds(account) , actualCurrency: 'USD' } | seiDisplayMonetaryAmount:amountOptions:roundFractionalNumber:displayNumericScaleSuffix}}</span>
                        </div>
                    </div>
                </div>
                <div class="wip-summary-row account-body-row"
                     id="proposedAmountSection"
                     *ngIf="isCurrentAccountInProposal(account)">
                    <div class="wip-summary-left indent-left">
                        <div class="col-xs-6">
                            <span>Total Proposed Amount</span>
                        </div>
                        <div class="col-xs-6">
                            <span>{{ {value: getTotalProposedAmount(account) , actualCurrency: 'USD' } | seiDisplayMonetaryAmount:amountOptions:roundFractionalNumber:displayNumericScaleSuffix}}</span>
                        </div>
                    </div>
                </div>
                <div class="wip-summary-row pageheader account-body-row">
                    <div class="col-xs-12 indent-left">
                        <sei-pageheader [headingLevel]="5"
                                        [pageHeaderProperties]="modelPageHeaderProperties">
                            <div extra-content>
                                <span class="market-value-header">{{ {value: isCurrentAccountInProposal(account) ? getModelInvestmentValue(account) : account.modelMarketValue, actualCurrency: 'USD' } | seiDisplayMonetaryAmount:amountOptions:roundFractionalNumber:displayNumericScaleSuffix}}</span>
                                <a class="section-arrow" [class.open]="account.showModelInvestmentOnWIP" aria-label="Expand Investment Details Section" (click)="toggleShowModelInvestmentDetails(account)">
                                    <i class="fas" [class.fa-chevron-right]="!account.showModelInvestmentOnWIP" [class.fa-chevron-down]="account.showModelInvestmentOnWIP"></i>
                                </a>
                            </div>
                        </sei-pageheader>
                    </div>
                </div>
                <div *ngIf="account.showModelInvestmentOnWIP">
                    <div *ngIf="!isCurrentAccountInProposal(account)">
                        <div class="wip-summary-row account-body-row"
                             *ngFor="let model of account.modelInvestments">
                            <div class="indent-left investment-row">
                                <div class="col-xs-6">
                                    <span>{{ model.modelName }}</span>
                                </div>
                                <div class="col-xs-6">
                                    <span class="pull-right">{{ {value: model.amount, actualCurrency: 'USD' } | seiDisplayMonetaryAmount:amountOptions:roundFractionalNumber:displayNumericScaleSuffix}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isCurrentAccountInProposal(account)">
                        <div class="wip-summary-row account-body-row"
                             *ngFor="let model of getCurrentAccountInProposal(account)?.strategies">
                            <div class="indent-left investment-row">
                                <div class="col-xs-6">
                                    <span>{{ model.strategyName }}</span>
                                </div>
                                <div class="col-xs-6">
                                    <span class="pull-right">{{ {value: model.amount, actualCurrency: 'USD' } | seiDisplayMonetaryAmount:amountOptions:roundFractionalNumber:displayNumericScaleSuffix}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!account.showModelInvestmentOnWIP && account.modelInvestments.length > 0"
                     class="wip-summary-row account-body-row">
                    <div class="indent-left investment-row">
                        <span class="show-more">
                            <i class="fas fa-info-circle"></i> {{ SHOW_MORE_MESSAGE }}
                        </span>
                    </div>
                </div>
                <car-wip-checklist-non-models *ngIf="isNonModelFeatureEnabled"
                                              [portfolios]="isCurrentAccountInProposal(account) ? getCurrentAccountInProposal(account)?.portfolios : account?.proposalTrackedPortfolios"></car-wip-checklist-non-models>
            </div>
        </div>
        <div *ngIf="this.accountList.length > VISIBLE_ACCOUNT_INCREMENT"
             class="wip-section-background-green wip-section-border wip-section-padding-bottom paging-buttons-container">
            <div class="row wip-summary-row account-body-row button-padding"
                 *ngIf="this.accountList.length > VISIBLE_ACCOUNT_INCREMENT">
                <div class="col-xs-12 indent-left no-padding-left">
                    <sei-button *ngIf="this.accountList.length > VISIBLE_ACCOUNT_INCREMENT"
                                buttonText="See Less"
                                buttonClass="sei-btn-link-padding-none"
                                buttonRightFontAwesomeIcon="fa-caret-up"
                                [buttonDisabled]="this.visibleAccountList.length <= VISIBLE_ACCOUNT_INCREMENT"
                                (buttonClicked)="setVisibleAccounts(this.visibleAccountList.length % VISIBLE_ACCOUNT_INCREMENT !== 0 ? this.visibleAccountList.length - this.visibleAccountList.length % VISIBLE_ACCOUNT_INCREMENT : this.visibleAccountList.length - VISIBLE_ACCOUNT_INCREMENT)"
                                class="pull-left"></sei-button>
                    <sei-button *ngIf="this.accountList.length > VISIBLE_ACCOUNT_INCREMENT"
                                buttonText="See More"
                                buttonClass="sei-btn-link-padding-none"
                                buttonRightFontAwesomeIcon="fa-caret-down"
                                [buttonDisabled]="this.accountList.length === this.visibleAccountList.length"
                                (buttonClicked)="setVisibleAccounts(this.visibleAccountList.length + VISIBLE_ACCOUNT_INCREMENT)"
                                class="pull-right"></sei-button>
                </div>
            </div>
        </div>
    </div>

</div>
