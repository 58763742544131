<div class=" row file-upload-footer">
    <div class="col-xs-6">
        <div class="cancel-button">
            <sei-button buttonText="Cancel"
                        buttonLeftFontAwesomeIcon="fas fa-times-circle icon-x"
                        buttonClass="btn-default"
                        (buttonClicked)="onClickCancel()">
            </sei-button>
        </div>
    </div>

</div>