<div cdkDropList
     [cdkDropListData]="statement.sections"
     (cdkDropListDropped)="dropGroup($event)"
     *ngIf="statement?.toggle">
    <div cdkDropListGroup
         *ngFor="let sectionGroup of statement.sections | orderBy: 'sortOrder'; let counter = index;"
         [ngSwitch]="sectionGroup.name"
         cdkDrag
         [style.z-index]="'unset'"
         [cdkDragData]="sectionGroup"
         [cdkDragDisabled]="counter===0 || isRenameSelected === true">
        <div class="checklist-list"
             [ngClass]="(sectionGroup.isActive)?'active':'normal'"
             [class.closed]="sectionGroup.isCollapsed"
             *ngIf="!sectionGroup.hidden"
             id="{{counter===0 ? 'checklist-proposal': 'checklist-list'+counter}}">
            <span class="preview-span" (click)="onClickPreview(sectionGroup)">click to preview</span>
            <div class="checklist-list-title">
                <sei-car-settings-node [disableDragDrop]="counter === 0"
                                       [groupIndex]="counter"
                                       [group]="sectionGroup"></sei-car-settings-node>
                <sei-car-cover-page *ngIf="sectionGroup.name==='Cover Page' && !sectionGroup.isCollapsed"
                                    [presentationType]="statement.statementType.description"
                                    [proposalSection]="sectionGroup"></sei-car-cover-page>
                <div cdkDropList
                     *ngIf="!sectionGroup.isCollapsed && counter > 0"
                     id="{{sectionGroup.id}}"
                     [cdkDropListData]="sectionGroup.sections"
                     (cdkDropListDropped)="dropItem($event)"
                     [cdkDropListConnectedTo]="getConnectedList()">
                    <div *ngFor="let section of sectionGroup.sections | orderBy: 'sortOrder'; let i = index;"
                         (click)="onClickPreview(sectionGroup)"
                         cdkDrag
                         [cdkDragData]="section"
                         [cdkDragDisabled]="counter===0 || isRenameSelected === true">
                        <sei-car-settings-element [groupIndex]="counter"
                                                  [businessRules]="businessRules"
                                                  (toggleChange)="updatePreview(sectionGroup)"
                                                  (savePdfStatement)="onSavePdfStatement($event)"
                                                  (updateParentSection)="updateParentSection($event)"
                                                  (renameCustomContent)="onRenameCustomContent($event)"
                                                  (renameOptionSelected)="onRenameOptionSelected($event)"
                                                  (onDeleteSection)="onDelete($event)"
                                                  [proposalSection]="section"></sei-car-settings-element>
                    </div>
                    <div *ngIf="sectionGroup.name === 'Advisor Content' && !sectionGroup.isCollapsed"
                         class="toggle-row">
                        <div class="toggle-row-left indent3">
                            <sei-button class="btn-in-tooltip"
                                        [buttonDisabled]="sectionGroup.sections.length > 14"
                                        buttonText="Add Content"
                                        buttonLeftFontAwesomeIcon="fas fa-cloud-upload-alt"
                                        buttonClass="btn-default"
                                        (buttonClicked)="onContentUploadModal()">
                            </sei-button>
                            <div class="wip-menu-icon static-position">
                                <i class="fas fa-info-circle sei-padding-left" [tooltip]="tooltipText"
                                    aria-hidden=true></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<car-file-upload-modal-panel [proposalId]="proposalId"
                             [fileUploadViewMode]="fileUploadViewMode"
                             [presentationType]="presentationType"
                             [sectionName]="sectionName"
                             [subSectionName]="subSectionName"
                             [statement]="statement"
                             (onCancel)="onContentUploadModalCancel()"
                             (advisorContentUploaded)="updateAdvisorContent($event)"
                             *ngIf="showFileUploadModal"></car-file-upload-modal-panel>
