<div class="typeahead">
    <div class="row form-inline input-row">
        <input id="search"
               autocomplete="off"
               class="col-xs-12 form-control proposal-input"
               type="text"
               (keyup)="searchFirmAdvisor($event)"
               [ngModel]="firmName"
               placeholder="Enter Firm Name" />
        <div class="col-xs-12 search-results"
             *ngIf="(searchStream | async) as results">
            <div class="row">
                <div (click)="onTypeAheadSelect(asset)"
                     *ngFor="let asset of results"
                     class="search-result-item-row col-xs-12">
                    <div class="pull-left search-result-item">{{asset.name}}</div>
                </div>
            </div>
        </div>
    </div>
</div>
