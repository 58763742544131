/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { AccountType } from '@CarInterfaces/account';
import { ExistingAccountData } from '@CarModels/existing-accounts';
import { AccountTypesService } from '@CarServices/account/account-types.service';
import { ExistingAccountsService } from '@CarServices/existing-accounts/existing-accounts.service';
import { SpinnerService } from '@CarServices/system/spinner.service';
import { Component, Input, OnInit } from '@angular/core';
import { CommandText, DelegationService, Proposal, ProposalService, Scenario } from '@sei/advisor-client-review-proposal-ux';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import * as _ from 'lodash';

@Component({
    selector: 'sei-car-existing-accounts-form',
    templateUrl: './existing-accounts-form.component.html',
    styleUrls: ['./existing-accounts-form.component.scss']
})
export class ExistingAccountsFormComponent extends ParentComponentSubscriptionManager implements OnInit {
    @Input()
    public scenario: Scenario;

    @Input()
    public proposal: Proposal;

    @Input()
    public existingAccounts: ExistingAccountData[];

    public accountTypes: AccountType[];
    private proposalBackup: Proposal;

    constructor(
        private delegationService: DelegationService,
        private existingAccountsService: ExistingAccountsService,
        private proposalService: ProposalService,
        private spinnerService: SpinnerService,
        private accountTypesService: AccountTypesService) {
        super('ExistingAccountsFormComponent');
    }

    ngOnInit(): void {
        this.proposalBackup = _.cloneDeep(this.proposal);
        this.subscriptions.push(this.delegationService.refresh().subscribe((commandText: CommandText) => {
            switch (commandText) {
                case CommandText.CancelRightHandDialog:
                    this.proposalService.changedProposal(this.proposalBackup);
                    break;
            }
        }));
        this.subscriptions.push(this.existingAccountsService.isExistingAccountCallInProgress.subscribe((value: boolean) => {
            value ? this.spinnerService.start() : this.spinnerService.stop();
        }));
        this.accountTypesService.getAccountTypes(this.proposal?.advisors[0]?.entityId ||
            this.proposal.scenarios[0]?.accounts[0]?.advisors[0]?.entityId).subscribe((response: AccountType[]) => {
                this.accountTypes = response;
            });
    }

}
