/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, Input, OnInit } from '@angular/core';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import * as _ from 'lodash';
import { OptionExploreStrategyDropDown } from '../../model/enums';
import { Account, Strategy, Type } from '../../model/proposal';
import { StrategiesService } from '../../service/strategies.service';


@Component({
    selector: 'sei-car-explore-strategy',
    templateUrl: './explore-strategy.component.html'
})
export class ExploreStrategyComponent extends ParentComponentSubscriptionManager implements OnInit {

    @Input()
    public account: Account;

    @Input()
    public firmId: number;

    @Input()
    public riskToleranceScore: number;

    @Input()
    public strategyCategories: Type[];

    public selectedStrategy: boolean = false;
    public selectedStrategyItem: Type;
    public selectedOptionExploreStrategy: number;
    public allExploreStrategies: Strategy[];
    public exploreStrategiesFilter: Strategy[];
    public optionStrategyDropDown: typeof OptionExploreStrategyDropDown = OptionExploreStrategyDropDown;

    constructor(
        private strategiesService: StrategiesService
    ) {
        super('ExploreStrategyComponent');
    }

    public ngOnInit(): void {
        if (!this.strategyCategories || this.strategyCategories.length === 0) {
            this.strategyCategories = [];
            this.strategyCategories.push({ id: 1, description: 'By Asset Allocation (e.g. 60/40)' });
            this.strategyCategories.push({ id: 2, description: 'By Investment Risk (e.g. Medium)' });
            this.strategyCategories.push({ id: 3, description: 'By Objective (e.g. Growth, Stability, etc.)' });
            this.strategyCategories.push({ id: 4, description: 'View All Available (100 + Strategies)' });
        }
        this.getExploreStrategies();
    }

    public onExploreStrategySelectionChange(data: Type): void {
        this.selectedStrategyItem = data;
        this.selectedStrategy = true;
        this.selectedOptionExploreStrategy = data.id;

        this.exploreStrategiesFilter = this.allExploreStrategies;

        this.sortByQualifiedNonQualified();

        // Note: For M1 this is taken out
        // if (this.selectedOptionExploreStrategy !== OptionExploreStrategy.AllAvailable) {
        //     this.filterExploreStrategiesQualified();
        // } else {
        //     this.exploreStrategiesFilter = this.allExploreStrategies;
        // }
    }

    private sortByQualifiedNonQualified(): void {
        if (this.account.type.taxQualified) {
            this.exploreStrategiesFilter =
                _.orderBy(this.exploreStrategiesFilter, ['advHierarchyQualSortNum', 'advModelSortNum', 'name'], ['asc', 'asc', 'asc']);
        } else {
            this.exploreStrategiesFilter =
                _.orderBy(this.exploreStrategiesFilter, ['advHierarchyNonqualSortNum', 'advModelSortNum', 'name'], ['asc', 'asc', 'asc']);
        }
    }

    public onSelectedStrategyClick(): void {
        this.selectedStrategy = false;
    }

    private getExploreStrategies(): void {
        this.strategiesService
            .getStrategiesExploreStrategies(this.firmId)
            .subscribe((strategies: Strategy[]) => {
                this.allExploreStrategies = strategies;
                this.filterStrategiesBySelectedOne();
            });
    }

    private filterExploreStrategiesQualified(): void {
        if (this.exploreStrategiesFilter) {
            this.exploreStrategiesFilter =
                this.exploreStrategiesFilter.filter((exploreStrategy: Strategy) => {
                    return exploreStrategy.taxSensitive === !this.account.type.taxQualified;
                });
        }
    }

    private filterStrategiesBySelectedOne(): void {
        let firstStrategyInAccount: Strategy;
        if (this.account.strategies && this.account.strategies.length > 0) {
            firstStrategyInAccount = this.account.strategies[0];
            if (firstStrategyInAccount) {
                this.allExploreStrategies =
                    this.strategiesService.filterStrategiesByStrategySelected(
                        this.allExploreStrategies,
                        firstStrategyInAccount
                    );
            }
        }
        this.exploreStrategiesFilter = this.allExploreStrategies;
    }
}
