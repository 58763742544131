/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@CarComponents/common/common.module';
import { ImpersonationComponent } from '@CarComponents/exceptions/impersonation/impersonation.component';
import { FullPageExceptionComponent } from './full-page-exception/full-page-exception.component';
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component';

@NgModule({
    imports: [
        // modules
        CommonModule
    ],
    declarations: [
        NotAuthorizedComponent,
        ImpersonationComponent,
        FullPageExceptionComponent
    ],
    providers: [],
    exports: [ImpersonationComponent]
})
export class ExceptionsModule {}
