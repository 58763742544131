/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { AllocationsForInvestmentMinimum } from '@sei/ias-applications-lib-ux';
import { Allocation, ProductAllocation, Product, PortfolioRiskAllocation } from '../proposal';
import { BaseFactoryAbstract } from './base-factory-abstract';

@Injectable()
export class ProductFactory extends BaseFactoryAbstract<Product> {

    constructor() {
        super();
    }

    public createAllocation(allocations: Allocation[]): Product {
        const productAllocation: ProductAllocation[] = [];
        allocations.forEach((allocation: Allocation) => {
            productAllocation.push({
                identifier: allocation.swpStrategyId ? allocation.swpStrategyId.toString() :
                    (allocation.fundTickerId ? allocation.fundTickerId : allocation.cusip),
                name: allocation.allocationDescription,
                allocationPct: allocation.percentage * 100,
                type: allocation.swpStrategyId && (!allocation.modelId || allocation.modelId === 0) ? 'Manager' :
                    allocation.modelId !== 0 ? 'Strategy' : 'Fund'
            });
        });
        const product: Product = { allocations: productAllocation };
        return product;
    }

    public createAllocationForInvestmentMinimum(allocations: Allocation[]): AllocationsForInvestmentMinimum[] {
        const allocationsForInvestmentMinimum: AllocationsForInvestmentMinimum[] = [];
        allocations.forEach((allocation: Allocation) => {
            allocationsForInvestmentMinimum.push({
                identifier: allocation.swpStrategyId ? allocation.swpStrategyId.toString() :
                    (allocation.fundTickerId ? allocation.fundTickerId : allocation.cusip),
                type: allocation.swpStrategyId && (!allocation.modelId || allocation.modelId === 0) ? 'Manager' :
                    allocation.modelId !== 0 ? 'Strategy' : 'Fund',
                allocationPct: allocation.percentage * 100
            });
        });
        return allocationsForInvestmentMinimum;
    }

    public createPortfolioRiskAllocation(allocations: Allocation[]): PortfolioRiskAllocation[] {
        const productAllocation: PortfolioRiskAllocation[] = [];
        allocations.forEach((allocation: Allocation) => {
            productAllocation.push({
                identifierVal: (allocation.modelId > 0 ? allocation.modelId.toString() :
                    (allocation.swpStrategyId > 0 ? allocation.swpStrategyId.toString() :
                        (allocation.fundTickerId ? allocation.fundTickerId : allocation.cusip))),
                identifierType: (allocation.modelId > 0 ? 'ModelId' :
                    allocation.swpStrategyId > 0 ? 'StrategyId' :
                        allocation.fundTickerId ? 'Ticker' : 'Cusip'),
                entityType: (allocation.modelId > 0 ? 'Model' : allocation.swpStrategyId ? 'Manager' : 'Fund'),
                allocationPct: allocation.percentage * 100
            });
        });
        return productAllocation;
    }
}
