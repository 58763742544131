/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WizardModel, WizardState } from '@sei/common-components-lib-ux';
import { SettingsElementComponent } from '../../../../common/settings-element/settings-element.component';
import { ProposalSection, SelectOption } from '../../../../model/presentation';
import { DataSharingService } from '../../../../service/data-sharing.service';
import { PresentationSettingService } from '../../../../service/presentation-setting.service';
import { ProposalService } from '../../../../service/proposal.service';

@Component({
    selector: 'sei-car-cost-overview',
    templateUrl: './cost-overview.component.html'
})
export class CostOverviewComponent extends SettingsElementComponent implements OnInit {

    public reportingLevelWizardModel: WizardModel[] = [
        { name: 'Roll Up', state: WizardState.ENABLE },
        { name: 'By Acct', state: WizardState.ENABLE }
    ];

    public reportingMethods: SelectOption[] =
        [{ label: 'Summary with Fund Expenses', value: 'Summary with Fund Expenses' },
        { label: 'Detail w/out Fund Expenses', value: 'Detail w/out Fund Expenses' },
        { label: 'Detail with Fund Expenses', value: 'Detail with Fund Expenses' }];

    constructor(
        presentationSettingService: PresentationSettingService,
        dataSharingService: DataSharingService,
        route: ActivatedRoute,
        router: Router,
        proposalService: ProposalService) {
        super(presentationSettingService, dataSharingService, router, route, proposalService);
    }

    ngOnInit() {
        super.ngOnInit();

        this.proposalSection.sections.forEach((section: ProposalSection) => {
            if (section.name === 'Reporting Level') {
                section.include = this.businessRules.reportingLevelRollUpByAccountOption;
                section.isToggleDisabled = !this.businessRules.reportingLevelRollUpByAccountOption;
            }

            if (section.name === 'Show Advisor Fee?') {
                section.include = this.businessRules.showAdvisorFee;
                section.isToggleDisabled = !this.businessRules.showAdvisorFee;
            }
        });
    }

    public onFeesNavigateClick(): void {
        this.savePdfStatement.emit(`/Proposal/WIP/${this.proposalId}/Fees`);
    }

    public onReportingLevelToggleChange(event): void {
        this.proposalSection.include = event;
    }
}
