/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */


import { AttributeCard, FirmDataRequest, FirmDataResponse } from '@CarModels/firm-data.models';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { ApigeeSlice, SeiAction } from '@sei/common-swp-components-lib-ux';
import { FirmInfoActions, FirmInfoSlice, IASCommonAppStore } from '@sei/ias-applications-lib-ux';
import { Observable, forkJoin, of } from 'rxjs';
import { skipWhile, take } from 'rxjs/operators';
import * as fromAuthReducer from '../../auth/auth-slice.reducers';
import { FirmDataService } from './firm-data.service';

@Injectable({
    providedIn: 'root'
})
export class FirmDataGuard {
    constructor(
        private firmDataService: FirmDataService,
        private store: Store<IASCommonAppStore>
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        let firmId: number;
        if (this.checkFirmInfo()) {
            return of(true);
        }
        const observablesArray: Array<Observable<ApigeeSlice | fromAuthReducer.AuthState>> = [];
        const authSliceObservable: Observable<fromAuthReducer.AuthState> = this.store.select(
            (slice: IASCommonAppStore): fromAuthReducer.AuthState => slice.carAuthenticationSlice).pipe(
                skipWhile((authSlice: fromAuthReducer.AuthState) => authSlice.userProfile === undefined),
                take(1)
            );
        const apigeeSliceObservable: Observable<ApigeeSlice> = this.store.select(
            (slice: IASCommonAppStore) => slice.apigeeSlice).pipe(
                skipWhile((apigeeSlice: ApigeeSlice) => apigeeSlice.accessToken === ''),
                take(1)
            );
        observablesArray.push(authSliceObservable);
        observablesArray.push(apigeeSliceObservable);

        authSliceObservable.subscribe((response: fromAuthReducer.AuthState) => {
            firmId = response?.userProfile?.firm?.swpFirmId;
        });

        forkJoin(observablesArray).subscribe(() => {
            const firmDataRequest: FirmDataRequest = { firmId };
            this.firmDataService.getFirmDataStream(firmDataRequest).pipe(
                take(1)
            ).subscribe((firmDataResponse: FirmDataResponse): void => {
                const attributesArray: AttributeCard[] = firmDataResponse.data;
                let firmPreferencesCard: AttributeCard;

                attributesArray.forEach((attribute: AttributeCard) => {
                    if (attribute.attributeType === 'Firm Preferences') {
                        firmPreferencesCard = attribute;
                    }
                });

                if (firmPreferencesCard) {
                    const action: SeiAction = {
                        type: FirmInfoActions[FirmInfoActions.SetFirmInfo],
                        payload: firmPreferencesCard
                    };
                    this.store.dispatch(action);
                }
            });
        });

        return of(true);
    }

    private checkFirmInfo(): boolean {
        let firmInfoSlice: FirmInfoSlice;
        this.store.select((slice: IASCommonAppStore): FirmInfoSlice => slice.firmInfoSlice).pipe(
            take(1)
        ).subscribe((slice: FirmInfoSlice) => {
            firmInfoSlice = slice;
        });
        return (firmInfoSlice !== undefined && firmInfoSlice?.values?.PlatformFeeBasisPoints !== undefined) ? true : false;
    }

}
