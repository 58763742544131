<div class="row" *ngFor="let currentAdvisor of currentAdvisors; let i=index; trackBy: trackByIndex">
    <div class="col-xs-12" [hidden]="showMore && i>0">
        <label for="{{'account-advisors-dropdown-'+i}}">{{(i+1 === 1) ? labels.Primary : labels.Additional}}
            <i [hidden]="i === 0" (click)="deleteAdvisor(i)" class="fas fa-trash-alt pull-right"></i>
        </label>
        <div class="form-group">
            <div class="elastic-search">
                <select id="{{'account-advisors-dropdown-'+i}}" class="form-control" [(ngModel)]="selectedAdvisorIds[i]"
                    [value]="getAdvisorValue(i)" (ngModelChange)="onAdvisorSelection($event, i)">
                    <option *ngFor="let advisor of advisors" [hidden]="shouldHideAdvisorOption(advisor.entityId)"
                        [value]="advisor?.entityId">{{advisor?.advisorName}}
                    </option>
                </select>
            </div>
        </div>
    </div>
</div>