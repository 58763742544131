/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, Input } from '@angular/core';
import { AlertPopoverTypes } from '../../model/enums';

@Component({
    selector: 'car-alert-popover',
    templateUrl: './alert-popover.component.html'
})
export class AlertPopoverComponent {
    public alertPopoverTypes: typeof AlertPopoverTypes = AlertPopoverTypes;

    @Input()
    public alertType: string;

    @Input()
    public alertMessage: string;
}
