/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountStatusComponent } from '@CarComponents/account-status/account-status-main/account-status-main.component';
import { WIPMainComponent } from '@CarComponents/wip-main/wip-main/wip-main.component';
import { PendingAccountDirective } from '../../core/directives/pending-account.directive';
import { PendingAccountLandingComponent } from './pending-account-landing-main/pending-account-landing.component';

@NgModule({
    declarations: [
        PendingAccountLandingComponent,
        PendingAccountDirective
    ],
    imports: [
        CommonModule
    ],
    exports: [
        PendingAccountLandingComponent
    ]
})
export class PendingAccountLandingModule {}
