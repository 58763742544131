/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { ApigeeConfig, SeiSwpOauthResponse } from '@CarInterfaces/oauth';
import { GlobalService } from '@CarServices/system/global.service';
import { PropertyService } from '@CarServices/system/property.service';
import { SeiJsonSerializerService } from '@CarServices/system/sei-json-serializer.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpGrantTypes } from '@CarModels/enums';

@Injectable({
    providedIn: 'root'
})
export class SwpOauthRepository {
    private _config: ApigeeConfig;
    private _proxyKey: string = '{reverseProxy}';
    private _apiVersionKey: string = '{apiVersion}';

    constructor(
        private readonly global: GlobalService,
        private readonly serializer: SeiJsonSerializerService,
        private readonly propertyService: PropertyService
    ) { }

    public set apigeeConfig(value: ApigeeConfig) {
        this._config = value;
    }

    public read(): Observable<SeiSwpOauthResponse> {
        const httpHeaders = this.global.httpUtilityService.createHttpHeader(
            this._config.payload.headers
        );
        let body: string = '';
        const grantType = this._config.payload.headers.grant_type;

        if (
            grantType === HttpGrantTypes.Password &&
            this.propertyService.allExist([
                () =>
                    this.global.configService.apigeeConfiguration.payload.body
                        .username,
                () =>
                    this.global.configService.apigeeConfiguration.payload.body
                        .password
            ])
        ) {
            const payloadBody = this.global.configService.apigeeConfiguration
                .payload.body;
            body = `username=${payloadBody.username}&password=${payloadBody.password}`;
        }

        this.global.logger.log(
            `Apigee token requested from ${this.global.configService.environment.carConfig.apigeeEnvironmentKey}`
        );

        return this.global.httpUtilityService
            .doPostRaw<SeiSwpOauthResponse, string>(
                this.createEndpoint(this._config),
                body,
                httpHeaders
            )
            .pipe(
                // Dealing with two different return paths right now.
                // Client Credentials is going through CAR SOA which is using the sei payload as a return
                // Password is going to Apigee directly, thus the token object is the response
                // using any to get through typing
                //
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                map((response: any) => {
                    if (this.propertyService.exists(() => response.data)) {
                        return this.serializer.deserialize<SeiSwpOauthResponse>(
                            response,
                            'token'
                        );
                    } else {
                        return response;
                    }
                })
            );
    }

    /*
     * If client credentials, then use the reverse proxy endpoint.
     * Password is not being supported through the reverse proxy and should only be used in LOCAL/PR/DEV environments
     */
    private createEndpoint(config: ApigeeConfig): string {
        let endpoint: string;

        if (
            config.payload.headers.grant_type ===
            HttpGrantTypes.ClientCredentials
        ) {
            endpoint = `${config.accessTokenServer}${config.accessTokenEndPoint}`;
        } else {
            endpoint = `${config.transportProtocol}://${config.server}${
                config.serverPort ? ':' + config.serverPort : ''
                }${config.urlPrefix}${config.accessTokenEndPoint}`;
        }

        // Because in Pilot and Prod a User can come in from multiple reverse proxies
        // We need to use the reverse proxy they came in on due to
        // CORs with different domains and the session guids not sharing
        return endpoint
            .replace(this._proxyKey, window.location.hostname)
            .replace(this._apiVersionKey, config.accessTokenApiVersion);
    }
}
