<div class="account-ribbon"
     [class.account-ribbon-border]="!showSectionDetails">
    <div class="left-component">
        <span class="menu-caret"><i class="fa fas fa-bars"></i></span>
        <div class="flex-left">
            {{account.name}}
        </div>
    </div>
    <div class="flex-right">
        <h5>
            Inv. Cost:
            {{ (account.investmentCost.rate * 100) | proposalFormatPercentWithLessThan:proposalPercentFormatOptions }} |
            <sei-car-display-monetary-amount [amount]="account.investmentCost.amount ? account.investmentCost.amount : 0"
                                             [actualCurrency]="currencyTypes.USD">
            </sei-car-display-monetary-amount>
        </h5>
        <a (click)="onShowDetailsClick()">
            <i class="fas"
               [class.fa-chevron-down]="showSectionDetails"
               [class.fa-chevron-right]="!showSectionDetails"></i>
        </a>
    </div>
</div>
<sei-car-fee-schedule-account-detail *ngIf="showSectionDetails"
                                     [strategyFees]="account.strategies"
                                     [portfolios]="account.proposalAccountPortfolios"
                                     [managementFeeBreakdown]="account?.managementFeeBreakdown"></sei-car-fee-schedule-account-detail>
