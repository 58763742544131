/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalService } from '@CarServices/system/global.service';
import { ProposalReactivateResponse, ProposalStatusResponse } from '@sei/advisor-client-review-proposal-ux';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ReactivateModalService {

    constructor(private globalService: GlobalService,
        private http: HttpClient) { }

    public navigateToDAO(): void {
        const carConfig = this.globalService.configService.environment.carConfig;
        const hostApplicationRedirect: string = `${carConfig.transportProtocol}://${carConfig.server}`;
        const newAccountProxyPath: string = `${carConfig.newAccountProxyGroup}`;
        window.open(`${hostApplicationRedirect}/${newAccountProxyPath}/#/PendingAccounts`);
    }

    public retrieveProposalPendingAccountStatuses(proposalId: number): Observable<ProposalStatusResponse> {
        const apiUrl = this.globalService.configService.routeFormatter(
            this.globalService.configService.getEndPoint('retrievePendingAccountStatus'),
            {
                key: 'proposalId',
                value: proposalId
            }
        );
        return this.http.get<ProposalStatusResponse>(apiUrl);
    }

    public deleteProposalPendingAccountsAndReactivateProposal(proposalId: number): Observable<ProposalReactivateResponse> {
        const apiUrl = this.globalService.configService.routeFormatter(
            this.globalService.configService.getEndPoint('reactivateProposal'),
            {
                key: 'proposalId',
                value: proposalId
            }
        );
        return this.http.post<ProposalReactivateResponse>(apiUrl, undefined);
    }
}
