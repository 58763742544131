<div class="row">
    <div class="col-xs-12">
        <h2>Proposal Name</h2>
    </div>
    <div class="col-xs-12">
        <p>Identify proposal name for your client</p>
    </div>
    <div class="col-xs-12">
        <input type="text"
               class="form-control"
               [(ngModel)]="proposalEditable.name"
               name="proposalName"
               required
               minlength="2">
    </div>
</div>
<car-proposal-clients-form *ngIf="proposalEditable && suffixData"
                           [proposal]="proposalEditable"
                           [suffix]="suffixData"
                           [isEditProposal]="true"
                           [disabledAddClient]="disabledAddClient"
                           (clientsModelChanged)="validateAddClients()">
</car-proposal-clients-form>
