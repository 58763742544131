<div class="pick-list-container">
    <i class="fa-sharp fa-solid fa-circle-check checkmark pointer"
        [ngClass]="showHoverStyling ? 'checkmark-not-selected' : 'checkmark-selected'"
        *ngIf="isExistingAccountSelected() || showHoverStyling"
        (click)="toggleSelectionStatusForExistingAccount()">
    </i>
    <sei-dashboard-tile [title]="existingAccountData?.accountName"
                        panelClass="panel-default"
                        class="pointer"
                        (mouseover)="showCheckmarkOnHover()"
                        (mouseout)="hideCheckmark()"
                        (titleClicked)="toggleSelectionStatusForExistingAccount()"
                        [class.selected-card-body]="isExistingAccountSelected()"
                        [class.hover-card-body]="showHoverStyling"
                        [class.collapsed]="isCardCollapsed"
                        [class.banned-pointer]="showBannedPointer()"
                        [class.disabled-color]="showBannedPointer()">
        <div panel-heading-extra-content
             class="extra-content"
             [ngClass]="isExistingAccountSelected() ? 'selected-card-header' : ''">
            <i class="fas fa-info-circle" *ngIf="showInfoIcon()" [tooltip]="toolTipMessage"></i>
            <div class="pull-right">
                <span (click)="toggleCollapsed($event)" class="pointer header">
                {{ { value: existingAccountData?.actualCurrentMarketValue, actualCurrency: 'USD' } | seiDisplayMonetaryAmount:amountOptions:roundFractionalNumber:displayNumericScaleSuffix }}
                <a class="section-arrow" [class.open]="!isCardCollapsed" aria-label="Expand Investment Details Section">
                <i class="fas" [class.fa-chevron-right]="isCardCollapsed" [class.fa-chevron-down]="!isCardCollapsed"></i>
                </a>
                </span>
            </div>

        </div>
        <div panel-content
             *ngIf="!isCardCollapsed"
             (click)="toggleSelectionStatusForExistingAccount()"
             class="pointer"
             [class.banned-pointer]="showBannedPointer()">
            <div class="row">
                <div class="col-xs-6 form-group"
                     [ngClass]="{'has-error': !isAmountValid}">
                    <label class="card-label">Amount</label>
                    <p>{{ {value: existingAccountData?.actualCurrentMarketValue, actualCurrency: 'USD' } | seiDisplayMonetaryAmount:amountOptions:roundFractionalNumber:displayNumericScaleSuffix}}</p>
                </div>
                <div class="col-xs-6">
                    <label class="card-label">Account Type</label>
                    <p>{{ existingAccountData?.accountType }}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-6 form-group">
                    <label class="card-label">Investment Program</label>
                    <p>{{ existingAccountData?.investmentProgramDescription }}</p>
                </div>
                <div class="col-xs-6 form-group">

                </div>
            </div>
            <div class="row"
                 *ngFor="let client of selectedClientList; let i=index">
                <div class="col-xs-12 form-group">
                    <sei-car-search-owner-form [index]="i"
                                               [client]="client"
                                               [entitySearchEnabled]="false"
                                               [clientList]="proposalClientList"
                                               [selectedClientList]="selectedClientList"
                                               [isHeaderLabel]="true"
                                               [accountTypeId]="accountTypeId"
                                               [existingAccountContactTypeId]="existingAccountTypeId"
                                               [hideDeleteIcon]="true"></sei-car-search-owner-form>
                </div>
            </div>
            <div class="wip-summary-row pageheader account-body-row">
                <div class="col-xs-12 indent-left">
                    <sei-pageheader [headingLevel]="5"
                                    [pageHeaderProperties]="modelPageHeaderProperties">
                        <div extra-content>
                            <span class="header-amount">{{ { value: existingAccountData?.modelMarketValue, actualCurrency: 'USD' }| seiDisplayMonetaryAmount:amountOptions:roundFractionalNumber:displayNumericScaleSuffix}}</span>
                            <a class="section-arrow" [class.open]="showModelInvestmentDetails" aria-label="Expand Investment Details Section" (click)="toggleShowModelInvestmentDetails()">
                            <i class="fas" [class.fa-chevron-right]="!showModelInvestmentDetails" [class.fa-chevron-down]="showModelInvestmentDetails"></i>
                        </a>
                        </div>
                    </sei-pageheader>
                </div>
            </div>
            <div *ngIf="showModelInvestmentDetails">
                <div class="wip-summary-row account-body-row"
                     *ngFor="let model of existingAccountData?.modelInvestments; let i = index">
                    <div class="indent-left investment-row">
                        <div class="col-xs-6">
                            <span>{{ model?.modelName }}</span>
                        </div>
                        <div class="col-xs-6">
                            <span class="pull-right">{{ {value: model?.amount, actualCurrency: 'USD' } | seiDisplayMonetaryAmount:amountOptions:roundFractionalNumber:displayNumericScaleSuffix}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!showModelInvestmentDetails && existingAccountData?.modelInvestments?.length > 0"
                 class="wip-summary-row account-body-row">
                <div class="indent-left investment-row">
                    <span class="pointer">
                        <i class="fas fa-info-circle"></i> {{ SHOW_MORE_MESSAGE }}
                    </span>
                </div>
            </div>

            <car-wip-checklist-non-models [portfolios]="existingAccountData?.existingNonModelPortfolios"></car-wip-checklist-non-models>
        </div>
    </sei-dashboard-tile>
</div>
