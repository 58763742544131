/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'car-page-template',
    templateUrl: './page-template.component.html'
})
export class PageTemplateComponent implements OnInit {
    public showTitle: boolean = false;

    @Input()
    public pageTitle: string = '';

    ngOnInit() {
        if (this.pageTitle.trim().length < 1) {
            this.showTitle = false;
        } else {
            this.showTitle = true;
        }
    }
}
