/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClientSearchTypeStatus, EntityDropdownEsTypes, SourceSystem } from '@sei/client-account-administration-lib-ux';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Contact } from '../model/contact';
import { DirectServiceRelationship, ElasticSearchRelatedParty, ElasticSearchRelatedPartyResult, ElasticSearchRelatedPartySWPResult, ElasticSearchResult } from '../model/elastic-search-related-party';
import { ContactTypes, ElasticSearchResultType, EntityType } from '../model/enums';
import { ContactRepository } from '../repositories/contact.repository';
import { ElasticSearchRepository } from '../repositories/elastic-search.repository';
import { GlobalService } from './system/global.service';

@Injectable({
    providedIn: 'root'
})
export class ElasticSearchService {
    constructor(
        private globalService: GlobalService,
        private elasticSearchRepository: ElasticSearchRepository,
        private contactRepository: ContactRepository,
        private http: HttpClient
    ) { }

    public getThirdPartyInformationStreaming(
        entityId: number
    ): Observable<ElasticSearchRelatedParty> {
        return this.elasticSearchRepository.read(entityId);
    }

    public getContactInformationStreaming(
        entityId: number
    ): Observable<Contact> {
        return this.contactRepository.read(entityId);
    }

    public getThirdPartyContactInformationByContactTypeStreaming(
        entityId: number,
        entityType: string,
        contactType: ContactTypes
    ): Observable<ElasticSearchResult> {
        if (entityType === EntityType.Prospect) {
            return this.getContactInformationByContactTypeStreaming(
                entityId,
                contactType
            ).pipe(
                map((contact) => {
                    const elasticSearchResult: ElasticSearchResult = {
                        elasticSearchResultType:
                            ElasticSearchResultType.Contact,
                        contact
                    };
                    return elasticSearchResult;
                })
            );
        } else {
            return this.getThirdPartyInformationByContactTypeStreaming(
                entityId,
                contactType
            ).pipe(
                map((relatedParty) => {
                    const elasticSearchResult: ElasticSearchResult = {
                        elasticSearchResultType:
                            ElasticSearchResultType.ThirdParty,
                        relatedPartyResult: {
                            ...relatedParty,
                            entityType
                        }
                    };
                    return elasticSearchResult;
                })
            );
        }
    }

    public getThirdPartyInformationByContactTypeStreaming(
        entityId: number,
        contactType: ContactTypes
    ): Observable<ElasticSearchRelatedParty> {
        return this.getThirdPartyInformationStreaming(entityId).pipe(
            tap((relatedParty) => {
                if (!this.validateThirdParty(relatedParty, contactType)) {
                    throw new Error(
                        'This owner is not compatible with the selected account type.'
                    );
                }
            })
        );
    }

    public getContactInformationByContactTypeStreaming(
        entityId: number,
        contactType: ContactTypes
    ): Observable<Contact> {
        return this.getContactInformationStreaming(entityId).pipe(
            tap((contact) => {
                if (!this.validateContact(contact, contactType)) {
                    throw new Error(
                        'This owner is not compatible with the selected account type.'
                    );
                }
            })
        );
    }

    public getCounterPartyProcessingOrg(): number {
        let processingOrgId = 0;
        const processingOrgIdConfig = this.globalService.configService.getOption(
            'processingOrgId'
        );

        if (processingOrgIdConfig) {
            processingOrgId = parseInt(processingOrgIdConfig, 10);

            if (isNaN(processingOrgId)) {
                processingOrgId = 0;
            }
        }

        return processingOrgId;
    }

    public getContactsSourceSystemOptions(): SourceSystem[] {
        // Default source system values
        // ALL => We need to create an array with all enum values
        return Object.keys(SourceSystem).map((key) => SourceSystem[key]);
    }

    public getContactsSearchEntityTypesOptions(): EntityDropdownEsTypes[] {
        const searchEntityTypes = [
            EntityDropdownEsTypes.Client,
            EntityDropdownEsTypes.Third_Party,
            EntityDropdownEsTypes.Prospect
        ];

        return searchEntityTypes;
    }

    public getContactsClientSearchTypeStatus(): ClientSearchTypeStatus[] {
        const clientSearchTypeStatus = [
            ClientSearchTypeStatus.CURRENT
        ];

        return clientSearchTypeStatus;
    }

    public getAccountsSearchEntityTypesOptions(): EntityDropdownEsTypes[] {
        return [EntityDropdownEsTypes.Account];
    }

    public getAccountsSourceSystemOptions(): SourceSystem[] {
        return [SourceSystem.SWP];
    }

    public getPendingAccountsSearchEntityTypesOptions(): EntityDropdownEsTypes[] {
        return [EntityDropdownEsTypes.Pending_Account];
    }

    public getPendingAccountsSourceSystemOptions(): SourceSystem[] {
        return [SourceSystem.CAR];
    }

    public getClientsSearchEntityTypesOptions(): EntityDropdownEsTypes[] {
        const searchEntityTypes = [
            EntityDropdownEsTypes.Client,
            EntityDropdownEsTypes.Third_Party,
            EntityDropdownEsTypes.Prospect
        ];

        return searchEntityTypes;
    }

    public getClientSearchSystemEntityTypesOptions(): SourceSystem[] {
        return [SourceSystem.CAR, SourceSystem.SWP];
    }

    public createSimpleProspectThirdPartyInformation(
        value: string,
        contactType: ContactTypes
    ): ElasticSearchRelatedPartyResult {
        let firstName: string;
        let lastName: string;

        if (value) {
            if (ContactTypes.Individual === contactType) {
                const spaceIndex = value.indexOf(' ');

                if (spaceIndex > -1) {
                    firstName = value.substring(0, spaceIndex);
                    lastName = value.substring(spaceIndex, value.length);
                } else {
                    firstName = value;
                }
            } else {
                lastName = value;
            }
        }

        const newElasticSearchRelatedParty: ElasticSearchRelatedPartyResult = {
            entityType: EntityType.Prospect,
            type: EntityType.Prospect,
            attributes: {
                partyName: {
                    firstName,
                    lastName
                }
            }
        };

        return newElasticSearchRelatedParty;
    }

    public getDirectServiceRelationshipByEntityId(
        entityId: number
    ): Observable<DirectServiceRelationship[]> {

        const apiUrl = this.globalService.configService.routeFormatter(
            this.globalService.configService.getEndPoint('elasticSearch'),
            {
                key: 'entityId',
                value: entityId
            }
        );

        return this.http.get(apiUrl).pipe(
            map((response: ElasticSearchRelatedPartySWPResult) => {
                if (
                    response &&
                    response.data &&
                    response.data.attributes &&
                    response.data.attributes.selectedDirectServiceRelationships
                ) {
                    return response.data.attributes.selectedDirectServiceRelationships;
                }
            })
        );
    }

    private validateThirdParty(
        elasticSearchRelatedParty: ElasticSearchRelatedParty,
        contactType: ContactTypes
    ) {
        const hasFirstName: boolean = elasticSearchRelatedParty.attributes
            .partyName.firstName
            ? true
            : false;

        if (ContactTypes.Organization === contactType) {
            if (hasFirstName) {
                return false;
            }
        } else {
            if (!hasFirstName) {
                return false;
            }
        }

        return true;
    }

    private validateContact(contact: Contact, contactType: ContactTypes) {
        const hasFirstName: boolean = contact.firstName ? true : false;

        if (ContactTypes.Organization === contactType) {
            if (hasFirstName) {
                return false;
            }
        } else {
            if (!hasFirstName) {
                return false;
            }
        }

        return true;
    }
}
