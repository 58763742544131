/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { StoreAction } from '@sei/common-swp-components-lib-ux';
import { CardStateSlice, IASCommonAppStore, InvestmentActions, InvestmentSummary, InvestmentSummaryStatusActions } from '@sei/ias-applications-lib-ux';

@Injectable({
    providedIn: 'root'
})
export class IasDispatchService {

    constructor(private readonly appStore: Store<IASCommonAppStore>) { }

    public cleanStoreIasStore(): void {
        this.cleanInvestmentSummaryStore();
        this.cleanAssetAllocationStore();
        this.resetInvestmentSummaryStatusStore();
    }

    private cleanInvestmentSummaryStore(): void {
        const actionInvestmentSummary: StoreAction<InvestmentSummary> = {
            type: InvestmentActions[InvestmentActions.CleanStoreInvestment]
        };
        this.appStore.dispatch(actionInvestmentSummary);
    }

    private cleanAssetAllocationStore(): void {
        const actionCardState: StoreAction<CardStateSlice> = {
            type: InvestmentActions[InvestmentActions.ResetAssetAllocationDetails]
        };
        this.appStore.dispatch(actionCardState);
    }

    private resetInvestmentSummaryStatusStore(): void {
        const isInvestmentSummary: boolean = true;
        const action: StoreAction<boolean> = {
            type: InvestmentSummaryStatusActions[InvestmentSummaryStatusActions.InvestmentSummaryStatus],
            payload: isInvestmentSummary
        };
        this.appStore.dispatch(action);
    }
}
