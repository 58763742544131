/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { ProposalSection, SelectOption, FundExpense } from '../model/presentation';

@Injectable({
    providedIn: 'root'
})
export class DataSharingService {
    private proposalSection: ProposalSection = undefined;
    private imageUploaded: SelectOption = undefined;
    private coverImageUploaded: string = undefined;
    private brandLogoUploaded: string = undefined;

    public proposalSectionChanged: BehaviorSubject<ProposalSection> = new BehaviorSubject<ProposalSection>(this.proposalSection);
    public imageUpload: BehaviorSubject<SelectOption>
        = new BehaviorSubject<SelectOption>(this.imageUploaded);

    public coverImageUpload: BehaviorSubject<string> = new BehaviorSubject<string>(this.coverImageUploaded);
    public brandLogoUpload: BehaviorSubject<string> = new BehaviorSubject<string>(this.brandLogoUploaded);

}
