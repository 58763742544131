/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { AuthState } from '@CarAuth/auth-slice.reducers';
import { UpdatePrimaryAdvisor } from '@CarAuth/auth.actions';
import { getCarUserProfile } from '@CarAuth/auth.selectors';
import { Firm, OfficeLocation, User, UserProfile } from '@CarInterfaces/user-profile';
import { UserProfileRepository } from '@CarRepos/user-profile-repository';
import { FirmProcessingRulesService } from '@CarServices/processing-rules/firm-processing-rules.service';
import { cloneDeep } from 'lodash';
import { noop, Observable, of } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { PropertyService } from './property.service';

/**
 * Car Profile object
 * There must be a valid CAR user profile object or authentication won't work
 * Right now this is advisor base but will need to be adjusted for Admins
 * Once the object is Fetched, it lives in NGRX until destroyed.
 */
@Injectable({
    providedIn: 'root'
})
export class UserProfileService {
    private userProfile: UserProfile;
    private authAdvisor: User;
    private authAdvisorOfficeLocation: OfficeLocation;

    constructor(
        private readonly userProfileRepository: UserProfileRepository,
        private readonly store: Store<AuthState>,
        private readonly propService: PropertyService,
        private readonly firmProcessingRulesService: FirmProcessingRulesService
    ) {
        this.initializeUserProfile();
    }

    /**
     * Gets the user profile object
     */
    public get profile(): UserProfile {
        // ToDo: This will be fixed in a different PR, just need this here to support account refreshes
        if (this.userProfile && !this.userProfile.primaryAdvisor) {
            this.userProfile.primaryAdvisor = cloneDeep(this.userProfile);
            this.store.dispatch(
                new UpdatePrimaryAdvisor({ userProfile: this.userProfile })
            );
        }
        return this.userProfile;
    }

    public isPrimaryAdvisor(): boolean {
        return (
            this.propService.exists(() => this.firm.advisors) &&
            !!this.firm.advisors.find(
                (advisor: User) => advisor.entityId === this.profile.entityId
            )
        );
    }

    public get authenticatedAdvisor(): User {
        if (!this.authAdvisor && this.firm && this.firm.advisors) {
            this.authAdvisor = this.firm.advisors.find(
                (advisor: User) =>
                    advisor.entityId === this.userProfile.entityId
            );
        }
        return this.authAdvisor;
    }

    public get authenticatedAdvisorOfficeLocation(): OfficeLocation {
        if (
            this.firm &&
            this.firm.locations &&
            this.firm.locations.length > 0
        ) {
            this.authAdvisorOfficeLocation = this.firm.locations[0];
        }

        return this.authAdvisorOfficeLocation;
    }

    public get firm(): Firm {
        if (!this.userProfile || !this.userProfile.firm) {
            // TODO Handle exception of no user profile and firm object for instance users - MVP2
            return undefined;
        }
        return this.userProfile.firm;
    }

    public getPrimaryAdvisor(siteminderId: number): Observable<UserProfile> {
        if (!this.userProfile) {
            throw new Error(
                `User profile object does not exist to set primary advisor`
            );
        }

        if (typeof siteminderId !== 'number') {
            siteminderId = Number.parseInt(siteminderId, 10);

            if (Number.isNaN(siteminderId)) {
                throw new Error(`Invalid data type to get primary advisor`);
            }
        }

        if (siteminderId === this.userProfile.entityId) {
            this.userProfile.primaryAdvisor = cloneDeep(this.userProfile);
            this.store.dispatch(
                new UpdatePrimaryAdvisor({ userProfile: this.userProfile })
            );
            return of(this.userProfile.primaryAdvisor);
        }

        if (
            this.userProfile.primaryAdvisor &&
            this.userProfile.primaryAdvisor.entityId === siteminderId
        ) {
            return of(this.userProfile.primaryAdvisor);
        }

        return this.userProfileRepository.read(siteminderId).pipe(
            filter((userProfile: UserProfile) => userProfile !== undefined),
            tap((userProfile: UserProfile) => {
                this.userProfile.primaryAdvisor = cloneDeep(userProfile);
                this.store.dispatch(
                    new UpdatePrimaryAdvisor({ userProfile: this.userProfile })
                );
            })
        );
    }

    public get isSuperUser(): boolean {
        const userLevelId = this.profile.entitlements.userLevelId;
        const functionPoint = this.profile.functionPoints.find(
            (funcPoint) => funcPoint.functionPointId === 6466
        );

        return (
            userLevelId === 1 &&
            (functionPoint.permissionLevel === 2 ||
                functionPoint.permissionLevel === 15)
        );
    }

    private initializeUserProfile() {
        if (!this.userProfile) {
            this.store
                .select(getCarUserProfile)
                .pipe(
                    filter(
                        (userProfile: UserProfile) => userProfile !== undefined
                    ),
                    tap((userProfile: UserProfile) => {
                        this.userProfile = userProfile;
                        this.firmProcessingRulesService.initializeService(
                            this.userProfile.firm
                        );
                    })
                )
                .subscribe(noop, () => {
                    delete this.userProfile;
                });
        }
    }
}
