/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, Input, OnInit } from '@angular/core';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { RiskWidgetTypes, StrategyMenu } from '../../model/enums';
import { RiskWidgetFactory } from '../../model/factory/risk-widget-factory';
import { Account, Strategy } from '../../model/proposal';

@Component({
    selector: 'sei-car-previously-used-strategy',
    templateUrl: './previously-used-strategy.component.html'
})

export class PreviouslyUsedStrategyComponent extends ParentComponentSubscriptionManager implements OnInit {

    @Input()
    public account: Account;

    @Input()
    public riskToleranceScore: number;

    @Input()
    public filteredStrategies: Strategy[];

    private riskRankSelectedFilter: number = 0;
    public filteredStrategiesByRiskRank: Strategy[];
    public riskWidgetAvailableValues: number[] = [];
    public ribbonTypes: typeof StrategyMenu = StrategyMenu;
    public riskWidgetTypes: typeof RiskWidgetTypes = RiskWidgetTypes;

    constructor() {
        super('PreviouslyUsedStrategyComponent');
    }

    public ngOnInit(): void {
        this.riskWidgetAvailableValues = new RiskWidgetFactory().getAllClickableSpanPoints();
        this.filterPreviouslyStrategies();
    }

    public onEventRiskLevelSelected(event: number): void {
        this.riskRankSelectedFilter = event;
        this.filterPreviouslyStrategies();
    }

    private filterPreviouslyStrategies(): void {
        if (this.filteredStrategies) {
            this.filteredStrategiesByRiskRank =
                this.filteredStrategies.filter((strategy: Strategy) => {
                    if (strategy.riskRank ===
                        (this.riskRankSelectedFilter === 0
                            ? strategy.riskRank
                            : this.riskRankSelectedFilter)) {
                        return strategy;
                    }
                });
        }
    }
}
