/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RiskLevels } from '../model/enums';
import { CompletedRiskAccounts, InvestorChoice, QuestionAnswer, Questionnaire } from '../model/questionnaire';
import { SeiPayload } from '../model/sei-payload';
import { NumberEvaluator } from '../utilities/number-evaluator.utility';
import { GlobalService } from './system/global.service';

@Injectable({
    providedIn: 'root'
})
export class QuestionnaireService {
    public userProfileId: number;
    private questionnaire: BehaviorSubject<Questionnaire[]>;
    private questionnaireQuestionUpdated: BehaviorSubject<InvestorChoice>;

    constructor(
        private readonly global: GlobalService,
        private http: HttpClient,
        private readonly numberEvaluator: NumberEvaluator
    ) { }

    public hasQuestionnaire(): boolean {
        if (this.questionnaire) {
            return !_.isEmpty(this.questionnaire);
        }
        return false;
    }

    public getQuestionnaire(
        proposalAccountId: number
    ): Observable<Questionnaire[]> {

        const apiUrl = this.global.configService.routeFormatter(
            this.global.configService.getEndPoint('getQuestionnaire'),
            [
                {
                    key: 'proposalAccountId',
                    value: proposalAccountId
                }
            ]
        );

        return this.http.get(apiUrl).pipe(
            map((response: SeiPayload) => {
                if (response && response.data) {
                    return response.data[0].questions;
                }
            })
        );
    }

    public getQuestionnaireByAccountGroupType(accountGroupId: number): Observable<Questionnaire[]> {
        const apiUrl = this.global.configService.routeFormatter(
            this.global.configService.getEndPoint('rtqByAccountGroup'),
            [
                {
                    key: 'accountGroupId',
                    value: accountGroupId
                }
            ]
        );
        return this.http.get(apiUrl).pipe(
            map((response: SeiPayload) => {
                if (response && response.data) {
                    return response.data[0].questionnaire.questions;
                }
            })
        );
    }

    public getCurrentQuestionnaire(): Questionnaire[] {
        return this.questionnaire.value;
    }

    public getCompletedAccounts(
        proposalAccountId: number
    ): Observable<CompletedRiskAccounts[]> {
        const apiUrl = this.global.configService.routeFormatter(
            this.global.configService.getEndPoint('getCompletedRiskAccounts'),
            [
                {
                    key: 'proposalAccountId',
                    value: proposalAccountId
                }
            ]
        );

        return this.http.get(apiUrl).pipe(
            map((response: SeiPayload) => {
                if (response && response.data) {
                    return response.data;
                }
            })
        );
    }

    public getRiskToleranceChoices(
        proposalAccountId: number
    ): Observable<QuestionAnswer[]> {
        const apiUrl = this.global.configService.routeFormatter(
            this.global.configService.getEndPoint('getRiskToleranceChoices'),
            [
                {
                    key: 'proposalAccountId',
                    value: proposalAccountId
                }
            ]
        );

        return this.http.get(apiUrl).pipe(
            map((response: SeiPayload) => {
                if (response && response.data) {
                    return response.data;
                }
            })
        );
    }

    public resetQuestionnaire(): void {
        this.questionnaire = new BehaviorSubject<Questionnaire[]>([]);
    }

    public getRiskTolerance(): Observable<Questionnaire[]> {
        if (!this.questionnaire) {
            return EMPTY;
        }
        return this.questionnaire.asObservable();
    }

    public getNextQuestionnaire(questionnaire: Questionnaire[]) {
        if (!this.questionnaire) {
            this.questionnaire = new BehaviorSubject<Questionnaire[]>(
                questionnaire
            );
        }
        return this.questionnaire.next(questionnaire);
    }

    public getQuestionSelectedUpdated(): Observable<InvestorChoice> {
        if (!this.questionnaireQuestionUpdated) {
            this.getNextQuestionSelectedUpdated(undefined);
        }
        return this.questionnaireQuestionUpdated.asObservable();
    }

    public getNextQuestionSelectedUpdated(question: InvestorChoice) {
        if (!this.questionnaireQuestionUpdated) {
            this.questionnaireQuestionUpdated = new BehaviorSubject<
                InvestorChoice
            >(question);
        }
        return this.questionnaireQuestionUpdated.next(question);
    }

    public isQuestionnaireComplete(): boolean {
        if (this.questionnaire && this.questionnaire.value) {
            const countQuestions = this.questionnaire.value.length;
            const answeredQuestions = this.getAnsweredQuestionsCount();

            if (
                answeredQuestions !== 0 &&
                answeredQuestions === countQuestions
            ) {
                return true;
            }
        }
        return false;
    }

    public isQuestionnaireInProgress(): boolean {
        if (this.questionnaire && this.questionnaire.value) {
            const countQuestions = this.questionnaire.value.length;
            const answeredQuestions = this.getAnsweredQuestionsCount();

            if (answeredQuestions > 0 && answeredQuestions < countQuestions) {
                return true;
            }
        }
        return false;
    }

    private getAnsweredQuestions(): Questionnaire[] {
        let answeredQuestions: Questionnaire[] = [];
        if (this.questionnaire && this.questionnaire.value) {
            answeredQuestions = this.questionnaire.value
                .filter((question: Questionnaire) => {
                    return question.investorChoices.some(
                        (option: InvestorChoice) => option.selected === true
                    );
                })
                .map((question: Questionnaire) => {
                    const newQuestion = Object.assign({}, question);
                    newQuestion.investorChoices = question.investorChoices.filter(
                        (option: InvestorChoice) => option.selected === true
                    );
                    return newQuestion;
                });
        }
        return answeredQuestions;
    }

    private getAnsweredQuestionsCount(): number {
        return this.getAnsweredQuestions().length;
    }

    private getQuestionsCount(): number {
        if (this.questionnaire && this.questionnaire.value) {
            return this.questionnaire.value.length;
        }
        return 0;
    }

    public getPercentQuestionsAnswered() {
        const answeredQuestions = this.getAnsweredQuestionsCount();
        const totalQuestions = this.getQuestionsCount();

        return answeredQuestions * (totalQuestions / 100);
    }

    public setAnsweredQuestions(
        selectedAnswers: QuestionAnswer[],
        questionnaire: Questionnaire[]
    ): Questionnaire[] {
        if (selectedAnswers) {
            this.questionnaire.next(questionnaire);
            selectedAnswers.map((answer: QuestionAnswer) => {
                const question = this.questionnaire.value.find(
                    (questionFound: Questionnaire) =>
                        questionFound.questionId === answer.questionId
                );
                if (question) {
                    const investorChoice = question.investorChoices.find(
                        (investor: InvestorChoice) =>
                            investor.choiceId === answer.choiceId
                    );

                    if (investorChoice) {
                        investorChoice.selected = true;
                        this.getNextQuestionSelectedUpdated(investorChoice);
                    }
                }
            });
            return questionnaire;
        }
    }

    public getRiskLevel(): string {
        const score = this.getScore();
        const riskLevel = this.numberEvaluator.between(score, 0, 31.99)
            ? RiskLevels.Low
            : this.numberEvaluator.between(score, 32, 68.99)
                ? RiskLevels.Medium
                : this.numberEvaluator.between(score, 69, 100)
                    ? RiskLevels.High
                    : RiskLevels.None;

        return riskLevel;
    }

    public getScore(): number {
        let totalScore: number = 0;

        this.getAnsweredQuestions().forEach(
            (question: Questionnaire) =>
            (totalScore += question.investorChoices.reduce(
                (sum, option) => sum + option.weightPoints,
                0
            ))
        );

        return totalScore;
    }

    public getRiskLevelScore(): number {
        let riskLevelScore: number;

        const riskLevel = this.getRiskLevel();

        switch (riskLevel) {
            case RiskLevels.Low:
                riskLevelScore = 1;
                break;
            case RiskLevels.Medium:
                riskLevelScore = 4;
                break;
            case RiskLevels.High:
                riskLevelScore = 7;
                break;
            default:
                riskLevelScore = 0;
                break;
        }

        return riskLevelScore;
    }

    public getQuestionnaireChoices(): QuestionAnswer[] {
        const choices: QuestionAnswer[] = [];
        const questionnaire: Questionnaire[] = this.getCurrentQuestionnaire();
        questionnaire.map((question) => {
            const answeredChoice: InvestorChoice = question.investorChoices.find(
                (choice) => choice.selected === true
            );

            if (answeredChoice) {
                choices.push({
                    questionId: question.questionId,
                    choiceId: answeredChoice.choiceId
                });
            }
        });
        return choices;
    }
}
