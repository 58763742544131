/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClientSearchTypeStatus, EntityDropdownEsResult, EntityDropdownEsTypes, SourceSystem } from '@sei/client-account-administration-lib-ux';
import { DropdownItem } from '@sei/common-components-lib-ux';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { OrderedSet } from 'immutable';
import { ContactTypes, ElasticControlType } from '../../model/enums';
import { ElasticSearchService } from '../../service/elastic-search.service';

@Component({
    selector: 'sei-car-entity-search',
    templateUrl: './entity-search.component.html'
})

export class EntitySearchComponent extends ParentComponentSubscriptionManager implements OnInit {
    public entityControlTypeEnum = ElasticControlType.Contacts;
    public selectedName: string;
    public selectedItems: OrderedSet<DropdownItem>;
    public processingOrgId: number;
    public sourceSystem: SourceSystem[];
    public entityTypes: EntityDropdownEsTypes[];
    public clientStatusOptions: ClientSearchTypeStatus[];
    public searchMinimumCharacters: number = 2;

    @Input()
    public controlType: string;

    @Input()
    public contactType: ContactTypes = ContactTypes.Individual;

    @Input()
    public multiSelect: boolean = false;

    @Input()
    public firmId: number;

    @Input()
    public limitResults: number = 50;

    @Input()
    public additionalActionLink: string = 'Create New';

    @Input()
    public placeholder: string = 'Start typing a name';

    @Output()
    public onEntitySelect = new EventEmitter();

    @Output()
    public onNewEntityName = new EventEmitter();

    @Output()
    public onError = new EventEmitter();

    constructor(
        private elasticSearchService: ElasticSearchService
    ) {
        super('EntitySearchComponent');
    }

    public ngOnInit() {
        this.initializeComponent();
    }

    public onControlSelect(eventData: Set<EntityDropdownEsResult>) {
        const searchItem: EntityDropdownEsResult = eventData.values().next()
            .value;

        if (this.controlType === ElasticControlType.Contacts) {
            this.elasticSearchService
                .getThirdPartyContactInformationByContactTypeStreaming(
                    searchItem.entity.entityId,
                    searchItem.entity.searchType.type,
                    this.contactType
                )
                .subscribe(
                    (elasticSearchResult) => {
                        this.onEntitySelect.emit(elasticSearchResult);
                    },
                    (error) => {
                        this.onError.emit(error.message);
                    }
                );
        } else {
            this.onEntitySelect.emit(searchItem);
        }
    }

    public onControlSelectCounterParty(eventData: OrderedSet<DropdownItem>) {
        this.selectedItems = eventData;
    }

    public onNewEntityItem(eventData: string) {
        this.onNewEntityName.emit(
            this.elasticSearchService.createSimpleProspectThirdPartyInformation(
                eventData,
                this.contactType
            )
        );
    }

    private initializeComponent() {
        switch (this.controlType) {
            case ElasticControlType.Contacts: {
                this.sourceSystem = this.elasticSearchService.getContactsSourceSystemOptions();
                this.entityTypes = this.elasticSearchService.getContactsSearchEntityTypesOptions();
                this.clientStatusOptions = this.elasticSearchService.getContactsClientSearchTypeStatus();
                break;
            }
            case ElasticControlType.Accounts: {
                this.sourceSystem = this.elasticSearchService.getAccountsSourceSystemOptions();
                this.entityTypes = this.elasticSearchService.getAccountsSearchEntityTypesOptions();
                this.clientStatusOptions = this.elasticSearchService.getContactsClientSearchTypeStatus();
                delete this.additionalActionLink;
                break;
            }
            case ElasticControlType.PendingAccounts: {
                this.sourceSystem = this.elasticSearchService.getPendingAccountsSourceSystemOptions();
                this.entityTypes = this.elasticSearchService.getPendingAccountsSearchEntityTypesOptions();
                this.clientStatusOptions = this.elasticSearchService.getContactsClientSearchTypeStatus();
                delete this.additionalActionLink;
                break;
            }
            case ElasticControlType.CounterParty: {
                this.processingOrgId = this.elasticSearchService.getCounterPartyProcessingOrg();
                break;
            }
            case ElasticControlType.Clients: {
                this.sourceSystem = this.elasticSearchService.getClientSearchSystemEntityTypesOptions();
                this.entityTypes = this.elasticSearchService.getClientsSearchEntityTypesOptions();
                this.clientStatusOptions = this.elasticSearchService.getContactsClientSearchTypeStatus();
                break;
            }
        }
    }
}
