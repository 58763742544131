/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DataSharingService } from '@sei/advisor-client-review-proposal-ux';
import { HtmlElementType, HudInfoText, InputPropertyType, ProposalSectionLabel } from '../../../../model/enums';
import { ProposalSection, SelectOption } from '../../../../model/presentation';

@Component({
    selector: 'sei-car-html-form-elements',
    templateUrl: './html-form-elements.component.html'
})
export class HtmlFormElementsComponent {

    public proposalSectionLabel: typeof ProposalSectionLabel = ProposalSectionLabel;

    @Input()
    public dropDownContent: SelectOption[] = [];

    @Input()
    proposalSection: ProposalSection;

    @Input()
    disableInput: boolean = false;

    @Input()
    presentationType: string;

    @Input()
    coverTitleDisabled: boolean = false;

    @Input()
    showRevertOption: boolean = false;

    @Input()
    disableRevertOption: boolean = false;

    @Input()
    selectWidth: number;

    @Output()
    public htmlFormElementSelected = new EventEmitter();

    @Output()
    public dropdownSelected: EventEmitter<ProposalSection> = new EventEmitter();

    public elementTypes: typeof HtmlElementType = HtmlElementType;

    public inputPropertyType: typeof InputPropertyType = InputPropertyType;
    public tooltipText: string = HudInfoText.ResetFirmDefault;

    constructor(private dataSharingService: DataSharingService) { }

    public optionSelected(event): void {
        /*
            Only emit event after option change if it is not Brand Logo, and not already the current section
            due to Brand Logo Upload changing the dropdown value and firing event automatically
         */
        const currentBrandLogoSectionId: number = this.dataSharingService.proposalSectionChanged?.getValue()
            ?.sections.find((subsection: ProposalSection) => subsection.id === this.proposalSection.id)?.id;
        if (this.proposalSection.name !== ProposalSectionLabel.BrandLogo || currentBrandLogoSectionId) {
            this.htmlFormElementSelected.emit(this.proposalSection);
        }
        this.proposalSection.inputProperty.value = event;
        this.dropdownSelected.emit(this.proposalSection);
    }
    public revertToDefaultValue(): void {
        if (this.proposalSection.defaultProperties &&
            this.proposalSection.defaultProperties.defaultValue) {
            this.proposalSection.inputProperty.value = this.proposalSection.defaultProperties.defaultValue;
            this.htmlFormElementSelected.emit(this.proposalSection);
        }
    }

    public onClick(): void {
        this.htmlFormElementSelected.emit(this.proposalSection);
    }
}
