<div class="row risk-tolerance-question">
    <div class="col-xs-12">
        <div class="row">
            <div class="col-xs-12">
                <h2>
                    {{ questionNumber }}.
                    {{ question?.questionText }}
                </h2>
            </div>
            <div class="col-xs-12 text-center"
                 *ngIf="questionNumber === questionEnum.QuestionNumberThree && !accountType">
                <sei-car-alt-source-image [class]="'img-responsive'"
                                          [fileName]="questionEnum.QuestionImageName">
                </sei-car-alt-source-image>
            </div>
            <div class="col-xs-12 text-center"
                 *ngIf="questionNumber === questionEnum.QuestionNumberTen && accountType">
                <sei-car-alt-source-image [class]="'img-responsive'"
                                          [fileName]="questionEnum.QuestionImageName">
                </sei-car-alt-source-image>
            </div>
        </div>
        <div class="row box-container">
            <div class="col-xs-12 car-main-sm-body-copy">
                <div *ngFor="let choice of question?.investorChoices">
                    <div class="row">
                        <div class="radio col-xs-12">
                            <label class="car-default-cursor">
                                <input type="radio"
                                       [value]="choice?.choiceId"
                                       [name]="question?.questionId"
                                       [ngModel]="optionSelectedId"
                                       (ngModelChange)="optionSelectedChange($event)"
                                       checked />
                                {{ choice?.investorChoice }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
