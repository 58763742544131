<div class="wip-summary-icon menu-caret"
     [hidden]="sectionType !== sectionLabels.ScenarioGoal">
    <a (click)="onShowDetailClick()">
        <i class="fas"
           [class.fa-chevron-down]="showDetail"
           [class.fa-chevron-right]="!showDetail"></i>
    </a>
</div>
<div class="wip-summary-icon"
     *ngIf="wipCheckList.mdcSnapShot.completed === wipAccountStatus.New
             && sectionType !== sectionLabels.CurrentScenario
             && sectionType !== sectionLabels.ProposalScenario
             && sectionType !== sectionLabels.ScenarioGoal"
     (click)="onSkipClick()">
    Skip
</div>
<car-wip-checklist-action-button [buttonDisabled]="buttonDisabled"
                                 [wipCheckList]="wipCheckList"></car-wip-checklist-action-button>
