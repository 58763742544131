<p *ngIf="showCopyOption">
    Answer the questions or
    <a containerClass="action-tooltip-popover"
       placement="bottom"
       container="body"
       triggers="manual"
       [popover]="copy_tooltip_template"
       [isOpen]="showCopyPopover"
       (click)="onCopyToggleClick()"
       #pop="bs-popover">copy</a>
    from another account.
</p>

<ng-template #copy_tooltip_template>
    <div class="info-tooltip-panel">
        <div class="row">
            <div class="col-xs-12 car-main-sm-body-copy form-group copy-text">
                Select account to copy answers from
            </div>
            <div class="col-xs-12 form-group">
                <sei-dropdown [items]="completedAccounts"
                              [ngModelOptions]="{standalone: true}"
                              [placeholder]="'Select Account'"
                              (ngModelChange)="onAccountToCopySelected($event)"
                              [(ngModel)]="accountToCopy">
                </sei-dropdown>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 car-main-sm-body-copy form-group">
                <sei-button class="btn-in-tooltip"
                            buttonText="Cancel"
                            buttonLeftFontAwesomeIcon="fas fa-times-circle"
                            buttonClass="btn-default"
                            (buttonClicked)="onCopyToggleClick()">
                </sei-button>
                <sei-button class="btn-in-tooltip"
                            buttonText="Copy"
                            buttonRightFontAwesomeIcon="fas fa-plus-circle copy-button-icon"
                            buttonClass="btn-default"
                            [buttonDisabled]="accountToCopyId === 0"
                            (buttonClicked)="onConfirmCopyClick()">
                </sei-button>
            </div>
        </div>
    </div>
</ng-template>

<sei-car-questionnaire-question [questions]="questionnaire"
                                [accountType]="account?.type.qualifiedPlan"></sei-car-questionnaire-question>

<sei-car-questionnaire-footer></sei-car-questionnaire-footer>
