<div *ngIf="isCurrentAccountsEnabled(); then all_accounts else proposed"></div>
<ng-template #proposed>
    <div class="wip-summary-row sectionhead wip-header-row wip-checklist-section-header wip-checklist-section-header-bg-gray"
         [class.wip-checklist-section-header-collapsed]="sectionHidden || !wipCheckList.length">
        <div class="wip-summary-left subhead"
             id="proposed-accounts-header">
            <h4>{{ accountTypeLabel }}</h4>
            <span *ngIf="!isPortfolioAssetDataLoading" class="wip-checklist-section-header-balance">
            <h4>{{ getTotalBalance() | currencySuffix }}</h4>
        </span>
            <span *ngIf="isPortfolioAssetDataLoading" class="portfolio-loading wip-checklist-section-header-balance">
            <sei-loading multiplier="1" [showLoadingIcon]="true"></sei-loading>
        </span>
        </div>
        <div class="wip-summary-right">
            <sei-button *ngIf="showStart"
                        buttonText="Start"
                        buttonRightFontAwesomeIcon="fa-arrow-circle-right"
                        buttonClass="btn-default"
                        [buttonDisabled]="startButtonDisabled"
                        (buttonClicked)="navigateToProposedAccounts()"></sei-button>
            <sei-button *ngIf="!showStart"
                        buttonText="Edit"
                        buttonClass="btn-link"
                        (buttonClicked)="navigateToProposedAccounts()"></sei-button> <span [hidden]="showStart">|</span>
            <a [hidden]="showStart" class="section-arrow" aria-label="Expand Proposed Accounts Section" (click)="isSectionHidden()">
            <i class="fas" [class.fa-chevron-right]="sectionHidden" [class.fa-chevron-down]="!sectionHidden"></i>
        </a>
        </div>
    </div>
    <div [hidden]="sectionHidden">
        <car-wip-checklist-accounts *ngFor="let accountWipCheckList of wipCheckList; let isLast = last"
                                    [class.alert-success]="accountWipCheckList.mdcSnapShot.completed === wipAccountStatus.Complete"
                                    [wipCheckList]="accountWipCheckList"
                                    [proposalId]="proposalId"
                                    [checkListId]="checkListId"
                                    [sectionType]="sectionType"
                                    [isLast]="isLast">
        </car-wip-checklist-accounts>
    </div>
</ng-template>
<ng-template #all_accounts>
    <div class="wip-summary-row sectionhead wip-header-row wip-checklist-section-header wip-checklist-section-header-bg-gray"
         [class.wip-checklist-section-header-collapsed]="sectionHidden || (!hasCurrentAccounts() && !hasProposedAccounts())">
        <div class="wip-summary-left subhead"
             id="proposed-accounts-header">
            <h4>{{ accountTypeLabel }}</h4>
            <span *ngIf="!isPortfolioAssetDataLoading" class="wip-checklist-section-header-balance">
            <h4>{{ getTotalBalance() | currencySuffix }}</h4>
        </span>
            <span *ngIf="isPortfolioAssetDataLoading" class="portfolio-loading wip-checklist-section-header-balance">
            <sei-loading multiplier="1" [showLoadingIcon]="true"></sei-loading>
        </span>
        </div>
        <div class="wip-summary-right">
            <sei-button *ngIf="showStart"
                        buttonText="Start"
                        buttonRightFontAwesomeIcon="fa-arrow-circle-right"
                        buttonClass="btn-default"
                        [buttonDisabled]="startButtonDisabled"
                        (buttonClicked)="navigateToProposedAccounts()"></sei-button>
            <sei-button *ngIf="!showStart"
                        buttonText="Edit"
                        buttonClass="btn-link"
                        (buttonClicked)="navigateToProposedAccounts()"></sei-button> <span [hidden]="showStart">|</span>
            <a [hidden]="showStart" class="section-arrow" aria-label="Expand Proposed Accounts Section" (click)="isSectionHidden()">
                            <i class="fas" [class.fa-chevron-right]="sectionHidden" [class.fa-chevron-down]="!sectionHidden"></i></a>
        </div>
    </div>
    <div [hidden]="sectionHidden">
        <div #proposedAccounts
             *ngIf="hasProposedAccounts()">
            <div class="account-type-subhead"
                 [class.rounded-bottom-borders]="proposedSectionHidden && !hasCurrentAccounts()">
                <h4>Proposed Accounts</h4>
                <a class="section-arrow pull-right" aria-label="Expand Proposed Accounts SubSection" (click)="isProposedSectionHidden()">
                    <i class="fas" [class.fa-chevron-right]="proposedSectionHidden" [class.fa-chevron-down]="!proposedSectionHidden"></i>
                </a>

            </div>
            <car-wip-checklist-accounts *ngFor="let accountWipCheckList of wipCheckList; let isLast = last"
                                        [class.alert-success]="accountWipCheckList.mdcSnapShot.completed === wipAccountStatus.Complete"
                                        [wipCheckList]="accountWipCheckList"
                                        [proposalId]="proposalId"
                                        [checkListId]="checkListId"
                                        [sectionType]="sectionType"
                                        [isLast]="isLast && !hasCurrentAccounts()"
                                        [hidden]="proposedSectionHidden">
            </car-wip-checklist-accounts>
        </div>
        <div #existingAccounts
             *ngIf="hasCurrentAccounts()">
            <div class="account-type-subhead"
                 [class.rounded-bottom-borders]="existingSectionHidden">
                <h4>Existing Accounts</h4>
                <span class="as-of-date" *ngIf="!isClientCallInProgress && dateTimeForPipe"> - Values are as of {{dateTimeForPipe | seiDateTime: format }}</span>
                <a class="section-arrow pull-right" aria-label="Expand Existing Accounts SubSection" (click)="isExistingSectionHidden()">
                <i class="fas" [class.fa-chevron-right]="existingSectionHidden" [class.fa-chevron-down]="!existingSectionHidden"></i></a>
            </div>
            <div *ngIf="isClientCallInProgress && !existingSectionHidden"
                 class="loading-container">
                <span class="portfolio-loading">
                    <sei-loading multiplier="2" [showLoadingIcon]="true"></sei-loading>
                </span>
            </div>
            <div *ngIf="!isClientCallInProgress && !existingSectionHidden">
                <car-wip-checklist-accounts *ngFor="let accountWipCheckList of existingAccountsWipCheckList; let isLast = last"
                                            [class.alert-success]="accountWipCheckList.mdcSnapShot.completed === wipAccountStatus.Complete"
                                            [wipCheckList]="accountWipCheckList"
                                            [proposalId]="proposalId"
                                            [checkListId]="checkListId"
                                            [sectionType]="sectionType"
                                            [showExisting]="true"
                                            [isLast]="isLast"
                                            [hidden]="existingSectionHidden">
                </car-wip-checklist-accounts>
            </div>
        </div>
    </div>
</ng-template>
