/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { Subscription } from 'rxjs';
import { FileUpload, FileUploadDocumentTypes, FileUploadViewMode } from '../../../model/enums';
import { FileUploadFactory } from '../../../model/factory/file-upload-factory';
import { FileUploadForm } from '../../../model/file-upload';
import { ProposalStatement } from '../../../model/presentation';
import { SeiPayload } from '../../../model/sei-payload';
import { UploadFormService } from '../../../service/upload-forms.service';

@Component({
    selector: 'car-file-upload-modal-panel',
    templateUrl: './file-upload-modal-panel.component.html'
})
export class FileUploadModalPanelComponent extends ParentComponentSubscriptionManager implements OnInit {
    public fileUploadForm: FileUploadForm;
    public maxFileAllowedToUpload = FileUpload.MaxFilesAllowedByUpload;
    private subscriptionData: Subscription;
    public brandLogoUpload = FileUploadViewMode.BrandLogo;
    public advisorContentUploadMode = FileUploadViewMode.AdvisorContent;

    @Input()
    public fileUploadViewMode: FileUploadViewMode;

    @Input()
    public statement?: ProposalStatement;

    @Input()
    public proposalId: number;

    @Input()
    public presentationType: string;

    @Input()
    public sectionName: string;

    @Input()
    public subSectionName: string;

    @Output()
    public onCancel = new EventEmitter();

    @Output()
    public onSuccessfullUpload = new EventEmitter<string>();

    @Output()
    public advisorContentUploaded = new EventEmitter<SeiPayload>();

    constructor(
        private uploadFormService: UploadFormService
    ) {
        super('FileUploadModalPanelComponent');
    }

    public ngOnInit() {
        this.initializeForm();

        this.subscriptionData = this.uploadFormService
            .getFileUploadFormStream()
            .subscribe((data) => (this.fileUploadForm = data));
        this.subscriptions.push(this.subscriptionData);
    }

    public initializeForm() {
        this.fileUploadForm = new FileUploadFactory().createObject();
        this.fileUploadForm.fileTypeId = FileUploadDocumentTypes.Others;
        this.uploadFormService.setFileUploadFormStream(this.fileUploadForm);
        this.uploadFormService.validateData(this.fileUploadForm);
    }

    public onCancelEvent(evt) {
        this.onCancel.emit();
    }

    public onSuccessfullUploadEvent(evt) {
        this.onSuccessfullUpload.emit(evt);
    }

    public advisorContentUpload(evt) {
        this.advisorContentUploaded.emit(evt);
    }
}
