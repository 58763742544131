/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { CreateDocumentRequest, TradeCreateDocumentResponse, TradeDocumentStatusResponse } from '@CarInterfaces/trade';
import { Account } from '@CarInterfaces/account';
import { DocumentRepository } from '@CarRepos/document.repository';
import * as enrollmentActions from '@CarServices/enrollment/state/enrollment.actions';
import * as enrollmentSelectors from '@CarServices/enrollment/state/enrollment.selectors';
import { GlobalService } from '@CarSystem/global.service';
import { Observable } from 'rxjs';
import { EnrollmentState } from './state/enrollment.reducers';

/**
 * Enrollment Service
 */
@Injectable({
    providedIn: 'root'
})
export class EnrollmentService {
    constructor(
        private readonly global: GlobalService,
        private readonly documentRepository: DocumentRepository,
        private readonly enrollmentStore: Store<EnrollmentState>
    ) {}

    /**
     *
     * @param request contains the accountNumber and id's of Additional Forms for document generation
     */
    public generateDocuments(
        request: CreateDocumentRequest
    ): Observable<TradeCreateDocumentResponse> {
        return this.documentRepository.create(request);
    }

    /**
     *
     * @param accountNumber
     */
    public loadDocument(accountNumber: string): Observable<ArrayBuffer> {
        return this.documentRepository.loadDocument(accountNumber);
    }

    /**
     *
     * @param accountNumber
     */
    public getDocumentAvailableStatus(
        accountNumber: string
    ): Observable<TradeDocumentStatusResponse> {
        return this.documentRepository.getStatus(accountNumber);
    }

    public getGeneratingDocumentStream(): Observable<{
        [key: string]: boolean;
    }> {
        return this.enrollmentStore.pipe(
            select(enrollmentSelectors.getGeneratingDocument)
        );
    }

    public getDocumentGeneratedStream(): Observable<{
        [key: string]: boolean;
    }> {
        return this.enrollmentStore.pipe(
            select(enrollmentSelectors.getDocumentGenerated)
        );
    }

    public initializeEnrollmentState(account: Account) {
        // this sets the store correctly for each account group on print dialog
        if (account) {
            const generatingDocuments: { [key: string]: boolean } = {};
            const documentGenerated: { [key: string]: boolean } = {};
            const errors: { [key: string]: string } = {};
            account.accountGroup.accounts.forEach((acc: Account) => {
                generatingDocuments[acc.accountNumber] = false;
                documentGenerated[acc.accountNumber] = false;
                errors[acc.accountNumber] = undefined;
            });

            this.enrollmentStore.dispatch(
                new enrollmentActions.InitializeState({
                    generatingDocuments,
                    documentGenerated
                })
            );
        }
    }

    public redirectToWipDetailsAction(accountNumber: number) {
        if (accountNumber) {
            this.enrollmentStore.dispatch(
                new enrollmentActions.RedirectToWipDetails(accountNumber)
            );
        }
    }

    public generateDocumentAction(
        createDocumentRequest: CreateDocumentRequest
    ) {
        this.enrollmentStore.dispatch(
            new enrollmentActions.GenerateDocuments(createDocumentRequest)
        );
    }

    // TODO, currently we are only hitting display document from print page
    // and use the window.location.href to open the new tab for /displaydocument.  we will eventually
    // need to change this when we are opening documents from a different page.
    // at that point, account number will be used.
    public loadDocumentAction(
        accountNumber: number
    ) {
        this.enrollmentStore.dispatch(
            new enrollmentActions.DocumentLoad()
        );
    }
}
