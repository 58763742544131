/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonSize, WizardModel, WizardState } from '@sei/common-components-lib-ux';
import { ParentComponentSubscriptionManager, SeiEnum } from '@sei/common-swp-components-lib-ux';
import { CommandText, OptionRiskLevel, RiskMethods, RiskToggleOptions, RiskToleranceLevel } from '../model/enums';
import { AccountFactory } from '../model/factory/account-factory';
import { Account, Type } from '../model/proposal';
import { DelegationService } from '../service/delegation.service';
import { QuestionnaireService } from '../service/questionnaire.service';

@Component({
    selector: 'sei-car-risk-selection',
    templateUrl: './risk-selection.component.html'

})
export class RiskSelectionComponent extends ParentComponentSubscriptionManager implements OnInit {

    public riskOptions: Type[];

    minWidth: number = 200;
    buttonSizeOptions: SeiEnum<ButtonSize> = new SeiEnum<ButtonSize>(ButtonSize);
    selectedButtonSize: ButtonSize = ButtonSize.Default;

    public wizardModel: WizardModel[] = [
        { name: 'Low', state: WizardState.ENABLE },
        { name: 'Medium', state: WizardState.ENABLE },
        { name: 'High', state: WizardState.ENABLE }
    ];

    @Input()
    public account: Account;

    @Input()
    public riskToleranceQuestionnaireMessage: string;

    @Output()
    public riskChanged: EventEmitter<void> = new EventEmitter();

    public riskToleranceQuestionnaireMessageShowCloseIcon: boolean = true;
    public riskToleranceQuestionnaireMessageShowOpen: boolean = true;

    public selectedRiskItem: Type;
    public selectedRisk: boolean = false;
    public riskToleranceLevel: RiskToleranceLevel;
    public optionRiskLevel: typeof OptionRiskLevel = OptionRiskLevel;

    constructor(private questionnaireService: QuestionnaireService,
        private delegationService: DelegationService) {

        super('RiskSelectionComponent');
        this.riskOptions = [];

        this.riskOptions.push({ id: 1, description: 'Complete Questionnaire' });
        this.riskOptions.push({ id: 2, description: 'Select Low / Medium / High Tolerance' });
        this.riskOptions.push({ id: 3, description: 'Skip' });

        if (this.account && !this.account.riskTolerance) {
            this.riskOptions.pop();
        }
    }

    ngOnInit(): void {
        this.initRiskTolerance();
        this.initOptionRisk();
        this.validateRiskTolerance();

        this.questionnaireService.getQuestionSelectedUpdated().subscribe(() => {
            if (this.questionnaireService.isQuestionnaireComplete()
                || this.questionnaireService.isQuestionnaireInProgress()) {
                this.enableDisableSaveButton(true);
                this.riskChanged.emit();
            }
        });
    }

    private initRiskTolerance(): void {
        if (
            this.account &&
            !this.account.riskTolerance
        ) {
            this.riskToleranceReset();
        }

        if (
            this.account &&
            this.account.riskTolerance &&
            this.account.riskTolerance.selectedLowMedHighRisk
        ) {
            this.initToggleRisk(this.account.riskTolerance.selectedLowMedHighRisk);
        }
    }

    private riskToleranceReset(): void {
        this.account.riskTolerance = new AccountFactory().createRiskTolerance();
    }

    private initOptionRisk(): void {
        if (
            this.account &&
            this.account.riskTolerance &&
            this.account.riskTolerance.riskMethod
        ) {
            this.selectedRiskItem = this.riskOptions.find((option) => option.id === this.account.riskTolerance.riskMethod);
            this.selectedRisk = true;
        }
    }

    private initToggleRisk(riskLevel): void {
        this.wizardModel.filter(
            (option) => {
                if (option.name === RiskToleranceLevel[riskLevel]) {
                    option.state = WizardState.ACTIVE;
                } else {
                    option.state = WizardState.ENABLE;
                }
            }
        );
    }

    public onSelectionChange(data): void {
        this.selectedRiskItem = data;
        this.selectedRisk = true;

        switch (data.id) {
            case OptionRiskLevel.LowMediumHigh:
                this.account.riskTolerance.riskMethod = RiskMethods.LowMediumHigh;
                break;
            case OptionRiskLevel.Questionnaire:
                this.account.riskTolerance.riskMethod = RiskMethods.Questionnaire;
                this.account.riskTolerance.fillQuestionnaire = this.questionnaireService.isQuestionnaireComplete();
                this.account.riskTolerance.calculatedQuestionnaireRisk = this.questionnaireService.getScore();
                break;
            case OptionRiskLevel.Skip:
                this.account.riskTolerance.riskMethod = RiskMethods.Skip;
                this.delegationService.publish(CommandText.ChangeRiskCapacity);
                break;
            default:
                delete this.account.riskTolerance.riskMethod;
                break;
        }
        this.validateRiskTolerance();
    }

    public onSelectedRiskClick(): void {
        this.selectedRisk = false;
    }

    public onWizardStepselect(event): void {
        if (this.selectedRiskItem.id === OptionRiskLevel.LowMediumHigh) {

            this.wizardModel.forEach((option) => {
                if (option.name === event.name) {
                    option.state = WizardState.ACTIVE;
                } else {
                    option.state = WizardState.ENABLE;
                }
            });

            switch (event.name) {
                case RiskToggleOptions.Low:
                    this.account.riskTolerance.selectedLowMedHighRisk = RiskToleranceLevel.Low;
                    break;
                case RiskToggleOptions.Medium:
                    this.account.riskTolerance.selectedLowMedHighRisk = RiskToleranceLevel.Medium;
                    break;
                case RiskToggleOptions.High:
                    this.account.riskTolerance.selectedLowMedHighRisk = RiskToleranceLevel.High;
                    break;
            }

            this.validateRiskTolerance();
            this.delegationService.publish(CommandText.ChangeRiskCapacity);
        }
    }

    private validateRiskTolerance(): void {
        if (!this.account) {
            return;
        }
        switch (this.account.riskTolerance.riskMethod) {
            case RiskMethods.LowMediumHigh:
                this.enableDisableSaveButton(this.account.riskTolerance && this.account.riskTolerance.selectedLowMedHighRisk > 0);
                break;
            case RiskMethods.Questionnaire:
                this.enableDisableSaveButton(this.questionnaireService.isQuestionnaireComplete());
                break;
            case RiskMethods.Skip:
                this.enableDisableSaveButton(true);
                break;
            default:
                this.enableDisableSaveButton(false);
                break;
        }
        this.riskChanged.emit();
    }

    private enableDisableSaveButton(enableButton: boolean = false) {
        if (enableButton) {
            this.delegationService.publish(CommandText.EnableSaveAndExitButton);
        } else {
            this.delegationService.publish(CommandText.DisableSaveAndExitButton);
        }
    }
}


