/*
 Copyright: This information constitutes the exclusive property of SEI
 Investments Company, and constitutes the confidential and proprietary
 information of SEI Investments Company.  The information shall not be
 used or disclosed for any purpose without the written consent of SEI
 Investments Company.
*/

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DisplayNullOptions, EditPercentOptions, EditQuantityOptions } from '@sei/common-components-lib-ux';
import { ParentComponentSubscriptionManager, YesNo } from '@sei/common-swp-components-lib-ux';
import { ToggleButton } from '../../common/toggle-button/toggle-button';
import { AdvisorFee, FeeGroup } from '../../model/account-fee';
import { AlertPopoverTypes, FeeTypesOptions } from '../../model/enums';
import { AdvisorFeeFactory } from '../../model/factory/advisor-fee-factory';
import { FeeScheduleGroupService } from '../../service/fee-schedule-group.service';


@Component({
    selector: 'sei-car-fee-schedule-flat-fee',
    templateUrl: './fee-schedule-flat-fee.component.html'

})
export class FeeScheduleFlatFeeComponent extends ParentComponentSubscriptionManager implements OnInit, OnDestroy {
    @Input()
    public advisorFee: AdvisorFee;
    @Input()
    public feeGroup: FeeGroup;
    @Input()
    public scenarioId: number;
    @Output()
    public onCanceFlatFeelEvent = new EventEmitter();
    @Output()
    public onDoneFeeRibbonEvent: EventEmitter<AdvisorFee> = new EventEmitter();

    public editPercentValid: boolean = true;

    public editPercentError: string;

    public isDefaultSelected: boolean = false;

    public toggleOptions: Array<ToggleButton<FeeTypesOptions>> = [
        {
            label: '%',
            data: FeeTypesOptions.Percentage
        },
        {
            label: 'BPS',
            data: FeeTypesOptions.Bps
        }
    ];
    public toggleYesNoOptions: Array<ToggleButton<YesNo>> = [
        {
            label: 'Yes',
            data: 1
        },
        {
            label: 'No',
            data: 2
        }
    ];

    public flatFeeRate: number = 0;
    public bps: number;
    public defaultToggle: number = 1;
    public feeScheduleSetting: number;
    public feeTypesOptions: typeof FeeTypesOptions = FeeTypesOptions;
    public flatFeeValid: boolean = true;
    public flatFeeErrorMessage: string;
    public alertPopoverTypes: typeof AlertPopoverTypes = AlertPopoverTypes;
    public percentOptions: EditPercentOptions;
    public quantityOptions: EditQuantityOptions;

    constructor(public feeScheduleGroupService: FeeScheduleGroupService,
        public activatedRoute: ActivatedRoute) {
        super('FeeScheduleFeeRateComponent');
    }

    public ngOnInit(): void {
        this.defaultToggle = this.advisorFee.isDefault ? YesNo.Yes : YesNo.No;
        this.feeScheduleSetting = this.advisorFee.scheduleSettings ? FeeTypesOptions.Bps : FeeTypesOptions.Percentage;

        this.setInitRate();

        this.percentOptions = {
            minValueAllowed: '0',
            nullDisplay: DisplayNullOptions.ZERO,
            maxDecimalPlaces: 2,
            maxValueAllowed: '3'
        };

        this.quantityOptions = {
            minValueAllowed: '0',
            nullDisplay: DisplayNullOptions.ZERO,
            maxDecimalPlaces: 0,
            maxValueAllowed: '300'
        };
    }

    public setInitRate(): void {
        if (this.feeScheduleSetting === FeeTypesOptions.Bps) {
            // Round to integer in case of BPS
            this.bps = Math.round(this.advisorFee.flatFeeRate * 10000);
        } else {
            // Round to 2 digits in case of %
            this.flatFeeRate = Number((this.advisorFee.flatFeeRate * 100).toFixed(2));
        }
    }

    public onTogglePercentBPSChange(event): void {
        this.feeScheduleSetting = event;
        this.resetErrors();

        if (this.feeScheduleSetting === FeeTypesOptions.Bps) {
            this.bps = this.flatFeeRate * 100;
        } else {
            this.flatFeeRate = this.bps / 100;
        }
    }

    public onToggleChangeYesNO(event): void {
        this.isDefaultSelected = (+event === YesNo.Yes);
    }

    public onCancelEventRibbon(): void {
        this.onCanceFlatFeelEvent.emit();
    }

    public onDoneFlatFeeRibbon(): void {
        if (!this.editPercentError && !this.flatFeeErrorMessage) {
            this.advisorFee = new AdvisorFeeFactory().createObject(this.advisorFee.isNew);
            this.advisorFee.isDefault = this.isDefaultSelected;
            this.advisorFee.scheduleSettings = this.feeScheduleSetting === FeeTypesOptions.Bps;

            if (this.feeScheduleSetting === FeeTypesOptions.Bps) {
                this.advisorFee.flatFeeRate = this.bps / 10000;
            } else {
                this.advisorFee.flatFeeRate = this.flatFeeRate / 100;
            }

            this.onDoneFeeRibbonEvent.emit(this.advisorFee);
        }

    }

    private resetErrors(): void {
        this.editPercentError = undefined;
        this.editPercentValid = true;
        this.flatFeeErrorMessage = undefined;
        this.flatFeeValid = true;
    }

    public onRateValueChange(event): void {
        this.feeScheduleSetting === FeeTypesOptions.Percentage ?
            this.flatFeeRate = Number(event.target.value.replace('%', '')) : this.bps = Number(event.target.value);
    }

    public onPercentError(errors: string[]) {
        if (errors.length > 0) {
            this.editPercentError = errors[0];
            this.editPercentValid = false;
        } else {
            this.editPercentError = undefined;
            this.editPercentValid = true;
        }
    }

    public onQuantityError(errors: string[]) {
        if (errors.length > 0) {
            this.flatFeeErrorMessage = errors[0];
            this.flatFeeValid = false;
        } else {
            this.flatFeeErrorMessage = undefined;
            this.flatFeeValid = true;
        }
    }
}
