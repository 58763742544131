/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import {
    investmentTypeOptionsData,
    InvestmentTypeOptionSettings,
    WipSectionSettings
} from '@CarModels/investment-type-options';
import { WipSections } from '@CarServices/wip/wip-enums';

@Injectable({
    providedIn: 'root'
})
export class InvestmentTypeOptionsService {
    public getOptions(
        investmentProgramId: number
    ): InvestmentTypeOptionSettings {
        return investmentTypeOptionsData.find(
            (option) => option.investmentProgramId === investmentProgramId
        );
    }

    public isDisableWipActions(
        investmentProgramId: number,
        wipSection: WipSections
    ) {
        const wipSectionSettings = this.getWipSectionSettings(
            investmentProgramId,
            wipSection
        );

        if (!wipSectionSettings) {
            return false;
        }

        return wipSectionSettings && wipSectionSettings.disableWipActions
            ? wipSectionSettings.disableWipActions
            : false;
    }

    private getWipSectionSettings(
        investmentProgramId: number,
        wipSection: WipSections
    ): WipSectionSettings {
        let wipSectionSettings: WipSectionSettings;

        const investmentTypeOption = this.getOptions(investmentProgramId);

        if (investmentTypeOption && investmentTypeOption.wipSectionSettings) {
            wipSectionSettings = investmentTypeOption.wipSectionSettings.find(
                (section) => section.sectionId === wipSection
            );
        }

        return wipSectionSettings;
    }
}
