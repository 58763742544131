/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ParentComponentSubscriptionManager, YesNo } from '@sei/common-swp-components-lib-ux';
import { ToggleButton } from '../common/toggle-button/toggle-button';
import { EntitySuffix } from '../model/contact';
import { CommandText, FirmAdvisorDisplayMode, FirmAdvisorLabel, UserLevelEntitlement } from '../model/enums';
import { Proposal } from '../model/proposal';
import { Firm, OfficeLocation, User, UserProfile } from '../model/user-profile';
import { DelegationService } from '../service/delegation.service';
import { FirmAdvisorService } from '../service/firm-advisor.service';
import { ProposalService } from '../service/proposal.service';
import { UserProfileService } from '../service/user-profile.service';

@Component({
    selector: 'car-proposal',
    templateUrl: './proposal.component.html'
})
export class ProposalComponent extends ParentComponentSubscriptionManager
    implements OnInit {
    @Input()
    public proposal: Proposal;

    @Input()
    public suffix: EntitySuffix[];

    @Input()
    public userProfile: UserProfile;

    public displaySection: boolean = false;
    public displayClients: boolean = false;
    public disabledAddClient: boolean;
    public submitted: boolean;
    public isTaxImpactAnalysisToggleDisabled: boolean = true; // Note: Disable for now and wire up until account story is ready.
    public firm: Firm;
    public locationsSelected: OfficeLocation[];
    public advisorsSelected: User[];
    public userLevel: typeof UserLevelEntitlement = UserLevelEntitlement;
    public firmAdvisorLabels: typeof FirmAdvisorLabel = FirmAdvisorLabel;
    public entitledAdvisorsEntityIds: number[] = [];
    public displayModes: typeof FirmAdvisorDisplayMode = FirmAdvisorDisplayMode;
    public previousUserProfile: UserProfile = undefined;
    public previousEntitledAdvisorsEntityIds: number[] = [];
    public toggleYesNoOptions: Array<ToggleButton<YesNo>> = [
        {
            label: 'Yes',
            data: 1
        },
        {
            label: 'No',
            data: 2
        }
    ];

    constructor(
        private delegationService: DelegationService,
        private firmAdvisorService: FirmAdvisorService,
        private proposalService: ProposalService,
        private userProfileService: UserProfileService,
        private router: Router
    ) {
        super('ProposalComponent');
    }

    ngOnInit(): void {
        this.subscriptions.push(this.delegationService.refresh().subscribe((data) => {
            if (data === CommandText.MrdcCanceled) {
                this.userProfile = this.previousUserProfile;
                this.userProfileService.setCurrentUserProfile(this.previousUserProfile);

                this.firm = this.userProfile.firm;
                this.userProfileService.updateCurrentUserProfileFirm(this.firm);
                this.userProfileService.setEtitledAdvisorsEntityIds(this.previousEntitledAdvisorsEntityIds);
                this.entitledAdvisorsEntityIds = this.previousEntitledAdvisorsEntityIds;
                this.userProfile = this.userProfileService.getCurrentUserProfile;
                this.router.navigate(['Proposal/Dashboard']);
            }
        }));
        this.initialize();
    }

    private initialize(): void {
        this.proposalService.clearElasticSearchAccountOwner();
        this.entitledAdvisorsEntityIds = this.userProfileService.getEtitledAdvisorsEntityIds();
        this.displayClientForm();
        this.setFirmFromUserProfile();

        // NOTE: user for cancel button
        this.previousUserProfile = { ...this.userProfile };
        this.previousEntitledAdvisorsEntityIds = Object.assign([], this.entitledAdvisorsEntityIds);
    }

    private setFirmFromUserProfile() {
        this.userProfile = this.userProfileService.getCurrentUserProfile;
        if (this.userProfile && this.userProfile.firm) {
            this.proposal.firm = this.userProfile.firm;
            this.proposal.firmId = this.userProfile.firm.firmId;
        }
    }

    public updateCurrentUserProfile(): void {
        this.userProfileService.updateCurrentUserProfileFirm(this.firm);
        this.setFirmFromUserProfile();
    }

    public displayClientForm(): void {
        if (this.userProfile && this.userProfile.entitlements) {
            this.displayClients = true;
        } else {
            this.displayClients = false;
        }
    }

    public getFirmsAdvisors(firmId?: number): void {
        this.subscriptions.push(
            this.firmAdvisorService
                .getFirmAdvisorsByFirmId(firmId)
                .subscribe((response: Firm) => {
                    if (response) {
                        this.firm = response;
                    }
                })
        );
    }

    public onToggleRiskQuestionnaireChange(event): void {
        this.proposal.completeRiskQuestionnaire = event ? true : false;
    }

    public onToggleTaxAnaylsisChange(event): void {
        this.proposal.completeTaxAnalysis = event ? true : false;
    }

    public onClientsChange(clientsChanged: boolean): void {
        if (clientsChanged && !this.displaySection) {
            this.displaySection = true;
        }
        this.validateProposal();
    }

    public firmSelected(event: Firm): void {
        this.firm = event;
        this.userProfile.firm = event;
        this.updateCurrentUserProfile();
    }

    public locationsUpdated(eventOfficeLocaions: OfficeLocation[]): void {
        this.displayClients = true;
        this.locationsSelected = eventOfficeLocaions;
        this.updateCurrentUserProfile();
    }

    public advisorsUpdated(event: User[]): void {
        this.displayClients = true;
        this.advisorsSelected = event;
        this.updateCurrentUserProfile();
    }

    public validateProposal(): void {
        let clientValid = false;

        if (this.proposal.clients.length > 0) {
            clientValid = this.proposal.clients.every((client): boolean => {
                return (
                    (client.isIndividual &&
                        client.firstName.length > 1 &&
                        client.lastName.length > 1) ||
                    (!client.isIndividual && client.organizationName.length > 1)
                );
            });
        }

        if (clientValid) {
            this.disabledAddClient = false;
            this.submitted = true;
        } else {
            this.disabledAddClient = true;
            this.submitted = false;
        }

        if (clientValid && this.proposal.name.length > 1) {
            this.delegationService.publish(CommandText.EnableScenario);
        } else {
            this.delegationService.publish(CommandText.DisableScenario);
        }
    }

    public firmCleared() {
        this.entitledAdvisorsEntityIds = [];
        this.userProfileService.setEtitledAdvisorsEntityIds(this.entitledAdvisorsEntityIds);
    }
}
