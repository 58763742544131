<div class="car-page-template">
    <div class="container">
        <div *ngIf="showTitle">
            <ng-container *ngTemplateOutlet="carPageTitleRow"></ng-container>
        </div>
        <div class="row">
            <div class="col-xs-12 col-sm-10 col-lg-6">
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</div>

<ng-template #carPageTitleRow>
    <div class="row car-border-bottom-no-padding">
        <div class="col-xs-12 col-sm-10 col-lg-6">
            <h2>{{pageTitle}}</h2>
        </div>
    </div>
</ng-template>
