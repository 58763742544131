<div class="container-fluid">
    <div class="footer-container">
        <div class="row">
            <div class="col-xs-3">
                <div class="pull-left button-container left-section">
                    <sei-car-proposal-presentation-checklist-button [buttonText]="'Back'"
                                                                    [fontAwesomeIcon]="'far fa-arrow-circle-left'"
                                                                    (buttonClicked)="onBackButtonClick()"></sei-car-proposal-presentation-checklist-button>
                </div>
            </div>
            <div class="col-xs-5">
                <div class="pull-right button-container mid-section">
                    <sei-car-checklist-zoom-slider (zoomValueChange)="onZoomValueChange($event)"></sei-car-checklist-zoom-slider>
                    <sei-car-proposal-presentation-checklist-button [buttonText]="'Orientation'"
                                                                    [fontAwesomeIcon]="'far fa-page-break'"
                                                                    (buttonClicked)="onOrientationClick()"></sei-car-proposal-presentation-checklist-button>
                    <sei-car-proposal-presentation-checklist-button [buttonText]="'Fullscreen'"
                                                                    [fontAwesomeIcon]="'far fa-expand-alt'"></sei-car-proposal-presentation-checklist-button>
                </div>
            </div>
            <div class="col-xs-4">
                <div class="pull-right button-container right-section">
                    <sei-car-proposal-presentation-checklist-button [buttonText]="'Save & Exit'"
                                                                    [fontAwesomeIcon]="'far fa-save'"></sei-car-proposal-presentation-checklist-button>
                    <sei-car-proposal-presentation-checklist-button [buttonText]="'Generate Presentation'"
                                                                    [fontAwesomeIcon]="'far fa-arrow-circle-right'"></sei-car-proposal-presentation-checklist-button>
                </div>
            </div>
        </div>
    </div>
</div>
