<section id="clientRiskPreference"
         class="fee-group-account-detail">
    <div class="indent-2">
        <div class="row header">
            <div class="col-sm-2 col-sm-offset-6 copy-xsmall align-center">
                <p>{{hudSummaryLabel.FundExp}}</p>
            </div>
            <div class="col-sm-2 copy-xsmall align-center">
                <!-- Turn off Beautify if saving this file to prevent the below lines from being formatted. Any whitespace will make the line break incorrectly with the icon -->
                <p *ngIf="!showDFS">{{hudSummaryLabel.MgmtFee}}<nobr><sei-car-fee-breakdown-tooltip [managementFeeBreakdown]="managementFeeBreakdown"></sei-car-fee-breakdown-tooltip></nobr></p>
                <p *ngIf="showDFS">{{hudSummaryLabel.MgmtDFS}}<nobr><sei-car-fee-breakdown-tooltip [managementFeeBreakdown]="managementFeeBreakdown"></sei-car-fee-breakdown-tooltip></nobr></p>
            </div>
            <div class="col-sm-2 copy-xsmall align-center">
                <p>{{hudSummaryLabel.PlatformFee}}</p>
            </div>
        </div>
        <div class="row"
             *ngFor="let strategyFee of strategyFees; let i = index">
            <div class="col-sm-4">
                <span class="subhead">{{strategyFee.name}}</span>
            </div>
            <div class="col-sm-2 align-center"
                 *ngIf="strategyFee.balance">
                <span>{{strategyFee.balance | currencySuffix }}</span>
            </div>
            <div class="col-sm-2 align-center"
                 *ngIf="!strategyFee.balance">
                &mdash;
            </div>
            <div class="col-sm-2 align-center"
                 *ngIf="strategyFee.fundExpense">
                {{ getExpense(strategyFee.fundExpense, strategyFee.balance) | proposalFormatPercentWithLessThan:proposalPercentFormatOptions
                }}
            </div>
            <div class="col-sm-2 align-center"
                 *ngIf="!strategyFee.fundExpense">
                &mdash;
            </div>
            <div class="col-sm-2 align-center"
                 *ngIf="strategyFee.managementFee || strategyFee.dfsFee">
                {{ getExpense(strategyFee.managementFee, strategyFee.balance) | proposalFormatPercentWithLessThan:proposalPercentFormatOptions }}
            </div>
            <div class="col-sm-2 align-center"
                 *ngIf="!strategyFee.managementFee && !strategyFee.dfsFee">
                &mdash;
            </div>
            <div class="col-sm-2 align-center"
                 *ngIf="strategyFee.platformFee">
                {{ getExpense(strategyFee.platformFee, strategyFee.balance) | proposalFormatPercentWithLessThan:proposalPercentFormatOptions }}
            </div>
            <div class="col-sm-2 align-center"
                 *ngIf="!strategyFee.platformFee">
                &mdash;
            </div>
        </div>
        <div class="row"
             *ngFor="let portfolio of portfolios; let i = index">
            <div class="col-sm-4">
                <span class="subhead">{{portfolio.portfolioName}} <i *ngIf="isClientDirected(portfolio)" class="fas fa-info-circle text-info sei-margin-left" [tooltip]="getTooltipMessage()"></i> </span>
            </div>
            <div class="col-sm-2 align-center"
                 *ngIf="portfolio.fees.balance">
                <span>{{portfolio.fees.balance | currencySuffix }}</span>
            </div>
            <div class="col-sm-2 align-center"
                 *ngIf="!portfolio.fees.balance">
                &mdash;
            </div>
            <div class="col-sm-2 align-center"
                 *ngIf="portfolio.fees.fundExpense">
                {{ getExpense(portfolio.fees.fundExpense, portfolio.fees.balance) | proposalFormatPercentWithLessThan:proposalPercentFormatOptions
                }}
            </div>
            <div class="col-sm-2 align-center"
                 *ngIf="!portfolio.fees.fundExpense">
                &mdash;
            </div>
            <div class="col-sm-2 align-center"
                 *ngIf="portfolio.fees.managementExpense || portfolio.fees.dfsFee">
                {{ getExpense(portfolio.fees.managementExpense, portfolio.fees.balance) | proposalFormatPercentWithLessThan:proposalPercentFormatOptions }}
            </div>
            <div class="col-sm-2 align-center"
                 *ngIf="!portfolio.fees.managementExpense && !portfolio.fees.dfsFee">
                &mdash;
            </div>
            <div class="col-sm-2 align-center"
                 *ngIf="portfolio.fees.advisorPlatformFee || portfolio.fees.clientPlatformFee">
                {{ getExpense(portfolio.fees.advisorPlatformFee + portfolio.fees.clientPlatformFee, portfolio.fees.balance) |
                    proposalFormatPercentWithLessThan:proposalPercentFormatOptions
                }}
            </div>
            <div class="col-sm-2 align-center"
                 *ngIf="!portfolio.fees.advisorPlatformFee && !portfolio.fees.clientPlatformFee">
                &mdash;
            </div>
        </div>
        <div class="row align-right">
            <div class="col-sm-12 box">
                Balance: {{totalBalance | currencySuffix }}
            </div>
        </div>
        <div class="row box-bottom hidden">
            <div class="col-sm-12 wip-summary-left copy-xsmall">
                *Advisory Firm will pay platform fee cost
            </div>
        </div>
    </div>
</section>
