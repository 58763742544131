<div class="questionnaire-container">
    <div *ngIf="questions?.length > 0">
        <div class="question-container"
             *ngFor="let question of questions; let i = index">
            <sei-car-questionnaire-question-selection [questionNumber]="i + 1"
                                                      [question]="question"
                                                      [accountType]="accountType"
                                                      (onAnswerChange)="onAnswerChange($event)"></sei-car-questionnaire-question-selection>
        </div>
    </div>
</div>
