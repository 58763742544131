/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { InvestmentProgram } from '@CarInterfaces/investment-program';
import { GlobalService } from '@CarServices/system/global.service';
import { Observable } from 'rxjs';
import { BaseRepository } from './base-repository';

@Injectable({
    providedIn: 'root'
})
export class InvestmentProgramRepository extends BaseRepository<
    InvestmentProgram
> {
    constructor(protected readonly global: GlobalService) {
        super(global);

        this.returnDataKey = 'investmentPrograms';

        this.endPoint = {
            read: this.global.configService.getEndPoint(
                'investmentProgramsAvailableStrategies'
            )
        };
    }

    /**
     * Gets the Investment Program object from REST for a given account number
     * Should be able to support every need of this.
     * @param accountNumber Account Number to get
     */
    public read(accountNumber: number | string): Observable<InvestmentProgram> {
        this.global.logger.log(
            `Account object requested from carWebService-Account: ${accountNumber.toString()}`
        );
        return this.global.httpUtilityService.doGet<InvestmentProgram>(
            this.global.configService.routeFormatter(this.endPoint.read, {
                key: 'accountNumber',
                value: accountNumber
            }),
            undefined,
            { returnDataKey: this.returnDataKey }
        );
    }
}
