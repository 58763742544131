/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EntityDropdownEsResult } from '@sei/client-account-administration-lib-ux';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { ElasticSearchRelatedPartyResult } from '../../model/elastic-search-related-party';
import { ContactTypes, ContactTypesDescription, ElasticControlType } from '../../model/enums';
import { ClientFactory } from '../../model/factory/client-factory';
import { Client, Type } from '../../model/proposal';
import { ContactTypeUtilityService } from '../../service/contact-type-utility.service';
import { GenericErrorService } from '../../service/system/generic-error.service';

@Component({
    selector: 'sei-car-proposal-add-client',
    templateUrl: './proposal-add-client.component.html'
})
export class ProposalAddClientComponent extends ParentComponentSubscriptionManager implements OnInit {

    @Input()
    public firmId: Number;

    @Input()
    public contactType: Type;

    @Output()
    public onClientAdded: EventEmitter<Client> = new EventEmitter<Client>();

    public client: Client;
    public elasticControlTypes: typeof ElasticControlType = ElasticControlType;

    constructor(
        private readonly errorHandler: GenericErrorService,
        private readonly contactTypeUtilityService: ContactTypeUtilityService
    ) {
        super('ProposalAddClientComponent');
    }

    public ngOnInit(): void {
        this.initializeComponent();
    }

    private initializeComponent(): void {
        if (!this.contactType) {
            this.contactType = { id: ContactTypes.Individual, description: ContactTypesDescription.Individual };
        }
    }

    public onEntitySelect(clientEntity: EntityDropdownEsResult): void {
        const newClient = new ClientFactory().clientFromEntityDropdownEsResult(clientEntity);
        this.onClientAdded.emit(newClient);
    }

    public onNewEntityName(clientEntity: ElasticSearchRelatedPartyResult): void {
        // NOTE: ES is prepending a space to last name for some reason
        const removeFirstSpacesRegex: RegExp = /^\s/g;
        if (clientEntity.attributes.partyName.lastName) {
            clientEntity.attributes.partyName.lastName = clientEntity.attributes.partyName.lastName.replace(removeFirstSpacesRegex, '');
        }
        if (clientEntity.attributes.partyName.firstName === undefined && clientEntity.attributes.partyName.lastName) {
            clientEntity.attributes.partyName.firstName = clientEntity.attributes.partyName.lastName;
            clientEntity.attributes.partyName.lastName = '';
        }

        this.client = new ClientFactory().clientFromElasticSearchRelatedPartyResult(clientEntity);

        if (this.contactTypeUtilityService.isOrganization(this.contactType.id)) {
            this.client.contactType.id = this.contactType.id;
            this.client.contactType.description = this.contactType.description;
            this.client.isIndividual = false;
        }
        this.onAddClient();
    }

    public onAddClient(): void {
        this.client.isNewClient = !(Number(this.client.swpEntityId) > 0);
        this.onClientAdded.emit(this.client);
    }

    public onElasticSearchError(event): void {
        this.errorHandler.setGenericError({ code: '', description: event });
    }
}
