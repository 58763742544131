/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
/* eslint-disable @typescript-eslint/no-shadow */
export enum HttpGrantTypes {
    Password = 'password',
    ClientCredentials = 'client_credentials'
}

export enum EnvironmentMode {
    Dev = 'DEV',
    Qa = 'QA',
    Pilot1 = 'PILOT-1',
    Pilot2 = 'PILOT-2',
    Prod = 'PROD'
}

export enum InvestmentProgramType {
    MutualFunds = 1,
    ManagedAccounts = 2,
    DistributionFocusedStrategies = 3,
    ETF = 4,
    FixedIncome = 5,
    CustomHighNetWorth = 6,
    SeiAmericanFund = 7,
    CustomFirmMutualFunds = 999
}

export enum ElasticControlType {
    Contacts = 'Contacts',
    Accounts = 'Accounts',
    PendingAccounts = 'PendingAccounts',
    CounterParty = 'CounterParty'
}

export enum EntityType {
    Prospect = 'Prospect',
    Client = 'Client',
    ThirdParty = 'Third Party'
}

export enum ElasticSearchResultType {
    Contact = 1,
    ThirdParty = 2,
    Search = 3
}

export enum ContactTypes {
    Individual = 1,
    Organization = 2
}

export enum ContactTypesDescription {
    Individual = 'Individual',
    Organization = 'Organization'
}

export enum DocumentTypes {
    Account,
    Contact
}

export enum OptionActions {
    None,
    Change = 1,
    Delete = 2
}

export enum OptionSelectorTextType {
    Sublabel = 1,
    RadioSelection = 2
}

// collides with interface AddressType
export const enum AddressTypes {
    Legal = 1,
    Mailing
}

export enum OwnerRole {
    None,
    Primary,
    Joint
}

export enum JointOwner {
    Min = 1,
    Max = 5
}

export enum OwnerCitizenshipType {
    CitizenAndLegal = 1,
    CitizenAndNotLegal = 2,
    NotCitizen = 3
}

export enum FundingSourceView {
    InitView,
    EditView,
    CurrentFundingSourceList
}

// collides with interface FundingSourceType
export enum FundingSourceTypes {
    None = 0,
    Check = 1,
    Ach = 2,
    Wire = 3,
    Transfer = 4
}

export enum FundingSourceTransferTypes {
    FullLiquidation = 1,
    FullInKind = 2,
    Partial = 3
}

export enum AchFrequencyType {
    None = 0,
    OneTime = 1,
    Weekly,
    Monthly,
    Quarterly
}

export const enum AchStartDateTypes {
    None = 0,
    OneTimeAsap = 1,
    OneTimeSelectDate = 2,
    WeeklyAsap = 3,
    MonthlyAsap = 9,
    MonthlySelectDate = 12,
    QuarterlyAsap = 13,
    QuarterlySelectDate = 16
}

export enum AchQuarterly {
    MinDate = 0,
    MaxDate = 28
}

export enum FundingDirectionScreens {
    Cash = 1,
    Transfer = 2,
    Dca = 3
}

export enum FundingDirectionType {
    Cash = 1,
    NoCash = 2
}

export enum PrevailingAssumptionTypes {
    Strategy = 1,
    AssetTransit = 2,
    AdvisorGuided = 3,
    ClientDirected = 4,
    FundStrategyAllocation = 5,
    Dca = 6
}

export enum AssetTransitAddType {
    FromPartial = 1,
    Manually = 2
}

// Suffixing these with s, there is variable collisions
export enum DcaScheduleTypes {
    TotalSumByTarget = 1,
    FixedContribution = 2
}

export enum DcaStartDateTypes {
    SpecificDate = 1,
    ImmediatelyReceiptAssets = 2
}

export enum DcaContributionFrequencyTypes {
    Daily = 1,
    Weekly = 2,
    Monthly = 3
}

export enum PaymentOfAdvisorFeesOptions {
    PerFlatRate = 1,
    PerFeeSchedule = 2,
    NoAutomatic = 3,
    PerFlatRateText = 'Automatically deduct per flat rate fee:',
    PerFeeScheduleText = 'Automatically deduct per fee schedule:',
    NoAutomaticText = 'No automatic advisory fee'
}

export enum DividendPaymentOptions {
    PaymentsWillBeReinvestedInTheayingPorfilio = 1,
    DistributeCashDividends = 2,
    PayCashDividendsToTheStrategyMoneyMarket = 3
}

export enum PayToRecipientDistributionOptions {
    Wire = 1,
    Ach = 2
}

export enum PercentageOrBpsOptions {
    Percentage = 'percentage',
    Bps = 'bps',
    PercentageTransfer = 1,
    BpsTransfer = 100
}

export enum FeeScheduleOptions {
    Annual = 'Annual',
    OneTime = 'One-Time',
    Create = 'Create'
}

export enum AmountOptions {
    Percentage = 'Percentage',
    Dollars = 'Dollars'
}

export enum NumberFormats {
    None = 0,
    Numeric = 1,
    Currency = 2
}

export enum DateEnum {
    Sunday = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6
}

// ACCOUNT GOALS
export enum WipGoalSettingOption {
    None = 0,
    AtAccountLevel = 1,
    NotAtAccountLevel = 2
}
// ACCOUNT GOALS

export enum WipDirection {
    None = 0,
    FromInitialToFinal = 1,
    FromFinalToInitial = 2
}

export enum PaymentInformationDistribution {
    FromThisAccount = 1,
    NotFromThisAccount = 2
}

export enum AccountTypeDistributionViaCheck {
    CheckingId = 1,
    SavingsId = 2,
    Checking = 'Checking',
    Savings = 'Savings'
}

export enum DistributionViaCheckOptions {
    Overnight = 1,
    ByWireACH = 2
}

export enum WithholdingElection {
    WithIncome = 1,
    WithoutIncome = 2
}

export enum DistributionTypeOptions {
    Wire = 1,
    Ach = 2
}

export enum AnnualInflation {
    Adjust = 1,
    NotAdjust = 2
}

export enum AdvisorRole {
    PrimaryId = 1,
    Primary = 'Primary',
    AdditionalId = 2,
    Additional = 'Secondary',
    MaxAdvisors = 5
}

export enum FileUploadViewMode {
    AccountOwner = 1,
    PendingAccounts = 2,
    User = 3
}

export enum FileUploadEditSection {
    NewAccount = 1,
    MaintainDocument = 2,
    AdvisorAdmin = 3
}

export enum FileUpload {
    MaxFilesAllowedByUpload = 1
}

export enum FileUploadDocumentTypes {
    BusinessLicense = 1,
    PartnershipAgreement = 2,
    DriverLicense = 3,
    Passport = 4,
    GreenCard = 5,
    TrustDocument = 6,
    CourtCertifiedCopyTestamentary = 7,
    ArticlesIncorporation = 8,
    CertificateIncorporation = 9,
    IrsDetermination = 10,
    CourtOrder = 11,
    OtherGovernmentIssuedId = 12,
    Others = 13
}

export enum DelegationSectionType {
    GeneralOwnerInformation = 1,
    LegalMailingAddress = 2,
    RegulatoryInformation = 3,
    BeneficiaryInformation = 4,
    RelatedPartiesInformation = 5,
    LegalRelatedPartiesAddress = 6,
    RelatedPartiesAdditionalInformation = 7,
    PowersOfAttorneyAgentInformation = 8,
    PowersOfAttorneyContactInformation = 9,
    PowersOfAttorneyRegulatoryInformation = 10
}

export enum AssetControlMode {
    NumberOfShares = 'NumberOfShares',
    PurchaseAmount = 'PurchaseAmount'
}

export enum AssetControlModes {
    NumberOfShares = 1,
    FundingTransferInstruction = 2,
    FundingExceptionTransferInstruction = 3
}

export enum RiskToleranceQuestionInsertImage {
    QuestionNumberThree = 3
}

export enum CommandText {
    AddAdditional = 'Add Additional',
    AddReturn = 'Add Return'
}

export enum DatePickerType {
    DatePicker = 'Datepicker',
    DateRangePicker = 'Daterangepicker'
}

export enum FirstDollarProspectus {
    Email = 1,
    LegalAddress = 2,
    MailingAddress = 3
}

export enum ProxyStatements {
    Email = 1,
    LegalAddress = 2,
    MailingAddress = 3
}

export enum DeliveryStatementsAndPayTos {
    MailingAddress = 1,
    LegalAddress = 2
}

export enum OwnerTypes {
    IndividualAdult = 1,
    IndividualMinor = 2,
    Trust = 3,
    Estate = 4
}

export enum OwnerTypeControl {
    None = 0,
    Toggle = 1,
    DropDownList = 2
}

export enum SortOrder {
    asc = 'asc',
    desc = 'desc',
    none = ''
}

export enum SortColumnName {
    accountName = 'accountName',
    status = 'status',
    advisorName = 'advisorName',
    targetFundingAmount = 'targetFundingAmount',
    statusDate = 'statusDate'
}

export const enum SuitabilityActions {
    Cancel = 1,
    AcknowledgeAndContinue = 2
}

export const enum RiskLevel {
    None = '',
    Low = 'Low',
    Medium = 'Medium',
    High = 'High'
}

export enum ButtonRiskType {
    Risk,
    MinValue
}

export enum StatementFrequency {
    Quarterly = 2,
    Monthly = 1,
    Annually = 3,
    quarterly = 'Quarterly',
    monthly = 'Monthly',
    annually = 'Annually',
    quarterlyId = 2,
    monthlyId = 1,
    annuallyId = 3
}

export enum DeleteAccountPlacement {
    Bottom,
    CloseRight
}

export enum StrategyAttributePathType {
    QuarterlyRebalance = 'Quarterly Rebalance',
    BondPreference = 'Bond Preference'
}

export enum States {
    CA = 5,
    MA = 21,
    NJ = 30,
    NY = 32,
    PA = 38
}

export enum YesNoLabels {
    Yes = 'Yes',
    No = 'No'
}

export enum StatementSummary {
    PlanSponsor = 'PlanSponsor',
    sendQuarterlyToPlanSponsor = 'sendQuarterlyToPlanSponsor',
    PlanTrustee = 'PlanTrustee',
    sendQuarterlyToPlanTrustee = 'sendQuarterlyToPlanTrustee',
    RecordKeeper = 'RecordKeeper',
    sendQuarterlyToRecordKeeper = 'sendQuarterlyToRecordKeeper',
    addToAccountChain = 'addToAccountChain',
    accountChainedTo = 'accountChainedTo',
    deliverStatementsToThirdParty = 'deliverStatementsToThirdParty',
    thirdPartyName = 'thirdPartyName'
}

export enum PreferredSigningMethodTypes {
    InPerson = 1,
    ViaEmail = 2
}

export enum PreferredAuthenticationMethodTypes {
    SmsMessage = 1,
    PhoneCall = 2
}

export enum RelatedPartyStatus {
    Empty = 'EMPTY', // empty or undefined value for a fields
    InValid = 'INVALID' // invalid characters for a field
}

/**
 * This may be related to OwnerRole, though talking to Jining
 * there are far more values than we have there.
 */
export enum PartyTypes {
    Owner = 1,
    JointOwner = 2,
    PrimaryBeneficiary = 3,
    ContingentBeneficiary = 4,
    Attorney = 5,
    Custodian = 6,
    Guardian = 7,
    Conservator = 8,
    Estate = 9,
    Executor = 10,
    Trust = 11,
    Trustee = 12,
    Partnership = 13,
    ControllingParty = 14,
    AuthorizedSigner = 15,
    BeneficialOwner = 16,
    Corporation = 17,
    Spouse = 18,
    Employer = 19,
    Plan = 20,
    PlanSponsor = 21,
    PlanSponsorContact = 22,
    PlanTrustee = 23,
    RecordKeeper = 24,
    BusinessOwnerAndSpouse = 25,
    Employee = 26,
    EmployerContact = 27,
    Witness = 28,
    Employer403b = 29
}

export enum AccountStatuses {
    Unidentified = 0,
    Draft = 1,
    AdvisorApprovingFirst = 2,
    SentForSignatures = 3,
    AdvisorApprovingLast = 4,
    BdApproved = 5,
    ProcessingAtSei = 6,
    NotTracked = 97,
    Opened = 98,
    FullyFunded = 99
}

export enum AccountStatusActionTypes {
    AddToCalendar = 1,
    EditApplicationInline = 2,
    EditESignature = 3,
    AttestBrokerApproved = 4,
    PrintSignedApplication = 5,
    ResendNotification = 6,
    ResubmitForSignatures = 7,
    ReviewAccountInline = 8,
    UploadApplication = 9,
    ViewDetailTransferStatus = 10,
    EditApplicationSide = 11,
    ReviewAccountSide = 12,
    StopTracking = 13,
    UploadTransferForm = 14
}

export enum AccountStatusActionCategories {
    // public static get inline(): string {
    //     return 'inline';
    // }

    // public static get side(): string {
    //     return 'side';
    // }
    inline = 'inline',
    side = 'side'
}

export enum AccountStatusStepTypes {
    Placeholder = 0,
    AwaitingAdvisorApproval = 1,
    AwaitingESignature = 2,
    AwaitingPhysicalSignatures = 3,
    AwaitingBrokerDealApproval = 4,
    ReadyForBrokerDealApproval = 5,
    AwaitingAttestationOfBrokerDealerApproval = 6,
    InProgressAtSei = 7,
    AccountOpenAwaitingFunding = 8,
    Complete = 9
}

export enum AccountStatusStepOwnerTypes {
    Adviser,
    Signer,
    BrokerDealer,
    Sei
}

export enum AccountStatusStepStatuses {
    ESignComplete,
    ESignFailure
}

export enum AccountStatusStepStatusTypes {
    BizError = 1,
    TechError = 2,
    Success = 3,
    Pending = 4,
    InProgress = 5
}

export enum LegalAgreement {
    Custody = 1,
    CoAdvisedClassic = 2,
    CoAdvisedComplete = 3,
    SubAdvised = 4
}

export enum BeneficiaryTypes {
    Tod,
    Ira
}

export enum BeneficiaryDesignationTypes {
    Standard = 1,
    PerCapita = 2,
    PerStirpes = 3,
    ToAllMyChildren = 4,
    LinealDescendantsPerStirpes = 5
}

export enum BeneficiaryPriorityTypes {
    Primary = 1,
    Contingent = 2
}

export enum BeneficiaryIdentificationTypes {
    Named = 1,
    AllMyChildren = 2
}

export enum MaritalStatusTypes {
    Married = 1,
    Single = 2
}

export enum RelationshipTypes {
    Spouse = 1,
    NonSpouse = 2
}

export enum MrdcView {
    Mrdc = 1,
    BlankForm = 2,
    PreassignedAccount = 3
}

export enum ContributionTypes {
    RegularOrSpousal = 3
}

export enum ContributionTaxYearTypes {
    None = 0,
    PriorYear = 1,
    CurrentYear = 2
}

export enum SignerSelectionAction {
    SendToApproval = 1,
    RequestSignature = 2
}

export enum WarningMessageTypes {
    Display,
    Information,
    Partial,
    Error,
    Success
}

export enum StatusCheckStates {
    Pending,
    Processing,
    Complete,
    Fail
}

export enum ImplementModalState {
    ImplementForm,
    ConfirmationPage
}
