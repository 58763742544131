/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@CarComponents/common/common.module';
import { MaterialModule } from '@CarComponents/material/material.module';
import { FundingPriorityGridComponent } from './funding-priority-grid/funding-priority-grid.component';
import { WIPFundingPriorityComponent } from './wip-funding-priority-main/wip-funding-priority-main.component';

@NgModule({
    imports: [CommonModule, MaterialModule],
    declarations: [WIPFundingPriorityComponent, FundingPriorityGridComponent],
    exports: [WIPFundingPriorityComponent]
})
export class WipStrategicFundingPrioritiesModule {}
