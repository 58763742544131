/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
import { Injectable } from '@angular/core';
import { Firm, ProcessingRule } from '@CarInterfaces/user-profile';
import { BusinessProcessingRule } from '@CarServices/processing-rules/business-processing-rule';

@Injectable({
    providedIn: 'root'
})
export class FirmProcessingRulesService {
    // Constants to compare rules against
    private readonly ruleDefinition = {
        allowQualifiedPlans: {
            key: 'AllowQualifiedPlans',
            value: 'Yes'
        },
        allow403b: {
            key: 'Allow403b',
            value: 'Yes'
        }
    };

    // Rule Objects
    private allowQualifiedPlansRule: BusinessProcessingRule;
    private allow403bRule: BusinessProcessingRule;

    constructor() {
        this.initializeService();
    }

    public allowQualifiedPlans(): boolean {
        return this.allowQualifiedPlansRule.ruleTruthy();
    }

    public allow403b(): boolean {
        return this.allow403bRule.ruleTruthy();
    }

    public initializeService(firm?: Firm) {
        let processingRules: ProcessingRule = {};

        if (firm && firm.processingRules) {
            processingRules = firm.processingRules;
        }

        this.allowQualifiedPlansRule = new BusinessProcessingRule({
            key: this.ruleDefinition.allowQualifiedPlans.key,
            comparator: this.ruleDefinition.allowQualifiedPlans.value,
            processingRules
        });

        this.allow403bRule = new BusinessProcessingRule({
            key: this.ruleDefinition.allow403b.key,
            comparator: this.ruleDefinition.allow403b.value,
            processingRules
        });
    }
}
