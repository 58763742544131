/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, share } from 'rxjs/operators';
import { SeiPayload } from '../model/sei-payload';
import { GlobalService } from './system/global.service';

@Injectable({
    providedIn: 'root'
})
export class CarElasticSearchService {
    constructor(private http: HttpClient, protected carGlobal: GlobalService) { }

    public getFirmAdvisors(firmId: number) {
        const apiUrl = this.carGlobal.configService.routeFormatter(
            this.carGlobal.configService.getEndPoint('advisors'),
            [
                {
                    key: 'firmId',
                    value: firmId
                }
            ]
        );

        return this.http.get<SeiPayload>(apiUrl).pipe(
            share(),
            map((response) => {
                if (response && response.data) {
                    return response.data[0].firms[0];
                }
            })
        );
    }
}
