/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

export class Apigee {
    public static AUTH_CODE_URL: string = '/v3/oauth/authorizationcode';
    public static GET_ACCESS_TOKEN_URL: string = '/v3/oauthtoken';
    public static INVALIDATE_ACCESS_TOKEN_URL: string = Apigee.GET_ACCESS_TOKEN_URL;
    public static REFRESH_ACCESS_TOKEN_URL: string = Apigee.GET_ACCESS_TOKEN_URL;

    // header and param keys
    public static KEY_APP_KEY: string = 'AppKey';
    public static KEY_AUTHORIZATION: string = 'Authorization';
    public static KEY_CLIENT_ID: string = 'client_id';
    public static KEY_AUTH_CODE: string = 'code';
    public static KEY_RESPONSE_TYPE: string = 'response_type';
    public static KEY_REDIRECT_URI: string = 'redirect_uri';
    public static KEY_CODE_CHALLENGE: string = 'code_challenge';
    public static KEY_CODE_CHALLENGE_METHOD: string = 'code_challenge_method';
    public static KEY_ACCESS_TOKEN: string = 'access_token';
    public static KEY_GRANT_TYPE: string = 'grant_type';
    public static SEARCH_PARAM_CODE: string = 'code';
    public static SEARCH_PARAM_SCOPE: string = 'scope';

    // options
    public static RESPONSE_TYPE_CODE: string = 'code';
    public static CODE_CHALLENGE_METHOD_PLAIN: string = 'plain';
    public static CODE_CHALLENGE_METHOD_SHA: string = 'S256';
    public static KEY_CODE_VERIFIER: string = 'code_verifier';

    public static GRANT_TYPE_AUTH_CODE: string = 'authorization_code';
    public static GRANT_TYPE_REFRESH_TOKEN: string = 'refresh_token';
    public static GRANT_TYPE_INVALIDATE_TOKEN: string = 'invalidate_token';
}
