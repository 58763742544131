/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, OnInit } from '@angular/core';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { RiskLevels } from '../../model/enums';
import { HudQuestionnaire } from '../../model/questionnaire';
import { QuestionnaireService } from '../../service/questionnaire.service';

@Component({
    selector: 'sei-car-questionnaire-footer',
    templateUrl: './questionnaire-footer.component.html'
})
export class QuestionnaireFooterComponent extends ParentComponentSubscriptionManager implements OnInit {
    public hudQuestionnaire: HudQuestionnaire;

    public constructor(
        private questionnaireService: QuestionnaireService
    ) {
        super('QuestionnaireFooterComponent');
    }

    public ngOnInit(): void {
        this.questionnaireService.getQuestionSelectedUpdated().subscribe(
            () => this.fetchData()
        );
    }

    private fetchData(): void {
        const progressPercent = this.questionnaireService.getPercentQuestionsAnswered();
        const progressBarPercent = progressPercent * 100;
        const score = this.questionnaireService.getScore();
        const riskLevel = this.questionnaireService.isQuestionnaireComplete() ? this.questionnaireService.getRiskLevel() : RiskLevels.None;

        this.hudQuestionnaire = {
            progressBarPercent,
            progressPercent,
            riskLevel,
            score
        };
    }
}
