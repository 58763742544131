/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { Account } from '@CarInterfaces/account';
import { GlobalService } from '@CarServices/system/global.service';
import { Observable } from 'rxjs';
import { BaseRepository } from './base-repository';

/**
 * Account Repository Class to manage Rest communications
 */
@Injectable({
    providedIn: 'root'
})
export class AccountRepository extends BaseRepository<Account> {
    constructor(protected readonly global: GlobalService) {
        super(global);

        this.returnDataKey = 'account';

        this.endPoint = {
            read: this.global.configService.getEndPoint('accountFetch'),
            create: this.global.configService.getEndPoint('accountCreate'),
            update: this.global.configService.getEndPoint('accountSave'),
            delete: this.global.configService.getEndPoint('accountDelete')
        };
    }

    /**
     * Gets the Account object from REST for a given account number
     * @param accountNumber Account Number to get
     */
    public read(accountNumber: number | string): Observable<Account> {
        this.global.logger.log(
            `Account object requested from carWebService-Account: ${accountNumber}`
        );
        return this.global.httpUtilityService.doGet<Account>(
            this.global.configService.routeFormatter(this.endPoint.read, {
                key: 'accountNumber',
                value: accountNumber
            }),
            undefined,
            { returnDataKey: this.returnDataKey }
        );
    }

    /**
     * Creates the Account based on minimum data
     * Does not validate
     * @param account Account Object to Create
     */
    public create(account: Account): Observable<Account> {
        this.global.logger.log(`Account create requested`);

        return this.global.httpUtilityService.doPost<Account, Account>(
            this.endPoint.create,
            account,
            undefined,
            { returnDataKey: this.returnDataKey }
        );
    }

    /**
     * Updates account using httpPut
     * Currently connected to MRDC
     * @param account Account Object To Update
     */
    public update(account: Account): Observable<Account> {
        this.global.logger.log(
            `Account object updated to carWebService-Account: ${account.accountNumber.toString()}`
        );

        return this.global.httpUtilityService.doPut<Account, Account>(
            this.global.configService.routeFormatter(this.endPoint.update, {
                key: 'accountNumber',
                value: account.accountNumber
            }),
            account,
            undefined,
            { returnDataKey: this.returnDataKey }
        );
    }

    /**
     * Updates account using httpPost
     * Future use for rest of WIP
     * @param account Account Object To Update
     */
    public updatePost(account: Account): Observable<Account> {
        this.global.logger.log(
            `Account object updated to carWebService-Account: ${account.accountNumber.toString()}`
        );

        return this.global.httpUtilityService.doPost<Account, Account>(
            this.global.configService.routeFormatter(this.endPoint.update, {
                key: 'accountNumber',
                value: account.accountNumber
            }),
            account,
            undefined,
            { returnDataKey: this.returnDataKey }
        );
    }

    /**
     * Delete an account using httpDelete
     * @param id Account number to delete
     */
    public delete(id: number, account?: Account): Observable<Account> {
        this.global.logger.log(`Account delete requested: ${id}`);

        return this.global.httpUtilityService.doDelete<Account, Account>(
            this.global.configService.routeFormatter(this.endPoint.delete, {
                key: 'accountNumber',
                value: id
            }),
            undefined,
            { returnDataKey: this.returnDataKey },
            account
        );
    }

    /**
     * Makes the given account editable.
     * @param account The account to make editable
     * @returns The account that has been returned to Edit state.
     */
    public editAccount(account: Account): Observable<Account> {
        this.global.logger.log(
            `Account ${account.accountNumber} being made editable.`
        );

        return this.global.httpUtilityService.doPost<Account, Account>(
            this.global.configService.routeFormatter(
                this.endPoint.makeEditable,
                {
                    key: 'accountNumber',
                    value: account.accountNumber
                }
            ),
            undefined
        );
    }
}
