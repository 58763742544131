/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { NgModule } from '@angular/core';
import { DatePipe } from '@angular/common';
import { CommonModule } from '@CarComponents/common/common.module';
import { BeneficiarySelectorComponent } from './beneficiary-selector/beneficiary-selector.component';
import { WIPBeneficiariesDetailComponent } from './wip-beneficiaries-detail/wip-beneficiaries-detail.component';
import { WIPBeneficiariesFooterComponent } from './wip-beneficiaries-footer/wip-beneficiaries-footer.component';
import { WIPBeneficiariesHeaderComponent } from './wip-beneficiaries-header/wip-beneficiaries-header.component';
import { WIPBeneficiaryComponent } from './wip-beneficiaries-main/wip-beneficiaries-main.component';

@NgModule({
    imports: [CommonModule],
    declarations: [
        BeneficiarySelectorComponent,
        WIPBeneficiariesDetailComponent,
        WIPBeneficiariesFooterComponent,
        WIPBeneficiariesHeaderComponent,
        WIPBeneficiaryComponent
    ],
    providers: [DatePipe],
    exports: [
        BeneficiarySelectorComponent,
        WIPBeneficiariesDetailComponent,
        WIPBeneficiariesFooterComponent,
        WIPBeneficiariesHeaderComponent,
        WIPBeneficiaryComponent
    ]
})
export class WipBeneficiariesModule {}
