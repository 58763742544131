<section id="proposalName" class="section-gap">
    <h1>Create New Proposal</h1>


    <div class="row">
        <div class="col-xs-12 form-group">
            <label for="proposalName">Proposal name for your client </label>
            <input type="text" class="form-control" id="proposalName" name="proposalName" [(ngModel)]="proposal.name"
                (ngModelChange)="validateProposal()" required placeholder="e.g. Janes Retirement">
        </div>
    </div>

    <sei-car-select-firm-advisor *ngIf="userProfile?.entitlements.userLevelId === userLevel.PO
    || userProfile?.entitlements.userLevelId === userLevel.Instance" [firm]="proposal.firm"
        [displayMode]="displayModes.Proposal" [entitledAdvisorsEntityIds]="entitledAdvisorsEntityIds"
        (firmSelect)="firmSelected($event)" (locationsUpdated)="locationsUpdated($event)"
        (firmAdvisorSelect)="advisorsUpdated($event)" [firmAdvisorLabel]="firmAdvisorLabels.Firm"
        (firmCleared)="firmCleared()"></sei-car-select-firm-advisor>

    <car-proposal-clients-form *ngIf="displayClients" [proposal]="proposal" [suffix]="suffix"
        [disabledAddClient]="disabledAddClient" (clientsChanged)="onClientsChange($event)"
        (clientsModelChanged)="validateProposal()"></car-proposal-clients-form>

</section>

<section *ngIf="displaySection" id="completeRiskQuestionnaire" class="section-gap" [hidden]="true">
    <div class="row toggle_row">
        <div class="col-12 col-sm-8 col-md-9">
            <p class="small">Select risk questionnaire for your client to complete</p>
            <p class="x-small">Choose “No” to complete risk questionnaire yourself</p>
        </div>
        <div class="col-12 col-sm-4 col-md-3 text-right">
            <car-toggle-button [toggleOptions]="toggleYesNoOptions" [selectedValue]="toggleYesNoOptions[1].data"
                (onChange)="onToggleRiskQuestionnaireChange($event)">
            </car-toggle-button>
        </div>
    </div>
</section>
<section *ngIf="displaySection" [hidden]="true">
    <div class="row title-bar section-gap">
        <div class="col-sm-8"><input type="text" value="Current Scenario"></div>
        <div class="col-sm-4 text-right">
            <h2>Balance N/A</h2>
        </div>
    </div>
    <p class="x-small">Build a more compelling sales story by adding &amp; analyzing current accounts.</p>
</section>
<section *ngIf="displaySection" id="currentAccount" [hidden]="true">
    <button type="button" class="btn add-content unselectable" [disabled]="isTaxImpactAnalysisToggleDisabled"><i
            class="fas fa-plus-circle"></i>Add Current Account</button>
</section>
<section *ngIf="displaySection" id="completeTaxAnalysis" class="section-gap" [hidden]="true">
    <div class="row toggle_row">
        <div class="col-12 col-sm-8 col-md-9">
            <p class="small">Complete Tax Impact Anaylsis</p>
            <p class="x-small">One or more current accounts required</p>
        </div>
        <div class="col-12 col-sm-4 col-md-3 text-right">
            <car-toggle-button [disabled]="isTaxImpactAnalysisToggleDisabled" [toggleOptions]="toggleYesNoOptions"
                [selectedValue]="toggleYesNoOptions[1].data" (onChange)="onToggleTaxAnaylsisChange($event)">
            </car-toggle-button>
        </div>
    </div>
</section>
