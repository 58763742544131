/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { InvestmentSummary } from '@sei/ias-applications-lib-ux';
import { BaseFactoryAbstract } from './base-factory-abstract';

@Injectable()
export class InvestmentSummaryFactory extends BaseFactoryAbstract<InvestmentSummary> {
        constructor() {
                super();
        }
        public createObject(): InvestmentSummary {
                return {
                        proposedInvestmentSummary: {
                                investmentDetails: {
                                        investmentCost: 0,
                                        minimumInvestment: 0,
                                        projectedReturn: 0,
                                        standardDeviation: 0
                                }
                        }

                };
        }
}
