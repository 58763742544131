/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
import { Injectable } from '@angular/core';
import { DevParameters, Global, Logger } from '@sei/common-swp-components-lib-ux';
import { ConfigurationService } from './configuration.service';

@Injectable({
    providedIn: 'root'
})
export class LoggerService extends Logger {
    // Should prevent double reloads when devParameters component changes.
    private devEnvironmentTag = 'DEV';
    private qaEnvironmentTag = 'QA';

    // settings to force devMode on app load.
    private readonly devParameters: DevParameters = {
        angularExtraChecksDevMode: true,
        mockData: false,
        ngrxstoreDevMode: false,
        xsrfCredentialsrequired: false
    };

    constructor(
        protected seiGlobal: Global,
        private config: ConfigurationService
    ) {
        super(seiGlobal);
    }

    // TODO: Once the siteminder is fully integrated we may just want to enable this in DEV but not QA
    public enableDevMode() {
        // Enable Dev mode for DEV and QA
        if (
            this.config.getOption('mode') === this.devEnvironmentTag ||
            this.config.getOption('mode') === this.qaEnvironmentTag
        ) {
            // Force settings so objects show up in DevTools without any of the developers doing something
            this.seiGlobal.reloadWithParams(this.devParameters);
        }
    }

    public logJsonObject(key: string, json) {
        if (this.seiGlobal.isAngularExtraChecksMode()) {
            this.getLogs().push(`Output JSON of: ${key}`);
            if (window.console && !this.disableConsole) {
                // eslint-disable-next-line no-console
                console.log(key, json);
            }
        }
    }

    public showCurrentLog() {
        if (this.seiGlobal.isAngularExtraChecksMode()) {
            if (window.console && !this.disableConsole) {
                // tslint:disable-next-line:no-console
                this.logJsonObject('CurrentLog', this.getLogs());
            }
        }
    }
}
