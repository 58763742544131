/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DisplayNullOptions, EditAmountOptions } from '@sei/common-components-lib-ux';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { FeeSchedule } from '../../model/account-fee';
import { AlertPopoverTypes, CommandText, CurrencyTypes } from '../../model/enums';
import { ErrorMessages } from '../../model/error-messages';
import { DelegationService } from '../../service/delegation.service';

@Component({
    selector: 'sei-car-fee-schedule-tier-ribbon',
    templateUrl: './fee-schedule-tier-ribbon.component.html'
})
export class FeeScheduleTierRibbonComponent extends ParentComponentSubscriptionManager implements OnInit {

    @Input()
    public tier: FeeSchedule;
    @Input()
    public index: number;
    @Input()
    public feeGroupId: number;
    @Input()
    public chargeInputView: boolean;
    @Output()
    public onFeeTierChange: EventEmitter<FeeSchedule> = new EventEmitter();
    @Output()
    public onFeeTierRemove: EventEmitter<number> = new EventEmitter();

    public amountOptions: EditAmountOptions;
    public currencyCode: String;
    public alertPopoverTypes: typeof AlertPopoverTypes = AlertPopoverTypes;
    public balanceErrorMessage: string;

    constructor(private delegationService: DelegationService) {
        super('FeeScheduleTierRibbonComponent');
    }

    public ngOnInit(): void {
        this.balanceErrorMessage = ErrorMessages.clientDisplayInvalidTierAmount();
        this.currencyCode = CurrencyTypes.USD;
        this.amountOptions = {
            minValueAllowed: '0',
            nullDisplay: DisplayNullOptions.ZERO
        };
        this.tier.index = this.index;
        this.tier.slab = this.index + 1;
        this.tier.rate = this.tier.rate * 10000;

        this.validateTierAmount();
        this.delegationService.refresh()
            .subscribe(
                (data: string) => {
                    if (data === CommandText.ValidateFeeTierAmount) {
                        this.validateTierAmount();
                    }
                });
    }

    private validateTierAmount(): void {
        this.tier.isValid = this.tier.highValue > this.tier.lowValue;
    }

    public setMonetaryAmount(event, i): void {
        this.tier.highValue = event;
        this.tier.index = this.index;
        this.tier.slab = this.index + 1;
    }

    public ontierAmountValidation(event) {
        this.validateTierAmount();
        if (this.tier.isValid) {
            this.onFeeTierChange.emit(this.tier);
            this.delegationService.publish(CommandText.ValidateFeeTierAmount);
        }
    }

    public onInputPercentageValueChange(event, index): void {
        // this.tier.percentage = +event.target.value.replace('%', '');
        // // Note: Using SEI directive to handle display. Backend works in decimal
        // if (this.tier.percentage > 0) {
        //     this.tier.percentage = this.tier.percentage / 100;
        //     this.tier.rate = this.tier.percentage * 10000;
        // }
        this.onFeeTierChange.emit(this.tier);
    }

    public deleteTier(index): void {
        this.onFeeTierRemove.emit(index);
    }

    public onInputBPSValueChange(): void {
        // this.tier.percentage = (this.tier.rate > 0) ? +(this.tier.rate / 10000) : 0;
        this.onFeeTierChange.emit(this.tier);
    }
}
