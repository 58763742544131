/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
import { Injectable } from '@angular/core';
import { accountTypeOptionsData, AccountTypeOptionSettings } from '../model/account-type-options';
import { ContactTypes } from '../model/enums';
import { Type } from '../model/proposal';

@Injectable({
    providedIn: 'root'
})
export class ContactTypeUtilityService {
    public getContactTypeFromAccountTypeId(accountTypeId: number): AccountTypeOptionSettings {
        return accountTypeOptionsData.find((accountType: AccountTypeOptionSettings) => {
            return accountType.accountTypeId === accountTypeId;
        });
    }

    public getContactTypeFromAccountTypeName(accountTypeName: string): AccountTypeOptionSettings {
        return accountTypeOptionsData.find((accountType: AccountTypeOptionSettings) => {
            return accountType.name.toLocaleLowerCase().trim().includes(accountTypeName.toLocaleLowerCase().trim());
        });
    }

    public isIndividual(contactType: ContactTypes): boolean {
        return contactType === ContactTypes.Individual;
    }

    public isOrganization(contactType: ContactTypes): boolean {
        return contactType === ContactTypes.Organization;
    }

    public getContactType(contactType: ContactTypes): Type {
        return {
            id: contactType,
            description: ContactTypes[contactType]
        };
    }
}
