/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { FirmDataRequest, FirmDataResponse } from '@CarModels/firm-data.models';
import { PropertyService } from '@CarServices/system/property.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalService } from '@sei/advisor-client-review-proposal-ux';
import { Global, HttpUtil } from '@sei/common-swp-components-lib-ux';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FirmDataService {
    constructor(
        private global: Global,
        private httpUtil: HttpUtil,
        protected carGlobal: GlobalService,
        private http: HttpClient,
        private propertyService: PropertyService
    ) { }

    getFirmDataStream(firmDataRequest: FirmDataRequest): Observable<FirmDataResponse> {
        if (this.global.isMockDataMode()) {
            const mockURL: string = 'assets/mock-data/firm-data.json';
            return this.httpUtil.doMockPostStronglyTyped<FirmDataResponse>(mockURL, 1000);
        } else {
            if (this.propertyService.exists(() => firmDataRequest.firmId)) {
                const url = this.carGlobal.configService.routeFormatter(
                    this.carGlobal.configService.getEndPoint('firmInfo'),
                    [
                        {
                            key: 'firmId',
                            value: firmDataRequest.firmId
                        }
                    ]
                );
                return this.http.get<FirmDataResponse>(url);
            }
        }

    }
}
