/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, OnInit } from '@angular/core';
import { Proposal, ProposalService } from '@sei/advisor-client-review-proposal-ux';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { Observable } from 'rxjs';

@Component({
    selector: 'sei-car-edit-proposal-container',
    templateUrl: './edit-proposal-container.component.html'
})
export class EditProposalContainerComponent extends ParentComponentSubscriptionManager implements OnInit {
    public proposal: Proposal;
    public proposalStream: Observable<Proposal>;

    constructor(private proposalService: ProposalService) {
        super('EditProposalContainerComponent');
    }

    public ngOnInit(): void {
        this.proposalStream = this.proposalService.currentProposal;
    }
}
