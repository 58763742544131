/*
Copyright: This information constitutes the exclusive property of SEI
Investments Company, and constitutes the confidential and proprietary
information of SEI Investments Company.  The information shall not be
used or disclosed for any purpose without the written consent of SEI
Investments Company.
*/

import { SeiAction } from '@sei/common-swp-components-lib-ux';
import { CRMRuleActions } from '../action/car-actions.enum';
import { CRMRuleStateSlice } from '../interface/store.interface';

export function crmRuleSlice(state: CRMRuleStateSlice, action: SeiAction) {
    switch (action.type) {
        case CRMRuleActions[CRMRuleActions.CRMRuleDetails]:
            return Object.assign({}, state, state = action.payload);
        default:
            return state;
    }
}
