/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { ExistingAccountData } from '@CarModels/existing-accounts';
import { ExistingAccountsService } from '@CarServices/existing-accounts/existing-accounts.service';
import { FeatureFlagService } from '@CarServices/feature-flag/feature-flag.service';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DisplayAmountOptions, DisplayNullOptions, DisplayNumericScaleSuffix, NumericScale } from '@sei/common-components-lib-ux';
import { DateHelperService, FormatDateConstants, ParentComponentSubscriptionManager, SubpageheaderModel } from '@sei/common-swp-components-lib-ux';
import { Portfolio, PortfolioHolding } from '@sei/ias-applications-lib-ux';
import * as _ from 'lodash';
import { catchError, take, throwError } from 'rxjs';
import { CommandText, UserLevelEntitlement, WipAccountStatus, WipChecklistLabels } from '../../model/enums';
import { Account, MdcSnapshot, Proposal } from '../../model/proposal';
import { DelegationService } from '../../service/delegation.service';
import { ProposalAccountService } from '../../service/proposal-account.service';
import { ProposalResolverService } from '../../service/proposal-resolver.service';
import { ProposalService } from '../../service/proposal.service';
import { UserProfileService } from '../../service/user-profile.service';

@Component({
    selector: 'car-wip-checklist-existing-accounts',
    templateUrl: './wip-checklist-existing-accounts.component.html',
    styleUrls: ['./wip-checklist-existing-accounts.component.scss']
})
export class WipChecklistExistingAccountsComponent extends ParentComponentSubscriptionManager implements OnInit {

    @Input()
    public proposal: Proposal;
    public existingAccountLabel: WipChecklistLabels = WipChecklistLabels.ExistingAccounts;
    public showStart: boolean = true;
    public isAccountsCallInProgress: boolean = true;
    public isSectionHidden: boolean = false;
    public accountsMap: Map<number, ExistingAccountData[]> = new Map();
    public accountList: ExistingAccountData[] = [];
    public modelPageHeaderProperties: SubpageheaderModel = { title: 'Model Investments', switcherAvailable: false };
    public readonly SHOW_MORE_MESSAGE: string = 'Use the arrow to expand the section details';
    public isNonModelFeatureEnabled: boolean = false;
    public visibleAccountList: ExistingAccountData[] = [];
    public readonly VISIBLE_ACCOUNT_INCREMENT = 3;
    public existingAccountsTotal: number = 0;
    public displayNumericScaleSuffix: DisplayNumericScaleSuffix = {
        scale: NumericScale.NULL,
        displayDecimals: 2
    };
    public roundFractionalNumber: boolean = false;
    public amountOptions: DisplayAmountOptions = {
        nullDisplay: DisplayNullOptions.ZERO,
        roundFractionalAmount: false
    };
    private firmId: number;
    private visibleAccountAmount: number = this.VISIBLE_ACCOUNT_INCREMENT;
    public removeFundsFromExistingAccount: boolean = false;
    public dateTimeForPipe: Date | string;
    public format: string = FormatDateConstants.USER_FORMAT_DATE;
    public userTimezone: string;
    public resolverLoading: boolean = false;

    constructor(private router: Router,
        private existingAccountsService: ExistingAccountsService,
        private userProfileService: UserProfileService,
        private delegationService: DelegationService,
        private proposalService: ProposalService,
        private featureFlagService: FeatureFlagService,
        public proposalAccountService: ProposalAccountService,
        private dateHelperService: DateHelperService,
        private proposalResolverService: ProposalResolverService) {
        super('WipChecklistExistingAccountsComponent');
        this.userTimezone = this.dateHelperService.getUserSystemTimezone();
    }

    ngOnInit(): void {
        super.ngOnInit();
        if (this.proposal?.scenarios[0]?.existingAccounts?.length > 0) {
            this.showStart = false;
        }
        this.subscriptions.push(this.proposalResolverService.isLoading.subscribe((resolverLoading: boolean) => {
            this.resolverLoading = resolverLoading;
        }));
        this.subscriptions.push(this.existingAccountsService.isExistingAccountCallInProgress.subscribe((isInProgress: boolean) => {
            this.isAccountsCallInProgress = isInProgress;
        }));
        this.subscriptions.push(this.existingAccountsService.existingAccounts
            .subscribe((accountsMap: Map<number, ExistingAccountData[]>) => {
                this.accountsMap = _.cloneDeep(accountsMap);
                this.accountList = this.existingAccountsService.convertExistingAccountsMapToArray(this.accountsMap);
                this.accountList = this.accountList.map((account: ExistingAccountData) => {
                    account.showModelInvestmentOnWIP = true;
                    account.showNonModelInvestmentOnWIP = true;
                    return account;
                });
                if (this.visibleAccountAmount < this.VISIBLE_ACCOUNT_INCREMENT) {
                    this.visibleAccountAmount = this.VISIBLE_ACCOUNT_INCREMENT;
                }
                this.setVisibleAccounts(this.visibleAccountAmount);
                this.accountList?.forEach((existingAccount: ExistingAccountData) => {
                    existingAccount.proposalTrackedPortfolios =
                        this.filterDeletedPortfoliosAndHoldings(existingAccount?.proposalTrackedPortfolios);
                });
                this.existingAccountsTotal =
                    this.calculateTotalMarketValueForExistingAccounts(this.accountList, this.proposal.scenarios[0].accounts);
                this.dateTimeForPipe = this.existingAccountsService.existingAccountsAsOfDate.getValue();
            }));
        this.subscriptions.push(this.delegationService.refresh().subscribe((commandText: CommandText) => {
            switch (commandText) {
                case CommandText.CancelRightHandDialog:
                    this.removeNewCurrentAccountsFromProposal();
                    break;
                case CommandText.UpdateWipCheckList:
                    this.existingAccountsTotal =
                        this.calculateTotalMarketValueForExistingAccounts(this.accountList, this.proposal.scenarios[0].accounts);
                    break;
            }
        }));
        this.getFirmId();
        this.retrieveClientExistingAccounts();
        this.isNonModelFeatureEnabled = this.featureFlagService.isNonModelFeatureEnabled();
    }

    public navigateToExistingAccounts(): void {
        this.router.navigate([`/Proposal/WIP/${this.proposal.id}/Scenarios/${this.proposal.scenarios[0].id}/ExistingAccounts`]);
    }

    public onShowSectionDetailsClick(): void {
        this.isSectionHidden = !this.isSectionHidden;
    }

    private retrieveClientExistingAccounts(): void {
        const scenarioId: number = this.proposal?.scenarios[0]?.id;
        const clientIds: number[] = this.existingAccountsService.retrieveClientIdListFromProposal(this.proposal);
        const primaryAdvisorId: number =
            Number(this.proposal?.scenarios[0]?.accounts[0]?.advisors[0]?.entityId || this.proposal?.advisors[0]?.entityId);
        if (clientIds.length > 0) {
            // This subscription is not added to this.subscriptions to avoid unsubscribing when navigating away from component.
            // take(1) will unsubscribe after the subscription sends a single response.
            this.existingAccountsService
                .retrieveExistingAccountsByIdList(clientIds, this.firmId, primaryAdvisorId, scenarioId, this.proposal.id)
                .pipe(take(1),
                    catchError(() => {
                        this.existingAccountsService.isExistingAccountCallInProgress.next(false);
                        this.existingAccountsService.existingAccountCallFailed.next(true);
                        return throwError(() => new Error('Error Retrieving Clients Existing Accounts'));
                    }))
                .subscribe(() => {
                    this.existingAccountsService.removeUnusedClientAccounts(this.proposal.clients);
                    this.existingAccountsService.existingAccountCallFailed.next(false);
                });
        }
    }

    public getFirmId(): void {
        const userProfile = this.userProfileService.getCurrentUserProfile;
        const isInstanceUser: boolean = userProfile.entitlements.userLevelId === UserLevelEntitlement.PO ||
            userProfile.entitlements.userLevelId === UserLevelEntitlement.Instance;
        isInstanceUser ? this.firmId = userProfile.firm.swpFirmId : this.firmId = this.userProfileService.firm.swpFirmId;
    }

    public getMdcSnapshot(index: number): MdcSnapshot {
        const account: ExistingAccountData = this.visibleAccountList[index];
        const currentAccountInProposal: Account =
            this.proposal.scenarios[0].accounts.find((accountItem: Account) =>
                accountItem.currentAccountId === account.accountId);
        return {
            completed: currentAccountInProposal ? WipAccountStatus.Complete : WipAccountStatus.New
        };
    }

    public setVisibleAccounts(amount: number) {
        if (amount > this.accountList.length) {
            amount = this.accountList.length;
        } else if (amount % this.VISIBLE_ACCOUNT_INCREMENT !== 0 && this.accountList.length < this.VISIBLE_ACCOUNT_INCREMENT) {
            amount -= amount % this.VISIBLE_ACCOUNT_INCREMENT;
        }
        this.visibleAccountAmount = amount;
        this.visibleAccountList = this.accountList.slice(0, amount);
    }

    public onModifyInvestmentsClicked(currentAccountIndex): void {
        const currentAccount: ExistingAccountData = this.visibleAccountList[currentAccountIndex];
        const currentAccountInProposal: Account =
            this.proposal.scenarios[0].accounts.find((accountItem: Account) =>
                accountItem.currentAccountId === currentAccount.accountId);
        if (!currentAccountInProposal) {
            const addedAccountIndex: number = this.proposal.scenarios[0].accounts.length;
            const account: Account = this.existingAccountsService.createAccountFromCurrentAccount(
                this.proposal,
                currentAccount,
                addedAccountIndex,
                currentAccount?.investmentProgramId);
            account.portfolios = this.existingAccountsService.filterDeletedPortfoliosAndHoldings(account.portfolios);
            this.proposal.scenarios[0].accounts.push(account);
            this.router.navigate(
                ['Proposal', 'WIP', this.proposal.id, 'Scenarios', this.proposal.scenarios[0].id, 'Accounts', addedAccountIndex]);
        } else {
            currentAccountInProposal.sweepModelElection = {
                isFdic: currentAccount.fdic,
                currencySweepModelAllocDescription: currentAccount.sweepModelDescription,
                platformSweepModelId: currentAccount.sweepModelId
            };
            currentAccountInProposal.platformFeeCapDetails = currentAccount.platformFeeCapDetails;
            setTimeout(() => {
                this.proposalService.changedProposal(_.cloneDeep(this.proposal));
            });
            this.router.navigate(
                ['Proposal', 'WIP', this.proposal.id, 'Scenarios', this.proposal.scenarios[0].id, 'Accounts', currentAccountInProposal.id]);
        }
    }

    public getTotalProposedAmount(account: ExistingAccountData): number {
        return this.removeFundsFromExistingAccount ?
            this.getAccountMarketValue(account) - this.getNewFunds(account) :
            this.getAccountMarketValue(account) + this.getNewFunds(account);
    }

    private calculatePortfolioTotal(portfolios: Portfolio[]): number {
        return this.proposalAccountService.calculateNonModelInvestmentTotal(portfolios);
    }

    public getAccountMarketValue(account: ExistingAccountData): number {
        let accountMarketValue: number = 0;
        if (account) {
            accountMarketValue = this.isNonModelFeatureEnabled ?
                account.actualCurrentMarketValue : account.modelMarketValue;
        }
        return accountMarketValue;
    }

    public isCurrentAccountInProposal(account: ExistingAccountData): boolean {
        return !!this.proposal.scenarios[0].accounts.find((accountItem: Account) =>
            accountItem.currentAccountId === account.accountId);
    }

    public getNewFunds(account: ExistingAccountData): number {
        const modelDeltaValue: number = this.getModelInvestmentValue(account) - account.modelMarketValue;
        const existingNonModelTotal: number = this.calculatePortfolioTotal(account.existingNonModelPortfolios);
        const proposedNonModelTotal: number = this.calculatePortfolioTotal(account.proposalTrackedPortfolios);
        const nonModelDeltaValue: number = existingNonModelTotal - proposedNonModelTotal;
        this.checkIfRemoveOrAdd(modelDeltaValue, nonModelDeltaValue);
        return Math.abs(modelDeltaValue - nonModelDeltaValue);
    }

    public checkIfRemoveOrAdd(modelDeltaValue: number, nonModelDeltaValue: number): void {
        this.removeFundsFromExistingAccount = modelDeltaValue - nonModelDeltaValue < 0;
    }

    public getCurrentAccountInProposal(account: ExistingAccountData): Account {
        return this.proposal.scenarios[0].accounts.find((accountItem: Account) =>
            accountItem.currentAccountId === account.accountId);
    }

    public getModelInvestmentValue(account: ExistingAccountData): number {
        const currentAccount: Account = this.proposal.scenarios[0].accounts.find((accountItem: Account) =>
            accountItem.currentAccountId === account.accountId);
        let currentModelInvestmentsValue: number = 0;
        currentAccount.strategies.forEach((strategy) => {
            currentModelInvestmentsValue = currentModelInvestmentsValue + strategy.amount;
        });
        return currentModelInvestmentsValue;
    }

    public filterDeletedPortfoliosAndHoldings(portfoliosToFilter: Portfolio[]): Portfolio[] {
        return this.existingAccountsService.filterDeletedPortfoliosAndHoldings(portfoliosToFilter);
    }

    private removeNewCurrentAccountsFromProposal(): void {
        this.visibleAccountList.forEach((account: ExistingAccountData) => {
            const currentAccountIndex: number = this.proposal.scenarios[0].accounts
                .findIndex((accountItem: Account) => (accountItem.currentAccountId === account.accountId &&
                    (accountItem.isNewAccount && accountItem.currentAccountId)));
            if (currentAccountIndex >= 0) {
                this.proposal.scenarios[0].accounts.splice(currentAccountIndex, 1);
                this.proposalService.changedProposal(_.cloneDeep(this.proposal));
            }
        });
    }

    public toggleShowModelInvestmentDetails(account: ExistingAccountData) {
        const currentAccountIndex: number = this.visibleAccountList.findIndex((accountItem: ExistingAccountData) => {
            return account.accountId === accountItem.accountId;
        });
        this.visibleAccountList[currentAccountIndex].showModelInvestmentOnWIP =
            !this.visibleAccountList[currentAccountIndex].showModelInvestmentOnWIP;
    }

    public toggleShowNonModelInvestmentDetails(account: ExistingAccountData) {
        const currentAccountIndex: number = this.visibleAccountList.findIndex((accountItem: ExistingAccountData) => {
            return account.accountId === accountItem.accountId;
        });
        this.visibleAccountList[currentAccountIndex].showNonModelInvestmentOnWIP =
            !this.visibleAccountList[currentAccountIndex].showNonModelInvestmentOnWIP;
    }

    private calculateTotalMarketValueForExistingAccounts(accountsList: ExistingAccountData[], modifiedAccounts: Account[]): number {
        let total: number = 0;
        if (accountsList && accountsList.length) {
            accountsList.forEach((accountData: ExistingAccountData): void => {
                const modifiedAccount: Account[] =
                    modifiedAccounts.filter((account: Account) => account.currentAccountId === accountData.accountId);
                if (modifiedAccount && modifiedAccount.length > 0) {
                    // Model Investment Total
                    total += modifiedAccount[0].balance;
                    // Non Model Investment Total
                    // Map each portfolio to a list of sums of each portfolio's holding values
                    total += modifiedAccount[0]?.portfolios.map(
                        // Map each portfolio's holdings to a list of values, then reduce to sum them
                        (portfolio: Portfolio) => portfolio?.proposedPortfolioHoldingList
                            ?.map((portfolioHolding: PortfolioHolding) =>
                                portfolioHolding?.quantity * portfolioHolding?.assetData?.estimatedValue)
                            ?.reduce((sum, current) => sum + current, 0))
                        // reduce the sums of each portfolio's value to get the total value of all portfolios
                        ?.reduce((sum, current) => sum + current, 0);
                } else {
                    total += accountData?.actualCurrentMarketValue;
                }
            });
        }
        return total;
    }

}

