/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */


import { ExistingAccountData } from '@CarModels/existing-accounts';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SubpageheaderModel } from '@sei/common-swp-components-lib-ux';
import { Client, Proposal } from '@sei/ias-applications-lib-ux';

@Component({
    selector: 'sei-car-existing-accounts-owner-section',
    templateUrl: './existing-accounts-owner-section.component.html',
    styleUrls: ['./existing-accounts-owner-section.component.scss']
})
export class ExistingAccountsOwnerSectionComponent implements OnInit, OnChanges {

    @Input()
    client: Client;
    @Input()
    existingAccountsOwnedByClient: { key: string; value: ExistingAccountData[] };
    @Input()
    proposal: Proposal;
    @Input()
    selectedExistingAccounts: ExistingAccountData[] = [];
    existingAccounts: ExistingAccountData[];

    public sectionHeaderProperties: SubpageheaderModel = {
        title: '',
        switcherAvailable: false
    };

    ngOnInit(): void {
        this.sectionHeaderProperties.title = this.getClientName(this.client);
        this.existingAccounts = this.existingAccountsOwnedByClient.value;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.selectedExistingAccounts = changes?.selectedExistingAccounts?.currentValue;
    }

    private getClientName(client: Client): string {
        let clientName = '';
        if (client) {
            clientName = `${client.firstName} ${client.lastName}`;
        }
        return clientName;
    }
}

