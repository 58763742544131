/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
import { Injectable } from '@angular/core';
import { PropertyService } from '@CarServices/system/property.service';
import { WalkMeConstants } from '@CarModels/constants';
import { GlobalService } from '@CarServices/system/global.service';


@Injectable({
    providedIn: 'root'
})
export class WalkMeService {
    private walkmeSnippet: string;
    private walkMeLoaded: boolean = false;

    constructor(
        private readonly propertyService: PropertyService,
        private readonly global: GlobalService
    ) { }

    public loadWalkMeSnippet() {
        if (this.global.configService.environment.carConfig &&
            // eslint-disable-next-line no-prototype-builtins
            this.global.configService.environment.carConfig.hasOwnProperty('walkMeUrl')
        ) {
            this.walkmeSnippet = this.global.configService.environment.carConfig['walkMeUrl'];
            if ( !this.walkMeLoaded && this.walkmeSnippet) {
                this.walkMeLoaded = true;
                this.loadWalkmeScript(this.walkmeSnippet);
            }
        } else {
            this.global.logger.log('Snippet is not loaded');
        }
    }

    private loadWalkmeScript(walkmeSnippet: string) {
        const walkmeURL = WalkMeConstants.WALKME_SNIPPET.replace(WalkMeConstants.WALKME_URL_DEFAULT
            , walkmeSnippet);
        const walkmeElement = document.createElement('script');
        walkmeElement.type = 'text/javascript';
        walkmeElement.async = true;
        walkmeElement.src = walkmeURL;

        const s = document.getElementsByTagName('head')[0].getElementsByTagName('meta')[0];
        s.parentNode.insertBefore(walkmeElement, s);
    }

}
