/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */


import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CommandText, DelegationService, GenericErrorService, Proposal, ProposalService, WipAccountStatus, WipChecklistLabels } from '@sei/advisor-client-review-proposal-ux';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { Observable } from 'rxjs';

@Component({
    selector: 'car-fee-analysis',
    templateUrl: './fee-analysis.component.html'
})
export class FeeAnalysisComponent extends ParentComponentSubscriptionManager implements OnInit {

    public proposalId: number;
    public proposalStream: Observable<Proposal>;
    public proposal: Proposal;

    constructor(private activatedRoute: ActivatedRoute,
        private proposalService: ProposalService,
        private delegationService: DelegationService,
        private genericErrorService: GenericErrorService) {
        super('FeeAnalysisComponent');
    }

    ngOnInit() {
        this.proposalId = Number(this.activatedRoute.parent.snapshot.paramMap.get('proposalId'));

        this.proposalStream = this.proposalService.currentProposal;

        const proposalSubscription = this.proposalStream
            .subscribe(
                (proposalResponse: Proposal) => {
                    if (proposalResponse) {
                        this.proposal = proposalResponse;
                    }
                }
            );
        this.subscriptions.push(proposalSubscription);

        this.subscriptions.push(this.delegationService.refresh()
            .subscribe(
                (data: string) => {
                    if (data === CommandText.SaveFeeGroup) {
                        // Note: Return to WipCheckList - Save current checklist first
                        const proposalScenario = this.proposal.wipCheckList
                            .find((wipCheckListItem) => wipCheckListItem.sectionType === WipChecklistLabels.ProposalScenario);

                        const foundAnalysisWipCheckListItem = proposalScenario.subCheckList.find((subCheckItem) => {
                            return subCheckItem.name === WipChecklistLabels.FeeAnalysis;
                        });

                        if (foundAnalysisWipCheckListItem) {
                            foundAnalysisWipCheckListItem.mdcSnapShot.completed = WipAccountStatus.Complete;

                            // eslint-disable-next-line  @typescript-eslint/no-empty-function
                            this.proposalService.updateProposalWipCheckList(this.proposal.wipCheckList).subscribe(() => {
                            },
                                (error) => this.genericErrorService.setGenericError(error));
                        }
                    }
                }));
    }
}
