/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { Contact, ContactType } from '@CarInterfaces/account';
import { BaseFactory } from '@CarInterfaces/base-factory';
import { ContactTypes, ContactTypesDescription, EntityType } from '@CarModels/enums';
import { AddressFactory } from './address-factory';

@Injectable({
    providedIn: 'root'
})
export class ContactFactory implements BaseFactory<Contact> {
    // Assumes individual with legal address
    public createObject(type?: ContactType, typeId?: number): Contact {
        if (!type && typeId > 0) {
            type = ContactFactory.createContactType(typeId);
        }

        if (!type) {
            type = ContactFactory.createContactType();
        }

        return {
            contactId: null, // Per Jining this needs be null for new contact
            entityId: -1,
            taxpayerIdentificationNumber: null,
            contactType: type,
            suffix: {
                suffixId: null,
                suffixName: null,
                sortOrder: null
            },
            // The backend is using the Legal address for contacts that don't have mailing
            addresses: [new AddressFactory().createObject()],
            entityType: EntityType.Prospect
        };
    }

    // tslint:disable-next-line: member-ordering
    public static createContactType(typeId?: number): ContactType {
        switch (typeId) {
            case ContactTypes.Organization:
                return {
                    contactTypeId: ContactTypes.Organization,
                    contactTypeDescription: ContactTypesDescription.Organization
                };
            default:
                return {
                    contactTypeId: ContactTypes.Individual,
                    contactTypeDescription: ContactTypesDescription.Individual
                };
        }
    }
}
