<sei-modal-window #implementModal
                  [modalType]="'lg'"
                  [modalTitle]="'Implement Proposal'"
                  [isDismissible]="!isLoading()"
                  [isBackdrop]="true"
                  [isDraggable]="true"
                  [footerEnable]="true"
                  (modalClosed)="cancel()">
    <div class="modal-body"
         *ngIf="isDAOIntegrationEnabled(); then dao_integration else default"></div>
    <footer class="modalbox-footer">
        <div class="btn-toolbar">
            <button type="button"
                    [hidden]="isConfirmationPage() || hasExceededMaxErrors()"
                    [disabled]="isLoading()"
                    class="btn btn-default pull-left"
                    [class.disabled]="false"
                    (click)="onClose()">Cancel</button>
            <button type="button"
                    [hidden]="isConfirmationPage() || hasExceededMaxErrors()"
                    [disabled]="isLoading()"
                    class="btn btn-primary pull-right"
                    [disabled]="!isValid || !enableImplementButton"
                    (click)="implement()">Submit</button>
            <button [hidden]="!isConfirmationPage()"
                    [disabled]="isLoading()"
                    type="button"
                    (click)="navigateToDAO()"
                    class="btn btn-primary pull-right">Review Accounts</button>
            <button [hidden]="!isConfirmationPage() && !hasExceededMaxErrors()"
                    [disabled]="isLoading()"
                    type="button"
                    (click)="isConfirmationPage() ? onClose(true) : onClose()"
                    [class.btn-primary]="hasExceededMaxErrors()"
                    [class.btn-default]="!hasExceededMaxErrors()"
                    class="btn pull-right">Close</button>
        </div>

    </footer>
</sei-modal-window>
<ng-template #default>
    <div class="container">
        <div class="row">
            <div class="col-xs-12 text-center">
                <h3>Implementing {{proposal?.proposalName}}</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 text-center">
                <p>By proceeding, this proposal will be marked as "Implemented" and <br />saved to the "Archived" tab within the Proposal Dashboard.<br />Select the action you'd like to take next:</p>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-8 col-xs-offset-4 car-main-sm-body-copy">
                <div class="row">
                    <div class="radio col-xs-12 ">
                        <label class="car-default-cursor">
                            <input type="radio"
                                   [value]="postProposalImplementRedirectActions.Dashboard"
                                   name="client"
                                   (change)="optionSelectedChange($event)" />
                            Return to Proposal Dashboard
                        </label>
                    </div>
                </div>
                <div class="row">
                    <div class="radio col-xs-12 ">
                        <label class="car-default-cursor">
                            <input type="radio"
                                   [value]="postProposalImplementRedirectActions.Account"
                                   name="client"
                                   (change)="optionSelectedChange($event)" />
                            Open a New Account
                        </label>
                    </div>
                </div>
                <div class="row">
                    <div class="radio col-xs-12">
                        <label class="car-default-cursor">
                            <input type="radio"
                                   [value]="postProposalImplementRedirectActions.SWP"
                                   name="client"
                                   (change)="optionSelectedChange($event)" />
                            Go to SEI Wealth Platform Home screen to initiate an Investment Change
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #dao_integration>
    <div *ngIf="isConfirmationPage(); then confirmation else implement_form">
    </div>
</ng-template>
<ng-template #implement_form>
    <div class="container">
        <div class="loading-spinner-container"
             *ngIf="isLoading()">
            <sei-loading [showLoadingIcon]="isLoading()"
                         [multiplier]="3"></sei-loading>
        </div>

        <div class="sei-margin-top"
             *ngIf="!isLoading()">
            <sei-alert *ngIf="showFailureMessage()"
                       [type]="errorType">
                <span *ngFor="let error of implementModalService.getValidationErrors()">{{error}}<br></span>
                <p *ngIf="containsValidationError() && !hideChecklistLink"><a (click)="navigateToChecklist()">Go to Proposal Checklist</a></p>
            </sei-alert>

            <sei-alert [type]="infoType"
                       *ngIf="!isProposalGenerated">
                <span>{{informationMessageForNonGeneratedProposals}}</span>
            </sei-alert>

            <div class="row">
                <div class="col-xs-12">
                    <h3>{{proposal?.proposalName}}</h3>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <p>By proceeding, this proposal will be saved to the “Archived” tab within the Proposal Dashboard. Select the action you'd like to take next:</p>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 car-main-sm-body-copy">
                    <div class="row">
                        <div class="radio col-xs-12 ">
                            <label class="car-default-cursor fit-content">
                                <input type="radio"
                                       [value]="postProposalImplementRedirectActions.Account"
                                       [checked]="selectedValue === postProposalImplementRedirectActions.Account"
                                       [disabled]="hasExceededMaxErrors()"
                                       name="client"
                                       (change)="optionSelectedChange($event)" />
                                Create Pending Account
                            </label>
                        </div>
                    </div>
                    <div class="row form-group padding-left"
                         *ngIf="selectedValue === postProposalImplementRedirectActions.Account && (showOfficeLocations || showAdvisorGroupIdInput())">
                        <div class="col-xs-6"
                             *ngIf="showOfficeLocations">
                            <label for="officeLocationDropdown"
                                   class="required-mark">Office Location</label>
                            <sei-dropdown id="officeLocationDropdown"
                                          [items]="officeLocationDropdownItems"
                                          [disabled]="hasExceededMaxErrors()"
                                          [ngModel]="selectedOfficeLocationDropdownItems"
                                          (ngModelChange)="onOfficeLocationChange($event)"></sei-dropdown>
                        </div>
                        <div class="col-xs-6"
                             *ngIf="showAdvisorGroupIdInput()">
                            <label for="advisorGroupId"
                                   class="control-label">Advisor Group ID <span class="optional-tag">- Optional</span></label>
                            <input id="advisorGroupId"
                                   type="text"
                                   class="form-control"
                                   [ngModel]="implementProposalOptions.advisorGroupId"
                                   [disabled]="hasExceededMaxErrors()"
                                   (ngModelChange)="onAdvisorGroupIdChange($event)"
                                   minlength="0"
                                   maxlength="255">
                        </div>
                    </div>
                    <div class="row">
                        <div class="radio col-xs-12 ">
                            <label class="car-default-cursor fit-content">
                                <input type="radio"
                                       [value]="postProposalImplementRedirectActions.Dashboard"
                                       [checked]="selectedValue === postProposalImplementRedirectActions.Dashboard"
                                       [disabled]="hasExceededMaxErrors()"
                                       name="client"
                                       (change)="optionSelectedChange($event)" />
                                Archive
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #confirmation>
    <div class="container">
        <div class="loading-spinner-container"
             *ngIf="isLoading()">
            <sei-loading [showLoadingIcon]="isLoading()"
                         [multiplier]="3"></sei-loading>
        </div>
        <div class="sei-margin-top"
             *ngIf="!isLoading()">
            <sei-alert [type]="successType"
                       *ngIf="isFullSuccess()">
                <span>Request to implement has been successfully submitted.</span>
            </sei-alert>
            <sei-alert *ngIf="!isFullSuccess() && getPartialSuccessfulAccountsMessage() !== undefined"
                       [type]="successType">
                <span>{{ getPartialSuccessfulAccountsMessage() }}</span>
            </sei-alert>
            <sei-alert *ngIf="!isFullSuccess() && getPartialFailedAccountsMessage() !== undefined"
                       [type]="errorType">
                <span>{{ getPartialFailedAccountsMessage() }}</span>
            </sei-alert>
            <sei-pageheader [headingLevel]="3"
                            [pageHeaderProperties]="proposalDetailsHeaderProperties">
                <div extra-content>
                    <sei-print-export #printExport
                                      (printFriendly)="printModal()"
                                      (onPrintingComplete)="printingComplete()"></sei-print-export>
                </div>
            </sei-pageheader>
            <div class="row">
                <div class="col-xs-4 proposal-details-column">
                    <span class="header-text">Proposal Name</span>
                    <span>{{proposal.proposalName}}</span>
                </div>
                <div class="col-xs-4 proposal-details-column"
                     *ngIf="showOfficeLocations">
                    <span class="header-text">Office Location</span>
                    <span>{{officeLocationDescription}}</span>
                </div>
                <div class="col-xs-4 proposal-details-column"
                     *ngIf="showAdvisorGroupIdInput()">
                    <span class="header-text">Advisor Group ID</span>
                    <span class="prevent-overflow">{{implementProposalOptions.advisorGroupId}}</span>
                </div>
            </div>
            <sei-pageheader [headingLevel]="3"
                            [pageHeaderProperties]="accountDetailsHeaderProperties"
                            class="header-margin"></sei-pageheader>
            <div class="form-group has-info">
                <p class="help-block text-info">Below is a list of generated pending accounts and their respective proposed funding amount.</p>
            </div>
            <div [class.accounts-container]="!isPrinting"
                 [class.print-view-accounts-container]="isPrinting">
                <div class="row section-header">
                    <div class="col-xs-5">
                        <p>Account Name</p>
                    </div>
                    <div class="col-xs-4">
                        <p>Account Number</p>
                    </div>
                    <div class="col-xs-3">
                        <p>Proposed Amount</p>
                    </div>
                </div>
                <div class="row"
                     *ngFor="let account of getProposalAccounts()">
                    <div class="col-xs-5 col-padding">
                        <p>{{account.name}}</p>
                    </div>
                    <div *ngIf="getPendingAccountId(account)"
                         class="col-xs-4">
                        <p>{{getPendingAccountId(account)}}</p>
                    </div>
                    <div *ngIf="!getPendingAccountId(account)"
                         class="col-xs-4 form-group has-error">
                        <p class="help-block text-error">Failed</p>
                    </div>
                    <div class="col-xs-3">
                        <p class="pull-right">{{{ value: account.balance, actualCurrency: 'USD'} | seiDisplayMonetaryAmount:amountOptions:roundFractionalNumber:displayNumericScaleSuffix}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
