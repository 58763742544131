<div class="wip-summary-row sectionhead wip-header-row wip-checklist-section-header wip-checklist-section-header-bg-gray"
    [class.wip-checklist-section-header-collapsed]="!showSectionDetail">
    <div class="wip-summary-left subhead">
        <h4>Fees</h4>
    </div>
    <div class="wip-summary-right">
        <sei-button [buttonDisabled]="feesEditDisabled"
                    buttonText="Edit"
                    buttonClass="btn-link"
                    (buttonClicked)="goToFees()"></sei-button> <span>|</span>
        <a class="section-arrow" [class.open]="showSectionDetail" aria-label="Expand Fees Section" (click)="onShowDetailsClick()">
            <i class="fas" [class.fa-chevron-right]="!showSectionDetail" [class.fa-chevron-down]="showSectionDetail"></i>
        </a>
    </div>
</div>
<ng-container *ngIf="hudSummaryStream | async as scenarioAccountFee; else loadingSpinner">
    <div [hidden]="!showSectionDetail" class="wip-section-background-green wip-section-border wip-section-border-rounded wip-section-padding-bottom">
        <div class="wip-summary-row head">
            <div class="wip-summary-left">
                <car-wip-checklist-status-indicator [mdcSnapShot]="wipCheckList.mdcSnapShot"></car-wip-checklist-status-indicator>
                Fee Analysis for Me
            </div>
        </div>
        <div *ngIf="scenarioAccountFee.hudSummary as hudSummary">
            <div class="wip-summary-row">
                <div class="wip-summary-left">Advisor Fee <i *ngIf="isClientDirectedPresent()" class="fas fa-info-circle text-info sei-margin-left" [tooltip]="getTooltipMessage()"></i> </div>
                <div class="wip-summary-right">
                    <p>
                        {{ (hudSummary.estimatedAdvisorFee.rate * 100) | proposalFormatPercentWithLessThan:proposalPercentFormatOptions }} |
                        {{hudSummary.estimatedAdvisorFee.amount | currency:currencySymbol:'symbol':'1.0-0'}}
                    </p>
                </div>
            </div>
            <div class="wip-summary-row"
                 [hidden]="showInvestmentSectionDetails">
                <div class="wip-summary-left">
                    Investment Cost
                    <a (click)="onShowInvestmentDetailsClick()">
                        <i class="fas" [class.fa-chevron-down]="showInvestmentSectionDetails"
                            [class.fa-chevron-right]="!showInvestmentSectionDetails"></i>
                    </a>
                </div>
                <div class="wip-summary-right">
                    <p>
                        {{ (hudSummary.investmentCost.rate * 100) | proposalFormatPercentWithLessThan:proposalPercentFormatOptions }} |
                        {{hudSummary.investmentCost.amount | currency:currencySymbol:'symbol':'1.0-0'}}
                    </p>
                </div>
            </div>
            <div [hidden]="!showInvestmentSectionDetails">
                <div class="wip-summary-row">
                    <div class="wip-summary-left">
                        Investment Cost
                        <a (click)="onShowInvestmentDetailsClick()">
                            <i class="fas" [class.fa-chevron-down]="showInvestmentSectionDetails"
                                [class.fa-chevron-right]="!showInvestmentSectionDetails"></i>
                        </a>
                    </div>
                    <div class="wip-summary-right subhead">
                        <p>
                            {{ (hudSummary.investmentCost.rate * 100) | proposalFormatPercentWithLessThan:proposalPercentFormatOptions }} |
                            {{hudSummary.investmentCost.amount | currency:currencySymbol:'symbol':'1.0-0'}}
                        </p>
                    </div>
                </div>
                <div class="wip-summary-row ">
                    <div class="wip-summary-left fee-margin-left">
                        {{hudSummary.fundExpense.label}}
                    </div>
                    <div class="wip-summary-right subhead">
                        <p>
                            {{ (hudSummary.fundExpense.rate * 100) | proposalFormatPercentWithLessThan:proposalPercentFormatOptions }} |
                            {{hudSummary.fundExpense.amount | currency:currencySymbol:'symbol':'1.0-0'}}
                        </p>
                    </div>
                </div>
                <div class="wip-summary-row ">
                    <div class="wip-summary-left fee-margin-left">
                        <span class="top-align">{{hudSummary.managementExpense.label}}
                        <sei-car-fee-breakdown-tooltip [managementFeeBreakdown]="hudSummary.managementFeeBreakdown"></sei-car-fee-breakdown-tooltip>
                        </span>
                    </div>
                    <div class="wip-summary-right">
                        <p>
                            {{ (hudSummary.managementExpense.rate * 100) | proposalFormatPercentWithLessThan:proposalPercentFormatOptions}} |
                            {{hudSummary.managementExpense.amount | currency:currencySymbol:'symbol':'1.0-0'}}
                        </p>
                    </div>
                </div>
                <div class="wip-summary-row">
                    <div class="wip-summary-left fee-margin-left">
                        {{hudSummary.platformFee.label}}
                    </div>
                    <div class="wip-summary-right fee-width-45">
                        <p>
                            {{ (hudSummary.platformFee.rate * 100) | proposalFormatPercentWithLessThan:proposalPercentFormatOptions }} |
                            {{hudSummary.platformFee.amount | currency:currencySymbol:'symbol':'1.0-0'}}
                        </p>
                    </div>
                </div>
            </div>
            <div class="wip-summary-row">
                <div class="wip-summary-left">Cost to Client</div>
                <div class="wip-summary-right">
                    <p>
                        {{ (hudSummary.costToClient.rate * 100) | proposalFormatPercentWithLessThan:proposalPercentFormatOptions }} |
                        {{hudSummary.costToClient.amount | currency:currencySymbol:'symbol':'1.0-0'}}
                    </p>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-template #loadingSpinner>
    <sei-car-wip-checklist-loading-spinner></sei-car-wip-checklist-loading-spinner>
</ng-template>
