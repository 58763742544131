/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { GlobalService } from '@CarServices/system/global.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SeiCommonAppStore } from '@sei/common-swp-components-lib-ux';
import { Observable } from 'rxjs';
import { map, skipWhile, take } from 'rxjs/operators';
import { ApigeeTokenService } from '../auth/apigee-token.service';
import { UrlRewriterInterceptor } from './url-rewriter.interceptor';

@Injectable({ providedIn: 'root' })
export class CoreInterceptor implements HttpInterceptor {
    private bearerHeaderPrefix = 'Bearer';
    private authHeaderVar = 'Authorization';
    private appKeyVar = 'appKey';

    constructor(
        private apigeeTokenService: ApigeeTokenService,
        private global: GlobalService,
        private appStore: Store<SeiCommonAppStore>
    ) { }

    public intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        // return if service call is OAUTH. Prevents RACE condition
        if (this.apigeeTokenService.isOauthRequest(req)) {
            return next.handle(req);
        }

        if (!req.headers.has('content-type')) {
            req = req.clone({
                headers: req.headers.set('content-type', 'application/json')
            });
        }

        if (!req.url.includes('forms/documents') &&
            !req.url.includes('proposalDocuments/presentation/download/') &&
            !req.url.includes('exportFeeData')) {
            req = req.clone({
                headers: req.headers.set('accept', 'application/json')
            });
        }

        // Auth tokens are not required for requests to KASSO
        if (UrlRewriterInterceptor.KEEP_ALIVE_IGNORABLE_PATHS.some((path) => req.url.includes(path))) {
            return next.handle(req);
        }
        return this.initializeToken(req, next);
    }

    private initializeToken(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        this.global.logger.log(
            `CAR Apigee enabled: ${this.global.configService.environment.carConfig.apigeeEnabled}`
        );

        if (!this.global.configService.environment.carConfig.apigeeEnabled) {
            this.global.logger.log(
                `CAR Apigee enabled: ${this.global.configService.environment.carConfig.apigeeEnabled}`
            );
            return next.handle(req);
        }

        // Note: get the token from store
        this.appStore.select((store) => store.apigeeSlice)
            .pipe(
                skipWhile((data) => this.global.isEmpty(data.accessToken) || !Object.keys(data).length),
                map((data) => {
                    // console.log(data.accessToken);
                    if (!(req && this.apigeeTokenService.isOauthRequestURL(req.url))) {
                        if (data.accessToken) {
                            req = req.clone({
                                headers: req.headers.set(
                                    this.authHeaderVar,
                                    `${this.bearerHeaderPrefix} ${data.accessToken}`
                                )
                            });
                        }
                    }
                }),
                take(1)
            ).subscribe();


        // Note: get the appKey from a configuration
        if (this.global.configService.apigeeConfiguration.payload.headers['appKey']) {
            req = req.clone({
                headers: req.headers.set(
                    this.appKeyVar,
                    this.global.configService.apigeeConfiguration.payload.headers['appKey']
                )
            });
        }

        return next.handle(req);
    }
}
