/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@CarComponents/common/common.module';
import { ButtonComponent } from '@sei/common-components-lib-ux';
import { HighlightSearchTextPipe } from '../../../app/core/pipes/highlight-search-text.pipe';
import { PendingAccountsDashboardComponent } from './pending-accounts-dashboard/pending-accounts-dashboard.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        PendingAccountsDashboardComponent,
        HighlightSearchTextPipe
    ],
    exports: [
        ButtonComponent,
        HighlightSearchTextPipe
    ],
    providers: [
        HighlightSearchTextPipe
    ]
})
export class PendingAccountsModule { }
