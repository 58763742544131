/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Global, HttpUtil } from '@sei/common-swp-components-lib-ux';
import { EditDataObject, SubmitDataObject } from '@sei/ias-applications-lib-ux';
import { Observable, throwError } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { ContentTypes } from '../model/enums';
import { CurrentAccountDetails, SubmitTaxTransitionResponse } from '../model/proposal';
import { GlobalService } from './system/global.service';

@Injectable({
    providedIn: 'root'
})

export class ProposalTaxTransitionService {

    constructor(private httpUtil: HttpUtil, private global: Global, protected carGlobal: GlobalService, private http: HttpClient) { }

    public fetchTaxTransitionDetails(proposalId: number): Observable<EditDataObject> {
        if (this.global.isMockDataMode()) {
            const mockURL: string = 'assets/mock-data/proposal-tax-transition.json';
            return this.httpUtil.doMockPostStronglyTyped<EditDataObject>(mockURL, 1000);
        } else {
            if (proposalId) {
                const apiUrl: string = this.carGlobal.configService.routeFormatter(
                    this.carGlobal.configService.getEndPoint('fetchTaxTransitionAnalysis'),
                    [
                        {
                            key: 'proposalId',
                            value: proposalId
                        }
                    ]
                );
                return this.http.get<EditDataObject>(apiUrl).pipe(
                    map((response: EditDataObject): EditDataObject => {
                        return response;
                    })
                );
            }
        }
    }

    public submitTaxTransitionDetails(params: SubmitDataObject): Observable<SubmitTaxTransitionResponse> {
        if (this.global.isMockDataMode()) {
            const mockURL: string = 'assets/mock-data/proposal-tax-transition.json';
            return this.httpUtil.doMockPostStronglyTyped<SubmitTaxTransitionResponse>(mockURL, 1000);
        } else {
            if (params) {
                const apiUrl: string = this.carGlobal.configService.routeFormatter(
                    this.carGlobal.configService.getEndPoint('submitTaxTransitionAnalysis'),
                    []
                );
                return this.http.post<SubmitTaxTransitionResponse>(apiUrl, params).pipe(
                    map((response: SubmitTaxTransitionResponse): SubmitTaxTransitionResponse => {
                        return response;
                    })
                );
            }
        }
    }

    public saveExitTaxTransitionDetails(params: EditDataObject): Observable<EditDataObject> {
        if (this.global.isMockDataMode()) {
            const mockURL: string = 'assets/mock-data/proposal-tax-transition.json';
            return this.httpUtil.doMockPostStronglyTyped<EditDataObject>(mockURL, 1000);
        } else {
            if (params) {
                const apiUrl: string = this.carGlobal.configService.routeFormatter(
                    this.carGlobal.configService.getEndPoint('saveExitTaxTransitionAnalysis'),
                    []
                );
                return this.http.post<EditDataObject>(apiUrl, params).pipe(
                    map((response: EditDataObject): EditDataObject => {
                        return response;
                    })
                );
            }
        }
    }

    public getTaxAnalysisDetails(proposalId: number): Observable<CurrentAccountDetails> {
        if (this.global.isMockDataMode()) {
            const mockURL: string = 'assets/mock-data/current-account-details.json';
            return this.httpUtil.doMockPostStronglyTyped<CurrentAccountDetails>(mockURL, 0);
        } else {
            const apiUrl: string = this.carGlobal.configService.routeFormatter(
                this.carGlobal.configService.getEndPoint('proposal'),
                [{
                    key: 'proposalId',
                    value: proposalId
                }]
            );
            return this.http.get<CurrentAccountDetails>(apiUrl).pipe(
                share(),
                map((response: CurrentAccountDetails): CurrentAccountDetails => {
                    return response;
                })
            );
        }
    }

    public getGeneratedDocument(proposalId: number, proposalName: string): Observable<void> {
        const proposalNameFilter: string = proposalName.replace(/[^a-zA-Z0-9]+/ig, '');
        const fileName: string = `${proposalId}_${proposalNameFilter}`;
        return this.openDownloadedDocument(proposalId, fileName);
    }

    public openDownloadedDocument(proposalId: number, fileName: string) {
        try {
            const apiUrl: string = this.carGlobal.configService.routeFormatter(
                this.carGlobal.configService.getEndPoint('taxtransitionAnalysis'),
                [
                    {
                        key: 'proposalId',
                        value: proposalId
                    }
                ]
            );

            return this.http.get<Blob>(apiUrl, {
                headers: new HttpHeaders({ 'Content-Type': ContentTypes.Zip }),
                responseType: 'blob' as 'json'
            }).pipe(
                map((objectResponse: Blob) => {
                    const fileUrl = window.URL.createObjectURL(objectResponse);
                    this.getDownloadHtmlElement(fileName, fileUrl).click();
                })
            );
        } catch (error) {
            return throwError(error);
        }
    }

    public getDownloadHtmlElement(fileName: string, linkRefence: string): HTMLAnchorElement {
        const link = document.createElement('a');
        link.href = linkRefence;
        link.target = '_blank';
        link.download = fileName;
        return link;
    }
}
