/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, Input, OnInit } from '@angular/core';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import * as _ from 'lodash';
import {
    CommandText,
    InvestmentObjective,
    InvestmentProgramTypes,
    OptionByObjectiveDropDown,
    OptionExploreStrategy,
    OptionFilterStrategies,
    RiskSnapPoints,
    RiskToleranceLevel,
    RiskWidgetTypes,
    StrategyMenu,
    TaxManagementLevelValues
} from '../../../model/enums';
import { InvestmentProgram } from '../../../model/investment-program';
import { Account, Strategy, Type } from '../../../model/proposal';
import { DelegationService } from '../../../service/delegation.service';
import { InvestmentProgramService } from '../../../service/investment-program.service';
import { StrategiesService } from '../../../service/strategies.service';

@Component({
    selector: 'sei-car-filter-strategy-risk-rank',
    templateUrl: './filter-strategy-risk-rank.component.html'
})
export class FilterStrategyRiskRankComponent extends ParentComponentSubscriptionManager implements OnInit {

    @Input()
    public account: Account;

    @Input()
    public firmId: number;

    @Input()
    public selectedOptionExploreStrategy: number;

    @Input()
    public riskToleranceScore: number;

    @Input()
    public allExploreStrategies: Strategy[];

    private riskRankByAccount: number;

    private growthSnapValues: number[];
    private stabilitySpanValues: number[];
    private riskRankMediumSnapValues: number[];
    private allRiskSnapValues: number[];

    public indexSelectedSortByValue: number;
    public filterStrategySorByOptionsInput: Type[];

    public investmentProgramIdFilter: number = 0;
    public exploreStrategyDropDownLabel: string;
    public strategyFilterDropDownDefaultValue: number;
    public snapDefaultRiskScoreValue: number;
    public riskWidgetAvailableValues: number[];
    public riskLevelClassMultiFilter: number[] = [];

    public filteredExploreStrategies: Strategy[] = [];
    public filteredSortedByStrategies: Strategy[];
    public filterStrategyDropDownData: Type[] = [];

    public riskWidgetTypes: typeof RiskWidgetTypes = RiskWidgetTypes;
    public ribbonTypes: typeof StrategyMenu = StrategyMenu;
    public optionExploreStrategy: typeof OptionExploreStrategy = OptionExploreStrategy;

    public strategyFilterLoaded = false;

    constructor(
        private strategiesService: StrategiesService,
        private investmentProgramService: InvestmentProgramService,
        private delegationService: DelegationService
    ) {
        super('FilterStrategyRiskRank');
    }

    public ngOnInit(): void {
        this.initializeValues();
        this.exploreStrategyDropDownLabel =
            this.strategiesService.setLabelDropDownExploreStrategy(this.selectedOptionExploreStrategy);

        this.riskRankByAccount =
            this.strategiesService.convertRiskToleranceScoreIntoLevel(
                (this.riskToleranceScore) ? -1 : this.riskToleranceScore
            );

        this.filterStrategySorByOptionsInput = this.strategiesService.getSortByDropDownValues();
        this.indexSelectedSortByValue = OptionFilterStrategies.Suggested;
        this.getStrategyDropDownData();
    }

    private initializeValues(): void {

        // Note: Initially set the default to Mutual Fund
        this.investmentProgramIdFilter = InvestmentProgramTypes.MutualFund;

        this.growthSnapValues = [
            RiskSnapPoints.MediumMedium,
            RiskSnapPoints.MediumHigh,
            RiskSnapPoints.HighLow,
            RiskSnapPoints.HighMedium
        ];

        this.stabilitySpanValues = [
            RiskSnapPoints.LowLow,
            RiskSnapPoints.LowMedium,
            RiskSnapPoints.LowHigh,
            RiskSnapPoints.MediumLow
        ];

        this.riskRankMediumSnapValues = [
            RiskSnapPoints.MediumLow,
            RiskSnapPoints.MediumMedium
        ];
        this.allRiskSnapValues = [
            RiskSnapPoints.None,
            RiskSnapPoints.LowLow,
            RiskSnapPoints.LowMedium,
            RiskSnapPoints.LowHigh,
            RiskSnapPoints.MediumLow,
            RiskSnapPoints.MediumMedium,
            RiskSnapPoints.MediumHigh,
            RiskSnapPoints.HighLow,
            RiskSnapPoints.HighMedium
        ];
    }

    private getStrategyDropDownData(): void {
        switch (this.selectedOptionExploreStrategy) {
            case OptionExploreStrategy.AssetAllocation:
                this.filterStrategyDropDownData = this.strategiesService.getByAssetAllocationOptions();
                this.applyBusinessRulesByAssetAllocation(true);
                this.filterExploreStrategies();
                this.strategyFilterLoaded = true;
                break;

            case OptionExploreStrategy.Objective:
                this.filterStrategyDropDownData = this.strategiesService.getByObjectiveOptions();
                this.applyBusinessRulesByObjective(true);
                this.filterExploreStrategies();
                this.strategyFilterLoaded = true;
                break;

            case OptionExploreStrategy.InvestmentRisk:
                this.applyBusinessRulesByInvestment();
                this.filterExploreStrategies();
                this.strategyFilterLoaded = true;
                break;

            case OptionExploreStrategy.AllAvailable:
                // NOTE: with all we are filtering explore strategy after api call in subscribe
                this.getInvestmentPrograms();
                break;

            default:
                this.applyBusinessRulesByInvestment();
                this.filterExploreStrategies();
                this.strategyFilterLoaded = true;
                break;
        }
    }

    private getInvestmentPrograms() {
        this.investmentProgramService
            .getInvestmentPrograms(this.firmId)
            .subscribe((programs) => {
                programs.forEach(
                    (invProgram: InvestmentProgram) => {
                        this.filterStrategyDropDownData.push({
                            id: invProgram.investmentProgramId,
                            description: invProgram.investmentProgramDescription
                        });
                    });
                this.applyBusinessRulesAllAvailable();
                this.filterExploreStrategies();
                this.strategyFilterLoaded = true;
            });
    }

    private applyBusinessRulesByInvestment(): void {
        this.riskWidgetAvailableValues = this.strategiesService.setArrayValues(this.allRiskSnapValues);
        this.snapDefaultRiskScoreValue =
            this.riskRankByAccount === 0
                ? RiskToleranceLevel.Medium
                : this.riskRankByAccount;
        this.riskLevelClassMultiFilter = this.strategiesService.setArrayValues([this.snapDefaultRiskScoreValue]);
    }

    private applyBusinessRulesAllAvailable(): void {
        const mutualFundsDefaultValue = 1;
        this.riskWidgetAvailableValues = this.strategiesService.setArrayValues(this.allRiskSnapValues);
        this.riskLevelClassMultiFilter = this.riskWidgetAvailableValues;
        this.snapDefaultRiskScoreValue = 0;
        this.strategyFilterDropDownDefaultValue = mutualFundsDefaultValue;
    }

    private applyBusinessRulesByObjective(defaultValues: boolean): void {
        if (defaultValues) {
            if (this.riskRankByAccount === RiskToleranceLevel.Low) {
                this.strategyFilterDropDownDefaultValue = OptionByObjectiveDropDown.Stability;
            } else {
                this.strategyFilterDropDownDefaultValue = OptionByObjectiveDropDown.Growth;
            }
        } else {
            if (this.strategyFilterDropDownDefaultValue === OptionByObjectiveDropDown.Growth) {
                this.riskRankByAccount = this.strategiesService.convertRiskToleranceScoreIntoLevel(
                    this.riskToleranceScore === null
                        ? -1
                        : this.riskToleranceScore
                );
                if (this.riskRankByAccount !== RiskToleranceLevel.High) {
                    this.riskRankByAccount = RiskToleranceLevel.Medium;
                }
            } else {
                this.riskRankByAccount = RiskToleranceLevel.Low;
            }
        }

        this.riskWidgetAvailableValues =
            this.strategiesService.setArrayValues(
                this.strategyFilterDropDownDefaultValue === OptionByObjectiveDropDown.Growth
                    ? this.growthSnapValues
                    : this.stabilitySpanValues
            );

        this.snapDefaultRiskScoreValue = this.riskRankByAccount === 0 ? RiskToleranceLevel.Medium : this.riskRankByAccount;
        this.riskLevelClassMultiFilter = this.strategiesService.setArrayValues([this.snapDefaultRiskScoreValue]);
    }

    private applyBusinessRulesByAssetAllocation(defaultValues: boolean): void {

        if (defaultValues) {
            this.strategyFilterDropDownDefaultValue =
                this.riskRankByAccount === 0
                    ? RiskToleranceLevel.Medium
                    : this.riskRankByAccount;
        }

        this.riskWidgetAvailableValues =
            this.strategiesService.setArrayValues(
                this.strategyFilterDropDownDefaultValue === RiskToleranceLevel.Medium
                    ? this.riskRankMediumSnapValues
                    : [this.strategyFilterDropDownDefaultValue]
            );

        this.riskLevelClassMultiFilter = this.strategiesService.setArrayValues([this.strategyFilterDropDownDefaultValue]);

        this.snapDefaultRiskScoreValue = this.strategyFilterDropDownDefaultValue;
    }

    private filterExploreStrategies(): void {
        if (this.allExploreStrategies) {
            this.filteredExploreStrategies =
                this.allExploreStrategies.filter((strategy: Strategy) => {
                    if (this.riskLevelClassMultiFilter.includes(strategy.riskRank === null ? RiskSnapPoints.None : strategy.riskRank)) {
                        return strategy;
                    }
                });

            if (this.selectedOptionExploreStrategy === OptionExploreStrategy.AllAvailable) {
                this.filteredExploreStrategies =
                    this.filteredExploreStrategies.filter((strategy: Strategy) => {
                        if (
                            (strategy.investmentProgramId ===
                                (this.investmentProgramIdFilter === 0
                                    ? strategy.investmentProgramId
                                    : this.investmentProgramIdFilter))
                        ) {
                            return strategy;
                        }
                    });
            } else if (this.selectedOptionExploreStrategy === OptionExploreStrategy.Objective) {
                this.filteredExploreStrategies = this.filteredExploreStrategies.filter(
                    (strategy: Strategy) => {
                        if (
                            (this.strategyFilterDropDownDefaultValue === OptionByObjectiveDropDown.Stability &&
                                strategy.investmentObjectiveNm === InvestmentObjective.Stability) ||
                            (this.strategyFilterDropDownDefaultValue === OptionByObjectiveDropDown.Growth &&
                                strategy.investmentObjectiveNm === InvestmentObjective.Growth)) {
                            return strategy;
                        }
                    }
                );
            }

            this.filteredSortedByStrategies = this.filteredExploreStrategies;
        }
    }

    public onStrategyFilterDropdownSelected(event: number): void {
        this.strategyFilterDropDownDefaultValue = event;

        switch (this.selectedOptionExploreStrategy) {
            case OptionExploreStrategy.AssetAllocation:
                this.applyBusinessRulesByAssetAllocation(false);
                break;
            case OptionExploreStrategy.Objective:
                this.applyBusinessRulesByObjective(false);
                break;
            case OptionExploreStrategy.AllAvailable:
                this.investmentProgramIdFilter = event;
                break;
        }

        this.filterExploreStrategies();

        this.indexSelectedSortByValue = OptionFilterStrategies.Suggested;
    }

    public onEventRiskLevelSelected(event: number): void {
        if (event === 0) {
            switch (this.selectedOptionExploreStrategy) {
                case OptionExploreStrategy.AssetAllocation:
                    if (this.strategyFilterDropDownDefaultValue === RiskToleranceLevel.Medium) {
                        this.riskLevelClassMultiFilter = this.strategiesService.setArrayValues(this.riskRankMediumSnapValues);
                    }
                    break;

                case OptionExploreStrategy.Objective:
                    this.riskLevelClassMultiFilter =
                        this.strategiesService.setArrayValues(
                            this.strategyFilterDropDownDefaultValue === OptionByObjectiveDropDown.Growth
                                ? this.growthSnapValues
                                : this.stabilitySpanValues
                        );
                    break;

                case OptionExploreStrategy.AllAvailable:
                case OptionExploreStrategy.InvestmentRisk:
                default:
                    this.riskLevelClassMultiFilter = this.strategiesService.setArrayValues(this.allRiskSnapValues);
                    break;
            }
        } else {
            this.riskLevelClassMultiFilter = this.strategiesService.setArrayValues([event]);
        }

        this.filterExploreStrategies();

        this.indexSelectedSortByValue = OptionFilterStrategies.Suggested;
    }

    public onSortByDropDownSelected(event: number): void {

        switch (event) {
            case OptionFilterStrategies.Suggested:
                this.filterExploreStrategies();
                break;

            case OptionFilterStrategies.MinimizeRisk:
                this.filteredSortedByStrategies =
                    this.filteredExploreStrategies.sort(
                        (strategyLeft, strategyRight) => {
                            return strategyLeft.standardDeviation - strategyRight.standardDeviation;
                        }
                    );
                // this.orderByAdditonaField('standardDeviation');
                break;

            case OptionFilterStrategies.MaximizeReturn:
                this.filteredSortedByStrategies =
                    this.filteredExploreStrategies.sort(
                        (strategyLeft, strategyRight) => {
                            return strategyRight.projectedReturn - strategyLeft.projectedReturn;
                        }
                    );
                // this.orderByAdditonaField('projectedReturn');
                break;

            case OptionFilterStrategies.ActivelyManaged:
            case OptionFilterStrategies.PassivelyManaged:
                this.filteredSortedByStrategies =
                    this.filteredExploreStrategies.filter(
                        (strategy: Strategy) => {
                            if (strategy.taxManagementLevel ===
                                (event === OptionFilterStrategies.ActivelyManaged
                                    ? TaxManagementLevelValues.Active
                                    : TaxManagementLevelValues.Passive)
                            ) {
                                return strategy;
                            }
                        });
                // this.orderByAdditonaField('standardDeviation');
                this.filteredSortedByStrategies = _.orderBy(this.filteredSortedByStrategies, ['standardDeviation'], ['asc']);
                break;
            default:
                break;
        }
        this.indexSelectedSortByValue = event;
    }

    // private orderByAdditonaField(additionalFieldToSortBy: string) {
    //     if (this.account.type.taxQualified) {
    //         this.filteredSortedByStrategies =
    //             _.orderBy(this.filteredSortedByStrategies,
    //                 [additionalFieldToSortBy ], ['asc', 'asc', 'asc', 'asc']);
    //     } else {
    //         this.filteredSortedByStrategies =
    //             _.orderBy(this.filteredSortedByStrategies,
    //                 ['advHierarchyNonqualSortNum', 'advModelSortNum', 'name', additionalFieldToSortBy], ['asc', 'asc', 'asc', 'asc']);
    //     }
    // }

    public onAccountChange(): void {
        this.delegationService.publish(CommandText.UpdateRiskWidget);
    }
}
