/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { createSelector } from '@ngrx/store';

import { AuthState } from './auth-slice.reducers';

/**
 * Default selector for CarAuthenticationSlice
 * For testing purposes, we need an empty object to return or undefined is encountered.
 * @param state entire store state for slice
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const selectAuthState = (state: any) =>
    state.carAuthenticationSlice ? state.carAuthenticationSlice : {};

/**
 * returns the isLoggedIn flag only from CarAuth Slice
 */
export const isLoggedIn = createSelector(
    selectAuthState,
    (auth: AuthState) => auth.loggedIn
);

/**
 * returns the inverse of isLoggedIn flag only from CarAuth Slice
 */
export const isLoggedOut = createSelector(
    isLoggedIn,
    (loggedIn: boolean) => !loggedIn
);

export const getCarUserProfile = createSelector(
    selectAuthState,
    (state: AuthState) => state.userProfile
);
