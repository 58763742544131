/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { SpinnerService } from '@CarServices/system/spinner.service';
import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WipAccountStatus, WipSections } from '../../model/enums';
import { WipCheckList } from '../../model/proposal';


@Component({
    selector: 'car-wip-checklist-action-button',
    templateUrl: './wip-checklist-action-button.component.html'
})
export class WipChecklistActionButtonComponent {
    public wipAccountStatus: typeof WipAccountStatus = WipAccountStatus;

    @Input()
    public wipCheckList: WipCheckList;

    @Input()
    public buttonDisabled: boolean = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private spinnerService: SpinnerService
    ) { }

    public navigate() {
        // Note: For AccountDetails we don't provide the full route.
        const segments = this.wipCheckList.route.split('/');
        if (segments && segments.length === 4 && segments[0] === WipSections.Scenarios && segments[2] === WipSections.AccountDetails) {
            this.router.navigate([segments[0], segments[1], segments[2], segments[3]], { relativeTo: this.route });
        } else {
            this.router.navigate([this.wipCheckList.route]);
        }
    }
}
