/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { SeiPayload } from '../../model/sei-payload';

@Injectable({
    providedIn: 'root'
})
export class SeiJsonSerializerService {
    /**
     * Serializes a data object to meet SEI payload standards
     * ToDo: Provide a way to inject key property for SEI Payload object
     * @param data Data object to wrap SEI Payload standard
     */
    public serialize<T>(data: T, includeWrapper: boolean): SeiPayload {
        const obj = includeWrapper ? { account: data } : data;

        return {
            data: [obj]
        };
    }

    /**
     * Constructs a JSON object from JSON returned from Rest call
     * Assumes REST call has SEI Payload standard
     * supports body property for when REST call observes: response option
     * @param response response object thats in assumed SEI Payload structure
     * @param dataKeyIndex key to property in data[] array to retrieve from
     */
    public deserialize<T>(response, dataKeyIndex: number | string): T {
        if (!response) {
            return null;
        }

        if (response.body) {
            return response.body.data[0][dataKeyIndex];
        }

        if (response.data[0] && response.data[0][dataKeyIndex]) {
            return response.data[0][dataKeyIndex] as T;
        }

        // return what ever the first element is incase there is no key provided
        return response.data[0] as T;
    }
}
