/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccountType } from '@CarInterfaces/account';
import { SeiPayload } from '@CarInterfaces/sei-payload';
import { GlobalService } from '@CarServices/system/global.service';
import { map, Observable, of, share } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AccountTypesService {

    accountTypes: AccountType[] = [];

    constructor(
        private readonly global: GlobalService
    ) { }

    getAccountTypes(advisorId: number): Observable<AccountType[]> {
        if (this.accountTypes.length === 0) {
            return this.global.httpService.getData(
                this.global.configService.routeFormatter(
                    this.global.configService.getEndPoint('AccountTypes'),
                    [
                        {
                            key: 'advisorId',
                            value: advisorId
                        }
                    ]
                )
            ).pipe(share(), map((response: HttpResponse<SeiPayload>) => {
                this.accountTypes = response.body.data[0].accountTypes.sort((a: AccountType, b: AccountType) => a.sortOrder = b.sortOrder);
                return this.accountTypes;
            }));
        } else {
            return of(this.accountTypes);
        }
    }
}
