/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ImplementModalState } from '@CarModels/enums';
import { GlobalService } from '@CarServices/system/global.service';
import { ImplementedAccountResponse, ImplementedAccountStatus, ImplementProposalOptions } from '@sei/advisor-client-review-proposal-ux';
import { HttpUtil } from '@sei/common-swp-components-lib-ux';
import { BehaviorSubject, map, Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class ImplementModalService {
    private validationErrors: BehaviorSubject<string[]> = new BehaviorSubject([]);
    private modalState: BehaviorSubject<ImplementModalState> = new BehaviorSubject(ImplementModalState.ImplementForm);
    private successfulAccountStatuses: BehaviorSubject<ImplementedAccountStatus[]> = new BehaviorSubject([]);
    private isLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private refreshDashboard: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private errorCounter: BehaviorSubject<number> = new BehaviorSubject(0);
    private readonly MAX_ERROR_COUNT_FOR_SUBMISSION = 0;

    constructor(private globalService: GlobalService,
        private http: HttpClient,
        private httpUtil: HttpUtil,
        private router: Router) { }

    public updateModalState(modalState: ImplementModalState): void {
        this.modalState.next(modalState);
    }

    public getCurrentModalState(): ImplementModalState {
        return this.modalState.getValue();
    }

    public setValidationErrors(errors: string[]): void {
        this.validationErrors.next(errors);
    }

    public getValidationErrors(): string[] {
        return this.validationErrors.getValue();
    }

    public setSuccessfulAccountStatuses(successfulAccountStatuses: ImplementedAccountStatus[]): void {
        this.successfulAccountStatuses.next(successfulAccountStatuses);
    }

    public getSuccessfulAccountStatuses(): ImplementedAccountStatus[] {
        return this.successfulAccountStatuses.getValue();
    }

    public getIsLoading(): boolean {
        return this.isLoading.getValue();
    }

    public setIsLoading(isLoading: boolean): void {
        this.isLoading.next(isLoading);
    }

    public getIsLoadingBehaviorSubject(): BehaviorSubject<boolean> {
        return this.isLoading;
    }

    public getRefreshDashboardBehaviorSubject(): BehaviorSubject<boolean> {
        return this.refreshDashboard;
    }

    public refreshDashboardProposals(): void {
        this.refreshDashboard.next(true);
    }

    public resetRefreshSubject(): void {
        this.refreshDashboard.next(false);
    }

    public addErrorCount(): void {
        this.errorCounter.next(this.errorCounter.getValue() + 1);
    }

    public getErrorCount(): number {
        return this.errorCounter.getValue();
    }

    public hasExceededMaxErrors(): boolean {
        return this.getErrorCount() > this.MAX_ERROR_COUNT_FOR_SUBMISSION;
    }

    public resetErrorCount(): void {
        this.errorCounter.next(0);
    }

    public resetModalState() {
        this.updateModalState(ImplementModalState.ImplementForm);
        this.setValidationErrors([]);
        this.setSuccessfulAccountStatuses([]);
        this.resetErrorCount();
        this.resetRefreshSubject();
        this.setIsLoading(false);
    }

    public navigateToDAO(): void {
        const carConfig = this.globalService.configService.environment.carConfig;
        const hostApplicationRedirect: string = `${carConfig.transportProtocol}://${carConfig.server}`;
        const newAccountProxyPath: string = `${carConfig.newAccountProxyGroup}`;
        window.open(`${hostApplicationRedirect}/${newAccountProxyPath}/#/PendingAccounts`);
    }

    public createAccountsInDAO(implementProposalOptions: ImplementProposalOptions): Observable<ImplementedAccountResponse> {
        const mock: boolean = false;
        if (mock) {
            this.isLoading.next(true);
            return this.httpUtil.doMockPostStronglyTyped('assets/mock-data/implement-proposal-accounts.json', 5000)
                .pipe(map((response: ImplementedAccountResponse) => {
                    this.isLoading.next(false);
                    return response;
                }));
        } else {
            this.isLoading.next(true);
            const apiUrl = this.globalService.configService.routeFormatter(
                this.globalService.configService.getEndPoint('createPendingAccounts')
            );
            return this.http
                .post<ImplementedAccountResponse>(apiUrl, implementProposalOptions)
                .pipe(map((response: ImplementedAccountResponse) => {
                    this.isLoading.next(false);
                    return response;
                }));
        }
    }

    public navigateToWipChecklist(proposalId: number): void {
        const url: string = `/Proposal/WIP/${proposalId}`;
        this.router.navigateByUrl(url);
    }

}
