/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { SiteminderGuard } from '@CarAuth/siteminder.guard';
import { Routes } from '@angular/router';
import { ProposalResolverService } from '@sei/advisor-client-review-proposal-ux';
import { PocTreeMapComponent } from './PresentationPOC/tree-map-chart.component';
import { ChecklistComponent } from './checklist/checklist.component';
import { PlaywrightPdfViewerComponent } from './playwright-pdf-viewer/playwright-pdf-viewer.component';
import { ProposalPresentationChecklistComponent } from './proposal-presentation-checklist/proposal-presentation-checklist.component';
import { SettingsComponent } from './settings/settings.component';

export const PRESENTATION_ROUTES: Routes = [
    {
        path: 'Proposal/Presentation/Settings/:proposalId',
        component: SettingsComponent,
        canActivate: [SiteminderGuard],
        resolve: {
            proposal: ProposalResolverService
        }
    },
    {
        path: 'Proposal/Presentation/CheckList/:proposalId',
        component: ChecklistComponent,
        canActivate: [SiteminderGuard],
        resolve: {
            proposal: ProposalResolverService
        }
    },
    {
        path: 'Proposal/Presentation/Playwright/Viewer/:documentType/:proposalId',
        component: PlaywrightPdfViewerComponent,
        canActivate: [SiteminderGuard]
    },
    {
        path: 'Proposal/Presentation/POC/TreeMapChart/:proposalId',
        component: PocTreeMapComponent,
        canActivate: [SiteminderGuard]
    },
    {
        path: 'Proposal/Presentation/:proposalId',
        component: ProposalPresentationChecklistComponent,
        canActivate: [SiteminderGuard]
    }
];

