/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { ModuleWithProviders, NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { EffectsModule } from '@ngrx/effects';
import * as fromAuthReducer from './auth-slice.reducers';
import { AuthEffects } from './auth.effects';

@NgModule({
    imports: [
        StoreModule.forFeature(
            fromAuthReducer.authModuleSliceName,
            fromAuthReducer.AuthenticationSlice
        ),
        EffectsModule.forFeature([AuthEffects])
    ]
})
export class AuthModule {
    static forRoot(): ModuleWithProviders<AuthModule> {
        return {
            ngModule: AuthModule
        };
    }
}
