/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
    standalone: true,
    selector: 'sei-car-checklist-zoom-slider',
    templateUrl: './checklist-zoom-slider.component.html',
    styleUrls: ['./checklist-zoom-slider.component.scss'],
    imports: [
        CommonModule,
        FormsModule
    ]
})
export class ChecklistZoomSliderComponent {
    public readonly MIN_ZOOM_VALUE = 50;
    public readonly MAX_ZOOM_VALUE = 200;
    public readonly RANGE_STEP_VALUE = 5;
    public zoomValue: number = 100;

    @Output()
    public zoomValueChange: EventEmitter<number> = new EventEmitter();

    public onRangeChange(value: number): void {
        this.zoomValue = value;
        this.zoomValueChange.emit(value / 100);
    }

}
