/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { BannerMessageConstants, WarningModalConstants } from '@CarModels/constants';
import { ExistingAccountData } from '@CarModels/existing-accounts';
import { ExistingAccountsService } from '@CarServices/existing-accounts/existing-accounts.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Account, Client, CommandText, DelegationService, GenericErrorService, Proposal, ProposalAccountService, ProposalService, RightHandFooterFunctions } from '@sei/advisor-client-review-proposal-ux';
import { BannerMessageType, ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import _ from 'lodash';
import { Subscription } from 'rxjs';

@Component({
    selector: 'sei-car-existing-accounts-pick-list',
    templateUrl: './existing-accounts-pick-list.component.html',
    styleUrls: ['./existing-accounts-pick-list.component.scss']
})
export class ExistingAccountsPickListComponent extends ParentComponentSubscriptionManager implements OnInit {

    public existingAccountsNotAddedToProposal: Map<number, ExistingAccountData[]>;
    public proposal: Proposal;
    public footerFunction: string = RightHandFooterFunctions.SelectExistingAccounts;
    public totalNumberOfExistingAccounts: number = 0;
    public totalNumberOfProposedAccounts: number = 0;
    public isAccountTotalBelowThreshold: boolean = true;
    public selectedExistingAccounts: ExistingAccountData[] = [];
    public isExistingAccountsCallInProgress: boolean = false;
    public unselectedAccountsModalMessage: WarningModalConstants = WarningModalConstants.MODAL_MESSAGE_UNSELECTED_ACCOUNTS;
    public showUnselectedAccountsModal: boolean = false;
    public addSelectedAccountClicked: boolean = false;
    public onPortfolioMappingComplete: boolean = false;
    public showMappingModal: boolean = false;
    public getHoldingsCallFailed: boolean = false;
    public typeWarning: BannerMessageType = BannerMessageType.warning;
    public getHoldingsCallFailedWarning: string = BannerMessageConstants.GET_HOLDINGS_CALL_FAILED;

    constructor(private existingAccountsService: ExistingAccountsService,
        private proposalService: ProposalService,
        private delegationService: DelegationService,
        private proposalAccountService: ProposalAccountService,
        private genericErrorService: GenericErrorService,
        private router: Router) {
        super('ExistingAccountsPickListComponent');
    }

    ngOnInit(): void {
        const proposalSubscription: Subscription = this.proposalService.currentProposal.subscribe({
            next: (proposal: Proposal): void => {
                if (proposal) {
                    this.proposalService.proposalBackup = _.cloneDeep(proposal);
                    this.proposal = proposal;
                    this.totalNumberOfProposedAccounts =
                        proposal?.scenarios[0]?.accounts?.filter((account: Account) => !account.currentAccountId).length;
                    this.isAccountTotalBelowThreshold =
                        this.proposalAccountService
                            .checkIfMaximumAccountsHasBeenReached(this.selectedExistingAccounts, proposal?.scenarios[0]?.accounts);
                    setTimeout(() => {
                        this.proposal?.scenarios[0]?.accounts?.some((account: Account) => account.currentAccountId) ?
                            this.delegationService.publish(CommandText.SaveAndExitButton) :
                            this.delegationService.publish(CommandText.AddSelectedAccountsButton);
                    });
                }
            },
            error: (error) => {
                this.genericErrorService.setGenericError({ code: '', description: `${error.message}` });
            }
        });
        this.subscriptions.push(proposalSubscription);

        const existingAccountsSubscription: Subscription =
            this.existingAccountsService.existingAccounts.subscribe({
                next: (existingAccounts: Map<number, ExistingAccountData[]>): void => {
                    if (existingAccounts.size > 0) {
                        this.existingAccountsNotAddedToProposal = existingAccounts;
                        this.totalNumberOfExistingAccounts =
                            this.existingAccountsService.convertExistingAccountsMapToArray(existingAccounts)?.length;
                    }
                },
                error: (error) => {
                    this.genericErrorService.setGenericError({ code: '', description: `${error.message}` });
                }
            });
        this.subscriptions.push(existingAccountsSubscription);

        const selectedExistingAccountsSubscription: Subscription =
            this.existingAccountsService.existingAccountsToBeAddedToProposal
                .subscribe({
                    next: (selectedExistingAccounts: ExistingAccountData[]) => {
                        this.selectedExistingAccounts = _.cloneDeep(selectedExistingAccounts);
                        this.isAccountTotalBelowThreshold =
                            this.proposalAccountService
                                .checkIfMaximumAccountsHasBeenReached(this.selectedExistingAccounts, this.proposal?.scenarios[0]?.accounts);
                        if (selectedExistingAccounts.length) {
                            this.delegationService.publish(CommandText.EnableProposedAccountsButton);
                        } else {
                            this.delegationService.publish(CommandText.DisableAddProposedAccountsButton);
                        }
                    },
                    error: (error) => {
                        this.genericErrorService.setGenericError({ code: '', description: `${error.message}` });
                    }
                });
        this.subscriptions.push(selectedExistingAccountsSubscription);

        const delegationSubscription: Subscription = this.delegationService.refresh().subscribe({
            next: (command: string): void => {
                switch (command) {
                    case CommandText.AddSelectedAccounts:
                        this.showAccountRemovalModal();
                        this.addSelectedAccountClicked = true;
                        break;
                    default:
                        return null;
                }
            },
            error: (error) => {
                this.genericErrorService.setGenericError({ code: '', description: `${error.message}` });
            }
        });
        this.subscriptions.push(delegationSubscription);

        const existingAccountCallStatusSubscription: Subscription =
            this.existingAccountsService.isExistingAccountCallInProgress.subscribe({
                next: (callStatus: boolean) => {
                    this.isExistingAccountsCallInProgress = callStatus;
                },
                error: (error) => {
                    this.genericErrorService.setGenericError({ code: '', description: `${error.message}` });
                }
            });
        this.subscriptions.push(existingAccountCallStatusSubscription);

        const existingAcconutsCallFailureStatus: Subscription =
            this.existingAccountsService.existingAccountCallFailed.subscribe({
                next: (failureStatus: boolean): void => {
                    this.getHoldingsCallFailed = failureStatus;
                }
            });
        this.subscriptions.push(existingAcconutsCallFailureStatus);
    }

    public getClientFromEntityId(clients: Client[], entityId: number): Client {
        return this.existingAccountsService.getClientByEntityId(clients, entityId);
    }

    public showAccountRemovalModal(): void {
        const allAddedExistingAccountsStillSelected: boolean = this.proposal?.scenarios[0]?.accounts
            ?.filter((account: Account) => account?.currentAccountId)
            ?.every((account: Account) => this.selectedExistingAccounts
                ?.some((existingAccount: ExistingAccountData) => Number(existingAccount?.accountId) === Number(account?.currentAccountId)));
        if (allAddedExistingAccountsStillSelected) {
            this.showMappingModal = true;
        } else {
            this.showUnselectedAccountsModal = true;
        }
    }

    public onUnselectedAccountsModalContinue(): void {
        this.showUnselectedAccountsModal = false;
        this.showMappingModal = true;
    }

    public onUnselectedAccountsModalCancel(): void {
        this.proposalService.changedProposal(this.proposalService.proposalBackup);
        this.showUnselectedAccountsModal = false;
    }

    public resetMappingSemaphoreValues(): void {
        this.addSelectedAccountClicked = false;
        this.showMappingModal = false;
    }

}
