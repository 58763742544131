/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, take } from 'rxjs/operators';
import * as fromProposal from '../state';
import * as proposalActions from '../state/proposal.actions';

@Injectable()
export class ProposalExistsGuard {
    constructor(private store: Store<fromProposal.State>) { }
    /**
     * This method creates an observable that waits for the `loaded` property
     * of the collection state to turn `true`, emitting one time once loading
     * has finished.
     */
    waitToLoad(): Observable<boolean> {
        return this.store.pipe(
            select(fromProposal.getSelectedProposalLoaded),
            filter((selectedProposalLoaded: boolean) => selectedProposalLoaded),
            take(1),
            catchError((err) => {
                return of(false);
            })
        );
    }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        // return of(true);
        const id = route.params['proposalId'];
        this.store.dispatch(new proposalActions.GetProposal({ proposalId: +id }));
        return this.waitToLoad();
    }
}
