/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
// tslint:disable
import { Injectable } from '@angular/core';
import { BaseFactory } from './base-factory';
@Injectable({
    providedIn: 'root'
})
export abstract class BaseFactoryAbstract<T> implements BaseFactory<T> {
    public createObject(_type?): T {
        return null;
    }
}
