<div class="form-group risk-widget-right" id="Risk">
     <div class="risk-widget-key">
          <div class="risk-capacity"><span class="risk-icon capacity"></span>Client Risk</div>
          <div class="risk-investment"><span class="risk-icon investment"></span>Strategy Risk</div>
     </div>
     <div class="risk-widget" id="risk">
          <div *ngFor="let riskLevel of riskWidget" class="risk-level" [ngClass]="riskLevel.riskLevel"
               [class.active]="riskLevel.active">
               <div class="risk-increment" [class.active]="riskLevel.low.active"
                    [class.disabled]="!riskLevel.low.enabled"
                    [hidden]="riskLevel.low.classId === riskLevelClasses.NoneClass"
                    (click)="onSnapPointSelected(riskLevel.riskLevel, riskLevel.low.classId)"></div>
               <div class="risk-increment" [class.active]="riskLevel.medium.active"
                    [class.disabled]="!riskLevel.medium.enabled"
                    [hidden]="riskLevel.medium.classId === riskLevelClasses.NoneClass"
                    (click)="onSnapPointSelected(riskLevel.riskLevel, riskLevel.medium.classId)"></div>
               <div class="risk-increment" [class.active]="riskLevel.high.active"
                    [class.disabled]="!riskLevel.high.enabled"
                    [hidden]="riskLevel.high.classId === riskLevelClasses.NoneClass"
                    (click)="onSnapPointSelected(riskLevel.riskLevel, riskLevel.high.classId)"></div>
          </div>

          <div class="risk-icon capacity" [ngClass]="riskTolerance"></div>
     </div>
</div>
