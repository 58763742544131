/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, share } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ProposalSection, ProposalPreviewResponse, ProposalStatement, AnalyticsBenchmark } from '../model/presentation';
import { GlobalService } from './system/global.service';

@Injectable({
    providedIn: 'root'
})
export class PresentationChecklistService {

    constructor(
        private http: HttpClient,
        protected carGlobal: GlobalService
    ) { }

    public retrievePreviewSectionImages(proposalId: number, statementTypeId: number, proposalSection: ProposalSection) {

        const apiUrl = this.carGlobal.configService.routeFormatter(
            this.carGlobal.configService.getEndPoint('retrievePreviewSectionImages'),
            [
                {
                    key: 'proposalId',
                    value: proposalId
                },
                {
                    key: 'statementTypeId',
                    value: statementTypeId
                }
            ]
        );

        const json = {
            data: [proposalSection]
        };

        return this.http.post(apiUrl, json)
            .pipe(
                map((response: ProposalPreviewResponse[]) => {
                    if (response) {
                        return (response);
                    }
                }));
    }

    // to fetch preview images for report options, this section depends on Reporting Levels section
    public retrievePreviewSectionImagesByProposalStatement(proposalId: number, proposalStatement: ProposalStatement) {

        const apiUrl = this.carGlobal.configService.routeFormatter(
            this.carGlobal.configService.getEndPoint('retrievePreviewSectionImagesByProposalStatement'),
            [
                {
                    key: 'proposalId',
                    value: proposalId
                }
            ]
        );

        const json = {
            data: [proposalStatement]
        };

        return this.http.post(apiUrl, json)
            .pipe(
                share(),
                map((response: ProposalPreviewResponse[]) => {
                    if (response) {
                        return (response);
                    }
                }));
    }

    public retrieveAnalyticsBenchmarkItems(): Observable<AnalyticsBenchmark[]> {
        const apiUrl = this.carGlobal.configService.routeFormatter(
            this.carGlobal.configService.getEndPoint('retrieveAnalyticsBenchmarks')
        );
        return this.http.get<AnalyticsBenchmark[]>(apiUrl);
    }
}
