/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule } from '@angular/router';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { CarUxLibModule } from '@sei/advisor-client-review-proposal-ux';
import { SeiCommonComponentsLibraryModule } from '@sei/common-components-lib-ux';
import { SeiCommonSwpComponentsLibraryModule } from '@sei/common-swp-components-lib-ux';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { fakeBackendProvider } from '../service/fakeBackendProvider';
import { ChecklistComponent } from './checklist/checklist.component';
import { PlaywrightPdfViewerComponent } from './playwright-pdf-viewer/playwright-pdf-viewer.component';
import { PRESENTATION_ROUTES } from './presentation.routes';
import { PreviewComponent } from './preview/preview.component';
import { CoverPageAbstractComponent } from './preview/templates/cover-page/abstract/cover-page-abstract.component';
import { CoverPageCustomComponent } from './preview/templates/cover-page/custom/cover-page-custom.component';
import { CoverPageSimplisticComponent } from './preview/templates/cover-page/simplistic/cover-page-simplistic.component';
import { SettingsComponent } from './settings/settings.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        CarUxLibModule,
        HttpClientModule,
        DragDropModule,
        PdfViewerModule,
        PopoverModule,
        SeiCommonSwpComponentsLibraryModule.forRoot(),
        SeiCommonComponentsLibraryModule.forRoot(),
        RouterModule.forRoot(
            PRESENTATION_ROUTES,
            {
                enableTracing: false, // <-- debugging purposes only
                useHash: true,
                preloadingStrategy: PreloadAllModules
            }
        ),
        CoverPageCustomComponent,
        CoverPageSimplisticComponent,
        CoverPageAbstractComponent
    ],
    exports: [
        RouterModule
    ],
    providers: [
        fakeBackendProvider
    ],
    declarations: [
        SettingsComponent,
        ChecklistComponent,
        PreviewComponent,
        PlaywrightPdfViewerComponent
    ]
})
export class PresentationModule { }
