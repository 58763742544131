<div class="checklist-section open" *ngIf="proposalStatement">
  <div class="checklist-header">
    <h4>{{proposalStatement.statementType.description}} for {{proposalStatement.name}}</h4>
    <span class="wip-menu-icon menu-caret" (click)="proposalStatement.toggle=!proposalStatement.toggle">
      <i [class.fa-angle-right]="!proposalStatement?.toggle" [class.fa-angle-down]="proposalStatement?.toggle"
        class="fa fas"></i>
    </span>
  </div>

  <sei-car-proposal-section *ngIf="proposalStatement.statementType.id === 1" [statement]="proposalStatement"
    [businessRules]="businessRulesOptions" [proposalId]="proposalId" (savePdfStatement)="onSavePdfStatement($event)"
    (saveAdvisorContent)="onSaveAdvisorContent($event)" (onDeleteSection)="onDelete($event)"></sei-car-proposal-section>

  <sei-car-ips-section *ngIf="proposalStatement.statementType.id === 2" [businessRules]="businessRulesOptions"
    [statement]="proposalStatement"></sei-car-ips-section>
</div>