/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FeatureFlagService } from '@CarServices/feature-flag/feature-flag.service';
import { ChecklistDocuments } from './common/proposal-presentation-checklist.constants';
import { ChecklistDocument } from './common/proposal-presentation-checklist.interfaces';
import { ProposalPresentationChecklistFooterComponent } from './proposal-presentation-checklist-footer/proposal-presentation-checklist-footer.component';
import { ProposalPresentationChecklistSidebarComponent } from './proposal-presentation-checklist-sidebar/proposal-presentation-checklist-sidebar.component';
import { ProposalPresentationContentAreaComponent } from './proposal-presentation-content-area/proposal-presentation-content-area.component';


@Component({
    standalone: true,
    selector: 'sei-car-proposal-presentation-checklist',
    templateUrl: './proposal-presentation-checklist.component.html',
    styleUrls: ['./proposal-presentation-checklist.component.scss'],
    imports: [
        ProposalPresentationChecklistSidebarComponent,
        ProposalPresentationChecklistFooterComponent,
        ProposalPresentationContentAreaComponent
    ]
})
export class ProposalPresentationChecklistComponent {
    public proposalId;

    constructor(private activatedRoute: ActivatedRoute,
        private featureFlagService: FeatureFlagService,
        private router: Router
    ) {
        this.proposalId = this.activatedRoute.snapshot.paramMap.get('proposalId');
        if (!this.featureFlagService.isChecklistRedesignEnabled()) {
            this.router.navigate(['/Proposal/Dashboard']);
        }
    }
    public checklistDocuments: ChecklistDocument[] = [ChecklistDocuments.PROPOSAL_DOCUMENT, ChecklistDocuments.IPS_DOCUMENT];
}
