/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromEnrollment from './enrollment.reducers';

// Extends the app state to include the raffle feature.
// This is required because Raffles are lazy loaded.
// So the reference to RaffleState cannot be added to app.state.ts directly.
export interface State {
    Car: fromEnrollment.EnrollmentState;
}

export const enrollmentModuleSliceName = 'carEnrollmentSlice';
const getCarFeatureState = createFeatureSelector<
    fromEnrollment.EnrollmentState
>(enrollmentModuleSliceName);

export const getGeneratingDocument = createSelector(
    getCarFeatureState,
    (state) => state.generatingDocumentsStatus
);

export const getDocumentGenerated = createSelector(
    getCarFeatureState,
    (state) => state.documentsGeneratedStatus
);

