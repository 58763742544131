<div class="row toggle-row"
     *ngIf="proposalSection && !proposalSection.hidden">
    <div class="col-xs-9 toggle-row-left indent3"
         [ngSwitch]="proposalSection.name">
        <span cdkDragHandle *ngIf="!proposalSection.pinned" class="wip-menu-grabber">
            <i class="fa fas fa-grip-vertical"></i>
        </span>
        <div *ngIf="renameSelected !== true">
            <p>{{proposalSection.name}}
                <a *ngIf="proposalSection.name===proposalSectionLabel.ShowAdvisorFee"
                    (click)="onFeesNavigateClick()">Adjust your fees<i class="far fa-arrow-alt-circle-right"></i></a>
                <sei-car-action-tooltip-popover *ngIf="proposalSection.statementSectionId === advisorContentSectionId"
                                                [toolTipOptions]="toolTipOptions"
                                                [noPadding]="true"
                                                (onOptionSelected)="onOptionSelected($event, proposalSection.name)"></sei-car-action-tooltip-popover>
            </p>
        </div>
        <div *ngIf="renameSelected === true">
            <sei-car-inline-edit-component [inputText]="proposalSection.name"
                                           (editionConfirmed)="onRenameConfirm($event)">
            </sei-car-inline-edit-component>
        </div>
    </div>
    <div class="col-xs-3"
         [class.toggle-row-center]="proposalSection.name !== proposalSectionLabel.ReportingLevel && proposalSection.toggle"
         *ngIf="proposalSection">
        <div id="section-{{proposalSection.id}}"
             [ngSwitch]="proposalSection.name">
            <ng-container *ngSwitchCase="proposalSectionLabel.ReportingLevel">
                <ng-container [ngTemplateOutlet]="reportingLevel"
                              [ngTemplateOutletContext]="{section:proposalSection}"></ng-container>
            </ng-container>
            <ng-container *ngSwitchDefault>
                <sei-icon-toggle *ngIf="proposalSection.toggle"
                                 name="icon-toggle"
                                 [(ngModel)]="proposalSection.include"
                                 (ngModelChange)="modelChanged(proposalSection)"
                                 [trueIcon]="trueIcon"
                                 [falseIcon]="falseIcon"
                                 [iconClass]="iconClass"
                                 [iconSize]="iconSize"
                                 [offIsGrey]="offIsGrey"
                                 [disabled]="proposalSection.isToggleDisabled">
                </sei-icon-toggle>
            </ng-container>
        </div>
    </div>
</div>
<ng-container *ngIf="proposalSection && !proposalSection.hidden">
    <div class="row toggle-row"
         *ngFor="let subSection of proposalSection.sections;let i = index">
        <div class="col-xs-9 toggle-row-left indent4"> <span class="wip-menu-grabber">&nbsp;</span>
            <p>{{subSection.name}}</p>
        </div>
        <div class="col-xs-3"
             [class.toggle-row-center]="subSection.name !== proposalSectionLabel.ReportingMethod && subSection.toggle">
            <div id="subSection-{{subSection.id}}"
                 [ngSwitch]="subSection.name">
                <ng-container *ngSwitchCase="proposalSectionLabel.ReportingMethod">
                    <ng-container [ngTemplateOutlet]="dropdownReportingMethods"
                                  [ngTemplateOutletContext]="{section:subSection}"></ng-container>
                </ng-container>
                <ng-container *ngSwitchDefault>
                    <sei-icon-toggle name="icon-toggle"
                                     *ngIf="subSection?.toggle"
                                     [(ngModel)]="subSection.include"
                                     (ngModelChange)="updatePreviewSection()"
                                     [trueIcon]="'fa-fw fas fa-toggle-on fa-rotate-180'"
                                     [falseIcon]="'fa-fw fas fa-toggle-off fa-rotate-180'"
                                     [iconClass]="iconClass"
                                     [iconSize]="iconSize"
                                     [offIsGrey]="offIsGrey"
                                     [disabled]="disabled">
                    </sei-icon-toggle>
                </ng-container>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #reportingLevel
             let-section='section'>
    <sei-car-toggle-include-exclude [wizardSteps]="reportingLevelWizardModel"
                                    [value]="section.include"
                                    [isCustomModel]="true"
                                    [isDisabled]="section.isToggleDisabled"
                                    (onChangeEvent)="onReportingLevelToggleChange($event)"></sei-car-toggle-include-exclude>
</ng-template>

<ng-template #dropdownReportingMethods
             let-section='section'>
    <sei-car-html-form-elements [proposalSection]="section"
                                [dropDownContent]="reportingMethods"
                                [selectWidth]="'55'"></sei-car-html-form-elements>
</ng-template>
