/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { PercentPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { DisplayNullOptions, DisplayPercentOptions } from '@sei/common-components-lib-ux';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { Observable } from 'rxjs';
import { StrategyAllocation } from '../../model/allocation';
import { Strategy } from '../../model/proposal';

@Component({
    selector: 'sei-car-strategy-drawer',
    templateUrl: './strategy-drawer.component.html',
    styleUrls: ['./strategy-drawer.component.scss']
})
export class StrategyDrawerComponent extends ParentComponentSubscriptionManager implements OnInit {

    @Input()
    public allocationStrategyStream: Observable<StrategyAllocation>;

    @Input()
    public strategy: Strategy;

    public strategyDescription: string = '';

    public displayPercentOptions: DisplayPercentOptions;

    constructor(private percent: PercentPipe) {
        super('StrategyDrawerComponent');
        super.ngOnDestroy();

        this.displayPercentOptions = {
            displayPercentSymbol: true,
            roundToDecimal: 2, nullDisplay: DisplayNullOptions.BLANK
        };
    }
}
