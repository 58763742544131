/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { CommonModule } from '@CarComponents/common/common.module';
import { InvestmentProgramsService } from '@CarServices/investment-programs/investment-programs.service';
import { StrategySelectionService } from '@CarServices/strategy-selection/strategy-selection.service';
import { NgModule } from '@angular/core';
import { EditPercentDirective } from '@sei/common-components-lib-ux';
import { ShortNumberPipe } from '../../../app/core/pipes/short-number.pipe';
import { WipStrategyAccordionComponent } from './wip-strategy-accordion/wip-strategy-accordion.component';
import { WipStrategyDfsComponent } from './wip-strategy-dfs/wip-strategy-dfs.component';
import { WipStrategyEtfSubstitutionComponent } from './wip-strategy-etf-substitution/wip-strategy-etf-substitution.component';
import { WipStrategyEtfComponent } from './wip-strategy-etf/wip-strategy-etf.component';
import { WipStrategyFixedIncomeComponent } from './wip-strategy-fixed-income/wip-strategy-fixed-income.component';
import { WipStrategyMutualFundsComponent } from './wip-strategy-mutual-funds/wip-strategy-mutual-funds.component';
import { WipStrategyNestedComponent } from './wip-strategy-nested/wip-strategy-nested.component';
import { WipStrategyRangeSelectorComponent } from './wip-strategy-range-selector/wip-strategy-range-selector.component';
import { WipStrategyRebalanceComponent } from './wip-strategy-rebalance/wip-strategy-rebalance.component';
import { WipStrategyRibbonListComponent } from './wip-strategy-ribbon-list/wip-strategy-ribbon-list.component';
import { WipStrategyRibbonComponent } from './wip-strategy-ribbon/wip-strategy-ribbon.component';
import { WipStrategySelectionFooterComponent } from './wip-strategy-selection-footer/wip-strategy-selection-footer.component';
import { WipStrategySelectionMainComponent } from './wip-strategy-selection-main/wip-strategy-selection-main.component';
import { WipStrategySelectorOptionComponent } from './wip-strategy-selector-option/wip-strategy-selector-option.component';
import { WipStrategySelectorComponent } from './wip-strategy-selector/wip-strategy-selector.component';
import { WipStrategySmaMainComponent } from './wip-strategy-sma-main/wip-strategy-sma-main.component';
import { WipStrategySmaComponent } from './wip-strategy-sma/wip-strategy-sma.component';

@NgModule({
    imports: [
        CommonModule,
        EditPercentDirective
    ],
    declarations: [
        WipStrategyMutualFundsComponent,
        WipStrategyRangeSelectorComponent,
        WipStrategyRibbonComponent,
        WipStrategyRibbonListComponent,
        WipStrategySelectionFooterComponent,
        WipStrategySelectionMainComponent,
        WipStrategyEtfComponent,
        WipStrategyDfsComponent,
        WipStrategySelectorComponent,
        WipStrategySelectorOptionComponent,
        WipStrategyFixedIncomeComponent,
        WipStrategyAccordionComponent,
        WipStrategyNestedComponent,
        WipStrategySmaMainComponent,
        WipStrategySmaComponent,
        WipStrategyRebalanceComponent,
        WipStrategyEtfSubstitutionComponent
    ],
    providers: [
        ShortNumberPipe,
        InvestmentProgramsService,
        StrategySelectionService
    ],
    exports: [
        WipStrategyMutualFundsComponent,
        WipStrategyRangeSelectorComponent,
        WipStrategyRibbonComponent,
        WipStrategyRibbonListComponent,
        WipStrategySelectionFooterComponent,
        WipStrategySelectionMainComponent,
        WipStrategyEtfComponent,
        WipStrategyDfsComponent,
        WipStrategySelectorComponent,
        WipStrategySelectorOptionComponent,
        WipStrategyFixedIncomeComponent,
        WipStrategyAccordionComponent,
        WipStrategyNestedComponent,
        WipStrategySmaMainComponent,
        WipStrategySmaComponent,
        EditPercentDirective
    ]
})
export class WipStrategySelectionModule { }
