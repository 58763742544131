<div class="tax-rate">
    <div class="row">
        <div class="col-sm-6">
            <div class="form-group">
                <label class="control-label"
                       for="fedIncome">Federal Income</label>
                <input class="form-control"
                       id="fedIncome"
                       seiEditPercent
                       [percentOptions]="percentOptions"
                       [readonly]="false"
                       [value]="$any(federalIncome)"
                       (output)="checkValue($event,'federalIncome')"
                       [disabled]="isDisabled">
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group">
                <label class="control-label"
                       for="stateIncome">State Income</label>
                <input class="form-control"
                       id="stateIncome"
                       seiEditPercent
                       [percentOptions]="percentOptions"
                       [readonly]="false"
                       [value]="$any(stateIncome)"
                       (output)="checkValue($event,'stateIncome')"
                       [disabled]="isDisabled">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-6">
            <div class="form-group">
                <label class="control-label"
                       for="federalCapitalGains">Federal Capital Gains</label>
                <input class="form-control"
                       id="federalCapitalGains"
                       seiEditPercent
                       [percentOptions]="percentOptions"
                       [readonly]="false"
                       [value]="$any(federalCapitalGains)"
                       (output)="checkValue($event,'federalCapitalGains')"
                       [disabled]="isDisabled">
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group">
                <label class="control-label"
                       for="stateCapitalGains">State Capital Gains</label>
                <input class="form-control"
                       id="stateCapitalGains"
                       seiEditPercent
                       [percentOptions]="percentOptions"
                       [readonly]="false"
                       [value]="$any(stateCapitalGains)"
                       (output)="checkValue($event,'stateCapitalGains')"
                       [disabled]="isDisabled">
            </div>
        </div>
    </div>
</div>
