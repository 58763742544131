/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DynamicErrorInfo } from '@CarModels/dynamic-error-info';
import { GenericError } from '@CarModels/generic-error';
import { BehaviorSubject, Observable, EMPTY } from 'rxjs';
import { GlobalService } from './global.service';
/**
 * Global Error Handling service.  Used with GenericErrorNotificationComponent to
 * publish and display errors
 */
@Injectable({
    providedIn: 'root'
})
export class GenericErrorService {
    private errorText: BehaviorSubject<GenericError>;
    public errorInfo: DynamicErrorInfo;
    private _hasError: boolean = false;

    constructor(private global: GlobalService, private http: HttpClient){}

    public get hasError(): boolean {
        return this._hasError;
    }

    public getErrorStream(): Observable<GenericError> {
        return this.errorText ? this.errorText.asObservable() : EMPTY;
    }

    public setGenericError(error: GenericError) {
        if (!this.errorText) {
            this.errorText = new BehaviorSubject<GenericError>(error);
            return;
        }
        this._hasError = true;
        this.errorText.next(error);
    }

    public clearGenericError() {
        this._hasError = false;
        if (this.errorText) {
            this.errorText.next(undefined);
        }
    }

    public getErrorsStream(): Observable<GenericError> {
        return this.errorText.asObservable();
    }

    public getErrorNumber() {
        const apiUrl: string = this.global.configService.routeFormatter(
            this.global.configService.getEndPoint('getErrorInfo'),
            [{
                key: 'error',
                value: 'GetContactPhoneNumberOnError'
            }]
        );

        return this.http.get<DynamicErrorInfo>(apiUrl);

    }
}
