<div class="car-centered-modal-container file-upload-container">
    <div class="car-centered-modal-content file-upload-content">
        <div class="car-centered-modal-panel-heading ">
            <div class="row">
                <div class="col-xs-12">
                    <h3 *ngIf="fileUploadViewMode !== advisorContentUploadMode" class="car-centered-modal-header">Upload Image</h3>
                    <h3 *ngIf="fileUploadViewMode === advisorContentUploadMode" class="car-centered-modal-header">Upload Document</h3>
                </div>
            </div>
        </div>
        <car-generic-error-notification></car-generic-error-notification>

        <div class="car-centered-modal-body centered-modal-body-fit-height">
            <car-file-multiple-upload
                [sectionSelected]="fileUploadViewMode"
                [proposalId]="proposalId"
                [presentationType]="presentationType"
                [sectionName]="sectionName"
                [subSectionName]="subSectionName"
                [statement]="statement"
                (onSuccessfullUpload)="onSuccessfullUploadEvent($event)"
                (advisorContentUploaded)="advisorContentUpload($event)"
            ></car-file-multiple-upload>
        </div>

        <div class="car-centered-modal-footer">
            <div class="row">
                <div class="col-xs-12">
                    <car-file-upload-modal-footer (onCancel)="onCancelEvent($event)"></car-file-upload-modal-footer>
                </div>
            </div>
        </div>
    </div>
</div>
