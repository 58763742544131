/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class UtilityService {
    constructor(private currencyPipe: CurrencyPipe) {}

    public isValidTIN(stringTIN: string, isIndividual: boolean): boolean {
        let isValid = false;
        let regex = null;

        if (isIndividual) {
            regex = /^([0-9]{3})-?([0-9]{2})-?([0-9]{4})$/;
        } else {
            regex = /^([0-9]{2})-?([0-9]{7})$/;
        }

        const t = stringTIN.match(regex);

        if (t !== null) {
            isValid = true;
        }

        return stringTIN === '' ? true : isValid;
    }

    public round(value: number, precision: number): number {
        const multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    }

    public currencyFormat(amount: number): string {
        if (amount == null) {
            amount = 0;
        }

        return `${this.currencyPipe.transform(
            amount,
            'USD',
            'symbol',
            '1.2-2'
        )} `;
    }

    /*  @method isValidDate
     *  This method helps determinate if a date is valid in a specific date format
     *  @params { string } stringDate date to validate
     *  @params { string } dateFormat date format to validate
     *  @return { boolean }
     */
    public isValidDate(stringDate): boolean {
        let isValid = false;
        let regex = null;

        regex = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;
        const yearIndex = 3;
        const monthIndex = 1;
        const dayIndex = 2;

        const t = stringDate.match(regex);

        if (t !== null) {
            const y = +t[yearIndex];
            const m = +t[monthIndex];
            const d = +t[dayIndex];
            const date = new Date(y, m - 1, d);

            isValid = date.getFullYear() === y && date.getMonth() === m - 1;
        }

        return isValid;
    }

    public getMonth(stringDate): number {
        let regex = null;
        let m = 1;

        regex = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;

        const t = stringDate.match(regex);

        if (t !== null) {
            m = +t[1];
        }

        return m;
    }

    public getYear(stringDate: string): number {
        let regex = null;
        let y = new Date().getFullYear();

        regex = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;

        const t = stringDate.match(regex);

        if (t !== null) {
            y = +t[3];
        }

        return y;
    }

    /**
     * clears child keys of an object when type fails
     * Example:
     * given account.accountOwners[0].compliance.countryOfCitizenship = {
     * countryId: -1,
     * countryCode: undefined,
     * countryName: undefined,
     * sortOrder: -1
     * }
     * This will effectively set the object to {} where type check fails
     * example: account.accountOwners[0].compliance.countryOfCitizenship = {}
     *
     * Use this where the endpoints are expecting empty objects
     * @param dataObject - type of T of object to clear
     * @param deepClear - default false - set true to perform recursive clear for nested objects
     * @returns dataObject result of type T
     */
    public clearObjectKeys<T>(dataObject: T, deepClear: boolean = false): T {
        const keys = Object.keys(dataObject);

        if (keys) {
            keys.forEach((key: string) => {
                // protect against null/undefined
                if (dataObject[key]) {
                    if (
                        Object.keys(dataObject[key]) &&
                        Object.keys(dataObject[key]).length > 0 &&
                        deepClear
                    ) {
                        this.clearObjectKeys(dataObject[key], deepClear);
                    }
                }
                delete dataObject[key];
            });
        }

        return dataObject;
    }

    /**
     * Sorts the keys of a key/value object array
     */
    public sortObjectKeys<T>(dataObject: T): T {
        const keys: string[] = Object.keys(dataObject);
        const returnObject = {};

        if (keys) {
            keys.sort().forEach(function(key) {
                returnObject[key] = dataObject[key];
            });
        }

        return returnObject as T;
    }

    /**
     * Gets a cookie value
     * @param key of cookie to retrieve
     */
    public getCookie(key: string): string {
        const decodedCookie: string = decodeURIComponent(document.cookie);
        const pairs: string[] = decodedCookie.split(/;\s*/);

        const prefix = `${key}=`;
        pairs.forEach((pair: string) => {
            if (pair.indexOf(prefix) === 0) {
                return pair.split('=')[1];
            }
        });
        return '';
    }

    public splitInColumns<T>(source: T[], columns: number): T[][] {
        const newArray: T[][] = [];

        if (source) {
            const totalElementsByArray = Math.ceil(source.length / columns);

            let totalElementsAssigned: number = 0;
            for (let i = 0; i < columns - 1; i++) {
                newArray.push(
                    source.slice(
                        totalElementsAssigned,
                        totalElementsAssigned + totalElementsByArray
                    )
                );

                totalElementsAssigned += totalElementsByArray;
            }

            // Residual elements
            newArray.push(source.slice(totalElementsAssigned, source.length));
        }

        return newArray;
    }
}
