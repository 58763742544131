/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
/* eslint-disable @typescript-eslint/no-explicit-any */
export class PropertyService {
    /**
     * Will determine if a property exists throughout an entire chain.
     * @param fn A function that returns the property chain to check.
     * () => foo.bar.baz.bat
     * @returns true if the final property exists; otherwise false.
     */
    public exists(fn: () => any): boolean {
        try {
            const value = fn();
            return value !== undefined && value !== null;
        } catch {
            return false;
        }
    }

    /**
     * Will determine if a property does not exist anywhere in a chain.
     * @param fn A function that returns the property chain to check.
     * () => foo.bar.baz.bat
     * @returns true if any property within the chain is null or undefined; otherwise false.
     */
    public notExists(fn: () => any): boolean {
        return !this.exists(fn);
    }

    /**
     * Will determine if a property exists throughout an entire chain and that it has a truthy value.
     * @param fn A function that returns the property chain to check.
     * () => foo.bar.baz.bat
     * @returns true if the final property exists and has a truthy value; otherwise false.
     */
    public existsAndTruthy(fn: () => any): boolean {
        return this.exists(fn) && !!fn();
    }

    /**
     * Will determine if a property does not exist throughout an entire chain, or has a falsey value.
     * @param fn A function that returns the property chain to check.
     * () => foo.bar.baz.bat
     * @returns true if the final property does not exist or has a falsey value; otherwise false.
     */
    public notExistsOrFalsey(fn: () => any): boolean {
        return this.notExists(fn) || !fn();
    }

    /**
     * Will determine if all property chains exist.
     * @param fnArray An array of functions that each returns a property chain to check.
     * [() => foo.bar.baz.bat, () => fee.fi.fo.fum]
     * @returns true if all final properties exist; otherwise false.
     */
    public allExist(fnArray: Array<() => any>): boolean {
        for (let i = 0; i < fnArray.length; i++) {
            if (this.notExists(fnArray[i])) {
                return false;
            }
        }

        return true;
    }

    /**
     * Will determine if any property chains exist.
     * @param fnArray An array of functions that each returns a property chain to check.
     * [() => foo.bar.baz.bat, () => fee.fi.fo.fum]
     * @returns true if any final properties exist; otherwise false.
     */
    public anyExist(fnArray: Array<() => any>): boolean {
        for (let i = 0; i < fnArray.length; i++) {
            if (this.exists(fnArray[i])) {
                return true;
            }
        }

        return false;
    }

    /**
     * Will determine if all property chains exist and have truthy values.
     * @param fnArray An array of functions that each returns a property chain to check.
     * [() => foo.bar.baz.bat, () => fee.fi.fo.fum]
     * @returns true if all final properties exist and have truthy values; otherwise false.
     */
    public allExistAndTruthy(fnArray: Array<() => any>): boolean {
        for (let i = 0; i < fnArray.length; i++) {
            if (!this.existsAndTruthy(fnArray[i])) {
                return false;
            }
        }

        return true;
    }

    /**
     * Will determine if any property chains exist and have a truthy value.
     * @param fnArray An array of functions that each returns a property chain to check.
     * [() => foo.bar.baz.bat, () => fee.fi.fo.fum]
     * @returns true if any final property exists and has a truthy value; otherwise false.
     */
    public anyExistAndTruthy(fnArray: Array<() => any>): boolean {
        for (let i = 0; i < fnArray.length; i++) {
            if (this.existsAndTruthy(fnArray[i])) {
                return true;
            }
        }

        return false;
    }
}
