/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { Proposal } from '../proposal';
import { BaseFactoryAbstract } from './base-factory-abstract';

@Injectable()
export class ProposalFactory extends BaseFactoryAbstract<Proposal> {
    public createObject() {
        return {
            id: 0,
            name: '',
            completeRiskQuestionnaire: false,
            completeTaxAnalysis: false,
            scenarios: [],
            clients: [],
            firmId: 1,
            firm: {
                advisors: [],
                firmId: 0,
                firmName: '',
                locations: []
            },
            riskPreference: {
                toleranceLevel: 5
            },
            presentation: {
                id: 0,
                name: '',
                section: []
            },
            wipCheckList: [],
            advisorChangeFlag: false,
            advisors: []
        };
    }
}
