/*
 Copyright: This information constitutes the exclusive property of SEI
 Investments Company, and constitutes the confidential and proprietary
 information of SEI Investments Company.  The information shall not be
 used or disclosed for any purpose without the written consent of SEI
 Investments Company.
*/
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { ToggleButton } from '../../common/toggle-button/toggle-button';
import { AdvisorFee, FeeGroup, FeeSchedule, ScenarioFeeSchedule } from '../../model/account-fee';
import { FeeScheduleTypes, FeeType, PercentageOrBpsOptions } from '../../model/enums';
import { FeeScheduleGroupService } from '../../service/fee-schedule-group.service';

@Component({
    selector: 'sei-car-fee-schedule-firm',
    templateUrl: './fee-schedule-firm.component.html'
})
export class FeeScheduleFirmComponent extends ParentComponentSubscriptionManager implements OnInit {

    @Input()
    public advisorFee: AdvisorFee;
    @Input()
    public scenarioId: number;
    @Input()
    public totalAccountBalance: number;
    @Input()
    public scenarioFeeSchedules: ScenarioFeeSchedule[] = [];
    @Input()
    public feeGroup: FeeGroup;
    @Output()
    public onDoneFeeRibbonEvent: EventEmitter<AdvisorFee> = new EventEmitter();
    @Output()
    public onCancelFirmFeeEvent = new EventEmitter();

    public scheduleName: string;

    public applyDiscountPercent: number = 0;
    public applyDiscountBps: number = 0;
    public applyDiscount: boolean = false;
    public feeSchedules: FeeSchedule[] = [];
    public feeGroupDiscount: boolean = false;
    public scheduleSettings: boolean = false;
    public excludeFeeOnCash: boolean = false;
    public feeScheduleType: number = 2;
    public scheduleSelected: number = -1;
    public proposalId: number;
    public feeType: typeof FeeType = FeeType;

    // public formatPercentage: string = 'p0';
    // public formatNumeric: string = 'n';
    // public format: string;
    public maxPercent: number = 3;
    public minPercent: number = 0;
    public maxBps: number = 300;
    public minBps: number = 0;

    // NOTE: Hidden controls not included in M1 'Apply Discount' & 'Exclude Fee on Cash'
    public hiddenControl: boolean = true;

    public toggleOptions: Array<ToggleButton<PercentageOrBpsOptions>> = [
        {
            label: '%',
            data: PercentageOrBpsOptions.Percentage
        },
        {
            label: 'BPS',
            data: PercentageOrBpsOptions.Bps
        }
    ];

    constructor(public feeScheduleGroupService: FeeScheduleGroupService,
        public activatedRoute: ActivatedRoute) {

        super('FeeScheduleFirmComponent');
        if (this.activatedRoute && this.activatedRoute.parent) {
            this.proposalId = Number(this.activatedRoute.parent.snapshot.paramMap.get('proposalId'));
        }
    }

    public ngOnInit(): void {
        this.feeGroupDiscount = this.advisorFee.feeGroupDiscount;
        this.scheduleSettings = this.advisorFee.scheduleSettings;
        this.excludeFeeOnCash = this.advisorFee.excludeFeeOnCash;
        this.feeScheduleType = this.advisorFee.feeScheduleType;
        this.applyDiscount = this.advisorFee.feeScheduleOptionalDiscount;

        this.getDefaultFeeGroup();
    }

    private getDefaultFeeGroup(): void {
        if (this.advisorFee.feeSchedule.length > 0) {
            this.feeSchedules = this.advisorFee.feeSchedule;
        } else {
            this.feeSchedules = [{
                slab: 1,
                lowValue: 0,
                highValue: 0,
                rate: 0,
                isValid: true,
                id: 0
            },
            {
                slab: 2,
                lowValue: 0,
                highValue: 0,
                rate: 0,
                isValid: true,
                id: 0
            }];
        }
    }

    private getFeeScheduleDetail(feeSchedule: ScenarioFeeSchedule): void {
        this.feeScheduleGroupService.getFeeSchedulesDetail(this.proposalId, this.scenarioId, feeSchedule).subscribe(
            (response) => {
                if (response) {
                    this.feeSchedules = response;
                    if (this.feeSchedules.length === 0) {
                        this.getDefaultFeeGroup();
                    }
                }
            });
    }

    public onCancelEventRibbon(): void {
        this.onCancelFirmFeeEvent.emit();
    }

    public onDoneFeeRibbon(): void {

        this.advisorFee.feeSchedule = this.feeSchedules;
        this.advisorFee.feeGroupDiscount = this.feeGroupDiscount;
        this.advisorFee.scheduleSettings = this.scheduleSettings;
        this.advisorFee.excludeFeeOnCash = this.excludeFeeOnCash;
        this.advisorFee.feeScheduleType = this.feeScheduleType;
        this.advisorFee.feeScheduleOptionalDiscount = this.applyDiscount;

        this.onDoneFeeRibbonEvent.emit(this.advisorFee);
    }


    public onToggleDiscountGroup(event): void {
        this.feeGroupDiscount = +event === 1;
    }

    public onToggleScheduleSettings(event): void {
        this.scheduleSettings = (event === PercentageOrBpsOptions.Percentage);
    }

    public onToggleFeeSchedule(event): void {
        this.feeScheduleType = event;
    }

    public onToggleExcludeFeeOnCash(event): void {
        this.excludeFeeOnCash = +event === 1;
    }

    public onFeeScheduleTiersChange(event): void {
        this.feeSchedules = event;
    }
    public onToggleApplyDiscount(event): void {
        this.applyDiscount = (+event === 1);
    }
    public scheduleDropdownSelected(event): void {
        this.scheduleSelected = +event.target.value;

        const feeScheduleFound: ScenarioFeeSchedule =
            this.scenarioFeeSchedules.find((feeSchedule: ScenarioFeeSchedule) => feeSchedule.scheduleId === this.scheduleSelected);

        if (feeScheduleFound) {
            this.feeScheduleType = FeeScheduleTypes.Banded;
            this.advisorFee.feeScheduleId = feeScheduleFound.scheduleId;
            this.advisorFee.feeScheduleName = feeScheduleFound.scheduleName;
            if (feeScheduleFound.slabTypeCd && feeScheduleFound.slabTypeCd === 9) {
                this.feeScheduleType = FeeScheduleTypes.Tiered;
            }
        }

        if (this.scheduleSelected > 0) {
            this.getFeeScheduleDetail(feeScheduleFound);
        } else {
            this.advisorFee.feeSchedule = [];
            this.getDefaultFeeGroup();
        }
    }

    public onApplyDiscountBpsChange(): void {
        this.applyDiscountPercent = +(this.applyDiscountBps / 100);
    }

    public onApplyDiscountChange(): void {
        this.applyDiscountBps = +(this.applyDiscountPercent * 100);
    }
}

