/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, Input, OnInit, OnChanges, SimpleChanges, HostBinding } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { RiskLevelClasses, RiskLevels, RiskLevelValues, RiskWidgetTypes } from '../../model/enums';
import { RiskWidgetFactory } from '../../model/factory/risk-widget-factory';
import { RiskWidget } from '../../model/risk-widget';
import { NumberEvaluator } from '../../utilities/number-evaluator.utility';

@Component({
    selector: 'car-risk-widget',
    templateUrl: './risk-widget.component.html'
})
export class RiskWidgetComponent implements OnInit, OnChanges {
    @Input()
    public riskWidgetType: string;

    @Input()
    public riskToleranceScore: number;

    @Input()
    public snapPointCombinedStrategyRisk: number;

    @Input()
    public strategyRiskScore: number;

    public riskToleranceScorePercentage: string;
    public riskWidget: RiskWidget[] = [];
    public capacityRiskLevel: string = RiskLevels.None;

    public riskWidgetTypes: typeof RiskWidgetTypes = RiskWidgetTypes;
    public riskLevelClasses: typeof RiskLevelClasses = RiskLevelClasses;

    constructor(
        private numberEvaluator: NumberEvaluator,
        private sanitizer: DomSanitizer
    ) { }

    public ngOnInit(): void {
        this.riskWidget = [];
        const riskLevelLow = Object.assign({}, new RiskWidgetFactory().createRiskLevel(RiskLevels.Low));
        const riskLevelMed = Object.assign({}, new RiskWidgetFactory().createRiskLevel(RiskLevels.Medium));
        const riskLevelHigh = Object.assign({}, new RiskWidgetFactory().createRiskLevel(RiskLevels.High));

        this.setCapacityRiskLevel();

        riskLevelLow.active = true;
        riskLevelMed.active = true;
        riskLevelHigh.active = true;

        this.riskWidget.push(riskLevelLow);
        this.riskWidget.push(riskLevelMed);
        this.riskWidget.push(riskLevelHigh);

        this.setStrategyRisk();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.riskWidget.length > 0) {
            this.setStrategyRisk();
            this.setCapacityRiskLevel();

            let min: number;
            let max: number;
            switch (this.snapPointCombinedStrategyRisk) {
                case RiskLevels.LowLow: {
                    min = this.riskWidget[RiskLevels.LowIndex].low.min;
                    max = this.riskWidget[RiskLevels.LowIndex].low.max;
                    break;
                }
                case RiskLevels.LowMedium: {
                    min = this.riskWidget[RiskLevels.LowIndex].medium.min;
                    max = this.riskWidget[RiskLevels.LowIndex].medium.max;
                    break;
                }
                case RiskLevels.LowHigh: {
                    min = this.riskWidget[RiskLevels.LowIndex].high.min;
                    max = this.riskWidget[RiskLevels.LowIndex].high.max;
                    break;
                }
                case RiskLevels.MediumLow: {
                    min = this.riskWidget[RiskLevels.MediumIndex].low.min;
                    max = this.riskWidget[RiskLevels.MediumIndex].low.max;
                    break;
                }
                case RiskLevels.MediumMedium: {
                    min = this.riskWidget[RiskLevels.MediumIndex].medium.min;
                    max = this.riskWidget[RiskLevels.MediumIndex].medium.max;
                    break;
                }
                case RiskLevels.MediumHigh: {
                    min = this.riskWidget[RiskLevels.MediumIndex].high.min;
                    max = this.riskWidget[RiskLevels.MediumIndex].high.max;
                    break;
                }
                case RiskLevels.HighLow: {
                    min = this.riskWidget[RiskLevels.HighIndex].low.min;
                    max = this.riskWidget[RiskLevels.HighIndex].low.max;
                    break;
                }
                case RiskLevels.HighMedium: {
                    min = this.riskWidget[RiskLevels.HighIndex].medium.min;
                    max = this.riskWidget[RiskLevels.HighIndex].medium.max;
                    break;
                }
                case RiskLevels.HighHigh: {
                    min = this.riskWidget[RiskLevels.HighIndex].high.min;
                    max = this.riskWidget[RiskLevels.HighIndex].high.max;
                    break;
                }
            }
            let percent = ((this.strategyRiskScore - min) * 100) / (max - min);
            // NOTE: in order to show what Risk Level is actually selected the requirement
            // is to move  the percent a little so its more obvious
            if (percent === 100) {
                percent = 99;
            } else if (percent === 0) {
                percent = 1;
            }
            this.riskToleranceScorePercentage = `${percent.toString()}%`;
        }
    }

    private setCapacityRiskLevel(): void {
        this.capacityRiskLevel =
            this.riskToleranceScore === null
                ? RiskLevelClasses.NoneClass
                : this.numberEvaluator.between(
                      this.riskToleranceScore,
                      RiskLevelValues.LowMin,
                      RiskLevelValues.LowMax
                  )
                ? RiskLevelClasses.LowMediumClass
                : this.numberEvaluator.between(
                      this.riskToleranceScore,
                      RiskLevelValues.MediumMin,
                      RiskLevelValues.MediumMax
                  )
                ? RiskLevelClasses.MediumMediumClass
                : this.numberEvaluator.between(
                      this.riskToleranceScore,
                      RiskLevelValues.HighMin,
                      RiskLevelValues.HighMax
                  )
                ? RiskLevelClasses.HighMediumClass
                : RiskLevelClasses.NoneClass;
    }

    private setStrategyRisk(): void {
        this.riskWidget.forEach((riskLevel: RiskWidget) => {
            riskLevel.low.active =
                this.snapPointCombinedStrategyRisk ===
                Number(riskLevel.low.classId.replace('_', ''));
            riskLevel.medium.active =
                this.snapPointCombinedStrategyRisk ===
                Number(riskLevel.medium.classId.replace('_', ''));
            riskLevel.high.active =
                this.snapPointCombinedStrategyRisk ===
                Number(riskLevel.high.classId.replace('_', ''));
        });
    }

    // NOTE: this uses CSS variables to set the risk tolerance percent left dynamically
    @HostBinding('attr.style')
    public get riskTolerancePercentStyle(): SafeStyle {
        return this.sanitizer.bypassSecurityTrustStyle(`--risk-tol-var: ${this.riskToleranceScorePercentage}`);
    }
}
