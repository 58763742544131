/*
* Copyright: This information constitutes the exclusive property of SEI
* Investments Company, and constitutes the confidential and proprietary
* information of SEI Investments Company.  The information shall not be
* used or disclosed for any purpose without the written consent of SEI
* Investments Company.
*/

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DropdownComponent, DropdownItem, LoadingStates, LoadingOverlayComponent, WizardModel, WizardState } from '@sei/common-components-lib-ux';
import { OrderedSet } from 'immutable';
import { Client, Proposal } from '@sei/advisor-client-review-proposal-ux';
import { IasApplicationsLibUxModule, TaxLotDetails } from '@sei/ias-applications-lib-ux';
import { FormsModule } from '@angular/forms';
import { PageHeaderMenuComponent, SubpageheaderModel } from '@sei/common-swp-components-lib-ux';
import { AccountTypes } from '@CarModels/account-type-options';
import { AccountOwnerObject, AccountTypeObject, CurrentAccount, SaveAccountDetails } from './model/tax-transition-analysis-current-account.model';
import { CurrentAccountEnum } from './enum/tax-transition-analysis-current-account.enum';

@Component({
    standalone: true,
    selector: 'sei-car-proposal-tax-transition-analysis-current-account',
    templateUrl: './tax-transition-analysis-current-account.component.html',
    imports: [
        DropdownComponent,
        FormsModule,
        IasApplicationsLibUxModule,
        LoadingOverlayComponent,
        PageHeaderMenuComponent
    ]
})
export class ProposalTaxTransitionAnalysisCurrentAccountComponent implements OnInit {

    @Input() public proposal: Proposal;
    @Input() public currentAccount: CurrentAccount;
    @Input() public allowMultiple: boolean = false;
    @Input() public taxLotDetails: TaxLotDetails[];
    @Input() public autoCompleteMessage: string;
    @Input() public toggleValue: boolean;
    @Input() public minWidth: number = 15;
    @Input()
    public wizardSteps: WizardModel[] = [
        { name: CurrentAccountEnum.Yes, state: WizardState.ACTIVE },
        { name: CurrentAccountEnum.No, state: WizardState.ENABLE }
    ];
    @Input()
    set title(title: string) {
        this._title = title;
        if (!this.initiateFlag && (title === CurrentAccountEnum.Edit || title === CurrentAccountEnum.Add)) {
            this.getCurrentAccountDetails();
        }
    }

    get title(): string {
        return this._title;
    }
    @Input() public isReadUser: boolean;
    @Output() public accountDetailsEvent: EventEmitter<SaveAccountDetails> = new EventEmitter<SaveAccountDetails>();
    @Output() public documentsEvent: EventEmitter<TaxLotDetails[]> = new EventEmitter<TaxLotDetails[]>();
    public accountName: string;
    public selectedAccountType: AccountTypeObject;
    public id: number;
    public subTitle: string;
    public accountOwners: AccountOwnerObject[] = [];
    public selectedAccountOwner: AccountOwnerObject;
    public accountDetailsHeaderProperties: SubpageheaderModel;
    public taxLotDetailsHeaderProperties: SubpageheaderModel;
    public headingLevel: number = 3;
    public multiSelect: boolean = false;
    public autocomplete: boolean = true;
    public autocompleteState: LoadingStates = LoadingStates.COMPLETE;
    public autocompleteAccountType: boolean = false;
    public showSelectionOptions: boolean = true;
    public showSearch: boolean = false;
    public accountOwnerData: OrderedSet<DropdownItem>;
    public selectedAccountOwnerData: OrderedSet<DropdownItem> = OrderedSet<DropdownItem>([]);
    public accountType: string = '';
    public initiateFlag: boolean = true;
    public currentAccountDetails: CurrentAccount;
    public isTaxlotExceptionPresent: boolean = false;
    private _title: string;

    constructor() {
        this.accountDetailsHeaderProperties = {
            title: CurrentAccountEnum.AccountDetails,
            switcherAvailable: false
        };
        this.taxLotDetailsHeaderProperties = {
            title: CurrentAccountEnum.TaxLotDetails,
            switcherAvailable: false
        };
    }

    ngOnInit(): void {
        this.taxLotDetails = [];
        this.initiateFlag = false;
        this.getCurrentAccountDetails();
    }

    public resetValues(): void {
        this.accountName = '';
        this.selectedAccountOwnerData = OrderedSet<DropdownItem>([]);
        this.accountType = '';
        this.setWizardSteps();
    }

    public getCurrentAccountDetails(): void {
        if (this.title === CurrentAccountEnum.Edit) {
            this.taxLotDetails = [];
            this.id = this.currentAccount.id;
            this.accountName = this.currentAccount.currentAccountName;
            this.selectedAccountType = this.currentAccount.accountType;
            this.selectedAccountOwner = this.currentAccount.accountOwners;
            this.taxLotDetails.push({
                taxLotDocument: this.currentAccount.taxLotDocument
            });
            this.isTaxlotExceptionPresent =
                this.currentAccount.taxLotDocument ? this.currentAccount.taxLotDocument.isExceptionPresent : false;
        } else if (this.title === CurrentAccountEnum.Add) {
            this.resetValues();
        }
        this.getSubTitle();
        this.getAccountOwnerData();
    }

    public getSubTitle(): void {
        if (this.proposal && this.proposal.name) {
            this.subTitle = this.proposal.name;
            this.subTitle = this.subTitle.charAt(0).toUpperCase() + this.subTitle.slice(1);
        }
    }

    public onAccountNameChange(): void {
        this.validateDetails();
    }

    public changeAccountOwner(): void {
        if (this.selectedAccountOwnerData) {
            this.selectedAccountOwnerData.forEach((e: DropdownItem): void => {
                this.selectedAccountOwner = {
                    id: e.id,
                    name: e.name
                };
            });
        } else {
            this.selectedAccountOwnerData = OrderedSet<DropdownItem>([]);
            this.selectedAccountOwner = {
                id: 0,
                name: ''
            };
        }
        this.validateDetails();
    }

    public getAccountOwnerData(): void {
        const dropdownItemSet: Set<DropdownItem> = new Set<DropdownItem>();
        if (this.proposal) {
            if (this.proposal.clients && this.proposal.clients.length > 0) {
                this.proposal.clients.forEach((data: Client): void => {
                    this.accountOwners.push({ id: data.id, name: data.firstName + ' ' + data.lastName });
                    if (this.title === CurrentAccountEnum.Edit) {
                        this.selectedAccountOwnerData =
                            OrderedSet<DropdownItem>([new DropdownItem(this.selectedAccountOwner.id, this.selectedAccountOwner.name)]);
                    }
                });
            }
            if (this.accountOwners) {
                this.accountOwners.forEach((e: AccountOwnerObject): void => {
                    dropdownItemSet.add(new DropdownItem(e.id, e.name));
                });
                this.accountOwnerData = OrderedSet<DropdownItem>(dropdownItemSet);
            }
        }
    }

    public setWizardSteps(): void {
        this.wizardSteps = [
            { name: CurrentAccountEnum.Yes, state: WizardState.ACTIVE },
            { name: CurrentAccountEnum.No, state: WizardState.ENABLE }
        ];
    }

    public validateDetails(): void {
        if (this.selectedAccountOwner && this.selectedAccountOwner.name) {
            this.currentAccountDetails = {
                accountType: {
                    accountTypeId: AccountTypes.IndividualOwnerId,
                    accountTypeDescription: AccountTypes.IndividualOwnerName as string,
                    taxQualified: true
                },
                accountOwners: this.selectedAccountOwner,
                currentAccountName: this.accountName ? this.accountName : null,
                isAccountTaxable: true,
                proposalId: this.proposal.id,
                taxLotDocument: this.taxLotDetails && this.taxLotDetails.length > 0 ? this.taxLotDetails[0].taxLotDocument : null,
                isExceptionPresent: this.taxLotDetails && this.taxLotDetails.length > 0 && this.taxLotDetails[0].exceptions
                    && this.taxLotDetails[0].exceptions.length > 0 ? true : false,
                isOverriddenPresent: this.isTaxlotExceptionPresent
            };
            if (this.title === CurrentAccountEnum.Edit) {
                this.currentAccountDetails.id = this.id;
            }
            this.accountDetailsEvent.emit({ data: [{ currentAccounts: [this.currentAccountDetails] }] });
        } else {
            this.currentAccountDetails = {
                proposalId: this.proposal.id
            };
            this.accountDetailsEvent.emit({ data: [{ currentAccounts: [this.currentAccountDetails] }] });
        }
    }

    public uploadDocuments(taxLotDetails: TaxLotDetails[]): void {
        this.taxLotDetails = taxLotDetails;
        this.isTaxlotExceptionPresent = false;
        this.documentsEvent.emit(taxLotDetails);
    }
}
