<div class="form-group topnav-strategy-menu">
    <a class="col-xs-4"
       [class.active]="selectedMenuItem === this.enumMenuSelection.ExploreStrategy"
       (click)="toggleMenu(this.enumMenuSelection.ExploreStrategy)">
        {{enumMenuSelection.ExploreStrategy}}</a>
    <a class="col-xs-4"
       [class.active]="selectedMenuItem === this.enumMenuSelection.PreviouslyUsed"
       [class.disabled]="filteredStrategiesExist"
       (click)="toggleMenu(this.enumMenuSelection.PreviouslyUsed)">{{ enumMenuSelection.PreviouslyUsed }}</a>
    <a class="col-xs-4"
       [class.active]="selectedMenuItem === this.enumMenuSelection.CustomizeStrategy"
       [hidden]="true"
       (click)="toggleMenu(this.enumMenuSelection.CustomizeStrategy)">
        {{enumMenuSelection.CustomizeStrategy}}</a>
</div>
