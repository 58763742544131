/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { Address, AddressType } from '@CarInterfaces/account';
import { BaseFactory } from '@CarInterfaces/base-factory';
import { AddressTypes } from '@CarModels/enums';

@Injectable({
    providedIn: 'root'
})
export class AddressFactory implements BaseFactory<Address> {
    // Will create an address of type LEGAL by default
    public createObject(type?: AddressType, typeId?: number): Address {
        if (!type && typeId > 0) {
            type = AddressFactory.createAddressType(typeId);
        }

        if (!type) {
            type = AddressFactory.createAddressType();
        }

        return {
            addressType: type,
            // We have code in Account Owners that is breaking with out state and country being declared
            // I am not sure where but when I have this, it works, if we don't declare this it doesn't work.
            // Someone gets extra credit for fixing this.
            state: {
                stateId: 0,
                sortOrder: 0,
                stateCode: ''
            },
            country: {
                countryCode: '',
                sortOrder: -1,
                countryId: -1,
                countryName: ''
            }
        };
    }

    // tslint:disable-next-line: member-ordering
    public static createAddressType(typeId?: number): AddressType {
        switch (typeId) {
            case AddressTypes.Mailing:
                return {
                    addressTypeId: 2,
                    addressTypeDescription: 'Mailing'
                };
            default:
                return {
                    addressTypeId: 1,
                    addressTypeDescription: 'Legal'
                };
        }
    }
}
