<div class="row">
    <div class="col-xs-12">
        <h5>Primary Advisor</h5>
        <sei-dropdown id="primaryAdvisorDropdown"
                      [ngModel]="selectedPrimaryAdvisorSet"
                      (ngModelChange)="onPrimaryAdvisorChange($event)"
                      [items]="primaryAdvisorDropdownItems"
                      [multiSelect]="false"></sei-dropdown>
    </div>
</div>
<div class="row"
     *ngIf="additionalAdvisorDropdownItems.size > 1">
    <div class="col-xs-12">
        <h5>Additional Advisors</h5>
        <sei-dropdown id="additionalAdvisorDropdown"
                      [ngModel]="selectedAdditionalAdvisorSet"
                      (ngModelChange)="onAdditionalAdvisorsDropdownChange($event)"
                      [items]="additionalAdvisorDropdownItems"
                      [multiSelect]="true"></sei-dropdown>
    </div>
</div>
