
/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { DropdownItem } from '@sei/common-components-lib-ux';
import { OrderedSet } from 'immutable';
import { Observable } from 'rxjs';
import { TaxRateFormResponse } from '../../tax-rate/model/tax-rate.model';

export interface AccountOwnerDetails {
    id: number | string;
    name: string;
}

export interface CurrentAccountDetails {
    id: string | number;
    currentAccountName: string;
}

export interface ProposedAccount {
    proposalAccountId: string | number;
    accountName: string;
}

export interface TaxTransitionResponse {
    data: TaxTransitionData;
    paging?: string;
    error?: ErrorDataDetails;
}

export interface ErrorDataDetails {
    code: string;
    message: string;
    trackingId: string;
    errors: ErrorDO[];
}

export interface ErrorDO {
    field: string;
    location: string;
    reason: string;
}

export interface TaxTransitionData {
    proposedAccounts: ProposedAccountData[];
    currentAccounts: CurrentAccountsData[];
}

export interface CurrentAccountsData {
    id: string | number;
    accountOwners: AccountOwnerDO;
    currentAccountName: string;
    accountType?: AccountTypeDO;
    isAccountTaxable?: boolean;
    proposalId: number;
    totalMarketValue?: number;
    taxlotFileName?: string;
}

export interface ProposedAccountData {
    proposalAccountId: string | number;
    accountName: string;
    proposalScenarioId?: string | number;
    accountTypeId?: number;
    isSeiAccount?: boolean;
    mrdcAmount?: number;
    allocatedAmount?: number;
    advisorFeeAmt?: number;
    seiFundExpenseAmt?: number;
    managementExpenseAmt?: number;
    advisorPlatformFeeAmt?: number;
    clientPlatformFeeAmt?: number;
    selectedRiskMethod?: number;
}

export interface TaxTransitionRequest {
    id: string;
    accountName: string;
}

export interface TaxTransitionDataDetails {
    accountOwner: OrderedSet<DropdownItem>;
    taxRateData: TaxRateFormat;
    currentAccounts: OrderedSet<DropdownItem>;
    proposedAccount: OrderedSet<DropdownItem>;
    dispositionMethod: OrderedSet<DropdownItem>;
}

export interface EditTaxTransitionData {
    isAddFlag: boolean;
    editDO?: EditDataObject;
}

export interface TaxRateFormat {
    federalIncome: string;
    federalCapitalGains: string;
    stateCapitalGains: string;
    stateIncome: string;
}

export enum TitleHeading {
    AccountDetails = 'Account Details',
    TaxRateDetails = 'Tax Rate Details',
    ProspectTaxRateWarning = 'The tax rate will not be saved for this owner.'
}

export enum DispositionMethod {
    LT = 'Least Tax',
    FIFO = 'First In, First Out',
    LIFO = 'Last In, First Out',
    HI = 'Highest Federal Cost',
    LO = 'Lowest Federal Cost',
    MA = 'Maximize Gain',
    MI = 'Minimize Gain'
}

export interface DispositionMethodData {
    id: string | number;
    value: string;
}

export interface EditDataObject {
    data: [DataDO];
    error?: ErrorDataDetails;
}

export interface DataDO {
    taxTransitionDTO: [TaxTransitionDTO];
}
export interface TaxTransitionDTO {
    taxTransitionAnalysisId?: number;
    taxTransitionAnalysisAccountId?: number;
    accountDetails: AccountDetailsObject;
    federalIncome: number;
    stateIncome: number;
    federalCapitalGains: number;
    stateCapitalGains: number;
    isSaveTaxRates: boolean;
    dispositionMethod?: string;
    taxEfficiency?: string[];
    isTaxRatesApiFailed?: boolean;
}
export interface SubmitDataObject {
    data: [DataSubmitDO];
}
export interface DataSubmitDO {
    taxTransitionDTO: [TaxTransitionSubmitDTO];
}
export interface TaxTransitionSubmitDTO {
    accountDetails: AccountDetailsSubmitDO;
    federalIncome: number;
    stateIncome: number;
    federalCapitalGains: number;
    stateCapitalGains: number;
    taxEfficiency: string[];
    isSaveTaxRates: boolean;
    dispositionMethod: string;
}
export interface CurrentAccountsSubmitDO {
    id: string | number;
    accountOwners: AccountOwnerDO;
    currentAccountName: string;
    accountType?: AccountTypeDO;
    isAccountTaxable?: boolean;
    proposalId: number;
    totalMarketValue?: number;
    taxlotFileName?: string;
    taxLotDocument?: TaxLotDocumentDetails;
}

export interface TaxLotDocumentDetails {
    taxLotDocumentId: number;
}

export interface AccountDetailsSubmitDO {
    proposedAccounts: ProposedAccountData[];
    currentAccounts: CurrentAccountsSubmitDO[];
}
export interface AccountDetailsObject {
    proposedAccounts: ProposedAccountData[];
    currentAccounts: CurrentAccountsData[];
}

export interface AccountTypeDO {
    accountTypeDescription: string;
    accountTypeId: number;
    taxQualified: boolean;
    qualifiedPlan: boolean;
}

export interface AccountOwnerDO {
    id: string | number;
    name: string;
    entityId: number;
    entityType: string;
}

export interface EnableButtons {
    disableSubmit: boolean;
    disableSaveExit: boolean;
}

export interface TaxTransitionAnalysisProxyInterface {
    getTaxRates(entityId: number, entityType: string): Observable<TaxRateFormResponse>;
    getTaxTransitionData(proposalId: string): Observable<TaxTransitionResponse>;
}
