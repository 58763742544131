/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, Input } from '@angular/core';
import { DisplayNullOptions, DisplayPercentOptions } from '@sei/common-components-lib-ux';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { PercentFormatPipe } from 'projects/advisor-client-review-proposal-ux-app/src/app/core/pipes/percent-format.pipe';
import { AdvisorFee, FeeAccount, ProposalPercentFormat } from '../../model/account-fee';
import { CurrencyTypes } from '../../model/enums';


@Component({
    selector: 'sei-car-fee-schedule-account',
    templateUrl: './fee-schedule-account.component.html',
    providers: [PercentFormatPipe]
})
export class FeeScheduleAccountComponent extends ParentComponentSubscriptionManager {

    @Input()
    public account: FeeAccount;
    @Input()
    public advisorFee: AdvisorFee;

    public currencyTypes: typeof CurrencyTypes = CurrencyTypes;
    public showSectionDetails: boolean = false;
    private displayPercentOptions: DisplayPercentOptions = {
        displayPercentSymbol: true,
        roundToDecimal: 2, nullDisplay: DisplayNullOptions.BLANK
    };
    public proposalPercentFormatOptions: ProposalPercentFormat = {
        isFeeBreakdown: false,
        displayPercentOptions: this.displayPercentOptions
    };
    constructor() {
        super('FeeScheduleAccountComponent');
    }

    public onShowDetailsClick(): void {
        this.showSectionDetails = !this.showSectionDetails ? true : false;
    }

}
