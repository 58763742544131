/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { GlobalService } from '@CarServices/system/global.service';
import { Store } from '@ngrx/store';
import { SeiCommonAppStore } from '@sei/common-swp-components-lib-ux';
import { Observable, of, throwError } from 'rxjs';
import { catchError, skipWhile, switchMap, take } from 'rxjs/operators';
import { ApigeeTokenService } from './apigee-token.service';

/**
 * JWT guard to ensure we have JWT if apigee is enabled in configuration for the environment
 * ToDo -> At some point we want this at the interceptor level. However, currently this causes a race condition
 *    The guard enables us to make sure JWT is current and/or apigee is up prior to using CAR.
 *    This may be the perm solution. Since SEI is using CAR as a testbed, this wasn't really vetted out with SEI and could change.
 */
@Injectable({
    providedIn: 'root'
})
export class ApigeeTokenGuard {
    constructor(
        private apigeeTokenService: ApigeeTokenService,
        private global: GlobalService,
        private appStore: Store<SeiCommonAppStore>
    ) { }
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        // if we are not enabled, continue on with the request
        if (!this.global.configService.environment.carConfig.apigeeEnabled) {
            return of(true);
        }

        // If we are already validated, continue, no need to generate new JWT
        if (this.apigeeTokenService.validate()) {
            return of(true);
        }

        // Get Token from Store.
        return this.appStore.select((store) => store.apigeeSlice)
            .pipe(
                skipWhile((data) => this.global.isEmpty(data.accessToken) || !Object.keys(data).length),
                // map((data) => console.log(data.accessToken)),
                take(1),
                switchMap(() => {
                    return of(true);
                }),
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                catchError((error: any) => {
                    // ToDo -> Will address in exception handling branch
                    return throwError(error);
                })
            );
    }
}
