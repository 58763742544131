/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import {
    Directive,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    OnChanges,
    Output,
    SimpleChanges
} from '@angular/core';

@Directive({
    selector: '[carFileDrop]'
})
export class FileDropDirective implements OnChanges {
    @Input() private allowedExtensions: string[] = [];
    @Input() private maxFilesAllowed: number = Number.MAX_SAFE_INTEGER;
    @Input() private backgroundColorDefault: string = '#FFFFFF';
    @Input() private backgroundColorHover: string = '#D2D2D2';

    @Output() private validFilesChangeEmitter: EventEmitter<File[]> = new EventEmitter();
    @Output() private invalidFilesChangeEmitter: EventEmitter<File[]> = new EventEmitter();
    @Output() private tooManyFilesErrorEmitter: EventEmitter<number> = new EventEmitter();

    /* tslint:disable:no-unused-variable */
    @HostBinding('style.background') public background = this
        .backgroundColorDefault;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.backgroundColorDefault) {
            this.background = this.backgroundColorDefault;
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @HostListener('dragover', ['$event']) onDragOver(event) {
        // Send the user feedback that something is going to be added
        event.preventDefault();
        event.stopPropagation();
        this.background = this.backgroundColorHover;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @HostListener('dragleave', ['$event']) onDragLeave(event) {
        // Return the control to it's original background color
        event.preventDefault();
        event.stopPropagation();
        this.background = this.backgroundColorDefault;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @HostListener('drop', ['$event']) onDrop(event) {
        event.preventDefault();
        event.stopPropagation();
        this.background = this.backgroundColorDefault;
        const files = event.dataTransfer.files;

        if (files.length > 0) {
            // If the user is adding more files than expected: throw an error
            if (files.length > this.maxFilesAllowed) {
                this.tooManyFilesErrorEmitter.emit(files.length);
                return;
            }

            const validFiles: File[] = [];
            const invalidFiles: File[] = [];
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const ext = file.name.split('.')[
                    file.name.split('.').length - 1
                ];
                if (this.allowedExtensions.lastIndexOf(ext) !== -1) {
                    validFiles.push(file);
                } else {
                    invalidFiles.push(file);
                }
            }
            this.validFilesChangeEmitter.emit(validFiles);
            this.invalidFilesChangeEmitter.emit(invalidFiles);
        }
    }
}
