/*
 * Copyright: This information co../../../model/presentationty of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { PresentationChecklistService } from 'projects/advisor-client-review-proposal-ux/src/lib/service/presentation-checklist.service';
import { DropdownItem } from '@sei/common-components-lib-ux';
import { OrderedSet } from 'immutable';
import { ProposalSectionLabel } from 'projects/advisor-client-review-proposal-ux/src/lib/model/enums';
import { ProposalService } from '../../../../service/proposal.service';
import { SettingsElementComponent } from '../../../../common/settings-element/settings-element.component';
import { AnalyticsBenchmark, ProposalSection, SelectOption } from '../../../../model/presentation';
import { DataSharingService } from '../../../../service/data-sharing.service';
import { PresentationSettingService } from '../../../../service/presentation-setting.service';

@Component({
    selector: 'sei-car-analytics',
    templateUrl: './analytics.component.html'
})
export class AnalyticsComponent extends SettingsElementComponent implements OnInit {

    private benchmarkDropdownItems: SelectOption[] = [];
    private marketIndexSection: ProposalSectionLabel = ProposalSectionLabel.MarketIndex;

    constructor(
        presentationSettingService: PresentationSettingService,
        dataSharingService: DataSharingService,
        route: ActivatedRoute,
        router: Router,
        proposalService: ProposalService,
        private readonly presentationChecklistService: PresentationChecklistService) {
        super(presentationSettingService, dataSharingService, router, route, proposalService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.presentationChecklistService.retrieveAnalyticsBenchmarkItems().subscribe((benchmarkItems: AnalyticsBenchmark[]) => {
            benchmarkItems.forEach((item: AnalyticsBenchmark) =>
                this.benchmarkDropdownItems.push({ value: item.analyticsBenchmarkName, label: item.analyticsBenchmarkName }));
        });
    }


    public parentToggleChange(): void {
        this.dataSharingService.proposalSectionChanged.next(this.proposalSection);
    }

    public modelChanged(subSection: ProposalSection): void {
        this.dataSharingService.proposalSectionChanged.next(this.proposalSection);
    }

    public dropItem(event: CdkDragDrop<ProposalSection[]>): void {
        if (event.previousContainer === event.container) {
            moveItemInArray(
                event.container.data,
                event.previousIndex,
                event.currentIndex
            );
        }

        event.container.data.forEach((section, index) => section.sortOrder = index);

        if (this.proposalSection) {
            this.dataSharingService.proposalSectionChanged.next(this.proposalSection);
        }

    }

    public getConnectedList(): String[] {
        return this.proposalSection.sections.map((x) => `${x.id}`);
    }

    public onMarketIndexChange(): void {
        this.dataSharingService.proposalSectionChanged.next(this.proposalSection);
    }

}
