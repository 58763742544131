/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
import { AccountTypes } from '@CarModels/account-type-options';
import { ExistingAccountData } from '@CarModels/existing-accounts';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpUtil } from '@sei/common-swp-components-lib-ux';
import { ModelStrategyGroupType, Portfolio, PortfolioHolding } from '@sei/ias-applications-lib-ux';
import _ from 'lodash';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { FeatureFlagService } from '@CarServices/feature-flag/feature-flag.service';
import { HudSummary } from '../model/account-fee';
import { ActionTags, ContactTypes, InvestmentProgramType, PortfolioPurpose, RiskLevelValues, RiskMethods, RiskToggleOptions, RiskToleranceLevel, TypeOfOwnerId, WipAccountStatus, WipChecklistLabels } from '../model/enums';
import { AccountFactory } from '../model/factory/account-factory';
import { FeesFactory } from '../model/factory/fees-factory';
import { Account, Client, EnrichedAccountAndFeesResponse, EnrichedPortfolioResponseItem, GroupCHNWStrategy, InvestmentSelectionOptions, MdcSnapshot, Proposal, ProposalAccount, RiskTolerance, Scenario, Strategy, WipCheckList } from '../model/proposal';
import { SeiPayload } from '../model/sei-payload';
import { ProposalService } from './proposal.service';
import { GlobalService } from './system/global.service';

@Injectable({
    providedIn: 'root'
})
export class ProposalAccountService {
    public requiredSections: WipCheckList[] = [];
    public optionalSections: WipCheckList[] = [];
    public accountsWithStrategy: Account[] = [];
    public accountsChanged: Account[] = [];
    public accountsCompleted: WipCheckList[] = [];
    public isPortfolioCallInProgress: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public feesHudSummary: BehaviorSubject<HudSummary> = new BehaviorSubject(undefined);
    public readonly NUMBER_OF_RTQ_QUESTIONS: number = 10;
    public readonly MAX_ACCOUNT_NAME_LENGTH: number = 135;
    private readonly MAX_NUMBER_OF_ACCOUNTS: number = 10;

    constructor(
        private http: HttpClient,
        private httpUtil: HttpUtil,
        protected proposalService: ProposalService,
        protected carGlobal: GlobalService,
        private featureFlagService: FeatureFlagService) { }

    private verifyNotCompletedSections(requiredSections: WipCheckList[]): boolean {
        let notCompleted: WipCheckList[] = [];
        requiredSections.forEach((section: WipCheckList) => {
            notCompleted = section.subCheckList.filter((subSection: WipCheckList) => {
                if (subSection.name !== WipChecklistLabels.FeeAnalysis && subSection.name !== WipChecklistLabels.CurrentAccounts &&
                    subSection.name !== WipChecklistLabels.TaxLots) {
                    return subSection.mdcSnapShot.completed !== WipAccountStatus.Complete;
                }
            });
        });

        return (notCompleted.length === 0);
    }

    private verifyCompletedSections(requiredSections): WipCheckList[] {
        let requiredAccount: WipCheckList[] = [];
        const requiredSection: WipCheckList[] = [];
        requiredSections.forEach((section: WipCheckList) => {
            requiredAccount = section.subCheckList.filter((account) => {
                return account.mdcSnapShot.completed === WipAccountStatus.Complete;
            });

            if (requiredAccount.length === section.subCheckList.length) {
                requiredSection.push(section);
            }
        });
        return requiredSection;
    }

    private getAccountsWithStrategies(accounts: Account[]) {
        return accounts.filter(
            (account) => {
                return account.strategies && account.strategies.length > 0;
            }
        );
    }

    private getRequiredSections(wipCheckList: WipCheckList[]) {
        return wipCheckList.filter(
            (checkList) => {
                return checkList.sectionType === WipChecklistLabels.ProposalScenario;
            }
        );
    }

    private getOptionalSections(wipCheckList: WipCheckList[]) {
        return wipCheckList.filter(
            (checkList) => {
                return checkList.sectionType === WipChecklistLabels.AssumptionsTransitionAnalysis ||
                    checkList.sectionType === WipChecklistLabels.ClientRiskPreference;
            }
        );
    }

    private getAccountsChanged(accounts: Account[]) {
        return accounts.filter(
            (account) => {
                return account.actionTag.id === ActionTags.Change;
            }
        );
    }

    private verifyRiskTolerance(riskTolerance: RiskTolerance): boolean {
        if (
            riskTolerance.riskMethod !== null &&
            riskTolerance.riskMethod !== 0
        ) {
            switch (riskTolerance.riskMethod) {
                case RiskMethods.LowMediumHigh:
                    return (riskTolerance.selectedLowMedHighRisk > 0);
                case RiskMethods.Questionnaire:
                    return (
                        riskTolerance.fillQuestionnaire &&
                        riskTolerance.calculatedQuestionnaireRisk > 0
                    );
                case RiskMethods.Skip:
                    return true;
            }
        }
        return false;
    }

    public updateProposalAccount(
        proposalAccountId: number,
        account: Account,
        groupCHNWStrategy: GroupCHNWStrategy[] = []
    ): Observable<SeiPayload> {

        const apiUrl = this.carGlobal.configService.routeFormatter(
            this.carGlobal.configService.getEndPoint('updateProposalAccount'),
            [
                {
                    key: 'proposalAccountId',
                    value: proposalAccountId
                }
            ]
        );

        const accountWithCHNW: ProposalAccount = {
            account,
            groupCHNWStrategy
        };
        if (this.carGlobal.isMockDataMode()) {
            const mockURL: string = 'assets/mock-data/update-proposal-account.json';
            return this.httpUtil.doMockPostStronglyTyped<SeiPayload>(mockURL, 1000);
        }
        return this.http.put<SeiPayload>(apiUrl, JSON.stringify(accountWithCHNW));
    }

    public verifyProposal(proposal: Proposal): boolean {

        let isComplete: boolean = false;
        let isRequiredSectionsComplete: boolean = false;

        proposal.scenarios.forEach((proposedScenario) => {

            const newOrChangeAccount: Account[] = proposedScenario.accounts.filter((account) => {
                return account.actionTag.id === ActionTags.New || account.actionTag.id === ActionTags.Change;
            });

            if (
                proposal.wipCheckList &&
                newOrChangeAccount &&
                newOrChangeAccount.length > 0
            ) {
                const completedOptionalSections: WipCheckList[] = [];
                // const completedRequiredSections: WipCheckList[] = [];
                const strategiesWithoutBalance: Strategy[] = [];

                this.accountsWithStrategy = this.getAccountsWithStrategies(newOrChangeAccount);

                this.requiredSections = this.getRequiredSections(proposal.wipCheckList);

                this.optionalSections = this.getOptionalSections(proposal.wipCheckList);

                this.accountsWithStrategy.forEach((account) => {
                    if (account.strategies) {
                        strategiesWithoutBalance.push(
                            ...account.strategies.filter((strategy) => strategy.amount <= 0 || strategy.amount === undefined)
                        );
                    }
                });

                if (this.requiredSections.length > 0) {
                    // Note: leave for now..take out once checklist is finished
                    // completedRequiredSections.push(...this.verifyCompletedSections(requiredSections));
                    isRequiredSectionsComplete = this.verifyNotCompletedSections(this.requiredSections);
                }

                if (this.optionalSections.length > 0) {
                    completedOptionalSections.push(...this.verifyCompletedSections(this.optionalSections));
                }

                isComplete = (
                    this.accountsWithStrategy.length === newOrChangeAccount.length &&
                    strategiesWithoutBalance.length <= 0 &&
                    isRequiredSectionsComplete
                );
            }
        });

        return isComplete;
    }

    public verifyImplementProposal(proposal: Proposal, scenarioId: number): boolean {
        // TODO: Still needs implementation for the non-SEI accounts as this is not existent in the actual scenarios:
        // Rule: Existing non-SEI flagged as ‘partial transition’ – assume some balance change must have been made
        let isComplete: boolean = false;

        const scenarioSelected = proposal.scenarios.find((scenario) => scenario.id === scenarioId);

        if (scenarioSelected) {
            const newOrChangeAccount: Account[] = scenarioSelected.accounts.filter((account) => {
                return account.actionTag.id === ActionTags.New || account.actionTag.id === ActionTags.Change;
            });

            if (
                proposal.wipCheckList &&
                newOrChangeAccount &&
                newOrChangeAccount.length > 0
            ) {
                this.accountsWithStrategy = this.getAccountsWithStrategies(newOrChangeAccount);

                this.accountsChanged = this.getAccountsChanged(newOrChangeAccount);

                // TODO: Checking Required sections only, in the future check every proposal account?
                this.requiredSections = this.getRequiredSections(proposal.wipCheckList);
                this.accountsCompleted = this.verifyCompletedSections(this.requiredSections);

                isComplete = (
                    this.accountsWithStrategy.length === newOrChangeAccount.length &&
                    this.accountsChanged.length === newOrChangeAccount.length &&
                    this.accountsCompleted.length === newOrChangeAccount.length
                );
            }
        }

        return isComplete;
    }

    public verifyProposalAccountStatus(account: Account): number {
        let accountStatus: number = WipAccountStatus.New;

        if (
            account.actionTag.id === ActionTags.New ||
            account.actionTag.id === ActionTags.Change
        ) {
            const hasStrategies: boolean = (
                account.strategies &&
                account.strategies.length > 0
            );

            const hasStrategiesWithBalance: Strategy[] = account.strategies.filter(
                (strategy) => strategy.amount > 0 && strategy.amount !== undefined
            );
            // If current account - assume RTQ has been completed
            const isCurrentAccount: boolean =
                this.featureFlagService?.checkExistingAccountsSectionEnabled() && !_.isNil(account?.currentAccountNumber);
            const hasRiskTolerance: boolean = isCurrentAccount ? true : this.verifyRiskTolerance(account.riskTolerance);

            accountStatus =
                (
                    hasStrategies &&
                    hasRiskTolerance &&
                    hasStrategiesWithBalance.length > 0
                ) ? WipAccountStatus.Complete :
                    (
                        hasStrategies ||
                        hasRiskTolerance ||
                        hasStrategiesWithBalance.length > 0
                    ) ? WipAccountStatus.Incomplete : WipAccountStatus.New;
        }
        return accountStatus;
    }

    public getAccountGroupType(account: Account): string {
        // NOTE: This will be remove when this account group type is done.
        const defaultAccountType: string = 'Non-IRA';
        if (
            account &&
            account.type &&
            account.type.groupType
        ) {
            return account.type.groupType.groupName || defaultAccountType;
        }

        return defaultAccountType;
    }


    public getInvestmentProgramInvestmentSelectionOptions(investmentProgramType: InvestmentProgramType): InvestmentSelectionOptions {
        return {
            [InvestmentProgramType.MutualFunds]: {
                isMASProgram: false,
                isDFSAccount: false,
                isRochdaleAccount: false,
                isShowFavorties: true,
                isShowExploreSEI: true,
                isShowSearch: false,
                isViewAllStrategies: true,
                strategyToggleDisabled: true,
                selectedInvestmentProgramType: undefined,
                hideCustomUmaModelOptions: false
            },
            [InvestmentProgramType.ManagedAccounts]: {
                isMASProgram: true,
                isDFSAccount: false,
                isRochdaleAccount: false,
                isShowFavorties: true,
                isShowExploreSEI: true,
                isShowSearch: true,
                isViewAllStrategies: true,
                strategyToggleDisabled: true,
                selectedInvestmentProgramType: undefined,
                hideCustomUmaModelOptions: false
            },
            [InvestmentProgramType.DistributionFocusedStrategies]: {
                isMASProgram: false,
                isDFSAccount: true,
                isRochdaleAccount: false,
                isShowFavorties: false,
                isShowExploreSEI: false,
                isShowSearch: false,
                isViewAllStrategies: true,
                strategyToggleDisabled: false,
                selectedInvestmentProgramType: ModelStrategyGroupType.DistributionFocusedStrategies,
                hideCustomUmaModelOptions: true
            },
            [InvestmentProgramType.CustomHighNetWorth]: {
                isMASProgram: false,
                isDFSAccount: false,
                isRochdaleAccount: true,
                isShowFavorties: false,
                isShowExploreSEI: false,
                isShowSearch: false,
                isViewAllStrategies: true,
                strategyToggleDisabled: false,
                selectedInvestmentProgramType: ModelStrategyGroupType.CustomHighNetWorth,
                hideCustomUmaModelOptions: true
            }
        }[investmentProgramType];
    }

    public getEnrichedPortfolioDetails(proposalId: number): Observable<EnrichedPortfolioResponseItem[]> {
        const apiUrl = this.carGlobal.configService.routeFormatter(
            this.carGlobal.configService.getEndPoint('retrieveEnrichedAccountData'),
            [
                {
                    key: 'proposalId',
                    value: proposalId
                }
            ]
        );
        this.isPortfolioCallInProgress.next(true);
        return this.http.get<EnrichedPortfolioResponseItem[]>(apiUrl).pipe(map((value: EnrichedPortfolioResponseItem[]) => {
            this.isPortfolioCallInProgress.next(false);
            return value;
        }));
    }

    public isNonModelAssetBondAsset(portfolioHolding: PortfolioHolding): boolean {
        return portfolioHolding.assetData?.assetTypeCode?.startsWith('D');
    }

    public calculateNonModelInvestmentTotal(portfolios: Portfolio[]): number {
        if (portfolios?.length > 0) {
            let nonModelInvestmentTotal: number = 0;
            portfolios.forEach((portfolio: Portfolio) => {
                nonModelInvestmentTotal += this.calculateValueOfNonModelPortfolio(portfolio);
            });
            return Number(nonModelInvestmentTotal);
        }

        return 0;
    }

    public calculateValueOfNonModelPortfolio(portfolio: Portfolio): number {
        return this.proposalService.calculateValueOfNonModelPortfolio(portfolio);
    }

    public getEnrichedAccountFees(proposal: Proposal): Observable<EnrichedAccountAndFeesResponse> {
        const apiUrl = this.carGlobal.configService.routeFormatter(
            this.carGlobal.configService.getEndPoint('retrieveEnrichedAccountData'),
            [
                {
                    key: 'proposalId',
                    value: proposal.id
                }
            ]
        );

        this.isPortfolioCallInProgress.next(true);
        this.feesHudSummary.next(undefined);
        return this.http.post<EnrichedAccountAndFeesResponse>(apiUrl, proposal).pipe(map((value: EnrichedAccountAndFeesResponse) => {
            this.isPortfolioCallInProgress.next(false);
            this.feesHudSummary.next(value.proposalHudSummaryDTO);
            return value;
        }));

    }

    public isClientDirectedPresentInProposal(proposal: Proposal): boolean {
        return proposal?.scenarios?.some((scenario: Scenario): boolean => {
            return this.isClientDirectedPresentInScenario(scenario);
        });
    }

    public isClientDirectedPresentInScenario(scenario: Scenario): boolean {
        return scenario?.accounts?.some((account: Account): boolean => {
            return account?.portfolios?.some((portfolio: Portfolio) => {
                return portfolio?.portfolioPurpose?.portfolioPurposeId === PortfolioPurpose.ClientDirected;
            });
        });
    }

    public getContactTypeFromTypeOfOwner(typeOfOwner: TypeOfOwnerId): ContactTypes {
        return {
            [TypeOfOwnerId.IndividualAdult]: ContactTypes.Individual,
            [TypeOfOwnerId.IndividualMinor]: ContactTypes.Individual,
            [TypeOfOwnerId.Trust]: ContactTypes.Organization,
            [TypeOfOwnerId.Estate]: ContactTypes.Organization
        }[typeOfOwner];
    }

    public isAccountIncomplete(mdcSnapShot: MdcSnapshot): boolean {
        return (mdcSnapShot?.completed === WipAccountStatus.Incomplete) ||
            (mdcSnapShot?.completed === WipAccountStatus.New || !mdcSnapShot);
    }

    public mapAccountRiskScoreToRiskName(account: Account): string {
        const riskScoreFromAccount: number = account?.riskTolerance?.selectedLowMedHighRisk;
        const accountRiskMethodSelection: number = account?.riskTolerance?.riskMethod;
        const allRtqQuestionsAnswered: boolean = account?.riskTolerance?.questionnaire?.length === this.NUMBER_OF_RTQ_QUESTIONS;
        if (account?.strategies?.length === 0 && riskScoreFromAccount === null && accountRiskMethodSelection === null) {
            return '';
        } else if (accountRiskMethodSelection === RiskMethods.LowMediumHigh) {
            if (riskScoreFromAccount === RiskToleranceLevel.Low) {
                return RiskToggleOptions.Low;
            } else if (riskScoreFromAccount === RiskToleranceLevel.Medium) {
                return RiskToggleOptions.Medium;
            } else if (riskScoreFromAccount === RiskToleranceLevel.High) {
                return RiskToggleOptions.High;
            } else {
                return RiskToggleOptions.Incomplete;
            }
        } else if (accountRiskMethodSelection === RiskMethods.Questionnaire) {
            if (allRtqQuestionsAnswered) {
                const calculatedQuestionnaireRisk: number = account?.riskTolerance?.calculatedQuestionnaireRisk;
                if (calculatedQuestionnaireRisk <= RiskLevelValues.LowMax) {
                    return RiskToggleOptions.Low;
                } else if (calculatedQuestionnaireRisk >= RiskLevelValues.MediumMin &&
                    calculatedQuestionnaireRisk <= RiskLevelValues.MediumMax) {
                    return RiskToggleOptions.Medium;
                } else if (calculatedQuestionnaireRisk >= RiskLevelValues.HighMin) {
                    return RiskToggleOptions.High;
                }
            } else {
                return RiskToggleOptions.Incomplete;
            }
        } else if (accountRiskMethodSelection === RiskMethods.Skip) {
            return RiskToggleOptions.Skipped;
        }
    }

    public doesAccountHaveSelectedRiskMethod(account: Account): boolean {
        return account?.riskTolerance?.riskMethod > 0;
    }

    public setAccountRiskToSkippedStatusIfNeeded(account: Account): void {
        if (!this.doesAccountHaveSelectedRiskMethod(account)) {
            account.riskTolerance.riskMethod = RiskMethods.Skip;
        }
    }

    public buildAccountNameAndCheckLength(account: Account): boolean {
        let accountName: string = '';
        const accountTypeId: number = account?.type?.id;
        const accountOwners: Client[] = account?.owners;
        if (accountOwners?.length > 0) {
            // extraCharacters represents the number of characters that the service will append to the account name
            let extraCharacters: number = 0;
            if (accountTypeId === AccountTypes.UgmaId || accountTypeId === AccountTypes.UtmaId
                || accountTypeId === AccountTypes.GuardianshipId) {
                extraCharacters = account?.type?.description?.length + 5;
            } else if (accountTypeId === AccountTypes.IndividualOwnerId) {
                extraCharacters = 0;
            } else if (accountTypeId === AccountTypes.CommunityPropertyId) {
                extraCharacters = 10;
            } else if (accountTypeId === AccountTypes.CommunityPropertyWithRightsOfSurvivorshipId) {
                extraCharacters = 7;
            } else if (accountTypeId === AccountTypes.JointTenantsWithRightsOfSurvivorshipId) {
                extraCharacters = 7;
            } else if (accountTypeId === AccountTypes.TenantsByEntiretyId) {
                extraCharacters = 8;
            } else if (accountTypeId === AccountTypes.TenantsInCommonId) {
                extraCharacters = 8;
            } else if (accountTypeId === AccountTypes.InheritedRothIraId) {
                extraCharacters = 13;
            } else if (accountTypeId === AccountTypes.InheritedTraditionalIraId) {
                extraCharacters = 8;
            } else {
                extraCharacters = account?.type?.description?.length + 1;
            }
            accountName = this.generateAccountName(accountOwners);
            return accountName.length + extraCharacters > this.MAX_ACCOUNT_NAME_LENGTH;
        }
        return false;
    }

    private generateAccountName(accountOwners: Client[]): string {
        let accountName: string = '';
        for (let i: number = 0; i < accountOwners?.length; i++) {
            const name: string = accountOwners[i]?.firstName + ' ' + accountOwners[i]?.lastName;
            if (accountOwners?.length - i > 2) {
                accountName += name + ', ';
            } else if (accountOwners?.length - i === 2) {
                accountName += name + ' and ';
            } else {
                accountName += name;
            }
        }
        return accountName;
    }

    public getNewAllocationAmountIncludingAnyUnassignedFunds(account: Account, unallocatedCurrentAccountModelFunds: number): number {
        let adjustedAmount: number = account?.balance;
        if (account && account?.currentAccountId && unallocatedCurrentAccountModelFunds > 0) {
            adjustedAmount = unallocatedCurrentAccountModelFunds;
        }
        return adjustedAmount;
    }

    public addNewAccountToProposal(proposal: Proposal): void {
        if (proposal) {
            const scenario: Scenario = proposal?.scenarios[0];
            const account: Account = new AccountFactory().createObject(scenario?.accounts?.length + 1);
            account.isNewAccount = true;
            account.advisors =
                _.cloneDeep(proposal.advisors ? proposal?.advisors : proposal?.scenarios[0]?.accounts[0]?.advisors);
            account.fees = new FeesFactory().createFees(0, 0, 0, 0, 0, 0, 0, 0);
            if (!scenario.accounts) {
                scenario.accounts = [];
            }
            scenario.accounts.push(account);
            const updatedProposal: Proposal = _.cloneDeep(proposal);
            this.proposalService.changedProposal(updatedProposal);
        }
    }

    public checkIfMaximumAccountsHasBeenReached(selectedExistingAccounts: ExistingAccountData[],
                                                proposedAccounts: Account[],
                                                proposedOffset: boolean = false): boolean {
        const onlyProposedAccounts: Account[] = proposedAccounts?.filter((account: Account) => !account?.currentAccountId);
        if (proposedOffset) {
            return ((selectedExistingAccounts?.length * 2) + onlyProposedAccounts?.length) <= (this.MAX_NUMBER_OF_ACCOUNTS - 1);
        } else {
            return ((selectedExistingAccounts?.length * 2) + onlyProposedAccounts?.length) < this.MAX_NUMBER_OF_ACCOUNTS;
        }
    }

    public updateWipChecklistsForCurrentAccounts(proposal: Proposal): void {
        if (proposal && this.featureFlagService.checkExistingAccountsSectionEnabled()) {
            const proposalAccounts: Account[] = proposal.scenarios[0]?.accounts;
            proposalAccounts?.forEach((account: Account): void => {
                if (account?.currentAccountNumber) {
                    const isAccountValid: boolean =
                        this.verifyProposalAccountStatus(account) === WipAccountStatus.Complete;
                    const proposalAccountId: number = account?.id;
                    proposal.wipCheckList?.forEach((wipChecklist) => {
                        if (wipChecklist.sectionType === WipChecklistLabels.ProposalScenario) {
                            wipChecklist?.subCheckList?.forEach((subChecklist) => {
                                if (subChecklist?.name !== WipChecklistLabels.FeeAnalysis &&
                                    subChecklist?.name !== WipChecklistLabels.TaxLots &&
                                    subChecklist?.name !== WipChecklistLabels.CurrentAccounts) {
                                        const accountIdInPath: string[] = subChecklist?.route?.split('/');
                                        const isRouteMatch: boolean =
                                            Number(accountIdInPath[accountIdInPath?.length - 1]) === proposalAccountId;
                                        if (isRouteMatch) {
                                            subChecklist.mdcSnapShot.completed =
                                                isAccountValid ? WipAccountStatus.Complete : WipAccountStatus.Incomplete;
                                        }
                                    }
                            });
                        }
                    });
                }
            });
        }
    }
}
