/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FeatureFlagService } from '@CarServices/feature-flag/feature-flag.service';
import { AssetClassLevels, GlobalService, ProposalData, Scenario, SeiPayload } from '@sei/advisor-client-review-proposal-ux';
import Highcharts, { Options, PointOptionsObject } from 'highcharts';
import Heatmap from 'highcharts/modules/heatmap';
import Tree from 'highcharts/modules/treemap';
import * as _ from 'lodash';
import { map, Observable, share } from 'rxjs';

Tree(Highcharts);
Heatmap(Highcharts);

@Component({
    selector: 'car-tree-map-component',
    templateUrl: './tree-map-chart.component.html'
})
export class PocTreeMapComponent implements OnInit {

    constructor(private readonly route: ActivatedRoute,
        private http: HttpClient,
        protected carGlobal: GlobalService,
        private featureFlagService: FeatureFlagService,
        private router: Router) {
        if (!this.featureFlagService.isChartPOCEnabled()) {
            this.router.navigate(['/Proposal/Dashboard']);
        }
    }

    @ViewChild('treeMapChart', { static: true }) chartEl: ElementRef;

    public treeMapChartOptions: Options;
    private proposalId: string;
    private proposalData: ProposalData;
    private treeMapData: PointOptionsObject[] = [];
    private parents: string[] = [];

    ngOnInit(): void {
        this.proposalId = this.route.snapshot.params.proposalId;
        this.getPresentationData().pipe(share()).subscribe((proposalData: ProposalData) => {
            if (proposalData) {
                this.proposalData = _.cloneDeep(proposalData);
                this.setChartOptions();
                Highcharts.chart(this.chartEl.nativeElement, this.treeMapChartOptions);
            }
        });
    }

    public getPresentationData(): Observable<ProposalData> {
        const apiUrl = this.carGlobal.configService.routeFormatter(
            this.carGlobal.configService.getEndPoint('presentationChecklist'),
            [
                {
                    key: 'proposalId',
                    value: this.proposalId
                }
            ]
        );

        return this.http.get(apiUrl).pipe(
            map((response: SeiPayload) => {
                if (
                    response &&
                    response.data &&
                    response.data.length > 0 &&
                    response.data[0].checkList &&
                    response.data[0].checkList.length > 0
                ) {
                    return response.data[0].proposalData;
                }
            })
        );
    }

    setChartOptions() {
        const arr = Object.entries(this.proposalData.scenarios);
        const scenarioMap = new Map(arr);
        scenarioMap.forEach((value: Scenario) => {
            const assetClassArray = Object.entries(value.assetClasses.assetClassLevel3);
            const assetClassMap = new Map(assetClassArray);
            let i: number = 0;
            assetClassMap.forEach((assetClassValue: AssetClassLevels) => {
                this.treeMapData.push({
                    name: assetClassValue.name + '<br>' + (assetClassValue.percentage * 100).toPrecision(2) + '%',
                    value: Number(assetClassValue.amount.toPrecision(2)),
                    colorValue: i
                });
                i = i + 1;
            });
        });

        this.treeMapChartOptions = {
            colorAxis: {
                minColor: '#FFFFFF',
                maxColor: Highcharts.getOptions().colors[0]
            },
            series: [{
                type: 'treemap',
                layoutAlgorithm: 'squarified',
                borderColor: '#fff',
                borderRadius: 6,
                borderWidth: 2,
                dataLabels: {
                    style: {
                        textOutline: 'none'
                    }
                },
                data: this.treeMapData
            }],
            title: {
                text: 'L3 Asset Classes by amount',
                align: 'left'
            },
            tooltip: {
                useHTML: true,
                pointFormat:
                    'The amount of <b>{point.name}</b> is <b>{point.value}</b>'
            },
            credits: {
                enabled: false
            },
            chart: {
                width: 510
            }
        };
    }
}
