/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'shortnumber'
})
export class ShortNumberPipe implements PipeTransform {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transform(value: any, args?: any): any {
        const suffixes = ['K', 'M', 'B', 'T'];
        if (isNaN(value) || value === null) {
            return null;
        }

        if (value === '') {
            value = 0;
        }

        // Number is already "short", no need to transform
        if (value < 1000) {
            return value;
        }

        const exp = Math.floor(Math.log(value) / Math.log(1000));

        return (value / Math.pow(1000, exp)).toFixed(args) + suffixes[exp - 1];
    }

}
