<div class="fee-strategy-ribbon">
    <div>
        <p>{{hudSummary.fundExpense.label}}</p>
    </div>
    <div>
        <p>{{hudSummary.fundExpense.rate * 100 | proposalFormatPercentWithLessThan:proposalPercentFormatOptions }}</p>
    </div>
</div>
<div class="fee-strategy-ribbon">
    <div class="icon-row">
        <p>{{hudSummary.managementExpense.label}}</p>
        <sei-car-fee-breakdown-tooltip class="stack-margin breakdown-icon"
                                       [managementFeeBreakdown]="hudSummary?.managementFeeBreakdown"
                                       [includeBackground]="true"></sei-car-fee-breakdown-tooltip>
    </div>
    <div>
        <p>{{hudSummary.managementExpense.rate * 100 | proposalFormatPercentWithLessThan:proposalPercentFormatOptions }}</p>
    </div>
</div>
<div class="fee-strategy-ribbon">
    <div>
        <p>{{hudSummary.platformFee.label}}</p>
    </div>
    <div>
        <p>{{hudSummary.platformFee.rate * 100 | proposalFormatPercentWithLessThan:proposalPercentFormatOptions }}</p>
    </div>
</div>
<div class="fee-strategy-ribbon fee-strategy-ribbon-border">
    <div>Inv Cost</div>
    <div>
        <span>{{hudSummary.investmentCost.rate * 100 | proposalFormatPercentWithLessThan:proposalPercentFormatOptions }}</span>
    </div>
</div>
