/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { UserProfile } from '@CarInterfaces/user-profile';
import { ProcessingRulesService } from '@CarServices/processing-rules/processing-rules.service';
import { EnvironmentMode, GlobalService } from '@CarServices/system/global.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { AuthActionTypes, Login, Logout, UpdatePrimaryAdvisor } from './auth.actions';

@Injectable()
export class AuthEffects {
    private localStorageKey = 'userProfile';

    constructor(
        private readonly actionsStream: Actions,
        private readonly global: GlobalService,
        private readonly processingRulesService: ProcessingRulesService
    ) { }

    /**
     * Sets the userProfile object to local storage if GET was successful
     * This implementation may change from local storage in the future
     * this just assists with the refresh issue and the GUARD in place
     *
     */

    loginStream = createEffect(() => this.actionsStream.pipe(
        ofType<Login>(AuthActionTypes.LoginAction),
        tap((action: Login) =>
            localStorage.setItem(
                this.localStorageKey,
                JSON.stringify(action.payload.userProfile)
            )
        )
    ), { dispatch: false });

    /**
     * CAR technically has no logout. This just removes the userProfile from local storage
     * Only calls when landing on the HomeComponent. Will adjust closer to end to end and full ADE support
     */

    logoutStream = createEffect(() => this.actionsStream.pipe(
        ofType<Logout>(AuthActionTypes.LogoutAction),
        filter(
            () =>
                this.global.configService.environment.mode ===
                EnvironmentMode.Dev
        ),
        tap(() => {
            localStorage.removeItem(this.localStorageKey);
        })
    ), { dispatch: false });

    /**
     * to support refresh, if localStorage item exist then use that object over GET
     * Once we get Siteminder in better position this will work with the Siteminder data
     */
    initStream = createEffect(() => {
        const userData: UserProfile = JSON.parse(
            localStorage.getItem(this.localStorageKey)
        );

        // DEV is not siteminder enabled so only do this if we are in a DEV or Local environment.
        // All others, we want to have new sessions every time a new instance of CAR is opened
        if (
            userData &&
            this.global.configService.environment.mode === EnvironmentMode.Dev
        ) {
            return of(new Login({ userProfile: userData }));
        } else {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return of(new Logout()) as any;
        }
    });

    /**
     * Update processing business rules when primary advisor has been updated
     */

    updatePrimaryAdvisorStream = createEffect(() => this.actionsStream.pipe(
        ofType<UpdatePrimaryAdvisor>(
            AuthActionTypes.UpdatePrimaryAdvisorAction
        ),
        tap(() => {
            // Update processing business rules when primary advisor has been updated
            this.processingRulesService.initializeService();
        })
    ), { dispatch: false });
}
