/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig, AppConfigService, Logger } from '@sei/common-swp-components-lib-ux';

import { GlobalService } from '@sei/advisor-client-review-proposal-ux';
import CryptoJS, { WordArray } from 'crypto-js';
import { Observable } from 'rxjs';
import { Apigee } from './apigee.constants';

@Injectable({
    providedIn: 'root'
})
export class SiteminderValidatorService {

    private static KEY_PKCE_CODE_VERIFIER: string = '';

    constructor(
        private readonly appConfigService: AppConfigService,
        private readonly logger: Logger,
        private readonly global: GlobalService,
        private readonly httpClient: HttpClient
    ) {

    }

    /**
     * Go to Apigee for authentication to get an auth code
     *
     * @param useShaChallenge defaults to true. Flag if the PKCE code challenge should be sent via SHA or plain text (Apigee supports both).
     */
    public requestApigeeAuthCode(useShaChallenge: boolean = true): Observable<object> {

        // Determine the redirect url to send to OAuth (no hashes, no code/scope search param)
        const currentUrl: URL = new URL(this.getLocationHref());
        let params: HttpParams = new HttpParams({
            fromString: this.getLocationSearch().slice(1)
        });

        // Remove any code or scope params if they exist
        if (params.has(Apigee.SEARCH_PARAM_CODE) || params.has(Apigee.SEARCH_PARAM_SCOPE)) {
            params = params.delete(Apigee.SEARCH_PARAM_CODE);
            params = params.delete(Apigee.SEARCH_PARAM_SCOPE);
            currentUrl.search = params.toString();
        }

        const locationHash: string = this.getLocationHash();
        const redirectUrl: string = locationHash ? currentUrl.href.replace(locationHash, '') : currentUrl.href;

        const pkceCodeChallenge: string = this.generateAndStoreCodeChallenge(useShaChallenge);
        const codeChallengeMethod: string = useShaChallenge ? Apigee.CODE_CHALLENGE_METHOD_SHA : Apigee.CODE_CHALLENGE_METHOD_PLAIN;

        try {
            const appConfig: ApiConfig = this.appConfigService.getApiConfig();
            const authCodeEndpoint: string = this.getProxyUrl(appConfig, Apigee.AUTH_CODE_URL);

            const authUrl: URL = new URL(authCodeEndpoint);
            authUrl.searchParams.append(Apigee.KEY_RESPONSE_TYPE, Apigee.RESPONSE_TYPE_CODE);
            authUrl.searchParams.append(Apigee.KEY_CLIENT_ID, appConfig.apiKey);
            authUrl.searchParams.append(Apigee.KEY_REDIRECT_URI, redirectUrl);
            authUrl.searchParams.append(Apigee.KEY_CODE_CHALLENGE, pkceCodeChallenge);
            authUrl.searchParams.append(Apigee.KEY_CODE_CHALLENGE_METHOD, codeChallengeMethod);

            const options = {
                withCredentials: true
            };
            return this.httpClient.get(authUrl.toString(), options);
        } catch (err) {
            this.logger.error(err);
        }
    }

    /**
    * getProxyUrl returns the proxy url which is a concat of apigee url + proxy endpoint
    * @param ApiConfig with a populated apiUrl
    * @param proxyPath  (e.g. api/v1/swp/swpbrandingservice/brandId)
    */
    getProxyUrl(apiConfig: ApiConfig, proxyPath: string): string | undefined {
        if (!apiConfig) {
            this.logger.error('Config not found');
        } else if (this.global.isEmpty(proxyPath)) {
            this.logger.error('No proxy path');
        } else {
            const url: URL = new URL(proxyPath, apiConfig.apiUrl);
            return url.href;
        }
        return;
    }

    getLocationHref(): string {
        return window.location.href;
    }

    getLocationSearch(): string {
        return window.location.search;
    }

    public getLocationHash(): string {
        return window.location.hash;
    }

    /**
    * Generate PKCE code challenge and store the verifier in session storage
    */
    generateAndStoreCodeChallenge(useSha: boolean = true): string {
        const verifier: string = this.generateCodeVerifier();
        sessionStorage.setItem(SiteminderValidatorService.KEY_PKCE_CODE_VERIFIER, verifier);

        return useSha ? this.generateSHA256CodeChallenge(verifier) : verifier;
    }

    /**
    * Generate a random code verifier
     */
    private generateCodeVerifier(): string {
        return this.generateRandomASCIIString(128);
    }

    /**
     * Generate a random ASCII string with a given length
     * @param length Length of string to generate
     */
    private generateRandomASCIIString(length: number): string {
        const values: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        let text: string = '';
        for (let i: number = 0; i < length; i++) {
            text += values.charAt(Math.floor(Math.random() * values.length));
        }

        return text;
    }

    /**
     * Generate a SHA 256 hash code_challenge that is Base64 url encoded
     * https://tools.ietf.org/html/rfc7636#section-4.2
     * BASE64URL-ENCODE(SHA256(ASCII(code_verifier))) == code_challenge
     *
     * @param codeVerifier
     */
    private generateSHA256CodeChallenge(codeVerifier: string): string {
        return this.base64URLEncode(CryptoJS.SHA256(codeVerifier));
    }

    /**
     * Base 64 Url Encode a WordArray
     * base64url spec: https://tools.ietf.org/html/rfc4648#section-5
     * @param value
     */
    private base64URLEncode(value: WordArray): string {
        // Base 64 encode, then remove = at end, replace + with -, replace / with _
        return value.toString(CryptoJS.enc.Base64)
            .replace(/=+$/g, '')
            .replace(/\+/g, '-')
            .replace(/\//g, '_');
    }
}
