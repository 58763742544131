/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { ModelAllocation } from '@sei/ias-applications-lib-ux';
import { FundList } from '../allocation';
import { BaseFactoryAbstract } from './base-factory-abstract';

@Injectable()
export class ModelAllocationFactory extends BaseFactoryAbstract<ModelAllocation> {

    constructor() {
        super();
    }

    public createModelAllocation(
        assetName: string,
        fundList: FundList[]
    ): ModelAllocation {
        return {
            assetName,
            fundList
        };
    }
}
