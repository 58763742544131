/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
/* eslint-disable rxjs/no-sharereplay */

import { ExistingAccountChildNodes, ExistingAccountData, ExistingAccountModelInvestment } from '@CarModels/existing-accounts';
import { ExistingAccountsService } from '@CarServices/existing-accounts/existing-accounts.service';
import { Injectable } from '@angular/core';
import { ApigeeHttpUtil, Global, HttpUtil } from '@sei/common-swp-components-lib-ux';
import { AssetActivityType, CardDetail, ExploreDetailsParams, ExploreModel, ExploreStrategiesFilter, FundDetail, FundDetailAssetType, InvestmentSummary, InvestmentTypeId, LevelClass, ModelAllocation, ModelStrategyType, ModelType, NonStrategyAssetDetails, ProductDetail, ProductScope, RiskTypeDescription, SubstitutionsCategory, SubstitutionsDetails } from '@sei/ias-applications-lib-ux';
import BigNumber from 'bignumber.js';
import * as _ from 'lodash';
import { Observable, merge } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { FundList } from '../model/allocation';
import {
    AllocationLevelClass,
    AssetNames,
    CashAndEquivalents,
    InvestmentProgramType,
    ModelTypeId,
    OptionExploreStrategy,
    OptionExploreStrategyDropDown,
    RiskLevelValues,
    RiskToleranceLevel
} from '../model/enums';
import { AllocationFactory } from '../model/factory/allocation-factory';
import { FundListFactory } from '../model/factory/fund-list-factory';
import { ModelAllocationFactory } from '../model/factory/modell-allocation-factory';
import { ProductDetailFactory } from '../model/factory/product-detail-factory';
import {
    Account,
    Allocation,
    CombinedStrategyRisk,
    CombinedStrategyRiskResponse,
    CustomStrategy,
    Strategy,
    Type
} from '../model/proposal';
import { SeiPayload } from '../model/sei-payload';
import { ProposalService } from './proposal.service';
import { GlobalService } from './system/global.service';
@Injectable({
    providedIn: 'root'
})
export class StrategiesService {

    public assetNames = [
        AssetNames.Equity,
        AssetNames.FixedIncome,
        AssetNames.Alternative,
        AssetNames.MultiAsset,
        AssetNames.Cash
    ];

    constructor(
        private apigeeHttpUtil: ApigeeHttpUtil,
        private httpUtil: HttpUtil,
        private global: Global,
        protected carGlobal: GlobalService,
        private existingAccountsService: ExistingAccountsService,
        private proposalService: ProposalService
    ) { }

    public getStrategiesExploreStrategies(firmId: number): Observable<Strategy[]> {
        const apiUrl = this.carGlobal.configService.routeFormatter(
            this.carGlobal.configService.getEndPoint('availableStrategies'),
            [
                {
                    key: 'firmId',
                    value: firmId
                }
            ]
        );

        return this.httpUtil.doGet(apiUrl).pipe(
            map((response: SeiPayload) => {
                if (response) {
                    return response.data[0].strategies;
                }
            })
        );
    }

    public getByAssetAllocationOptions(): Type[] {
        // NOTE: Temporary mocked data.
        const byAssetAllocationOptionsData: Type[] = [
            { id: 1, description: '0/100' },
            { id: 2, description: '10/90' },
            { id: 3, description: '25/75' },
            { id: 5, description: '40/60' },
            { id: 6, description: '60/40' },
            { id: 7, description: '80/20' },
            { id: 8, description: '100/0' }
        ];

        return byAssetAllocationOptionsData;
    }

    public getByObjectiveOptions(): Type[] {
        // NOTE: Temporary mocked data.
        const byObjectiveOptionsData: Type[] = [
            { id: 1, description: 'Growth' },
            { id: 2, description: 'Stability' }
        ];

        return byObjectiveOptionsData;
    }

    public getSortByDropDownValues(): Type[] {
        const filterStrategySorByOptionsInput: Type[] = [];
        filterStrategySorByOptionsInput.push({ id: 1, description: 'Suggested' });
        filterStrategySorByOptionsInput.push({ id: 2, description: 'Minimize Risk' });
        filterStrategySorByOptionsInput.push({ id: 3, description: 'Maximize Return' });
        filterStrategySorByOptionsInput.push({ id: 4, description: 'Actively Managed' });
        filterStrategySorByOptionsInput.push({ id: 5, description: 'Passively Managed' });

        return filterStrategySorByOptionsInput;
    }


    public setArrayValues(argsToInsert: number[]): number[] {
        const arrayValues: number[] = [];
        argsToInsert.forEach((elementToInsert: number) =>
            arrayValues.push(elementToInsert));
        return arrayValues;
    }

    public getIndexOnArrayValue(filterDropDownData: Type[], riskToleranceLevel: number): number {
        const foundIndexValue =
            filterDropDownData.indexOf(
                filterDropDownData.find(
                    (strategyFilterDropDownData) =>
                        strategyFilterDropDownData.id === riskToleranceLevel)
            );

        return foundIndexValue;
    }

    public convertRiskToleranceScoreIntoLevel(riskToleranceScore: number): number {
        if (
            riskToleranceScore >= RiskLevelValues.LowMin &&
            riskToleranceScore <= RiskLevelValues.LowMax
        ) {
            return RiskToleranceLevel.Low;
        } else if (
            riskToleranceScore >= RiskLevelValues.MediumMin &&
            riskToleranceScore <= RiskLevelValues.MediumMax
        ) {
            return RiskToleranceLevel.Medium;
        } else if (
            riskToleranceScore >= RiskLevelValues.HighMin &&
            riskToleranceScore <= RiskLevelValues.HighMax
        ) {
            return RiskToleranceLevel.High;
        } else {
            return 0;
        }
    }

    public setLabelDropDownExploreStrategy(selectedOption: number): string {
        switch (selectedOption) {
            case OptionExploreStrategy.AssetAllocation:
                return OptionExploreStrategyDropDown.AssetAllocation;
            case OptionExploreStrategy.Objective:
                return OptionExploreStrategyDropDown.Objective;
            case OptionExploreStrategy.AllAvailable:
                return OptionExploreStrategyDropDown.AllAvailable;

            default:
                return '';
        }
    }

    public filterStrategiesByStrategySelected(strategiesToFilter: Strategy[], firstStrategyInAccount: Strategy): Strategy[] {
        if (firstStrategyInAccount) {
            switch (firstStrategyInAccount.investmentProgramId) {
                case InvestmentProgramType.MutualFunds:
                    strategiesToFilter = strategiesToFilter.filter((strategy: Strategy) => {
                        if (strategy.investmentProgramId === firstStrategyInAccount.investmentProgramId) {
                            return strategy;
                        }
                    });
                    break;
                case InvestmentProgramType.ETF:
                case InvestmentProgramType.FixedIncome:
                    strategiesToFilter = strategiesToFilter.filter((strategy: Strategy) => {
                        if (strategy.investmentProgramId === InvestmentProgramType.ETF ||
                            strategy.investmentProgramId === InvestmentProgramType.FixedIncome ||
                            strategy.investmentProgramId === InvestmentProgramType.ManagedAccounts) {
                            return strategy;
                        }
                    });
                    break;
                case InvestmentProgramType.Custom:
                    strategiesToFilter = strategiesToFilter.filter((strategy: Strategy) => {
                        if (strategy.investmentProgramId === InvestmentProgramType.Custom) {
                            return strategy;
                        }
                    });
                    break;
                default: break;
            }
        }
        return strategiesToFilter;
    }

    public getCombinedStrategyRisk(account: Account): Observable<CombinedStrategyRisk> {
        const apiUrl = this.carGlobal.configService.routeFormatter(
            this.carGlobal.configService.getEndPoint('getCombinedStrategyRisk'),
            [
                {
                    key: 'proposalAccountId',
                    value: account.id
                }
            ]
        );
        if (this.global.isMockDataMode()) {
            const mockURL: string = 'assets/mock-data/combined-strategy-risk.json';
            return this.httpUtil.doMockPostStronglyTyped<CombinedStrategyRiskResponse>(mockURL, 1000).pipe(
                shareReplay(1),
                map((response: CombinedStrategyRiskResponse) => {
                    if (response) {
                        return response.combinedStrategyRisk;
                    }
                }
                ));
        }
        return merge(this.httpUtil.doPost(apiUrl, account).pipe(
            shareReplay(1),
            map((response: CombinedStrategyRiskResponse) => {
                if (response) {
                    return response.combinedStrategyRisk;
                }
            }
            )));
    }

    public subscribeToGetFilterStrategyDetail(params: ExploreDetailsParams) {
        if (this.global.isMockDataMode()) {
            const mockURL: string = 'assets/mock-data/investment-options.json';
            return this.httpUtil.doMockPostStronglyTyped<ExploreStrategiesFilter>(mockURL, 1000);
        } else {
            const investmentOptionsURL = `/api/v1/swp/mmtproductlibraryservice/investmentoptions`;
            return this.apigeeHttpUtil.doPost<ExploreStrategiesFilter>(investmentOptionsURL, params);
        }
    }

    public subscribeToGetModelDetail(params: ExploreDetailsParams): Observable<ExploreModel> {
        if (this.global.isMockDataMode()) {
            const mockURL: string = 'assets/mock-data/model-detail.json';
            return this.httpUtil.doMockPostStronglyTyped<ExploreModel>(mockURL, 1000);
        } else {
            const exploreURL = `/api/v1/swp/mmtproductlibraryservice/modeldetail`;
            return this.apigeeHttpUtil.doPost<ExploreModel>(exploreURL, params);
        }

    }

    public investmentSummaryHasChanges(
        investmentSummary: InvestmentSummary, previousInvestmentSummary: InvestmentSummary
    ): boolean {
        const productDetails: ProductDetail[] = this.getProductDetails(investmentSummary);
        const previousProductDetails: ProductDetail[] = this.getProductDetails(previousInvestmentSummary);

        if (productDetails.length !== previousProductDetails.length) {
            return true;
        }

        for (const productDetail of productDetails) {
            const foundProductDetail: ProductDetail =
                previousProductDetails.find((itemProductDetail: ProductDetail) => {
                    return productDetail.modelType === itemProductDetail.modelType &&
                        productDetail.pmeModelId === itemProductDetail.pmeModelId;
                });
            if (!foundProductDetail || productDetail.currentMarketValue !== foundProductDetail.currentMarketValue) {
                return true;
            }
        }
        return false;
    }

    public getProductDetails(investmentSummary: InvestmentSummary): ProductDetail[] {
        const productDetails: ProductDetail[] = [];

        if (
            investmentSummary &&
            investmentSummary.proposedInvestmentSummary &&
            investmentSummary.proposedInvestmentSummary.strategyDetails
        ) {
            productDetails.push(...investmentSummary.proposedInvestmentSummary.strategyDetails);

            const modelDetail: ProductDetail = investmentSummary.proposedInvestmentSummary.modelDetails;
            if (modelDetail) {
                productDetails.push(modelDetail);
            }
        }
        return productDetails;
    }

    public setDefaultAmountToFirstStrategy(investmentSummary: InvestmentSummary, defaultAmount: number): void {
        const requiredNumberOfStrategiesToSetDefault: number = 1;
        if (this.totalNumberOfStrategies(investmentSummary) === requiredNumberOfStrategiesToSetDefault) {
            const modelDetail: ProductDetail = investmentSummary.proposedInvestmentSummary.modelDetails;
            if (modelDetail) {
                investmentSummary.proposedInvestmentSummary.modelDetails.currentMarketValue =
                    modelDetail.currentMarketValue || defaultAmount;
            } else {
                const lastStrategy: ProductDetail = investmentSummary.proposedInvestmentSummary.strategyDetails.pop();
                if (lastStrategy) {
                    lastStrategy.currentMarketValue = lastStrategy.currentMarketValue || defaultAmount;
                    investmentSummary.proposedInvestmentSummary.strategyDetails.push(lastStrategy);
                }
            }
        }
    }

    public itHasTheMinimumOfRequiredStrategies(investmentSummary: InvestmentSummary, minimum: number = 1): boolean {
        return this.totalNumberOfStrategies(investmentSummary) >= minimum;
    }

    private totalNumberOfStrategies(investmentSummary: InvestmentSummary): number {
        let totalStrategiesFound: number = 0;

        if (investmentSummary && investmentSummary.proposedInvestmentSummary) {
            if (
                (investmentSummary.proposedInvestmentSummary.strategyDetails &&
                    investmentSummary.proposedInvestmentSummary.strategyDetails.length)
            ) {
                totalStrategiesFound = investmentSummary.proposedInvestmentSummary.strategyDetails.length;
            }
            if (investmentSummary.proposedInvestmentSummary.modelDetails) {
                totalStrategiesFound++;
            }
        }
        return totalStrategiesFound;
    }

    public requiredSwpStrategyId(strategyTypeId: number): boolean {
        return strategyTypeId === InvestmentTypeId.Manager;
    }

    public isUMAStrategy(strategyTypeId: number): boolean {
        return strategyTypeId === InvestmentTypeId.Custom;
    }

    public getStrategyTypeIdByModelType(modelType: ModelType, isCustom: boolean): InvestmentTypeId {
        switch (modelType) {
            case ModelType.UMAModel:
                if (isCustom) {
                    return InvestmentTypeId.Custom;
                }
                return InvestmentTypeId.Model;
            case ModelType.Strategy:
                return InvestmentTypeId.Model;
            case ModelType.Manager:
                return InvestmentTypeId.Manager;
            default:
                return InvestmentTypeId.Fund;
        }
    }

    public getModelTypeIdByModelType(modelType: ModelType): ModelTypeId {
        return modelType === ModelType.UMAModel
            ? ModelTypeId.UMAModel
            : ModelTypeId.Strategy;
    }

    public getSwpStrategyIdByProductDetail(productDetail: ProductDetail): number {
        if (productDetail) {
            if (productDetail.modelType === ModelType.Manager) {
                return +productDetail.swpStrategyId || +productDetail.pmeModelId;
            }
        }
        return undefined;
    }

    public getProposalAccountStrategyId(product: ProductDetail, strategies: Strategy[], isCustom: boolean): number {
        if (!strategies) {
            return undefined;
        }

        const strategyFoundIndex = strategies.findIndex((strategy: Strategy) => {
            let isValidModelIdSwpId: boolean = strategy.modelId === product.pmeModelId;
            if (this.requiredSwpStrategyId(strategy.strategyTypeId)) {
                isValidModelIdSwpId = strategy.strategyTypeId === +product.swpStrategyId;
            }
            return strategy.strategyTypeId === this.getStrategyTypeIdByModelType(product.modelType, isCustom) &&
                strategy.modelTypeId === this.getModelTypeIdByModelType(product.modelType) &&
                isValidModelIdSwpId;
        });
        const accountStrategy = strategies[strategyFoundIndex];
        // Remove element from index to prevent duplicates when the same strategy is added
        // multiple times in the same account
        strategies.splice(strategyFoundIndex, 1);

        return accountStrategy ? accountStrategy.proposalAccountStrategyId : undefined;
    }

    public convertCardDetailToProductDetail(cardDetail: CardDetail, accountStrategy: Strategy, accountId: number): ProductDetail {
        if (!cardDetail) {
            return undefined;
        }
        const productDetail: ProductDetail = {
            activityType: AssetActivityType.AddStrategy,
            modelType: ModelType.Manager,
            pmeModelId: undefined,
            swpStrategyId: undefined,
            currentMarketValue: undefined,
            proposalScenarioGoalId: undefined,
            name: cardDetail.modelName,
            modelName: cardDetail.modelName,
            investmentRiskType: cardDetail.risk,
            investmentProgram: cardDetail.investmentProgram,
            isModified: true,

            previousAllocationId: undefined,
            previousAllocationName: undefined,
            previousAllocationType: undefined,
            portfolioId: undefined,
            processingOrgId: 0,
            firmId: undefined,
            previousMarketValue: undefined,
            actionFrom: undefined,
            assetAllocationMetrics: {
                riskType: cardDetail.risk as RiskTypeDescription,
                investmentDetails: {
                    investmentCost: cardDetail.investmentCost,
                    minimumInvestment: cardDetail.investmentMinimum
                },
                allocationDetails: {
                    equity: cardDetail.allocationPercentage.equity,
                    fixedIncome: cardDetail.allocationPercentage.fixedIncome,
                    alternative: cardDetail.allocationPercentage.alternative,
                    cash: cardDetail.allocationPercentage.cash,
                    multiAsset: cardDetail.allocationPercentage.multiAsset
                }
            },
            isAddInvestment: true,
            stateFocusedStateSpecificCd: undefined,
            alternativeMinimumTaxEligFl: undefined,
            selectedStateSpecificCode: undefined,
            proposalAccountId: accountId
        };

        if (accountStrategy) {
            productDetail.pmeModelId = accountStrategy.swpStrategyId;
            productDetail.swpStrategyId = accountStrategy.swpStrategyId;
            productDetail.currentMarketValue = accountStrategy.amount;
            productDetail.proposalScenarioGoalId = accountStrategy.proposalScenarioGoalId;
        }
        return productDetail;
    }

    public setAllocationMetrics(productDetail: ProductDetail,
        customStrategy: CustomStrategy,
        modelDetailMap: Map<number, ProductDetail>): void {
        const assetAllocationMetricsRelation: Map<string, number> = new Map();

        this.assetNames.forEach((assetName: string) => {
            const allocationsByAssetName: Allocation[] =
                customStrategy?.allocations.filter((allocation: Allocation) => {
                    return allocation.assetName === assetName;
                });

            if (allocationsByAssetName) {
                const totalPercentage: number = allocationsByAssetName.reduce(
                    (accumulator, currentValue: Allocation) => {
                        return accumulator + currentValue.percentage;
                    }, 0
                );
                assetAllocationMetricsRelation.set(assetName, totalPercentage * 100);
            }
        });

        customStrategy?.allocations.forEach((allocation: Allocation) => {
            if (allocation.assetName === AssetNames.MultiAsset) {
                if (modelDetailMap.has(allocation.modelId)) {
                    const percentageOfUMA: BigNumber = new BigNumber(allocation.percentage);
                    const modelDetail: ProductDetail = modelDetailMap.get(allocation.modelId);
                    const equityPercent: BigNumber =
                        new BigNumber(modelDetail.assetAllocationMetrics.allocationDetails.equity).times(percentageOfUMA);
                    const totalEquityPercent: BigNumber =
                        new BigNumber(assetAllocationMetricsRelation.get(AssetNames.Equity)).plus(equityPercent);
                    const fixedIncomePercent: BigNumber =
                        new BigNumber(modelDetail.assetAllocationMetrics.allocationDetails.fixedIncome).times(percentageOfUMA);
                    const totalFixedIncomePercent: BigNumber =
                        new BigNumber(assetAllocationMetricsRelation.get(AssetNames.FixedIncome)).plus(fixedIncomePercent);
                    const alternativePercent: BigNumber =
                        new BigNumber(modelDetail.assetAllocationMetrics.allocationDetails.alternative).times(percentageOfUMA);
                    const totalAlternativePercent: BigNumber =
                        new BigNumber(assetAllocationMetricsRelation.get(AssetNames.Alternative)).plus(alternativePercent);
                    const cashPercent: BigNumber =
                        new BigNumber(modelDetail.assetAllocationMetrics.allocationDetails.cash).times(percentageOfUMA);
                    const totalCashPercent = new BigNumber(assetAllocationMetricsRelation.get(AssetNames.Cash)).plus(cashPercent);

                    const totalMultiAssetPercent: BigNumber = new BigNumber(assetAllocationMetricsRelation.get(AssetNames.MultiAsset))
                        .minus(equityPercent)
                        .minus(fixedIncomePercent)
                        .minus(alternativePercent)
                        .minus(cashPercent);

                    assetAllocationMetricsRelation.set(AssetNames.Equity, Number(totalEquityPercent.toFixed(2)));
                    assetAllocationMetricsRelation.set(AssetNames.FixedIncome, Number(totalFixedIncomePercent.toFixed(2)));
                    assetAllocationMetricsRelation.set(AssetNames.Alternative, Number(totalAlternativePercent.toFixed(2)));
                    assetAllocationMetricsRelation.set(AssetNames.Cash, Number(totalCashPercent.toFixed(2)));
                    assetAllocationMetricsRelation.set(AssetNames.MultiAsset, Number(totalMultiAssetPercent.toFixed(2)));
                }
            }
        });

        productDetail.assetAllocationMetrics = {
            riskType: productDetail.investmentRiskType as RiskTypeDescription,
            investmentDetails: {
                investmentCost: 0,
                minimumInvestment: 0
            },
            allocationDetails: {
                equity: assetAllocationMetricsRelation.get(AssetNames.Equity),
                intEquity: Math.floor(assetAllocationMetricsRelation.get(AssetNames.Equity)),
                fixedIncome: assetAllocationMetricsRelation.get(AssetNames.FixedIncome),
                intFixedIncome: Math.floor(assetAllocationMetricsRelation.get(AssetNames.FixedIncome)),
                alternative: assetAllocationMetricsRelation.get(AssetNames.Alternative),
                intAlternative: Math.floor(assetAllocationMetricsRelation.get(AssetNames.Alternative)),
                cash: assetAllocationMetricsRelation.get(AssetNames.Cash),
                intCash: Math.floor(assetAllocationMetricsRelation.get(AssetNames.Cash)),
                multiAsset: assetAllocationMetricsRelation.get(AssetNames.MultiAsset),
                intMultiAsset: Math.floor(assetAllocationMetricsRelation.get(AssetNames.MultiAsset))
            }
        };

        const sumOfDoubleAllocPer: number = productDetail.assetAllocationMetrics.allocationDetails.equity +
            productDetail.assetAllocationMetrics.allocationDetails.fixedIncome +
            productDetail.assetAllocationMetrics.allocationDetails.alternative +
            productDetail.assetAllocationMetrics.allocationDetails.cash +
            productDetail.assetAllocationMetrics.allocationDetails.multiAsset;
        const sumOfIntAllocPer: number = productDetail.assetAllocationMetrics.allocationDetails.intEquity +
            productDetail.assetAllocationMetrics.allocationDetails.intFixedIncome +
            productDetail.assetAllocationMetrics.allocationDetails.intAlternative +
            productDetail.assetAllocationMetrics.allocationDetails.intCash +
            productDetail.assetAllocationMetrics.allocationDetails.intMultiAsset;
        const diffOfAllocationPer: number = sumOfDoubleAllocPer - sumOfIntAllocPer;

        const maxValueOfAllocPer = Math.floor(Math.max(...Object.values(productDetail.assetAllocationMetrics.allocationDetails)));
        if (diffOfAllocationPer) {
            if (maxValueOfAllocPer === productDetail.assetAllocationMetrics.allocationDetails.intEquity) {
                productDetail.assetAllocationMetrics.allocationDetails.intEquity += diffOfAllocationPer;
            } else if (maxValueOfAllocPer === productDetail.assetAllocationMetrics.allocationDetails.intFixedIncome) {
                productDetail.assetAllocationMetrics.allocationDetails.intFixedIncome += diffOfAllocationPer;
            } else if (maxValueOfAllocPer === productDetail.assetAllocationMetrics.allocationDetails.intAlternative) {
                productDetail.assetAllocationMetrics.allocationDetails.intAlternative += diffOfAllocationPer;
            } else if (maxValueOfAllocPer === productDetail.assetAllocationMetrics.allocationDetails.intCash) {
                productDetail.assetAllocationMetrics.allocationDetails.intCash += diffOfAllocationPer;
            } else if (maxValueOfAllocPer === productDetail.assetAllocationMetrics.allocationDetails.intMultiAsset) {
                productDetail.assetAllocationMetrics.allocationDetails.intMultiAsset += diffOfAllocationPer;
            }
        }
    }

    private createFundDetailsByAllocation(allocation: Allocation): FundDetail {
        const strategyType: string = this.getAllocationType(allocation.allocationType);
        let levelClass: number;
        if ((allocation.allocationDescription === CashAndEquivalents.Cash ||
            allocation.allocationDescription === CashAndEquivalents.CashEquivalents) &&
            (allocation.assetName === CashAndEquivalents.Cash ||
                allocation.assetName === CashAndEquivalents.CashEquivalents)) {
            levelClass = LevelClass.Cash;
        } else {
            levelClass = this.getLevelClass(strategyType);

        }
        let ticker: string;
        if (allocation.fundTickerId) {
            ticker = allocation.fundTickerId;
        } else if (allocation.modelId > 0) {
            // To tie subtitution edits back to common components for ModelStrategyType.Strategy
            ticker = '' + allocation.modelId;
        }
        const fundDetail: FundDetail = {
            fundName: allocation.allocationDescription,
            allocationTarget: Number(((allocation.percentage || 0) * 100).toFixed(2)),
            instrumentId: allocation.swpStrategyId ? allocation.swpStrategyId.toString() : undefined,
            ticker,
            id: allocation.fundTickerId ? allocation.fundTickerId : '' + allocation.modelId,
            strategyType,
            assetType: this.getFundDetailAssetTypeAll(allocation.allocationType),
            upperTolerance: Number(((allocation.targetPctUpperTolerance || 0) * 100).toFixed(2)),
            lowerTolerance: Number(((allocation.targetPctLowerTolerance || 0) * 100).toFixed(2)),
            isTaxManaged: allocation.taxMgmtEligFl,
            isManager: strategyType === ModelStrategyType.Manager ? true : false,
            nonSei: allocation.nonSei,
            sorKey: allocation.sorKey,
            swpStrategyId: allocation.swpStrategyId,
            baseManagerSWPStrategyId: allocation.baseManagerSWPStrategyId,
            assetClass: [{
                l1Id: allocation?.level1Id,
                l1Name: allocation?.level1Value,
                l2Id: allocation?.level2Id,
                l2Name: allocation?.level2Value,
                l3Id: allocation?.level3Id,
                l3Name: allocation?.level3Value,
                allocationPct: undefined
            }],
            cusip: allocation?.cusip,
            productScope: allocation.productScope,
            productType: allocation.productType,
            levelClass
        };
        return fundDetail;
    }

    public setProductDetailCustomStrategy(productDetail: ProductDetail, productDetailMap: Map<number, ProductDetail>,
        customStrategy: CustomStrategy): ProductDetail {

        if (!productDetail.assetAllocationMetrics || !productDetail.assetAllocationMetrics.allocationDetails) {
            this.setAllocationMetrics(productDetail, customStrategy, productDetailMap);
        }

        if (customStrategy && customStrategy.allocations?.length > 0) {
            productDetail.isThirdPartyManagersIncluded = customStrategy.includeThirdPartyManager;
            productDetail.isTaxOverlayService = customStrategy.includeTaxOverlayService;
            if (productDetail.isCustom) {
                productDetail.modelAllocation = [];

                const fundDetailGrouped: Map<string, FundDetail[]> = new Map<string, FundDetail[]>();
                customStrategy.allocations.forEach((allocation: Allocation) => {
                    let fundDetail: FundDetail[] = [];
                    if (_.isEmpty(allocation.assetName)) {
                        if (allocation.level1Value !== CashAndEquivalents.CashEquivalents &&
                            allocation.level1Value !== CashAndEquivalents.Cash) {
                            allocation.assetName = allocation.level1Value;
                        } else {
                            allocation.assetName = AssetNames.Cash;
                        }
                    }

                    if (_.isEmpty(allocation.levelClass) && allocation.assetName === AssetNames.Cash) {
                        allocation.levelClass = AllocationLevelClass.Cash;
                    }

                    if (fundDetailGrouped.has(allocation.assetName)) {
                        fundDetail = fundDetailGrouped.get(allocation.assetName);
                    } else {
                        fundDetail = [];
                        fundDetailGrouped.set(allocation.assetName, fundDetail);
                    }
                    const populatedFundDetail: FundDetail = this.createFundDetailsByAllocation(allocation);

                    // If there are models present in the UMA
                    if (productDetailMap.size > 0 && productDetailMap.has(allocation.modelId)) {

                        const modelDetail: ProductDetail = productDetailMap.get(allocation.modelId);
                        if (modelDetail.substitutionsData && modelDetail.substitutionsData.length > 0) {
                            const substitutionsData: SubstitutionsDetails[] = _.cloneDeep(modelDetail.substitutionsData);
                            let selectedSubstitutions: SubstitutionsCategory[];

                            if (allocation.selectedSubstitutions?.length > 0) {
                                selectedSubstitutions = _.cloneDeep(allocation.selectedSubstitutions);
                            }

                            this.setSubstitutionDropdownItems(substitutionsData, selectedSubstitutions);
                            populatedFundDetail.selectedSubstitutions = selectedSubstitutions;
                            populatedFundDetail.substitutionsData = substitutionsData;
                        }
                    }
                    fundDetail.push(populatedFundDetail);
                });
                fundDetailGrouped.forEach((value: FundDetail[], key: string) => {
                    let modelAllocation: ModelAllocation;

                    if (this.isCashOrCashAndEquivalents(key)) {
                        // asset level - cash - can have either money market funds or fdic insured cash.
                        const cashItems: FundDetail[] = [];
                        const moneyMarketItems: FundDetail[] = [];

                        value.forEach((item: FundDetail) => {
                            if (this.isCashOrCashAndEquivalents(item.fundName)) {
                                cashItems.push(item);
                            } else {
                                item.levelName = CashAndEquivalents.MoneyMarket;
                                moneyMarketItems.push(item);
                            }
                        });

                        const cashFundList: FundList = new FundListFactory().createFundList(cashItems[0]?.fundName, cashItems);
                        const moneyMarketFundList: FundList =
                            new FundListFactory().createFundList(moneyMarketItems[0]?.levelName, moneyMarketItems);

                        let fundList: FundList[];
                        if (moneyMarketFundList && moneyMarketFundList.fundDetail.length > 0) {
                            fundList = [moneyMarketFundList, cashFundList];
                        } else {
                            fundList = [cashFundList];
                        }
                        modelAllocation = new ModelAllocationFactory().createModelAllocation(key, fundList);

                    } else {
                        const fundList: FundList = new FundListFactory().createFundList(key, value);
                        modelAllocation = new ModelAllocationFactory().createModelAllocation(key, [fundList]);
                    }
                    productDetail.modelAllocation.push(modelAllocation);
                });
            }
        }
        productDetail.activityType = AssetActivityType.AddStrategy;
        return productDetail;
    }

    private setSubstitutionDropdownItems(substitutionsData: SubstitutionsDetails[], selectedSubstitutions: SubstitutionsCategory[]) {
        if (!_.isEmpty(substitutionsData) && !_.isEmpty(selectedSubstitutions)) {

            selectedSubstitutions.forEach((substitute: SubstitutionsCategory): void => {
                const index: number = substitutionsData.findIndex((details: SubstitutionsDetails): boolean =>
                    details.category === substitute.name);
                const detail: SubstitutionsDetails = substitutionsData[index];
                detail.selectedValue = substitute.selectedValue;
                detail.selectedFundName = substitute.displayName;
                substitutionsData[index] = detail;
            });
        }
    }

    public getAllocationType(allocationType: number): string {
        switch (allocationType) {
            case InvestmentTypeId.Manager:
                return ModelStrategyType.Manager;
            case InvestmentTypeId.Model:
                return 'SEI';
            case InvestmentTypeId.Firm:
                return ModelStrategyType.Firm;
            case InvestmentTypeId.NonSEI:
                return ModelStrategyType.NonSEI;
            default:
                return ModelStrategyType.Fund;
        }
    }

    private getFundDetailAssetTypeAll(allocationType: number): FundDetailAssetType {
        switch (allocationType) {
            case InvestmentTypeId.Fund:
                return FundDetailAssetType.Fund;
            case InvestmentTypeId.Manager:
                return FundDetailAssetType.Manager;
            case InvestmentTypeId.Model:
                return FundDetailAssetType.SEI;
            case InvestmentTypeId.Firm:
                return FundDetailAssetType.Firm;
            // FundDetailAssetType does not cover Custom / Non SEI
            default:
                return undefined;
        }
    }

    public getProductDetailByStrategy(strategy: Strategy, account: Account): ProductDetail {
        if (!strategy || !account) {
            return undefined;
        }
        const productDetail: ProductDetail =
            new ProductDetailFactory().createProductDetail(
                strategy.name,
                strategy.risk,
                strategy.amount,
                strategy.firmId,
                [],
                undefined,
                true
            );
        productDetail.productScope = strategy.modelId ? ProductScope.SEI : ProductScope.ClientProductScope;
        return productDetail;
    }

    public setProductDetailValues(productDetail: ProductDetail, accountStrategy: Strategy, account: Account): void {
        if (accountStrategy) {
            if (accountStrategy.selectedSubstitutions?.length > 0) {
                const selectedSubstitutions: SubstitutionsCategory[] = _.cloneDeep(accountStrategy.selectedSubstitutions);
                const substitutionsData: SubstitutionsDetails[] = _.cloneDeep(productDetail.substitutionsData);

                this.setSubstitutionDropdownItems(substitutionsData, selectedSubstitutions);
                productDetail.selectedSubstitutions = selectedSubstitutions;
                productDetail.substitutionsData = substitutionsData;

            }
            // TODO Will uncomment when fixing NonModel assets in Custom UMAs
            // if (productDetail.containsNonStrategyAllocation) {
            //     this.setNonStrategyAllocation(productDetail, accountStrategy, account);
            // }
            productDetail.currentMarketValue = accountStrategy.amount;
            productDetail.proposalScenarioGoalId = accountStrategy.proposalScenarioGoalId;
        }
        productDetail.proposalAccountId = account.id;
        productDetail.activityType = AssetActivityType.AddStrategy;
    }

    public getCustomStrategy(productDetail: ProductDetail): CustomStrategy {
        const allocations: Allocation[] = [];

        if (!productDetail) {
            return undefined;
        }
        if (productDetail.modelAllocation) {
            productDetail.modelAllocation.forEach((modelAllocation: ModelAllocation) => {
                if (Array.isArray(modelAllocation.fundList)) {
                    modelAllocation.fundList.forEach((fundList: FundList) => {
                        if (Array.isArray(fundList.fundDetail)) {
                            fundList.fundDetail.forEach((fundDetail: FundDetail) => {
                                const allocation: Allocation = this.generateAllocationByStrategyType(fundDetail, modelAllocation.assetName);
                                if (fundDetail.strategyType === 'Manager' && fundDetail.swpStrategyId) {
                                    allocation.swpStrategyId = fundDetail.swpStrategyId;
                                }
                                if (fundDetail.selectedSubstitutions?.length > 0) {
                                    allocation.selectedSubstitutions = [];
                                    fundDetail.selectedSubstitutions.forEach((substitution) => {
                                        substitution.pmeModelId = Number(fundDetail.id);
                                        allocation.selectedSubstitutions.push(substitution);
                                    });
                                }
                                if (allocation) {
                                    allocations.push(allocation);
                                }
                            });
                        }
                    });
                }
            });
        }

        const customStrategy: CustomStrategy = {
            allocations,
            name: productDetail.name,
            includeTaxOverlayService: productDetail.isTaxOverlayService,
            includeThirdPartyManager: productDetail.isThirdPartyManagersIncluded
        };

        return customStrategy;
    }

    public generateAllocationByStrategyType(fundDetail: FundDetail, assetName: string): Allocation {

        if (!fundDetail) {
            return undefined;
        }

        let allocationType: number = InvestmentTypeId.Fund;
        let swpStrategyId: number = +fundDetail.instrumentId;
        let fundTickerId: string = fundDetail.ticker;
        let modelId: number = 0;
        const percentage: number = fundDetail.allocationTarget / 100;
        let modelStrategyType: string = fundDetail.strategyType || fundDetail.assetType;
        const nonSei: boolean = fundDetail.nonSei ||
            (fundDetail.isSourcedFromAds && !fundDetail.seiFund) ||
            fundDetail.isNonSeiAssetAvailable;
        const targetPctUpperTolerance: number = fundDetail.upperTolerance / 100;
        const targetPctLowerTolerance: number = fundDetail.lowerTolerance / 100;
        const taxMgmtEligFl: boolean = fundDetail.isTaxManaged;
        const sorKey: number = fundDetail.sorKey;
        const cusip: string = fundDetail.cusip;
        const baseManagerSWPStrategyId: number = fundDetail.baseManagerSWPStrategyId;
        const productScope: string = fundDetail.productScope;
        const productType: string = fundDetail.productType;
        let level1Id: number;
        let level1Value: string;
        let level2Id: string;
        let level2Value: string;
        let level3Id: string;
        let level3Value: string;
        if (fundDetail.assetClass) {
            level1Id = fundDetail?.assetClass[0]?.l1Id;
            level1Value = fundDetail?.assetClass[0]?.l1Name;
            level2Id = fundDetail?.assetClass[0]?.l2Id;
            level2Value = fundDetail?.assetClass[0]?.l2Name;
            level3Id = fundDetail?.assetClass[0]?.l3Id;
            level3Value = fundDetail?.assetClass[0]?.l3Name;
        }

        if (modelStrategyType === null || modelStrategyType === undefined) {
            if (!nonSei) {
                modelStrategyType = ModelStrategyType.Fund;
            } else {
                modelStrategyType = ModelStrategyType.NonSEI;
            }
        }

        switch (modelStrategyType) {
            case ModelStrategyType.Fund:
                allocationType = InvestmentTypeId.Fund;
                swpStrategyId = undefined;
                break;
            case ModelStrategyType.Manager:
                allocationType = InvestmentTypeId.Manager;
                break;
            case ModelStrategyType.Custom:
                allocationType = InvestmentTypeId.Custom;
                break;
            case ModelStrategyType.SEI:
                allocationType = InvestmentTypeId.Model;
                fundTickerId = '';
                modelId = +fundDetail.id;
                break;
            case ModelStrategyType.Firm:
                allocationType = InvestmentTypeId.Firm;
                fundTickerId = '';
                modelId = +fundDetail.id;
                break;
            case ModelStrategyType.NonSEI:
                allocationType = InvestmentTypeId.NonSEI;
                swpStrategyId = undefined;
                break;
            case ModelStrategyType.Strategy:
                allocationType = InvestmentTypeId.Firm;
                fundTickerId = '';
                modelId = +fundDetail.ticker;
                break;
            default:
                break;
        }

        return new AllocationFactory().createAllocation(
            allocationType,
            swpStrategyId,
            fundTickerId,
            modelId,
            percentage,
            fundDetail.fundName,
            assetName,
            nonSei,
            targetPctUpperTolerance,
            targetPctLowerTolerance,
            taxMgmtEligFl,
            sorKey,
            level1Id,
            level1Value,
            level2Id,
            level2Value,
            level3Id,
            level3Value,
            baseManagerSWPStrategyId,
            cusip,
            productScope,
            productType,
            this.getLevelClass(modelStrategyType)
        );
    }

    private setNonStrategyAllocation(productDetail: ProductDetail, accountStrategy: Strategy, account: Account): void {
        const existingAccount: ExistingAccountData = this.existingAccountsService.getExistingAccountById(account.currentAccountId);
        const nonStrategyAllocations: NonStrategyAssetDetails[] = [];
        const getHoldingsModelData: ExistingAccountModelInvestment =
            existingAccount.modelInvestments.find((modelInvestment: ExistingAccountModelInvestment) =>
                Number(modelInvestment.modelId) === Number(accountStrategy.modelId));
        const nonAssetChildNodes: ExistingAccountChildNodes[] =
            getHoldingsModelData.childNodes.filter((child: ExistingAccountChildNodes) => child.allocationId === 0);
        nonAssetChildNodes.forEach((childNode: ExistingAccountChildNodes) => {
            const currentAllocation: number = this.getNonStrategyAllocationTarget(getHoldingsModelData, childNode);
            const nonStrategyAllocation: NonStrategyAssetDetails = {
                portfolioName: childNode.portfolioName,
                portfolioId: this.parseIdentifierForPortfolioId(childNode.nodeId?.identifier),
                portfolioAllocationTarget: currentAllocation,
                portfolioCurrentAllocation: currentAllocation,
                lowerTolerance: currentAllocation - 5 < 0 ? 0 : currentAllocation - 5,
                upperTolerance: currentAllocation + 5 > 100 ? 100 : currentAllocation + 5,
                modelAllocationId: childNode.allocationId,
                delete: false,
                fundDetail: []
            };
            nonStrategyAllocations.push(nonStrategyAllocation);
        });
        productDetail.modelAllocation.push(
            { assetName: 'Non Strategies', fundList: [], nonStrategies: nonStrategyAllocations }
        );


    }

    private parseIdentifierForPortfolioId(identifier: string): number {
        return Number(identifier.split('-')[0]);
    }

    private getNonStrategyAllocationTarget(modelData: ExistingAccountModelInvestment, childNode: ExistingAccountChildNodes): number {
        return (childNode.positionInfo.tradeDatedQuantity / modelData.amount) * 100;
    }

    private getLevelClass(modelStrategyType: string): number {
        let levelClass: number;
        if (modelStrategyType === ModelStrategyType.SEI || modelStrategyType === ModelStrategyType.Firm) {
            levelClass = 18;
        }

        if (modelStrategyType === ModelStrategyType.Manager) {
            levelClass = 20;
        }
        return levelClass;
    }

    private isCashOrCashAndEquivalents(name: string): boolean {
        return name === CashAndEquivalents.Cash ||
                name === CashAndEquivalents.CashEquivalents;
    }
}
