/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, OnInit } from '@angular/core';
import { ItemNavigatorComponent } from '@sei/common-components-lib-ux';

@Component({
    selector: 'sei-car-navigator',
    templateUrl: './navigator.component.html'
})
export class NavigatorComponent extends ItemNavigatorComponent {

    constructor() {
        super();
    }
}
