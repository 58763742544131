/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OptionSelectionComponent } from '../../common/option-selection/option-selection.component';
import { AccountTypeOptionSettings } from '../../model/account-type-options';
import { EntitySuffix } from '../../model/contact';
import { CommandText, ContactTypes, ElasticControlType } from '../../model/enums';
import { Proposal, Client, Advisor } from '../../model/proposal';
import { UserProfile } from '../../model/user-profile';

@Component({
    selector: 'sei-car-proposal-account-option-selection',
    templateUrl: './proposal-account-option-selection.component.html'

})
export class ProposalAccountOptionSelectionComponent extends OptionSelectionComponent implements OnInit {
    @Input()
    public proposal: Proposal;

    @Input()
    public suffix: EntitySuffix[];

    @Input()
    public userProfile: UserProfile;

    @Input()
    public disabledChangeOption: boolean = false;

    @Input()
    public accountAdvisorList: Advisor[];

    @Output()
    public invalidAccountType = new EventEmitter();

    public contactTypes: typeof ContactTypes = ContactTypes;
    public elasticSearchDataFilter: Array<{ value: string; label: string }> = [];
    public elasticControlTypes: typeof ElasticControlType = ElasticControlType;
    public displaySection: boolean = false;
    public disabledAddClient: boolean;
    public submitted: boolean;
    public isNewAccount: boolean = false;
    public showAccountWarning: boolean = false;

    public ngOnInit(): void {
        this.isNewAccount = this.accountData.id === undefined;
        if (this.isNewAccount) {
            if (this.accountData) {
                this.getAccountDataType();
                this.filterAccountContactTypes();
                this.accountData.advisors = this.accountAdvisorList;
            }
        } else {
            this.showAccountOwnerDropdown = false;
        }
    }

    private getAccountDataType(): void {
        if (
            this.accountData &&
            this.accountData.type
        ) {
            this.accountDataType = this.accountData.type;
        }
    }


    private addClientsToElasticSearch(): void {
        this.proposalService.addClientsToElasticSearch(this.proposal.clients);
        this.filterAccountContactTypes();
    }

    private verifyAccountType(accountTypes: AccountTypeOptionSettings): boolean {
        if (
            accountTypes &&
            this.accountData
        ) {
            const accountTypeValid = this.accountData.owners.filter(
                (owner) => {
                    if (
                        owner &&
                        owner.contactType &&
                        owner.contactType.id
                    ) {
                        return accountTypes.accountContactTypes.includes(owner.contactType.id);
                    }
                }
            );
            return (accountTypeValid.length > 0);
        }
    }

    public filterAccountContactTypes(): void {
        this.elasticSearchDataFilter = this.accountTypeOptionsService.getElasticSearchAccountOwnerDataFilterByAccountType(this.id);
    }

    public onAddedClient(client: Client): void {
        if (client && this.proposal && Array.isArray(this.proposal.clients)) {
            this.proposal.clients.push(client);
            const clientName: string = client.isIndividual ? `${client.firstName} ${client.lastName}` : client.organizationName;
            this.onClientsChange(true);
            this.onAccountOwnersDropdownSelected(clientName);
        }
    }

    public onClientsChange(clientsChanged: boolean): void {
        if (clientsChanged && !this.displaySection) {
            this.displaySection = true;
        }
        this.validateProposal();
    }

    public validateProposal(): void {
        let clientValid = false;

        if (this.proposal.clients.length > 0) {
            clientValid = this.proposal.clients.every((client): boolean => {
                return (client.isIndividual && client.firstName.length > 1 && client.lastName.length > 1) ||
                    (!client.isIndividual && client.organizationName.length > 1);
            });
        }

        if (clientValid) {
            this.disabledAddClient = false;
            this.submitted = true;
        } else {
            this.disabledAddClient = true;
            this.submitted = false;
        }

        if (clientValid && this.proposal.name.length > 1) {
            this.delegationService.publish(CommandText.EnableScenario);
        } else {
            this.delegationService.publish(CommandText.DisableScenario);
        }
        this.addClientsToElasticSearch();
    }

    public onChange(): void {
        this.checked = !this.checked;
        this.selectedData.emit(this.accountDataType);
        if (this.checked) {
            this.getAccountDataType();
            if (
                this.accountDataType &&
                !this.isNewAccount
            ) {
                const accountTypes: AccountTypeOptionSettings = this.accountTypeOptionsService.getOptions(this.accountDataType.id);
                if (!this.verifyAccountType(accountTypes)) {
                    this.checked = false;
                    this.showAccountWarning = true;
                    this.invalidAccountType.emit(this.accountDataType);
                } else {
                    this.showAccountWarning = false;
                }
            } else {
                if (this.accountData) {
                    this.accountData.owners = [];
                    this.accountData.advisors = [];
                    this.accountData.balance = 0;
                }
                this.delegationService.publish(CommandText.UpdateBalance);
            }
        } else {
            this.disableSaveButton();
        }
    }

    private disableSaveButton(): void {
        this.delegationService.publish(CommandText.DisableScenario);
        this.delegationService.publish(CommandText.DisableCreateProposal);
        this.delegationService.publish(CommandText.DisableSaveAndExitButton);
    }
}
