/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { AccountGoal } from '@CarInterfaces/account-goal';
import { BaseFactory } from '@CarInterfaces/base-factory';
import { GoalType } from '@CarInterfaces/goal-type';

@Injectable({
    providedIn: 'root'
})
export class AccountGoalFactory implements BaseFactory<AccountGoal> {
    public createObject(type: GoalType): AccountGoal {
        return {
            goalId: 0,
            goalType: type
        };
    }
}
