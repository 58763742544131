/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToolTipLabels } from '../../model/enums';
import { ToolTipOptions } from '../../model/tooltip-options';

const RenameProposal = 'Rename';
const DeleteProposal = 'Delete Proposal';
@Component({
    selector: 'sei-car-action-tooltip-popover',
    templateUrl: './action-tooltip-popover.component.html'
})
export class ActionToolTipPopoverComponent {
    public showOptionsPopover: boolean = false;

    @Input()
    public toolTipOptions: ToolTipOptions[];

    @Input()
    public noPadding: boolean = false;

    @Input()
    public isChecklistLocked: boolean = false;

    @Output()
    public onOptionSelected = new EventEmitter();

    @Output()
    public onRemoveConfirm = new EventEmitter();

    public onOptionsToggleClick(): void {
        this.showOptionsPopover = !this.showOptionsPopover;
    }

    public onOptionClick(label: string): void {
        this.onOptionSelected.emit(label);
        this.onOptionsToggleClick();
    }

    public onRemoveClick(): void {
        this.onRemoveConfirm.emit(ToolTipLabels.RemoveProposal);
    }

    public filterOptionsIfReadOnlyProposal(): ToolTipOptions[] {

        if (this.isChecklistLocked) {
            this.toolTipOptions.forEach((toolTip) => {
                if (toolTip.label === RenameProposal || toolTip.label === DeleteProposal) {
                    toolTip.disabled = true;
                }
            });
        }

        return this.toolTipOptions;
    }
}
