/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, Input } from '@angular/core';
import { WipAccountStatus } from '../../model/enums';
import { MdcSnapshot } from '../../model/proposal';

@Component({
    selector: 'car-wip-checklist-status-indicator',
    templateUrl: './wip-checklist-status-indicator.component.html',
    styleUrls: ['./wip-checklist-status-indicator.component.scss']
})
export class WipChecklistStatusIndicatorComponent {
    public accountStatus: typeof WipAccountStatus = WipAccountStatus;

    @Input()
    public mdcSnapShot: MdcSnapshot;

    @Input()
    public indentContent: boolean = false;
}
