/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, share } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { StrategyPayLoad } from '../model/proposal';
import { SeiPayload } from '../model/sei-payload';
import { StrategyAllocation } from '../model/allocation';
import { GlobalService } from './system/global.service';
import { GenericErrorService } from './system/generic-error.service';

@Injectable({
    providedIn: 'root'
})
export class ProposalAccountStrategyService {

    constructor(
        private http: HttpClient,
        private genericErrorService: GenericErrorService,
        protected carGlobal: GlobalService) { }


    public getAdvisorStrategiesById(advisorId: number) {
        const apiUrl = this.carGlobal.configService.routeFormatter(
            this.carGlobal.configService.getEndPoint('previousUsedStrategies'),
            [
                {
                    key: 'advisorId',
                    value: advisorId
                }
            ]
        );

        return this.http.get<SeiPayload>(apiUrl)
            .pipe(
                share(),
                map((response) => {
                    if (response &&
                        response.data) {
                        return response.data[0].strategies;
                    }
                })
            );
    }

    public getAllocationByStrategyId(strategyId: number): Observable<StrategyAllocation> {
        const apiUrl = this.carGlobal.configService.routeFormatter(
            this.carGlobal.configService.getEndPoint('getProposalAccountStrategy'),
            [
                {
                    key: 'strategyId',
                    value: strategyId
                }
            ]
        );

        return this.http.get<SeiPayload>(apiUrl)
            .pipe(
                share(),
                map((response) => {
                    if (response &&
                        response.data) {
                        return response.data[0];
                    }
                })
            );
    }

    public createProposalAccountStrategy(strategy: StrategyPayLoad) {

        const apiUrl = this.carGlobal.configService.routeFormatter(
            this.carGlobal.configService.getEndPoint('addProposalAccountStrategy'),
            [
                {
                    key: 'proposalAccountId',
                    value: strategy.proposalAccountId
                },
                {
                    key: 'strategyId',
                    value: strategy.investmentStrategyCategoryId
                }
            ]
        );

        return this.http.post(apiUrl, strategy).pipe(
            map((response: SeiPayload) => {
                if (response &&
                    response.data) {
                    return response.data;
                }

            })
        );
    }

    public deleteProposalAccountStrategy(strategy: StrategyPayLoad) {

        const apiUrl = this.carGlobal.configService.routeFormatter(
            this.carGlobal.configService.getEndPoint(
                'deleteProposalAccountStrategy'
            ),
            [
                {
                    key: 'proposalAccountId',
                    value: strategy.proposalAccountId
                },
                {
                    key: 'strategyId',
                    value: strategy.proposalAccountStrategyId
                }
            ]
        );
        return this.http.delete(apiUrl);
    }
}
