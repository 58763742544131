/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { ChecklistSectionIds } from './proposal-presentation-checklist.enums';
import { ChecklistDocument } from './proposal-presentation-checklist.interfaces';

export class ChecklistDocuments {
    public static readonly PROPOSAL_DOCUMENT: ChecklistDocument = {
        name: 'Proposal',
        sections: [
            {
                name: 'Cover Page',
                shortcutName: 'Cover',
                fontAwesomeIconSelector: 'fa-book-open',
                id: ChecklistSectionIds.ProposalCoverPage,
                sortOrder: 1,
                active: true
            },
            {
                name: 'Advisor Content',
                shortcutName: 'Advisor',
                fontAwesomeIconSelector: 'fa-user',
                id: ChecklistSectionIds.AdvisorContent,
                sortOrder: 2
            },
            {
                name: 'Marketing Content',
                shortcutName: 'Marketing',
                fontAwesomeIconSelector: 'fa-lightbulb',
                id: ChecklistSectionIds.MarketingContent,
                sortOrder: 3
            },
            {
                name: 'Proposed Scenario',
                shortcutName: 'Scenario',
                fontAwesomeIconSelector: 'fa-chart-line',
                id: ChecklistSectionIds.ProposedScenario,
                sortOrder: 4
            },
            {
                name: 'SEI Strategy Details',
                shortcutName: 'Strategy',
                fontAwesomeIconSelector: 'fa-chess',
                id: ChecklistSectionIds.SEIStrategyDetails,
                sortOrder: 5
            },
            {
                name: 'Cost Overview',
                shortcutName: 'Cost',
                fontAwesomeIconSelector: 'fa-sack-dollar',
                id: ChecklistSectionIds.CostOverview,
                sortOrder: 6
            }
        ]
    };
    public static readonly IPS_DOCUMENT: ChecklistDocument = {
        name: 'IPS Report',
        sections: [
            {
                name: 'Cover Page',
                shortcutName: 'Cover',
                fontAwesomeIconSelector: 'fa-book-open',
                id: ChecklistSectionIds.IPSCoverPage,
                sortOrder: 1
            },
            {
                name: 'Reporting Levels',
                shortcutName: 'Levels',
                fontAwesomeIconSelector: 'fa-tasks-alt',
                id: ChecklistSectionIds.ReportingLevels,
                sortOrder: 2
            },
            {
                name: 'Report Options',
                shortcutName: 'Options',
                fontAwesomeIconSelector: 'fa-list',
                id: ChecklistSectionIds.ReportOptions,
                sortOrder: 3
            },
            {
                name: 'Proposal Analytics',
                shortcutName: 'Analytics',
                fontAwesomeIconSelector: 'fa-chart-bar',
                id: ChecklistSectionIds.Analytics,
                sortOrder: 4
            }
        ]
    };
}
