<div *ngIf="strategy" id="strategy-{{strategy.id}}" class="strategy-ribbon" [class.drawer-open]="showStrategyDrawer"
    [class.strategy-bb]="showStrategyDrawer"
    [class.drawer-closed]="!showStrategyDrawer && !(ribbonType === ribbonTypes.StrategySummary)"
    (mouseover)="toggleAddStrategy($event)" (mouseout)="toggleAddStrategy($event)">

    <div class="form-item size-70 strategy-content">
        <div class="strategy-title">{{ strategy.name }}</div>
        <div class="strategy-description">{{ strategy.seiAdvShortDescription }}</div>
    </div>

    <div class="form-item size-30 text-right" *ngIf="ribbonType === ribbonTypes.PreviouslyUsed">
        <span class="pill used">Used {{ strategy.previousUsedCounts }}X</span>
    </div>

    <div class="form-item size-30 strategy-content pull-right" *ngIf="ribbonType === ribbonTypes.StrategySummary">
        <input type="text" placeholder="$" class="form-control" id="strategyBalance-{{strategy.id}}"
            [class.alert]="strategyBalanceError" (blur)="onStrategyBalanceBlur()" seiEditMonetaryAmount
            [amountOptions]="amountOptions" [actualCurrency]="this.currencyTypes.USD" [value]="strategy.balance"
            (output)="onStrategyBalanceChange($event)">
        <car-alert-popover [hidden]="!strategyBalanceError" [alertType]="alertPopoverTypes.Error"
            [alertMessage]="'Strategy Balance is required and cannot be blank'"></car-alert-popover>
    </div>

    <div class="strategy-hover animated faster fadeInUp" [class.show]="!showAddStrategy" [hidden]="showAddStrategy"
        *ngIf="ribbonType === ribbonTypes.PreviouslyUsed || ribbonType === ribbonTypes.ExploreStrategy">
        <button type="button" class="btn sml primary" (click)="addStrategyClick(strategy)">
            Add Strategy
            <i class="fas fa-plus-circle car-fa-blue"></i>
        </button>
        <button type="button" class="btn btn-right caret-flip" (click)="toggleChevron(strategy.id)">
            <a class="strategy-menu-icon">
                <i [className]="showSectionDetails ? 'fas fa-angle-down' : 'fas fa-angle-right'"></i>
            </a>
        </button>
        <div class="clearfix"></div>
    </div>

    <div class="strategy-content">
        <div *ngIf="strategy.risk" class="pill" [class.low]="strategy.risk === risk.Low"
            [class.medium]="strategy.risk === risk.Medium" [class.high]="strategy.risk === risk.High">
            {{ strategy.risk }}</div>
        <div class="pill primary">{{ strategy.minimum | currencySuffix }} min</div>
        <div class="pill primary" *ngIf="strategy.percentageInvestmentCost">{{ strategy.percentageInvestmentCost }}%
            Inv Cost</div>
    </div>

    <sei-car-strategy-drawer [allocationStrategyStream]="allocationStrategyStream" [strategy]="strategy"
        *ngIf="showStrategyDrawer"></sei-car-strategy-drawer>

    <div class="strategy-ribbon-footer" *ngIf="ribbonType === ribbonTypes.StrategySummary">
        <button *ngIf="showChangeButton" type="button" class="btn btn-default" [disabled]="strategyBalanceValidate()"
            (click)="changeStrategyClick(strategy.id)">
            <span class="fas fa-arrow-circle-left"></span> Change
        </button>&nbsp;
        <button type="button" class="btn btn-default" containerClass="wip-delete-account-popover" placement="bottom"
            container="body" [popover]="account_overview_tooltip_template" [outsideClick]="true" #pop="bs-popover">
            <i class="fas fa-trash-alt trash-button-icon"></i>Remove
        </button>

        <ng-template #account_overview_tooltip_template>
            <div class="delete-account-panel">
                <div class="row">
                    <div class="col-xs-12">
                        <h3>Remove This Strategy</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12 car-main-sm-body-copy form-group delete-text">
                        Are you sure you want to remove this item from your strategy?
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12 car-main-sm-body-copy form-group">
                        <button type="button" class="btn btn-default btn-in-tooltip" (click)="pop.hide()">
                            <span class="fas fa-times-circle"></span>
                            Cancel
                        </button>
                        <button type="button" class="btn btn-default btn-in-tooltip"
                            (click)="onConfirmRemoveStrategyClick(); pop.hide();">
                            Remove
                            <span class="far fa-trash-alt trash-button-icon"></span>
                        </button>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</div>
