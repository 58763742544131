<div class="car-impersonation">
    <div class="row">
        <div class="col-xs-12">
            <p>A valid siteminder Id is required to continue:</p>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-6">
            <form name="form-inline"
                  (ngSubmit)="onSubmit(f)"
                  #f="ngForm">
                <div class="form-group">
                    <label for="siteminderId">Siteminder Id</label>
                    <input type="text"
                           class="form-control"
                           id="siteminderId"
                           name="siteminderId"
                           placeholder="Enter Siteminder Id"
                           [(ngModel)]="siteminderId"
                           required>
                    <div class="text-danger"
                         [hidden]="f.valid && authenticated">
                        {{invalidMessage}}
                    </div>
                </div>
                <button type="submit"
                        class="btn btn-primary"
                        [disabled]="!f.valid">{{buttonText}}</button>
            </form>
        </div>
    </div>
    <div class="row"
         *ngIf="hasError">
        <div class="col-xs-12">
            <p></p>
            <pre>{{errorDetail |json}}</pre>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-12">
            <p>Reload with Dev Parameters Mode
                <button type="button"
                        (click)="onEnableDevMode($event)">Enable</button>
            </p>
        </div>
    </div>
</div>
