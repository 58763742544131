/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { AdvisorSelected, CurrentAdvisor } from '../advisor-selected';
import { Advisor } from '../proposal';
import { Firm, User } from '../user-profile';
import { BaseFactoryAbstract } from './base-factory-abstract';

@Injectable()
export class AdvisorFactory extends BaseFactoryAbstract<User> {

    constructor() {
        super();
    }

    public createFirmAdvisor(firmId?: number, firmName?: string): Firm {
        return {
            firmId: firmId || undefined,
            firmName: firmName || '',
            advisors: [],
            locations: []
        };
    }

    public createAdvisor(advisorId?: number, advisorEntityId?: number, name?: string): Advisor {
        return {
            id: advisorId,
            userId: advisorId,
            email: '',
            entityId: advisorEntityId,
            name: name || '',
            advisorName: name,
            role: {
                id: 1,
                description: ''
            }
        };
    }

    public createCurrentAdvisor(advisor: Advisor, disabled?: boolean): CurrentAdvisor {
        return {
            advisorSelected: advisor,
            disabled: disabled || false
        };
    }

    public createAdvisorSelected(): AdvisorSelected {
        return {
            currentSelected: undefined,
            selectedAdvisorIds: [],
            hasAdvisorsAvailable: false
        };
    }
}


