/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { NonModelOverrideSellsOnly } from '@CarModels/constants';
import { InvestmentProgramType, YesNoLabels } from '@CarModels/enums';
import { AccountTypesService } from '@CarServices/account/account-types.service';
import { ExistingAccountsService } from '@CarServices/existing-accounts/existing-accounts.service';
import { FeatureFlagService } from '@CarServices/feature-flag/feature-flag.service';
import { ProcessingRulesService } from '@CarServices/processing-rules/processing-rules.service';
import { PropertyService } from '@CarServices/system/property.service';
import { SpinnerService } from '@CarServices/system/spinner.service';
import { Location } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, createFeatureSelector, createSelector } from '@ngrx/store';
import {
    Account,
    AccountDetailTabs,
    ActionTags,
    Advisor,
    CRMRuleActions,
    CRMRuleResponse,
    CRMRuleStateSlice,
    CardDetailResponse,
    Client,
    CombinedStrategyRisk,
    CommandText,
    CrmRules,
    CustomStrategy,
    DelegationService,
    ElasticSearchService,
    ErrorMessages,
    FirmAdvisorService,
    GenericErrorService,
    GlobalService,
    GroupCHNWStrategy,
    HudFeeSchedule,
    HudSummaryFactory,
    InvestmentSelectionOptions,
    NavigationTab,
    NonModelPortfolioTypeRequest,
    NonModelPortfolioTypeResponse,
    OwnerRoleDescription,
    Proposal,
    ProposalAccountService,
    ProposalService,
    RiskLevelValues,
    RiskMethods,
    RiskToleranceLevel,
    RochdaleGroupService,
    Scenario,
    SeiPayload,
    ServerErrorStatus,
    StrategiesService,
    Strategy,
    StrategyMenu,
    StrategyNavTabs,
    SweepModelElection,
    TaxStatus,
    UserProfile,
    UserProfileService,
    WipCheckList,
    WipChecklistLabels,
    WipTagNames
} from '@sei/advisor-client-review-proposal-ux';
import { BannerMessageType } from '@sei/common-components-lib-ux';
import { BannerMessageModel, ParentComponentSubscriptionManager, StoreAction } from '@sei/common-swp-components-lib-ux';
import {
    AccountCharacteristicsInfo,
    AccountDetails,
    ApiConfiguration,
    AssetActivityType,
    AssetAllocationComponent,
    AssetDetailParams,
    BusinessRuleFlags,
    BusinessRuleFlagsService,
    CalculateInvestmentCostResponse,
    CardDetail,
    ComponentVisibility,
    CrmPayload,
    CrmRulesService,
    CurrentInvestmentSummary,
    ExploreDetailsParams,
    ExploreModel,
    FirmInfoSlice,
    IASCommonAppStore,
    InvestmentActions,
    InvestmentAssignmentService,
    InvestmentCostParams,
    InvestmentMinimumParam,
    InvestmentMinimumResponse,
    InvestmentOptionService,
    InvestmentSummary,
    LoaderService,
    ModelType,
    NonModelData,
    NonModelPortfolioTypes,
    NonModelSellsOnlyOverride,
    Portfolio,
    PortfolioData,
    PortfolioHolding,
    PortfolioPurpose,
    PortfolioRiskParams,
    PortfolioRiskResponse,
    ProductDetail,
    ProductScope,
    RiskTypeDescription,
    RiskWidgetTypes,
    SubstitutionsCategory,
    Total
} from '@sei/ias-applications-lib-ux';
import BigNumber from 'bignumber.js';
import * as _ from 'lodash';
import { Observable, Subscription, concat, forkJoin } from 'rxjs';
import { finalize, take, tap } from 'rxjs/operators';
import { InvestmentSummaryFactory } from '../../../../../advisor-client-review-proposal-ux/src/lib/model/factory/investment-summary-factory';
import { ProductFactory } from '../../../../../advisor-client-review-proposal-ux/src/lib/model/factory/product-factory';
import { IasDispatchService } from '../../../../../advisor-client-review-proposal-ux/src/lib/service/ias-dispatch.service';

export interface InvesmtentSummaryState {
    proposedInvestmentSummary: CurrentInvestmentSummary;
}

const getProductFeatureState = createFeatureSelector<InvesmtentSummaryState>('accountAssignmentDataSlice');

export const getInvestSummary = createSelector(
    getProductFeatureState,
    (state) => state
);

@Component({
    selector: 'car-account-details',
    templateUrl: './account-details.component.html',
    styleUrls: ['./account-details.component.scss']
})
export class AccountDetailsComponent extends ParentComponentSubscriptionManager implements OnInit, AfterViewInit, OnDestroy {
    public proposal: Proposal;
    public proposalStream: Observable<Proposal>;
    public account: Account;
    public accountId: number;
    public riskPreference: number;
    public lastTabWorkedOn: number = AccountDetailTabs.RiskCapacity;
    public accountDetailTabs: typeof AccountDetailTabs = AccountDetailTabs;
    public enviromentApplicationUrls;
    public showErrorExclamation: boolean;
    public navigationTabs: NavigationTab[] = [];
    public riskToleranceQuestionnaireMessage: string;
    public primaryAdvisorLoaded: boolean = false;
    public accountDetails: AccountDetails;
    public bookMarkName: string = StrategyMenu.StrategySummary;
    public userProfile: UserProfile;
    public componentVisibility: ComponentVisibility;
    public apiConfiguration: ApiConfiguration;
    public investmentSummary: InvestmentSummary;
    public previousInvestmentSummary: InvestmentSummary;
    public hudFeeSchedule: HudFeeSchedule = new HudSummaryFactory().createHudFee();
    public showRiskWidget: boolean = false;
    public selectedRiskWidgetType: RiskWidgetTypes = RiskWidgetTypes.StrategySummary;
    public riskToleranceScore: number;
    public investmentRiskScore: number;
    public showInvestmentLegend = true;
    public showRiskToleranceLegend = true;
    public highlightRiskLevelActive = true;
    public showHUD: boolean = false;
    public strategyMenu: typeof StrategyMenu = StrategyMenu;
    public isLoading: boolean = true;
    public routePath: string;
    public createUmaModelRoute: string;
    public errorMessageCombinedStrategy: string;
    public generalErrorMessage: string;
    public activityTypeCheck = AssetActivityType;
    public accountCharacteristicsInfo: AccountCharacteristicsInfo;
    public crmRules: CrmRules;
    public businessRuleFlags: BusinessRuleFlags;
    public investmentSummaryLoaded: boolean = false;
    public substitutedInvestmentCost: number;
    public substitutedInvestmentMinimum: number;
    public substitutedPortfolioRisk: string;
    public isAssetAllocation: boolean;
    public accountName: string;
    public platformFeeBasisPoint: number = 0;
    public sacDiscount: boolean = false;
    public isShowSearch: boolean = true;
    public primaryDsrId: number;
    public modifyInvestmentBannerConfig: BannerMessageModel[] = [];
    public isManagedNonManagedCombination: boolean;
    public isEnableManagerGateway: boolean = false;
    public isCashNodeAllocationConfigEnabled: boolean = false;
    public selectedInvestmentProgramType: string;
    public investmentSelectionOptions: InvestmentSelectionOptions;
    public addAdditionalInvestment: boolean = true;
    public nonModelInvestments: NonModelData[];
    public nonModelPortfolioType: NonModelPortfolioTypeResponse;
    public isShowNonModelMarket: boolean = false;
    public isShowNonModel: boolean = false;
    public nonModelInvestmentSummary: boolean = false;
    public showZeroAmountOnSummary: boolean = false;
    public showAdditionalInvestmentBtnInModelPortfolio: boolean = false;
    public showAdditionalInvestmentBtnInNonModelPortfolio: boolean = true;
    public createNonModelInvestmentsFromExisting: NonModelData[] = null;
    public enableNonModelPortfolioAndAssetSearch: boolean = false;
    public isNonModelLoading: boolean = false;
    public isSaveInProgress = false;
    public nonModelBuysWarning: boolean = false;
    public nonModelSellsOnlyOverride: NonModelSellsOnlyOverride;
    public isSubstitutionEnabled: boolean = false;
    public enableThirdPartyMoneyMarket: boolean = false;
    private currentAccountIndex: number = -1;
    private accountsBackup: Account[] = [];
    private strategiesBackUp: Strategy[];
    private readonly pathAssetAllocation: string = 'Asset-Allocation/Investment-Summary';
    private noCashInModel = false;
    private navigateToLast = false;
    private accountSavedInProposal = true;
    private failedToSaveAccountDetails = false;
    private callRiskBecauseErrorCleared = true;
    private previousPortfolios: NonModelData[];
    private accountPortfoliosBackup: Portfolio[];
    private taxStatus: TaxStatus = TaxStatus.Taxable;
    private unallocatedCurrentAccountModelFunds: number = 0;
    public isInvestorPlatformFeeEnabled: boolean = false;

    constructor(
        private readonly router: Router,
        private readonly activatedRoute: ActivatedRoute,
        private readonly proposalService: ProposalService,
        private readonly appStore: Store<IASCommonAppStore>,
        private readonly strategiesService: StrategiesService,
        private readonly delegationService: DelegationService,
        private readonly iasDispatchService: IasDispatchService,
        private readonly firmAdvisorService: FirmAdvisorService,
        private readonly genericErrorService: GenericErrorService,
        private readonly elasticSearchService: ElasticSearchService,
        private readonly rochdaleGroupService: RochdaleGroupService,
        private readonly processingRulesService: ProcessingRulesService,
        private readonly proposalAccountService: ProposalAccountService,
        private readonly investmentOptionService: InvestmentOptionService,
        private readonly businessRuleFlagsService: BusinessRuleFlagsService,
        private readonly investmentAssignmentService: InvestmentAssignmentService,
        private readonly propService: PropertyService,
        private readonly crmRulesService: CrmRulesService,
        private readonly loaderService: LoaderService,
        private readonly userProfileService: UserProfileService,
        private readonly spinnerService: SpinnerService,
        private readonly globalService: GlobalService,
        private readonly location: Location,
        private readonly accountTypesService: AccountTypesService,
        private httpClient: HttpClient,
        private featureFlagService: FeatureFlagService,
        private existingAccountsService: ExistingAccountsService
    ) {
        super('AccountDetailsComponent');
        this.isCashNodeAllocationConfigEnabled = !!this.globalService.configService.environment.cashNodeAllocationConfigFeature;
        this.proposalStream = this.proposalService.currentProposal;
        // Note: get current proposal
        const subscription = this.proposalStream
            .subscribe(
                (proposalResponse: Proposal) => {
                    if (proposalResponse) {
                        this.proposal = proposalResponse;
                    }
                    this.isLoading = false;
                },
                ((error) => {
                    this.genericErrorService.setGenericError(error);
                })
            );
        this.subscriptions.push(subscription);
        const previousUrl: string = this.router?.getCurrentNavigation()?.previousNavigation?.finalUrl?.toString();
        previousUrl?.indexOf('ProposedAccounts') > 0 ||
            previousUrl?.indexOf('ExistingAccounts') > 0 ? this.navigateToLast = true : this.navigateToLast = false;
        this.enableThirdPartyMoneyMarket = this.featureFlagService.isEnableThirdPartyMoneyMarket();
        this.isInvestorPlatformFeeEnabled = this.featureFlagService.isInvestorPlatformFeeEnabled();
    }

    public ngOnInit(): void {
        this.proposal.scenarios[0].existingAccounts = _.cloneDeep(this.proposal?.scenarios[0]?.accounts);
        this.enableNonModelPortfolioAndAssetSearch = this.featureFlagService.isNonModelFeatureEnabled();
        this.isSubstitutionEnabled = this.featureFlagService.isSubstitutionsFeatureFlagEnabled();

        this.determineIfAccountInCurrentProposal();
        if (this.accountSavedInProposal) {
            this.cleanStoreInvestment();

            // Note: get account id
            this.accountId = Number(
                this.activatedRoute.snapshot.paramMap.get('accountId')
            );

            if (this.proposal) {
                this.retrieveAccountDetails();
                this.setRiskTolerance();
                this.businessRuleFlags = this.businessRuleFlagsService.getBusinessRules();
                const proposalScenarioChecklist: WipCheckList = this.proposal.wipCheckList
                    ?.find((wipChecklist: WipCheckList) =>
                        wipChecklist.sectionType === WipChecklistLabels.ProposalScenario);
                const proposalAccountIds: number[] = this.proposal.scenarios[0].accounts.map((account: Account) => account.id);
                proposalScenarioChecklist.subCheckList.forEach((subchecklist: WipCheckList, index) => {
                    const anyAccountMatches: boolean = proposalAccountIds.some((id: number) =>
                        subchecklist?.route?.includes(id.toString()) || !subchecklist.route.includes('/Accounts/'));
                    if (!anyAccountMatches) {
                        proposalScenarioChecklist.subCheckList.splice(index, 1);
                    }
                });
            }

            if (this.enableNonModelPortfolioAndAssetSearch) {
                this.addAdditionalInvestment = false;
                this.isShowNonModelMarket = true;
                this.isShowNonModel = true;
                this.nonModelInvestmentSummary = true;
                this.showZeroAmountOnSummary = true;
                this.showAdditionalInvestmentBtnInModelPortfolio = true;
                this.showAdditionalInvestmentBtnInNonModelPortfolio = false;

                if (this.nonModelInvestments?.length > 0) {
                    this.createNonModelInvestmentsFromExisting = this.nonModelInvestments;
                }
            }

            // Note: process actions
            this.subscriptions.push(
                this.delegationService.refresh().subscribe((action: string) => {
                    this.processAction(action);
                })
            );

            const subscriptionStore: Subscription = this.appStore.select(getInvestSummary).subscribe(
                (responseProposedInvestmentSummary: InvestmentSummary) => {
                    // If this flag is true, we failed to save the account, so we need to use the previously
                    // saved investment summary.
                    if (this.failedToSaveAccountDetails) {
                        responseProposedInvestmentSummary = this.previousInvestmentSummary;
                        const action: StoreAction<InvestmentSummary> = {
                            type: InvestmentActions[InvestmentActions.InitalInvestment],
                            payload: _.cloneDeep(this.previousInvestmentSummary)
                        };
                        this.appStore.dispatch(action);
                        this.callRiskBecauseErrorCleared = false;
                        this.failedToSaveAccountDetails = false;
                        if (this.featureFlagService.isNonModelFeatureEnabled()) {
                            if (!_.isEmpty(this.account?.portfolios)) {
                                this.createNonModelInvestmentsFromExisting = this.createNonModelObjectFromPortfolio();
                            }
                        }
                    }
                    if (responseProposedInvestmentSummary) {
                        this.updateHUDInformation(responseProposedInvestmentSummary);

                        if (responseProposedInvestmentSummary.proposedInvestmentSummary) {
                            this.setBusinessRuleFlags(responseProposedInvestmentSummary.proposedInvestmentSummary);
                            this.setStatusDfsRochdaleStrategyFlag(true);
                            if (_.isEmpty(responseProposedInvestmentSummary.proposedInvestmentSummary.strategyDetails)) {
                                delete this.account.groupCHNWStrategyId;
                                this.account.strategies = [];
                            }
                            if (this.strategiesService.itHasTheMinimumOfRequiredStrategies(responseProposedInvestmentSummary, 1)) {
                                this.setStatusDfsRochdaleStrategyFlag(false);
                                const defaultAmount: number =
                                    this.proposalAccountService.
                                        getNewAllocationAmountIncludingAnyUnassignedFunds(this.account,
                                            this.unallocatedCurrentAccountModelFunds);
                                this.strategiesService.setDefaultAmountToFirstStrategy(
                                    responseProposedInvestmentSummary,
                                    defaultAmount
                                );
                                this.previousInvestmentSummary = _.cloneDeep(responseProposedInvestmentSummary);
                                if (this.callRiskBecauseErrorCleared) {
                                    this.updateRiskWidgetInvestmentPoint(responseProposedInvestmentSummary);
                                    this.callRiskBecauseErrorCleared = true;
                                } else {
                                    this.transformInvestmentSummaryToProposalStrategy(responseProposedInvestmentSummary);
                                }
                            }
                        }
                    }
                });

            this.primaryDsrId = this.account.advisors.find(
                (advisor) => advisor.role.description === 'Primary'
            ).entityId;

            const crmRuleRequest: CrmPayload = {
                primaryDsrId: this.primaryDsrId,
                isAccountManaged: this.businessRuleFlags.isAccountManaged ? this.businessRuleFlags.isAccountManaged : false
            };
            this.subscriptions.push(this.crmRulesService.getCrmRules(crmRuleRequest).subscribe((response: CRMRuleResponse): void => {
                const payload: CRMRuleStateSlice = { crmRuleDetailsStore: response.data.attributes };
                const action: StoreAction<CRMRuleStateSlice> = {
                    type: CRMRuleActions[CRMRuleActions.CRMRuleDetails],
                    payload: _.cloneDeep(payload)
                };
                this.appStore.dispatch(action);
                this.crmRules = response.data.attributes.crmRules;
                this.sacDiscount = response.data.attributes.sacDiscount;
                this.getValuesForInvestmentSummary();
            }));
            this.subscriptions.push(subscriptionStore);
            this.intiateNavigationTabs();

            // Note: this is to make CAR component visible
            this.componentVisibility = {
                isSubstitutionsVisible: true,
                seiIasInlineInputVisible: true,
                seiIasRiskSummaryWidget: true,
                seiIasGoalFundingVisible: true,
                strategySelectionPrimaryFilterVisible: true,
                goToExploreStrategyOnEmptyInvestment: true,
                showDfsRochdaleStrategySelection: true,
                allowRemoveInvestmentModification: false,
                allowRemoveStrategyOnInvestmentSelection: true,
                hideAddToUmaButton: true, // To hide Add To UMA on Investment Summary, set this false to get this feature back
                hideManagementStrategyFilters: false,
                allowRemoveWarningEnterAmount: true,
                customizeCreateUmaModel: true,
                hideCustomUmaModelOptions: false,
                hideEditPreferences: !this.isSubstitutionEnabled,
                hideThirdPartyManager: this.account.investmentProgramId !== InvestmentProgramType.ManagedAccounts
            };

            this.apiConfiguration = {
                apiVersion: 'v4'
            };

            this.appStore.select((slice: IASCommonAppStore): FirmInfoSlice => slice.firmInfoSlice).pipe(
                take(1)
            ).subscribe((slice: FirmInfoSlice) => {
                if (this.propService.exists(() => (slice.values.PlatformFeeBasisPoints))) {
                    this.platformFeeBasisPoint = +slice.values.PlatformFeeBasisPoints;
                }
            });

            this.initializeModifyInvestmentBanner();
        } else {
            this.spinnerService.stop();
        }
        if (this.featureFlagService.isNonModelFeatureEnabled()) {
            this.subscriptions.push(this.proposalAccountService.isPortfolioCallInProgress.subscribe({
                next: (isLoading: boolean) => {
                    isLoading ? this.spinnerService.start() : this.spinnerService.stop();
                    this.isNonModelLoading = isLoading;
                    if (!this.nonModelPortfolioType && !this.isNonModelLoading) {
                        // Non-models have been enriched. Pull latest from proposal and update this.account
                        this.account = this.proposal?.scenarios[0]?.accounts?.find(
                            (account) => account?.id === this.accountId
                        );
                        const isAccountDetailsLoading: boolean =
                            !this.investmentSummaryLoaded || this.isNonModelLoading || !this.nonModelPortfolioType || this.isSaveInProgress;
                        if (isAccountDetailsLoading) {
                            this.spinnerService.start();
                            this.delegationService.publish(CommandText.NonModelEnrichmentInProgress);
                        }
                        this.subscriptions.push(this.retrieveNonModelAccountTypes({
                            primaryAdvisorId: this.primaryDsrId,
                            accountGroupTypeId: this.account.type.groupType.groupTypeId,
                            investmentProgramId: this.account.investmentProgramId
                        }).subscribe({
                            next: (response: NonModelPortfolioTypeResponse): void => {
                                this.nonModelPortfolioType = response;
                                if (!_.isEmpty(this.account?.portfolios)) {
                                    this.createNonModelInvestmentsFromExisting = this.createNonModelObjectFromPortfolio();
                                    this.accountsBackup = _.cloneDeep(this.proposal.scenarios[0].accounts || []);
                                }
                                this.isNonModelLoading = false;
                                if (!this.investmentSummaryLoaded || this.isNonModelLoading ||
                                    !this.nonModelPortfolioType || this.isSaveInProgress) {
                                    this.spinnerService.start();
                                    this.delegationService.publish(CommandText.NonModelEnrichmentInProgress);
                                } else {
                                    this.spinnerService.stop();
                                    this.delegationService.publish(CommandText.NonModelEnrichmentComplete);
                                }
                            },
                            error: (error) => {
                                this.genericErrorService.setGenericError(error);
                                this.spinnerService.stop();
                                this.delegationService.publish(CommandText.NonModelEnrichmentComplete);
                            },
                            complete: () => {
                                this.spinnerService.stop();
                                this.delegationService.publish(CommandText.NonModelEnrichmentComplete);
                            }
                        }));
                    }
                },
                error: (error) => {
                    this.genericErrorService.setGenericError(error);
                    this.spinnerService.stop();
                },
                complete: () => {
                    this.spinnerService.stop();
                }
            }));
        }

        this.nonModelSellsOnlyOverride = {
            warningText: NonModelOverrideSellsOnly.WARNING_TEXT,
            warningType: BannerMessageType.warning,
            showCloseIcon: false,
            overrideSellsOnlyRuleFeatureEnabled: this.featureFlagService.isOverrideSellsOnlyRuleFeatureEnabled()
        };
    }

    public onClickToAddNewInvestment(flag: boolean): void {
        this.addAdditionalInvestment = flag;
    }

    public onNonModelPortfolioChange(data: NonModelData[]): void {
        const isPortfolioChanged: boolean = !(this.previousPortfolios &&
            data.every((newPortfolio: NonModelData, index: number): boolean => {
                const previousPortfolio: NonModelData =
                    this.previousPortfolios.find((savedPortfolio: NonModelData) => savedPortfolio.id === newPortfolio.id);
                if (previousPortfolio) {
                    const isPortfolioValueEqual = data[index].nonModelPortfolioItems.every((newAsset: PortfolioData): boolean => {
                        const savedAsset: PortfolioData =
                            previousPortfolio.nonModelPortfolioItems
                                .find((previousAsset: PortfolioData) => newAsset.id === previousAsset.id);
                        const isValueSame: boolean = savedAsset && newAsset.value.toFixed(2) === savedAsset.value.toFixed(2);
                        return isValueSame;
                    });
                    return isPortfolioValueEqual;
                }
                return false;
            }));
        this.nonModelInvestments = data;
        this.mapNonModelToPortfolio();
        if (isPortfolioChanged) {
            this.updateHUDInformation(this.previousInvestmentSummary);
        }
        this.previousPortfolios = _.cloneDeep(data);
        this.accountPortfoliosBackup = _.cloneDeep(this.account?.portfolios);
        this.validateAccount();
    }

    private createNonModelObjectFromPortfolio(): NonModelData[] {
        const newNonModelData: NonModelData[] = [];
        this.account?.portfolios?.forEach((data: Portfolio): void => {
            const portfolioHoldingList: PortfolioData[] = [];
            let portfolioValue: number = 0;

            data.proposedPortfolioHoldingList.forEach((asset: PortfolioHolding): void => {
                const assetName: string = asset.cashAssetFlag && !asset.tickerId ? 'Cash & Equivalents' : asset?.assetData?.name;
                const smallestTradeQuantity: number = asset?.assetData?.smallestTradeQuantity;
                const smallestTradeIncrement: number = asset?.assetData?.smallestTradeIncrement;
                const isBond: boolean = asset?.assetData?.assetTypeCode?.startsWith('D');
                const value: number = isBond ? this.getAmountForBondAssets(asset.quantity, asset?.assetData?.estimatedValue) :
                    asset.quantity * asset?.assetData?.estimatedValue;
                const isNewAsset: boolean = asset.proposalPortfolioHoldingId === undefined;
                const id: number = asset.proposalPortfolioHoldingId === undefined ?
                    this.getMaxId(portfolioHoldingList) :
                    asset.proposalPortfolioHoldingId;
                portfolioHoldingList.push({
                    id,
                    assetName,
                    price: asset?.assetData?.estimatedValue,
                    quantity: asset.quantity,
                    value,
                    isQtyValid: true,
                    isValueValid: true,
                    instrumentId: Number(asset.instrumentId),
                    existingAccountPortfolioId: asset?.existingAccountPortfolioId ?? null,
                    isExistingAccountHoldingDeleted: asset?.isExistingAccountHoldingDeleted ?? false,
                    assetDetail: {
                        ticker: asset.tickerId,
                        cusip: asset.cusipId,
                        allocations: undefined,
                        seiFund: undefined,
                        smallestTradeQuantity,
                        smallestTradeIncrement,
                        sorKey: asset.sorKey,
                        estimatedPrice: asset?.assetData?.estimatedValue
                    },
                    isCash: asset.cashAssetFlag,
                    assetTypeCode: asset?.assetData?.assetTypeCode,
                    isBond,
                    isNewAsset
                });

                portfolioValue += Number(asset.quantity) * Number(asset?.assetData?.estimatedValue);
            });
            newNonModelData.push({
                id: data.proposalPortfolioId,
                portfolioType: this.nonModelPortfolioType?.nonModelPortfolioTypes
                    ?.find((type: NonModelPortfolioTypes): boolean => type.portfolioPurposeId === data.portfolioPurpose.portfolioPurposeId)
                    || this.createPortfolioTypeFromPortfolioPurpose(data.portfolioPurpose),
                nonModelPortfolioItems: portfolioHoldingList,
                portfolioValue,
                isShowAssets: true,
                portfolioName: data.portfolioName,
                existingAccountPortfolioId: data.existingAccountPortfolioId ?? undefined,
                isExistingAccountPortfolioDeleted: data.isExistingAccountPortfolioDeleted ?? false
            });
        });

        return newNonModelData;
    }

    private createPortfolioTypeFromPortfolioPurpose(portfolioPurpose: PortfolioPurpose): NonModelPortfolioTypes {
        return {
            productId: undefined,
            portfolioPurposeId: portfolioPurpose.portfolioPurposeId,
            portfolioPurposeName: portfolioPurpose.portfolioPurposeName,
            portfolioLimit: 0,
            defaultPortfolioSweepModel: portfolioPurpose.defaultPortfolioSweepModel || undefined
        };
    }

    private mapNonModelToPortfolio(): void {
        const portfolios: Portfolio[] = [];

        this.nonModelInvestments.forEach((nonModel: NonModelData): void => {
            const assetList: PortfolioHolding[] = [];

            nonModel.nonModelPortfolioItems.forEach((portfolioData: PortfolioData): void => {
                const portfolioId: number = portfolioData.id === 0 || portfolioData.isNewAsset ? undefined : portfolioData.id;
                const isCash: boolean = !!portfolioData.isCash;
                assetList.push({
                    proposalPortfolioHoldingId: portfolioId,
                    instrumentId: Number(portfolioData.instrumentId),
                    tickerId: portfolioData.assetDetail?.ticker,
                    cusipId: portfolioData.assetDetail?.cusip,
                    quantity: portfolioData.quantity,
                    cashAssetFlag: isCash,
                    sorKey: portfolioData.assetDetail?.sorKey,
                    existingAccountPortfolioId: portfolioData?.existingAccountPortfolioId ?? null,
                    isExistingAccountHoldingDeleted: portfolioData?.isExistingAccountHoldingDeleted ?? false,
                    assetData: {
                        name: portfolioData.assetName,
                        estimatedValue: portfolioData.price,
                        assetTypeCode: portfolioData.assetTypeCode,
                        smallestTradeIncrement: portfolioData?.assetDetail?.smallestTradeIncrement,
                        smallestTradeQuantity: portfolioData?.assetDetail?.smallestTradeQuantity
                    }
                });
            });

            portfolios.push({
                proposalPortfolioId: nonModel.id,
                portfolioPurpose: {
                    portfolioPurposeId: nonModel?.portfolioType?.portfolioPurposeId,
                    portfolioPurposeName: nonModel?.portfolioType?.portfolioPurposeName,
                    defaultPortfolioSweepModel: nonModel?.portfolioType?.defaultPortfolioSweepModel
                },
                portfolioName: this.account?.currentAccountNumber ? nonModel?.portfolioName : nonModel?.portfolioType?.portfolioPurposeName,
                proposedPortfolioHoldingList: assetList,
                fees: undefined,
                existingAccountPortfolioId: nonModel?.existingAccountPortfolioId ?? undefined,
                isExistingAccountPortfolioDeleted: nonModel?.isExistingAccountPortfolioDeleted ?? false
            });
        });

        this.account.portfolios = portfolios;
    }

    private retrieveNonModelAccountTypes(request: NonModelPortfolioTypeRequest): Observable<NonModelPortfolioTypeResponse> {
        const url: string = this.globalService.configService.routeFormatter(
            this.globalService.configService.getEndPoint('retrieveNonModelPortfolioTypes'));
        const body: NonModelPortfolioTypeRequest = request;
        const temp: Observable<NonModelPortfolioTypeResponse> = this.httpClient.post<NonModelPortfolioTypeResponse>(url, body);
        return temp;
    }

    private determineIfAccountInCurrentProposal(): void {
        this.accountId = Number(
            this.activatedRoute.snapshot.paramMap.get('accountId')
        );

        if (this.proposal) {
            const isAccountIdInRoutePresentInProposal = this.proposal.scenarios[0].accounts &&
                !this.proposal.scenarios[0].accounts.some((account) => account.id === this.accountId);
            if (isAccountIdInRoutePresentInProposal) {
                this.accountSavedInProposal = false;
                // Send user back to WIP because they have refreshed or lost account context
                this.router.navigate(['../Proposal/WIP/', this.activatedRoute.snapshot.parent.params.proposalId]);
            } else {
                this.accountSavedInProposal = true;
            }
        }
    }

    public ngOnDestroy(): void {
        this.businessRuleFlagsService.inactiveAllRules();
        // Stops spinner from non-model enrichment call when component is destroyed before false value comes accross the observable stream
        this.spinnerService.stop();
        super.ngOnDestroy();
    }

    private cleanStoreInvestment(): void {
        this.iasDispatchService.cleanStoreIasStore();
    }

    private setStatusDfsRochdaleStrategyFlag(flagStatus: boolean): void {
        this.componentVisibility.showDfsRochdaleStrategySelection = flagStatus;
    }

    private getValuesForInvestmentSummary(): void {
        this.setAccountDetailsValues();
        this.setProposedInvestmentSummary();
    }

    private setRiskTolerance(): void {
        if (
            this.account &&
            this.account.riskTolerance
        ) {
            switch (this.account.riskTolerance.riskMethod) {
                case RiskMethods.Questionnaire:
                    if (this.account?.riskTolerance?.questionnaire.length === this.proposalAccountService.NUMBER_OF_RTQ_QUESTIONS) {
                        this.riskToleranceScore = this.account.riskTolerance.calculatedQuestionnaireRisk;
                    } else {
                        this.riskToleranceScore = undefined;
                    }
                    break;
                case RiskMethods.LowMediumHigh:
                    this.getLowMedHighRisk(this.account.riskTolerance.selectedLowMedHighRisk);
                    break;
                case RiskMethods.Skip:
                    this.riskToleranceScore = undefined;
                    break;
            }
        }
    }

    private getLowMedHighRisk(riskLevel): void {
        switch (riskLevel) {
            case RiskToleranceLevel.Low:
                this.riskToleranceScore = RiskLevelValues.LowMid;
                break;
            case RiskToleranceLevel.Medium:
                this.riskToleranceScore = RiskLevelValues.MediumMid;
                break;
            case RiskToleranceLevel.High:
                this.riskToleranceScore = RiskLevelValues.HighMid;
                break;
        }
    }

    private processAction(action: string) {
        switch (action) {
            case CommandText.UpdateStrategy:
                if (this.currentAccountIndex !== -1) {
                    this.isLoading = true;
                    this.updateStrategy(this.currentAccountIndex, this.account, this.routePath);
                    this.isLoading = false;
                }
                break;

            case CommandText.GetStrategies:
                this.retrieveAccountDetails();
                break;

            case CommandText.SaveStrategies: {
                this.isSaveInProgress = true;
                // Note: Read from store
                let isReadyToSave: boolean = true;
                this.proposalAccountService.setAccountRiskToSkippedStatusIfNeeded(this.account);
                const subscription = this.appStore.select(getInvestSummary).subscribe(
                    (responseProposedInvestmentSummary: InvestmentSummary) => {
                        if (responseProposedInvestmentSummary) {
                            isReadyToSave = this.isModelDetailNameValid(responseProposedInvestmentSummary);
                            if (isReadyToSave) {
                                this.transformInvestmentSummaryToProposalStrategy(responseProposedInvestmentSummary);
                            }
                        }
                    });

                this.subscriptions.push(subscription);
                // Note: Validate strategies before exiting.
                isReadyToSave = isReadyToSave && this.validateStrategies();

                if (this.currentAccountIndex !== -1 && isReadyToSave) {
                    this.saveProposalAccount(this.currentAccountIndex);
                }
                break;
            }
            // Note: if the button is disabled then there is an eror so show exclamation icon
            case CommandText.DisableSaveAndExitButton:
                this.loaderService.hideOverlayLoader();
                if (!this.isManagedNonManagedCombination) {
                    this.showErrorExclamation = true;
                }
                break;

            // Note: if the button is enabled then there is no error so hide exclamation icon
            case CommandText.EnableSaveAndExitButton:
                this.loaderService.hideOverlayLoader();
                this.showErrorExclamation = false;
                break;

            case CommandText.GoToNext:
                this.goToNext();
                break;

            case CommandText.GoToPrev:
                this.goToPrev();
                break;

            case CommandText.CancelRightHandDialog:
                // restore code
                this.restoreAccounts();
                break;

            case CommandText.ChangeRiskCapacity:
                this.setRiskTolerance();
                break;
        }
    }

    private restoreAccounts(): void {
        const scenario: Scenario = this.proposal.scenarios[0];

        if (scenario && Array.isArray(scenario.accounts)) {
            scenario.accounts = this.accountsBackup;
        }
    }

    private validateStrategies(): boolean {
        this.generalErrorMessage = '';
        const validStrategies = this.account.strategies.filter((strategy: Strategy) => {
            return strategy.amount > 0;
        });

        if (validStrategies && validStrategies.length === this.account.strategies.length) {
            return true;
        } else {
            this.generalErrorMessage = ErrorMessages.investmentAmountGreaterThanZero();
            this.showErrorExclamation = true;
            return false;
        }
    }

    private isModelDetailNameValid(investmentSummary: InvestmentSummary): boolean {
        this.generalErrorMessage = '';
        let modelDetailNameValid: boolean = true;
        if (investmentSummary.proposedInvestmentSummary.modelDetails &&
            investmentSummary.proposedInvestmentSummary.modelDetails.isCustom
        ) {
            modelDetailNameValid =
                investmentSummary.proposedInvestmentSummary.modelDetails.isNameValid;
            this.showErrorExclamation = !modelDetailNameValid;
            this.generalErrorMessage = this.showErrorExclamation ? ErrorMessages.customUmaNameMustChange() : '';
        }
        return modelDetailNameValid;
    }

    private saveProposalAccount(accountIndex: number) {
        this.spinnerService.start();
        this.proposal.scenarios[0]?.accounts.forEach((account: Account) => {
            const initialValue: number = 0;
            const total = account.strategies.reduce(
                (accumulator, currentValue) => {
                    return accumulator + currentValue.amount;
                },
                initialValue
            );
            const accountHasStrategies: boolean = !!account.strategies && account.strategies.length > 0;
            if (accountHasStrategies) {
                account.balance = total;
            }
        });
        if (this.account.isNewAccount) {
            this.account.id = 0;
            this.existingAccountsService.resetTemporaryPortfolioIds(this.account);
            this.proposalService.fixProposalForSave(this.proposal);
            this.subscriptions.push(this.proposalService.updateProposalScenario(this.proposal, this.proposal.scenarios[0].id)
                .subscribe({
                    next: (updatedProposal: Proposal) => {
                        this.account.id = updatedProposal.scenarios[0].accounts[accountIndex].id;
                        this.account.owners = updatedProposal?.scenarios[0]?.accounts[accountIndex]?.owners;
                        this.account.existingPortfolios = updatedProposal?.scenarios[0]?.accounts[accountIndex]?.existingPortfolios;
                        this.proposal = updatedProposal;
                        this.routePath = this.activatedRoute.snapshot.routeConfig.path.replace(
                            ':accountId',
                            String(this.account.id)
                        ).replace(
                            ':scenarioId',
                            String(this.proposal.scenarios[0].id)
                        );
                        this.account.isNewAccount = false;
                        this.subscriptions.push(this.proposalService.updateProposalWipCheckList(updatedProposal.wipCheckList)
                            .subscribe({
                                next: (updatedWipChecklist: WipCheckList[]) => {
                                    this.proposal.wipCheckList = updatedWipChecklist;
                                    this.updateAccount(accountIndex);
                                },
                                error: () => {
                                    this.spinnerService.stop();
                                }
                            })
                        );

                    },
                    error: () => {
                        this.spinnerService.stop();
                    }
                })
            );
        } else {
            this.updateAccount(accountIndex);
        }
    }

    private updateAccount(accountIndex: number): void {
        const scenario: Scenario = this.proposal.scenarios[0];
        const accounts: Account[] = scenario.accounts;
        const newCHNWStrategyGroup: GroupCHNWStrategy[] = this.rochdaleGroupService.createGroupCHNWStrategy(
            accounts,
            scenario.groupCHNWStrategies || []
        );
        scenario.groupCHNWStrategies = newCHNWStrategyGroup;
        if (this.account?.currentAccountId) {
            this.account.isCurrentAccountInvestmentsModified = true;
        }
        const subscription = this.proposalAccountService
            .updateProposalAccount(this.account.id, this.account, newCHNWStrategyGroup)
            .subscribe((payload: SeiPayload) => {
                // eslint-disable-next-line @typescript-eslint/tslint/config
                const [proposalAccount] = payload.data;
                if (proposalAccount?.account?.currentAccountId) {
                    // This is a current account - we need to update the account details.
                    const updatedAccount = _.cloneDeep(proposalAccount.account) as Account;
                    this.existingAccountsService.updateExistingAccount(updatedAccount);
                }
                this.updateStrategy(accountIndex, proposalAccount.account, this.routePath);
                scenario.groupCHNWStrategies = proposalAccount.groupCHNWStrategy;
                if (proposalAccount.groupCHNWStrategy) {
                    // If it's custom high net worth then we need to refresh the entire proposal because more accounts have been updated
                    this.proposalService.getProposalById(this.proposal.id).subscribe((proposalResponse) => {
                        this.proposal = _.cloneDeep(proposalResponse);
                        const updatedAccounts: Account[] = this.proposal.scenarios[0].accounts;
                        this.rochdaleGroupService.setGroupCHNWToAccounts(updatedAccounts, proposalAccount.groupCHNWStrategy);
                        this.proposalService.changedProposal(this.proposal);
                        updatedAccounts.forEach((account, indexToUpdate) => {
                            const customChnwAccountPath: string = this.activatedRoute.snapshot.routeConfig.path.replace(
                                ':accountId', String(account.id)
                            ).replace(
                                ':scenarioId', String(scenario.id)
                            );
                            this.updateStrategy(indexToUpdate, account, customChnwAccountPath);
                        });
                        this.updateWipChecklist();
                    });
                } else {
                    this.updateWipChecklist();
                }
            },
                (error) => {
                    this.accountDetails.accountId = this.account.id;
                    this.account.portfolios = _.cloneDeep(this.accountPortfoliosBackup);
                    this.createNonModelInvestmentsFromExisting = _.cloneDeep(this.previousPortfolios);
                    this.router.navigate([`Proposal/WIP/${this.proposal.id}/Scenarios/${scenario.id}/Accounts/${this.account.id}`],
                        { replaceUrl: true });
                    this.failedToSaveAccountDetails = true;
                    this.isSaveInProgress = false;
                    this.spinnerService.stop();
                    this.delegationService.publish(CommandText.LoadingStopped);
                });

        this.subscriptions.push(subscription);
    }

    private updateWipChecklist(): void {
        // Note: Return to WipCheckList - Save current checklist first
        this.proposalService.updateProposalWipCheckList(this.proposal.wipCheckList).subscribe((checklist: WipCheckList[]) => {
            this.delegationService.publish(CommandText.UpdateWipCheckList);
            this.proposal.wipCheckList = checklist;
            this.proposalService.changedProposal(this.proposal);
            this.spinnerService.stop();
            this.delegationService.publish(CommandText.LoadingStopped);
            this.proposalService.proposalBackup = undefined;
            if (this.navigateToLast) {
                this.location.back();
            } else {
                this.router.navigate(['../Proposal/WIP/',
                    this.activatedRoute.snapshot.parent.params.proposalId]);
            }

        });
    }

    private setAccountDetailsValues(): void {
        const processingOrgIdElastic = this.elasticSearchService.getCounterPartyProcessingOrg();
        let primaryAdvisor: Advisor;
        let clientEntityId: number = 0;

        if (this.account) {
            if (this.account.advisors) {
                primaryAdvisor = this.account.advisors.find(
                    (advisor) => advisor.role.description === OwnerRoleDescription.Primary
                );
            }

            const clients: Client[] = this.proposal.clients;
            if (clients && clients.length) {
                clientEntityId = clients[0].entityId || 0;
            }

            if (this.account.investmentProgramId) {
                this.investmentSelectionOptions =
                    this.proposalAccountService.getInvestmentProgramInvestmentSelectionOptions(this.account.investmentProgramId);
                this.componentVisibility.hideCustomUmaModelOptions = this.investmentSelectionOptions.hideCustomUmaModelOptions;
            }
        }

        let swpFirmId: number = 0;
        if (this.propService.notExists(() => this.userProfile)) {
            this.userProfile = this.userProfileService.getCurrentUserProfile;
        }
        if (this.userProfile && this.userProfile.firm) {
            swpFirmId = this.userProfile.firm.swpFirmId;
        } else if (this.proposal && this.proposal.firm) {
            swpFirmId = this.proposal.firm.swpFirmId;
        }
        this.isEnableManagerGateway = this.isGatewayEnabled();
        const entityId: number = primaryAdvisor ? +primaryAdvisor.entityId : 0;
        if (this.account.owners && this.propService.exists(() => (this.account.owners[0].firstName))
            && this.propService.exists(() => (this.account.owners[0].lastName))) {
            this.accountName = (this.account.owners[0].firstName.trim() + ' ' + this.account.owners[0].lastName.trim()).trim();
        }
        const sweepModelElection: SweepModelElection = this.account.sweepModelElection.platformSweepModelId > 0 ?
            this.account.sweepModelElection : this.account.type.sweepModelElection;
        this.accountDetails = {
            investmentStatus: '',
            firmId: swpFirmId,
            firmName: this.propService.exists(() => this.userProfile.firm.firmName) ? this.userProfile.firm.firmName : '',
            userId: entityId || 0,
            clientId: clientEntityId,
            processingOrgId: processingOrgIdElastic,
            accountName: this.account ? this.account.name : '',
            accountNumber: this.getCurrentAccountNumberIfNeeded(this.account),
            accountId: this.accountId || 0,
            accountMarketValue: 0,
            accountType: this.proposalAccountService.getAccountGroupType(this.account),
            eSigners: [],
            inProgressActivityId: '',
            inProgressActivityStatus: '',
            fdicInd: sweepModelElection?.isFdic,
            currencySweepModelAlloc: sweepModelElection?.currencySweepModelAllocDescription
        };

        if (this.account?.type) {
            this.taxStatus = this.account.type.nonTaxableStatus ? TaxStatus.NonTaxable : TaxStatus.Taxable;
            if (this.accountCharacteristicsInfo) {
                this.accountCharacteristicsInfo.taxStatus = this.taxStatus;
            }
        }

        const taxStatus: string = this.taxStatus;

        this.accountCharacteristicsInfo = {
            primaryClientId: entityId || 0,
            primaryClientName: primaryAdvisor ? primaryAdvisor.name : '',
            primaryDsrId: entityId || 0,
            primaryDsrName: primaryAdvisor ? primaryAdvisor.name : '',
            taxStatus,
            isTaxOverlayDefaultOptionFeatureFlag:
                this.account?.investmentProgramId === InvestmentProgramType.ManagedAccounts ? true : false,
            isInvestorPlatformFeeEnabled: this.isInvestorPlatformFeeEnabled
        };
    }

    private transformInvestmentSummaryToProposalStrategy(responseProposedInvestmentSummary: InvestmentSummary): void {
        this.account.strategies = [];
        const strategyProductDetails: ProductDetail[] =
            this.strategiesService.getProductDetails(responseProposedInvestmentSummary);
        const strategyList: Strategy[] = _.cloneDeep(this.strategiesBackUp);
        strategyProductDetails.forEach((product: ProductDetail) => {
            // Note: need to inject custom model based on strategyTypeId
            // 1	Fund // 2	Manager // 3	Model // 4	Custom

            const assignStrategyTypeId: number =
                this.strategiesService.getStrategyTypeIdByModelType(product.modelType, product.isCustom);
            const assignModelTypeId: number = this.strategiesService.getModelTypeIdByModelType(product.modelType);
            const proposalAccountStrategyId: number =
                this.strategiesService.getProposalAccountStrategyId(product, strategyList, product.isCustom);
            const swpStrategyId: number =
                this.strategiesService.getSwpStrategyIdByProductDetail(product);
            let modelSubstitutions: SubstitutionsCategory[];
            if (this.isSubstitutionEnabled && product.selectedSubstitutions?.length > 0) {
                modelSubstitutions = [...product.selectedSubstitutions];
            }

            const customStrategy: CustomStrategy = this.strategiesService.getCustomStrategy(product);

            let swpFirmId: number = 0;
            if (this.userProfile && this.userProfile.firm) {
                swpFirmId = this.userProfile.firm.swpFirmId;
            } else if (this.proposal && this.proposal.firm) {
                swpFirmId = this.proposal.firm.swpFirmId;
            }

            const proposalStrategy: Strategy = {
                strategyName: product.name,
                strategyTypeId: assignStrategyTypeId,
                strategyTypeName: product.modelType,
                modelTypeName: product.modelName,
                modelTypeId: assignModelTypeId,
                swpStrategyId,
                // fundTickerId: undefined, // NOTE: This for funds, for the moment fundTickerId is not available. Validated with Adrian
                modelId: this.strategiesService.isUMAStrategy(assignStrategyTypeId) ? null : product.pmeModelId,
                amount: product.currentMarketValue || 0,
                cusipId: '',
                customModelName: '',
                customModelTypeId: undefined,
                customStrategyId: undefined,
                proposalAccountId: this.account.id,
                proposalScenarioGoalId: product.proposalScenarioGoalId,
                isManagedInvestmentStrategy: product.managedFlag,
                investmentStrategyCategoryId: 23208, // Note: sending it as generic number so not to break the contract is necessary
                proposalAccountStrategyId,
                customStrategy,
                selectedSubstitutions: modelSubstitutions,
                firmId: swpFirmId
            };
            this.account.strategies.push(proposalStrategy);
        });
    }

    private setProposedInvestmentSummary(): void {
        this.investmentSummary = new InvestmentSummaryFactory().createObject();
        this.investmentSummary.proposedInvestmentSummary.strategyDetails = [];
        delete this.investmentSummary.proposedInvestmentSummary.modelDetails;

        this.strategiesBackUp = _.cloneDeep(this.account.strategies);
        const observablesExploreModel: Array<Observable<ExploreModel>> = [];
        const observablesCardDetail: Array<Observable<CardDetail>> = [];
        const observablesCalculateInvestmentCostResponse: Array<Observable<CalculateInvestmentCostResponse>> = [];
        const observablesCalculateInvestmentMinimumResponse: Array<Observable<InvestmentMinimumResponse>> = [];
        const obsevablesPortfolioRiskResponse: Array<Observable<PortfolioRiskResponse>> = [];
        const accountNumber: string = this.getCurrentAccountNumberIfNeeded(this.account);
        this.account.strategies.forEach((strategy: Strategy) => {
            if (this.strategiesService.requiredSwpStrategyId(strategy.strategyTypeId)) {
                if (strategy.swpStrategyId) {
                    const requestAssetDetail: AssetDetailParams = {
                        ticker: strategy.swpStrategyId.toString(),
                        isManager: true
                    };
                    observablesCardDetail.push(this.investmentOptionService.subscribeToGetAssetsDetail(requestAssetDetail));
                }
            } else {
                // Get Model detail for all model ids (in UMA or otherwise) which are eligible for substitution.
                if (strategy.modelId) {
                    const requestModelDetail: ExploreDetailsParams = {
                        firmId: this.accountDetails.firmId,
                        modelId: [strategy.modelId],
                        fetchType: 'MODEL_DETAIL',
                        sacDiscount: this.sacDiscount,
                        fdic: this.account.sweepModelElection.isFdic,
                        currencySweepModelId: this.account.sweepModelElection.platformSweepModelId,
                        accountNumber,
                        isAccountFlow: accountNumber ? true : false
                    };
                    observablesExploreModel.push(this.investmentAssignmentService.subscribeToGetModelDetail(requestModelDetail));
                } else if (strategy.modelTypeName === ModelType.UMAModel && strategy.customStrategy?.allocations?.length) {
                    const modelIds: number[] = [];
                    strategy.customStrategy.allocations.forEach((allocation) => {
                        if (allocation.modelId && allocation.modelId !== 0) {
                            modelIds.push(allocation.modelId);
                        }
                    });
                    if (modelIds.length > 0) {
                        const requestModelDetail: ExploreDetailsParams = {
                            firmId: this.accountDetails.firmId,
                            modelId: modelIds,
                            fetchType: 'MODEL_DETAIL',
                            sacDiscount: this.sacDiscount,
                            fdic: this.account.sweepModelElection.isFdic,
                            currencySweepModelId: this.account.sweepModelElection.platformSweepModelId,
                            accountNumber,
                            isAccountFlow: accountNumber ? true : false
                        };
                        observablesExploreModel.push(this.investmentAssignmentService.subscribeToGetModelDetail(requestModelDetail));
                    } else {
                        this.mapUmaStrategyToInvestmentSummary(strategy, new Map());
                    }
                }
            }
        });

        if (observablesExploreModel.length || observablesCardDetail.length || observablesCalculateInvestmentCostResponse.length) {
            const generateProductDetail = (dataArray: ExploreModel[]) => {
                const strategyList: Strategy[] = _.cloneDeep(this.strategiesBackUp);
                const modelDetailMap: Map<number, ProductDetail> = new Map();
                dataArray.forEach((responseModelDetail: ExploreModel) => {
                    if (responseModelDetail && responseModelDetail.data && Array.isArray(responseModelDetail.data.attributes)) {
                        const productDetails: ProductDetail[] = responseModelDetail.data.attributes
                            .filter((productDetailItem: ProductDetail) => productDetailItem.mmtModelStatus === 'Active');
                        productDetails.forEach((productDetail: ProductDetail) => {
                            modelDetailMap.set(productDetail.pmeModelId, productDetail);
                        });
                    }
                });
                strategyList.forEach((strategy) => {
                    const isExistingAccountModelClientCustom: boolean = this.account.currentAccountId &&
                        modelDetailMap.has(strategy?.modelId) &&
                        (modelDetailMap.get(strategy.modelId)?.productScope?.toUpperCase() === ProductScope.ClientProductScope.toUpperCase()
                            || modelDetailMap.get(strategy.modelId)?.productScope?.toUpperCase()
                            === ProductScope.FirmProductScope.toUpperCase())
                        && modelDetailMap.get(strategy.modelId)?.modelType?.toUpperCase() === ModelType.UMAModel.toUpperCase();

                    if (this.strategiesService.isUMAStrategy(strategy.strategyTypeId)) {
                        this.mapUmaStrategyToInvestmentSummary(strategy, modelDetailMap);
                    } else if (isExistingAccountModelClientCustom) {
                        const modelDetail: ProductDetail = modelDetailMap.get(strategy.modelId);
                        if (modelDetail) {
                            modelDetail.proposalAccountId = this.account.id;
                            modelDetail.activityType = AssetActivityType.AddStrategy;
                            modelDetail.currentMarketValue = strategy.amount;
                            this.investmentSummary.proposedInvestmentSummary.modelDetails = modelDetailMap.get(strategy.modelId);
                        } else if (this.account?.currentAccountId) {
                            this.existingAccountsService.showNoModelFoundAlert.next(true);
                            this.unallocatedCurrentAccountModelFunds += strategy.amount;
                        }
                    } else {
                        const modelDetail: ProductDetail = modelDetailMap.get(strategy.modelId);
                        if (modelDetail) {
                            this.strategiesService.setProductDetailValues(modelDetail, strategy, this.account);
                            this.investmentSummary.proposedInvestmentSummary.strategyDetails.push({ ...modelDetail });
                        } else if (this.account?.currentAccountId) {
                            this.existingAccountsService.showNoModelFoundAlert.next(true);
                            this.unallocatedCurrentAccountModelFunds += strategy.amount;
                        }
                    }
                    this.customUmaPillsData(observablesCalculateInvestmentMinimumResponse,
                        observablesCalculateInvestmentCostResponse, obsevablesPortfolioRiskResponse);
                });
            };

            const exploreModels$: Observable<ExploreModel[]> = forkJoin(observablesExploreModel).pipe(
                tap(generateProductDetail)
            );

            const generateCardDetail = (cardDetailsResponse: CardDetailResponse[]) => {
                const strategyList: Strategy[] = _.cloneDeep(this.strategiesBackUp);
                cardDetailsResponse.forEach((cardDetailResponse: CardDetailResponse) => {
                    const cardDetail: CardDetail = cardDetailResponse.data.attributes;
                    const accountStrategyIndex = strategyList.findIndex(
                        (strategy: Strategy) => strategy.swpStrategyId === +cardDetail.swpStrategyId
                    );
                    const accountStrategy = strategyList[accountStrategyIndex];
                    // Remove element from index to prevent duplicates when the same strategy is added
                    // multiple times in the same account
                    strategyList.splice(accountStrategyIndex, 1);
                    const productDetail: ProductDetail =
                        this.strategiesService.convertCardDetailToProductDetail(cardDetail, accountStrategy, this.account.id);

                    this.investmentSummary.proposedInvestmentSummary.strategyDetails.push({ ...productDetail });
                });

            };

            const cardDetail$: Observable<CardDetail[]> = forkJoin(observablesCardDetail).pipe(
                tap(generateCardDetail)
            );

            const generateInvestmentCostDetail = this.investmentCostDetail();
            const investmentCost$: Observable<CalculateInvestmentCostResponse[]> = forkJoin(observablesCalculateInvestmentCostResponse).
                pipe(
                    tap(generateInvestmentCostDetail)
                );

            const generateInvestmentMinimumDetail = this.investmentMinimumDetail();
            const investmentMinimum$: Observable<InvestmentMinimumResponse[]> = forkJoin(observablesCalculateInvestmentMinimumResponse).
                pipe(
                    tap(generateInvestmentMinimumDetail)
                );
            const generatePortfolioRisk = this.portfolioRisk();
            const portfolioRisk$: Observable<PortfolioRiskResponse[]> = forkJoin(obsevablesPortfolioRiskResponse).
                pipe(
                    tap(generatePortfolioRisk)
                );

            const subscription = concat(
                exploreModels$,
                cardDetail$,
                investmentCost$,
                investmentMinimum$,
                portfolioRisk$
            ).pipe(
                finalize(() => {
                    this.investmentSummaryLoaded = true;
                    if (this.enableNonModelPortfolioAndAssetSearch) {
                        !this.investmentSummaryLoaded ||
                            this.isNonModelLoading ||
                            !this.nonModelPortfolioType ? this.spinnerService.start() : this.spinnerService.stop();
                    } else {
                        this.spinnerService.stop();
                    }

                })
            ).subscribe();

            this.subscriptions.push(subscription);
        } else {
            this.customUmaPillsData(observablesCalculateInvestmentMinimumResponse,
                observablesCalculateInvestmentCostResponse, obsevablesPortfolioRiskResponse);
            const generateInvestmentMinimumDetail = this.investmentMinimumDetail();
            const generateInvestmentCostDetail = this.investmentCostDetail();
            const generatePortfolioRisk = this.portfolioRisk();
            const investmentMinimum$: Observable<InvestmentMinimumResponse[]> = forkJoin(observablesCalculateInvestmentMinimumResponse).
                pipe(
                    tap(generateInvestmentMinimumDetail)
                );
            const investmentCost$: Observable<CalculateInvestmentCostResponse[]> = forkJoin(observablesCalculateInvestmentCostResponse).
                pipe(
                    tap(generateInvestmentCostDetail)
                );
            const portfolioRisk$: Observable<PortfolioRiskResponse[]> = forkJoin(obsevablesPortfolioRiskResponse).
                pipe(
                    tap(generatePortfolioRisk)
                );
            const subscription = concat(
                investmentCost$,
                investmentMinimum$,
                portfolioRisk$
            ).pipe(
                finalize(() => {
                    this.investmentSummaryLoaded = true;
                    if (this.enableNonModelPortfolioAndAssetSearch) {
                        !this.investmentSummaryLoaded ||
                            this.isNonModelLoading ||
                            !this.nonModelPortfolioType ? this.spinnerService.start() : this.spinnerService.stop();
                    } else {
                        this.spinnerService.stop();
                    }
                })
            ).subscribe();
        }
    }

    private mapUmaStrategyToInvestmentSummary(strategy: Strategy, modelDetailMap: Map<number, ProductDetail>) {
        const productDetailSkeleton: ProductDetail = this.strategiesService.getProductDetailByStrategy(strategy, this.account);
        const productDetail: ProductDetail = this.strategiesService.setProductDetailCustomStrategy(productDetailSkeleton,
            modelDetailMap, strategy.customStrategy);
        productDetail.name = strategy.strategyName;
        productDetail.currentMarketValue = strategy.amount;
        productDetail.proposalScenarioGoalId = strategy.proposalScenarioGoalId;
        productDetail.proposalAccountId = this.account.id;
        productDetail.firmId = this.accountDetails.firmId;
        this.investmentSummary.proposedInvestmentSummary.modelDetails = { ...productDetail };
    }

    private investmentCostDetail() {
        return (dataArray: CalculateInvestmentCostResponse[]) => {
            dataArray.forEach((responseInvestmentCost: CalculateInvestmentCostResponse) => {
                if (responseInvestmentCost && responseInvestmentCost.data && responseInvestmentCost.data[0] != null) {
                    const calculationTotals: Total = responseInvestmentCost.data[0].total;
                    if (calculationTotals) {
                        this.substitutedInvestmentCost = Number((
                            Math.round(calculationTotals.investmentCostDollar * 100) / 100).toFixed(2)
                        );
                    }
                }
            });
            if (this.investmentSummary.proposedInvestmentSummary.modelDetails.modelType === ModelType.UMAModel) {
                this.investmentSummary.proposedInvestmentSummary.modelDetails.assetAllocationMetrics.investmentDetails.investmentCost
                    = this.substitutedInvestmentCost;
            }
        };
    }

    private investmentMinimumDetail() {
        return (dataArray: InvestmentMinimumResponse[]) => {
            dataArray.forEach((responseInvestmentMinimum: InvestmentMinimumResponse) => {
                if (responseInvestmentMinimum && responseInvestmentMinimum.data.attributes.investmentMinimum) {
                    this.substitutedInvestmentMinimum = responseInvestmentMinimum.data.attributes.investmentMinimum;
                }
            });
            if (this.investmentSummary.proposedInvestmentSummary.modelDetails.modelType === ModelType.UMAModel) {
                this.investmentSummary.proposedInvestmentSummary.modelDetails.assetAllocationMetrics.investmentDetails.minimumInvestment
                    = this.substitutedInvestmentMinimum;
            }
        };
    }

    private portfolioRisk() {
        return (dataArray: PortfolioRiskResponse[]) => {
            dataArray.forEach((responsePortfolioRisk: PortfolioRiskResponse) => {
                if (responsePortfolioRisk) {
                    this.substitutedPortfolioRisk = responsePortfolioRisk.data[0].data.portfolioRisk;
                }
            });
            if (this.investmentSummary.proposedInvestmentSummary.modelDetails.modelType === ModelType.UMAModel) {
                this.investmentSummary.proposedInvestmentSummary.modelDetails.assetAllocationMetrics.riskType
                    = this.riskcalculation(this.substitutedPortfolioRisk);
            }
        };
    }

    private riskcalculation(substitutedPortfolioRisk: string): RiskTypeDescription {
        if (substitutedPortfolioRisk === 'Medium Risk') {
            return RiskTypeDescription.Medium;
        } else if (substitutedPortfolioRisk === 'High Risk') {
            return RiskTypeDescription.High;
        } else if (substitutedPortfolioRisk === 'Low Risk') {
            return RiskTypeDescription.Low;
        }
    }

    private customUmaPillsData(observablesCalculateInvestmentMinimumResponse:
        Array<Observable<InvestmentMinimumResponse>>,
        observablesCalculateInvestmentCostResponse: Array<Observable<CalculateInvestmentCostResponse>>,
        obsevablesPortfolioRiskResponse: Array<Observable<PortfolioRiskResponse>>) {
        this.account.strategies.forEach((strategy: Strategy) => {
            if (strategy.customStrategy && strategy.customStrategy.allocations) {
                const requestToGetInvestmentMinimumDetails: InvestmentMinimumParam = {
                    modelId: null,
                    isTaxManaged: strategy.customStrategy.includeTaxOverlayService,
                    allocations: new ProductFactory().
                        createAllocationForInvestmentMinimum(strategy.customStrategy.allocations)
                };
                observablesCalculateInvestmentMinimumResponse.push(this.investmentAssignmentService.
                    subscribeToGetInvestmentMinimum(requestToGetInvestmentMinimumDetails));
                const requestGetInvestmentCostDetails: InvestmentCostParams = {
                    accountNumber: this.getCurrentAccountNumberIfNeeded(this.account),
                    taxMgmtForAccount: strategy.customStrategy.includeTaxOverlayService,
                    dollarAmount: 100,
                    product: new ProductFactory().createAllocation(strategy.customStrategy.allocations),
                    firmId: this.accountDetails.firmId,
                    fdic: this.account.sweepModelElection.isFdic
                };
                observablesCalculateInvestmentCostResponse.push(this.investmentAssignmentService.
                    subscribeToGetCalculateInvestmentCost(requestGetInvestmentCostDetails, this.apiConfiguration));

                const requestGetPortfolioRisks: PortfolioRiskParams = {
                    products: new ProductFactory().createPortfolioRiskAllocation(strategy.customStrategy.allocations)
                };

                obsevablesPortfolioRiskResponse.push(
                    this.investmentAssignmentService.subscribeToGetPortfolioRisk(requestGetPortfolioRisks,
                        this.apiConfiguration));

            }
        });
    }

    private setBusinessRuleFlags(proposedInvestmentSummary: CurrentInvestmentSummary): void {
        this.businessRuleFlagsService.inactiveAllRules();
        const isRochdaleAccount: boolean = this.account &&
            (typeof this.account.groupCHNWStrategyId === 'number') &&
            proposedInvestmentSummary.strategyDetails.some((product: ProductDetail): boolean => {
                return product.level1Hierarchy === 'Custom High Net Worth';
            });
        if (isRochdaleAccount) {
            this.businessRuleFlagsService.activateRochdaleAccount();
        }
        this.businessRuleFlagsService.setPrimaryBusinessRuleFlagsByInvestment(proposedInvestmentSummary);
        this.businessRuleFlagsService.emitEventBusinessRuleFlags();
        this.businessRuleFlags = this.businessRuleFlagsService.getBusinessRules();
    }

    private updateRiskWidgetInvestmentPoint(investmentSummary: InvestmentSummary): void {
        this.transformInvestmentSummaryToProposalStrategy(investmentSummary);
        this.strategiesService.getCombinedStrategyRisk(this.account)
            .subscribe((response: CombinedStrategyRisk) => {
                this.investmentRiskScore = 0;
                this.errorMessageCombinedStrategy = '';
                if (response) {
                    this.investmentRiskScore = response.strategyRiskScore;
                }
            }, (responseError: HttpErrorResponse) => {
                if (responseError.error && +responseError.error.code === ServerErrorStatus.ValidationError) {
                    this.errorMessageCombinedStrategy = responseError.error.message;
                }
            });
    }

    private intiateNavigationTabs(): void {
        this.navigationTabs = [
            {
                accountDetailTabs: this.accountDetailTabs.RiskCapacity,
                dataTarget: 'tabRiskCapacity',
                dataToggle: 'tab',
                showErrorExclamationIcon: false,
                description: StrategyNavTabs.Risk,
                isActive: false
            },
            {
                accountDetailTabs: this.accountDetailTabs.StrategySelection,
                dataTarget: 'tabStrategySelection',
                dataToggle: 'tab',
                showErrorExclamationIcon: true,
                description: StrategyNavTabs.Strategy,
                isActive: false
            }
        ];
    }

    private enableDisableNextPrevButton(): void {
        this.navigationTabs.forEach((navTap, index) => {
            navTap.isActive = navTap.accountDetailTabs === this.lastTabWorkedOn;
            if (navTap.isActive) {
                if (index === 0) {
                    this.delegationService.publish(
                        CommandText.DisablePrevButton
                    );
                    this.delegationService.publish(
                        CommandText.EnableNextButton
                    );
                } else if (index === this.navigationTabs.length - 1) {
                    this.delegationService.publish(
                        CommandText.EnablePrevButton
                    );
                    this.delegationService.publish(
                        CommandText.DisableNextButton
                    );
                } else {
                    this.delegationService.publish(
                        CommandText.EnablePrevButton
                    );
                    this.delegationService.publish(
                        CommandText.EnableNextButton
                    );
                }
            }
        });
    }

    private retrieveAccountDetails(): void {
        if (this.proposal && this.proposal.scenarios.some) {
            const scenario: Scenario = this.proposal.scenarios[0];
            this.currentAccountIndex = scenario.accounts.findIndex((account) => account.id === this.accountId);
            this.account = scenario.accounts.find(
                (account) => account.id === this.accountId
            );

            this.investmentRiskScore = this.account && this.account.combinedStrategyRisk
                ? this.account.combinedStrategyRisk.strategyRiskScore
                : undefined;

            const accountId = this.activatedRoute.snapshot.paramMap.get(
                'accountId'
            );
            const scenarioId = this.activatedRoute.snapshot.paramMap.get(
                'scenarioId'
            );

            this.routePath = this.activatedRoute.snapshot.routeConfig.path.replace(
                ':accountId',
                accountId
            ).replace(
                ':scenarioId',
                scenarioId
            );

            this.createUmaModelRoute = `${this.router.url}/${this.pathAssetAllocation}`;
            if (this.account.investmentProgramId === InvestmentProgramType.MutualFunds) {
                this.createUmaModelRoute = this.createUmaModelRoute
                    .concat('?hideThirdPartyManager');
            } else if (this.account.investmentProgramId === InvestmentProgramType.ManagedAccounts) {
                this.createUmaModelRoute = this.createUmaModelRoute
                    .concat('?defaultThirdPartyManagerToYes');

            }
            this.accountsBackup = _.cloneDeep(scenario.accounts || []);

            this.setLastTabWorkedOn();

            const primaryAdvisor = this.account.advisors.find(
                (advisor) => advisor.role.description === 'Primary'
            );

            const subscription = this.firmAdvisorService
                .getFirmDataByEntityId(primaryAdvisor.entityId)
                .subscribe((userProfile: UserProfile) => {
                    this.riskToleranceQuestionnaireMessage = this.processingRulesService.getRiskToleranceQuestionnaireMessage(
                        userProfile,
                        this.account.strategies
                    );
                    this.primaryAdvisorLoaded = true;
                    this.userProfile = userProfile;
                    this.setAccountDetailsValues();
                });

            this.subscriptions.push(subscription);
        }
    }

    private setLastTabWorkedOn(): void {
        const proposedSection = this.proposal.wipCheckList.find(
            (wipCheckListItem) =>
                wipCheckListItem.sectionType ===
                WipChecklistLabels.ProposalScenario
        );
        if (proposedSection) {
            const foundIndex = proposedSection.subCheckList.findIndex(
                (subCheckList) => subCheckList.route === this.routePath
            );
            if (foundIndex !== -1) {
                this.lastTabWorkedOn =
                    proposedSection.subCheckList[foundIndex].mdcSnapShot
                        .lastTabVisited;
                this.lastTabWorkedOn === 0
                    ? this.saveLastTabWorkedOn(AccountDetailTabs.RiskCapacity)
                    : this.saveLastTabWorkedOn(this.lastTabWorkedOn);
            }
        }
    }

    private updateStrategy(accountIndex: number, account: Account,
        currentAccountRoutePath: string, updateWipCheckList: boolean = true): void {
        if (!account) {
            return;
        }

        this.setAccountChanged(this.proposal.scenarios[0], account, accountIndex);
        // TODO: Wire up properly once we have wipCheckList for now take last position and update wipCheckList,
        // wipCheckList will always be returned with latest state.
        // Note: Since one strategy is select mark it complete.
        const proposedSectionIndex: number = this.proposal.wipCheckList.findIndex(
            (wipCheckListItem) =>
                wipCheckListItem.sectionType ===
                WipChecklistLabels.ProposalScenario
        );

        const foundIndex = this.proposal.wipCheckList[proposedSectionIndex]?.subCheckList
            ?.findIndex((x) => x.route === currentAccountRoutePath);
        if (foundIndex !== -1 && foundIndex !== undefined) {
            // Note: update last tab worked on
            this.proposal.wipCheckList[proposedSectionIndex].subCheckList[foundIndex].mdcSnapShot.lastTabVisited = this.lastTabWorkedOn;

            if (updateWipCheckList) {
                // Note: update completed flag
                this.proposal.wipCheckList[proposedSectionIndex].subCheckList[foundIndex].mdcSnapShot.completed =
                    this.proposalAccountService.verifyProposalAccountStatus(this.proposal.scenarios[0].accounts[accountIndex]);

                if (account.strategies && account.strategies.length) {
                    const initialValue: number = 0;
                    const total = account.strategies.reduce(
                        (accumulator, currentValue) => {
                            return accumulator + currentValue.amount;
                        },
                        initialValue
                    );

                    // Note: update wip balance
                    this.proposal.wipCheckList[proposedSectionIndex].subCheckList[foundIndex].balance = total;
                    account.balance = total;
                }
            }
        }

        this.proposalService.changedProposal(this.proposal);

    }

    private setAccountChanged(currentScenario: Scenario, account: Account, accountIndex: number): void {
        if (currentScenario && currentScenario.accounts && currentScenario.accounts[accountIndex]) {
            currentScenario.accounts[accountIndex] = account;
            currentScenario.accounts[accountIndex].actionTag.description = WipTagNames.Change;
            currentScenario.accounts[accountIndex].actionTag.id = ActionTags.Change;
        }
    }

    private goToNext(): void {
        const indexActive = this.navigationTabs.indexOf(
            this.navigationTabs.find((navTab) => navTab.isActive === true)
        );
        if (this.navigationTabs.length > indexActive) {
            this.saveLastTabWorkedOn(
                this.navigationTabs[indexActive + 1].accountDetailTabs
            );
        }
    }

    private goToPrev(): void {
        const indexActive = this.navigationTabs.indexOf(
            this.navigationTabs.find((navTab) => navTab.isActive === true)
        );
        if (this.navigationTabs.length > indexActive) {
            this.saveLastTabWorkedOn(
                this.navigationTabs[indexActive - 1].accountDetailTabs
            );
        }
    }

    private getCurrentAccountNumberIfNeeded(account: Account): string {
        let accountNumber: string;
        if (account?.currentAccountNumber && this.featureFlagService.checkExistingAccountsSectionEnabled()) {
            accountNumber = account?.currentAccountNumber;
        }
        return accountNumber;
    }

    public saveLastTabWorkedOn(lastTabWorkedOn: number): void {
        this.lastTabWorkedOn = lastTabWorkedOn;
        this.enableDisableNextPrevButton();
    }

    public saveAndExitDisable(hiddenSaveAndExit: boolean): void {
        this.showRiskWidget = false;
        this.bookMarkName = StrategyMenu.StrategySelection;
        if (hiddenSaveAndExit) {
            this.showHUD = false;
        } else {
            const subscription: Subscription = this.appStore.select(getInvestSummary)
                .subscribe((responseProposedInvestmentSummary: InvestmentSummary) => {
                    if (responseProposedInvestmentSummary) {
                        if (this.strategiesService.itHasTheMinimumOfRequiredStrategies(responseProposedInvestmentSummary, 1)) {
                            this.showRiskWidget = true;
                            this.bookMarkName = StrategyMenu.StrategySummary;
                        }
                        if (responseProposedInvestmentSummary.proposedInvestmentSummary) {
                            if (!responseProposedInvestmentSummary.proposedInvestmentSummary.modelDetails) {
                                this.noCashInModel = false;
                            }
                        }
                        if (this.strategiesValid()) {
                            this.noCashInModel = false;
                        }
                        this.noCashInModel ? this.delegationService.publish(CommandText.DisableSaveAndExitButton) :
                            this.validateAccount();
                    }
                });
            this.subscriptions.push(subscription);
        }
    }


    public updateHUDInformation(investmentSummary: InvesmtentSummaryState): void {
        if (this.strategiesService.itHasTheMinimumOfRequiredStrategies(investmentSummary, 2)) {
            let totalFee: number = 0;
            let minFee: number = 0;
            investmentSummary.proposedInvestmentSummary.strategyDetails.forEach((productDetail: ProductDetail) => {
                const amount: number = productDetail.currentMarketValue || 0;
                const minimum: number = productDetail.assetAllocationMetrics.investmentDetails.minimumInvestment || 0;
                totalFee += +amount;
                minFee += minimum;
            });
            if (this.featureFlagService.isNonModelFeatureEnabled() && this.account?.portfolios) {
                this.account.portfolios.forEach((portfolio: Portfolio) => {
                    totalFee += this.proposalAccountService.calculateValueOfNonModelPortfolio(portfolio);
                });
            }
            const modelDetail: ProductDetail = investmentSummary.proposedInvestmentSummary.modelDetails;
            if (modelDetail) {
                totalFee += +modelDetail.currentMarketValue || 0;
                if (modelDetail.assetAllocationMetrics && modelDetail.assetAllocationMetrics.investmentDetails) {
                    minFee += modelDetail.assetAllocationMetrics.investmentDetails.minimumInvestment || 0;
                }
            }
            this.hudFeeSchedule.total = totalFee;
            this.hudFeeSchedule.min = minFee;
            this.transformInvestmentSummaryToProposalStrategy(investmentSummary);
            this.account.fees.platformFeeBasisPoints = this.platformFeeBasisPoint;
            this.hudFeeSchedule.account = this.account;
            this.delegationService.publish(CommandText.GetFeeStrategy);
            this.showHUD = true;
        } else {
            this.showHUD = false;
        }
    }

    public displayInvestmentProductDetail(investmentSummary: InvestmentSummary): void {
        const defaultAmount: number =
            this.proposalAccountService
                .getNewAllocationAmountIncludingAnyUnassignedFunds(this.account, this.unallocatedCurrentAccountModelFunds);
        this.strategiesService.setDefaultAmountToFirstStrategy(investmentSummary, defaultAmount);
        if (this.account?.strategies &&
            !this.account?.strategies?.every((strategy: Strategy) => strategy.amount && Number(strategy.amount) > 0)) {
            this.saveAndExitDisableOnSummary(true);
        }
        this.modifyInvestmentCrmRules(investmentSummary);
    }

    public routerActivated(event: AssetAllocationComponent): void {
        if (event) {
            this.isAssetAllocation = true;
        } else {
            this.isAssetAllocation = false;
        }
    }
    public routerDeactivated(): void {
        this.isAssetAllocation = false;
    }

    public ngAfterViewInit(): void {
        this.enableDisableNextPrevButton();
    }

    public modifyInvestmentCrmRules(investmentSummary: InvestmentSummary): boolean {
        const proposedInvestment: ProductDetail[] = [];
        let proposedStrategyInvestment: ProductDetail[] = [];
        if (investmentSummary.proposedInvestmentSummary && investmentSummary.proposedInvestmentSummary.strategyDetails) {
            proposedStrategyInvestment = investmentSummary.proposedInvestmentSummary.strategyDetails;
        }
        if (proposedStrategyInvestment && proposedStrategyInvestment.length > 0) {
            proposedStrategyInvestment.forEach((investmentData: ProductDetail): void => {
                proposedInvestment.push(investmentData);
            });
        }
        let proposedModelInvestment: ProductDetail;
        if (investmentSummary.proposedInvestmentSummary && investmentSummary.proposedInvestmentSummary.modelDetails) {
            proposedModelInvestment = investmentSummary.proposedInvestmentSummary.modelDetails;
        }
        if (proposedModelInvestment) {
            proposedInvestment.push(proposedModelInvestment);
        }
        if (!_.isEmpty(proposedInvestment) && this.isFirmModelStrategyAllowed()) {
            if (this.isManagedModelStrategyAllowed()) {
                if (this.crmRules.isSeiProductsAndManagedProductsTogetherAllowed &&
                    this.crmRules.isFirmProductsAndManagedProductsTogetherAllowed) {
                    this.initializeModifyInvestmentBanner();
                    return true;
                } else if (!this.crmRules.isSeiProductsAndManagedProductsTogetherAllowed &&
                    this.crmRules.isFirmProductsAndManagedProductsTogetherAllowed) {
                    if (this.isNonManagedSeiStrategy(proposedInvestment) && this.isManagedProduct(proposedInvestment)) {
                        this.showModifyInvestmentInfoMessage();
                        return false;
                    } else {
                        this.enablePageButtons();
                        return true;
                    }
                } else if (this.crmRules.isSeiProductsAndManagedProductsTogetherAllowed &&
                    !this.crmRules.isFirmProductsAndManagedProductsTogetherAllowed) {
                    if (this.isFirmProduct(proposedInvestment) && this.isManagedProduct(proposedInvestment)) {
                        this.showModifyInvestmentInfoMessage();
                        return false;
                    } else {
                        this.enablePageButtons();
                        return true;
                    }
                } else if (!this.crmRules.isSeiProductsAndManagedProductsTogetherAllowed &&
                    !this.crmRules.isFirmProductsAndManagedProductsTogetherAllowed) {
                    if (this.isNonManagedProduct(proposedInvestment) && this.isManagedProduct(proposedInvestment)) {
                        this.showModifyInvestmentInfoMessage();
                        return false;
                    } else {
                        this.enablePageButtons();
                        return true;
                    }
                } else {
                    return true;
                }
            } else if (this.isManagedProduct(proposedInvestment)) {
                this.showModifyInvestmentInfoMessage();
                return false;
            } else {
                this.enablePageButtons();
                return true;
            }
        } else if (!_.isEmpty(proposedInvestment) && this.isManagedModelStrategyAllowed()) {
            if (this.crmRules.isSeiProductsAndManagedProductsTogetherAllowed) {
                if (this.isFirmProduct(proposedInvestment)) {
                    this.showModifyInvestmentInfoMessage();
                    return false;
                } else {
                    this.enablePageButtons();
                    return true;
                }
            } else if (this.isNonManagedProduct(proposedInvestment) && this.isManagedProduct(proposedInvestment)) {
                this.showModifyInvestmentInfoMessage();
                return false;
            } else {
                this.enablePageButtons();
                return true;
            }
        } else if (!_.isEmpty(proposedInvestment) &&
            (this.isManagedProduct(proposedInvestment) || this.isFirmProduct(proposedInvestment))) {
            this.showModifyInvestmentInfoMessage();
            return false;
        } else {
            this.enablePageButtons();
            return true;
        }
    }

    public isManagedModelStrategyAllowed(): boolean {
        if (this.crmRules.isManagedModelAllowed && this.crmRules.isManagedStrategyAllowed) {
            return true;
        } else {
            return false;
        }
    }

    public isFirmModelStrategyAllowed(): boolean {
        if (this.crmRules.isFirmModelAllowed && this.crmRules.isFirmStrategyAllowed) {
            return true;
        } else {
            return false;
        }
    }

    private isGatewayEnabled(): boolean {
        return this?.userProfile?.processingRules?.ShowProductTp === YesNoLabels.Yes;
    }

    public isNonManagedSeiStrategy(investment: ProductDetail[]): boolean {
        let nonManagedSeiStrategy: boolean = false;
        investment.forEach((investmentData: ProductDetail): void => {
            if (investmentData.modelType === ModelType.Strategy &&
                investmentData.productScope === 'SEI' &&
                !investmentData.managedFlag &&
                investmentData.activityType !== AssetActivityType.HoldAssets &&
                investmentData.activityType !== AssetActivityType.Removed) {
                nonManagedSeiStrategy = true;
            }
        });
        return nonManagedSeiStrategy;
    }

    public isFirmProduct(investment: ProductDetail[]): boolean {
        let firmProduct: boolean = false;
        investment.forEach((investmentData: ProductDetail): void => {
            if (investmentData.productScope === 'Firm' &&
                investmentData.activityType !== AssetActivityType.HoldAssets &&
                investmentData.activityType !== AssetActivityType.Removed) {
                firmProduct = true;
            }
        });
        return firmProduct;
    }

    public isManagedProduct(investment: ProductDetail[]): boolean {
        let managedProduct: boolean = false;
        investment.forEach((investmentData: ProductDetail): void => {
            if ((investmentData.managedFlag || investmentData.modelType === ModelType.Manager ||
                this.isClientCustomModel(investmentData)) &&
                investmentData.activityType !== AssetActivityType.HoldAssets &&
                investmentData.activityType !== AssetActivityType.Removed) {
                managedProduct = true;
            }
        });
        return managedProduct;
    }

    public isNonManagedProduct(investment: ProductDetail[]): boolean {
        let nonManagedProduct: boolean = false;
        investment.forEach((investmentData: ProductDetail): void => {
            if (!(investmentData.managedFlag || investmentData.modelType === ModelType.Manager ||
                this.isClientCustomModel(investmentData)) &&
                investmentData.activityType !== AssetActivityType.HoldAssets &&
                investmentData.activityType !== AssetActivityType.Removed) {
                nonManagedProduct = true;
            }
        });
        return nonManagedProduct;
    }

    public isClientCustomModel(clientModel: ProductDetail): boolean {
        if (clientModel.productScope === 'Client' && clientModel.modelType === ModelType.UMAModel &&
            clientModel.isThirdPartyManagersIncluded) {
            return true;
        } else {
            return false;
        }
    }

    public initializeModifyInvestmentBanner(): void {
        this.modifyInvestmentBannerConfig = [
            { messages: [], messageType: BannerMessageType.danger, display: false, showMessageContent: false }
        ];
    }

    public showModifyInvestmentInfoMessage(): void {
        this.isManagedNonManagedCombination = true;
        /* eslint-disable */
        this.modifyInvestmentBannerConfig[0].messages = ['You have included both ‘managed’ and ‘non-managed’ investments.  Please ensure that all investments are either ‘managed’ or ‘non-managed’ before proceeding.'];
        this.modifyInvestmentBannerConfig[0].display = true;

        this.disablePageButtons();
    }

    public saveAndExitDisableOnSummary(flag: boolean): void {
        if (flag === true) {
            this.delegationService.publish(CommandText.DisableSaveAndExitButton);
        } else {
            this.delegationService.publish(CommandText.EnableSaveAndExitButton);
        }
    }

    public validateAccount() {
        const strategiesValid: boolean = this.strategiesValid()
        if (strategiesValid) {
            this.disableFooterButtons(false);
        } else {
            this.disableFooterButtons(true);
        }
    }

    public checkFirmCustomStrategiesAllowed(): boolean {
        return this.crmRules?.isAllowFirmCustomStrategiesManagedAcc;
    }

    private disablePageButtons(): void {
        setTimeout(() => {
            this.delegationService.publish(CommandText.DisablePrevButton);
            this.delegationService.publish(CommandText.DisableNextButton);
            this.delegationService.publish(CommandText.DisableSaveAndExitButton);
        }, 100);
    }

    private enablePageButtons(): void {
        this.isManagedNonManagedCombination = false;
        this.enableDisableNextPrevButton();
    }

    validateFooterButtonStatus(disable: boolean): void {
        this.strategiesValid() ? this.disableFooterButtons(disable) : this.disableFooterButtons(true);
    }

    private disableFooterButtons(disable: boolean): void {
        if (!this.isPortfoliosValid()) {
            this.delegationService.publish(CommandText.DisableSaveAndExitButton);
        } else if (disable) {
            this.noCashInModel = true;
            this.delegationService.publish(CommandText.DisableSaveAndExitButton);
        } else {
            this.noCashInModel = false;
            this.delegationService.publish(CommandText.EnableSaveAndExitButton);
        }

    }

    private getAmountForBondAssets(qty: number,
        price: number): number {
        const estimatedPriceAsDecimal: BigNumber = new BigNumber(price.toFixed(2)).dividedBy(new BigNumber(100));
        const marketValue: BigNumber = estimatedPriceAsDecimal.times(new BigNumber(qty));
        return marketValue.toNumber();
    }

    private isPortfoliosValid(): boolean {
        const portfoliosValid: boolean = !this.account?.portfolios || this.account.portfolios.every((portfolio: Portfolio): boolean => {
            return portfolio.proposedPortfolioHoldingList.length === 1 ?
                portfolio.proposedPortfolioHoldingList[0].quantity > 0 :
                portfolio.proposedPortfolioHoldingList
                    .filter((holding: PortfolioHolding) => !holding.cashAssetFlag)
                    .every((holding: PortfolioHolding): boolean => {
                        return holding.quantity > 0;
                    });
        });
        return portfoliosValid;
    }

    private strategiesValid(): boolean {
        return !this.account?.strategies ||
            this.account?.strategies?.every((strategy: Strategy) => strategy.amount && Number(strategy.amount) > 0);
    }

    private getMaxId(holdingsList: PortfolioData[]) {
        const idList: number[] = holdingsList.map((holding: PortfolioData) => (holding.id || 0));
        return (idList && idList.length > 0 ? Math.max(...idList) + 1 : 0);
    }

    public showInvestmentSelectionComponent(): boolean {
        const showInvestmentSelection: boolean =
            this.investmentSummaryLoaded && this.accountDetails.firmId > 0 && !this.isNonModelLoading && !this.isSaveInProgress;
        if (showInvestmentSelection) {
            this.delegationService.publish(CommandText.LoadingStopped);
        } else {
            this.delegationService.publish(CommandText.IsLoading);
        }
        return showInvestmentSelection;
    }

}
