/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { TooltipConstants } from '@CarModels/constants';
import { SpinnerService } from '@CarServices/system/spinner.service';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DisplayAmountOptions, DisplayNullOptions, DisplayNumericScaleSuffix, LoadingOverlayStyle, ModalWindowComponent, NumericScale } from '@sei/common-components-lib-ux';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { CurrentAccount, CurrentAccountEnum, CurrentAccounts, SaveAccountDetails, TaxLotDetails, TaxLotDocument } from '@sei/ias-applications-lib-ux';
import { AccountNameMessages, CommandText } from '../../../model/enums';
import { CurrentAccountDetails, MdcSnapshot, Proposal, ProposalObjects, WipCheckList, WipCheckListCurrentAccount, WipCheckListCurrentAccountDisplay } from '../../../model/proposal';
import { AccountChecklistService } from '../../../service/account-checklist.service';
import { DelegationService } from '../../../service/delegation.service';
import { ProposalAccountService } from '../../../service/proposal-account.service';


@Component({
    selector: 'sei-car-account-checklist',
    templateUrl: './account-checklist.component.html',
    styleUrls: ['./account-checklist.component.scss']
})
export class AccountChecklistComponent extends ParentComponentSubscriptionManager implements OnInit, OnChanges {
    @Input() public isCurrentAccountAddEnable: boolean;
    @Input() public proposal: Proposal;
    @Input() public isReadUser: boolean;
    @Input() public showLockTooltip: boolean = false;
    @ViewChild(ModalWindowComponent, { static: true }) exitWarningModal: ModalWindowComponent;
    @Output() public enableTaxTransitionAddEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
    public isAccountsExpand: boolean = false;
    public title: string = CurrentAccountEnum.Add;
    public currentAccount: CurrentAccount;
    public allowMultiple: boolean = false;
    public documents: TaxLotDocument[] = [];
    public loadCurrentAccount: boolean = false;
    public mdcSnapShot: MdcSnapshot = {
        id: 1,
        active: true,
        changeState: '',
        completed: 1,
        disabled: true,
        optional: true,
        required: true
    };
    public isSubmitDisabled: boolean = true;
    public saveAccountDetails: SaveAccountDetails;
    public currentAccountCheckLists: WipCheckListCurrentAccount[] = [];
    styleInput: LoadingOverlayStyle = LoadingOverlayStyle.Translucent;
    public isDisplayModal: boolean = false;
    public currentAccountId: number;
    public currentAccountName: string;
    public deleteError: boolean = false;
    public isShowOverrideAndClose: boolean = true;
    public overrideError: boolean = false;
    public infoTextPriceAsOf: string = AccountNameMessages.PriceAsOfMessage;
    public displayNumericScaleSuffix: DisplayNumericScaleSuffix = {
        scale: NumericScale.NULL,
        displayDecimals: 2
    };
    public roundFractionalNumber: boolean = false;
    public displayMonetaryAmountOptions: DisplayAmountOptions = {
        nullDisplay: DisplayNullOptions.ZERO,
        roundFractionalAmount: false
    };
    public currentAccountList: WipCheckListCurrentAccountDisplay[] = [];
    public infoTextAccountName: string = AccountNameMessages.Empty;
    public displayAccountNameInfo: boolean = false;
    public isShowPriceAsOfDate: boolean = false;

    constructor(private accountChecklistService: AccountChecklistService,
        private spinnerService: SpinnerService,
        private delegationService: DelegationService,
        public proposalAccountService: ProposalAccountService) {
        super('AccountChecklistComponent');
    }

    public ngOnInit(): void {
        this.isAccountsExpand = (this.isCurrentAccountAddEnable && this.currentAccountCheckLists.length > 0);
        const delegationServiceSubscription = this.delegationService.refresh().subscribe((data: string) => {
            switch (data) {
                case CommandText.EnableCurrentAccountsSubmitButton:
                    this.isSubmitDisabled = false;
                    break;
                case CommandText.DisableCurrentAccountsSubmitButton:
                    this.isSubmitDisabled = true;
                    break;
                default:
                    break;
            }
        });
        this.subscriptions.push(delegationServiceSubscription);
    }

    public ngOnChanges(simpleChanges: SimpleChanges): void {
        if (simpleChanges && simpleChanges.isCurrentAccountAddEnable) {
            this.isCurrentAccountAddEnable = simpleChanges.isCurrentAccountAddEnable.currentValue;
        }

        if (this.isCurrentAccountAddEnable) {
            this.loadCurrentAccountDetails();
        }
    }

    public enableTaxTransitionAdd() {
        let enableTaxTransitionAdd: boolean = false;
        if (this.currentAccountCheckLists) {
            this.currentAccountCheckLists.forEach((checklist: WipCheckList) => {
                if (checklist.mdcSnapShot.completed === 1) {
                    enableTaxTransitionAdd = true;
                }
            });
        }
        this.enableTaxTransitionAddEvent.emit(this.isCurrentAccountAddEnable && enableTaxTransitionAdd);
    }

    public currentAccountExpand(): void {
        this.isAccountsExpand = !this.isAccountsExpand;
    }

    public getSaveAndExitTooltip(): string {
        if (this.showLockTooltip) {
            return TooltipConstants.PROPOSAL_CHECKLIST_LOCKED_MESSAGE;
        }
        return undefined;
    }

    private displayPriceAsOf(id: number): boolean {
        if (this.currentAccountList) {
            this.currentAccountList.forEach((item: WipCheckListCurrentAccountDisplay): void => {
                if (item.id === id) {
                    if (item.isSubscribedAssetsIncluded && !item.isUnsubscribedAssetsIncluded) {
                        this.isShowPriceAsOfDate = (item.priceAsOfDate !== null);
                    } else if (item.isSubscribedAssetsIncluded && item.isUnsubscribedAssetsIncluded) {
                        this.isShowPriceAsOfDate = (item.priceAsOfDate !== null);
                    } else if (!item.isSubscribedAssetsIncluded && item.isUnsubscribedAssetsIncluded) {
                        this.isShowPriceAsOfDate = false;
                    } else {
                        this.isShowPriceAsOfDate = (item.priceAsOfDate !== null);
                    }
                }
            });
        } else {
            this.isShowPriceAsOfDate = false;
        }
        return this.isShowPriceAsOfDate;
    }

    private showAccountNameInfo(id: number): boolean {
        if (this.currentAccountList) {
            this.currentAccountList.forEach((item: WipCheckListCurrentAccountDisplay): void => {
                if (item.id === id) {
                    if (item.isSubscribedAssetsIncluded && !item.isUnsubscribedAssetsIncluded) {
                        this.infoTextAccountName = AccountNameMessages.Empty;
                        this.displayAccountNameInfo = false;
                    } else if (item.isSubscribedAssetsIncluded && item.isUnsubscribedAssetsIncluded) {
                        this.infoTextAccountName = AccountNameMessages.BothSubscribedUnsubscribedAssets;
                        this.displayAccountNameInfo = true;
                    } else if (!item.isSubscribedAssetsIncluded && item.isUnsubscribedAssetsIncluded) {
                        this.infoTextAccountName = AccountNameMessages.OnlyUnsubscribedAssets;
                        this.displayAccountNameInfo = true;
                    } else {
                        this.infoTextAccountName = AccountNameMessages.Empty;
                        this.displayAccountNameInfo = false;
                    }
                }
            });
        } else {
            this.infoTextAccountName = AccountNameMessages.Empty;
            this.displayAccountNameInfo = false;
        }
        return this.displayAccountNameInfo;
    }

    public loadCurrentAccountForm(): void {
        if (this.isCurrentAccountAddEnable) {
            this.documents = [];
            this.title = CurrentAccountEnum.Add;
            this.loadCurrentAccount = this.loadCurrentAccount ? false : true;
            document.body.style.overflow = 'hidden';
        }
    }

    public onCancel(): void {
        this.loadCurrentAccount = this.loadCurrentAccount ? false : true;
        document.body.style.removeProperty('overflow');
    }

    public getSelectedAccountDetails(saveAccountDetails: SaveAccountDetails): void {
        this.saveAccountDetails = saveAccountDetails;
        if (this.documents && this.documents.length > 0) {
            this.saveAccountDetails.data[0].currentAccounts[0].taxLotDocument = this.documents[0];
        } else {
            if (this.saveAccountDetails && this.saveAccountDetails.data.length !== 0) {
                this.saveAccountDetails.data[0].currentAccounts[0].taxLotDocument = {};
            }
        }
        this.isSubmitDisabled = ((this.saveAccountDetails && this.saveAccountDetails.data.length !== 0
            && this.saveAccountDetails.data[0].currentAccounts[0].accountOwners) && !this.overrideError) ? false : true;
    }

    private createCurrentAccountList(): void {
        this.currentAccountList.forEach((obj: WipCheckListCurrentAccountDisplay): void => {
            if (obj.totalMarketValue) {
                obj.marketValueDisplay = {
                    value: obj.totalMarketValue,
                    actualCurrency: 'USD'
                };
            } else {
                obj.marketValueDisplay = null;
            }
        });
    }

    public loadCurrentAccountDetails(): void {
        this.proposal.wipCheckList.forEach((wipCheckList: WipCheckList): void => {
            if (wipCheckList.name === this.proposal.name) {
                wipCheckList.subCheckList.forEach((subCheckList: WipCheckList): void => {
                    if (subCheckList.name === 'Current Accounts') {
                        this.currentAccountCheckLists = subCheckList.subCheckList;
                    }
                });
            }
            this.currentAccountList = this.currentAccountCheckLists;
            this.createCurrentAccountList();
            this.enableTaxTransitionAdd();
            document.body.style.removeProperty('overflow');
        });
    }

    public getAccountDetails(): void {
        this.accountChecklistService.getCurrentAccountDetails(this.proposal.id)
            .subscribe({
                next: (response: CurrentAccountDetails): void => {
                    if (response && response.data && response.data.length !== 0) {
                        const proposalObjects: ProposalObjects = response.data[0];
                        this.proposal = proposalObjects.proposals[0];
                        this.loadCurrentAccountDetails();
                    }
                    this.spinnerService.stop();
                },
                error: (): void => {
                    this.spinnerService.stop();
                },
                complete: (): void => {
                    this.spinnerService.stop();
                }
            });
    }

    public getCurrentAccount(currentAccountId: number): void {
        this.accountChecklistService.getCurrentAccount(currentAccountId)
            .subscribe((response: SaveAccountDetails): void => {
                response.data.forEach((data: CurrentAccounts): void => {
                    data.currentAccounts.forEach((currentAccount: CurrentAccount): void => {
                        if (currentAccount.id) {
                            this.title = CurrentAccountEnum.Edit;
                            this.currentAccount = currentAccount;
                            this.loadCurrentAccount = this.loadCurrentAccount ? false : true;
                            document.body.style.overflow = 'hidden';
                            if (currentAccount.taxLotDocument && currentAccount.taxLotDocument.taxLotDocumentId) {
                                this.documents[0] = currentAccount.taxLotDocument;
                            }
                        }
                    });
                });
            });
    }

    public submitCurrentAccount(): void {
        this.delegationService.publish(CommandText.DisableCurrentAccountsSubmitButton);
        this.spinnerService.start();
        this.accountChecklistService.submitCurrentAccountDetails(this.saveAccountDetails)
            .subscribe((response: SaveAccountDetails): void => {
                response.data.forEach((data: CurrentAccounts): void => {
                    data.currentAccounts.forEach((currentAccuount: CurrentAccount): void => {
                        if (currentAccuount.id) {
                            this.getAccountDetails();
                            this.loadCurrentAccount = false;
                            this.delegationService.publish(CommandText.EnableCurrentAccountsSubmitButton);
                            this.isAccountsExpand = true;
                        }
                    });
                });
            },
                (): void => {
                    this.spinnerService.stop();
                    this.delegationService.publish(CommandText.EnableCurrentAccountsSubmitButton);
                },
                () => {
                    this.delegationService.publish(CommandText.EnableCurrentAccountsSubmitButton);
                });
    }

    public displayWarning(currentAccountId: number, currentAccountName: string): void {
        this.currentAccountId = currentAccountId;
        this.currentAccountName = currentAccountName;
        this.isDisplayModal = true;
        this.exitWarningModal.showModalBox();
    }

    public deleteCurrentAccount(): void {
        this.spinnerService.start();
        this.accountChecklistService.deleteCurrentAccount(this.currentAccountId).subscribe((response: SaveAccountDetails): void => {
            if (response) {
                this.deleteError = false;
                this.getAccountDetails();
                this.isDisplayModal = false;
                this.exitWarningModal.hideModalBox();
            }
            this.deleteError = false;
        }, (): void => {
            this.deleteError = true;
            this.spinnerService.stop();
        });
    }

    public onModalClosed(): void {
        if (this.isDisplayModal) {
            this.isDisplayModal = false;
            this.exitWarningModal.hideModalBox();
        }
    }

    public closeErrorMessage(): void {
        this.deleteError = false;
    }

    public uploadDocuments(documents: TaxLotDetails[]): void {
        if (documents && documents.length > 0 && documents[0].taxLotDocument && documents[0].taxLotDocument.filename) {
            this.isShowOverrideAndClose = documents[0].isShowOverrideAndClose;
            if ((documents[0].exceptions && documents[0].exceptions.length === 0) || !documents[0].isShowOverrideAndClose) {
                this.documents[0] = documents[0].taxLotDocument;
            } else {
                this.documents = [];
            }
            if (this.saveAccountDetails && this.saveAccountDetails.data.length > 0) {
                this.saveAccountDetails.data[0].currentAccounts[0].taxLotDocument =
                    ((documents[0].exceptions && documents[0].exceptions.length === 0) || !documents[0].isShowOverrideAndClose)
                        ? documents[0].taxLotDocument : {};
            }
            this.isSubmitDisabled = ((documents[0].isAnyTaxLotValid === null || documents[0].isAnyTaxLotValid === true) &&
                (documents[0].isShowOverrideAndClose && documents[0].exceptions.length > 0
                    && documents[0].exceptions[0].rowNumber !== null)) ? true : false;
            if (this.isSubmitDisabled) {
                this.overrideError = true;
            } else {
                this.overrideError = false;
            }
        } else {
            this.documents = [];
            this.saveAccountDetails.data[0].currentAccounts[0].taxLotDocument = {};
            this.isSubmitDisabled = (this.saveAccountDetails && this.saveAccountDetails.data.length !== 0
                && this.saveAccountDetails.data[0].currentAccounts[0].accountOwners) ? false : true;
            this.overrideError = false;
        }
    }
}
