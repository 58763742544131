/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { SeiPayload } from '../../model/sei-payload';
import { GenericErrorService } from '../system/generic-error.service';
import { LoggerService } from '../system/logger.service';
import { SeiJsonSerializerService } from '../system/sei-json-serializer.service';

// TODO: Work in progress and Tech Debt noted for some refactoring
// // eslint-disable-next-line @typescript-eslint/no-explicit-any just to get past linting.

// Setting this class up to switch out CARHttp but can't since other developers are working on affected code.
// Once they complete their PRs, we will swap this to CARHttpService

// Consolidates all HTTP calls into one place for other Angular/TS API code to use.
@Injectable({
    providedIn: 'root'
})
export class HttpService {
    constructor(
        private readonly http: HttpClient,
        private readonly errorHandler: GenericErrorService,
        private readonly serializer: SeiJsonSerializerService,
        private readonly carLogger: LoggerService
    ) {}

    // Centralizes a http request to attempt to maintain type safe returns and centralize HTTP error processing
    public getData(route): Observable<HttpResponse<SeiPayload>> {
        if (!route) {
            throw new Error('No route provided. Route is undefined');
        }

        try {
            return this.http.get<SeiPayload>(route, {
                observe: 'response'
            });
        } catch (e) {
            this.errorHandler.setGenericError(e.message);
            return of(new HttpResponse<SeiPayload>());
        }
    }

    // For now user with Repository Services until we get http cleaned up
    // TODO: Temp naming of function
    public getHttpData<T>(route: string, returnKey: string): Observable<T> {
        if (!route) {
            throw new Error('No route provided. Route is undefined');
        }
        if (!returnKey) {
            throw new Error(
                'No key provided for SEI payload data property array'
            );
        }

        return this.http
            .get(route)
            .pipe(
                map(
                    (body: Object) =>
                        this.serializer.deserialize<T>(body, returnKey) as T
                )
            );
    }

    public sendHttpData<T>(
        route: string,
        returnKey: string,
        data?
    ): Observable<T> {
        if (!route) {
            throw new Error('No route provided. Route is undefined');
        }
        if (!returnKey) {
            throw new Error(
                'No key provided for SEI payload data property array'
            );
        }

        return this.http
            .post(route, data)
            .pipe(
                map(
                    (body: Object) =>
                        this.serializer.deserialize<T>(body, returnKey) as T
                )
            );
    }

    // This is used for related parties, since we do not have PUT Http method that returns an Observable
    // This could be changed when refactor for HTTP Util has been completed
    public sendPutHttpData<T, D>(
        route: string,
        data: D,
        returnKey: string
    ): Observable<T> {
        if (!route) {
            throw new Error('No route provided. Route is undefined');
        }
        if (!returnKey) {
            throw new Error(
                'No key provided for SEI payload data property array'
            );
        }

        const payload: SeiPayload = {
            data: [{ account: data }],
            error: undefined,
            paging: undefined
        };

        return this.http
            .put<T>(route, payload)
            .pipe(
                map((body: T) =>
                    this.serializer.deserialize<T>(body, returnKey)
                )
            );
    }

    public deletedHttpData<T>(route: string, returnKey: string): Observable<T> {
        if (!route) {
            throw new Error('No route provided. Route is undefined');
        }
        if (!returnKey) {
            throw new Error(
                'No key provided for SEI payload data property array'
            );
        }

        return this.http
            .delete(route)
            .pipe(
                map(
                    (body: Object) =>
                        this.serializer.deserialize<T>(body, returnKey) as T
                )
            );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public async getDataAsync(route): Promise<any> {
        const res = this.http
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .get<any>(route, { observe: 'response' })
            .toPromise();
        return res;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public async sendDataAsync(route, body?): Promise<any> {
        if (!route) {
            throw new Error('No route provided. Route is undefined');
        }
        try {
            const res = await this.http
                .post(route, body, { observe: 'response' })
                .toPromise();

            return res.body;
        } catch (error) {
            await this.handleHttpError(error);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public async sendDataPutAsync(route, body?): Promise<any> {
        if (!route) {
            throw new Error('No route provided. Route is undefined');
        }
        try {
            const res = await this.http
                .put(route, body, { observe: 'response' })
                .toPromise();

            return res.body;
        } catch (error) {
            await this.handleHttpError(error);
        }
    }

    private handleHttpError(data) {
        // Simple error reporter to find untrapped errors that need to be handled
        // will address this piece of code with http fixes
        // some developers have had issues not seeing hidden http errors
        this.carLogger.logJsonObject(
            'httpErrorHandler-pleasehandleerror',
            data
        );
        this.errorHandler.setGenericError(data.message);
        return new HttpResponse<SeiPayload>();
    }
}
