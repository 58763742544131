/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Account } from '@CarInterfaces/account';
import { PropertyService } from '@CarServices/system/property.service';
import { SpinnerService } from '@CarServices/system/spinner.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SubpageheaderModel } from '@sei/common-swp-components-lib-ux';
import * as _ from 'lodash';
import { CurrentAdvisor } from '../../model/advisor-selected';
import { AdvisorRole, UserLevelEntitlement, advisorLabel } from '../../model/enums';
import { AdvisorFactory } from '../../model/factory/advisor-factory';
import { AccountOwner, Advisor, Proposal } from '../../model/proposal';
import { User, UserProfile } from '../../model/user-profile';
import { ElasticSearchService } from '../../service/elastic-search.service';
import { FirmAdvisorService } from '../../service/firm-advisor.service';
import { ProposalService } from '../../service/proposal.service';
import { GenericErrorService } from '../../service/system/generic-error.service';
import { UserProfileService } from '../../service/user-profile.service';

@Component({
    selector: 'sei-car-advisors-form',
    templateUrl: './proposal-advisors-form.component.html'
})

export class ProposalAdvisorsFormComponent implements OnInit {
    @Input() public proposal: Proposal;
    @Input() public defaultOwnerId?: number;
    @Input() public accountOwnerData: AccountOwner;
    @Input() public accountTypeId: number;
    @Output() public onAdvisorChange: EventEmitter<CurrentAdvisor[]> = new EventEmitter<CurrentAdvisor[]>();


    public firmId: number;
    public disableAddAdvisor: boolean = false;
    public hasAdvisorsAvailable: boolean = false;
    public advisors: Advisor[] = [];
    public currentAdvisors: CurrentAdvisor[] = [];
    public advisorSelectedIds: number[] = [];
    public holdExistAdvisors: Advisor[] = [];
    private userProfile: UserProfile;
    public accountData: Account;
    public maxAdvisors: boolean = false;
    private maxAdvisorLength: number = 5;

    pageHeaderProperties: SubpageheaderModel;
    private pageHeaderTitle: string = 'Advisors';
    private switcherAvailable: boolean = false;
    headingLevel: number = 2;

    constructor(
        public proposalService: ProposalService,
        public elasticSearchService: ElasticSearchService,
        public firmAdvisorService: FirmAdvisorService,
        private genericErrorService: GenericErrorService,
        private userProfileService: UserProfileService,
        private propertyService: PropertyService,
        private spinnerService: SpinnerService
    ) {
        this.pageHeaderProperties = {
            title: this.pageHeaderTitle,
            switcherAvailable: this.switcherAvailable
        };
    }

    public ngOnInit(): void {
        this.setAccountData();
        this.initializeComponent();
        this.initializeAdvisors();
    }

    private initializeComponent(): void {
        this.userProfile = this.userProfileService.getCurrentUserProfile;
        this.firmId =
            this.proposal.firm && this.proposal.firm.firmId
                ? this.proposal.firm.firmId
                : this.proposal.firmId;
        this.disableButtons(false);
    }

    private initializeAdvisors(): void {
        this.advisors = [];
        this.setAdvisorsDropdown();
    }

    private setAccountData(): void {
        const advisorsArray: Advisor[] = this.proposal?.advisors !== undefined ? _.cloneDeep(this.proposal?.advisors) :
            _.cloneDeep(this.proposal.scenarios[0].accounts[0].advisors);
        if (advisorsArray.length > 0) {
            const primaryAdvisor: Advisor = advisorsArray.find((advisor: Advisor) => advisor.role.id === AdvisorRole.Primary);
            const primaryAsCurrentAdvisor: CurrentAdvisor = { advisorSelected: primaryAdvisor, disabled: false };
            this.currentAdvisors.push(primaryAsCurrentAdvisor);
            advisorsArray.splice(advisorsArray.findIndex((advisor: Advisor): boolean => advisor.role.id === AdvisorRole.Primary), 1);
            advisorsArray.forEach((advisor: Advisor): void => {
                const currentAdvisor: CurrentAdvisor = { advisorSelected: advisor, disabled: false };
                this.currentAdvisors.push(currentAdvisor);
            });
        }
    }

    private setAdvisorsDropdown(): void {
        let addAdvisor: boolean;
        this.currentAdvisors.length === 0 ? addAdvisor = true : addAdvisor = false;
        this.getAdvisors(addAdvisor);
    }

    private getAdvisors(addAdvisor: boolean = true): void {
        if (this.userProfile && this.userProfile.entitlements
            && this.userProfile.entitlements.userLevelId === UserLevelEntitlement.PO
            || this.userProfile.entitlements.userLevelId === UserLevelEntitlement.Instance) {
            // Note: this is for instance/sales users and using the firm attached to it to retrieve advisors
            this.spinnerService.start();
            this.firmAdvisorService.getFirmAdvisorsByFirmIdAndUserId(this.userProfile.firm.swpFirmId, this.userProfile.entityId).subscribe(
                (advisors: Advisor[]) => {
                    if (advisors) {
                        const firmAdvisors = this.userProfile.firm.advisors;
                        const entitledAdvisorsInFirmAdvisors = _.intersectionBy(firmAdvisors, advisors, 'entityId');
                        entitledAdvisorsInFirmAdvisors.map(
                            (advisor: Advisor) => {
                                if (
                                    !this.advisors.some((currentAdvisor) => currentAdvisor.entityId === advisor.entityId)
                                ) {
                                    this.advisors.push(
                                        new AdvisorFactory().createAdvisor(advisor.userId, advisor.entityId, advisor.advisorName)
                                    );
                                }
                            }
                        );
                        this.advisors.sort((a, b) => a.name.localeCompare(b.name));
                        (addAdvisor) ? this.addAdvisor() : this.disableButtons(false);
                        this.setPrimaryAdvisor();
                        if (this.propertyService.exists(() => this.currentAdvisors[0].advisorSelected.entityId)) {
                            const currentAdvisors: Advisor[] = [];
                            this.currentAdvisors.forEach((currentAdvisor: CurrentAdvisor) => {
                                currentAdvisors.push(currentAdvisor.advisorSelected);
                            });
                            this.advisorSelected(currentAdvisors);
                        }
                    }
                },
                (error) => {
                    this.genericErrorService.setGenericError({ code: '500', description: error });
                    this.spinnerService.stop();
                },
                () => {
                    this.spinnerService.stop();
                }
            );
        } else {
            this.spinnerService.start();
            this.firmAdvisorService.getFirmDataByEntityId(this.userProfile.entityId).subscribe(
                (userProfile) => {
                    if (userProfile) {
                        const entitledAdvisors = userProfile.entitlements.advisors;
                        const firmAdvisors = userProfile.firm.advisors;

                        if (entitledAdvisors && firmAdvisors) {
                            const entitledAdvisorsInFirmAdvisors = _.intersectionBy(firmAdvisors, entitledAdvisors, 'entityId');
                            entitledAdvisorsInFirmAdvisors.map(
                                (advisor: User) => {
                                    if (
                                        !this.advisors.some((currentAdvisor) => currentAdvisor.entityId === advisor.entityId)
                                    ) {
                                        this.advisors.push(
                                            new AdvisorFactory().createAdvisor(advisor.userId, advisor.entityId, advisor.advisorName)
                                        );
                                    }
                                }
                            );
                            (addAdvisor) ? this.addAdvisor() : this.disableButtons(false);
                            this.advisors.sort((a: Advisor, b: Advisor): number => a.name.localeCompare(b.name));
                            this.setPrimaryAdvisor();
                            if (this.propertyService.exists(() => this.currentAdvisors[0].advisorSelected.entityId)) {
                                const currentAdvisors: Advisor[] = [];
                                this.currentAdvisors.forEach((currentAdvisor: CurrentAdvisor) => {
                                    currentAdvisors.push(currentAdvisor.advisorSelected);
                                });
                                this.advisorSelected(currentAdvisors);
                            }

                        }
                    }
                },
                (error) => {
                    this.genericErrorService.setGenericError({ code: '500', description: error });
                    this.spinnerService.stop();
                },
                () => {
                    this.spinnerService.stop();
                }
            );
        }
    }

    private setPrimaryAdvisor(): void {
        if (
            this.currentAdvisors &&
            this.currentAdvisors.length === 1 &&
            !this.currentAdvisors[0].advisorSelected.entityId &&
            this.advisors &&
            this.advisors.length
        ) {
            // NOTE: when instance user we need to default to primary advisor from impersonated firm
            // this is first entry in this.userProfileService.getEtitledAdvisorsEntityIds[0]
            if (
                this.userProfile && this.userProfile.entitlements
                && (this.userProfile.entitlements.userLevelId === UserLevelEntitlement.PO
                    || this.userProfile.entitlements.userLevelId === UserLevelEntitlement.Instance)
            ) {
                if (this.advisors.length === 1) {
                    this.currentAdvisors[0].advisorSelected = this?.advisors.find(
                        (advisor: Advisor) => advisor.entityId === this?.userProfileService?.getEtitledAdvisorsEntityIds()[0]
                    );
                }
            } else {
                let selectedAdvisor: Advisor = this.advisors.find(
                    (advisor: Advisor) => advisor.entityId === this.userProfile.entityId
                );
                if (!(this.propertyService.exists(() => selectedAdvisor)) && this.advisors.length === 1) {
                    selectedAdvisor = this.advisors[0];
                }
                if (this.propertyService.exists(() => selectedAdvisor)) {
                    this.currentAdvisors[0].advisorSelected = selectedAdvisor;
                }

            }
        }

        if (
            this.currentAdvisors &&
            this.currentAdvisors.length === 1 &&
            this.currentAdvisors[0].advisorSelected && this.currentAdvisors[0].advisorSelected.entityId
        ) {
            this.currentAdvisors[0].advisorSelected.role = {
                id: AdvisorRole.Primary,
                description: advisorLabel.get(AdvisorRole.Primary)
            };
        }
    }

    // Advisors
    public addAdvisor(advisor?: Advisor, disabled?: boolean, index?: number): void {
        if (!advisor) {
            advisor = new AdvisorFactory().createAdvisor();
        }
        if (advisor.role && !advisor.role.id) {
            advisor.role.id = (this.currentAdvisors.length > 0) ? AdvisorRole.Additional : AdvisorRole.Primary;
            this.currentAdvisors.push(new AdvisorFactory().createCurrentAdvisor(advisor, disabled));
        } else {
            if (advisor.role.id === AdvisorRole.Primary) {
                this.currentAdvisors.push(new AdvisorFactory().createCurrentAdvisor(_.cloneDeep(advisor), disabled));
            }
            this.holdExistAdvisors.push(_.cloneDeep(advisor));
            if (this.currentAdvisors.length > 0) {
                this.holdExistAdvisors.map((existAdvisor: Advisor) => {
                    if (!this.currentAdvisors.some((value) => value.advisorSelected.entityId === existAdvisor.entityId)) {
                        existAdvisor.role.id = AdvisorRole.Additional;
                        this.currentAdvisors.push(new AdvisorFactory().createCurrentAdvisor(_.cloneDeep(existAdvisor), disabled));
                    }
                });

            }
        }
        this.disableButtons(true);
    }

    public advisorSelected(advisors: Advisor[]): void {
        this.advisorSelectedIds = [];
        this.holdExistAdvisors = [];
        this.currentAdvisors = [];
        advisors.forEach((advisor: Advisor) => {
            const currentAdvisor: CurrentAdvisor = { advisorSelected: advisor, disabled: false };
            this.currentAdvisors.push(currentAdvisor);
        });
        this.currentAdvisors.map(
            (currentAdvisor: CurrentAdvisor, index) => {
                if (index === 0) {
                    currentAdvisor.advisorSelected.role = {
                        id: AdvisorRole.Primary,
                        description: advisorLabel.get(AdvisorRole.Primary)
                    };
                } else {
                    currentAdvisor.advisorSelected.role = {
                        id: AdvisorRole.Additional,
                        description: advisorLabel.get(AdvisorRole.Additional)
                    };
                }
                this.advisorSelectedIds.push(Number(currentAdvisor.advisorSelected.entityId));
            }
        );

        this.hasAdvisorsAvailable = (
            this.advisorSelectedIds &&
            this.advisorSelectedIds.length < this.advisors.length
        );
        this.disableButtons(false);
        this.emitCurrentAdvisors();

    }

    private disableButtons(disable: boolean): void {
        // If enabling, check to make sure the last advisor is selected, else disable the button.
        disable === false && this.propertyService.exists(() => this.currentAdvisors[this.currentAdvisors.length - 1].advisorSelected.id) ?
            disable = false : disable = true;
        this.disableAddAdvisor = disable || !this.hasAdvisorsAvailable;
        this.checkMaxAdvisors();
    }

    private checkMaxAdvisors(): void {
        this.currentAdvisors.length >= this.maxAdvisorLength ?
            this.maxAdvisors = true : this.maxAdvisors = false;
    }

    private emitCurrentAdvisors(): void {
        this.onAdvisorChange.emit(this.currentAdvisors);
    }
}
