/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { AdvisorRole } from '@CarModels/enums';
import { PropertyService } from '@CarServices/system/property.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpUtil } from '@sei/common-swp-components-lib-ux';
import { Portfolio, PortfolioHolding } from '@sei/ias-applications-lib-ux';
import BigNumber from 'bignumber.js';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, share } from 'rxjs/operators';
import { DashboardRequest, ProposalView } from '../model/dashboard';
import { ContactTypes, OwnerRole, OwnerRoleDescription, ProposalStatusId } from '../model/enums';
import { ProposalFactory } from '../model/factory/proposal-factory';
import { AsyncAddUpdateParams, DeleteSectionRequestParams, ProposalSection } from '../model/presentation';
import { Account, AccountOwner, Advisor, Client, Goal, MinDataProposal, Proposal, Scenario, Strategy, WipCheckList, elasticSearchAccountOwnerData } from '../model/proposal';
import { SeiPayload } from '../model/sei-payload';
import { AccountTypeOptionsService } from './account-type-options.service';
import { GoalService } from './goal.service';
import { GenericErrorService } from './system/generic-error.service';
import { GlobalService } from './system/global.service';

@Injectable({
    providedIn: 'root'
})
export class ProposalService {
    private proposal: Proposal;
    private proposalSource: BehaviorSubject<Proposal>;
    private _proposals: BehaviorSubject<Proposal[]>;
    private _proposalsView: BehaviorSubject<ProposalView[]>;
    private updatedSectionName: string;

    private dataStore: {
        proposals: Proposal[];
        proposalView: ProposalView[];
    };

    public proposals: Observable<Proposal[]>;
    public dashBoardView: Observable<ProposalView[]>;
    public currentProposal: Observable<Proposal>;
    public proposalBackup: Proposal;
    public isExistingAccountsPresentOnProposal: boolean = false;

    constructor(
        private readonly http: HttpClient,
        private readonly genericErrorService: GenericErrorService,
        private readonly goalService: GoalService,
        protected readonly carGlobal: GlobalService,
        private readonly httpUtil: HttpUtil,
        private accountTypeOptionsService: AccountTypeOptionsService,
        private readonly propertyService: PropertyService
    ) {
        // Implement as a ngrx store in the future
        this.dataStore = { proposals: [], proposalView: [] };

        this._proposals = new BehaviorSubject([]) as BehaviorSubject<Proposal[]>;
        this._proposalsView = new BehaviorSubject([]) as BehaviorSubject<ProposalView[]>;
        this.proposalSource = new BehaviorSubject<Proposal>(this.proposal);

        this.proposals = this._proposals.asObservable();
        this.dashBoardView = this._proposalsView.asObservable();
        this.currentProposal = this.proposalSource.asObservable();
    }

    private proposalApiUri(proposalId: number): string {
        return this.carGlobal.configService.routeFormatter(
            this.carGlobal.configService.getEndPoint('proposal'),
            [
                {
                    key: 'proposalId',
                    value: proposalId
                }
            ]
        );
    }

    private getProposalAccountOwner(proposal: Proposal): Proposal {
        // Note: need to match owner contract.
        proposal.scenarios.forEach((scenario) => {
            scenario.accounts.forEach((account, accountIndex) => {
                const accountTypeOptions = this.accountTypeOptionsService.getOptions(account.type.id);
                account.owners.forEach((owner, ownerIndex) => {
                    if (accountTypeOptions.accountContactTypes[0] === ContactTypes.Individual) {
                        const ownerClient = proposal.clients.find(
                            (client) =>
                            ((owner.entityId && owner.entityId === client.entityId) ||
                                ((!owner.entityId || !client.entityId) &&
                                    this.getClientName(client) === this.getAccountOwnerFullName(owner))));
                        if (ownerClient) {
                            owner.entitySuffix = ownerClient.entitySuffix
                                ? ownerClient.entitySuffix
                                : null;
                            if (ownerClient.isNewClient) {
                                owner.isNewClient = false;
                            }
                            owner.externalVendor = ownerClient.externalVendor;
                            owner.firstName = ownerClient.firstName;
                            owner.lastName = ownerClient.lastName;
                            owner.middleInitial = ownerClient.middleInitial;
                            owner.swpEntityId = ownerClient.swpEntityId;
                            owner.entityId = ownerClient.entityId;
                            owner.entityType = ownerClient.entityType;
                            owner.isIndividual = ownerClient.isIndividual;
                        } else {
                            account.owners.splice(ownerIndex, 1);
                        }

                    } else if (accountTypeOptions.accountContactTypes[0] === ContactTypes.Organization) {
                        const organizationClient = proposal.clients.find((client) => (owner.organizationName === client.organizationName));
                        if (organizationClient) {
                            owner.isNewClient = false;
                        } else {
                            account.owners.splice(ownerIndex, 1);
                        }
                    }
                });
            }),
                scenario.accounts = scenario.accounts.filter((account) => account.owners.length >= 1);
        });
        proposal.clients.forEach((client) => {
            if (client.contactType.id === ContactTypes.Organization) {
                client.isNewClient = false;
            }
        });
        return proposal;
    }

    private transformGoalsJsonDatesToObject(proposal: Proposal): void {
        proposal.scenarios.forEach((scenario: Scenario) =>
            scenario.goals = this.goalService.transformGoalsJsonDatesToObject(scenario.goals)
        );
    }

    public getDashboardProposals(
        firmId: number,
        onBehalfOfUserId: number,
        advisors: number[],
        showActive: boolean = true
    ) {
        let dashBoardViewEndPoint = 'retrieveActiveProposalDashboard';
        if (!showActive) {
            dashBoardViewEndPoint = 'retrieveArchiveProposalDashboard';
        }
        const apiUrl = this.carGlobal.configService.routeFormatter(
            this.carGlobal.configService.getEndPoint(dashBoardViewEndPoint)
        );
        if (this.carGlobal.isMockDataMode()) {
            const mockURLjson: string = 'assets/mock-data/proposal-dashboard-mock.json';
            const responseMock = this.httpUtil.doMockPostStronglyTyped<SeiPayload>(mockURLjson, 1000);
            return responseMock.pipe(
                share(),
                map((result) => {
                    result.data.forEach((data: ProposalView) => {
                        data.advisorName = data.advisors
                            .map((advisor) => advisor.name)
                            .join(', ');
                    });
                    this.dataStore.proposalView = result.data;
                    this._proposalsView.next(
                        Object.assign({}, this.dataStore).proposalView
                    );
                }));
        } else {
            const request: DashboardRequest =
            {
                onBehalfOfUserId,
                advisorList: advisors,
                firmId
            };
            return this.http.post<SeiPayload>(apiUrl, request).pipe(
                share(),
                map((response) => {
                    if (response && response.data) {
                        if (response.error && response.error.length > 0) {
                            this.genericErrorService.setGenericError({
                                code: response.error.code,
                                description: response.error.message
                            });
                        }

                        response.data.forEach((data: ProposalView) => {
                            data.advisorName = data.advisors
                                .map((advisor) => advisor.name)
                                .join(', ');
                        });

                        this.dataStore.proposalView = response.data;
                        this._proposalsView.next(
                            Object.assign({}, this.dataStore).proposalView
                        );
                    }
                })
            );
        }
    }

    getProposal(proposalId: number): Observable<Proposal> {
        const apiUrl = this.carGlobal.configService.routeFormatter(
            this.carGlobal.configService.getEndPoint('proposal'),
            [
                {
                    key: 'proposalId',
                    value: proposalId
                }
            ]
        );
        if (this.carGlobal.isMockDataMode()) {
            const mockURL: string = 'assets/mock-data/proposals.json';
            return this.httpUtil.doMockPostStronglyTyped<Proposal>(mockURL, 1000);
        }
        return this.http
            .get<Proposal>(apiUrl)
            .pipe(catchError(this.handleError));
    }

    public getProposalById(proposalId: number): Observable<Proposal> {
        if (this.carGlobal.isMockDataMode()) {
            const mockURL: string = 'assets/mock-data/proposals.json';
            return this.httpUtil.doMockPostStronglyTyped<Proposal>(mockURL, 1000).pipe(
                share(),
                map((response) => {
                    if (
                        response &&
                        response['data'][0] &&
                        response['data'][0].proposals[0]
                    ) {
                        this.transformGoalsJsonDatesToObject(response['data'][0].proposals[0]);
                        return response['data'][0].proposals[0];
                    }
                }),
                catchError(this.handleError)
            );
        }
        return this.http.get<Proposal>(this.proposalApiUri(proposalId)).pipe(
            share(),
            map((response) => {
                if (
                    response &&
                    response['data'][0] &&
                    response['data'][0].proposals[0]
                ) {
                    this.transformGoalsJsonDatesToObject(response['data'][0].proposals[0]);
                    return response['data'][0].proposals[0];
                }
            }),
            catchError(this.handleError)
        );
    }

    public getMinDataProposalById(proposalId: number): Observable<MinDataProposal> {
        const apiUrl: string = this.carGlobal.configService.routeFormatter(
            this.carGlobal.configService.getEndPoint('retrieveMinDataProposal'),
            [
                {
                    key: 'proposalId',
                    value: proposalId
                }
            ]
        );
        return this.http.post<MinDataProposal>(apiUrl, undefined).pipe(
            share(),
            map((response) => {
                return response;
            }),
            catchError(this.handleError)
        );
    }

    public createFactoryProposal(): Proposal {
        return new ProposalFactory().createObject();
    }

    public changedProposal(proposal: Proposal) {
        this.proposalSource.next(proposal);
        if (proposal && proposal.scenarios[0]) {
            this.checkIfScenarioHasAnyExistingAccounts(proposal);
        }
    }

    public getCurrentProposal(): Proposal {
        return this.proposalSource.value;
    }

    public getProposalChangedEvent(): BehaviorSubject<Proposal> {
        return this.proposalSource;
    }

    public updateProposalWipCheckList(wipCheckList: WipCheckList[]): Observable<WipCheckList[]> {
        const apiUrl = this.carGlobal.configService.routeFormatter(
            this.carGlobal.configService.getEndPoint('wipChecklist')
        );

        const json = {
            data: [
                {
                    wipCheckList
                }
            ]
        };
        if (this.carGlobal.isMockDataMode()) {
            const mockURL: string = 'assets/mock-data/wip-checklist.json';
            return this.httpUtil.doMockPostStronglyTyped<WipCheckList>(mockURL, 1000).pipe(
                share(),
                map((response) => {

                    return response['data'][0].wipCheckList;

                })
            );
        }
        return this.http.put(apiUrl, json).pipe(
            share(),
            map((response) => {
                if (
                    response && response['data'][0]
                ) {
                    return response['data'][0].wipCheckList;
                }
            }),
            catchError(this.handleError)
        );
    }

    public proposalUpsert(proposal: Proposal): Observable<Proposal> {

        proposal = this.getProposalAccountOwner(proposal);
        const json = {
            data: [
                {
                    proposals: [proposal]
                }
            ]
        };

        if (proposal.id === 0) {
            const apiUrl = this.carGlobal.configService.routeFormatter(
                this.carGlobal.configService.getEndPoint('proposals')
            );
            if (this.carGlobal.isMockDataMode()) {
                const mockURL: string = 'assets/mock-data/new-proposal.json';
                return this.httpUtil.doMockPostStronglyTyped<SeiPayload>(mockURL, 1000).pipe(
                    map((response: SeiPayload) => {
                        if (response) {
                            return response.data[0].proposals[0];
                        }
                    })
                );
            }
            return this.http.post<SeiPayload>(apiUrl, json).pipe(
                map((response: SeiPayload) => {
                    if (response) {
                        return response.data[0].proposals[0];
                    }
                }),
                catchError(this.handleError)
            );
        } else {
            const apiUrl = this.carGlobal.configService.routeFormatter(
                this.carGlobal.configService.getEndPoint('updateProposal'),
                [
                    {
                        key: 'proposalId',
                        value: proposal.id
                    }
                ]
            );
            if (this.carGlobal.isMockDataMode()) {
                const mockURL: string = 'assets/mock-data/new-proposal.json';
                return this.httpUtil.doMockPostStronglyTyped<SeiPayload>(mockURL, 1000).pipe(
                    map((response: SeiPayload) => {
                        if (response) {
                            return response.data[0].proposals[0];
                        }
                    })
                );
            }
            // NOTE: confirm Endpoint once API is ready.
            return this.http.put<SeiPayload>(apiUrl, json).pipe(
                map((response: SeiPayload) => {
                    if (response) {
                        // NOTE: the wip checklist is not coming back on this for some reason
                        // we need to copy the new clients from response to original passed in proposal
                        // this.changedProposal(response.data[0].proposals[0]);
                        return response.data[0].proposals[0];
                    }
                }),
                catchError(this.handleError)
            );
        }
    }

    public deleteProposalScenario(
        proposalId: number,
        scenarioId: number
    ): Observable<SeiPayload> {
        delete this.proposal.scenarios[0];
        return of({ data: [{ proposal: this.proposal }] });
    }

    public updateProposalScenario(proposal: Proposal, scenarioId: number): Observable<Proposal> {
        proposal = this.getProposalAccountOwner(proposal);

        const apiUrl = this.carGlobal.configService.routeFormatter(
            this.carGlobal.configService.getEndPoint('updateProposalScenario'),
            [
                {
                    key: 'proposalId',
                    value: proposal.id
                },
                {
                    key: 'scenarioId',
                    value: scenarioId
                }
            ]
        );

        // NOTE: this work will be in next sprint 3.2 currently BE is throwing 500
        // this.updateProposalWipCheckList(proposal.wipCheckList);
        // return this.http.put<SeiPayload>(apiUrl, proposal);
        return this.http.put<SeiPayload>(apiUrl, proposal).pipe(
            map((response: SeiPayload) => {
                if (response) {
                    // this.changedProposal(response.data[0].proposals[0]);
                    return response.data[0].proposals[0];
                }
            }),
            catchError(this.handleError)
        );
    }

    public deleteProposal(proposalId: number) {
        const apiUrl = this.carGlobal.configService.routeFormatter(
            this.carGlobal.configService.getEndPoint('proposal'),
            [
                {
                    key: 'proposalId',
                    value: proposalId
                }
            ]
        );

        const deleteProposal = this.http.delete(apiUrl);
        return deleteProposal;
    }

    public archiveProposal(proposalId: number) {
        const apiUrl = this.carGlobal.configService.routeFormatter(
            this.carGlobal.configService.getEndPoint('updateStatusArchivedProposalDashboard'),
            [
                {
                    key: 'proposalId',
                    value: proposalId
                }
            ]
        );

        return this.http.put(apiUrl, '');
    }

    public reactivateProposal(proposalId: number) {
        const apiUrl = this.carGlobal.configService.routeFormatter(
            this.carGlobal.configService.getEndPoint('reactivateProposalDashboard'),
            [
                {
                    key: 'proposalId',
                    value: proposalId
                }
            ]
        );

        return this.http.put(apiUrl, '');
    }

    public scenarioExists(scenarioName: string): boolean {
        return this.proposal.scenarios.every(
            (scenario) => scenario.name === scenarioName
        );
    }

    public addMockElasticSearch(object: {
        value: string;
        label: string;
        contactTypeId: ContactTypes;
        isNewClient: boolean;
    }) {
        // Note: This will be refactored once we have real elastic search service..
        // where client added in proposal will be merged with elastic search data
        if (
            !elasticSearchAccountOwnerData.find(
                (elastic) => elastic.value === object.value
            )
        ) {
            elasticSearchAccountOwnerData.unshift(object);
        }
    }

    public clearElasticSearchAccountOwner(): void {
        elasticSearchAccountOwnerData.splice(
            0,
            elasticSearchAccountOwnerData.length
        );
    }

    public addClientsToElasticSearch(clients: Client[]): void {
        this.clearElasticSearchAccountOwner();
        clients.forEach((client) => {
            let labelText = `${client.firstName}`;
            if (client.middleInitial) {
                labelText = `${labelText} ${client.middleInitial}`;
            }
            if (client.lastName) {
                labelText = `${labelText} ${client.lastName}`;
            }
            if (client.entitySuffix && client.entitySuffix.suffixName) {
                labelText = `${labelText} ${client.entitySuffix.suffixName}`;
            }
            if (!client.isIndividual) {
                labelText = client.organizationName;
            }

            client.labelName = labelText;

            this.addMockElasticSearch({
                value: labelText,
                label: labelText,
                contactTypeId: client.contactType.id,
                isNewClient: client.isNewClient
            });
        });
    }


    public editProposalName(
        proposalId: number,
        proposalName: string
    ): Observable<string> {
        const apiUrl = this.carGlobal.configService.routeFormatter(
            this.carGlobal.configService.getEndPoint('updateProposalName'),
            [
                {
                    key: 'proposalId',
                    value: proposalId
                }
            ]
        );

        const json = {
            data: [
                {
                    name: proposalName
                }
            ]
        };
        return this.http.post<SeiPayload>(apiUrl, json).pipe(
            map(() => {
                return proposalName;
            }),
            catchError(this.handleError)
        );
    }

    public implementProposal(proposalId: number) {
        const apiUrl = this.carGlobal.configService.routeFormatter(
            this.carGlobal.configService.getEndPoint(
                'implementProposalDashboard'
            ),
            [
                {
                    key: 'proposalId',
                    value: proposalId
                }
            ]
        );

        return this.http.put(apiUrl, '');
    }


    private handleError(err) {
        return throwError(err.error);
    }

    public getScenarioByAccountId(proposal: Proposal, accountId: number): Scenario {
        let currentScenario: Scenario;
        if (proposal) {
            proposal.scenarios.forEach((scenario: Scenario) => {
                if (scenario.accounts.find((account: Account) => account.id === accountId)) {
                    currentScenario = scenario;
                    return;
                }
            });
        }
        return currentScenario;
    }

    public getAccount(proposal: Proposal, accountId: number): Account {
        let currentAccount: Account;
        if (proposal) {
            proposal.scenarios.forEach((scenario: Scenario) => {
                currentAccount =
                    scenario.accounts.find((account: Account) => account.id === accountId);
                if (currentAccount) {
                    return;
                }
            });
        }
        return currentAccount;
    }

    private isGoalWithDuplicatedNamesOrEmptyNames(goals: Goal[]): boolean {
        let isValidToSave: boolean = !goals.some((goal: Goal) => !goal.name);
        isValidToSave = isValidToSave &&
            goals.every((goal: Goal) => {
                const filterByName: Goal[] =
                    goals.filter((filterGoal: Goal) => filterGoal.name.toLowerCase() === goal.name.toLowerCase());
                return !(filterByName && filterByName.length > 1);
            });
        return isValidToSave;
    }

    public validateProposalGoals(goals?: Goal[]): boolean {
        const isValidGoal: boolean =
            !goals ||
            goals.length < 1 ||
            this.isGoalWithDuplicatedNamesOrEmptyNames(goals);
        return isValidGoal;

    }

    public validateProposalAccount(accountData: Account): boolean {
        if (!accountData) {
            return false;
        }
        const accountTypeOptions = this.accountTypeOptionsService.getOptions(
            accountData.type.id
        );
        let allOwnersValid = true;
        accountData.owners.forEach((owner) => {
            if (accountTypeOptions.accountContactTypes[0] === 1) {
                if (!(owner.firstName && owner.firstName.length >= 1 && owner.lastName && owner.lastName.length >= 1)) {
                    allOwnersValid = false;
                }
            } else {
                if (!(owner.organizationName && owner.organizationName.length > 1)) {
                    allOwnersValid = false;
                }
            }

        });

        return accountData.owners.length > 0 &&
            allOwnersValid &&
            accountData.balance > 0 &&
            accountData.advisors.length > 0;
    }

    public validateProposalFirstAccount(accountData: Account): boolean {
        if (!accountData) {
            return false;
        }

        return accountData.owners.length > 0 &&
            ((accountData.owners[0].firstName &&
                accountData.owners[0].firstName.length > 1 &&
                accountData.owners[0].lastName &&
                accountData.owners[0].lastName.length > 1) ||
                (accountData.owners.length > 0 &&
                    accountData.owners[0].organizationName &&
                    accountData.owners[0].organizationName.length > 1)) &&
            accountData.balance > 0 &&
            accountData.advisors.length > 0;
    }

    public getClientName(client: Client): string {
        let clientName: string = '';
        if (client) {
            if (client.isIndividual) {
                if (client.middleInitial && client.middleInitial !== '') {
                    clientName = (
                        client.firstName +
                        ' ' +
                        client.middleInitial +
                        ' ' +
                        client.lastName
                    );
                } else {
                    clientName = client.firstName + ' ' + client.lastName;
                }
                if (client.entitySuffix && client.entitySuffix.suffixId > 0) {
                    clientName = clientName + ' ' + client.entitySuffix.suffixName;
                }
            } else {
                clientName = client.organizationName;
            }
        }
        return clientName;
    }

    // Owners full name
    public getAccountOwnerFullName(accountContact: AccountOwner): string {
        let accountOwnerFullName: string = '';
        if (accountContact) {
            if (accountContact.contactType.id === ContactTypes.Individual) {
                if (
                    accountContact.middleInitial &&
                    accountContact.middleInitial !== ''
                ) {
                    accountOwnerFullName = accountContact.firstName +
                        ' ' +
                        accountContact.middleInitial +
                        ' ' +
                        accountContact.lastName;
                } else {
                    accountOwnerFullName = accountContact.firstName + ' ' + accountContact.lastName;
                }
                if (accountContact.entitySuffix && accountContact.entitySuffix.suffixId > 0) {
                    accountOwnerFullName = accountOwnerFullName + ' ' + accountContact.entitySuffix.suffixName;
                }
            } else {
                accountOwnerFullName = accountContact.organizationName;
            }
        }
        return accountOwnerFullName;
    }

    public removeInvalidClientFromProposalClients(proposal: Proposal): void {
        const updatedClientList: Client[] = [];
        proposal.clients.forEach((proposalClient: Client) => {
            const validName: boolean = this.validateOwner(proposalClient);
            const isClientUnique: boolean = !this.isClientAlreadyAdded(proposalClient, updatedClientList);
            if (isClientUnique && validName) {
                updatedClientList.push(proposalClient);
            }
        });
        proposal.clients = updatedClientList;
    }

    public isClientAlreadyAdded(client: Client, clientList: Client[]): boolean {
        const clientResult: Client = clientList.find((proposalClient) =>
            (proposalClient.entityId && proposalClient.entityId === client.entityId) ||
            (!proposalClient.entityId && client.labelName === proposalClient.labelName && client.contactId === ContactTypes.Individual)
        );
        return this.propertyService.exists(() => (clientResult));
    }

    private isClientStillanValidOwnerById(client: Client, proposal: Proposal): boolean {
        let clientIsAccountOwner: boolean = false;
        proposal.scenarios.some((scenario) => {
            scenario.accounts.some((account) => {
                account.owners.some((owner) => {
                    if (owner.entityId && owner.entityId === client.entityId) {
                        clientIsAccountOwner = true;
                    }
                    return clientIsAccountOwner;
                });
                return clientIsAccountOwner;
            });
            return clientIsAccountOwner;
        });
        return clientIsAccountOwner;
    }

    private isClientStillanValidOwnerByName(client: Client, proposal: Proposal): boolean {
        let clientIsAccountOwner: boolean = false;
        proposal.scenarios.some((scenario) => {
            scenario.accounts.some((account) => {
                account.owners.some((owner) => {
                    if ((this.getClientName(client) === this.getAccountOwnerFullName(owner)) ||
                        (client.firstName != null && client.lastName != null)) {
                        clientIsAccountOwner = true;
                    }
                    return clientIsAccountOwner;
                });
                return clientIsAccountOwner;
            });
            return clientIsAccountOwner;
        });
        return clientIsAccountOwner;
    }

    public deleteSection(deletedSection: ProposalSection, deleteRequestParams: DeleteSectionRequestParams) {
        const apiUrl = this.carGlobal.configService.routeFormatter(
            this.carGlobal.configService.getEndPoint(
                'deleteAdvisorSectionContent'
            ),
            [
                {
                    key: 'proposalId',
                    value: deleteRequestParams.proposalId
                },
                {
                    key: 'presentationType',
                    value: deleteRequestParams.presentationType
                },
                {
                    key: 'sectionName',
                    value: deleteRequestParams.sectionName
                },
                {
                    key: 'subSectionName',
                    value: encodeURIComponent(deleteRequestParams.subSectionName)
                }
            ]
        );
        return this.http.request('delete', apiUrl, { body: deletedSection });
    }

    public asynchronousAddUpdate(asyncAddUpdateParams: AsyncAddUpdateParams) {
        const apiUrl = this.carGlobal.configService.routeFormatter(
            this.carGlobal.configService.getEndPoint(
                'asynchronousAddUpdate'
            ),
            [
                {
                    key: 'proposalId',
                    value: asyncAddUpdateParams.proposalId
                },
                {
                    key: 'documentId',
                    value: asyncAddUpdateParams.documentId
                },
                {
                    key: 'subSectionName',
                    value: asyncAddUpdateParams.subSectionName
                },
                {
                    key: 'operationType',
                    value: asyncAddUpdateParams.operationType
                }
            ]
        );
        return this.http.request('post', apiUrl);
    }

    public setUpdatedSectionName(updatedSectionName: string): void {
        this.updatedSectionName = updatedSectionName;
    }

    public getUpdatedSectionName(): string {
        return this.updatedSectionName;
    }

    public validateProposal(proposal: Proposal, scenarioId: number): boolean {
        const scenarioIndex: number = proposal?.scenarios.findIndex((scenario: Scenario) => scenario.id === Number(scenarioId));
        const proposalValid: boolean = proposal?.scenarios[scenarioIndex]?.accounts &&
            proposal.scenarios[0].accounts.every((account: Account) => this.validateAccount(account));

        return proposalValid;
    }

    public validateAccount(account: Account): boolean {
        const clientsValid: boolean = account &&
            this.validateAllOwners(account.owners);
        const hasBalance: boolean = account.balance && account.balance > 0;
        const hasAccountType: boolean = !!account.type;
        const hasInvestmentProgram: boolean = !!account.investmentProgramId;

        const accountValid: boolean = clientsValid && hasBalance && hasAccountType && hasInvestmentProgram;
        return accountValid;
    }

    public validateAllOwners(clients: Client[]): boolean {
        return clients && clients.length > 0 && clients.every((client: Client) => this.validateOwner(client));
    }

    public validateOwner(client: Client): boolean {
        const isIndividual: boolean = client?.contactType?.id === ContactTypes.Individual;
        const lastNameValid: boolean = client.lastName && client.lastName.length > 0;
        if (isIndividual) {
            const firstNameValid: boolean = client.firstName !== undefined && client.firstName.length > 0;
            return lastNameValid && firstNameValid && (client.isOptionalFieldsValid === undefined || client.isOptionalFieldsValid === true);
        } else {
            const orgNameValid: boolean = client.organizationName && client.organizationName.length > 0;
            return lastNameValid && orgNameValid && (client.isOptionalFieldsValid === undefined || client.isOptionalFieldsValid === true);
        }
    }

    public fixProposalForSave(proposal: Proposal): void {
        this.removeInvalidClientFromProposalClients(proposal);
        proposal.clients.forEach((client: Client) => {
            if (client.isNewClient) {
                client.contactId = 0;
                client.id = 0;
            }
        });
        proposal.scenarios.forEach((scenario: Scenario) => {
            scenario.accounts.forEach((account: Account) => {
                account.owners.forEach((client: AccountOwner) => {
                    if (client.isNewClient) {
                        client.contactId = 0;
                        client.id = 0;
                    }
                    client.ownerRole = { id: OwnerRole.Primary, description: OwnerRoleDescription.Primary };
                });
                if (account.isNewAccount) {
                    account.id = 0;
                }
            });
        });
    }

    public getNumberOfAccountsFromProposal(scenarios: Scenario[]): number {
        let totalNumberOfAccounts = 0;
        scenarios?.forEach((scenario: Scenario) => {
            if (scenario.accounts && scenario.accounts.length > 0) {
                totalNumberOfAccounts += scenario.accounts.length;
            }
        });
        return totalNumberOfAccounts;
    }


    public getProposalPrimaryAdvisor(proposal: Proposal): Advisor {
        let primaryAdvisor: Advisor;
        primaryAdvisor = proposal?.advisors?.find((advisor: Advisor): boolean => advisor.role.id === AdvisorRole.PrimaryId);
        // Older proposals might not have a primary advisor at the proposal level
        // If there is no primary advisor at proposal level, look at the first account
        if (!primaryAdvisor) {
            primaryAdvisor = proposal?.scenarios[0]?.accounts[0]?.advisors?.find((advisor: Advisor): boolean =>
                advisor.role.id === AdvisorRole.PrimaryId);
        }
        return primaryAdvisor;
    }

    public isProposalChecklistLocked(): boolean {
        const currentProposal: Proposal = this.getCurrentProposal();
        return currentProposal?.statusId === ProposalStatusId.Archived || currentProposal?.statusId === ProposalStatusId.Implemented;
    }

    public getTotalBalanceOfProposedAccounts(currentScenario: Scenario, isNonModelFeatureEnabled: boolean): number {
        const initialValue: number = 0;
        let totalBalance: number = 0;

        if (
            currentScenario &&
            currentScenario.accounts &&
            currentScenario.accounts.length > 0
        ) {
            currentScenario.accounts.filter((account: Account) => !account.currentAccountId).forEach((account: Account) => {
                if (account?.strategies?.length > 0) {
                    account.strategies.forEach((strategy: Strategy) => {
                        const amount: number = Number(strategy.amount) ?? 0;
                        totalBalance += amount;
                    });
                }
            });
        }

        if (isNonModelFeatureEnabled) {
            currentScenario.accounts.filter((account: Account) => !account.currentAccountId).forEach((account: Account) => {
                if (account.portfolios && account.portfolios.length > 0) {
                    account.portfolios.forEach((portfolio: Portfolio) => {
                        if (portfolio.proposedPortfolioHoldingList && portfolio.proposedPortfolioHoldingList.length > 0) {
                            totalBalance += this.calculateValueOfNonModelPortfolio(portfolio);
                        }
                    });
                }
            });
        }

        return Number(totalBalance.toFixed(2));
    }

    public getTotalBalanceOfAllAccounts(currentScenario: Scenario, isNonModelFeatureEnabled: boolean): number {
        let totalBalance: number = 0;

        if (
            currentScenario &&
            currentScenario.accounts &&
            currentScenario.accounts.length > 0
        ) {
            currentScenario.accounts.forEach((account: Account) => {
                if (account?.strategies?.length > 0) {
                    account.strategies.forEach((strategy: Strategy) => {
                        const amount: number = Number(strategy.amount) ?? 0;
                        totalBalance += amount;
                    });
                }
            });
        }

        if (isNonModelFeatureEnabled) {
            currentScenario.accounts.forEach((account: Account) => {
                if (account.portfolios && account.portfolios.length > 0) {
                    account.portfolios.forEach((portfolio: Portfolio) => {
                        if (portfolio.proposedPortfolioHoldingList && portfolio.proposedPortfolioHoldingList.length > 0) {
                            totalBalance += this.calculateValueOfNonModelPortfolio(portfolio);
                        }
                    });
                }
            });
        }

        return Number(totalBalance.toFixed(2));
    }

    public calculateValueOfNonModelPortfolio(portfolio: Portfolio): number {
        if (portfolio) {
            let portfolioTotal: number = 0;
            portfolio.proposedPortfolioHoldingList?.forEach((holding: PortfolioHolding) => {
                if (holding.assetData) {
                    const isBond: boolean = holding.assetData?.assetTypeCode?.startsWith('D');
                    portfolioTotal += isBond ? this.getBondValue(Number(holding.assetData.estimatedValue),
                        Number(holding.quantity)) :
                        Number(holding.assetData.estimatedValue * holding.quantity);
                }

            });
            return portfolioTotal;
        }

        return 0;
    }


    private getBondValue(estimatedPrice: number, quantity: number): number {
        const priceAsDecimal: BigNumber = new BigNumber(estimatedPrice).dividedBy(new BigNumber(100));
        const value: BigNumber = priceAsDecimal.times(new BigNumber(quantity));
        return value.toNumber();
    }

    public checkIfScenarioHasAnyExistingAccounts(proposal: Proposal): void {
        this.isExistingAccountsPresentOnProposal = proposal.scenarios[0].accounts.some((account) => account.currentAccountId);
    }
}
