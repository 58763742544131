/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { ContactFactory } from '@CarFactories/contact-factory';
import { Address, Compliance } from '@CarInterfaces/account';
import { AccountOwnerDetail } from '@CarInterfaces/account-owner-detail';
import { BaseFactory } from '@CarInterfaces/base-factory';
import { ContactDeliveryPreferences } from '@CarInterfaces/contact-delivery-preferences';
import { Role } from '@CarInterfaces/role';
import { EntityType, OwnerRole } from '@CarModels/enums';

@Injectable({
    providedIn: 'root'
})
export class AccountOwnerDetailFactory
    implements BaseFactory<AccountOwnerDetail> {
    // Creates an AccountOwnerDetail object
    // uses role first, if no role object was provided, then uses roleId, if no id then assumes Primary
    public createObject(role?: Role, roleId?: number): AccountOwnerDetail {
        if (!role && roleId > 0) {
            role = this.createRole(roleId);
        }

        // if no role was provided, then assume primary
        if (!role) {
            role = this.createRole(OwnerRole.Primary);
        }

        return {
            // This is needed for Account Owners end point
            // Bug's noted for backlog. Account Owner needs to work so we can get to other things
            // Leaving this in till the backend bug is address.
            accountOwnersId: 0,
            ownerRole: role,
            contactId: null,
            contactType: ContactFactory.createContactType(),
            prospectId: 1,
            addresses: [] as Address[],
            suffix: {},
            compliance: this.createComplianceObject(),
            contactDeliveryPreferences: this.createContactDeliveryPreferencesObject(),
            entityType: EntityType.Prospect
        };
    }

    // returns role based on id requested
    private createRole(id: number): Role {
        switch (id) {
            case OwnerRole.Joint:
                return {
                    roleId: 2,
                    roleDescription: 'Joint'
                };
            default:
                return {
                    roleId: 1,
                    roleDescription: 'Primary'
                };
        }
    }

    private createComplianceObject(): Compliance {
        return {
            countryOfCitizenship: {
                countryId: -1,
                countryCode: null,
                countryName: null,
                sortOrder: null
            },
            sourceOfFunds: {
                sourceOfFundId: -1,
                sourceOfFundName: null
            }
        };
    }

    private createContactDeliveryPreferencesObject(): ContactDeliveryPreferences {
        return {
            sendTo: {
                statementId: 0,
                statementName: null
            },
            payTo: {
                payToId: 0,
                payToName: null
            },
            firstDollarProspectus: {
                prospectusId: 0,
                prospectusName: null
            },
            proxyStatementInvestorCommunication: {
                proxyStatementId: 0,
                proxyStatementName: null
            }
        };
    }
}
