/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { HttpService } from '@CarHttp/http.service';
import { SeiPayload } from '@CarInterfaces/sei-payload';
import { SelectedStrategy } from '@CarInterfaces/selected-strategy';
import { ConfigurationService } from '@CarServices/system/configuration.service';
import { Observable, Subject } from 'rxjs';

/**
 * Do not used. To be deleted
 * @deprecated changes here only support PR builds
 */
@Injectable()
export class StrategySelectionService {
    public selectedRange = new Subject();
    // not sure what Eric intended here. When we  get back to Strategy Selection, we need to clean this logic up
    //
    // Basically with out too much tracing, not sure why we need _selectedStrategies and selectedStrategies
    private _selectedStrategies: SelectedStrategy[] = [];
    public selectedStrategies = new Subject<SelectedStrategy[]>();
    // //////////////////
    public selectedStrategyMin = new Subject<string>();
    public totalPercentage = new Subject<number>();

    constructor(
        private readonly carHttpService: HttpService,
        private readonly carConfigurationService: ConfigurationService
    ) {}

    public addSelectedStrategy(strategy: SelectedStrategy) {
        let strategyFound = false;
        this._selectedStrategies.forEach((s: SelectedStrategy) => {
            if (s.strategyId === strategy.strategyId) {
                strategyFound = true;
                return;
            }
        });

        if (!strategyFound) {
            this._selectedStrategies.push(strategy);
            this.selectedStrategies.next(this._selectedStrategies);
        }
    }

    public removeSelectedStrategy(strategyId: number) {
        this._selectedStrategies = this._selectedStrategies.filter(
            (s: SelectedStrategy) => s.strategyId !== strategyId
        );
        this.selectedStrategies.next(this._selectedStrategies);
    }

    public getStrategyLevels(
        accountNumber: number
    ): Observable<HttpResponse<SeiPayload>> {
        return this.carHttpService.getData(
            this.carConfigurationService.routeFormatter(
                this.carConfigurationService.getEndPoint(
                    'accountInvestmentProgramsAvailablesFetch'
                ),
                [
                    {
                        key: 'accountNumber',
                        value: accountNumber
                    }
                ]
            )
        );
    }

    public getTotalSelectedStrategies(strategies: SelectedStrategy[]) {
        let total = 0;

        strategies.forEach((strategy: SelectedStrategy) => {
            total += strategy.percentage;
        });

        this.totalPercentage.next(total);
    }
}
