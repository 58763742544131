/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Apigee } from '@CarAuth/siteminder/apigee.constants';
import { GlobalService } from '@CarServices/system/global.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ServerErrorStatus } from '../../model/enums';
import { HttpResponseUrl } from '../../model/error-messages';
import { GenericErrorService } from '../system/generic-error.service';

@Injectable({ providedIn: 'root' })
export class HttpErrorInterceptorService implements HttpInterceptor {

    constructor(
        private genericErrorService: GenericErrorService,
        private globalService: GlobalService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler):
        Observable<HttpEvent<any>> {

        return next.handle(req)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    HttpResponseUrl.pathsToExclude().forEach((segment) => {
                        if (error && error.url && error.url.indexOf(segment) <= 0) {
                            // If we intercept an Unauthorized error related to access token,
                            // then we redirect the user to reauthenticate
                            if (error.url.includes(Apigee.GET_ACCESS_TOKEN_URL) &&
                                error.statusText && error.statusText.includes('Unauthorized')) {
                                window.location.href = this.globalService.configService.environment.authConfig.logOffUrl;
                            }
                            if (error.status !== ServerErrorStatus.ValidationError) {
                                this.genericErrorService.setGenericError({
                                    code: error.statusText,
                                    description: error.message
                                });
                            }
                        }
                    });

                    return throwError(error);
                })
            );
    }
}
