<div *ngIf="selectedStrategy; then hasSelectedData; else noSelectedData"></div>
<ng-template #hasSelectedData>
    <div class="row animated fadeIn slow">
        <div class="col-xs-12">
            <car-option-selection [checked]="true"
                                  [id]="selectedStrategyItem.id"
                                  [showOwnerControls]="false"
                                  [pickListItemText]="selectedStrategyItem.description"
                                  [groupName]="'StrategySelection'"
                                  (selectedData)="onSelectedStrategyClick()">
            </car-option-selection>
        </div>
    </div>

    <div class="row animated fadeIn slow"
         *ngIf="exploreStrategiesFilter">
        <sei-car-filter-strategy-risk-rank class="col-xs-12"
                                           [selectedOptionExploreStrategy]="selectedOptionExploreStrategy"
                                           [account]="account"
                                           [firmId]="this.firmId"
                                           [riskToleranceScore]="riskToleranceScore"
                                           [allExploreStrategies]="exploreStrategiesFilter">
        </sei-car-filter-strategy-risk-rank>
    </div>


</ng-template>
<ng-template #noSelectedData>
    <div class="row">
        <div class="col-xs-12">
            <car-option-selection *ngFor="let category of strategyCategories"
                                  [id]="category.id"
                                  [checked]="false"
                                  [showOwnerControls]="false"
                                  [pickListItemText]="category.description"
                                  [groupName]="'StrategySelection'"
                                  (selectedData)="onExploreStrategySelectionChange(category)">
            </car-option-selection>
        </div>
    </div>
</ng-template>
