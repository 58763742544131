/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { DocumentSection } from '../../../common/proposal-presentation-checklist.interfaces';
import { ProposalPresentationChecklistService } from '../../../common/proposal-presentation-checklist.service';

@Component({
    standalone: true,
    selector: 'sei-car-sidebar-item',
    templateUrl: './sidebar-item.component.html',
    styleUrls: ['./sidebar-item.component.scss'],
    imports: [
        CommonModule
    ],
    providers: [

    ]
})
export class SidebarItemComponent extends ParentComponentSubscriptionManager implements OnInit {
    @Input()
    public documentSection: DocumentSection;

    constructor(private proposalPresentationChecklistService: ProposalPresentationChecklistService) {
        super('SidebarItemComponent');
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.proposalPresentationChecklistService.activeChecklistSectionId.subscribe((activeChecklistSectionId: number) => {
                if (this.documentSection.id === activeChecklistSectionId) {
                    this.documentSection.active = true;
                } else {
                    this.documentSection.active = false;
                }
            }));
    }

    onClick(): void {
        this.proposalPresentationChecklistService.setActiveChecklistSection(this.documentSection.id);
    }



}
