/*
Copyright: This information constitutes the exclusive property of SEI
Investments Company, and constitutes the confidential and proprietary
information of SEI Investments Company.  The information shall not be
used or disclosed for any purpose without the written consent of SEI
Investments Company.
*/

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DropdownItem } from '@sei/common-components-lib-ux';
import { OrderedSet } from 'immutable';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Account, Goal, GoalDetail, GoalDetailPayLoad, ScenarioGoal, Strategy, Type } from '../model/proposal';
import { SeiPayload } from '../model/sei-payload';
import { GlobalService } from './system/global.service';

@Injectable({
    providedIn: 'root'
})
export class GoalService {
    private goalNames: BehaviorSubject<Goal[]>;
    private readonly GOAL_NAME_MAX_LENGTH: number = 65;

    constructor(
        private readonly carGlobal: GlobalService,
        private readonly http: HttpClient
    ) { }

    public getGoalType():
        Observable<Type[]> {
        // NOTE: This is the code when endpoint is ready
        // const apiUrl = this.carGlobal.configService.routeFormatter(
        //     this.carGlobal.configService.getEndPoint('goalType')
        // );

        // return this.http.get(apiUrl).pipe(
        //     map((response: SeiPayload) => {
        //         if (response) {
        //             return response.data[0];
        //         }
        //     })
        // );

        const returnType: Type[] = [];
        returnType.push({ id: 0, description: 'Select' });
        returnType.push({ id: 1, description: 'Business Investment' });
        returnType.push({ id: 2, description: 'Charitable Gift' });
        returnType.push({ id: 3, description: 'Current Lifestyle' });
        returnType.push({ id: 4, description: 'Distribution' });
        returnType.push({ id: 5, description: 'Education' });
        returnType.push({ id: 6, description: 'Endowment' });
        returnType.push({ id: 7, description: 'Future Lifestyle' });
        returnType.push({ id: 8, description: 'Major Purchase' });
        returnType.push({ id: 9, description: 'Parental Support' });
        returnType.push({ id: 10, description: 'Retirement' });
        returnType.push({ id: 11, description: 'Second Home' });
        returnType.push({ id: 12, description: 'Wealth Accumulation' });
        returnType.push({ id: 13, description: 'Wealth Transfer' });

        return of(returnType);
    }

    public getFrequency():
        Observable<Type[]> {
        // NOTE: This is the code when endpoint is ready
        // const apiUrl = this.carGlobal.configService.routeFormatter(
        //     this.carGlobal.configService.getEndPoint('goalFrequency')
        // );

        // return this.http.get(apiUrl).pipe(
        //     map((response: SeiPayload) => {
        //         if (response) {
        //             return response.data[0];
        //         }
        //     })
        // );

        const returnType: Type[] = [];
        returnType.push({ id: 0, description: 'Select' });
        returnType.push({ id: 1, description: 'Once' });
        returnType.push({ id: 2, description: 'Monthly' });
        returnType.push({ id: 3, description: 'Quarterly' });
        returnType.push({ id: 4, description: 'Semi-Annually' });
        returnType.push({ id: 5, description: 'Annually' });
        return of(returnType);
    }

    public getPriority():
        Observable<Type[]> {
        // NOTE: This is the code when endpoint is ready
        // const apiUrl = this.carGlobal.configService.routeFormatter(
        //     this.carGlobal.configService.getEndPoint('goalPriority')
        // );

        // return this.http.get(apiUrl).pipe(
        //     map((response: SeiPayload) => {
        //         if (response) {
        //             return response.data[0];
        //         }
        //     })
        // );

        const returnType: Type[] = [];
        returnType.push({ id: 0, description: 'Select' });
        returnType.push({ id: 1, description: 'Have to Now' });
        returnType.push({ id: 2, description: 'Want to Now' });
        returnType.push({ id: 3, description: 'Have to Later' });
        returnType.push({ id: 4, description: 'Want to Later' });
        return of(returnType);
    }

    public saveGoals(goals: GoalDetail[], proposalId: number, scenarioId: number): Observable<GoalDetailPayLoad> {
        const apiUrl = this.carGlobal.configService.routeFormatter(
            this.carGlobal.configService.getEndPoint('updateGoalDetails'),
            [
                {
                    key: 'proposalId',
                    value: proposalId
                },
                {
                    key: 'scenarioId',
                    value: scenarioId
                }
            ]
        );

        const scenarioGoal: ScenarioGoal = {
            scenarioGoals: goals
        };

        return this.http.put(apiUrl, scenarioGoal).pipe(
            map((response: SeiPayload) => {
                if (response) {
                    const goalDetailPayLoad: GoalDetailPayLoad = {
                        goalDetail: this.transformGoalsJsonDatesToObject(response.data[0]['goals']),
                        wipCheckList: response.data[0]['wipCheckList']
                    };
                    return goalDetailPayLoad;
                }
            })
        );
    }

    public resetGoalNames(): void {
        this.goalNames = new BehaviorSubject<Goal[]>([]);
    }

    public getNextGoal(goals: Goal[]) {
        if (!this.goalNames) {
            this.goalNames = new BehaviorSubject<Goal[]>(
                goals
            );
        }
        return this.goalNames.next(goals);
    }

    public setGoalNames(
        goalName: Goal[]
    ): Goal[] {
        if (goalName) {
            this.goalNames.next(goalName);
        }
        return goalName;
    }

    public getCurrentGoalNames(): Goal[] {
        return this.goalNames.value;
    }

    public isNameDuplicated(): boolean {
        const goalNames: string[] =
            this.goalNames.value.map((goal: Goal) => goal.name);

        if (!goalNames || !goalNames.length) {
            return false;
        }

        const isNameDuplicated: boolean =
            goalNames.every((name: string) => {
                const filterByName: string[] =
                    goalNames.filter((filterName: string) => filterName.toLowerCase() === name.toLowerCase());
                return filterByName && filterByName.length > 1;
            });

        return isNameDuplicated;
    }

    public isThereNotEmptyNames(): boolean {
        return this.goalNames.value.every((goal: GoalDetail) => !!goal.name.trim());
    }

    public transformGoalsJsonDatesToObject(goals: GoalDetail[]): GoalDetail[] {
        if (goals) {
            goals.forEach((goalDetail: GoalDetail) => {
                if (typeof goalDetail.startDate === 'string') {
                    goalDetail.startDate = new Date(this.formatDateTimeZone(goalDetail.startDate));
                }
                if (typeof goalDetail.endDate === 'string') {
                    goalDetail.endDate = new Date(this.formatDateTimeZone(goalDetail.endDate));
                }
            });
        }
        return goals;
    }

    private formatDateTimeZone(stringDate: string): Date {
        const millisecondsFactor: number = 60000;
        const dateToFormat: Date = new Date(stringDate);
        const userTimezoneOffset: number = dateToFormat.getTimezoneOffset() * millisecondsFactor;
        return new Date(dateToFormat.getTime() + userTimezoneOffset);
    }

    public assignDataDropDownList(dataValues: Type[]): OrderedSet<DropdownItem> {
        const dropdownList: DropdownItem[] = [];
        dataValues.forEach((data: Type) => {
            dropdownList.push(new DropdownItem(data.id, data.description));
        });
        return OrderedSet<DropdownItem>(dropdownList);
    }

    public isThisGoalValid(goal: GoalDetail): boolean {
        const isValid: boolean =
            goal.name.length > 0 &&
            goal.name.length <= this.GOAL_NAME_MAX_LENGTH &&
            goal.goalType.id > 0 &&
            goal.priority.id > 0 &&
            goal.frequency.id > 0 &&
            goal.startDate &&
            goal.endDate &&
            goal.startDate < goal.endDate &&
            goal.endValue > 0 &&
            !!goal.name.trim();
        return isValid;
    }

    public removeDeletedGoals(account: Account, goals: GoalDetail[]) {
        if (account?.proposalScenarioGoalId && !goals.some((goal: GoalDetail) => goal?.id === account?.proposalScenarioGoalId)) {
            account.proposalScenarioGoalId = undefined;
        }
        account?.strategies?.forEach((strategy: Strategy) => {
            if (strategy.proposalScenarioGoalId && !goals.some((goal: GoalDetail) => goal.id === strategy.proposalScenarioGoalId)) {
                strategy.proposalScenarioGoalId = undefined;
            }
        });
    }
}
