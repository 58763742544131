/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DelegationService {

    private subject: BehaviorSubject<string>;

    public publish(message: string) {
        if (!this.subject) {
            this.subject = new BehaviorSubject<string>(message);
            return;
        }
        this.subject.next(message);
    }

    public clearPublishedData() {
        if (this.subject) {
            this.subject.next(undefined);
        }
    }

    public refresh(): Observable<string> {
        if (!this.subject) {
            this.publish('');
        }
        return this.subject.asObservable();
    }
}
