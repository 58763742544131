<div class="row">
    <div class="col-xs-12 account-panel-title">
        <h1>Risk Tolerance</h1>
        <h2>{{account?.name}}</h2>
    </div>
</div>
<div class="row"
     *ngIf="riskToleranceQuestionnaireMessage">
    <div class="col-xs-12">
        <sei-alert [showCloseIcon]="riskToleranceQuestionnaireMessageShowCloseIcon"
                   [(open)]="riskToleranceQuestionnaireMessageShowOpen">
            <div [innerHTML]="riskToleranceQuestionnaireMessage">
            </div>
        </sei-alert>
    </div>
</div>
<h2>Get Risk By:</h2>
<div *ngIf="selectedRisk; then hasSelectedData; else noSelectedData"></div>
<ng-template #hasSelectedData>
    <div class="row">
        <div class="col-xs-12">
            <car-option-selection [checked]="true"
                                  [id]="selectedRiskItem.id"
                                  [showOwnerControls]="false"
                                  [pickListItemText]="selectedRiskItem.description"
                                  [groupName]="'RiskListing'"
                                  (selectedData)="onSelectedRiskClick()">
            </car-option-selection>
        </div>
        <div class="col-xs-12"
             *ngIf="selectedRiskItem.id === optionRiskLevel.LowMediumHigh">
            <sei-wizard-nav [wizardMinWidth]="minWidth"
                            [wizards]="wizardModel"
                            [buttonSize]="selectedButtonSize"
                            (onWizardClick)="onWizardStepselect($event)">
            </sei-wizard-nav>
        </div>
        <div class="col-xs-12"
             *ngIf="selectedRiskItem.id === optionRiskLevel.Questionnaire">
            <div class="row">
                <div class="col-xs-12">
                    <sei-car-questionnaire-main [account]="account"></sei-car-questionnaire-main>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #noSelectedData>
    <div class="row">
        <div class="col-xs-12">
            <car-option-selection *ngFor="let item of riskOptions"
                                  [id]="item.id"
                                  [checked]="false"
                                  [showOwnerControls]="false"
                                  [pickListItemText]="item.description"
                                  [groupName]="'RiskListing'"
                                  (selectedData)="onSelectionChange(item)">
            </car-option-selection>
        </div>
    </div>
</ng-template>
