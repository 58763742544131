<div class="file-multiple-upload ">
    <div class="dropzone-multiple-upload cell"
         carFileDrop
         [allowedExtensions]="allowedExtensions"
         [maxFilesAllowed]="maxFileAllowedToUpload"
         [backgroundColorDefault]="'#FFFFFF'"
         [backgroundColorHover]="'#D1D1D1'"
         (validFilesChangeEmitter)="onChangeFileUpload($event)">
        <div class="flex-display drop-items-multiple-upload">
            <div class="row">
                <div class="col-xs-12 flex-display-justify-center">
                    <i
                        class="fas fa-file-upload fa-3x upload-icon file-upload-color-icon"
                    ></i>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-12 flex-display-justify-center file-main-label-multiple-upload">
                    <div class="car-bold">Drag and Drop a file</div>
                </div>
            </div>
            <div class="row row-multiple-upload-spacing">
                <div class="col-xs-12 flex-display-justify-center action-label-multiple-upload">
                    or
                    <label for="file-upload"
                           class="car-action-label action-label-multiple-upload">browse</label>
                    to choose a file
                    <input id="file-upload"
                           #fileInput
                           type="file"
                           (click)="resetSelectFile(fileInput)"
                           value=""
                           [disabled]="disableUploadSection"
                           [accept]="acceptAttributeMultiple"
                           multiple
                           (change)="onChangeFileUpload($event.target.files)" />
                </div>
                <div class="col-xs-12 flex-display-justify-center allowed-extension-spacing"
                     *ngIf="sectionSelected !== 5">PNG, JPEG, JPG, GIF and/or less than 10MB</div>
                <div class="col-xs-12 flex-display-justify-center allowed-extension-spacing"
                     *ngIf="sectionSelected === 5">You may upload only one PDF at a time, and it must be in Portrait layout and less than 10 MB in size. This will be saved as default content for future proposals.</div>
                <div class="col-xs-12 flex-display-justify-center allowed-extension-spacing"
                     *ngIf="sectionSelected === 5">Password protected documents cannot be uploaded.</div>
            </div>

            <div class="row upload-error-spacing">
                <div class="col-xs-12 multiple-upload-error-message">
                    <span *ngIf="hasErrors && isUploadingServiceError">
                    {{uploadErrorMessage}}
                    <i class="fas fa-times-circle fa-1x multiple-upload-error"
                        (click)="hasErrors = !hasErrors;isUploadingServiceError = !isUploadingServiceError">
                       </i>
                    <br />
                    </span>
                    <span *ngIf="hasErrors && hasFileSizeExceeded">
                        This file exceeds the 10 MB file size limit supported by this action.
                        <i class="fas fa-times-circle fa-1x multiple-upload-error"
                        >
                       </i>
                       <br/>
                    </span>
                    <span *ngIf="hasErrors && fileAlreadyExistsError">
                        A file with this name already exists.
                        <i class="fas fa-times-circle fa-1x multiple-upload-error"
                        >
                       </i>
                       <br/>
                    </span>
                    <span *ngIf="hasErrors && hasInvalidFileName">
                       Invalid file name. Allowable characters are alphanumeric, spaces and the following special characters - #  !  , @ _ & ( ) ' $  [  ] ^  ~.
                        <i class="fas fa-times-circle fa-1x multiple-upload-error"
                        >
                       </i>
                    </span>
                </div>
            </div>

            <div class="row row-multiple-upload-setting"
                 *ngFor="let file of files; let i=index;">
                <div class="col-xs-12 formatting-records-multiple-upload"
                     *ngIf="file?.name">
                    <div class="row">
                        <span class="upload-multiple-document-label col-xs-4 margin-file-upload">{{ file?.name }}</span>
                        <span class="col-xs-8">
                     <span *ngIf="checkRemoveButtonVisibility(i, file?.name)"
                            [class.car-control-disabled]="disableUploadSection"
                            class="car-action-label action-label-multiple-upload pull-right order-last delete-margin"
                            (click)="removeFile(i)">
                            <i class="delete-button-spacing fas fa-trash-alt fa-1x"></i>
                    </span>
                        <span class="pull-right multiple-upload-success" *ngIf="fileName[i]===file.name && documentUploadedIndexList[i]"
                       >
                        <i class="fas fa-check-circle fa-1x">
                        </i> Successfully Uploaded
                      </span>
                        <span *ngIf="!checkInValidFile(file?.name) && checkUploadButtonVisibilty(i)">
                       <button
                            type="button"
                            [disabled]="disableUploadSection"
                            class="btn btn-default pull-right delete-margin"
                            (click)="uploadFile(file,i)">
                        Complete Upload
                        </button>
                     </span>
                        <span  *ngIf="checkInValidFile(file?.name) && !checkUploadButtonVisibilty(i)"
                         class="pull-right margin-file-upload">
                         <i class="fas fa-times-circle fa-1x multiple-upload-error">
                                File Type Not Supported
                         </i> |
                     </span>
                        <span  *ngIf="(reUploadingEnableIndexList[i])"
                           class="pull-right margin-file-upload">
                           <span >
                                <i class="fas fa-times-circle fa-1x multiple-upload-error">
                                        File Failed to Load
                                </i> |
                           </span>
                        <span
                         [class.car-control-disabled]="disableUploadSection"
                         class="car-action-label action-label-multiple-upload fa-1x"
                         (click)="uploadFile(file,i)">  Reload
                         <i class="fas fa-redo fa-1x"></i> |
                        </span>
                        </span>
                        <span *ngIf="checkUploadingIsInProgress(i)"
                        class="fa-1x car-bold pull-right delete-margin">
                        Uploading
                        <i class="fas fa-spinner fa-spin"></i>
                      </span>

                        <span class="remove-document"
                      *ngIf="clickedOnRemoveDocumentIndexList[i]">
                         <button type="button"
                           class="btn btn-default btn-sm pull-right delete-margin"
                           (click)="noToRemoveFile(i)">
                         No
                         </button>
                       <button type="button"
                        class="btn btn-default btn-sm btn-margin pull-right delete-margin"
                        (click)="yesToRemoveFile(file.name, i)">
                           Yes
                        </button>
                        <span class="text-warning pull-right">
                            <i class="fas fa-exclamation-triangle car-fa-remove-warning margin-file-upload-icon"></i>
                            <span>Are you sure you want to remove this document?</span>
                        </span>

                        </span>
                        </span>
                    </div>
                    <div [ngClass]="{'row-multiple-upload-border':((files.length-1)>i),
                                    'row-multiple-upload-bottom-space': !((files.length-1)>i)}"></div>
                </div>

            </div>
        </div>
    </div>

    <div *ngIf="hasErrors"
         class="row">
        <div class="col-xs-12 multiple-upload-error">
            <span *ngIf="tooManyFilesCount > 1">
                You are only allowed to upload
                {{ maxFileAllowedToUpload }} file(s) at a time. You attempted to
                upload {{ tooManyFilesCount }} file(s).
                <i class="fas fa-times-circle fa-1x multiple-upload-error"
                >
               </i>
            </span>
        </div>
    </div>
</div>
