<div class="form-group" id="StrategySelection"
     *ngIf="riskWidgetType === riskWidgetTypes.StrategySelection; else StrategySummary">

     <div class="risk-widget-key">
          <div class="risk-capacity"><span class="risk-icon capacity"></span>Client Risk</div>
     </div>
     <div class="risk-widget" id="risk">
          <div *ngFor="let riskLevel of riskWidget" class="risk-level" [ngClass]="riskLevel.riskLevel">
               <div class="risk-increment"></div>
               <div class="risk-increment"></div>
               <div class="risk-increment"></div>
          </div>

          <div class="risk-icon capacity" [ngClass]="capacityRiskLevel"></div>
     </div>
</div>
<ng-template #StrategySummary>
     <div class="form-group" id="StrategySummary">
          <div class="risk-widget-key">
               <div class="risk-capacity"><span class="risk-icon capacity"></span>Risk Tolerance</div>
               <div class="risk-investment"><span class="risk-icon investment"></span>Investment Risk</div>
          </div>
          <div class="risk-widget" id="risk">
               <div *ngFor="let riskLevel of riskWidget" class="risk-level" [ngClass]="riskLevel.riskLevel"
                    [class.active]="riskLevel.active">
                    <div class="risk-increment full disabled highlight" [class.snap]="riskLevel.low.active"
                         [class.active]="riskLevel.low.active"
                         [hidden]="riskLevel.low.classId === riskLevelClasses.NoneClass"></div>
                    <div class="risk-increment full disabled highlight" [class.snap]="riskLevel.medium.active"
                         [class.active]="riskLevel.medium.active"
                         [hidden]="riskLevel.medium.classId === riskLevelClasses.NoneClass"></div>
                    <div class="risk-increment full disabled highlight" [class.snap]="riskLevel.high.active"
                         [class.active]="riskLevel.high.active"
                         [hidden]="riskLevel.high.classId === riskLevelClasses.NoneClass"></div>
               </div>

               <div class="risk-icon capacity" [ngClass]="capacityRiskLevel"></div>
          </div>
     </div>
</ng-template>
