/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    standalone: true,
    selector: 'sei-car-proposal-presentation-checklist-button',
    templateUrl: './proposal-presentation-checklist-button.component.html',
    styleUrls: ['./proposal-presentation-checklist-button.component.scss'],
    imports: [
        CommonModule
    ]
})
export class ProposalPresentationChecklistButtonComponent {
    @Input()
    public buttonText: string;

    @Input()
    public fontAwesomeIcon: string;

    @Output()
    public buttonClicked: EventEmitter<boolean> = new EventEmitter();
}
