/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { RiskWidget } from '../risk-widget';
import { RiskSnapPoints, RiskLevelClasses, RiskLevels } from '../enums';
import { BaseFactoryAbstract } from './base-factory-abstract';

@Injectable()
export class RiskWidgetFactory extends BaseFactoryAbstract<RiskWidget> {
    constructor() {
        super();
    }

    public createRiskLevel(riskLevel: RiskLevels): RiskWidget {
        let lowMin: number;
        let lowMax: number;
        let medMin: number;
        let medMax: number;
        let highMin: number;
        let highMax: number;
        let riskClass: string;
        let lowClass: string;
        let mediumClass: string;
        let highClass: string;

        switch (riskLevel) {
            case RiskLevels.Low: {
                lowMin = 0;
                lowMax = 3;
                medMin = 3;
                medMax = 5.08;
                highMin = 5.08;
                highMax = 6.85;
                riskClass = RiskLevelClasses.RiskLowClass;
                lowClass = RiskLevelClasses.LowLowClass;
                mediumClass = RiskLevelClasses.LowMediumClass;
                highClass = RiskLevelClasses.LowHighClass;
                break;
            }
            case RiskLevels.Medium: {
                lowMin = 6.85;
                lowMax = 9.07;
                medMin = 9.07;
                medMax = 11.92;
                highMin = 11.92;
                highMax = 14.4;
                riskClass = RiskLevelClasses.RiskMediumClass;
                lowClass = RiskLevelClasses.MediumLowClass;
                mediumClass = RiskLevelClasses.MediumMediumClass;
                highClass = RiskLevelClasses.MediumHighClass;
                break;
            }
            case RiskLevels.High: {
                lowMin = 14.4;
                lowMax = 17.88;
                medMin = 17.88;
                medMax = 22;
                highMin = 22;
                highMax = 30;
                riskClass = RiskLevelClasses.RiskHighClass;
                lowClass = RiskLevelClasses.HighLowClass;
                mediumClass = RiskLevelClasses.HighMediumClass;
                highClass = RiskLevelClasses.HighHighClass;
                break;
            }
        }
        return {
            riskLevel: riskClass,
            active: false,
            low: {
                active: false,
                highlight: false,
                enabled: false,
                classId: lowClass,
                min: lowMin,
                max: lowMax
            },
            medium: {
                active: false,
                highlight: false,
                enabled: false,
                classId: mediumClass,
                min: medMin,
                max: medMax
            },
            high: {
                active: false,
                highlight: false,
                enabled: false,
                classId: highClass,
                min: highMin,
                max: highMax
            }
        };
    }

    public getAllClickableSpanPoints(): number[] {
        const riskSnapPointValues: number[] = [
            RiskSnapPoints.LowLow,
            RiskSnapPoints.LowMedium,
            RiskSnapPoints.LowHigh,
            RiskSnapPoints.MediumLow,
            RiskSnapPoints.MediumMedium,
            RiskSnapPoints.MediumHigh,
            RiskSnapPoints.HighLow,
            RiskSnapPoints.HighMedium
        ];
        return riskSnapPointValues;
    }
}
