<section *ngIf="!hideSection"
         id="section-{{checkListSection.id}}"
         class="wip-checklist-section">
    <car-wip-checklist-details [subCheckList]="checkListSection.subCheckList"
                               [sectionType]="checkListSection.sectionType"
                               [checkListId]="checkListSection.id"
                               [proposalId]="proposal.id"
                               [isCurrentAccountAddEnable]="isCurrentAccountAddEnable"
                               [scenarioId]="scenarioId"
                               [proposal]="proposal">
    </car-wip-checklist-details>
</section>
