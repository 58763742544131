/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { ApigeeHttpUtil, Global, HttpUtil } from '@sei/common-swp-components-lib-ux';
import { Observable } from 'rxjs';
import { TaxRateFormResponse } from '../../tax-rate/model/tax-rate.model';
import { TaxTransitionResponse } from '../model/tax-transition-analysis.model';

@Injectable({
    providedIn: 'root'
})
export class TaxTransitionAnalysisProxy {
    constructor(private httpUtil: HttpUtil, private global: Global,
        private apigeeHttpUtil: ApigeeHttpUtil) { }

    public getTaxRates(entityId: number, entityType: string): Observable<TaxRateFormResponse> {
        if (this.global.isMockDataMode()) {
            const mockURL: string = 'assets/mock-data/tax-rate-form.json';
            return this.httpUtil.doMockPostStronglyTyped<TaxRateFormResponse>(mockURL, 1000);
        } else {
            if (entityId || entityType) {
                const requestURL: string = '/api/v4/swp/imsproposalservice/proposals/taxrates/' + entityId + '/' + entityType;
                return this.apigeeHttpUtil.doGet<TaxRateFormResponse>(requestURL);
            }
        }
    }

    public getDefaultTaxRates(): Observable<TaxRateFormResponse> {
        if (this.global.isMockDataMode()) {
            const mockURL: string = 'assets/mock-data/tax-rate-form.json';
            return this.httpUtil.doMockPostStronglyTyped<TaxRateFormResponse>(mockURL, 1000);
        } else {
            const requestURL: string = '/api/v4/swp/imsproposalservice/proposals/taxrates/';
            return this.apigeeHttpUtil.doGet<TaxRateFormResponse>(requestURL);
        }
    }

    public getTaxTransitionData(proposalId: string): Observable<TaxTransitionResponse> {
        if (this.global.isMockDataMode()) {
            const mockURL: string = 'assets/mock-data/tax-transition-analysis.json';
            return this.httpUtil.doMockPostStronglyTyped<TaxTransitionResponse>(mockURL, 1000);
        } else {
            const requestURL: string = '/api/v4/swp/imsproposalservice/proposals/accountdetails/';
            return this.apigeeHttpUtil.doGet<TaxTransitionResponse>(requestURL + proposalId);
        }
    }
}
