/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { UploadFileInterceptor } from '@CarHttp/upload-file.interceptor';

export const fileUploadProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: UploadFileInterceptor,
    multi: true
};
