/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromProposal from './proposal.reducers';

export interface State {
    Proposal: fromProposal.ProposalState;
}

export const proposalModuleSliceName = 'proposalSlice';
const getProposalFeatureState = createFeatureSelector<
    fromProposal.ProposalState
>(proposalModuleSliceName);

export const getSelectedProposal = createSelector(
    getProposalFeatureState,
    (state) => state.selectedProposal
);

export const getSelectedProposalLoaded = createSelector(
    getProposalFeatureState,
    (state) => state.selectedProposalLoaded
);

export const getShowSpinner = createSelector(
    getProposalFeatureState,
    (state) => state.showSpinner
);
