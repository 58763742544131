/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { BaseFactory } from '@CarInterfaces/base-factory';
import { DelegationSection } from '@CarInterfaces/delegation';
import { DelegationConstants } from '@CarModels/constants';
import { DelegationSectionType } from '@CarModels/enums';

// TODO: Refactor this section once integration happens

@Injectable({
    providedIn: 'root'
})
export class DelegationFactory implements BaseFactory<DelegationSection> {
    public createObject(
        typeId?: DelegationSectionType,
        entityId?: number | string
    ): DelegationSection {
        let label: string;
        let name: string;

        switch (typeId) {
            case DelegationSectionType.GeneralOwnerInformation:
                label = DelegationConstants.GENERAL_OWNER_INFORMATION;
                name = DelegationConstants.GENERAL_OWNER_INFORMATION_NAME;
                break;
            case DelegationSectionType.LegalMailingAddress:
                label = DelegationConstants.LEGAL_MAILING_ADDRESS;
                name = DelegationConstants.LEGAL_MAILING_ADDRESS_NAME;
                break;
            case DelegationSectionType.RegulatoryInformation:
                label = label = DelegationConstants.REGULATORY_INFORMATION;
                name = DelegationConstants.REGULATORY_INFORMATION_NAME;
                break;
            case DelegationSectionType.BeneficiaryInformation:
                label = DelegationConstants.BENEFICIARY_INFORMATION;
                name = DelegationConstants.BENEFICIARY_INFORMATION_NAME;
                break;
            case DelegationSectionType.PowersOfAttorneyAgentInformation:
                label = DelegationConstants.POWERS_OF_ATTORNEY_INFORMATION;
                name = DelegationConstants.POWERS_OF_ATTORNEY_AGENT_INFORMATION;
                break;
            case DelegationSectionType.PowersOfAttorneyContactInformation:
                name =
                    DelegationConstants.POWERS_OF_ATTORNEY_CONTACT_INFORMATION;
                break;
            case DelegationSectionType.PowersOfAttorneyRegulatoryInformation:
                name =
                    DelegationConstants.POWERS_OF_ATTORNEY_REGULATORY_INFORMATION;
                break;
            case DelegationSectionType.RelatedPartiesInformation:
                name = DelegationConstants.RELATED_PARTIES_NAME;
                break;
            case DelegationSectionType.LegalRelatedPartiesAddress:
                name = DelegationConstants.RELATED_PARTIES_ADDRESS;
                break;
            case DelegationSectionType.RelatedPartiesAdditionalInformation:
                name = DelegationConstants.RELATED_PARTIES_ADDITIONAL;
                break;
            default:
                label = '';
                break;
        }

        return {
            delegationTypeId: typeId,
            name,
            label,
            entityId,
            role: { roleDescription: undefined, roleId: undefined }
        };
    }
}
