/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { CdkDragDrop, CdkDragEnter, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { DisplayNullOptions, DisplayPercentOptions } from '@sei/common-components-lib-ux';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { AdvisorFee, FeeAccount, FeeGroup, HudSummary, ScenarioFeeSchedule } from '../../model/account-fee';
import { CommandText, FeeType } from '../../model/enums';
import { Account } from '../../model/proposal';
import { DelegationService } from '../../service/delegation.service';
import { FeeScheduleGroupService } from '../../service/fee-schedule-group.service';

@Component({
    selector: 'sei-car-fee-schedule',
    templateUrl: './fee-schedule.component.html'

})
export class FeeScheduleComponent extends ParentComponentSubscriptionManager implements OnInit, OnChanges, OnDestroy {

    @Input()
    public feeGroup: FeeGroup;
    @Input()
    public showScheduleFlatFeeRateDetails: boolean = false;
    @Input()
    public scenarioId: number;
    @Input()
    public proposalId: number;
    @Input()
    public scenarioFeeSchedules: ScenarioFeeSchedule[] = [];
    @Output()
    public onAccountEnter = new EventEmitter();
    @Output()
    public onAccountRemoved = new EventEmitter();
    @Output()
    public onCancelNewAccountFee: EventEmitter<FeeAccount> = new EventEmitter();
    @Output()
    public onChangeAsDefault: EventEmitter<number> = new EventEmitter();
    @Output()
    public onHudSummaryUpdate: EventEmitter<HudSummary> = new EventEmitter();

    public totalAccountStrategyBalance: number = 0;
    public calculatedRate: number = 0;
    public emittedAccount: boolean = false;
    public feeTypes: typeof FeeType = FeeType;
    public displayPercentOptions: DisplayPercentOptions;


    constructor(private feeScheduleGroupService: FeeScheduleGroupService,
        private delegationService: DelegationService) {
        super('FeeScheduleComponent');
    }

    public ngOnInit(): void {
        this.delegationService.refresh().subscribe((data) => {
            if (data === CommandText.RefreshFees) {
                this.updateAccountFee();
                this.delegationService.clearPublishedData();
            }
        });

        this.displayPercentOptions = {
            displayPercentSymbol: true,
            roundToDecimal: 2, nullDisplay: DisplayNullOptions.BLANK
        };
    }

    public ngOnDestroy(): void {
        this.delegationService.clearPublishedData();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.updateAccountFee();
    }

    public updateAccountFee(): void {

        const feeAnalysis = {
            scenarioId: this.scenarioId,
            scenarioName: '',
            feeGroups: [this.feeGroup]
        };

        this.feeScheduleGroupService.calculateAdvisorFees(feeAnalysis, this.proposalId, this.scenarioId)
            .subscribe((data: HudSummary) => {
                if (data) {
                    this.totalAccountStrategyBalance = data.estimatedAdvisorFee.amount;
                    this.calculatedRate = data.estimatedAdvisorFee.rate;
                }
            });

        this.onHudSummaryUpdate.emit(this.feeGroup.hudSummary);
    }

    public drop(event: CdkDragDrop<Account[]>): void {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex);

            this.delegationService.publish(CommandText.RefreshFees);
        }

        if (this.emittedAccount) {
            this.onAccountEnter.emit();
            this.emittedAccount = false;
        }
    }

    public enter(event: CdkDragEnter<Account[]>): void {
        this.emittedAccount = true;
    }

    public onEditScheduleFlatFeeRate() {
        this.showScheduleFlatFeeRateDetails = (!this.showScheduleFlatFeeRateDetails);
    }

    public onSaveFeeGroup(event: AdvisorFee): void {

        this.feeGroup.advisorFee = event;
        if (this.feeGroup.accounts.length === 1 && this.feeGroup.accounts[0].previousFeeGroupId !== this.feeGroup.id) {
            this.feeGroup.accounts[0].previousFeeGroupId = this.feeGroup.id;
        }

        this.onEditScheduleFlatFeeRate();

        if (event.isDefault) {
            this.onChangeAsDefault.emit(this.feeGroup.id);
        }

        this.delegationService.publish(CommandText.RefreshFees);
        this.onHudSummaryUpdate.emit(this.feeGroup.hudSummary);
    }

    public onCancelFeeGroup(): void {
        if (this.showScheduleFlatFeeRateDetails && this.feeGroup && this.feeGroup.accounts.length === 1
            && this.feeGroup.accounts[0].previousFeeGroupId !== this.feeGroup.id) {
            this.onCancelNewAccountFee.emit(this.feeGroup.accounts[0]);
        } else {
            this.onEditScheduleFlatFeeRate();
        }
        this.delegationService.publish(CommandText.RefreshFees);
    }
}
