/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
import { BannerMessageConstants } from '@CarModels/constants';
import { ExistingAccountsService } from '@CarServices/existing-accounts/existing-accounts.service';
import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AlertComponent, BannerMessageType } from '@sei/common-components-lib-ux';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { Subscription } from 'rxjs';

@Component({
    standalone: true,
    selector: 'sei-car-existing-accounts-alert',
    templateUrl: './existing-accounts-model-alert.component.html',
    imports: [AlertComponent, CommonModule]
})

export class ExistingAccountsModelAlertComponent extends ParentComponentSubscriptionManager implements OnInit, OnDestroy {

    public isAlertVisible: boolean = false;
    public typeWarning: BannerMessageType = BannerMessageType.warning;
    public existingModelNotFoundWarning: string = BannerMessageConstants.MODEL_NOT_FOUND_MESSAGE;

    constructor(private existingAccountsService: ExistingAccountsService) {
        super('ExistingAccountsModelAlertComponent');
    }

    ngOnInit(): void {
        const alertSubscription: Subscription =
            this.existingAccountsService.showNoModelFoundAlert.subscribe((alertStatus: boolean): void => {
                this.isAlertVisible = alertStatus;
            });
        this.subscriptions.push(alertSubscription);
    }

    ngOnDestroy(): void {
        this.existingAccountsService.showNoModelFoundAlert.next(false);
    }

}
