<h2>{{ firmAdvisorLabel }}</h2>
<div class="row">
    <div class="col-xs-12 form-group">
        <label>Select {{ firmAdvisorLabel }}</label>
        <sei-car-firm-advisor-search [firm]="firm" [entitledAdvisorsEntityIds]="entitledAdvisorsEntityIds"
            (selectedValue)="firmSelected($event)" (onKeyUp)="newSearch()"></sei-car-firm-advisor-search>
    </div>
</div>
<div *ngIf="firmAdvisor && !clearForm">
    <div class="row indent-2">
        <div class="col-xs-12 form-group">
            <label>Office</label>
            <sei-car-settings-dropdown [dropDownContent]="officesArray"
                (optionSelected)="officeSelected($event)"></sei-car-settings-dropdown>
        </div>
    </div>
</div>
<div *ngIf="displayMode === displayModes.Dashboard; then Dashboard"></div>
<ng-template #Dashboard>
    <div *ngIf="firmAdvisor">
        <div class="row indent-2">
            <div class="col-xs-12">
                <sei-car-account-advisors-dropdown (accountAdvisorDropdownSelected)="advisorDropdownUpdated($event)"
                    (accountAdvisorDropdownDeleted)="advisorDropdownDeleted($event)" [currentAdvisors]="advisorsArray"
                    [firmAdvisorMode]="true" [defaultAdvisorId]="defaultAdvisorId" [firmId]="firmId">
                </sei-car-account-advisors-dropdown>
            </div>
        </div>
        <div class="row indent-2">
            <div class="col-xs-12">
                <button class="btn add-content unselectable button-gap" type="button" *ngIf="firmAdvisor"
                    (click)="onAddAdvisorClick()">
                    <i class="fas fa-plus-circle"></i>Add Advisor
                </button>
            </div>
        </div>
    </div>
</ng-template>
