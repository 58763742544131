/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@CarComponents/common/common.module';
import { RiskToleranceFooterComponent } from './risk-tolerance-footer/risk-tolerance-footer.component';
import { RiskToleranceQuestionComponent } from './risk-tolerance-question/risk-tolerance-question.component';
import { RiskToleranceQuestionaryComponent } from './risk-tolerance-questionary/risk-tolerance-questionary.component';
import { WIPRiskToleranceComponent } from './wip-risk-tolerance-main/wip-risk-tolerance-main.component';

@NgModule({
    imports: [CommonModule],
    declarations: [
        RiskToleranceFooterComponent,
        RiskToleranceQuestionComponent,
        RiskToleranceQuestionaryComponent,
        WIPRiskToleranceComponent
    ],
    providers: [],
    exports: [
        RiskToleranceFooterComponent,
        RiskToleranceQuestionComponent,
        RiskToleranceQuestionaryComponent,
        WIPRiskToleranceComponent
    ]
})
export class WipRiskToleranceModule {}
