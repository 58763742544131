/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalService } from '../service/system/global.service';
import { ElasticSearchRelatedParty } from '../model/elastic-search-related-party';
import { GenericSeiPayload } from '../model/sei-payload';
import { BaseRepository } from './base-repository';

@Injectable({
    providedIn: 'root'
})
export class ElasticSearchRepository extends BaseRepository<
ElasticSearchRelatedParty
> {
    constructor(protected global: GlobalService) {
        super(global);
        this.returnDataKey = 'data';
        this.endPoint = {
            read: this.global.configService.getEndPoint('elasticSearch')
        };
    }

    /**
     * Gets the Elastic Search Related Party object from REST for a given entity Id
     * @param Id Entity Id to get
     */
    public read(id: number | string): Observable<ElasticSearchRelatedParty> {
        this.global.logger.log(
            `Elastic Search Related Party object requested from carWebService-ElasticSearch: ${id.toString()}`
        );

        return this.global.httpUtilityService
            .doGetRaw<GenericSeiPayload>(
                this.global.configService.routeFormatter(this.endPoint.read, {
                    key: 'entityId',
                    value: id
                })
            )
            .pipe(
                map((response: GenericSeiPayload) => {
                    return response.data;
                })
            );
    }
}
