/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

export enum CurrentAccountEnum {
    Yes = 'Yes',
    No = 'No',
    AccountDetails = 'Account Details',
    TaxLotDetails = 'Tax Lot Details',
    Edit = 'Edit',
    Other = 'Other',
    Add = 'Add'
}

