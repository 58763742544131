/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ChecklistDocument } from '../common/proposal-presentation-checklist.interfaces';
import { SidebarItemListComponent } from './sidebar-item-list/sidebar-item-list.component';


@Component({
    standalone: true,
    selector: 'sei-car-proposal-presentation-checklist-sidebar',
    templateUrl: './proposal-presentation-checklist-sidebar.component.html',
    styleUrls: ['./proposal-presentation-checklist-sidebar.component.scss'],
    imports: [
        SidebarItemListComponent,
        CommonModule
    ]
})
export class ProposalPresentationChecklistSidebarComponent {
    @Input()
    public checklistDocuments: ChecklistDocument[];

    public drawerCollapsed: boolean;

    public toggleDrawerCollapsed(): void {
        this.drawerCollapsed = !this.drawerCollapsed;
    }
}
