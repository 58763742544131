/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { TooltipConstants } from '@CarModels/constants';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DisplayNullOptions, DisplayPercentOptions } from '@sei/common-components-lib-ux';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { PercentFormatPipe } from 'projects/advisor-client-review-proposal-ux-app/src/app/core/pipes/percent-format.pipe';
import { HudFeeSchedule, HudSummary, ManagementFeeBreakdown, ProposalPercentFormat } from '../../model/account-fee';
import { CurrencyTypes, HudInfoText, HudMode, RiskLevels } from '../../model/enums';
import { Scenario } from '../../model/proposal';
import { HudQuestionnaire } from '../../model/questionnaire';
import { ProposalAccountService } from '../../service/proposal-account.service';

@Component({
    selector: 'sei-car-heads-up-display',
    templateUrl: './heads-up-display.component.html',
    styleUrls: ['./heads-up-display.component.scss'],
    providers: [PercentFormatPipe]
})
export class HeadsUpDisplayComponent extends ParentComponentSubscriptionManager implements OnInit, OnChanges {

    @Input()
    public hudQuestionnaire: HudQuestionnaire;
    @Input()
    public hudSummary: HudSummary;
    @Input()
    public hudFeeStrategy: HudFeeSchedule;
    @Input()
    public scenario: Scenario;
    @Input()
    public managementFeeBreakdown: ManagementFeeBreakdown[];

    public hudCompressed: boolean = false;
    public hudMode: string;
    public hudInfoText: string;
    public hudModes: typeof HudMode = HudMode;
    public riskLevels: typeof RiskLevels = RiskLevels;
    public currencyTypes: typeof CurrencyTypes = CurrencyTypes;
    private displayPercentOptions: DisplayPercentOptions;
    public proposalPercentFormatOptions: ProposalPercentFormat;
    public feeSummaryLoaded: boolean = false;

    constructor(private proposalAccountService: ProposalAccountService) {
        super('HeadsUpDisplayComponent');
    }

    public ngOnInit(): void {
        this.setHudMode();
        this.displayPercentOptions = {
            displayPercentSymbol: true,
            roundToDecimal: 2, nullDisplay: DisplayNullOptions.BLANK
        };

        this.proposalPercentFormatOptions = {
            isFeeBreakdown: false,
            displayPercentOptions: this.displayPercentOptions
        };
    }
    public ngOnChanges(): void {
        this.setHudMode();

    }

    public setHudMode(): void {

        this.hudMode =
            this.hudQuestionnaire ? this.hudModes.Questionnaire :
                this.hudSummary ? this.hudModes.FeeSummary :
                    this.hudFeeStrategy ? this.hudModes.Strategy : '';

        this.hudInfoText =
            this.hudQuestionnaire ? HudInfoText.Questionnaire :
                this.hudSummary ? HudInfoText.FeeSummary :
                    this.hudFeeStrategy ? HudInfoText.StrategySummary : '';

    }

    public onHudCompressToggle(): void {
        this.hudCompressed = !this.hudCompressed;
    }

    public getTooltipMessage(): string {
        return TooltipConstants.CLIENT_DIRECTED_MESSAGE;
    }

    public isClientDirectedPresent(): boolean {
        return this.proposalAccountService.isClientDirectedPresentInScenario(this.scenario);
    }

    public setFeeSummaryLoaded(event: boolean) {
        this.feeSummaryLoaded = event;
    }

}
