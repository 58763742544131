/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@CarComponents/common/common.module';
import { WipOutsideStrategyDetailComponent } from './wip-outside-strategy-detail/wip-outside-strategy-detail.component';
import { WipOutsideStrategyMainComponent } from './wip-outside-strategy-main/wip-outside-strategy-main.component';
import { WipOutsideStrategySummaryComponent } from './wip-outside-strategy-summary/wip-outside-strategy-summary.component';

@NgModule({
    imports: [CommonModule],
    declarations: [
        WipOutsideStrategyMainComponent,
        WipOutsideStrategyDetailComponent,
        WipOutsideStrategySummaryComponent
    ],
    exports: [WipOutsideStrategySummaryComponent]
})
export class WipOutsideStrategyModule {}
