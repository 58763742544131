/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { SpinnerService } from '@CarServices/system/spinner.service';
import { Component, Input, OnInit } from '@angular/core';
import { LoadingOverlayStyle } from '@sei/common-components-lib-ux';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';

@Component({
    selector: 'car-spinner',
    templateUrl: './spinner.component.html'
})
export class SpinnerComponent extends ParentComponentSubscriptionManager implements OnInit {

    @Input()
    public commonComponentSpinnerStatus: boolean = false;

    public isSpinnerActive: boolean = false;
    public styleInput: LoadingOverlayStyle = LoadingOverlayStyle.Translucent;

    constructor(private spinnerService: SpinnerService) {
        super('SpinnerComponent');
    }

    ngOnInit() {
        const spinnerServiceSubscription = this.spinnerService.isSpinnerActive.subscribe((currentSpinnerStatus: boolean) => {
            this.isSpinnerActive = currentSpinnerStatus;
        });
        this.subscriptions.push(spinnerServiceSubscription);
    }
}

