<div class="row"
     *ngIf="isNewClient">
    <div [hidden]="!!contactTypeId"
         class="col-xs-6 form-group">
        <label for="prospectType">Prospect Type</label>
        <sei-dropdown id="prospectType"
                      [ngModel]="selectedContactTypeSet"
                      (ngModelChange)="onContactTypeChanged($event)"
                      [items]="contactTypes"></sei-dropdown>
    </div>
</div>
<div *ngIf="this.owner.contactType.id === 1">
    <div class="row">
        <div class="form-group col-xs-4">
            <label for="firstName">First Name</label>
            <input *ngIf="isNewClient"
                   id="firstName"
                   type="text"
                   class="form-control"
                   [value]="owner.firstName"
                   [ngModel]="owner.firstName"
                   (ngModelChange)="onOwnerFirstNameChange($event)"
                   minlength="2"
                   maxlength="36">
            <span *ngIf="!isNewClient">{{ owner?.firstName ? owner.firstName : '-' }}</span>
        </div>
        <div class="col-xs-2">
            <label for="middleInitial">MI</label>
            <input *ngIf="isNewClient"
                   id="middleInitial"
                   type="text"
                   class="form-control"
                   [value]="owner.middleInitial"
                   [ngModel]="owner.middleInitial"
                   (ngModelChange)="onMiddleInitialChange($event)"
                   maxlength="36">
            <span *ngIf="!isNewClient">{{ owner?.middleInitial ? owner.middleInitial : '-' }}</span>
        </div>
        <div class="col-xs-4">
            <label for="lastName">Last Name</label>
            <input *ngIf="isNewClient"
                   id="lastName"
                   type="text"
                   class="form-control"
                   [value]="owner.lastName"
                   [ngModel]="owner.lastName"
                   (ngModelChange)="onOwnerLastNameChange($event)"
                   minlength="2"
                   maxlength="100">
            <span *ngIf="!isNewClient">{{ owner?.lastName ? owner.lastName : '-' }}</span>
        </div>
        <div class="col-xs-2">
            <label for="clientSuffix">Suffix</label>
            <sei-dropdown *ngIf="isNewClient"
                          id="clientSuffix"
                          [ngModel]="selectedSuffixSet"
                          (ngModelChange)="onSuffixChange($event)"
                          [items]="suffixSet"></sei-dropdown>
            <span *ngIf="!isNewClient">{{ owner?.entitySuffix?.suffixName ? owner.entitySuffix.suffixName : '-' }}</span>
        </div>
    </div>
</div>

<div *ngIf="this.owner.contactType.id !== 1"
     class="row">
    <div class="form-group col-xs-12">
        <label for="organizationName"
               class="control-label">Organization Name</label>
        <input *ngIf="isNewClient"
               type="text"
               class="form-control"
               [ngModel]="owner.organizationName"
               (ngModelChange)="onOrganizationNameChange($event)"
               id="organizationName"
               minlength="2"
               maxLength="100" />
        <span *ngIf="!isNewClient">{{ owner?.organizationName ? owner.organizationName : '-' }}</span>
    </div>
</div>

<div class="row">
    <div class="col-xs-6 form-group"
         [class.has-error]="owner?.emailAddress && !isEmailValid">
        <label for="emailAddress"
               class="control-label">Email Address <span *ngIf="isNewClient" class="optional-tag">- Optional</span></label>
        <input *ngIf="isNewClient"
               id="emailAddress"
               seiEmail
               type="text"
               class="form-control"
               [value]="owner?.emailAddress"
               [ngModel]="owner?.emailAddress"
               (ngModelChange)="onEmailChange($event)"
               minlength="2"
               maxlength="100"
               (isValid)="checkEmailValid($event)">
        <span *ngIf="!isNewClient">{{ owner?.emailAddress ? owner.emailAddress : '-' }}</span>
    </div>
    <div class="col-xs-6 form-group"
         [class.has-error]="owner?.phoneNumber && !isPhoneValid">
        <label for="phoneNumber"
               class="control-label">Phone Number <span *ngIf="isNewClient" class="optional-tag">- Optional</span></label>
        <input *ngIf="isNewClient"
               id="phoneNumber"
               seiEditPhone
               type="text"
               class="form-control"
               [value]="owner?.phoneNumber"
               [ngModel]="owner?.phoneNumber"
               (phoneNumber)="onPhoneChange($event)"
               autocomplete="none"
               minlength="2"
               maxlength="100"
               (isValid)="checkPhoneValid($event)">
        <span *ngIf="!isNewClient && owner?.phoneNumber">{{ owner.phoneNumber | seiDisplayPhone:seiDisplayPhoneOption }}</span>
        <span *ngIf="!isNewClient && !owner?.phoneNumber">-</span>
    </div>
</div>
