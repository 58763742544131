/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApigeeConfig, SeiSwpOauthResponse } from '@CarInterfaces/oauth';
import { SwpOauthRepository } from '@CarRepos/swp-oauth-repository';
import { GlobalService } from '@CarServices/system/global.service';
import { PropertyService } from '@CarServices/system/property.service';
import { createFeatureSelector, DefaultProjectorFn, MemoizedSelector, Store } from '@ngrx/store';
import { ApigeeSlice, SeiCommonAppStore } from '@sei/common-swp-components-lib-ux';
import { Observable, of } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ApigeeTokenService {
    private _config: ApigeeConfig;
    private _response: SeiSwpOauthResponse;

    private oauthSecurityKey: string = 'oauthtoken';
    private authorizationCodeKey: string = 'authorizationcode';
    private oauthTokenKey: string = 'v3/oauthtoken';
    private elasticSearchKey: string = 'v1/evententities';

    constructor(
        private readonly global: GlobalService,
        private readonly swpOauthRepository: SwpOauthRepository,
        private readonly propertyService: PropertyService,
        private readonly appStore: Store<SeiCommonAppStore>
    ) {
        this.initializeService();
    }

    public get apigeeConfig(): ApigeeConfig {
        return this._config;
    }

    public get apigeeTokenResponse(): SeiSwpOauthResponse {
        return this._response;
    }

    public set response(value: SeiSwpOauthResponse) {
        this._response = value;
    }

    public get jwt(): string {
        if (!this.validate()) {
            return undefined;
        }

        return this._response.access_token;
    }

    public get appKey(): string {
        if (!this.validate()) {
            return undefined;
        }

        return this._config.payload.headers.appKey;
    }

    /**
     * Fetches the token from REST
     * @deprecated
     */
    public getApigeeToken(): Observable<SeiSwpOauthResponse> {
        if (this.validate()) {
            return of(this.apigeeTokenResponse);
        }

        this.swpOauthRepository.apigeeConfig = this.apigeeConfig;
        return this.swpOauthRepository.read().pipe(
            filter((response) => response !== undefined),
            tap((response: SeiSwpOauthResponse) => (this._response = response))
        );
    }

    public validate(): boolean {
        if (
            !this.propertyService.allExist([
                () => this.apigeeConfig.payload.headers.appKey,
                () => this.apigeeTokenResponse
            ])
        ) {
            return false;
        }

        return this.isTokenExpired();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public isOauthRequest(req: HttpRequest<any>): boolean {
        return req.url.includes(this.oauthSecurityKey) || req.url.includes(this.authorizationCodeKey);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public ignoreHeaderSmUniversalId(req: HttpRequest<any>): boolean {
        return req.url.includes(this.oauthTokenKey)
            || req.url.includes(this.authorizationCodeKey)
            || req.url.includes(this.elasticSearchKey);
    }

    public isOauthRequestURL(requestURL: String): boolean {
        return requestURL.includes(this.oauthTokenKey);
    }
    /**
     * This should be all that is needed to generate a new token
     * If the token is invalid, the guard will request a new one
     * THere is no requirement to redirect an invalid token since
     * tokens are generated by APP KEY. THe Redirect would occurs
     * if APP KEY is invalid and the access token end point returns a 403
     */
    public isTokenExpired(): boolean {
        if (
            this.propertyService.notExists(
                () => this.apigeeTokenResponse.expires_in
            )
        ) {
            return false;
        }

        const currentTime = Date.now() / 1000;
        // token expires time is given in seconds
        const expiredTime =
            Date.now() + this.apigeeTokenResponse.expires_in * 1000;
        return currentTime < expiredTime;
    }

    /**
     * Check if the token is expired based on the issued_at
     * and the local time while received the token
     */
    public isTokenExpiredNow(): boolean {
        if (
            this.propertyService.notExists(
                () => this._response.expires_in
            )
        ) {
            return false;
        }
        const localTime = Date.now();
        const serverTimeNow =
            localTime + (this._response.diff_time);
        const serverExpiryTime = (Number(this._response.issued_at) + (this._response.expires_in * 1000));
        return serverTimeNow > serverExpiryTime;
    }

    private initializeService() {
        this._config = this.global.configService.apigeeConfiguration;
    }

    public getUnexpiredAccessToken(): Observable<ApigeeSlice> {
        const selectApigeeState: MemoizedSelector<object, ApigeeSlice, DefaultProjectorFn<ApigeeSlice>> =
            createFeatureSelector<ApigeeSlice>('apigeeSlice');
        return this.appStore.select(selectApigeeState)
            .pipe(
                filter((data: ApigeeSlice): boolean => !this.global.isEmpty(data.accessToken) && !data.isTokenExpired),
                first()
            );
    }
}
