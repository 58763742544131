/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, OnInit, Input, OnDestroy } from '@angular/core';

@Component({
    selector: 'car-right-hand-dialog',
    templateUrl: './right-hand-dialog.component.html'
})
export class RightHandDialogComponent implements OnInit, OnDestroy {
    public showTitle: boolean = false;

    @Input()
    public footerFunction: string = 'MRDC';

    @Input()
    public dialogTitle: string = '';

    private get body(): HTMLBodyElement {
        return document.getElementsByTagName('body')[0];
    }

    public ngOnInit(): void {
        this.body.style.overflow = 'hidden';

        this.showTitle = this.dialogTitle.trim().length > 0;
    }

    public ngOnDestroy(): void {
        this.body.style.overflow = 'auto';
    }
}
