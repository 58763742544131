/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { SpinnerService } from '@CarServices/system/spinner.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Global, Logger } from '@sei/common-swp-components-lib-ux';
import { Observable } from 'rxjs';
import { finalize, map, share } from 'rxjs/operators';
import { HttpOptions } from '../../model/http-types';
import { SeiJsonSerializerService } from '../system/sei-json-serializer.service';

export enum HttpGrantTypes {
    Password = 'password',
    ClientCredentials = 'client_credentials'
}

/**
 * Extends HttpUtil to provide some encapsulation for CAR requirements as needed.
 *
 * WORK IN PROGRESS
 *
 * Having CORs issues using HttpUtil will work on this later
 */
@Injectable({
    providedIn: 'root'
})
export class HttpUtilityService {
    /**
     * constructor to pass injector classes to super
     * @param http default http client from injector
     * @param globalService global service to provide global and logger to super
     */
    constructor(
        protected readonly http: HttpClient,
        // Do not use CarGlobal. Creates a Circular Reference
        protected readonly logger: Logger,
        protected readonly global: Global,
        private readonly serializer: SeiJsonSerializerService,
        private readonly spinnerService: SpinnerService
    ) { }

    /**
     * Providing functionality for doGet to support CAR returned data
     * @param route route to end point
     * @param headers optional headers to be added. Note CAR uses interceptors for headers. this is
     * to support blueprint
     * @param options option object:
     * {
     *  returnDataKey: string; (required)
     * }
     *
     * Throws error if options.returnDataKey is not provided
     */
    public doGet<ReturnType>(
        route: string,
        headers?: HttpHeaders,
        options?: HttpOptions
    ): Observable<ReturnType> {
        this.spinnerService.start();

        this.logger.log(`doGet called for ${route}`);

        if (!options) {
            this.logger.error(
                `expected options.returnDataKey received invalid error see exception for details or use doPostRaw`
            );
            throw new Error(
                `expected options.returnDataKey received options: ${JSON.stringify(
                    options
                )}`
            );
        }
        return this.http.get<ReturnType>(route).pipe(
            share(),
            map((body: ReturnType) =>
                this.serializer.deserialize<ReturnType>(
                    body,
                    options.returnDataKey
                )
            ),
            finalize(() => this.spinnerService.stop())
        );
    }

    public doPost<ReturnType, BodyDataType>(
        route: string,
        body: BodyDataType,
        headers?: HttpHeaders,
        options?: HttpOptions,
        includeWrapper: boolean = true
    ): Observable<ReturnType> {
        this.spinnerService.start();

        this.logger.log(`doPost called for ${route}`);

        if (!options) {
            this.logger.error(
                `expected options.returnDataKey received invalid error see exception for details or use doPostRaw`
            );
            throw new Error(
                `expected options.returnDataKey received options: ${JSON.stringify(
                    options
                )}`
            );
        }

        const bodyData = this.serializer.serialize<BodyDataType>(
            body,
            includeWrapper
        );

        return this.http.post<ReturnType>(route, bodyData).pipe(
            share(),
            map((response: ReturnType) =>
                this.serializer.deserialize<ReturnType>(
                    response,
                    options.returnDataKey
                )
            ),
            finalize(() => this.spinnerService.stop())
        );
    }

    public doPut<ReturnType, BodyDataType>(
        route: string,
        body: BodyDataType,
        headers?: HttpHeaders,
        options?: HttpOptions
    ): Observable<ReturnType> {
        this.spinnerService.start();

        this.logger.log(`doPost called for ${route}`);

        if (!options) {
            this.logger.error(
                `expected options.returnDataKey received invalid error see exception for details or use doPostRaw`
            );
            throw new Error(
                `expected options.returnDataKey received options: ${JSON.stringify(
                    options
                )}`
            );
        }

        const bodyData = this.serializer.serialize<BodyDataType>(body, true);

        return this.http.put<ReturnType>(route, bodyData).pipe(
            share(),
            map((response: ReturnType) =>
                this.serializer.deserialize<ReturnType>(
                    response,
                    options.returnDataKey
                )
            ),
            finalize(() => this.spinnerService.stop())
        );
    }

    public doDelete<ReturnType, BodyDataType>(
        route: string,
        headers?: HttpHeaders,
        options?: HttpOptions,
        body?: BodyDataType
    ): Observable<ReturnType> {
        this.spinnerService.start();

        this.logger.log(`doDelete called for ${route}`);

        if (body) {
            return this.http.request('delete', route, { body }).pipe(
                share(),
                map((response: Object) => {
                    try {
                        // Endpoint should return data property as empty.
                        // Deserialize method uses data property to deserialize the Json object
                        // if it could be deserialized we can determinate as a false since
                        // it is not an empty data property
                        return this.serializer.deserialize<ReturnType>(
                            response,
                            options.returnDataKey
                        );
                    } catch (e) {
                        return undefined;
                    }
                }),
                finalize(() => this.spinnerService.stop())
            );
        }

        return this.http.delete(route).pipe(
            share(),
            map((response: Object) => {
                {
                    try {
                        // Endpoint should return data property as empty.
                        // Deserialize method uses data property to deserialize the Json object
                        // if it could be deserialized we can determinate as a false since
                        // it is not an empty data property
                        return this.serializer.deserialize<ReturnType>(
                            response,
                            options.returnDataKey
                        );
                    } catch (e) {
                        return undefined;
                    }
                }
            }),
            finalize(() => this.spinnerService.stop())
        );
    }

    /**
     * Allows direct access to super doGet function
     * @param route end point route to hit
     * @param headers optional request headers to add
     */
    public doGetRaw<ReturnType>(
        route: string,
        headers?: HttpHeaders
    ): Observable<ReturnType> {
        this.spinnerService.start();

        if (headers) {
            return this.http
                .get<ReturnType>(route, {
                    headers
                })
                .pipe(finalize(() => this.spinnerService.stop()));
        } else {
            return this.http
                .get<ReturnType>(route)
                .pipe(finalize(() => this.spinnerService.stop()));
        }
    }

    public doPostRaw<ReturnType, BodyDataType>(
        route: string,
        body: BodyDataType,
        headers?: HttpHeaders
    ): Observable<ReturnType> {
        this.spinnerService.start();

        if (headers) {
            return this.http
                .post<ReturnType>(route, body, {
                    headers
                })
                .pipe(finalize(() => this.spinnerService.stop()));
        } else {
            return this.http
                .post<ReturnType>(route, body)
                .pipe(finalize(() => this.spinnerService.stop()));
        }
    }

    public createHttpHeader(headers): HttpHeaders {
        let httpHeaders = new HttpHeaders();

        if (headers) {
            const headerKeys = Object.keys(headers);
            headerKeys.forEach((key: string) => {
                httpHeaders = httpHeaders.append(key, headers[key]);
            });
        }

        return httpHeaders;
    }
}
