<div *ngFor="let section of proposalSection.sections;let counter = index">
    <div class="row toggle-row"
         *ngIf="!section.hidden">
        <div class="col-xs-7 toggle-row-left indent2"
             [ngSwitch]="section.name">
            <br *ngSwitchCase="proposalSectionLabel.TableOfContents" />
            <p>{{section.name}}</p>
            <sei-car-html-form-elements *ngSwitchCase="proposalSectionLabel.CoverImage"
                                        [proposalSection]="section"
                                        [dropDownContent]="coverImages"
                                        [showRevertOption]="true"
                                        [presentationType]="presentationType"
                                        [disableRevertOption]="!section.defaultProperties.defaultValue || disableRevertByTheme()"
                                        (dropdownSelected)="onCoverImageChange($event)"
                                        (htmlFormElementSelected)="updatePreviewSection($event, section.statementSectionId)"></sei-car-html-form-elements>
            <sei-car-html-form-elements *ngSwitchCase="proposalSectionLabel.CoverTitle"
                                        [disableInput]="coverTitleDisabled || isIPS()"
                                        [proposalSection]="section"
                                        [showRevertOption]="true"
                                        [presentationType]="presentationType"
                                        [coverTitleDisabled]="coverTitleDisabled"
                                        [disableRevertOption]="!section.defaultProperties.defaultValue"
                                        (htmlFormElementSelected)="updatePreviewSection($event,section.statementSectionId)"></sei-car-html-form-elements>
            <sei-car-html-form-elements *ngSwitchCase="proposalSectionLabel.TitleBarColor"
                                        [proposalSection]="section"
                                        [showRevertOption]="true"
                                        [presentationType]="presentationType"
                                        [coverTitleDisabled]="coverTitleDisabled"
                                        [disableRevertOption]="!section.defaultProperties.defaultValue"
                                        (htmlFormElementSelected)="updatePreviewSection($event,section.statementSectionId)"></sei-car-html-form-elements>
            <sei-car-html-form-elements *ngSwitchCase="proposalSectionLabel.TitleBarTextColor"
                                        [proposalSection]="section"
                                        [showRevertOption]="true"
                                        [presentationType]="presentationType"
                                        [coverTitleDisabled]="coverTitleDisabled"
                                        [disableRevertOption]="!section.defaultProperties.defaultValue"
                                        (htmlFormElementSelected)="updatePreviewSection($event,section.statementSectionId)"></sei-car-html-form-elements>
            <sei-car-html-form-elements *ngSwitchCase="proposalSectionLabel.BrandLogo"
                                        [proposalSection]="section"
                                        [dropDownContent]="firmLogos"
                                        [presentationType]="presentationType"
                                        [coverTitleDisabled]="coverTitleDisabled"
                                        [showRevertOption]="false"
                                        [disableRevertOption]="!section.defaultProperties.defaultValue"
                                        (htmlFormElementSelected)="updatePreviewSection($event,section.statementSectionId)"></sei-car-html-form-elements>
            <sei-car-html-form-elements *ngSwitchCase="proposalSectionLabel.PresentationTheme"
                                        [proposalSection]="section"
                                        [dropDownContent]="themeOptions"
                                        [presentationType]="presentationType"
                                        [coverTitleDisabled]="coverTitleDisabled"
                                        [showRevertOption]="false"
                                        [disableRevertOption]="!section.defaultProperties.defaultValue"
                                        (dropdownSelected)="onThemeChange($event)"
                                        (htmlFormElementSelected)="updatePreviewSection($event,section.statementSectionId)"></sei-car-html-form-elements>
            <sei-car-html-form-elements *ngSwitchCase="proposalSectionLabel.CoverSubtitle"
                                        [proposalSection]="section"
                                        [showRevertOption]="true"
                                        [presentationType]="presentationType"
                                        [disableInput]="isIPS()"
                                        [disableRevertOption]="!section.defaultProperties.defaultValue"
                                        (htmlFormElementSelected)="updatePreviewSection($event,section.statementSectionId)"></sei-car-html-form-elements>
            <sei-car-html-form-elements *ngSwitchCase="proposalSectionLabel.ThemeColor"
                                        [proposalSection]="section"
                                        [showRevertOption]="true"
                                        [presentationType]="presentationType"
                                        [disableRevertOption]="!section.defaultProperties.defaultValue"
                                        (htmlFormElementSelected)="updatePreviewSection($event,section.statementSectionId)"></sei-car-html-form-elements>

        </div>
        <div class="col-xs-2">
            <sei-button class="btn-in-tooltip"
                        *ngIf="(showBrandLogoUpload && section.name === proposalSectionLabel.BrandLogo || (section.name === proposalSectionLabel.CoverImage && checkThemeAllowUpload()))"
                        buttonText="Upload"
                        buttonLeftFontAwesomeIcon="fas fa-cloud-upload-alt"
                        buttonClass="btn-default"
                        (buttonClicked)="(showBrandLogoUpload && section.name === proposalSectionLabel.BrandLogo) ? onImageUploadClick($event) : onCoverImageUploadClick($event)">
            </sei-button>
        </div>

        <div id="toggle-{{counter}}"
             class="col-xs-3"
             [class.toggle-row-center]="section.name !== proposalSectionLabel.BrandLogo && section.toggle">
            <br *ngIf="section.name === proposalSectionLabel.TableOfContents" />
            <sei-icon-toggle *ngIf="section.name !== proposalSectionLabel.BrandLogo && section.toggle && !section.hidden"
                             name="icon-toggle"
                             [(ngModel)]="section.include"
                             (ngModelChange)="updatePreviewSection($event, section.statementSectionId)"
                             [trueIcon]="trueIcon"
                             [falseIcon]="falseIcon"
                             [iconClass]="iconClass"
                             [iconSize]="iconSize"
                             [offIsGrey]="offIsGrey"
                             [disabled]="disabled">
            </sei-icon-toggle>
        </div>
    </div>
</div>

<car-file-upload-modal-panel [proposalId]="proposalId"
                             [fileUploadViewMode]="fileUploadViewMode"
                             [presentationType]="presentationType"
                             [sectionName]="sectionName"
                             [subSectionName]="subSectionName"
                             (onCancel)="onImageUploadModalCancel()"
                             (onSuccessfullUpload)="updateCoverImageDropdown($event)"
                             *ngIf="showFileUploadModal"></car-file-upload-modal-panel>
