/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { GroupCHNWStrategy, Account } from '../model/proposal';

@Injectable({
    providedIn: 'root'
})
export class RochdaleGroupService {
    public createGroupCHNWStrategy(accounts: Account[], strategyGroups: GroupCHNWStrategy[] = []): GroupCHNWStrategy[] {
        const groupCHNWStrategy: GroupCHNWStrategy[] = [...strategyGroups];

        groupCHNWStrategy.forEach((group: GroupCHNWStrategy) => group.accountId = []);

        for (const account of accounts) {
            if (this.hasRochdale(account)) {
                // eslint-disable-next-line @typescript-eslint/tslint/config
                const [rochdaleStrategy] = account.strategies;
                const strategyGroup: GroupCHNWStrategy = this.findGroupByModelId(rochdaleStrategy.modelId, groupCHNWStrategy);

                if (strategyGroup) {
                    const accountsId = new Set([...strategyGroup.accountId, account.id]);
                    strategyGroup.accountId = Array.from(accountsId);
                } else {
                    const newGroup = this.createCHNWStrategy();
                    newGroup.modelId = rochdaleStrategy.modelId;
                    newGroup.accountId = [account.id];
                    groupCHNWStrategy.push(newGroup);
                }

                if (account.groupCHNWStrategyId === 0) {
                    account.groupCHNWStrategyId = null;
                }
            } else {
                account.groupCHNWStrategyId = null;
            }
        }

        return groupCHNWStrategy;
    }

    public setGroupCHNWToAccounts(accounts: Account[], strategyGroup: GroupCHNWStrategy[]): void {
        for (const group of strategyGroup) {
            const { accountId, groupId }: { accountId: number[]; groupId: number} = group;

            for (const id of accountId) {
                const foundAccount: Account = accounts.find((account: Account) => account.id === id);

                if (foundAccount) {
                    foundAccount.groupCHNWStrategyId = groupId;
                }
            }
        }
    }

    private hasRochdale(account: Account): boolean {
        return typeof account.groupCHNWStrategyId === 'number' && account.strategies && account.strategies.length > 0;
    }

    private findGroupByModelId(modelId: number, strategyGroup: GroupCHNWStrategy[]): GroupCHNWStrategy {
        return strategyGroup.find((group: GroupCHNWStrategy) => {
            return group.modelId === modelId;
        });
    }

    private createCHNWStrategy(): GroupCHNWStrategy {
        return {
            accountId: [],
            groupId: null,
            modelId: null,
            isDefault: true
        };
    }
}
