/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, OnInit } from '@angular/core';
import { AuthService } from '@CarAuth/auth.service';
import { GlobalService } from '@CarServices/system/global.service';

@Component({
    selector: 'car-not-authorized',
    templateUrl: './not-authorized.component.html'
})
export class NotAuthorizedComponent implements OnInit {
    public showImpersonation: boolean;
    public smUser: string;
    public smUniversalId: number;

    constructor(
        private readonly global: GlobalService,
        private readonly authService: AuthService
    ) {}

    ngOnInit() {
        // ensure the store is cleared for impersonation
        this.authService.kill();

        this.showImpersonation = this.global.configService.environment.authConfig.showImpersonation;

        this.smUniversalId = this.authService.externalSiteminderUniversalId;
        this.smUser = this.authService.externalSiteminderUserId;
    }
}
