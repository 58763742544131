/*
 Copyright: This information constitutes the exclusive property of SEI
 Investments Company, and constitutes the confidential and proprietary
 information of SEI Investments Company.  The information shall not be
 used or disclosed for any purpose without the written consent of SEI
 Investments Company.
*/

import { APP_INITIALIZER, NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { SiteminderValidatorComponent } from '@CarAuth/siteminder/siteminder-validator.component';
import { AccountStatusModule } from '@CarComponents/account-status/account-status.module';
import { CommonModule } from '@CarComponents/common/common.module';
import { ExceptionsModule } from '@CarComponents/exceptions/exceptions.module';
import { HomePageComponent } from '@CarComponents/home-page/home-page.component';
import { MrdcModule } from '@CarComponents/mrdc/mrdc.module';
import { PendingAccountLandingModule } from '@CarComponents/pending-account-landing/pending-account-landing.module';
import { PendingAccountsModule } from '@CarComponents/pending-accounts/pending-accounts.module';
import { PrintDialogModule } from '@CarComponents/print-dialog/print-dialog.module';
import { WipRiskToleranceModule } from '@CarComponents/risk-tolerance/wip-risk-tolerance.module';
import { SignerSelectionModule } from '@CarComponents/signer-selection/signer-selection.module';
import { WipAccountDetailsModule } from '@CarComponents/wip-account-details/wip-account-details.module';
import { WipAccountOwnerModule } from '@CarComponents/wip-account-owners/wip-account-owners.module';
import { WipBeneficiariesModule } from '@CarComponents/wip-beneficiaries/wip-beneficiaries.module';
import { WipFundingDirectionsModule } from '@CarComponents/wip-funding-directions/wip-funding-directions.module';
import { WipStrategicFundingPrioritiesModule } from '@CarComponents/wip-funding-priority/wip-funding-priority.module';
import { WipFundingSourcesModule } from '@CarComponents/wip-funding-sources/wip-funding-sources.module';
import { WipGoalsModule } from '@CarComponents/wip-goals/wip-goals.module';
import { WipMainModule } from '@CarComponents/wip-main/wip-main.module';
import { WipOutsideStrategyModule } from '@CarComponents/wip-outside-strategy/wip-outside-strategy.module';
import { PowerOfAttorneyModule } from '@CarComponents/wip-power-attorney/wip-power-attorney.module';
import { WipRelatedPartiesModule } from '@CarComponents/wip-related-parties/wip-related-parties.module';
import { WipScheduledWithDrawlsModule } from '@CarComponents/wip-scheduled-withdrawals/wip-scheduled-withdrawals.module';
import { WipStrategySelectionModule } from '@CarComponents/wip-strategy-selection/wip-strategy-selection.module';
import { CoreInterceptor } from '@CarHttp/core-interceptor';
import { AccountStoreModule } from '@CarServices/account/account-store/account-store.module';
import { WipSectionService } from '@CarServices/wip/wip-section.service';
import { CurrencyPipe, DatePipe, PercentPipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { OrderByPipe, crmRuleSlice } from '@sei/advisor-client-review-proposal-ux';
import {
    SeiCommonComponentsLibraryModule
} from '@sei/common-components-lib-ux';
import {
    AppConfigService,
    HttpXsrfInterceptor,
    SeiCommonSwpComponentsLibraryModule,
    USE_APIGEE,
    shippableCommonComponentLibEffects,
    shippableCommonComponentLibSlices
} from '@sei/common-swp-components-lib-ux';
import { accountAssignmentDataSlice, assetAllocationDataSlice, spinnerSlice } from '@sei/ias-applications-lib-ux';
import * as Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './core/auth/auth.module';
import { AuthService } from './core/auth/auth.service';
import { HeaderComponent } from './core/header/header.component';
import { HttpErrorHandlerInterceptor } from './core/http/http-error-handler.interceptor';
import { SiteminderInterceptor } from './core/http/siteminder.interceptor';
import { UploadFileInterceptor } from './core/http/upload-file.interceptor';
import { UrlRewriterInterceptor } from './core/http/url-rewriter.interceptor';
import { EnrollmentModule } from './core/services/enrollment/enrollment.module';
import { PresentationModule } from './proposal/presentation/presentation.module';
import { ProposalModule } from './proposal/proposal.module';
import { PercentFormatterDirective } from './shared/directives/percent-formatter/percent-formatter.directive';

highchartsMore(Highcharts);

@NgModule({
    declarations: [
        AppComponent,
        HomePageComponent,
        PercentFormatterDirective,
        HeaderComponent,
        SiteminderValidatorComponent
    ],
    exports: [HomePageComponent],
    imports: [
        BrowserModule,
        ButtonsModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        PopoverModule.forRoot(),
        TooltipModule.forRoot(),
        HttpClientModule,
        HttpClientXsrfModule.withOptions({
            cookieName: HttpXsrfInterceptor.CSRF_TOKEN_NAME,
            headerName: HttpXsrfInterceptor.XSRF_TOKEN_NAME
        }),
        StoreModule.forRoot(shippableCommonComponentLibSlices, {
            runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false
            }
        }),
        StoreModule.forFeature(
            'accountAssignmentDataSlice',
            accountAssignmentDataSlice),
        StoreModule.forFeature(
            'assetAllocationDataSlice',
            assetAllocationDataSlice),
        StoreModule.forFeature(
            'crmRuleSlice',
            crmRuleSlice
        ),
        StoreModule.forFeature(
            'spinnerSlice',
            spinnerSlice
        ),
        EffectsModule.forRoot([...shippableCommonComponentLibEffects]),
        StoreDevtoolsModule.instrument({ maxAge: 25 }),
        SeiCommonSwpComponentsLibraryModule.forRoot(),
        SeiCommonComponentsLibraryModule.forRoot(),
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        // CAR Modules
        CommonModule,
        ExceptionsModule,
        MrdcModule,
        AccountStatusModule,
        WipAccountOwnerModule,
        WipBeneficiariesModule,
        WipFundingDirectionsModule,
        WipFundingSourcesModule,
        WipMainModule,
        WipRiskToleranceModule,
        WipGoalsModule,
        WipScheduledWithDrawlsModule,
        WipStrategySelectionModule,
        WipAccountDetailsModule,
        PowerOfAttorneyModule,
        WipRelatedPartiesModule,
        PendingAccountsModule,
        PendingAccountLandingModule,
        WipStrategicFundingPrioritiesModule,
        PrintDialogModule,
        WipOutsideStrategyModule,
        SignerSelectionModule,
        AuthModule.forRoot(),
        AccountStoreModule.forRoot(),
        EnrollmentModule.forRoot(),
        ProposalModule,
        PresentationModule
    ],
    providers: [
        // KASSO
        CurrencyPipe,
        PercentPipe,
        DatePipe,
        OrderByPipe,
        // Interceptors
        // Order of execution is top down
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorHandlerInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: UrlRewriterInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CoreInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SiteminderInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: UploadFileInterceptor,
            multi: true
        },
        WipSectionService,
        AuthService,
        {
            provide: APP_INITIALIZER,
            useFactory: (config: AppConfigService) => () => config.load(),
            deps: [AppConfigService],
            multi: true
        },
        { provide: USE_APIGEE, useValue: true }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
