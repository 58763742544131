/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { ProcessingRule, UserProfile } from '@CarInterfaces/user-profile';
import { userProfileResponseMock15960077 } from '@CarMock/user-profile-response.15960077.mock';
import { EnvironmentMode, GlobalService } from '@CarServices/system/global.service';
import { PropertyService } from '@CarServices/system/property.service';
import { UtilityService } from '@CarServices/system/utility.service';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BaseRepository } from './base-repository';

@Injectable({
    providedIn: 'root'
})
export class UserProfileRepository extends BaseRepository<UserProfile> {
    constructor(
        protected readonly global: GlobalService,
        private readonly utilityService: UtilityService,
        private readonly propertyService: PropertyService
    ) {
        super(global);

        this.returnDataKey = 'userProfile';
        this.endPoint = {
            read: this.global.configService.getEndPoint('userProfile')
        };
    }

    /**
     * Endpoint is using an id called entityId. This wil be converted to siteMinderId
     * @param siteminderId Id to generate profile
     */
    public read(siteminderId: number | string): Observable<UserProfile> {
        this.global.logger.log(
            `SiteminderId requested from carWebService-UserProfile: ${siteminderId.toString()}`
        );

        // return mock object for development and mock mode
        // ToDo -> Tie this into Dev Parameters
        // ToDo => Change this to intercept the /user endpoint rather then in repo class
        if (
            this.global.configService.environment.mode ===
            EnvironmentMode.Dev &&
            (this.global.configService.environment.mock ||
                this.global.isMockDataMode())
        ) {
            this.sortProcessingRules(userProfileResponseMock15960077);
            return of(userProfileResponseMock15960077);
        }

        return this.global.httpUtilityService
            .doGet<UserProfile>(
                this.global.configService.routeFormatter(this.endPoint.read, {
                    key: 'userEntityId',
                    value: siteminderId.toString() // needed incase number is passed
                }),
                undefined,
                { returnDataKey: this.returnDataKey }
            )
            .pipe(
                tap((userProfile: UserProfile) =>
                    this.sortProcessingRules(userProfile)
                )
            );
    }

    private sortProcessingRules(userProfile: UserProfile) {
        if (this.propertyService.exists(() => userProfile.processingRules)) {
            userProfile.processingRules = this.utilityService.sortObjectKeys<
                ProcessingRule
                >(userProfile.processingRules);
        }
    }
}
