<div>
    <div class="center-contents">
        <button id="previous-page" (click)="decrementPageNumber()">Previous Page</button> | <button id="next-page" (click)="incrementPageNumber()">Next page</button>
    </div>

    <pdf-viewer
      [src]="pdfDocument"
      [rotation]="0"
      [original-size]="true"
      [show-all]="true"
      [page]="pageNumber"
      [fit-to-page]="true"
      [zoom]=".75"
      [zoom-scale]="'page-width'"
      [stick-to-page]="false"
      [render-text]="true"
      [external-link-target]="'blank'"
      [autoresize]="true"
      [show-borders]="false"
      style="width: 95%; height: 700px;"
    ></pdf-viewer>
</div>
