/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    DisplayNullOptions,
    EditAmountOptions
} from '@sei/common-components-lib-ux';
import { Observable } from 'rxjs';
import { StrategyAllocation } from '../../model/allocation';
import {
    AlertPopoverTypes,
    CommandText,
    CurrencyTypes,
    EventType,
    RiskLevels,
    StrategyMenu
} from '../../model/enums';
import { Account, Strategy } from '../../model/proposal';
import { DelegationService } from '../../service/delegation.service';
import { ProposalAccountStrategyService } from '../../service/proposal-account-strategy.service';

@Component({
    selector: 'car-strategy-ribbon',
    templateUrl: './strategy-ribbon.component.html',
    styleUrls: ['./strategy-ribbon.component.scss']
})
export class StrategyRibbonComponent implements OnInit {
    @Input()
    public strategy: Strategy;

    @Input()
    public strategyIndex: number;

    @Input()
    public account: Account;

    @Input()
    public ribbonType: string;

    @Output() strategyMouseOver = new EventEmitter<Strategy>();

    @Output() accountChange = new EventEmitter();

    public allocationStrategyStream: Observable<StrategyAllocation>;
    public showAddStrategy: boolean = true;
    public showSectionDetails: boolean = false;
    public showStrategyDrawer: boolean = false;
    public strategyBalanceError: boolean = false;
    public formattedAmount: string = '';
    public risk: typeof RiskLevels = RiskLevels;
    public ribbonTypes: typeof StrategyMenu = StrategyMenu;
    public alertPopoverTypes: typeof AlertPopoverTypes = AlertPopoverTypes;
    public currencyTypes: typeof CurrencyTypes = CurrencyTypes;
    public amountOptions: EditAmountOptions;

    // NOTE : Hide this button in M1
    public showChangeButton: boolean = false;

    constructor(
        private proposalAccountStrategyService: ProposalAccountStrategyService,
        private delegationService: DelegationService
    ) { }

    public ngOnInit(): void {
        this.delegationService.refresh().subscribe((data: string) => {
            if (data === CommandText.SaveStrategies) {
                this.strategyBalanceError = this.strategyBalanceValidate();
            }
        });

        this.enableDisableSaveButton();
        this.initializeComponent();
    }

    public initializeComponent(): void {
        this.amountOptions = {
            minValueAllowed: '0',
            nullDisplay: DisplayNullOptions.ZERO
        };
    }

    public toggleAddStrategy(event): void {
        this.showAddStrategy = event.type === EventType.MouseOver ? false : true;
        if (this.showStrategyDrawer) {
            this.showAddStrategy = false;
        }
        this.strategyMouseOver.emit(this.strategy);
    }

    public toggleChevron(strategyId: number): void {
        this.showSectionDetails = !this.showSectionDetails;
        if (this.showSectionDetails) {
            this.showAddStrategy = false;
            this.showStrategyDrawer = true;
            this.bindAllocations(strategyId);
        } else {
            this.showAddStrategy = true;
            this.showStrategyDrawer = false;
        }
    }

    public bindAllocations(strategyId: number): void {
        this.allocationStrategyStream = this.proposalAccountStrategyService.getAllocationByStrategyId(strategyId);
    }

    public addStrategyClick(strategy: Strategy): void {
        if (!this.account.strategies) {
            this.account.strategies = [];
        }

        strategy.proposalAccountStrategyId = undefined;
        strategy.investmentStrategyCategoryId = strategy.id;
        strategy.id = undefined;
        strategy.balance = this.account.strategies.length === 0 ? this.account.balance : 0;
        this.account.strategies.push(strategy);
        this.delegationService.publish(CommandText.UpdateStrategy);
        this.onStrategyBalanceBlur();
    }

    public changeStrategyClick(strategyId: number): void {
        const strategyIndex: number = this.account.strategies.findIndex((x) => x.id === strategyId);
        this.account.strategies.splice(strategyIndex, 1);
        this.delegationService.publish(CommandText.ChangeStrategy);
    }

    public onConfirmRemoveStrategyClick(): void {
        this.account.strategies.splice(this.strategyIndex, 1);

        this.delegationService.publish(CommandText.UpdateStrategy);
        if (this.account.strategies.length <= 0) {
            this.delegationService.publish(CommandText.ChangeStrategy);
            this.delegationService.publish(CommandText.GetStrategies);
        }
        this.onStrategyBalanceBlur();
    }

    public onStrategyBalanceBlur(): void {
        this.accountChange.emit();
        this.enableDisableSaveButton();
    }

    public enableDisableSaveButton(): void {
        if (this.strategy) {
            this.strategyBalanceError = this.strategyBalanceValidate();

            const inValidStrategies = this.account.strategies.filter(
                (strategy: Strategy) => {
                    return !strategy.balance;
                }
            );

            if ((inValidStrategies.length > 0 && this.strategyBalanceError)) {
                this.delegationService.publish(CommandText.DisableSaveAndExitButton);
            } else {
                this.delegationService.publish(
                    CommandText.EnableSaveAndExitButton
                );
            }
        }
    }

    public onStrategyBalanceChange(event: number): void {
        this.strategy.balance = event;
        this.enableDisableSaveButton();
    }

    public strategyBalanceValidate(): boolean {
        return !this.strategy.balance;
    }
}
