/*
 * Copyright: This information co../../../model/presentationty of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsElementComponent } from '../../../../common/settings-element/settings-element.component';
import { ProposalSection } from '../../../../model/presentation';
import { DataSharingService } from '../../../../service/data-sharing.service';
import { PresentationSettingService } from '../../../../service/presentation-setting.service';
import { ProposalService } from '../../../../service/proposal.service';


@Component({
    selector: 'sei-car-reporting-levels',
    templateUrl: './reporting-levels.component.html'
})
export class ReportingLevelsComponent extends SettingsElementComponent implements OnInit {
    constructor(
        public presentationSettingService: PresentationSettingService,
        public dataSharingService: DataSharingService,
        public router: Router,
        public route: ActivatedRoute,
        proposalService: ProposalService
    ) {
        super(presentationSettingService, dataSharingService, router, route, proposalService);
    }

    ngOnInit() {
        super.ngOnInit();

        this.proposalSection.sections.forEach((section: ProposalSection) => {
            if (section.name === 'Roll up All Accounts') {
                section.include = this.businessRules.ipsReportingLevelRollUpAllAccounts;
                section.isToggleDisabled = !this.businessRules.ipsReportingLevelRollUpAllAccounts;
            }
        });
    }
}
