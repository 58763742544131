<label for="account-owners-dropdown"
       [hidden]="hideLabel">Account Owner</label>
<select id="account-owners-dropdown "
        class="form-control"
        (change)="onChange($event)">
    <option selected
            disabled>Select or Enter a Name</option>
    <option *ngFor="let item of elasticSearchData"
            [disabled]="item.value === staticOptions.StartTypingName || item.value === '' || item.value === staticOptions.HorizontalLine"
            [class.bold]="item.value === staticOptions.NewClient"
            [value]="item.value">{{item.label}}
    </option>
</select>
