/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { UserProfile } from '@CarInterfaces/user-profile';
import { GlobalService } from '@CarSystem/global.service';
import { UserProfileService } from '@CarSystem/user-profile.service';
import { Store } from '@ngrx/store';
import { SeiCommonAppStore } from '@sei/common-swp-components-lib-ux';
import { Observable, of } from 'rxjs';
import {
    exhaustMap,
    filter,
    mergeMap,
    skipWhile,
    switchMap
} from 'rxjs/operators';
import { ApigeeTokenService } from './apigee-token.service';
import { AuthService } from './auth.service';

// ToDo: MVP2 -> Need to use v7 feature of guard priority
@Injectable({
    providedIn: 'root'
})
export class SiteminderGuard {

    constructor(
        private readonly authService: AuthService,
        private readonly global: GlobalService,
        private readonly userProfileService: UserProfileService,
        private readonly apigeeTokenService: ApigeeTokenService,
        private appStore: Store<SeiCommonAppStore>
    ) { }

    public canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (
            this.authService.isAuthenticated &&
            this.userProfileService.profile &&
            this.userProfileService.profile.entityId
        ) {
            // this should be mapped if this is true, but just incase
            this.authService.externalSiteminderUniversalId = this.userProfileService.profile.entityId;

            return this.getBearerToken();
        }

        // Return true if siteminder check is not enforced
        if (
            !this.authService.isAuthenticated &&
            !this.global.configService.environment.authConfig.enforceSiteminder
        ) {
            return this.getBearerToken();
        }

        if (
            !this.authService.isAuthenticated &&
            this.global.configService.environment.carConfig.apigeeEnabled
        ) {
            // Either this is first request or current token is expired so generate new one
            // We are not required to worry about refresh token, just acquire new JWT
            return this.appStore.select((store) => store.apigeeSlice)
                .pipe(
                    skipWhile((data) => this.global.isEmpty(data.accessToken) || !Object.keys(data).length),
                    exhaustMap(() => {
                        return this.performAuthStreaming();
                    })
                );
        } else {
            return this.performAuthStreaming();
        }
    }

    private performAuthStreaming(): Observable<boolean> {
        return this.authService.getUser().pipe(
            filter((data: UserProfile) => data !== undefined),
            mergeMap(() => {
                return of(true);
            })
        );
    }

    private performUserProfileStreaming(): Observable<boolean> {
        return this.authService
            .getCarUserProfileStreaming(
                this.authService.externalSiteminderUniversalId
            )
            .pipe(
                mergeMap(() => {
                    return of(true);
                })
            );
    }

    private getBearerToken(): Observable<boolean> {
        if (!this.global.configService.environment.carConfig.apigeeEnabled) {
            return of(true);
        }

        // If we are already validated, continue, no need to generate new JWT
        if (this.apigeeTokenService.validate()) {
            return of(true);
        }

        // Either this is first request or current token is expired so generate new one
        // We are not required to worry about refresh token, just acquire new JWT
        return this.appStore.select((store) => store.apigeeSlice)
            .pipe(
                skipWhile((data) => this.global.isEmpty(data.accessToken) || !Object.keys(data).length),
                switchMap(() => {
                    return of(true);
                })
            );
    }
}
